import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { browserHistory } from 'react-router';
import LimitReachedModalContainer from 'myhg/segment/LimitReachedModalContainer';
import EndTrialMessage from 'myhg/segment/segmentCrmStatus/components/EndTrialMessage';
import UserUtils from 'utils/UserUtils';
import {
    saveCurrentSegment
} from 'myhg/segment/actions';
import { HTTP_FORBIDEN_CODE, PLATFORM_SOURCE } from 'shared/constants';
import {
    SEGMENT_MATCHING_TITLE,
    SEGMENT_MATCHING_SERVICES,
    LINKEDIN_MAX_SEGMENT_SIZE,
    LINKEDIN_SEGMENT_TOO_LARGE_MSG,
    LINKEDIN_NOT_PROVISIONED_MSG,
    UNMATCH_SEGMENT_REMAINING_DAYS,
    REMAINING_DAYS,
    REMAINING_DAY
} from '../constants';

export default class SegmentDataMatchTiles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showLimitReachedModal: false
        };
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    onConnectSelect(matchServiceItem) {
        const { dispatch, currentSegment, isUserAuthenticated } = this.props;

        if (isUserAuthenticated) {
            if (currentSegment.id) {
                browserHistory.push(matchServiceItem.url);
                return;
            }

            dispatch(saveCurrentSegment()).then(() => {
                browserHistory.push(matchServiceItem.url);
            }).catch((error) => {
                if (error && error.status === HTTP_FORBIDEN_CODE) {
                    this.setState({
                        showLimitReachedModal: true
                    });
                }
            });
        } else {
            browserHistory.push(matchServiceItem.regUrl);
        }
    }

    onLimitOkClick = () => {
        this.setState({
            showLimitReachedModal: false
        });
    }

    getTooltipHideDelay() {
        return 2000;
    }

    getEndTrialText() {
        const { accountDetails } = this.props;

        if (accountDetails && this.isTrialEnded()) {
            return (
                <div className="end-trial-message-wrapper">
                    <div className="end-trial-gradient"></div>
                    <EndTrialMessage
                        description = "To continue comparing your segments to your accounts,"
                        { ...this.props }
                    />
                </div>
            );
        }

        return null;
    }

    /**
     * this method will display the remaining trial days message near title
     */
    getEndTrialTitleMessage() {
        const remainingDays = (this.getCampaignTrialInfo()).remainingDays;
        if (this.isTrialEnded() || this.isPaidUser() || !remainingDays) {
            return null;
        }

        const dayText = remainingDays === 1 ? REMAINING_DAY : REMAINING_DAYS;

        return (
            <span className="end-trial">
                { remainingDays }
                &nbsp;
                { dayText }
                &nbsp;
                { UNMATCH_SEGMENT_REMAINING_DAYS }
            </span>
        );
    }

    getLimitReachedModal() {
        return (
            <LimitReachedModalContainer
                onLimitOkClick={ this.onLimitOkClick }
                open={ this.state.showLimitReachedModal }
            />
        );
    }

    getTileClasses(tileConfig) {
        return classNames(
            'tile',
            {
                // 'hidden' for not showing unavailable platforms
                'not-available hidden': !this.getTileAvailable(tileConfig)
            }
        );
    }

    getTileClickBinding(tileConfig) {
        switch (tileConfig.type) {
            case PLATFORM_SOURCE.LINKEDIN:
                return this.getLinkedInTileBinding(tileConfig);

            default:
                return this.onConnectSelect.bind(this, tileConfig);
        }
    }

    getLinkedInTileBinding(tileConfig) {
        if (this.isLinkedInEnabled() && this.isPaidUser()
            && this.isSegmentLoaded() && !this.isSegmentTooLarge()) {
            return this.onConnectSelect.bind(this, tileConfig);
        }
        return null;
    }

    getTooltipContent(tileConfig) {
        switch (tileConfig.type) {
            case PLATFORM_SOURCE.LINKEDIN:
                return this.getLinkedInTileTooltip();
            default:
                return null;
        }
    }

    getLinkedInTileTooltip() {
        if (!this.isLinkedInEnabled()) {
            return 'LinkedIn is not enabled for this account.';
        } else if (!this.isPaidUser()) {
            return LINKEDIN_NOT_PROVISIONED_MSG;
        } else if (this.isSegmentTooLarge()) {
            return LINKEDIN_SEGMENT_TOO_LARGE_MSG;
        }
        return null;
    }

    getSegmentSize() {
        const { currentSegment } = this.props;
        return currentSegment.data.status.companies;
    }

    getTileAvailable(tileConfig) {
        if (!tileConfig.available || this.isTrialEnded()) {
            return false;
        }

        switch (tileConfig.type) {
            case PLATFORM_SOURCE.LINKEDIN:
                return this.isLinkedInEnabled();

            default:
                return true;
        }
    }

    getCampaignTrialInfo() {
        const { accountDetails } = this.props;
        return UserUtils.getCampaignTrialInfo(accountDetails);
    }

    isPaidUser() {
        const { accountDetails } = this.props;
        return UserUtils.isPaid(accountDetails);
    }

    isLinkedInEnabled() {
        const { accountDetails } = this.props;
        return UserUtils.hasLinkedInEnabled(accountDetails);
    }

    isTrialEnded() {
        const trialInfo = this.getCampaignTrialInfo();
        return (!this.isPaidUser()
                && trialInfo.isActive
                && trialInfo.remainingDays === 0);
    }

    isSegmentTooLarge() {
        const segmentSize = this.getSegmentSize();
        return (segmentSize > LINKEDIN_MAX_SEGMENT_SIZE);
    }

    isSegmentLoaded() {
        const { currentSegment } = this.props;
        const companies = currentSegment.data.status.companies;
        return (companies && companies > 0);
    }

    buildTiles(tileConfigList) {
        return tileConfigList.map((item) => {
            const tileClasses = this.getTileClasses(item);
            const iconClasses = classNames('icon', item.iconClass);

            return [
                <a
                    key={ `${item.type}-button` }
                    className={ tileClasses }
                    onClick={ this.getTileClickBinding(item) }
                    data-for={ `${item.type}-tooltip` }
                    data-tip={ this.getTooltipContent(item) }
                >
                    <span className={ iconClasses }></span>
                    <span className="text">
                        <span>{ item.text[0] }</span>
                        <span>{ item.text[1] }</span>
                    </span>
                </a>,
                <ReactTooltip
                    key={ `${item.type}-tooltip` }
                    id={ `${item.type}-tooltip` }
                    place="bottom"
                    effect="solid"
                    multiline
                />
            ];
        });
    }

    render() {
        return (
            <div className="segment-matching container segment-status-wrapper">
                <div className="segment-matching-section">
                    <h4 className="h4">{ SEGMENT_MATCHING_TITLE }</h4>
                    { this.getEndTrialTitleMessage() }
                    <div className="tiles">
                        { this.buildTiles(SEGMENT_MATCHING_SERVICES) }
                    </div>
                </div>
                { this.getEndTrialText() }
                { this.getLimitReachedModal() }
            </div>
        );
    }
}
