import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_RESET_STATE
} from './constants';

export const logInInitialState = {
    submitting: false,
    authData: null
};

export default function logIn(state = logInInitialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST :
            return Object.assign({}, state, {
                submitting: true,
                error: null
            });

        case LOGIN_SUCCESS :
            return Object.assign({}, state, {
                submitting: false,
                authData: action.data,
                error: null
            });

        case LOGIN_FAILURE :
            return Object.assign({}, state, {
                submitting: false,
                error: action.error
            });

        default:
            return state;
    }
}

export const forgotPasswordInitialState = {
    submitting: false,
    success: false,
    error: null,
    data: null
};

export function resetPassword(state = forgotPasswordInitialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST :
            return Object.assign({}, state, {
                submitting: true
            });

        case RESET_PASSWORD_SUCCESS :
            return Object.assign({}, state, {
                submitting: false,
                success: true,
                data: action.data
            });

        case RESET_PASSWORD_FAILURE :
            return Object.assign({}, state, {
                submitting: false,
                error: action.error
            });

        case RESET_PASSWORD_RESET_STATE:
            return forgotPasswordInitialState;

        default:
            return state;
    }
}
