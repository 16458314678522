import React from 'react';
import classNames from 'classnames';

export default class FilterWrapper extends React.Component {

    render() {
        const { className, innerClassName } = this.props;
        const filterWrapperClassName = classNames('filterwrapper', className);

        return (
            <div className={ filterWrapperClassName }>
                <span className={ innerClassName || 'filterwrapper-text' }>
                    { this.props.children }
                </span>
            </div>
        );
    }
}
