import React from 'react';
import { SEGMENT_TAB_MENUS } from '../constants';
import { segmentMenuSelect } from '../actions';
import TabMenu from 'shared/ui/tabs/TabMenu';

export default class SegmentTabMenus extends React.Component {

    menuItemChanged = (menu) => {
        const { dispatch } = this.props;
        dispatch(segmentMenuSelect(menu));
    }

    render() {
        const { segmentMenu } = this.props;
        return (
            <TabMenu
                className="segment-tabs"
                menus={ SEGMENT_TAB_MENUS }
                selectedMenu={ segmentMenu }
                menuItemChanged={ this.menuItemChanged }
            />
        );
    }
}
