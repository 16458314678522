export const SALESFORCE_CONTACTS_REQUEST = 'SALESFORCE_CONTACTS_REQUEST';
export const SALESFORCE_CONTACTS_SUCCESS = 'SALESFORCE_CONTACTS_SUCCESS';
export const SALESFORCE_CONTACTS_FAILURE = 'SALESFORCE_CONTACTS_FAILURE';
export const UNEXPECTED_CONTACTS_LOAD_ERROR =
    'There was a problem retrieving some of your Salesforce data.';

export const SALESFORCE_RECORD_TYPE_CONTACT = 'contacts';
export const SALESFORCE_RECORD_TYPE_LEAD = 'leads';

export const SALESFORCE_RECORD_SUMMARY = 'Please select the record type(s) from your Salesforce' +
    ' account you would like us to match to your segment.';
export const SALESFORCE_RECORD_TYPE_PAGE_TITLE = 'Select Salesforce Record Type(s)';
export const RECORD_TYPE_ADD = 'RECORD_TYPE_ADD';
export const RECORD_TYPE_REMOVE = 'RECORD_TYPE_REMOVE';
export const RECORD_TYPE_CLEAR = 'RECORD_TYPE_CLEAR';

export const SELECTIONS_RECORD_TYPE_LIST = [
    SALESFORCE_RECORD_TYPE_CONTACT,
    SALESFORCE_RECORD_TYPE_LEAD
];

export const RECORD_TYPE_INFO = {
    [SALESFORCE_RECORD_TYPE_CONTACT]: {
        title: 'Contacts',
        description: 'Match technologies using Contact records.'
    },
    [SALESFORCE_RECORD_TYPE_LEAD]: {
        title: 'Leads',
        description: 'Match technologies using Lead records.'
    }
};
