import {
    FILTERS_SET_LAST_CHECK_VALUE,
    FILTERS_LAST_CHECK_OPTIONS,
    FILTERS_LAST_CHECK_CLEAR
} from './constants';

export const lastCheckFilterInitialState = FILTERS_LAST_CHECK_OPTIONS[0].value;

export default function lastCheck(state = lastCheckFilterInitialState, action) {
    switch (action.type) {
        case FILTERS_SET_LAST_CHECK_VALUE:
            return action.lastCheckValue || lastCheckFilterInitialState;

        case FILTERS_LAST_CHECK_CLEAR:
            return lastCheckFilterInitialState;

        default:
            return state;
    }
}
