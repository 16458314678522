import 'dom4';
import 'babel-polyfill';
import React from 'react';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { hydrate } from 'react-dom';
import configureStore from './redux/configureStore';
import routes from './routes';

require('../../assets/styles/index.scss');

const initialState = window.__INITIAL_STATE__;
delete window.__INITIAL_STATE__;

const sentryDsnPublic = window.__SENTRY_DSN_PUBLIC__;
delete window.__SENTRY_DSN_PUBLIC__;

const release = window.__RELEASE_TAG__;
delete window.__RELEASE_TAG__;

// set up Store
const store = configureStore(initialState);

hydrate(
    <Provider store={ store }>
        <Router children={ routes() } history={ browserHistory } />
    </Provider>,
    document.getElementById('react-view')
);
