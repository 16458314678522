import React from 'react';
import sprintf from 'sprintf-js';
import CollectionCard from 'shared/cards/components/CollectionCard';
import CardsList from 'shared/cards/components/CardsList';
import Loader from 'shared/ui/loader/Loader';
import {
    COLLECTIONS_SHOW_NEXT_TEXT,
    COLLECTIONS_TOP_PRODUCT_COLLECTIONS
} from './constants';
import {
    showNextCollections
} from './actions';

export default class Collections extends React.Component {

    getRecommendedSubscriptions() {
        const {
            error,
            data: collections,
            showTopCount
        } = this.props.collections;

        const items = collections && collections.length > 0 ?
            this.mapDataToCards(collections) : null;
        const showNextText = sprintf.sprintf(
            COLLECTIONS_SHOW_NEXT_TEXT,
            COLLECTIONS_TOP_PRODUCT_COLLECTIONS
        );

        let content = <Loader />;

        if (error) {
            content = error.message;
        } else if (items && items.length > 0) {
            content = (
                <CardsList
                    cards ={ items }
                    showMore={ this.showMore }
                    countPerRow={ 3 }
                    topCount={ showTopCount }
                    nextText={ showNextText }
                />
            );
        }

        return content;
    }

    showMore = () => {
        this.props.dispatch(showNextCollections());
    }

    mapDataToCards(data) {
        return data.map((collection) => {
            return (
                <CollectionCard
                    key={ collection.slug }
                    collection={ collection }
                />
            );
        });
    }

    render() {
        return this.getRecommendedSubscriptions();
    }
}
