import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import CounterWrapper from 'shared/ui/counter/CounterWrapper';
import SegmentFilters from './components/SegmentListItemFilters';
import SegmentStatus from './components/SegmentListItemStatus';
import FirmographicUtils from 'utils/FirmographicUtils';
import { DateUtils, NumeralUtils, track } from 'utils/Utils';
import UserUtils from 'utils/UserUtils';
import { ACTION } from 'utils/track-constants';
import {
    UNIQUE_COMPANIES,
    MATCHED_COMPANIES,
    NEW_NEW_COMPANIES,
    CREATED
} from './constants';
import { getSegments } from 'myhg/viewallsegments/actions';
import { resetSegmentView, deleteSegment } from 'myhg/segment/actions';
import GenericDeleteButton from '../../../shared/ui/buttons/GenericDeleteButton';

function mapStateToProps(state) {
    return {
        accountDetails: state.user.accountDetails.data,
        revenueSteps: FirmographicUtils.getIntervalsIncludingBoundaries(
            state.taxonomy.firmographic.data.revenueSteps),
        employeesSteps: FirmographicUtils.getIntervalsIncludingBoundaries(
            state.taxonomy.firmographic.data.employeesSteps)
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class MySegmentListItemContainer extends React.Component {

    onClick = () => {
        const { dispatch, segment } = this.props;

        track(ACTION.OPEN_SEGMENT, {
            label: segment.name
        });

        dispatch(resetSegmentView());
        browserHistory.push('/dashboard/segment/view/'.concat(segment.id));
    }

    getStatistics() {
        const { segment } = this.props;
        const {
            companies,
            matched,
            netNew
        } = segment.data.status;
        const { recordTypes } = segment.data.filters;

        const formattedTotalCompaniesCount = companies ?
            NumeralUtils.formatIntegerNumber(companies) : '-';
        const formattedMatchedCompaniesCount = matched ?
            NumeralUtils.formatIntegerNumber(matched) : '-';
        const formattedNetNewCompaniesCount = netNew ?
            NumeralUtils.formatIntegerNumber(netNew) : '-';

        return (
            <div className="mysegment-statistics">
                <div className="mysegment-company-details">
                    <CounterWrapper
                        className="segment-stat-counter"
                        iconClass="mysegment-company-details-value icon-building"
                        title={ UNIQUE_COMPANIES }
                        itemCounts={ formattedTotalCompaniesCount }
                    />
                </div>
                <div className="mysegment-company-details">
                    <CounterWrapper
                        className="segment-stat-counter"
                        iconClass="mysegment-company-details-value icon-building"
                        title={ MATCHED_COMPANIES }
                        itemCounts={ formattedMatchedCompaniesCount }
                    />
                </div>
                <div className="mysegment-company-details">
                    <CounterWrapper
                        className="segment-stat-counter"
                        iconClass="mysegment-company-details-value icon-building"
                        title={ NEW_NEW_COMPANIES }
                        itemCounts={ formattedNetNewCompaniesCount }
                    />
                </div>
                <SegmentStatus
                    segmentStatus={ segment.data.status }
                    recordTypes={ recordTypes }
                />
            </div>
        );
    }

    getSegmentDelete() {
        const { accountDetails } = this.props;
        let button;
        if (UserUtils.isPaid(accountDetails)) {
            button = (
                <GenericDeleteButton
                    dispatch={ this.props.dispatch }
                    item={ this.props.segment }
                    tracker={ ACTION.DELETE_SEGMENT }
                    deleteAction={ deleteSegment }
                    afterDelete={ this.afterSegmentDelete }
                />
            );
        }
        return button;
    }

    afterSegmentDelete = () => {
        this.props.dispatch(getSegments());
    };

    render() {
        const { segment } = this.props;
        const url = `/dashboard/segment/view/${segment.id}`;
        const hrefId = `href_${segment.id}`;

        return (
            <Link to={ url } className="mysegment" id={ hrefId } onClick={ this.onClick }>
                <div className="mysegment-details">
                    <div className="mysegment-details-title">
                        { segment.name }
                        { this.getSegmentDelete() }
                    </div>
                    <span className="mysegment-details-data">
                        { CREATED } <b> { DateUtils.toStandardFormatDate(segment.createdOn) } </b>
                    </span>
                    <SegmentFilters { ...this.props } />
                </div>
                { this.getStatistics() }
            </Link>
        );
    }
}
