import {
    ADD_SHARE_EMAIL,
    REMOVE_SHARE_EMAIL,
    SHARE_VIA_EMAIL_SUCCESS,
    SHARE_VIA_EMAIL_ERROR,
    CLEAN_SHARE_STATE,
    POST_SEGMENT_SHARE,
    GET_SEGMENT_SHARE
} from './constants';
import EmailService from 'common/services/EmailService';
import SegmentService from 'common/services/SegmentService';

export function addShareEmail(email) {
    return (dispatch) => {
        dispatch({
            type: ADD_SHARE_EMAIL,
            email
        });
    };
}

export function cleanShareEmail() {
    return (dispatch) => {
        dispatch({
            type: CLEAN_SHARE_STATE
        });
    };
}

export function removeShareEmail(index) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_SHARE_EMAIL,
            index
        });
    };
}

export function shareViaEmails(segmentName, segmentUrl, emails) {
    return (dispatch) => {
        return EmailService.instance.sendEmail(emails, segmentUrl, segmentName)
            .then(() => {
                dispatch(cleanShareEmail());
                return dispatch({
                    type: SHARE_VIA_EMAIL_SUCCESS
                });
            })
            .catch((error) => {
                dispatch({
                    type: SHARE_VIA_EMAIL_ERROR,
                    error
                });
            });
    };
}

export function postSegmentShare(key, data) {
    return (dispatch) => {
        dispatch({
            type: POST_SEGMENT_SHARE,
            key
        });

        return SegmentService.instance.postShareSegment(key, data);
    };
}

export function getSegmentShare(key) {
    return (dispatch) => {
        dispatch({
            type: GET_SEGMENT_SHARE,
            key
        });
        return SegmentService.instance.getShareSegment(key);
    };
}
