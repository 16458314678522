import React from 'react';
import Modal from 'react-modal';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    closeLightbox,
    updateLightbox
} from './actions';
import { LightboxComponents } from './lightboxComponents';

function mapStateToProps(state) {
    return {
        lightbox: state.lightbox,
        productDetails: state.product.productDetails,
        authData: state.authentication.authData
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class LightboxContainer extends React.Component {

    onLightBoxClose() {
        if (this.props.lightbox.initialPath || this.props.lightbox.initialPath === '') {
            browserHistory.push(this.props.lightbox.initialPath || '/');
        }
        this.props.dispatch(closeLightbox());
    }

    onUpdateLigthBox(props) {
        this.props.dispatch(updateLightbox(props));
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    render() {
        const customClass = {
            content: {
                top: '0',
                bottom: '0',
                width: '100%',
                left: '0',
                right: '0',
                margin: '0 auto 0 auto',
                padding: '165px 0 0',
                background: 'transparent',
                border: 'none'
            }
        };

        let lightboxContent = null;
        if (this.props.lightbox.isOpen && this.props.lightbox.modalType) {
            const SpecificModal = LightboxComponents[this.props.lightbox.modalType];

            lightboxContent = (
                <Modal
                    closeTimeoutMS={ 150 }
                    isOpen={ this.props.lightbox.isOpen }
                    style={ customClass }
                    contentLabel={ this.props.lightbox.modalType }
                >
                    <div className="lightbox-container">
                        <div
                            onClick={ this.onLightBoxClose.bind(this) }
                            className="lightbox-close icon-close-x"
                        >
                        </div>
                        <SpecificModal
                            {...this.props.lightbox}
                            productDetails={ this.props.productDetails }
                            onUpdateLigthBox={ this.onUpdateLigthBox.bind(this) }
                            onLightBoxClose={ this.onLightBoxClose.bind(this) }
                            isUserAuthenticated={ this.isUserAuthenticated() }
                        />
                    </div>
                </Modal>
            );
        }
        return lightboxContent;
    }
}
