import SalesforceService from 'common/services/SalesforceService';
import {
    TELEMETRY_SUCCESS
} from './constants';

export function sendTelemetryData(actionName) {
    return (dispatch, getState) => {
        const state = getState();
        const campaignSessionId = state.mydata.campaignWizard.wizardSteps.campaignSessionId;
        const telemetryData = {
            action: actionName,
            campaignSessionId
        };
        return SalesforceService.instance.sendTelemetry(telemetryData)
            .then((response) => {
                dispatch({
                    type: TELEMETRY_SUCCESS,
                    data: response.data
                });
            });
    };
}

export function sendSalesforceTelemetryData(actionName, campaignSessionId) {
    return (dispatch) => {
        const telemetryData = {
            action: actionName,
            campaignSessionId
        };
        return SalesforceService.instance.sendTelemetry(telemetryData)
            .then((response) => {
                dispatch({
                    type: TELEMETRY_SUCCESS,
                    data: response.data
                });
            });
    };
}

export function sendNewTelemetryData(telemetryObject) {
    return (dispatch, getState) => {
        const state = getState();
        const accountData = state.user.accountDetails.data;
        let newTelemetryData = telemetryObject;

        if (accountData) {
            const userData = {
                id: accountData.id,
                name: accountData.firstName + accountData.lastName,
                email: accountData.username
            };

            newTelemetryData = Object.assign({}, userData, telemetryObject);
        }

        return SalesforceService.instance.sendNewTelemetryData(newTelemetryData);
    };
}
