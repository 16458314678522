const techs = [
    // HootSuite
    11470,

    // Informatica
    244,

    // MongoDB
    1178,

    // F5 Networks
    10448,

    // SAP Business Objects
    637,

    // Dassault Systemes
    4780,

    // LexisNexis
    1092,

    // AngularJS
    12735,

    // MySQL
    816,

    // PeopleSoft
    21,

    // Python
    10851,

    // Apache Hadoop
    1091
];

export default techs;

