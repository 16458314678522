export const ADDITIONAL_COMPANIES_REQUEST = 'ADDITIONAL_COMPANIES_REQUEST';
export const ADDITIONAL_COMPANIES_SUCCESS = 'ADDITIONAL_COMPANIES_SUCCESS';
export const ADDITIONAL_COMPANIES_FAILURE = 'ADDITIONAL_COMPANIES_FAILURE';
export const SHOW_ADDITIONAL_COMPANIES = 'SHOW_ADDITIONAL_COMPANIES';

export const MY_HG_NEW_SEGMENT_BTN = '+ New Segment';
export const MY_HG_ADDITIONAL_COMPANIES_TITLE = 'We found %1$s Additional Companies ' +
    'using products from Your Collections in the past';
export const MY_HG_ADDITIONAL_COMPANIES_SUBTITLE = 'with these %1$s companies';
export const DEFAULT_PERIOD = 6;
export const PERIOD_SELECTION_OPTIONS = [6, 3, 2, 1];
