import {
    SEGMENT_INDUSTRIES_DATA_REQUEST,
    SEGMENT_INDUSTRIES_DATA_SUCCESS,
    SEGMENT_INDUSTRIES_DATA_FAILURE,
    SEGMENT_INDUSTRIES_CLEAN_DATA
} from './constants';

export const industriesFirmographicInitialState = {
    isFetched: false,
    isFetching: false,
    error: null,
    data: null,
    cancelToken: null
};

export default function industriesFirmographic(state = industriesFirmographicInitialState, action) {
    switch (action.type) {
        case SEGMENT_INDUSTRIES_DATA_REQUEST:
            return Object.assign({}, state, {
                isFetched: false,
                isFetching: true,
                cancelToken: action.cancelToken,
                error: null
            });

        case SEGMENT_INDUSTRIES_DATA_SUCCESS:
            return Object.assign({}, state, {
                isFetched: true,
                isFetching: false,
                data: action.data,
                error: null,
                cancelToken: null
            });

        case SEGMENT_INDUSTRIES_DATA_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                cancelToken: null
            });

        case SEGMENT_INDUSTRIES_CLEAN_DATA: {
            if (state.cancelToken) {
                state.cancelToken.cancel();
            }
            return industriesFirmographicInitialState;
        }

        default:
            return state;
    }
}
