import React from 'react';
import LeftPageWizardContent from './components/LeftPageWizardContent';
import RightWizardPanel from './components/RightWizardPanel';

export default class PageWizardWrapper extends React.Component {

    render() {
        return (
            <div className="page-wizard-wrapper modal-container">
                <LeftPageWizardContent
                    onBack={ this.props.onBack }
                    content={ this.props.leftContent }
                />
                <RightWizardPanel
                    content={ this.props.rightContent }
                    onClose={ this.props.onClose }
                />
            </div>
        );
    }
}
