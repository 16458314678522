import React from 'react';
import classNames from 'classnames';

export default class SelectInput extends React.Component {

    onSelectInput() {
        this.props.onSelectInput();
    }

    getSelectPlaceholder() {
        let placeholder;

        if (this.props.selectedItem) {
            placeholder = this.props.selectedItem.text;
        } else {
            placeholder = (
                <span className="placeholder">
                    { this.props.placeholder }
                </span>
            );
        }

        return placeholder;
    }

    render() {
        const inputClassName = classNames('search-input',
            { open: this.props.isOpen }
        );
        const iconClassName = classNames('typeahead-icon', {
            'icon-small-arrow-up': this.props.isOpen,
            'icon-small-arrow-down': !this.props.isOpen
        });
        return (
            <div className={ inputClassName } onClick={ this.props.onSelectInput }>
                <div
                    className="select-input"
                    data-qa="input"
                >
                    { this.getSelectPlaceholder() }
                </div>

                <span className={ iconClassName }></span>
            </div>
        );
    }
}
