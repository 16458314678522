import React from 'react';
import { connect } from 'react-redux';
import { loadTechnologiesPermissions } from 'shared/user/technologiesPermissions/actions';
import TechnologyList from 'discovery/collection/components/TechnologyList';
import Loader from 'shared/ui/loader/Loader';
import withTaxonomy from 'shared/taxonomy/enhancer';
import MyProductsWrapper from './MyProductsWrapper';

function mapStateToProps(state) {
    return {
        technologiesPermissions: state.user.technologiesPermissions
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
class ViewMyProductsContainer extends React.Component {
    componentDidMount() {
        this.props.dispatch(loadTechnologiesPermissions());
    }

    getProductList() {
        const {
            findTaxonomyProductsForSlugs,
            technologiesPermissions: { slugs }
        } = this.props;

        const technologies = findTaxonomyProductsForSlugs(slugs);

        const alphabeticallySorted = technologies.sort((a, b) => {
            return a.product.localeCompare(b.product);
        });

        const technologiesChunks = [];
        const countsPerColumn = Math.ceil(alphabeticallySorted.length / 3);

        for (let i = 0; i < alphabeticallySorted.length; i += countsPerColumn) {
            technologiesChunks.push(alphabeticallySorted.slice(i, countsPerColumn + i));
        }

        const techSlides = technologiesChunks.map((techs, index) => {
            return (
                <div
                    className="col-lg-4"
                    key={ index }
                >
                    <TechnologyList technologies={ techs } />
                </div>
            );
        });

        return (
            <div className="collection-details">
                <div className="collection-details-products row">
                    { techSlides }
                </div>
            </div>
        );
    }

    getInnerContent() {
        let content;
        const { slugs, all } = this.props.technologiesPermissions;

        if (all) {
            content = (<h3 className="text-center"> You have access to all products! </h3>);
        } else if (slugs.length) {
            content = this.getProductList();
        } else {
            content = (<Loader />);
        }

        return content;
    }

    render() {
        return (
            <MyProductsWrapper>
                { this.getInnerContent() }
            </MyProductsWrapper>
        );
    }
}

export default withTaxonomy(ViewMyProductsContainer);
