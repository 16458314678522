import React from 'react';
import classNames from 'classnames';
import { Link, browserHistory } from 'react-router';
import keyboardSelectionHOC from './keyboardSelectionHOC';

@keyboardSelectionHOC
export default class CollectionSelection extends React.Component {

    getCollectionList() {
        const { items: { collections }, showAsLink } = this.props;

        return collections.map((collection, index) => {
            // Assemble class names
            const itemClassName = classNames('select-item clickable', {
                selected: this.props.selectedIndex === index
            });
            const iconClassName = collection.vendorUrl ?
                `fav-icon icon-${collection.vendorUrl}` : 'icon-category';

            return (
                <li
                    key={ index }
                    className={ itemClassName }
                    onClick={ this.collectionSelectHandler.bind(this, collection) }
                >
                    <span className="icon-circle favicon-wrapper">
                        <span className={ iconClassName } />
                    </span>
                    { this.getLineItemText(collection, showAsLink) }
                </li>
            );
        });
    }

    getLineItemText(collection, includeLink) {
        let lineItem = this.props.formatter(collection.name, this.props.textFilter);

        if (includeLink) {
            lineItem = (
                <Link
                    to={ `/collection/${collection.slug}` }
                >
                    { lineItem }
                </Link>
            );
        }

        return lineItem;
    }

    collectionSelectHandler(collection) {
        const { showAsLink } = this.props;

        if (showAsLink) {
            browserHistory.push(`/collection/${collection.slug}`);
        } else if (this.props.itemSelectHandler) {
            this.props.itemSelectHandler(collection);
        }
    }

    render() {
        let content = '';

        if (this.props.items.collections.length === 0) {
            content = (
                <div className="no-result-msg">
                    <p>
                        <span className="text">
                            No Results
                        </span>
                    </p>
                </div>
            );
        } else {
            content = (
                <div>
                    { this.getCollectionList() }
                </div>
            );
        }

        return content;
    }
}
