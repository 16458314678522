import React from 'react';
import classNames from 'classnames';

export default class CounterWrapper extends React.Component {

    getIcon() {
        let content = null;

        if (this.props.iconClass) {
            const iconClassNames = classNames('icon', this.props.iconClass);
            content = <span className={ iconClassNames } />;
        }

        return content;
    }

    render() {
        const { title, itemCounts, children, subTitle, isHeaderCounter } = this.props;
        const counterClassName = classNames('counter-wrapper', this.props.className, {
            header: isHeaderCounter
        });

        return (
            <div className={ counterClassName } >
                { title ? <span className="title">{ title }</span> : null }
                <div className="counts">
                    { this.getIcon() }
                    <span className="counts-value">
                        { itemCounts }
                    </span>
                    { children }
                </div>
                { subTitle ? <span className="title">{ subTitle }</span> : null }
            </div>
        );
    }
}
