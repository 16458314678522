import {
    SEGMENT_SUMMARY_MENU_NAME,
    SEGMENT_SET_MENU
} from './constants';
import { getMenuByName } from './actions';

export const segmentMenuInitialState = getMenuByName(SEGMENT_SUMMARY_MENU_NAME);

export default function segmentMenu(state = segmentMenuInitialState, action) {
    switch (action.type) {
        case SEGMENT_SET_MENU:
            return action.menu;

        default:
            return state;
    }
}
