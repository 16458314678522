import SegmentService from 'common/services/SegmentService';
import {
    ADDITIONAL_COMPANIES_REQUEST,
    ADDITIONAL_COMPANIES_SUCCESS,
    ADDITIONAL_COMPANIES_FAILURE,
    SHOW_ADDITIONAL_COMPANIES,
    DEFAULT_PERIOD
} from './constants';

export function loadAdditionalCompanies(period = DEFAULT_PERIOD) {
    return (dispatch) => {
        dispatch({
            type: ADDITIONAL_COMPANIES_REQUEST,
            period
        });

        return SegmentService.instance.getAdditionalCompaniesByPeriod(period)
            .then((response) => {
                dispatch({
                    type: ADDITIONAL_COMPANIES_SUCCESS,
                    data: response.data,
                    period
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADDITIONAL_COMPANIES_FAILURE,
                    error,
                    period
                });
            });
    };
}

export function showAdditionalCompanies(period = DEFAULT_PERIOD) {
    return {
        type: SHOW_ADDITIONAL_COMPANIES,
        period
    };
}
