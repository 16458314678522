import React from 'react';
import { Link } from 'react-router';
import { NumeralUtils } from 'utils/Utils';
import CounterWrapper from 'shared/ui/counter/CounterWrapper';
import Loader from 'shared/ui/loader/Loader';
import {
    MY_HG_TOP_HEADER_VIEW_LIST,
    MY_HG_TOP_HEADER_TITLE,
    MY_HG_TOP_HEADER_PRODUCTS_TITLE,
    MY_HG_TOP_HEADER_COMPANIES_TITLE,
    MY_HG_NEW_SEGMENT_BTN
} from '../constants';

export default class TopHeaderPaidUser extends React.Component {

    getViewListElement() {
        return (
            <Link className="view-list" to="/dashboard/my-products">
                <span className="icon icon-search" />
                { MY_HG_TOP_HEADER_VIEW_LIST }
            </Link>
        );
    }

    getCounters() {
        const {
            permissionCount: { loaded, data: { products, companies } }
        } = this.props;

        return (
            <div className="dashboard-counter" >
                <CounterWrapper
                    className="top-section"
                    iconClass="icon-product"
                    title={ MY_HG_TOP_HEADER_PRODUCTS_TITLE }
                    itemCounts=
                        { loaded ?
                            NumeralUtils.formatIntegerNumber(products) :
                            (<Loader className="white" />)
                        }
                    children={ this.getViewListElement() }
                />
                <CounterWrapper
                    className="top-section"
                    iconClass="icon-building"
                    title={ MY_HG_TOP_HEADER_COMPANIES_TITLE }
                    itemCounts=
                        { loaded ?
                            NumeralUtils.formatIntegerNumber(companies) :
                            (<Loader className="white" />)
                        }
                />
            </div>
        );
    }

    render() {
        const {
            boxMessage,
            allPermissions
        } = this.props;

        return (
            <div id="paid-summary-header" className="myhghome-top-header">
                <div className="box message">
                    { boxMessage || <h2>{ MY_HG_TOP_HEADER_TITLE }</h2> }
                </div>
                <div className="box button">
                    {
                        allPermissions ? null : this.getCounters()
                    }
                    <button
                        id="add-new-segment"
                        className="btn btn-primary"
                        onClick={ this.props.onNewSegmentClick }
                        data-qa="create-segment-button"
                    >
                        { MY_HG_NEW_SEGMENT_BTN }
                    </button>
                </div>
            </div>
        );
    }
}
