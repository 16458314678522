import { combineReducers } from 'redux';
import accounts from 'myhg/import/accounts/reducer';
import file from 'myhg/import/file/reducer';
import {
    IMPORT_SET_SEGMENT_ID
} from './constants';

export const importFlagsInitialState = {
    segmentId: null
};

export function importFlags(state = importFlagsInitialState, action) {
    switch (action.type) {
        case IMPORT_SET_SEGMENT_ID:
            return Object.assign({}, state, {
                segmentId: action.segmentId
            });

        default:
            return state;
    }
}

export default combineReducers({
    accounts,
    importFlags,
    file
});
