import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import ProductHeaderContainer from 'shared/header/ProductHeaderContainer';
import SegmentContainer from 'myhg/segment/SegmentContainer';
import loadFirmographicTaxonomy from 'shared/taxonomy/firmographic/actions';
import Footer from 'shared/footer/Footer';

function mapStateToProps(state) {
    return {
        firmographic: state.taxonomy.firmographic
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class ProductSegment extends React.Component {

    static needs = [
        loadFirmographicTaxonomy
    ];

    componentDidMount() {
        const {
            dispatch,
            firmographic
        } = this.props;

        if (!firmographic.data && !firmographic.isFetching) {
            dispatch(loadFirmographicTaxonomy());
        }
    }

    render() {
        const title = 'Segment';

        return (
            <div>
                <Helmet
                    htmlAttributes= { { lang: 'en' } }
                    title= { title }
                />
                <ProductHeaderContainer />
                <SegmentContainer { ...this.props } />
                { this.props.children }
                <Footer />
            </div>
        );
    }
}
