import { combineReducers } from 'redux';
import {
    technologies,
    matchAllProducts
} from './technologiesFilter/reducer';
import collections from './collectionsFilter/reducer';
import employees from './employeesFilter/reducer';
import revenue from './revenueFilter/reducer';
import industries from './industriesFilter/reducer';
import locations from './locationsFilter/reducer';
import signalScores from './signalScores/reducer';
import lastCheck from './lastCheckFilter/reducer';
import includeUnknownFirmos from './unknownValuesFilter/reducer';
import {
    SEGMENT_ADVANCED_FILTERS_SET_VISIBILITY,
    SEGMENT_ADVANCED_FILTERS_CLEAR,
    ADVANCED_FILTERS_SET_ACTIVE_TIME_FRAME,
    USAGE_TIME_FRAME_SIGNAL_SCORE
} from './constants';

export const segmentFiltersViewInitialState = {
    isAdvancedOpen: false,
    activeTimeFrame: USAGE_TIME_FRAME_SIGNAL_SCORE
};

export function segmentFiltersView(state = segmentFiltersViewInitialState, action) {
    switch (action.type) {
        case SEGMENT_ADVANCED_FILTERS_SET_VISIBILITY:
            return Object.assign({}, state, { isAdvancedOpen: action.value });

        case ADVANCED_FILTERS_SET_ACTIVE_TIME_FRAME:
            return Object.assign({}, state, { activeTimeFrame: action.activeTimeFrame });

        case SEGMENT_ADVANCED_FILTERS_CLEAR:
            return segmentFiltersViewInitialState;

        default:
            return state;
    }
}

export default combineReducers({
    technologies,
    collections,
    matchAllProducts,
    industries,
    locations,
    employees,
    revenue,
    signalScores,
    lastCheck,
    includeUnknownFirmos,
    segmentFiltersView
});
