import React from 'react';
import classNames from 'classnames';
import { track } from 'utils/Utils';

export default class TabMenu extends React.Component {

    itemMenuChanged(menuItem) {
        if (this.props.selectedMenu.name !== menuItem.name) {
            this.props.menuItemChanged(menuItem);
        }
        if (menuItem.trackAction) {
            track(menuItem.trackAction, { label: menuItem.trackWhere });
        }
    }

    render() {
        const items = this.props.menus.map((item, index) => {
            const className = classNames('tab-item',
                { selected: this.props.selectedMenu.name === item.name }
            );
            const classNameIcons = classNames('tab-icons', item.icon);
            const icon = <span className={ classNameIcons }></span>;

            return (
                <a
                    className={ className }
                    key={ index }
                    onClick={ this.itemMenuChanged.bind(this, item) }
                >
                    { item.icon ? icon : null }
                    <span
                        className="tab-title"
                        data-qa={ `${item.displayName.replace(/ /g, '-').toLowerCase()}-tab` }
                    >
                        { item.displayName }
                    </span>
                    <span className="triangle"></span>
                </a>
            );
        });

        const className = classNames('tab-menu no-selection', this.props.className);
        return (
            <div className={ className }>
                { items }
            </div>
        );
    }
}
