import React from 'react';
import { Link } from 'react-router';

export default class ResetPasswordSuccessContent extends React.Component {

    getSuccessContent() {
        return (
            <div className="modal-form">
                <p className="text-content light-title">
                    Your password was changed.
                </p>
                <div className="inputs-container">
                    <Link
                        id="reset-password-signin"
                        className="btn btn-primary"
                        to="/login"
                    >
                        Please Log In
                    </Link>
                </div>
            </div>
        );
    }

    openAuthModal(path) {
        this.props.openAuthModal(path);
    }

    render() {
        return this.getSuccessContent();
    }
}
