import React from 'react';
import { connect } from 'react-redux';
import { loadTechnologiesPermissions } from 'shared/user/technologiesPermissions/actions';
import TechnologiesFilter from './components/TechnologiesFilter';
import UserUtils from 'utils/UserUtils';
import {
    addTechnology,
    removeTechnology,
    clearTechnologies
} from './actions';
import { loadCollectionById } from 'discovery/collection/actions';
import {
    addCollection,
    removeCollection
} from 'myhg/segment/segmentFilters/collectionsFilter/actions';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        accountDetails: state.user.accountDetails.data,
        authData: state.authentication.authData,
        technologiesPermissions: state.user.technologiesPermissions,
        technologies: segment.segmentFilters.technologies,
        collections: segment.segmentFilters.collections,
        matchAllProducts: segment.segmentFilters.matchAllProducts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class TechnologiesFilterContainer extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;
        if (this.isUserAuthenticated()) {
            dispatch(loadTechnologiesPermissions());
        }
    }


    onTechnologyUnSelect = (technology) => {
        const { dispatch } = this.props;
        dispatch(removeTechnology(technology.slug));
    }

    onCollectionUnSelect = (collection) => {
        const { dispatch } = this.props;
        dispatch(removeCollection(collection));
    }

    onTechnologySelect = (technology) => {
        const { dispatch } = this.props;

        dispatch(addTechnology(technology.slug));
    }

    onCollectionSelect = (collection) => {
        const { dispatch } = this.props;

        dispatch(loadCollectionById(collection.slug))
            .then((actionResult) => {
                let slugs = actionResult.data.productSlugs;
                const permissionedSlugs = this.getAllowedSlugs();
                if (!!permissionedSlugs) {
                    // get only permissioned slugs from the collection
                    const permissionedSlugsSet = new Set(permissionedSlugs);
                    slugs = [...new Set(slugs)].filter(x => {
                        return permissionedSlugsSet.has(x);
                    });
                }
                // dispatch(addTechnologies(slugs));
                dispatch(addCollection({
                    slug: collection.slug,
                    name: collection.name,
                    vendorUrl: collection.vendorUrl,
                    collectionType: collection.collectionType,
                    techSlugs: slugs
                }));
            });
    }

    onMultipleTechnologiesSelect = (technologies) => {
        technologies.forEach(technology => {
            this.onTechnologySelect(technology);
        });
    }

    getAllowedSlugs() {
        const { accountDetails, technologiesPermissions } = this.props;

        return UserUtils.getAllowedSlugs(accountDetails, technologiesPermissions);
    }

    clearAllTechnologies = () => {
        const { dispatch } = this.props;
        dispatch(clearTechnologies());
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    render() {
        const { accountDetails } = this.props;
        const technologiesPermissionsLoaded = this.props.technologiesPermissions.fetched;
        if (!technologiesPermissionsLoaded && UserUtils.isPaid(accountDetails)) {
            return null;
        }

        return (
            <TechnologiesFilter
                { ...this.props }
                isPaidUser={ UserUtils.isPaid(accountDetails) }
                onTechnologySelect={ this.onTechnologySelect }
                onTechnologyUnSelect={ this.onTechnologyUnSelect }
                onCollectionSelect={ this.onCollectionSelect }
                onCollectionUnSelect={ this.onCollectionUnSelect }
                onMultipleTechnologiesSelect={ this.onMultipleTechnologiesSelect }
                allowedSlugs={ this.getAllowedSlugs() }
                clearAllTechnologies={ this.clearAllTechnologies }
            />
        );
    }
}
