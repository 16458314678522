import React from 'react';
import { connect } from 'react-redux';
import Loader from 'shared/ui/loader/Loader';
import MarketoConnect from './components/MarketoConnect';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import {
    MARKETO_CONNECT_PAGE_TITLE
} from './constants';
import {
    validatePlatformToken
} from 'myhg/platformConnection/actions';
import { PLATFORM_SOURCE } from 'shared/constants';
import segmentEnforcer from 'myhg/segment/segmentEnforcer';

function mapStateToProps(state) {
    return {
        segmentId: state.myhg.segment.currentSegment.id,
        authData: state.authentication.authData,
        platformToken: state.myhg.platformConnection.platformToken,
        marketoConnect: state.myhg.platformConnection.marketoConnect,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@segmentEnforcer
@connect(mapStateToProps, mapDispatchToProps)

// TODO : marketo will relies on salesforce existing apis (but without oauth)
// we need to update salesforce naming to cloud elements and parameterize source type only
export default class MarketoConnectContainer extends React.Component {

    componentDidMount() {
        // always validate the token
        this.props.dispatch(validatePlatformToken());
    }

    render() {
        let content = <Loader />;

        const checkTokenData = this.props.platformToken.data;
        const { authData } = this.props;

        const customHeaderTitle = (
            <span>
                { MARKETO_CONNECT_PAGE_TITLE }
                <span className={ `platform-title-logo ${PLATFORM_SOURCE.MARKETO}-img` } />
            </span>
        );

        if (checkTokenData) {
            const isValidConnection = !!checkTokenData.valid &&
                authData.platformSource === PLATFORM_SOURCE.MARKETO;
            content = (
                <MarketoConnect
                    { ...this.props }
                    requestError={ this.props.marketoConnect.error }
                    isValidConnection={ isValidConnection }
                />
            );
        }

        return (
            <ImportPageContainerWrapper
                title={ MARKETO_CONNECT_PAGE_TITLE }
                customHeader={ customHeaderTitle }
            >
                { content }
            </ImportPageContainerWrapper>
        );
    }
}
