import {
    SEGMENT_REVENUE_DATA_REQUEST,
    SEGMENT_REVENUE_DATA_SUCCESS,
    SEGMENT_REVENUE_DATA_FAILURE,
    SEGMENT_REVENUE_CLEAN_DATA
} from './constants';

export const revenueFirmographicInitialState = {
    isFetched: false,
    isFetching: false,
    error: null,
    data: null,
    cancelToken: null
};

export default function revenueFirmographic(state = revenueFirmographicInitialState, action) {
    switch (action.type) {
        case SEGMENT_REVENUE_DATA_REQUEST:
            return Object.assign({}, state, {
                isFetched: false,
                isFetching: true,
                cancelToken: action.cancelToken,
                error: null
            });

        case SEGMENT_REVENUE_DATA_SUCCESS:
            return Object.assign({}, state, {
                isFetched: true,
                isFetching: false,
                data: action.data,
                error: null,
                cancelToken: null
            });

        case SEGMENT_REVENUE_DATA_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                cancelToken: null
            });

        case SEGMENT_REVENUE_CLEAN_DATA: {
            if (state.cancelToken) {
                state.cancelToken.cancel();
            }
            return revenueFirmographicInitialState;
        }

        default:
            return state;
    }
}
