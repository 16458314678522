import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton class that encapsulates marketo related API calls
 */
export default class MarketoService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new MarketoService(singletonEnforcer);
        }
        return this[singleton];
    }

    connect(connectData, campaignSessionId) {
        return ApiWrapper.instance.axios.get(
            'marketo/access-token', {
                params: {
                    ...connectData
                },
                headers: {
                    campaignSessionId
                },
                timeout: 60 * 1000
            }
        );
    }
}
