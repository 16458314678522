import React from 'react';
import classNames from 'classnames';
import { browserHistory } from 'react-router';
import sprintf from 'sprintf-js';
import LimitReachedModalContainer from 'myhg/segment/LimitReachedModalContainer';
import DownloadLimitModal from 'myhg/segment/components/DownloadLimitModal';
import UserUtils from 'utils/UserUtils';
import CrmStatusLoader from './CrmStatusLoader';
import { sendSalesforceTelemetryData } from 'shared/telemetry/actions';
import { downloadAsCSV, copySegment } from 'myhg/segment/actions';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import Loader from 'shared/ui/loader/Loader';
import SegmentContactUS from 'myhg/segment/components/SegmentContactUS';
import {
    SEGMENT_STATUS,
    MATCH_SOURCE_TYPE,
    MATCH_SOURCE_NAMES
} from 'myhg/segment/constants';
import {
    SEGMENT_CRM_DOWNLOAD_SAMPLE,
    SEGMENT_CRM_DOWNLOAD_CSV,
    SEGMENT_CRM_CAMPAIGM_NAME,
    SEGMENT_CRM_UPLOAD_FILE,
    SEGMENT_CRM_PLATFORM_ACCOUNT,
    SEGMENT_CRM_END_TRIAL_DOWNLOAD_BUTTON
} from '../constants';
import {
    SEGMENT_EXPORT_TEXTS,
    NO_MATCHES_TEXTS
} from './constants';
import { PERMISSION_TYPE } from 'shared/user/permissions/constants';
import {
    CLICK_CREATE_CAMPAIGN
} from 'shared/telemetry/constants';
import { SEGMENT_DOWNLOAD_TOOLTIP } from '../../segmentTable/constants';

export default class SegmentSourceDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showLimitReachedModal: false,
            modalOpen: false
        };
    }

    onCopy = () => {
        const { dispatch } = this.props;
        dispatch(copySegment());
        browserHistory.push('/dashboard/segment/new');
    }

    onContinueDownload = () => {
        const { dispatch } = this.props;

        dispatch(downloadAsCSV());

        track(ACTION.EXPORT_SEGMENT_CSV, {
            label: this.props.currentSegment.name
        });

        this.closeModal();
    }

    onCSVExport = () => {
        const { downloadLimit } = this.props;

        if (downloadLimit >= 0) {
            this.setState({ modalOpen: true });
        } else {
            this.onContinueDownload();
        }
    }

    onLimitOkClick = () => {
        this.setState({
            showLimitReachedModal: false
        });
    }

    getTextSection(icon, title, source = null, campaign = null) {
        const cssClass = classNames('match-source', {
            'campaign-name': !icon
        });
        const campaignContent = campaign ?
            <span className="match-campaign"> { campaign } </span> :
            null;
        const sourceContent = source ?
            <span className={ cssClass }> { source } </span> : null;
        const iconContent = icon ? <span className= { icon } /> : null;

        return (
            <div>
                { iconContent }
                { source ?
                    (
                        <span className="high-light">
                            <span className="source-title">{ title }</span>
                            { sourceContent }
                            { campaignContent }
                        </span>
                    ) :
                    null
                }
            </div>
        );
    }

    getExportedStatusContent(campaignName, externalLink) {
        return (
            <span className="high-light">
                <span className="source-title">
                    { SEGMENT_CRM_CAMPAIGM_NAME }
                </span>
                <span className="match-source campaign-name">
                    { externalLink ? (
                        <a
                            target="_blank"
                            href={ externalLink }
                        >
                            { campaignName }
                        </a>
                    ) : campaignName }
                </span>
            </span>
        );
    }

    getBuildCampaignButton() {
        const {
            accountDetails,
            currentSegment,
            netNewMatches,
            isPlatformTypeAndNotConnected,
            isPlatformTypeAndOtherAccount
        } = this.props;
        const { data: { status: { source } } } = currentSegment;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        const isPaidUser = UserUtils.isPaid(accountDetails);
        let content = null;

        if (campaignTrialInfo.isActive && campaignTrialInfo.remainingDays === 0 && !isPaidUser) {
            content = (
                <SegmentContactUS
                    isButton
                    elementId="end-trial-campaign"
                    linkLabel={ SEGMENT_EXPORT_TEXTS.EXPORT_CAMPAIGN_TRIAL_EXPIRED[source] }
                    cssClass="btn btn-primary"
                    { ...this.props }
                />
            );
        } else if (netNewMatches.isFetching) {
            content = <CrmStatusLoader />;
        } else if (isPlatformTypeAndNotConnected || isPlatformTypeAndOtherAccount) {
            content = null;
        } else if (netNewMatches.data && netNewMatches.data.matchedCompanies === 0) {
            content = (
                <div className="col-xl-6 review-your-segment">
                    <span>
                        { NO_MATCHES_TEXTS.title } <a
                            className="default-link" onClick={ this.props.onReviewFilters }
                        >
                            { NO_MATCHES_TEXTS.reviewLink }
                        </a>
                    </span>
                </div>
            );
        } else {
            content = (
                <button
                    id="sample-campaign"
                    className="btn btn-primary"
                    onClick={ this.handleCampaignClick }
                    data-qa="build-campaign"
                >
                    { isPaidUser ?
                        SEGMENT_EXPORT_TEXTS.EXPORT_CAMPAIGN_PAID_USER[source] :
                        SEGMENT_EXPORT_TEXTS.EXPORT_CAMPAIGN[source] }
                </button>
            );
        }

        return content;
    }

    getDownloadSampleButton() {
        const { accountDetails, matchesDataLoad, segmentExport } = this.props;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        const isPaidUser = UserUtils.isPaid(accountDetails);
        const isDisabled = !matchesDataLoad.data.length || !accountDetails.downloadEnabled;

        if (campaignTrialInfo.isActive && !isPaidUser) {
            return (
                <SegmentContactUS
                    isButton
                    elementId="end-trial-download"
                    linkLabel={ SEGMENT_CRM_END_TRIAL_DOWNLOAD_BUTTON }
                    cssClass="btn btn-primary"
                    { ...this.props }
                />
            );
        }

        const showTooltip = !accountDetails.downloadEnabled;
        const tooltip = showTooltip ? SEGMENT_DOWNLOAD_TOOLTIP : '';

        return (
            <span>
                <button
                    id="sample-download"
                    className="btn btn-primary"
                    onClick = { this.onCSVExport }
                    disabled= { isDisabled || segmentExport.inProgress }
                    data-toggle="tooltip"
                    data-placement="top"
                    title ={ tooltip }
                >
                    { isPaidUser ? SEGMENT_CRM_DOWNLOAD_CSV : SEGMENT_CRM_DOWNLOAD_SAMPLE }
                    { segmentExport.inProgress ? <Loader className="small-loader white" /> : null }
                </button>
                { this.props.downloadLimit >= 0 ? (
                    <DownloadLimitModal
                        { ...this.props }
                        open={ this.state.modalOpen }
                        onPrimaryActionClick={ this.onContinueDownload }
                        onCloseActionClick = { this.closeModal }
                    />
                ) : null }
            </span>
        );
    }

    getMatchedStatus() {
        const { source, sourceValue, fileName, campaign } = this.props.status;
        let content = null;

        if (!source) {
            return content;
        }

        switch (source) {
            case MATCH_SOURCE_TYPE.FILE:
                content = (
                    <div className="col-xl-6">
                        { this.getTextSection(
                            'icon-check-list',
                            SEGMENT_CRM_UPLOAD_FILE,
                            fileName)
                        }
                    </div>);
                break;

            default: {
                // any other platform sources
                const sourceName = MATCH_SOURCE_NAMES[source].toLowerCase();
                const titleAccount = sprintf.sprintf(
                    SEGMENT_CRM_PLATFORM_ACCOUNT,
                    sourceName);

                content = (
                    <div className="col-xl-6">
                        { this.getTextSection(
                            `segment-platform-logo ${sourceName}-img`,
                            titleAccount,
                            sourceValue,
                            campaign)
                        }
                    </div>);
                break;
            }
        }

        return content;
    }

    getExportedStatus() {
        const { source, campaignName, externalCampaignLink } = this.props.status;
        let content;

        switch (source) {
            case MATCH_SOURCE_TYPE.FILE:
                content = this.getDownloadSampleButton();
                break;

            default:
                content = this.props.status.status === SEGMENT_STATUS.EXPORTED ?
                    this.getExportedStatusContent(campaignName, externalCampaignLink) :
                    this.getBuildCampaignButton();
                break;
        }

        return content;
    }

    getStatus() {
        const { status } = this.props.status;
        let content;
        switch (status) {
            case SEGMENT_STATUS.MATCHED:
            case SEGMENT_STATUS.EXPORTED:
                content = (<div className="row">
                    { this.getMatchedStatus() }
                    { this.getExportedStatus() }
                </div>);
                break;

            default:
                content = null;
                break;
        }

        return content;
    }

    closeModal = () => {
        this.setState({ modalOpen: false });
    }

    handleCampaignClick = () => {
        const { dispatch, segment, isUserAuthenticated } = this.props;

        if (!isUserAuthenticated) {
            // user required to register
            browserHistory.push('/register');
        // TODO: limit api should not be used anymore to check for limits reached
        // BUT IS THERE ANYTHING TO CHECK AGAINST ???
        // } else if (!this.isAbleToCreateCampaign()) {
        //     // limited access, show modal with limit reached
        //     this.setState({
        //         showLimitReachedModal: true
        //     });
        } else {
            dispatch(sendSalesforceTelemetryData(
                CLICK_CREATE_CAMPAIGN, segment.currentSegment.id));
            browserHistory.push('/dashboard/export');
        }
    }

    isAbleToCreateCampaign() {
        const { permissions } = this.props;
        const campaignPermission = permissions ?
            permissions[PERMISSION_TYPE.CREATE_CAMPAIGN] : null;

        return campaignPermission && (
            campaignPermission.isUnlimited || campaignPermission.remaining > 0);
    }

    render() {
        return (
            <div className="segment-status">
                { this.getStatus() }
                { this.state.showLimitReachedModal ? (
                    <LimitReachedModalContainer
                        onLimitOkClick={ this.onLimitOkClick }
                    />
                ) : null }
            </div>
        );
    }
}
