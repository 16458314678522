import {
    FIRMOGRAPHIC_TAXONOMY_REQUEST,
    FIRMOGRAPHIC_TAXONOMY_SUCCESS,
    FIRMOGRAPHIC_TAXONOMY_FAILURE
} from './constants';

export const firmographicInitialState = {
    isFetching: false,
    error: null,
    data: null
};

export default function firmographic(state = firmographicInitialState, action) {
    switch (action.type) {
        case FIRMOGRAPHIC_TAXONOMY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case FIRMOGRAPHIC_TAXONOMY_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null
            });

        case FIRMOGRAPHIC_TAXONOMY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
}
