import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import LogInForm from './LogInForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import {
    resetPasswordResetRequest,
    passwordResetRequest,
    login
} from './actions';
import { ACTION, WHERE } from 'utils/track-constants';
import { track } from 'utils/Utils';

function mapStateToProps(state) {
    return {
        login: state.authentication.logIn,
        resetPassword: state.authentication.resetPassword,
        authData: state.authentication.authData
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class LogInContainer extends React.Component {

    static contextTypes = {
        router: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            form: 'login'
        };
    }

    componentWillUnmount() {
        return this.props.dispatch(
            resetPasswordResetRequest()
        );
    }

    onLoginFormChange(form) {
        this.setState({ form });
    }

    onForgotPasswordSubmit(data) {
        return this.props.dispatch(
            passwordResetRequest(data.email)
        );
    }

    onLoginSubmit(loginData) {
        return this.props.dispatch(
            login(loginData)
        );
    }

    openSignUpModal() {
        track(ACTION.SIGN_UP, { label: WHERE.PRELOGIN_LIGHTBOX });
    }

    render() {
        let content = null;
        if (this.state.form === 'login') {
            content = (
                <LogInForm
                    onLoginSubmit={ this.onLoginSubmit.bind(this) }
                    submitting={ this.props.login.submitting }
                    openSignUpModal={ this.openSignUpModal.bind(this) }
                    requestError={ this.props.login.error }
                    onLoginFormChange={ this.onLoginFormChange.bind(this) }
                    {...this.props}
                />
            );
        } else {
            content = (
                <ForgotPasswordForm
                    openSignUpModal={ this.openSignUpModal.bind(this) }
                    onForgotPasswordSubmit={ this.onForgotPasswordSubmit.bind(this) }
                    requestError={ this.props.resetPassword.error }
                    requestSuccess={ this.props.resetPassword.success }
                    resetToken={ this.props.resetPassword.data }
                    onLoginFormChange={ this.onLoginFormChange.bind(this) }
                />
            );
        }

        return content;
    }
}
