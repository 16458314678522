import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import Loader from 'shared/ui/loader/Loader';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import OAuthError from './OAuthError';
import authorizeOAuthHandler from 'shared/oAuth/authorizeOAuthHandler';
import { UrlUtils } from 'utils/Utils';
import { loadSegment } from 'myhg/segment/actions';

function mapStateToProps(state) {
    return {
        authData: state.authentication.authData
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@authorizeOAuthHandler
@connect(mapStateToProps, mapDispatchToProps)

export default class OAuthorizeContainer extends React.Component {

    componentDidMount() {
        const { state } = this.props.location.query;
        const { dispatch } = this.props;

        if (state) {
            const stateObject = UrlUtils.decodeQueryParamObject(state);
            dispatch(loadSegment(stateObject.segmentId));
        }
        // this should handle the case when back button is pressed
        // don't request for another token access as user is already authenticated
        if (this.props.isAuthenticated) {
            browserHistory.push('/dashboard');
        }
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated } = this.props;

        if (!prevProps.isAuthenticated && isAuthenticated) {
            browserHistory.push(`/dashboard/import/${this.getPlatform()}`);
        }
    }

    getPlatform() {
        const { state } = this.props.location.query;
        const stateObject = UrlUtils.decodeQueryParamObject(state);

        return stateObject.platform;
    }

    getState() {
        const { state } = this.props.location.query;
        return UrlUtils.decodeQueryParamObject(state);
    }

    render() {
        const errorData = this.props.getErrorData();
        // if there is an errorcCode - return error content content
        // if there is an authcode returned, redirect to next screen
        let content = null;
        if (this.props.oAuthConnect.submitting && !errorData) {
            content = <Loader />;
        } else if (errorData) {
            content = (
                <ImportPageContainerWrapper
                    title="Error"
                >
                    <OAuthError
                        { ...this.props }
                        state={ this.getState() }
                        error={ errorData }
                    />
                </ImportPageContainerWrapper>
            );
        }

        return content;
    }
}
