import React from 'react';
import ReactDOM from 'react-dom';
import StackedBarChart from 'shared/ui/charts/StackedBarChart';
import FirmographicUtils from 'utils/FirmographicUtils';
import { NumeralUtils } from 'utils/Utils';
import {
    TIP_DATA_TYPE_NETNEW_TEXT,
    TIP_DATA_TYPE_MATCHED_TEXT,
    TIP_DATA_TYPE_ALL_TEXT
} from '../constants';
import {
    denormalizeNetNewMatchesData
} from '../reducer';

/**
 * Component that renders the RevenueNetNewChart Chart
 */
export default class RevenueNetNewChart extends React.Component {

    componentDidMount() {
        this.barChart = new StackedBarChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        this.barChart.update(this.getChartState());
    }

    componentWillUnmount() {
        this.barChart.destroy(this.getDOMNode());
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getChartState() {
        const { data, revenueFrom, revenueTo } = this.props;
        const denData = denormalizeNetNewMatchesData(data);
        const mergedData = denData.netNew.map((netNew, index) => {
            return {
                from: netNew.from,
                to: netNew.to,
                value: netNew.value + denData.matched[index].value
            };
        });

        const isCurrency = true;
        const companiesNetNewData = FirmographicUtils.getStackedBarData(
            denData.netNew, true, revenueFrom, revenueTo, isCurrency);
        const companiesMatchedData = FirmographicUtils.getStackedBarData(
            denData.matched, true, revenueFrom, revenueTo, isCurrency);
        const companiesMergedData = FirmographicUtils.getStackedBarData(
            mergedData, false, revenueFrom, revenueTo, isCurrency);

        const stackedDataFormat = [{
            type: 'matched',
            values: companiesMatchedData
        }, {
            type: 'netNew',
            values: companiesNetNewData
        }, {
            type: 'overall',
            values: companiesMergedData
        }];

        const tooltipDataLabels = {
            netNew: TIP_DATA_TYPE_NETNEW_TEXT,
            matched: TIP_DATA_TYPE_MATCHED_TEXT,
            overall: TIP_DATA_TYPE_ALL_TEXT
        };

        return {
            data: stackedDataFormat,
            dataX: 'name',
            dataY: 'value',
            tooltipData: (d) => {
                const range = d.name.indexOf('-') < 0 ? '' : 'between';
                return `<span>Number of ${tooltipDataLabels[d.type]} companies with</br>
                    revenue ${range} ${d.name}</span></br>
                    <span class='high-light'>${NumeralUtils.formatIntegerNumber(d.value)}</span>`;
            }
        };
    }

    render() {
        return (
            <div
                id="revenue-net-new-bar-chart"
                className="stacked-bar-chart stacked-bar-chart-firmographic"
            />
        );
    }
}
