import {
    POPULAR_TECHNOLOGIES_REQUEST,
    POPULAR_TECHNOLOGIES_SUCCESS,
    POPULAR_TECHNOLOGIES_FAILURE,
    POPULAR_TECHNOLOGIES_LIST
} from './constants';


// @todo: reset to empty once we havea api support for getting specific technologies
// using harcoded data for now
export const popularTechnologiesInitialState = {
    isFetching: false,
    error: null,
    data: []
};

export function sortTechs(techs) {
    const slugList = POPULAR_TECHNOLOGIES_LIST;
    const result = [];
    slugList.forEach((slug) => {
        const techToAdd = techs.find((technology) => {
            return technology.slug === slug;
        });
        result.push(techToAdd);
    });

    return result;
}

/**
 * Fetches list of popular technologies.
 * @param {object} state Current state for reducer.
 * @param {object} action Action requested.
 * @returns {state} Reduced state for request, success or failure action types.
 */
export default function trendingTechnologies(state = popularTechnologiesInitialState, action) {
    switch (action.type) {
        case POPULAR_TECHNOLOGIES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case POPULAR_TECHNOLOGIES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: sortTechs(action.data),
                error: null
            });

        case POPULAR_TECHNOLOGIES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
}
