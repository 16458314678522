import {
    LINKEDIN_ACCESS_TOKEN_REQUEST,
    LINKEDIN_ACCESS_TOKEN_SUCCESS,
    LINKEDIN_ACCESS_TOKEN_FAILURE,
    LINKEDIN_ACCOUNTS_REQUEST,
    LINKEDIN_ACCOUNTS_SUCCESS,
    LINKEDIN_ACCOUNTS_FAILURE,
    LINKEDIN_MATCHED_URLS_REQUEST,
    LINKEDIN_MATCHED_URLS_SUCCESS,
    LINKEDIN_MATCHED_URLS_FAILURE
} from './constants';
import OverlordService from 'common/services/OverlordService';

export function linkedInConnect(accessCode, segmentId) {
    return (dispatch) => {
        dispatch({
            type: LINKEDIN_ACCESS_TOKEN_REQUEST
        });

        const redirectUri = `${process.env.LINKED_IN_REDIRECT_URL}?segmentId=${segmentId}`;

        return OverlordService.instance.getLinkedInAccessToken(redirectUri, accessCode)
            .then((response) => {
                dispatch({
                    type: LINKEDIN_ACCESS_TOKEN_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: LINKEDIN_ACCESS_TOKEN_FAILURE,
                    error
                });

                throw error;
            });
    };
}

export function getAccounts(accessToken) {
    return (dispatch) => {
        dispatch({
            type: LINKEDIN_ACCOUNTS_REQUEST
        });

        return OverlordService.instance.getAccounts(accessToken)
            .then((response) => {
                dispatch({
                    type: LINKEDIN_ACCOUNTS_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: LINKEDIN_ACCOUNTS_FAILURE,
                    error
                });

                throw error;
            });
    };
}

export function matchUrlsToAccount(name, accountLocation, segment, accessToken) {
    return (dispatch) => {
        dispatch({
            type: LINKEDIN_MATCHED_URLS_REQUEST
        });

        return OverlordService.instance.matchUrls(name, accountLocation, segment, accessToken)
            .then((response) => {
                dispatch({
                    type: LINKEDIN_MATCHED_URLS_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: LINKEDIN_MATCHED_URLS_FAILURE,
                    error
                });

                throw error;
            });
    };
}
