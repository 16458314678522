// SEARCH PRODUCT ACTION TYPES
export const OPEN_SEARCH_PRODUCT = 'OPEN_SEARCH_PRODUCT';
export const CLOSE_SEARCH_PRODUCT = 'CLOSE_SEARCH_PRODUCT';
export const SELECT_SEARCH_COLLECTION = 'SELECT_SEARCH_COLLECTION';
export const FILTER_SEARCH_PRODUCT = 'FILTER_SEARCH_PRODUCT';

// SUGGEST PRODUCT ACTION ASYNC TYPES
export const SUGGEST_PRODUCT_REQUEST = 'SUGGEST_PRODUCT_REQUEST';
export const SUGGEST_PRODUCT_SUCCESS = 'SUGGEST_PRODUCT_SUCCESS';
export const SUGGEST_PRODUCT_FAILURE = 'SUGGEST_PRODUCT_FAILURE';
export const SUGGEST_PRODUCT_RESET = 'SUGGEST_PRODUCT_RESET';

// SEARCH COMPONENT SOURCES
export const DOWNLOAD_SAMPLE = 'downloadSample';
export const PRODUCTS_HEADER = 'productsHeader';
export const HOME_TOP_BANNER = 'homeTopBanner';
export const DISCOVER_NEW_LEADS = 'discoverNewLeads';

// SEARCH COMPONENT TEXTS
export const SEARCH_PLACEHOLDER_TEXT =
    'Search Any Technology Product';

export const SEARCH_LIST_LIMIT = 10;

export const PRODUCT_PAID_MY_SEGMENTS = 'My Segments';
export const PRODUCT_PAID_MY_DASHBOARD = 'My Dashboard';
export const SEARCH_PRODUCT_UNTRACKED = 'Sorry, we aren\'t tracking "%1$s"';
export const PRODUCT_ALREADY_SELECTED = 'Product "%1$s" is already selected';

export const SEARCHLIST_PRODUCT_TITLE = 'TECHNOLOGY PRODUCTS';
export const SEARCHLIST_COLLECTION_TITLE = 'PRODUCT CATEGORIES';
