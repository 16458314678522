import { combineReducers } from 'redux';
import {
    SEGMENTS_REQUEST,
    SEGMENTS_SUCCESS,
    SEGMENTS_FAILURE
} from './constants';

export const segmentsInitialState = {
    error: null,
    isFetching: true,
    data: []
};

export function allSegments(state = segmentsInitialState, action) {
    switch (action.type) {
        case SEGMENTS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case SEGMENTS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.reverse(),
                error: null
            });

        case SEGMENTS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
}

export default combineReducers({
    allSegments
});
