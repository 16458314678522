import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import SearchSelection from './components/SearchSelection';
import SearchInput from './components/SearchInput';
import {
    openSearchProduct,
    searchProductByFilter,
    closeSearchProduct,
    resetTechnologySuggest,
    createSuggestTechnologyEvent,
    setSelectedCollection
} from './actions';
import {
    getSortedFilteredTechnologiesByInstalls,
    getSortedFilteredCollections
} from './reducer';

function mapStateToProps(state) {
    return {
        search: state.search,
        isFetching: state.taxonomy.products.isFetching,
        technologies: state.taxonomy.products.data,
        collections: state.taxonomy.collections.data
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onSearchTextChange: (text, source) => {
            dispatch(setSelectedCollection(source, null));
            if (text) {
                dispatch(openSearchProduct(source));
                dispatch(searchProductByFilter(text));
            }
            if (!text) {
                dispatch(closeSearchProduct(source));
            }
            dispatch(resetTechnologySuggest());
        },
        onSuggest: (technologyName) => {
            dispatch(createSuggestTechnologyEvent(technologyName));
        },
        dispatch
    };
}

// [TODO] Currently this component is used to search for navigation and/or selection.
// The implementation is to rigid now that it needs to handle collections searches.
// Redesign it to better handle multiple columns/data searches when time allows.
class SearchContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputSelection: null
        };
    }

    componentWillUnmount() {
        this.props.dispatch(closeSearchProduct(this.props.source));
        this.props.dispatch(searchProductByFilter(''));
    }

    onSearchTextChange(text) {
        this.props.onSearchTextChange(text, this.props.source);
    }

    onSetSelectedCollection = (collection) => {
        const { dispatch, source } = this.props;
        dispatch(setSelectedCollection(source, collection));
    }

    onClearInputSelection = () => {
        const { dispatch, source } = this.props;
        dispatch(setSelectedCollection(source, null));
    }

    getItems() {
        const { technologies, collections, search, allowedSlugs, isHybrid } = this.props;
        const technologiesSearch = getSortedFilteredTechnologiesByInstalls({
            technologies,
            excludeTechnologies: (this.props.excludeTechnologies || []),
            filterText: search.currentFilter
        }, allowedSlugs);

        const collectionsSearch = isHybrid ? getSortedFilteredCollections({
            collections,
            filterText: search.currentFilter
        }) : null;

        return {
            technologiesSearch,
            collectionsSearch
        };
    }

    handleClickOutside = () => {
        const { source, dispatch } = this.props;
        dispatch(closeSearchProduct(source));
        dispatch(resetTechnologySuggest());
    };

    selectProduct = (technology) => {
        this.props.onTechnologySelect(technology);
        this.resetAndCloseSearch();
    };

    selectCollection = (collection) => {
        const { onCollectionSelect } = this.props;

        if (onCollectionSelect) {
            onCollectionSelect(collection);
        } else {
            browserHistory.push(`/collection/${collection.slug}`);
        }

        this.resetAndCloseSearch();
    };

    selectMultipleProducts = (technologies) => {
        this.props.onMultipleTechnologiesSelect(technologies);
        this.resetAndCloseSearch();
    };

    resetAndCloseSearch() {
        this.props.dispatch(closeSearchProduct(this.props.source));
        if (this.refs.searchInput) {
            this.refs.searchInput.clearText();
        }
    }

    render() {
        const { search } = this.props;

        let listSearchSelection;
        const searchBySource = search.searchBySource[this.props.source];
        const selectedCollection = !!searchBySource ?
            searchBySource.selectedCollection : null;

        if (searchBySource && searchBySource.isOpen) {
            listSearchSelection = (
                <SearchSelection
                    items={ this.getItems() }
                    isLoading={ this.props.isFetching }
                    isHybrid={ !!this.props.isHybrid }
                    textFilter={ this.props.search.currentFilter }
                    suggest={ this.props.search.suggest }
                    onSuggest={ this.props.onSuggest }
                    source={ this.props.source }
                    existingProducts={ this.props.excludeTechnologies }
                    allowedSlugs={ this.props.allowedSlugs }
                    selectProduct={ this.selectProduct }
                    selectMultipleProducts={ this.selectMultipleProducts }
                    selectCollection={ this.selectCollection }
                    showAsLink={ this.props.showAsLink }
                    allowMultipleSelection={ this.props.allowMultipleSelection }
                    selectedCollection={ selectedCollection }
                    onSetSelectedCollection={ this.onSetSelectedCollection }
                />
            );
        }

        const className = classNames('search-container', this.props.className, {
            hybrid: !!this.props.isHybrid,
            wide: !!this.props.isWide
        });

        return (
            <div className={ className }>
                <SearchInput
                    ref="searchInput"
                    placeholder={ this.props.inputPlaceholderText }
                    autoFocus={ this.props.autoFocus }
                    onSearchTextChange={ this.onSearchTextChange.bind(this) }
                    isOpen={ !!searchBySource ? searchBySource.isOpen : false }
                    inputSelection={ selectedCollection ? selectedCollection.name : null }
                    onClearInputSelection={ this.onClearInputSelection }
                />
                { listSearchSelection }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(SearchContainer));

SearchContainer.propTypes = {
    source: PropTypes.string.isRequired,
    excludeTechnologies: PropTypes.array,
    showLink: PropTypes.bool,
    eventTypes: PropTypes.array
};

SearchContainer.defaultProps = {
    excludeTechnologies: [],
    showAsLink: false,
    onMultipleTechnologiesSelect: () => {},
    eventTypes: ['click', 'touchend'] // on click outside events
};
