import d3 from 'd3';
import d3TipModule from 'd3-tip';
import {
    VERTICAL_BARCHART_VERTICAL_MARGINS_RATIO,
    VERTICAL_BARCHART_HORIZONTAL_MARGINS_RATIO,
    VERTICAL_BARCHART_RANGEBANDS_PADDING,
    VERTICAL_BARCHART_TICK_SIZE,
    VERTICAL_BARCHART_MAX_DOMAIN_SCALE_RATIO,
    VERTICAL_BARCHART_SHADOWBAR_ANIMATION_MS,
    VERTICAL_BARCHART_BAR_ANIMATION_MS,
    VERTICAL_BARCHART_UPDATE_AXIS_ANIMATION_MS,
    CHART_WIDTH_HEIGHT_ASPECT_RATIO
} from './constants';

d3.tip = d3TipModule;

/**
* Basic Bar Chart
*/
export default class VerticalBarChart {

    constructor(el, props = {}) {
        this.el = el;
        this.props = props;
        this.tootlipId = 'vertical-bar-chart-tip';
        this.verticalMarginRatio = VERTICAL_BARCHART_VERTICAL_MARGINS_RATIO;
        this.horizontalMarginRatio = VERTICAL_BARCHART_HORIZONTAL_MARGINS_RATIO;
        this.drawChart(this.props);
        this.handleResizeHandler = this.handleResize.bind(this);
        // on window rezise automatically adjust bar chart size
        window.addEventListener('resize', this.handleResizeHandler);
    }

    handleResize() {
        if (this.svg) {
            this.svg.remove();
        }

        this.drawChart(this.props);
    }

    drawChart(props) {
        this.chartContainerWidth = this.el.offsetWidth;
        this.chartContainerHeight = this.el.offsetWidth * CHART_WIDTH_HEIGHT_ASPECT_RATIO;

        this.verticalMargin = this.chartContainerWidth * this.verticalMarginRatio;
        this.horizontalMargin = this.chartContainerHeight * this.horizontalMarginRatio;

        this.width = this.chartContainerWidth - 2 * this.verticalMargin;
        this.height = this.chartContainerHeight - 2 * this.horizontalMargin;

        // create X and Y scales
        this.x = d3.scale.ordinal()
            .rangeRoundBands([0, this.width], VERTICAL_BARCHART_RANGEBANDS_PADDING);

        this.y = d3.scale.linear()
            .range([this.height, 0]);

        this.configureXAxis();
        this.configureYAxis();

        // create svg and chart containers
        this.svg = d3.select(this.el)
            .append('svg')
            .attr('class', 'bar-chart vertical')
            .attr('width', this.chartContainerWidth)
            .attr('height', this.chartContainerHeight);

        this.chart = this.svg
            .append('g')
            .attr('transform', `translate(${this.verticalMargin}, ${this.horizontalMargin} )`);

        // update chart with data
        this.update(props);
    }

    configureXAxis() {
        this.xAxis = d3.svg.axis()
            .scale(this.x)
            .innerTickSize(0)
            .outerTickSize(VERTICAL_BARCHART_TICK_SIZE)
            .orient('bottom');
    }

    configureYAxis() {
        this.yAxis = d3.svg.axis()
            .ticks(4)
            .scale(this.y)
            .outerTickSize(VERTICAL_BARCHART_TICK_SIZE)
            .innerTickSize(this.props.yGrid ? -this.width : 0)
            .orient('left');

        this.yAxis.tickFormat((d) => {
            return d3.format('s')(d);// M, K format
        });
    }

    update(props) {
        this.props = props;
        const data = this.props.data || [];

        // update domain for the X and Y scales based on the data
        this.x.domain(data.map((d) => { return d.name; }));
        this.y.domain([0, d3.max(data,
            (d) => { return d.value; }) * VERTICAL_BARCHART_MAX_DOMAIN_SCALE_RATIO]);

        this.drawAxis();

        // bind data
        const bars = this.chart.selectAll('.bar').data(data);

        if (this.props.tooltipData) {
            this.removeTooltip();

            this.tip = d3.tip()
                .attr('id', this.tootlipId)
                .attr('class', 'd3-tip')
                .html(this.props.tooltipData)
                .offset([-12, 0]);
            this.svg.call(this.tip);
        }

        // shadow bar
        if (this.props.shadowBar) {
            bars.enter()
                .append('rect')
                    .attr('class', 'shadowbar')
                    .attr('x', (d) => { return this.x(d.name); })
                    .attr('y', () => { return 0; })
                    .attr('height', (d) => { return this.y(d.value); })
                    .attr('width', this.x.rangeBand())
                    .attr('opacity', 0);
        }

        bars.transition()
            .duration(VERTICAL_BARCHART_SHADOWBAR_ANIMATION_MS)
            .attr('opacity', 1);

        // interest bar
        bars.enter()
            .append('rect')
                .attr('class', 'bar')
                .attr('x', (d) => { return this.x(d.name); })
                .attr('y', () => { return this.y(0); })
                .attr('height', () => { return 0; })
                .attr('width', this.x.rangeBand())
                .on('mouseover', (d) => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip animate').show(d);
                    }
                })
                .on('click', (d, i) => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip animate').show(d);
                    }
                    if (this.props.onClick) {
                        this.props.onClick(d, i);
                    }
                })
                .on('mouseout', (d) => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip').show(d);
                        this.tip.hide();
                    }
                })
                .attr('opacity', 0);

        bars.transition()
            .duration(VERTICAL_BARCHART_BAR_ANIMATION_MS)
            .ease('sin-in-out')
            .attr('height', (d) => { return this.height - this.y(d.value); })
            .attr('y', (d) => { return this.y(d.value); })
            .attr('opacity', 1);
    }

    drawAxis() {
        if (this.chart.select('.x.axis')[0][0] !== null) {
            // rescale on data update
            this.chart.select('.x.axis')
                .transition()
                .duration(VERTICAL_BARCHART_UPDATE_AXIS_ANIMATION_MS)
                .ease('sin-in-out')
                .call(this.xAxis);
            this.chart.select('.y.axis')
                .transition()
                .duration(VERTICAL_BARCHART_UPDATE_AXIS_ANIMATION_MS)
                .ease('sin-in-out')
                .call(this.yAxis);
        } else {
            // append X and Y axises
            this.chart
                .append('g')
                .attr('class', 'x axis')
                .attr('transform', `translate(0, ${this.height} )`)
                .call(this.xAxis);

            this.chart.append('g')
                .attr('class', 'y axis')
                .call(this.yAxis);
        }

        // set axises tick text fonts
        this.chart.select('.x.axis')
            .selectAll('text')
                .attr('dy', '1.60em');

        this.chart.select('.y.axis')
            .selectAll('text')
                .attr('dx', '-0.168em');

        // hide first tick (0 value tick)
        this.chart.select('.y.axis')
            .select('.tick')
                .attr('visibility', 'hidden');
    }

    removeTooltip() {
        d3.select(`#${this.tootlipId}`).remove();
    }

    destroy() {
        this.removeTooltip();
        window.removeEventListener('resize', this.handleResizeHandler);
    }
}
