import {
    ADD_SHARE_EMAIL,
    REMOVE_SHARE_EMAIL,
    SHARE_VIA_EMAIL_SUCCESS,
    SHARE_VIA_EMAIL_ERROR,
    CLEAN_SHARE_STATE
} from './constants';

export const shareEmailInitialState = {
    shareSuccess: null,
    shareError: '',
    shareEmails: []
};

export default function shareViaEmail(state = shareEmailInitialState, action) {
    switch (action.type) {
        case SHARE_VIA_EMAIL_SUCCESS:
            return Object.assign({}, state, {
                shareSuccess: true,
                shareError: '',
                shareEmails: []
            });

        case SHARE_VIA_EMAIL_ERROR:
            return Object.assign({}, state, {
                shareSuccess: false,
                shareError: 'Error! Please check the validity of recipients'
            });

        case ADD_SHARE_EMAIL: {
            const email = action.email;
            return Object.assign({}, state, {
                shareEmails: [...state.shareEmails, email],
                shareError: '',
                shareSuccess: null
            });
        }
        case REMOVE_SHARE_EMAIL: {
            const index = action.index;
            return Object.assign({}, state, {
                shareEmails: state.shareEmails.filter((item) => {
                    return item !== state.shareEmails[index];
                }),
                shareError: '',
                shareSuccess: null
            });
        }
        case CLEAN_SHARE_STATE: {
            return Object.assign({}, state, {
                shareEmails: [],
                shareError: '',
                shareSuccess: null
            });
        }
        default:
            return state;
    }
}
