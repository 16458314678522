import { combineReducers } from 'redux';
import home from 'myhg/home/reducer';
import imports from 'myhg/import/reducer';
import exports from 'myhg/export/reducer';
import segment from 'myhg/segment/reducer';
import viewallsegments from 'myhg/viewallsegments/reducer';
import platformGlobalError from 'myhg/platformGlobalError/reducer';
import platformConnection from 'myhg/platformConnection/reducer';

export default combineReducers({
    home,
    imports,
    exports,
    segment,
    viewallsegments,
    platformGlobalError,
    platformConnection
});
