import React from 'react';
import { browserHistory } from 'react-router';
import {
    EMPTY_SEGMENT_LIST,
    EMPTY_SEGMENT_LIST_CREATE_NEW_LINK
} from '../constants';
import { resetSegmentView } from 'myhg/segment/actions';
import { ACTION, WHERE } from 'utils/track-constants';
import { track } from 'utils/Utils';

export default class EmptySegmentList extends React.Component {

    onNewSegmentClick = () => {
        const { dispatch } = this.props;

        dispatch(resetSegmentView());

        track(ACTION.CREATE_SEGMENT, {
            label: WHERE.USER_ACCOUNT_BODY
        });

        browserHistory.push('/dashboard/segment/new');
    }

    render() {
        return (
            <div className="empty-segment-list">
                { EMPTY_SEGMENT_LIST }
                <a
                    className="primary-link"
                    onClick={ this.onNewSegmentClick }
                    data-qa="create-segment-link"
                >
                    { EMPTY_SEGMENT_LIST_CREATE_NEW_LINK }
                </a>
            </div>
        );
    }
}
