import { HTTP_SERVERERROR_CODE } from 'shared/constants';

export default class ApiErrorMessageHelper {

    static getMessageByErrorResponse(error) {
        let errorMessage = 'Unknown error.';
        if (error.data && error.data.reason) {
            // get API from default error message object
            const showErrorCode = error.status === HTTP_SERVERERROR_CODE;
            errorMessage = showErrorCode ?
                `(${error.data.code}) ${error.data.reason}` : error.data.reason;
        } else if (error.statusText) {
            // get API http status message
            errorMessage = `(${error.status}) ${error.statusText}`;
        } else if (error.message) {
            // get network related error
            errorMessage = error.message;
        }

        return errorMessage;
    }

    static getNormalizedErrorData(error) {
        let errorMessage = ApiErrorMessageHelper.getMessageByErrorResponse(error);

        if (error.code === 'ECONNABORTED') {
            // User friendly error message on timeout requests
            errorMessage = 'Operation timeout.';
        }

        return {
            status: error.status,
            message: errorMessage,
            code: error.data ? error.data.code : error.code,
            data: error.data ? error.data : null
        };
    }

    static getNormalizedErrorDataFromOauthQuery(error, errorDescription) {
        return {
            status: error,
            message: errorDescription
        };
    }
}
