import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import ValidationField from 'shared/ui/formFields/ValidationField';
import Validation from 'utils/Validation';
import { URL } from 'shared/constants';
import Loader from 'shared/ui/loader/Loader';
import Checkbox from 'shared/ui/checkbox/Checkbox';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';
import {
    SIGN_UP_TITLE,
    SIGN_UP_SUBTITLE
} from './constants';
import { SEGMENT_MATCHING_SERVICES } from 'myhg/segment/constants';

export const fields = [
    'firstName',
    'lastName',
    'companyName',
    'email',
    'password',
    'agreementChecked'
];

const validate = values => {
    // TODO implement validator utilities for required, minimum length, email, etc..
    const errors = {};

    if (!Validation.validateWhitespace(values.firstName)) {
        errors.firstName = 'Required';
    }

    if (!Validation.validateWhitespace(values.lastName)) {
        errors.lastName = 'Required';
    }

    if (!values.email) {
        errors.email = 'Required';
    } else if (!Validation.validateEmail(values.email)) {
        errors.email = 'Invalid email address';
    }

    if (!values.password) {
        errors.password = 'Required';
    } else if (!Validation.validatePassword(values.password)) {
        errors.password = `
            Invalid password`;
    }

    if (!values.agreementChecked) {
        errors.agreementChecked = 'Check the terms';
    }
    return errors;
};

class SignUpForm extends React.Component {
    constructor(props) {
        super(props);

        this.availablePlatforms = SEGMENT_MATCHING_SERVICES.filter(item => {
            return item.available;
        });

        this.state = {
            platform: props.landingPage
        };
    }


    getError(error) {
        if (!error) {
            return null;
        }

        return (
            <p className="text-content error">
                { error.message }
            </p>
        );
    }

    getPasswordTooltip() {
        const {
            fields: {
                password
            }
        } = this.props;

        const tooltipClassNames = classNames(
            '__react_component_tooltip place-top type-dark custom-tooltip', {
                show: password.error && password.touched
            });
        // call ReactTooltip component to inject the css styles in html every time the page is
        // accessed since we use css class from react tooltip component
        return (
            <div className={ tooltipClassNames }>
                <span>
                    Password must contain at least one lower case letter,
                    one upper case letter,
                    one digit and must be at least 6 characters
                </span>
                <ReactTooltip />
            </div>
        );
    }

    getTitle = () => {
        const { landingPage } = this.props.params;
        const signupSource = landingPage || '';
        const isFile = signupSource === 'file';
        const platformClass = `source-logo icon ${signupSource}-img`;
        const logoClassName = isFile ? 'icon icon-check-list' : platformClass;
        const subtitleType = landingPage || 'none';

        return (
            <div className="title">
                { SIGN_UP_TITLE[signupSource] }
                <div className={ classNames('subtitle', subtitleType) }>
                    { SIGN_UP_SUBTITLE[signupSource] }
                    <span className={ logoClassName } />
                </div>
            </div>
        );
    }

    isValidForm() {
        return Object.keys(this.props.errors).length === 0 &&
            this.props.errors.constructor === Object;
    }

    render() {
        const {
            fields: {
                firstName,
                lastName,
                email,
                password,
                agreementChecked
            },
            handleSubmit,
            submitting,
            requestError
        } = this.props;

        return (
            <div className="signup-right">
                { this.getTitle() }
                <form
                    id="sign-up"
                    className="modal-form"
                    onSubmit={ handleSubmit(this.props.onRegisterSubmit) }
                >
                    <ValidationField
                        type="text"
                        label="Work Email Address"
                        placeholder="julie@example.com"
                        fieldType="input"
                        {...email}
                        data-qa="work-email"
                    />
                    <div className="form-twocolums-wrapper">
                        <ValidationField
                            className="left"
                            type="text"
                            label="First Name"
                            fieldType="input"
                            {...firstName}
                            data-qa="first-name"
                        />
                        <ValidationField
                            className="right"
                            type="text"
                            label="Last Name"
                            fieldType="input"
                            {...lastName}
                            data-qa="last-name"
                        />
                    </div>
                    <div className="input-wrapper">
                        <ValidationField
                            type="password"
                            label="Password"
                            placeholder="6+ characters"
                            fieldType="input"
                            {...password}
                            data-qa="password"
                        />
                        { this.getPasswordTooltip() }
                    </div>
                    <Checkbox
                        label={
                            <span className="label">I agree to the &nbsp;
                                <a href={ URL.TERMS_OF_USE } target="_blank" data-qa="terms-link">
                                    Terms of Service
                                </a>
                                &nbsp; and &nbsp;
                                <a
                                    href={ URL.PRIVACY_POLICY }
                                    target="_blank"
                                    data-qa="privacy-policy"
                                >
                                    Privacy Policy
                                </a>
                            </span>
                        }
                        {...agreementChecked}
                    />
                    { this.getError(requestError) }
                    <FormButtonWrapper>
                        <button
                            id="register-btn"
                            type="submit"
                            className="btn btn-primary"
                            disabled={ submitting || !this.isValidForm() }
                            data-qa="signup-up-button"
                        >
                            Sign Up { submitting ?
                            <Loader className="small-loader white" /> : '' }
                        </button>
                    </FormButtonWrapper>
                    <p className="text-content large">
                        Already have an account? &nbsp;
                        <Link to="/login" className="default-link" data-qa="login-link">
                            Login Here
                        </Link>
                    </p>
                </form>
            </div>
        );
    }
}

// https://www.npmjs.com/package/@jimpick/redux-form
SignUpForm.propTypes = {
    fields: PropTypes.object.isRequired,
    // It will run validation, both sync and async, and, if the form is valid,
    // it will call this.props.onRegisterSubmit function
    handleSubmit: PropTypes.func.isRequired,
    // Whether or not your form is currently submitting.
    // This prop will only work if you have passed an onSubmit function
    // that returns a promise. It will be true until the promise is resolved or rejected.
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'signUpForm',
    fields,
    validate
})(SignUpForm);
