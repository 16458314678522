import React from 'react';
import { browserHistory } from 'react-router';

export default class RedirectAction extends React.Component {

    onSubmit = () => {
        const { platformName } = this.props;
        const platformRoute = platformName.toLowerCase();
        if (platformRoute === 'file') {
            // for file redirect user to import flow instead, there is no connect flow required
            browserHistory.push(`/dashboard/import/${platformRoute}`);
        } else {
            browserHistory.push(`/dashboard/connect/${platformRoute}`);
        }
    }

    render() {
        return (
            <button
                id="confirmation-success"
                className="btn btn-primary"
                onClick={ this.onSubmit }
                data-qa="continue-button"
            >
                { this.props.actionText }
            </button>
        );
    }
}
