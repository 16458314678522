import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router';
import sprintf from 'sprintf-js';
import Loader from 'shared/ui/loader/Loader';
import {
    SEARCH_PRODUCT_UNTRACKED,
    PRODUCT_ALREADY_SELECTED
} from '../constants';
import keyboardSelectionHOC from './keyboardSelectionHOC';

@keyboardSelectionHOC
export default class ProductSelection extends React.Component {

    getUntrackedProduct() {
        let content = null;

        if (!this.props.suggest.isSent) {
            content = (
                <div className="no-result-msg">
                    <p>
                        { sprintf.sprintf(SEARCH_PRODUCT_UNTRACKED, this.props.textFilter) }
                        <span
                            className="blue-text"
                            onClick={ () => { this.props.onSuggest(this.props.textFilter); } }
                        >
                            Suggest This Technology
                        </span>
                    </p>
                </div>
            );
        } else {
            const error = this.props.suggest.error;
            content = (
                <div className="no-result-msg">
                    <p>
                        { !error ?
                        <span className="text">
                            Thank you. Your suggestion has been received.
                        </span> :
                        <span className="error-text">
                            { error.message }
                        </span> }
                    </p>
                </div>
            );
        }

        return content;
    }

    getTrackedProductsList() {
        const { items, allowedSlugs, showAsLink } = this.props;

        return items.technologies.map((tech, index) => {
            // Disable if we have permissions and is not selected
            let disabled;
            if (!!allowedSlugs) {
                disabled = !allowedSlugs.find((slug) => {
                    return tech.slug === slug;
                });
            } else {
                disabled = false;
            }

            const isSelectedProduct = !!this.props.selectedItems.find(product => {
                return product && (product.productId === tech.productId);
            });

            // Assemble class names
            const itemClassName = classNames('select-item', {
                disabled,
                clickable: !disabled,
                selected: (this.props.selectedIndex === index || isSelectedProduct) && !disabled
            });
            const iconName = tech.vendorUrl ? tech.vendorUrl : 'default_favicon';
            const iconClassName = classNames('', {
                'fav-icon': !disabled,
                [`icon-${iconName}`]: !disabled,
                'icon-key': disabled
            });

            return (
                <li
                    key={ index }
                    className={ itemClassName }
                    onClick={ this.productSelectHandler.bind(this, tech) }
                >
                    <span className="icon-circle favicon-wrapper">
                        <span className={ iconClassName } />
                    </span>
                    { this.getLineItemText(tech, showAsLink, disabled) }
                </li>
            );
        });
    }

    getLineItemText(tech, includeLink, disabled) {
        let lineItem;
        if (includeLink) {
            lineItem = (
                <Link
                    to={ `/product/${tech.slug}` }
                >
                    { this.props.formatter(tech.product, this.props.textFilter) }
                </Link>
            );
        } else {
            if (!disabled) {
                lineItem = this.props.formatter(tech.product, this.props.textFilter);
            } else {
                lineItem = <span>{ tech.product }</span>;
            }
        }
        return lineItem;
    }

    getExistingProduct() {
        const { textFilter, existingProducts } = this.props;

        return existingProducts.find(prod => {
            return prod.product.toLowerCase() === textFilter.toLowerCase();
        });
    }

    productSelectHandler(tech) {
        this.props.itemSelectHandler(tech);
    }

    render() {
        let content = '';
        const existingProduct = this.getExistingProduct();

        if (this.props.isLoading) {
            return (
                <Loader className="small-loader white" />
            );
        } else if (this.props.items.technologies.length === 0 && !existingProduct) {
            content = this.getUntrackedProduct();
        } else if (existingProduct) {
            content = (
                <div className="no-result-msg">
                    <p>
                        { sprintf.sprintf(PRODUCT_ALREADY_SELECTED, existingProduct.product) }
                    </p>
                </div>
            );
        } else {
            content = (
                <div>
                    { this.getTrackedProductsList() }
                </div>
            );
        }

        return content;
    }
}
