import React from 'react';

export default class GenericBenefits extends React.Component {
    render() {
        return (
            <ul className="landing-page-benefits">
                <li>
                    <span className="icon icon-check-mark" />
                    <span className="item">
                        Explore the data for competitive and complementary technologies.
                    </span>
                </li>
                <li>
                    <span className="icon icon-check-mark" />
                    <span className="item">
                        Save and share customer segments.
                    </span>
                </li>
                <li>
                    <span className="icon icon-check-mark" />
                    <span className="item">
                        Download sample data and compare to your account data&nbsp;
                        to discover net new accounts.
                    </span>
                </li>
            </ul>
        );
    }
}
