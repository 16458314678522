import React from 'react';
import withTaxonomy from 'shared/taxonomy/enhancer';
import SearchContainer from 'shared/search/SearchContainer';
import Dropdown from 'shared/ui/dropdown/Dropdown';
import { TechnologyIcon } from 'shared/ui/technologyIcon/TechnologyIcon';
import {
    FILTER_PRODUCT_SEARCH_PLACEHOLDER,
    FILTER_PRODUCT_SEARCH_ONE_PLACEHOLDER,
    FILTER_PRODUCT_SEARCH_PLACEHOLDER_FREE,
    FILTER_PRODUCT_MAX_COUNT,
    PRODUCTS_RELATION_OPTIONS,
    PRODUCTS_RELATION_TEXT
} from '../constants';
import { setProductsRelation } from '../actions';

const MIN_TECHS_TO_CLEAR_ALL = 6;

class TechnologiesFilter extends React.Component {

    onTechnologyUnSelect(item) {
        this.props.onTechnologyUnSelect(item);
    }

    onCollectionUnSelect(collection) {
        this.props.onCollectionUnSelect(collection);
    }

    onSelectionChange = (selectedIndex) => {
        const { dispatch } = this.props;
        dispatch(setProductsRelation(PRODUCTS_RELATION_OPTIONS[selectedIndex].value));
    }

    getSearchContainer() {
        const { findTaxonomyProductsForSlugs, isPaidUser, technologies } = this.props;
        const excludeTechnologies = findTaxonomyProductsForSlugs(technologies);

        if (!isPaidUser && technologies.length >= FILTER_PRODUCT_MAX_COUNT) {
            return null;
        }

        let inputPlaceholderText = FILTER_PRODUCT_SEARCH_ONE_PLACEHOLDER;

        if (technologies.length > 0) {
            inputPlaceholderText = isPaidUser ?
                FILTER_PRODUCT_SEARCH_PLACEHOLDER : FILTER_PRODUCT_SEARCH_PLACEHOLDER_FREE;
        }

        return (
            <SearchContainer
                isHybrid
                className="segment-technology-search"
                source="segmentFilter"
                inputPlaceholderText={ inputPlaceholderText }
                onTechnologySelect={ this.props.onTechnologySelect }
                onCollectionSelect={ this.props.onCollectionSelect }
                onMultipleTechnologiesSelect={ this.props.onMultipleTechnologiesSelect }
                excludeTechnologies={ excludeTechnologies }
                allowedSlugs={ this.props.allowedSlugs }
                allowMultipleSelection
            />
        );
    }

    getClearAll() {
        const selectedProducts = this.props.technologies;

        if (selectedProducts.length > MIN_TECHS_TO_CLEAR_ALL) {
            return (
                <div>
                    <a
                        onClick={ this.props.clearAllTechnologies }
                    >
                        Clear All
                    </a>
                </div>
            );
        }

        return null;
    }

    getSelectedTechnologiesContent() {
        const { findTaxonomyProduct } = this.props;
        const selectedProducts = this.props.technologies.map(
            (slug) => {
                const item = findTaxonomyProduct(slug);

                return (
                    <div key={ `${slug}-product` } className="technology-icon-text">
                        <TechnologyIcon technology={ item } />
                        <span className="item-name">
                            { item.product }
                        </span>
                        <span
                            onClick={ this.onTechnologyUnSelect.bind(this, item) }
                            className="item-close icon-close-x"
                        />
                    </div>
                );
            }
        );

        return (
            <div className="technologies-selected-container">
                { this.getSelectedCollectionsContent() }
                { selectedProducts }
            </div>
        );
    }

    getSelectedCollectionsContent() {
        return this.props.collections.map(
            (collection) => {
                return (
                    <div key={ `${collection.slug}-collection` } className="technology-icon-text">
                        <TechnologyIcon technology={ collection } />
                        <span className="item-name">
                            { `${collection.name} (${collection.techSlugs.length})` }
                        </span>
                        <span
                            onClick={ this.onCollectionUnSelect.bind(this, collection) }
                            className="item-close icon-close-x"
                        />
                    </div>
                );
            }
        );
    }

    getDropdownSelections() {
        return PRODUCTS_RELATION_OPTIONS.map((item) => {
            return item.text;
        });
    }

    getProductsRelationContent() {
        const activeOption = PRODUCTS_RELATION_OPTIONS.find((item) => {
            return item.value === this.props.matchAllProducts;
        });
        const dropdownActivator = (
            <div>
                { activeOption.text }
                <span className="icon icon-small-arrow-down" />
            </div>
        );

        return (
            <h3>
                { PRODUCTS_RELATION_TEXT[0] }
                <Dropdown
                    className="products-relation-dropdown"
                    activator={ dropdownActivator }
                    selections={ this.getDropdownSelections() }
                    onSelectionChange={ this.onSelectionChange }
                />
                { PRODUCTS_RELATION_TEXT[1] }
            </h3>
        );
    }

    render() {
        return (
            <div
                className="select-technology"
            >
                { this.getProductsRelationContent() }
                { this.getSearchContainer() }
                { this.getClearAll() }
                { this.getSelectedTechnologiesContent() }
            </div>
        );
    }
}

export default withTaxonomy(TechnologiesFilter);
