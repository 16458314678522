export const MY_SEGMENTS_TITLE = 'My Segments';
export const MY_SEGMENTS_DESCRIPTION = 'You have created %1$s segments so far.';
export const MY_SEGMENTS_HEADER_PRODUCTS_TITLE = '# products';
export const MY_SEGMENTS_HEADER_COMPANIES_TITLE = '# unique companies';
export const TOTAL_SEGMENT_PAGES = '%1$s-%2$s of %3$s Segments';
export const SEGMENTS_REQUEST = 'SEGMENTS_REQUEST';
export const SEGMENTS_SUCCESS = 'SEGMENTS_SUCCESS';
export const SEGMENTS_FAILURE = 'SEGMENTS_FAILURE';
export const START_INDEX = 1;
export const PAGINATION_STEP = 10;
export const EMPTY_SEGMENT_LIST = 'Your segment list is empty - ';
export const EMPTY_SEGMENT_LIST_CREATE_NEW_LINK = 'Create Your First Segment';
