import React from 'react';
import TabMenu from 'shared/ui/tabs/TabMenu';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import ImportFile from './ImportFile';
import LoadFiles from './LoadFiles';
import {
    MATCH_CSV_TITLE,
    MENU,
    UPLOAD_NEW_FILE,
    PREVIOUSLY_UPLOADED
} from './constants';

export default class ImportFileContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            MENU,
            selectedTab: MENU[0]
        };
    }

    getContentByTabSelection() {
        const selectedMenu = this.state.selectedTab;
        let tabComponent;

        switch (selectedMenu.name) {
            case UPLOAD_NEW_FILE:
                tabComponent = <ImportFile { ...this.props } />;
                break;

            case PREVIOUSLY_UPLOADED:
                tabComponent = <LoadFiles { ...this.props } />;
                break;

            default:
                tabComponent = null;
        }

        return (
            <div>
                <TabMenu
                    menus={ this.state.MENU }
                    selectedMenu={ selectedMenu }
                    menuItemChanged={ this.menuItemChanged }
                />
                { tabComponent }
            </div>
        );
    }

    menuItemChanged = (menuItem) => {
        this.setState({
            selectedTab: menuItem
        });
    }

    render() {
        return (
            <ImportPageContainerWrapper
                title={ MATCH_CSV_TITLE }
                className="segment-match-csv-container"
            >
                { this.getContentByTabSelection() }
            </ImportPageContainerWrapper>
        );
    }
}
