import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton class that encapsulates salesforce related API calls
 */
export default class SalesforceService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new SalesforceService(singletonEnforcer);
        }
        return this[singleton];
    }

    // TODO -> Move telemetry events into another service or rename this service
    sendTelemetry(telemetryData) {
        const data = {
            action: telemetryData.action,
            campaignSessionId: telemetryData.campaignSessionId
        };
        return ApiWrapper.instance.axios.post('telemetry', data);
    }

    sendNewTelemetryData(telemetryData) {
        return ApiWrapper.instance.axios.post('telemetry/new', telemetryData);
    }
}
