import React from 'react';
import { connect } from 'react-redux';
import FirmographicUtils from 'utils/FirmographicUtils';
import Slider from 'shared/ui/slider/Slider';
import { setRevenueInterval } from './actions';
import { removeUnknowValuesId } from '../unknownValuesFilter/actions';
import { UNKNOWN_VALUES_REVENUE_FILTER_NAME } from '../unknownValuesFilter/constants';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        revenueSteps: FirmographicUtils.getIntervalsIncludingBoundaries(
            state.taxonomy.firmographic.data.revenueSteps),
        revenue: segment.segmentFilters.revenue
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class RevenueFilterContainer extends React.Component {

    componentDidMount() {
        const { revenue, revenueSteps } = this.props;
        const revenueInterval = {
            from: revenueSteps[0],
            to: revenueSteps[revenueSteps.length - 1],
        };

        if (!revenue) {
            this.props.dispatch(setRevenueInterval(revenueInterval));
        }
    }

    onRevenueSliderChange(item) {
        const { revenue, revenueSteps, dispatch } = this.props;
        const revenueInterval = {
            from: revenueSteps[item[0]],
            to: revenueSteps[item[1]],
        };

        if (revenue.from === revenueSteps[0] &&
            revenue.to === revenueSteps[revenueSteps.length - 1]) {
            // unselect unknown firmo value for revenue
            dispatch(removeUnknowValuesId(UNKNOWN_VALUES_REVENUE_FILTER_NAME));
        }
        dispatch(setRevenueInterval(revenueInterval));
        // const label = `${revenueInterval.from} - ${revenueInterval.to}`;
        // track(ACTION.CREATE_CAMPAIGN_REVENUE, { label });
    }

    onPositionChange(item) {
        this.onRevenueSliderChange(item);
    }

    render() {
        const { revenueSteps, revenue } = this.props;

        const formattedRevenueArray = revenueSteps.map((number, index) => {
            return FirmographicUtils.getFormattedNumber(
                number, true, index === revenueSteps.length - 1);
        });
        const defLeftIndex = revenue ?
            revenueSteps.indexOf(revenue.from) : 0;
        const defRigthIndex = revenue ?
            revenueSteps.indexOf(revenue.to) : revenueSteps.length - 1;

        return (
            <Slider
                isControlled
                defaulLeftIndex={ defLeftIndex }
                defaulRigthIndex={ defRigthIndex }
                data={ formattedRevenueArray }
                onChange={ this.onPositionChange.bind(this) }
            />
        );
    }
}
