import React from 'react';
import sprintf from 'sprintf-js';
import GenericModal from 'shared/ui/modal/GenericModal';
import {
    DOWNLOAD_LIMIT_MODAL_TITLE,
    DOWNLOAD_LIMIT_MODAL_CONTENT,
    DOWNLOAD_LIMIT_MODAL_BTN
} from 'myhg/segment/constants';
import { NumeralUtils } from 'utils/Utils';

export default class DownloadLimitModal extends React.Component {

    getModalContent() {
        const { tableCountRecords } = this.props.currentSegment.data.status;
        const remaining = this.props.downloadLimit - tableCountRecords;

        return (
            <div>
                <div className="records">
                    <b>{ NumeralUtils.formatIntegerNumber(tableCountRecords, false) }</b>
                    <span>records</span>
                </div>
                {
                    sprintf.sprintf(
                        DOWNLOAD_LIMIT_MODAL_CONTENT,
                        NumeralUtils.formatIntegerNumber(this.props.downloadLimit, false),
                        NumeralUtils.formatIntegerNumber(remaining < 0 ? 0 : remaining, false)
                    )
                }
            </div>
        );
    }

    render() {
        return (
            <GenericModal
                open={ this.props.open }
                className="generic-modal export-modal"
                title={ DOWNLOAD_LIMIT_MODAL_TITLE }
                content={ this.getModalContent() }
                onPrimaryActionClick={ this.props.onPrimaryActionClick }
                primaryActionText={ DOWNLOAD_LIMIT_MODAL_BTN }
                onCloseActionClick = { this.props.onCloseActionClick }
            />
        );
    }
}
