import d3 from 'd3';
import {
    VERTICAL_BARCHART_BAR_ANIMATION_MS
} from './constants';

export default class DonutChart {

    constructor(el, props = {}) {
        this.el = el;
        this.props = props;
        this.dataKey = this.props.dataKey || 'value';
        this.initChart();
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (this.svg) {
            this.svg.remove();
        }

        this.initChart();
    }

    initChart() {
        this.width = this.el.offsetWidth;
        this.height = this.el.offsetHeight;

        const radius = Math.min(this.width, this.height) / 2;

        this.arc = d3.svg.arc()
            .outerRadius(radius)
            .innerRadius(radius / 2);

        this.pie = d3.layout.pie()
            .value(d => { return d[this.dataKey]; })
            .sort(null);

        this.svg = d3.select(this.el)
            .append('svg')
                .attr('width', this.width)
                .attr('height', this.height);

        this.chart = this.svg
            .append('g')
                .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`);

        this.update(this.props.data);
    }

    update(data) {
        this.props.data = data;
        const pieData = this.pie(this.props.data);

        const paths = this.chart.selectAll('path')
            .data(pieData, d => { return d.data.type; });

        paths.exit().remove();

        paths.enter()
            .append('path')
                .attr('class', d => { return `layer ${d.data.type}`; })
            .transition()
            .ease('sin-in-out')
            .duration(VERTICAL_BARCHART_BAR_ANIMATION_MS)
            .attrTween('d', b => {
                const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, b);
                return t => { return this.arc(i(t)); };
            });
    }

    destroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
