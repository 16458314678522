import React from 'react';
import { Link } from 'react-router';

export default class LoginLogoutNavBar extends React.Component {

    getActionOptions() {
        const { isUserAuthenticated } = this.props;
        return !isUserAuthenticated ?
            this.loginNavBar() : this.props.logOutNavbar();
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    loginNavBar() {
        return (
            <Link
                rel="nofollow"
                to="/login"
                className="btn btn-link user-login"
            >
                Login
            </Link>
        );
    }

    render() {
        return (
            <div className="action-options">
                { this.getActionOptions() }
            </div>
        );
    }
}
