import { browserHistory } from 'react-router';
import ProductService from 'common/services/ProductService';
import { loadSampleCompanies } from './sampleCompanies/actions';
import { loadSimilarProducts } from './similarProducts/actions';
import { loadFirmographicData } from './firmographics/actions';
import { loadCollectionsByProduct } from './collections/actions';
import {
    LOAD_PRODUCT_DETAILS_REQUEST,
    LOAD_PRODUCT_DETAILS_SUCCESS,
    LOAD_PRODUCT_DETAILS_FAILURE,
    SET_NEW_PRODUCT_ID,
    INCREASE_INSIGHTS_INDEX,
    DECREASE_INSIGHTS_INDEX,
    TOGGLE_FAVORITE_PRODUCT,
    TOGGLE_DESCRIPTION,
    UPDATE_FAVORITE_PRODUCT_REQUEST,
    UPDATE_FAVORITE_PRODUCT_SUCCESS,
    UPDATE_FAVORITE_PRODUCT_FAILURE

} from './constants';

/**
 * Action creator to load product details from the API
 */
export function loadProductDetails(productIdFunc) {
    // React Router passes the param as an object
    let productId = productIdFunc;
    if (typeof productIdFunc === 'object') {
        productId = productIdFunc.productId;
    }
    return (dispatch) => {
        dispatch({
            type: LOAD_PRODUCT_DETAILS_REQUEST,
            productId
        });
        return ProductService.instance.getProductDetailsById(productId)
            .then(response => {
                dispatch({
                    type: LOAD_PRODUCT_DETAILS_SUCCESS,
                    data: response.data,
                    productId
                });
            })
            .catch(error => {
                dispatch({
                    type: LOAD_PRODUCT_DETAILS_FAILURE,
                    error,
                    productId
                });
                return {
                    serverShouldHandle: true,
                    error
                };
            });
    };
}

/**
 * Action creator to update product ID for on the product page
 */
export function updateProductId(productIdFunc) {
    // React Router passes the param as an object
    let productId = productIdFunc;
    if (typeof productIdFunc === 'object') {
        productId = productIdFunc.productId;
    }
    return (dispatch) => {
        return dispatch({
            type: SET_NEW_PRODUCT_ID,
            productId
        });
    };
}

/**
 * Action creator to select a new product for the product page. This is only for client usage.
 */
export function selectNewProduct(slug) {
    return () => {
        // there could be a lightbox open on the same path,
        // workaround is to only redirect to the product id path if different product is submitted
        if (window) {
            const currentSlug = window.location.pathname.replace('/product/', '');
            if (currentSlug !== slug) {
                browserHistory.push(`/product/${slug}`);
            }
        }

        // Scroll to the top as we load a new product
        if (window) {
            window.scrollTo(0, 0);
        }

        return slug;
    };
}

/**
 * Action creator to dispatch all the request to get the data for a new product
 */
export function loadProductData(productId) {
    return (dispatch) => {
        // Emit multiple actions that needed for the product page
        const actions = [
            // update slug
            dispatch(updateProductId(productId)),
            // get product details
            dispatch(loadProductDetails(productId)),
            // get sample companies
            dispatch(loadSampleCompanies(productId)),
            // get collections product is part of
            dispatch(loadCollectionsByProduct(productId)),
            // get similar techs
            dispatch(loadSimilarProducts(productId)),
            // get firmographic data
            dispatch(loadFirmographicData(productId))
        ];

        return actions;
    };
}

/**
 * Action creator to view next insight
 */
export function increaseInsightsIndex() {
    return (dispatch) => {
        return dispatch({
            type: INCREASE_INSIGHTS_INDEX
        });
    };
}

/**
 * Action creator to view previous insight
 */
export function decreaseInsightsIndex() {
    return (dispatch) => {
        return dispatch({
            type: DECREASE_INSIGHTS_INDEX
        });
    };
}

export function toggleDescription() {
    return (dispatch) => {
        return dispatch({
            type: TOGGLE_DESCRIPTION
        });
    };
}

export function toggleFavoriteProduct() {
    return (dispatch) => {
        return dispatch({
            type: TOGGLE_FAVORITE_PRODUCT
        });
    };
}

export function updateFavoriteProduct(slug, isFavorite) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_FAVORITE_PRODUCT_REQUEST,
            isFavorite
        });
        return ProductService.instance.updateFavoriteProduct(slug, isFavorite)
            .then(response => {
                dispatch({
                    type: UPDATE_FAVORITE_PRODUCT_SUCCESS,
                    data: response.data
                });
                dispatch(toggleFavoriteProduct());
            })
            .catch(error => {
                dispatch({
                    type: UPDATE_FAVORITE_PRODUCT_FAILURE,
                    error
                });
            });
    };
}
