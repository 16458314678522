import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import root from 'window-or-global';
import ProductSelection from './ProductSelection';
import CollectionSelection from './CollectionSelection';
import CollectionProductsSelectionContainer from './CollectionProductsSelectionContainer';
import SearchListContainer from './SearchListContainer';
import SearchSelectionHeader from './SearchSelectionHeader';
import {
    EVENT_DOM_KEYDOWN,
    KEYCODE_TAB
} from 'shared/constants';
import {
    SEARCHLIST_PRODUCT_TITLE,
    SEARCHLIST_COLLECTION_TITLE
} from '../constants';

const technologiesColumn = 'technologies';
const collectionsColumn = 'collections';
const tabColumns = [technologiesColumn, collectionsColumn];

export default class SearchSelection extends React.Component {

    constructor(props) {
        super(props);
        const position = technologiesColumn;
        this.state = {
            position
        };
    }

    componentDidMount() {
        root.addEventListener(EVENT_DOM_KEYDOWN, this.handleKeyboardKeydown);
    }

    componentWillUnmount() {
        root.removeEventListener(EVENT_DOM_KEYDOWN, this.handleKeyboardKeydown);
    }

    /**
    * Get highlighted text component for item text found.
    * @param {string} text Full text to display.
    * @param {string} filterText string part that should be highlighted
    * @returns {Array} Array react components to form the final higlighted text
    */
    getHighlightedTextForItem(text, filterText) {
        const startPos = text.toLowerCase().indexOf(filterText.toLowerCase());

        return [
            <span key={ 0 }>
                { text.substring(0, startPos) }
            </span>,
            <span key={ 1 } className="highlight-search">
                { text.substr(startPos, filterText.length) }
            </span>,
            <span key={ 2 }>
                { text.substr(startPos + filterText.length) }
            </span>
        ];
    }

    getProductContainer() {
        const { isHybrid } = this.props;
        const isSearchActive = this.state.position === tabColumns[0];

        return (
            <SearchListContainer
                items={ this.props.items.technologiesSearch }
                containerId="product-search-container"
                listName={ isHybrid ? SEARCHLIST_PRODUCT_TITLE : null }
            >
                <ProductSelection
                    items={ this.props.items.technologiesSearch }
                    searchableItems={ this.props.items.technologiesSearch.technologies }
                    existingProducts={ this.props.existingProducts }
                    allowedSlugs={ this.props.allowedSlugs }
                    showAsLink={ this.props.showAsLink }
                    suggest={ this.props.suggest }
                    onSuggest={ this.props.onSuggest }
                    textFilter={ this.props.textFilter }
                    formatter={ this.getHighlightedTextForItem }
                    itemSelected={ this.productSelected }
                    multipleItemsSelected={ this.multipleProductsSelected }
                    isSearchActive={ isSearchActive }
                />
            </SearchListContainer>
        );
    }

    getCollectionContainer() {
        const { isHybrid } = this.props;
        const isSearchActive = this.state.position === tabColumns[1];

        return (
            <SearchListContainer
                items={ this.props.items.collectionsSearch }
                containerId="collection-search-container"
                listName={ isHybrid ? SEARCHLIST_COLLECTION_TITLE : null }
            >
                <CollectionSelection
                    showAsLink={ this.props.showAsLink }
                    items={ this.props.items.collectionsSearch }
                    searchableItems={ this.props.items.collectionsSearch.collections }
                    formatter={ this.getHighlightedTextForItem }
                    textFilter={ this.props.textFilter }
                    itemSelected={ this.setSelectedCollection }
                    isSearchActive={ isSearchActive }
                />
            </SearchListContainer>
        );
    }

    getCollectionProductsContainer() {
        return (
            <CollectionProductsSelectionContainer
                collection={ this.props.selectedCollection }
                selectCollection={ this.props.selectCollection }
                existingProducts={ this.props.existingProducts }
                allowedSlugs={ this.props.allowedSlugs }
                formatter={ this.getHighlightedTextForItem }
                textFilter={ this.props.textFilter }
                selectedProducts={ this.state.selectedProducts }
                itemSelected={ this.productSelected }
                multipleItemsSelected={ this.multipleProductsSelected }
                isSearchActive
            />
        );
    }

    // this will set the current selected collection
    setSelectedCollection = (collection) => {
        const { onSetSelectedCollection } = this.props;
        onSetSelectedCollection(collection);
    }

    handleKeyboardKeydown = (ev) => {
        switch (ev.which) {
            case KEYCODE_TAB: {
                const { isHybrid } = this.props;

                // hybrid search expects multiple columns search results
                if (!isHybrid) {
                    break;
                }
                ev.preventDefault();
                const positionIndex = tabColumns.indexOf(this.state.position);
                const newPosition = tabColumns[(positionIndex + 1) % tabColumns.length];
                const newItems = this.props.items[`${newPosition}Search`][newPosition];
                if (newItems.length > 0) {
                    this.setState({
                        position: newPosition
                    });
                }
                break;
            }

            default:
                break;
        }
    }

    productSelected = (product) => {
        this.props.selectProduct(product);
    }

    multipleProductsSelected = (products) => {
        this.props.selectMultipleProducts(products);
    }

    render() {
        const { isHybrid, allowMultipleSelection } = this.props;
        const searchSelectionClassName = classNames('search-selection', {
            hybrid: isHybrid
        });

        if (this.props.selectedCollection) {
            return (
                <div className={ searchSelectionClassName }>
                    <div className="search-selection-container">
                        { allowMultipleSelection ? <SearchSelectionHeader /> : null }
                        { this.getCollectionProductsContainer() }
                    </div>
                </div>
            );
        }

        return (
            <div className={ searchSelectionClassName }>
                <div className="search-selection-container">
                    { allowMultipleSelection ? <SearchSelectionHeader /> : null }
                    { this.getProductContainer() }
                    { isHybrid ? this.getCollectionContainer() : null }
                </div>
            </div>
        );
    }
 }

SearchSelection.contextTypes = {
    router: PropTypes.object.isRequired
};
