import {
    FILTERS_ADD_INDUSTRY,
    FILTERS_REMOVE_INDUSTRY,
    FILTERS_SET_INDUSTRIES
} from '../constants';

export function addIndustry(industry) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_ADD_INDUSTRY,
            industry
        });
    };
}

export function removeIndustry(industry) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_REMOVE_INDUSTRY,
            industry
        });
    };
}

export function setIndustries(industries) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_SET_INDUSTRIES,
            industries
        });
    };
}
