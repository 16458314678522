import LaunchDarkly from 'ldclient-js';
import Guid from 'guid';
import { setFeatureFlags } from 'common/root/actions';

export function createLaunchDarklyUser(authData = {}) {
    let user = {};

    const isUserAuthenticated = !!authData.token;
    if (isUserAuthenticated) {
        user = {
            key: authData.userId,
            name: authData.fullName,
            email: authData.username
        };
    } else {
        user = {
            key: "discovery-public-user",
            anonymous: true
        };
    }

    return user;
}

export function fetchFeatureFlagsClient(dispatch, getState) {
    const key = getState().root.variables.launchDarklyClientId;
    const authData = getState().authentication.authData;

    return new Promise(resolve => {
        if (key) {
            // Initialize LD
            const user = createLaunchDarklyUser(authData);
            const client = LaunchDarkly.initialize(key, user);

            // Get feature flag mappings
            client.on('ready', () => {
                const flags = client.allFlags();

                // Set Feature Flags
                dispatch(setFeatureFlags(flags));
                resolve(flags);
            });
        } else {
            resolve();
        }
    });
}
