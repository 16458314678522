import axios from 'axios';
import UserUtils from 'utils/UserUtils';
import PlatformService from 'common/services/PlatformService';
import SegmentService from 'common/services/SegmentService';
import ProductService from 'common/services/ProductService';
import SegmentUtils from '../SegmentUtils';
import {
    saveCurrentSegment
} from 'myhg/segment/actions';
import {
    NET_NEW_MATCHES_REQUEST,
    NET_NEW_MATCHES_SUCCESS,
    NET_NEW_MATCHES_FAILURE,
    NET_NEW_MATCHES_SET,
    SEGMENT_SUMMARY_REQUEST,
    SEGMENT_SUMMARY_SUCCESS,
    SEGMENT_SUMMARY_FAILURE,
    SEGMENT_SUMMARY_SET,
    SEGMENT_PREVIOUS_FILES_REQUEST,
    SEGMENT_PREVIOUS_FILES_SUCCESS,
    SEGMENT_PREVIOUS_FILES_FAILURE,
    SEGMENT_AVAILABLE_RECORDS_REQUEST,
    SEGMENT_AVAILABLE_RECORDS_SUCCESS,
    SEGMENT_AVAILABLE_RECORDS_FAILURE,
    PRODUCT_SUMMARY_REQUEST,
    PRODUCT_SUMMARY_SUCCESS,
    PRODUCT_SUMMARY_FAILURE,
    PRODUCT_SUMMARY_RESET
} from './constants';
import {
    SEGMENT_STATUS,
    MATCH_SOURCE_TYPE,
    DISPLAYED_SUMMARY_PRODUCTS_LIMIT
} from 'myhg/segment/constants';

export function loadPlatformNetNewMatches(campaignSessionId) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const { filters } = getState().myhg.segment.currentSegment.data;
        const { salesforceUsername } = getState().authentication.authData;

        dispatch({
            type: NET_NEW_MATCHES_REQUEST,
            cancelToken
        });

        return PlatformService.instance.netNewMatches(filters, campaignSessionId, cancelToken)
            .then(response => {
                const { data } = response;
                dispatch({
                    type: NET_NEW_MATCHES_SUCCESS,
                    data
                });
                // always save current segment on matching data
                dispatch(saveCurrentSegment({
                    status: SEGMENT_STATUS.MATCHED,
                    sourceValue: salesforceUsername,
                    companies: data.companies,
                    matched: data.matchedCompanies,
                    netNew: data.newCompanies,
                    people: data.people
                }));
            })
            .catch(error => {
                dispatch({
                    type: NET_NEW_MATCHES_FAILURE,
                    error
                });
            });
    };
}

export function loadPlatformAvailableRecords(campaignSessionId, saveSegmentFilters) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const { filters } = getState().myhg.segment.currentSegment.data;

        dispatch({
            type: SEGMENT_AVAILABLE_RECORDS_REQUEST,
            cancelToken
        });

        return PlatformService.instance
            .getAvailableRecords(filters, campaignSessionId, cancelToken)
            .then(response => {
                const { data } = response;
                dispatch({
                    type: SEGMENT_AVAILABLE_RECORDS_SUCCESS,
                    data
                });
                // always save current segment on matching data
                dispatch(saveCurrentSegment({
                    tableCountRecords: data.available
                }, {}, saveSegmentFilters));
            })
            .catch(error => {
                dispatch({
                    type: SEGMENT_AVAILABLE_RECORDS_FAILURE,
                    error
                });
            });
    };
}

export function loadFileNetNewMatches(fileId) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const { filters } = getState().myhg.segment.currentSegment.data;

        dispatch({
            type: NET_NEW_MATCHES_REQUEST,
            cancelToken
        });

        return SegmentService.instance.getFileMatchAndNetNew(filters, fileId, cancelToken)
            .then(response => {
                const { data } = response;
                dispatch({
                    type: NET_NEW_MATCHES_SUCCESS,
                    data
                });
                // always save current segment on matching data
                dispatch(saveCurrentSegment({
                    status: SEGMENT_STATUS.MATCHED,
                    source: MATCH_SOURCE_TYPE.FILE,
                    companies: data.companies,
                    matched: data.matchedCompanies,
                    netNew: data.newCompanies,
                    people: data.people,
                    sourceValue: fileId
                }, {
                    fileId
                }));
            })
            .catch(error => {
                dispatch({
                    type: NET_NEW_MATCHES_FAILURE,
                    error
                });
            });
    };
}

export function loadFileAvailableRecords(fileId, saveSegmentFilters) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const { filters } = getState().myhg.segment.currentSegment.data;

        dispatch({
            type: SEGMENT_AVAILABLE_RECORDS_REQUEST,
            cancelToken
        });

        return SegmentService.instance.getCSVAvailableRecords(filters, fileId, cancelToken)
            .then(response => {
                const { data } = response;
                dispatch({
                    type: SEGMENT_AVAILABLE_RECORDS_SUCCESS,
                    data
                });
                // always save current segment on matching data
                dispatch(saveCurrentSegment({
                    tableCountRecords: data.available
                }, {
                    fileId
                }, saveSegmentFilters));
            })
            .catch(error => {
                dispatch({
                    type: SEGMENT_AVAILABLE_RECORDS_FAILURE,
                    error
                });
            });
    };
}

export function loadSegmentSummary(shouldSaveSegment) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const { segment } = getState().myhg;
        const { id, data: { filters } } = segment.currentSegment;

        const productCompaniesPayload = {
            slugs: filters.slugs,
            globalMatch: true
        };

        const promises = [
            SegmentService.instance.getSegmentSummary(filters, id, cancelToken),
            SegmentService.instance.getSegmentSummary(productCompaniesPayload, id, cancelToken)
        ];

        dispatch({
            type: SEGMENT_SUMMARY_REQUEST,
            cancelToken
        });

        return axios.all(promises)
            .then(responses => {
                const { data } = responses[0];

                Object.assign(data, {
                    productCompanies: responses[1].data.companies
                });

                dispatch({
                    type: SEGMENT_SUMMARY_SUCCESS,
                    data
                });
                // save new segment status
                dispatch(saveCurrentSegment({
                    companies: data.companies,
                    productCompanies: data.productCompanies
                }, null, null, shouldSaveSegment));
            })
            .catch(error => {
                dispatch({
                    type: SEGMENT_SUMMARY_FAILURE,
                    error
                });
            });
    };
}

export function loadProductSummary(slug) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const { segment } = getState().myhg;
        const { id, data: { filters, status } } = segment.currentSegment;
        const productFilters = { ...filters, slugs: [slug] };
        // check if trial period expired or not for non paid users
        const accountDetails = getState().user.accountDetails.data;
        const isTrialExpired = UserUtils.isTrialExpired(accountDetails);

        dispatch({
            type: PRODUCT_SUMMARY_REQUEST,
            cancelToken
        });

        const productCompaniesPayload = {
            slugs: [slug],
            globalMatch: true
        };

        let promises = [];

        if (filters.matchAllProducts && !isTrialExpired) {
            promises = [
                Promise.resolve({
                    data: {
                        companies: status.companies,
                        newCompanies: status.netNew,
                        matchedCompanies: status.matched
                    }
                })
            ];
        } else {
            if (!status.source || isTrialExpired) {
                const segmentFilters = SegmentUtils.removeMatchesAndIncludeFilters(
                    productFilters, status, isTrialExpired);

                promises = [
                    SegmentService.instance.getSegmentSummary(
                        segmentFilters, id, cancelToken
                    )
                ];
            } else if (status.source === MATCH_SOURCE_TYPE.FILE) {
                promises = [
                    SegmentService.instance.getFileMatchAndNetNew(
                        productFilters, status.sourceValue, cancelToken
                    )
                ];
            } else {
                // platform matches call
                promises = [
                    PlatformService.instance.netNewMatches(
                        productFilters, id, cancelToken
                    )
                ];
            }
        }

        promises.push(
            SegmentService.instance.getSegmentSummary(
                productCompaniesPayload, id, cancelToken
            ),
            ProductService.instance.getProductDetailsById(productFilters.slugs)
        );

        return axios.all(promises)
            .then(responses => {
                const { data } = responses[0];

                Object.assign(data, {
                    slug,
                    productCompanies: responses[1].data.companies,
                    trend: responses[2].data.percent
                });

                dispatch({
                    type: PRODUCT_SUMMARY_SUCCESS,
                    data: {
                        slug: data.slug,
                        companies: data.companies,
                        productCompanies: data.productCompanies,
                        netNew: data.newCompanies,
                        matched: data.matchedCompanies,
                        trend: data.trend
                    }
                });
            })
            .catch(error => {
                dispatch({
                    type: PRODUCT_SUMMARY_FAILURE,
                    error
                });
            });
    };
}

export function loadProductsSummary(slugs, limit = DISPLAYED_SUMMARY_PRODUCTS_LIMIT) {
    return (dispatch) => {
        if (!slugs || !slugs.length) {
            return;
        }

        const products = limit ? slugs.slice(0, limit) : slugs;

        dispatch({
            type: PRODUCT_SUMMARY_RESET
        });

        products.forEach(product => {
            dispatch(loadProductSummary(product));
        });
    };
}

export function loadAvailableRecords(shouldSaveSegment) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const filters = getState().myhg.segment.currentSegment.data.filters;

        dispatch({
            type: SEGMENT_AVAILABLE_RECORDS_REQUEST,
            cancelToken
        });

        const currentState = getState();
        const { id } = currentState.myhg.segment.currentSegment;

        return SegmentService.instance.getAvailableRecords(filters, id, cancelToken)
            .then(response => {
                const { data } = response;
                dispatch({
                    type: SEGMENT_AVAILABLE_RECORDS_SUCCESS,
                    data
                });
                // save new segment status
                dispatch(saveCurrentSegment({
                    tableCountRecords: data.available
                }, null, null, shouldSaveSegment));
            })
            .catch(error => {
                dispatch({
                    type: SEGMENT_AVAILABLE_RECORDS_FAILURE,
                    error
                });
            });
    };
}

export function setMatchesBySegmentStatus(status) {
    return (dispatch) => {
        // set summary data
        dispatch({
            type: SEGMENT_SUMMARY_SET,
            data: {
                companies: status.companies
            }
        });

        // set net new data
        dispatch({
            type: NET_NEW_MATCHES_SET,
            data: {
                matchedCompanies: status.matched,
                newCompanies: status.netNew,
                people: status.people
            }
        });
    };
}

export function getPreviouslyMatchedFiles() {
    return (dispatch) => {
        dispatch({
            type: SEGMENT_PREVIOUS_FILES_REQUEST
        });

        ProductService.instance.getFiles()
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: SEGMENT_PREVIOUS_FILES_SUCCESS,
                    data
                });
            })
            .catch((error) => {
                dispatch({
                    type: SEGMENT_PREVIOUS_FILES_FAILURE,
                    error: error.message
                });
            });
    };
}
