import { combineReducers } from 'redux';
import firmographic from './firmographic/reducer';
import products from './products/reducer';
import collections from './collections/reducer';

export default combineReducers({
    firmographic,
    products,
    collections
});
