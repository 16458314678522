import { combineReducers } from 'redux';
import permissions from './permissions/reducer';
import accountDetails from './accountDetails/reducer';
import technologiesPermissions from './technologiesPermissions/reducer';
import { userPreferences, updateUserPreferences } from './userPreferences/reducer';

export default combineReducers({
    accountDetails,
    permissions,
    technologiesPermissions,
    userPreferences,
    updateUserPreferences
});
