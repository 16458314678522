import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

export default class CollectionSection extends React.Component {

    getIcon() {
        let content = null;

        if (this.props.iconClass) {
            const iconClassNames = classNames('icon', this.props.iconClass);
            content = <span className={ iconClassNames } />;
        }

        return content;
    }

    getTooltip() {
        const { tooltip } = this.props;
        let result = [];

        if (!!tooltip) {
            result = [
                <ReactTooltip
                    effect="solid"
                    place="top"
                    id={ `companies-tooltip-${tooltip.index}` }
                    offset={ { right: 10 } }
                    key="tooltip-component"
                />,
                <span
                    className="tooltip-info icon-info"
                    data-for={ `companies-tooltip-${tooltip.index}` }
                    data-tip={ tooltip.message }
                    data-html
                    key="tooltip-span"
                />
            ];
        }

        return result;
    }

    render() {
        const { title, itemCounts, children } = this.props;
        const collectionSectionClassName = classNames('collection-section', this.props.className);

        return (
            <div className={ collectionSectionClassName } >
                <span className="title">
                    { title }
                    { this.getTooltip() }
                </span>
                <div className="counts">
                    { this.getIcon() }
                    <span className="counts-value">
                        { itemCounts }
                    </span>
                    { children }
                </div>
            </div>
        );
    }
}
