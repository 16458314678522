import React from 'react';
import Helmet from 'react-helmet';
import ProductHeaderContainer from 'shared/header/ProductHeaderContainer';
import Footer from 'shared/footer/Footer';

export default class SegmentPage extends React.Component {

    render() {
        const title = 'Segment';

        return (
            <div>
                <Helmet
                    htmlAttributes= { { lang: 'en' } }
                    title= { title }
                />
                <ProductHeaderContainer />
                { this.props.children }
                <Footer />
            </div>
        );
    }
}
