import {
    FILTERS_SET_REVENUE_INTERVAL
} from '../constants';

export function setRevenueInterval(revenue) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_SET_REVENUE_INTERVAL,
            revenue
        });
    };
}
