import React from 'react';
import { ACCOUNT_TYPES_NAMES } from 'myhg/import/accounts/constants';
import {
    SEGMENT_STATUS,
    MATCH_ON_RECORD_TYPES,
    MATCH_SOURCE_TYPE,
    MATCH_SOURCE_NAMES
} from 'myhg/segment/constants';

export default class SegmentStatus extends React.Component {

    getActionValue(actionTitle, sourceValue, recordTypes = null) {
        const result = [];
        result.push(
            <span key="action" className="segment-status-action">
                { `${actionTitle}${!!sourceValue ? ':' : ''}` }
            </span>
        );

        if (!!sourceValue) {
            result.push(
                <span key="source" className="segment-status-source">
                    { sourceValue }
                </span>
            );
        }

        if (recordTypes) {
            const recordTypeNames = recordTypes.map((item) => {
                return ACCOUNT_TYPES_NAMES[item];
            });

            result.push(
                <div key="recordType">
                    { MATCH_ON_RECORD_TYPES }
                    <span className="segment-status-record-type">
                        { recordTypeNames.join(', ') }
                    </span>
                </div>
            );
        }

        return result;
    }

    getMatchedStatus() {
        const { source, sourceValue, fileName } = this.props.segmentStatus;
        const { recordTypes } = this.props;
        const title = `Matched to ${MATCH_SOURCE_NAMES[source]}`;
        let content;

        switch (source) {
            case MATCH_SOURCE_TYPE.FILE:
                content = this.getActionValue(
                    title,
                    fileName
                );
                break;

            default:
                content = this.getActionValue(
                    title,
                    sourceValue,
                    recordTypes
                );
                break;
        }

        return content;
    }

    getExportedStatus() {
        const { source, campaignName } = this.props.segmentStatus;
        let content;

        switch (source) {
            case MATCH_SOURCE_TYPE.SALESFORCE:
                content = this.getActionValue(
                    'Salesforce Campaign Created',
                    campaignName
                );
                break;

            case MATCH_SOURCE_TYPE.MARKETO:
                content = this.getActionValue(
                    'Marketo List Appended',
                    campaignName
                );
                break;

            case MATCH_SOURCE_TYPE.ELOQUA:
                content = this.getActionValue(
                    'Eloqua Campaign Created',
                    campaignName
                );
                break;

            case MATCH_SOURCE_TYPE.FILE:
                content = this.getActionValue(
                    'Exported to File'
                );
                break;

            default:
                content = null;
                break;
        }

        return content;
    }

    getStatus() {
        const { status } = this.props.segmentStatus;
        let content;

        switch (status) {
            case SEGMENT_STATUS.MATCHED:
                content = this.getMatchedStatus();
                break;

            case SEGMENT_STATUS.EXPORTED:
                content = this.getExportedStatus();
                break;

            default:
                content = null;
                break;
        }

        return content;
    }

    render() {
        return (
            <div className="segment-status">
                { this.getStatus() }
            </div>
        );
    }
}
