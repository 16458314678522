import React from 'react';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import matchMedia from 'matchmedia';
import HeaderDropDown from 'shared/header/HeaderDropDown';
import ProductHeader from './ProductHeader';
import {
    logout
} from 'discovery/authenticationModal/actions';
import { selectNewProduct } from 'discovery/product/actions';
import {
    LOG_OUT_REASON_USER_REQUEST
} from 'discovery/authenticationModal/constants';
import {
    HIDDEN,
    BREAKPOINT_LG,
    VISIBLE
} from 'shared/constants';
import {
    ACTION,
    WHERE
} from 'utils/track-constants';
import {
    track
} from 'utils/Utils';
import UserUtils from 'utils/UserUtils';
import DeprecationBanner from "../deprecation/DeprecationBanner";

function mapStateToProps(state) {
    return {
        productDetails: state.product.productDetails,
        authData: state.authentication.authData,
        accountDetailsData: state.user.accountDetails.data,
        locationPath: state.root.locationPath,
        featureFlags: state.root.featureFlags
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

class ProductHeaderContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDown: HIDDEN
        };
    }

    componentDidMount() {
        const mql = matchMedia(`(max-width: ${BREAKPOINT_LG})`);
        mql.addListener(this.mediaQueryChanged);
        window.setTimeout(() => {
            this.setState({
                mql,
                deviceMode: mql.matches,
                mounted: true
                // show the login/logout component only when mounted
                // reason: matchMedia not available on server side rendering
            });
        }, 0);
    }

    componentWillUnmount() {
        if (this.state.mql) {
            this.state.mql.removeListener(this.mediaQueryChanged);
        }
    }

    onLogout = () => {
        this.props.dispatch(logout(LOG_OUT_REASON_USER_REQUEST));
        this.setState({ dropDown: HIDDEN });
    }

    onTechnologySelect = (technology) => {
        if (technology && technology.slug) {
            this.props.dispatch(selectNewProduct(technology.slug));
            track(ACTION.VIEW_PRODUCT, { label: WHERE.PRODUCT_SEARCH });
        }
    }

    getHeaderComponent() {
        return (
            <>
                <DeprecationBanner />

                <ProductHeader
                        { ...this.props }
                        dropdownState={ this.state.dropDown }
                        logOutNavbar={ this.logOutNavbar }
                        onTechnologySelect={ this.onTechnologySelect }
                        deviceMode={ this.state.deviceMode }
                        mounted={ this.state.mounted }
                        isUserAuthenticated={ this.isUserAuthenticated() }
                />
            </>
        );
    }

    mediaQueryChanged = () => {
        this.setState({ deviceMode: this.state.mql.matches });
    };

    handleClickOutside = () => {
        this.setState({ dropDown: HIDDEN });
    };

    toggleDropDown = () => {
        if (this.state.dropDown === HIDDEN) {
            this.setState({ dropDown: VISIBLE });
        } else {
            this.setState({ dropDown: HIDDEN });
        }
    };

    logOutNavbar = (inject) => {
        const className = classNames('dropdown-container', this.state.dropDown);
        return (
            <HeaderDropDown
                authData={ this.props.authData }
                className={ className }
                onLogout={ this.onLogout }
                toggleDropDown={ this.toggleDropDown }
                handleClickOutSide={ this.handleClickOutside }
            >
                { inject }
            </HeaderDropDown>
        );
    }

    isPaidUser() {
        const { accountDetailsData } = this.props;
        return UserUtils.isPaid(accountDetailsData);
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    render() {
        return (
            <div className="top-header-container">
                <div className="top-header-content-wrapper">
                    { this.getHeaderComponent() }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(ProductHeaderContainer));
