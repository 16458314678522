export const USER_PERMISSIONS_REQUEST = 'USER_PERMISSIONS_REQUEST';
export const USER_PERMISSIONS_FAILURE = 'USER_PERMISSIONS_FAILURE';
export const USER_PERMISSIONS_SUCCESS = 'USER_PERMISSIONS_SUCCESS';

export const PERMISSION_TYPE = {
    CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
    DOWNLOAD_SAMPLE: 'DOWNLOAD_SAMPLE',
    APPEND_LIST: 'APPEND_LIST',
    CREATE_SEGMENT: 'CREATE_SEGMENT'
};
