import React from 'react';
import ReactDOM from 'react-dom';
import VerticalBarChart from 'shared/ui/charts/VerticalBarChart';
import FirmographicUtils from 'utils/FirmographicUtils';
import { NumeralUtils } from 'utils/Utils';
import { setEmployeesInterval } from 'myhg/segment/segmentFilters/employeesFilter/actions';

/**
 * Component that renders the Employees Chart
 */
export default class EmployeesChart extends React.Component {

    componentDidMount() {
        this.barChart = new VerticalBarChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        this.barChart.update(this.getChartState());
    }

    componentWillUnmount() {
        this.barChart.destroy(this.getDOMNode());
    }

    onEmployeeSelect = (data, index) => {
        const { dispatch } = this.props;
        const { data: { employeesSteps } } = this.props.firmographic;
        const employeesIntervals =
            FirmographicUtils.getIntervalsIncludingBoundaries(employeesSteps);

        const employeesInterval = {
            from: employeesIntervals[index],
            to: employeesIntervals[index + 1],
        };
        this.props.createNewProductSegment();
        dispatch(setEmployeesInterval(employeesInterval));
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getCorrectRangeForChart() {
        if (this.props.data) {
            return this.props.data.filter(range => {
                return (range.from > 0 && range.to > 0);
            });
        }

        return [];
    }

    getChartState() {
        const data = this.getCorrectRangeForChart();
        return {
            yGrid: true,
            shadowBar: false,
            data: FirmographicUtils.parseDomainUnformattedData(data),
            tooltipData: (d) => {
                const range = d.name.indexOf('-') < 0 ? '' : 'between';
                return `<span>Number of companies worldwide with</br>
                    employees ${range} ${d.name}</span></br>
                    <span class='high-light'>${NumeralUtils.formatIntegerNumber(d.value)}</span>`;
            },
            onClick: this.onEmployeeSelect
        };
    }

    render() {
        return (
            <div id="employees-bar-chart" className="bar-chart-container">
            </div>
        );
    }
}
