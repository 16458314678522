import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import ModalWizardWrapper from 'shared/ui/modalWizard/ModalWizardWrapper';

function mapStateToProps(state) {
    return {
        segmentId: state.myhg.segment.currentSegment.id
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

/**
 * Import Page Wrapper
 */
export default class ImportPageContainerWrapper extends React.Component {

    onBack = () => {
        browserHistory.goBack();
    }

    onClose = () => {
        const { segmentId, onlyGoBack } = this.props;
        if (segmentId && !onlyGoBack) {
            browserHistory.push(`/dashboard/segment/view/${segmentId}`);
        } else if (onlyGoBack) {
            browserHistory.goBack();
        } else {
            browserHistory.push('/dashboard');
        }
    }

    render() {
        return (
            <ModalWizardWrapper
                title={ this.props.title }
                onClose={ this.onClose }
                onBack={ this.onBack }
                className={ this.props.className }
                customHeader={ this.props.customHeader }
            >
                { this.props.children }
            </ModalWizardWrapper>
        );
    }
}
