import React from 'react';
import ExistingCampaigns from './ExistingCampaigns';
import NewCampaignForm from './NewCampaignForm';
import TabMenu from 'shared/ui/tabs/TabMenu';
import {
    clearSaveCampaignError
} from 'myhg/export/actions';
import {
    EXPORT_CONFIGS,
    EXPORT_SUB_TITLE
} from '../constants';

export default class ExportTabs extends React.Component {

    constructor(props) {
        super(props);

        const { data: { status: { source } } } = this.props.currentSegment;
        const menuTitles = EXPORT_CONFIGS[source].MENUS;
        const menus = menuTitles.map((menuTitle, index) => {
            return {
                displayName: menuTitle,
                name: index
            };
        });

        this.state = {
            menus,
            selectedMenu: menus[0]
        };
    }

    getContentByTabSelection(menu) {
        let tabComponent;

        switch (menu.name) {
            case 0:
                tabComponent = (
                    <ExistingCampaigns
                        {...this.props}
                    />
                );
                break;

            case 1:
                tabComponent = (
                    <NewCampaignForm
                        {...this.props}
                    />
                );
                break;

            default:
                tabComponent = <ExistingCampaigns {...this.props} />;
        }

        return tabComponent;
    }

    menuItemChanged(menu) {
        this.setState({
            selectedMenu: menu
        });
        this.props.dispatch(clearSaveCampaignError());
    }

    render() {
        const { selectedMenu } = this.state;
        const content = this.getContentByTabSelection(selectedMenu);

        return (
            <div className="platform-export-container">
                <h4 className="sub-title">
                    { EXPORT_SUB_TITLE }
                </h4>
                <TabMenu
                    className="platform-export-menu"
                    menus={ this.state.menus }
                    selectedMenu={ selectedMenu }
                    menuItemChanged={ this.menuItemChanged.bind(this) }
                    {...this.props}
                />
                { content }
            </div>
        );
    }
}
