import React from 'react';
import { connect } from 'react-redux';
import Checkbox from 'shared/ui/checkbox/Checkbox';
import {
    addUnknowValuesId,
    removeUnknowValuesId
} from './actions';
import {
    UNKNOWN_VALUES_OPTIONS
} from './constants';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        includeUnknownFirmos: segment.segmentFilters.includeUnknownFirmos
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class UnknownValuesFilterContainer extends React.Component {

    onChange(item, isChecked) {
        const { dispatch } = this.props;

        if (isChecked) {
            dispatch(addUnknowValuesId(item.id));
        } else {
            dispatch(removeUnknowValuesId(item.id));
        }
    }

    getUnknownValueCheckbox() {
        const { unknownValuesId, includeUnknownFirmos } = this.props;
        // get option item from available list
        const option = UNKNOWN_VALUES_OPTIONS.find((item) => {
            return item.id === unknownValuesId;
        });

        if (!option) {
            return null;
        }

        // determine if item is checked by checking presence in filters array
        const isItemChecked = includeUnknownFirmos.indexOf(unknownValuesId) >= 0;

        return (
            <Checkbox
                className={ unknownValuesId }
                key={ unknownValuesId }
                value={ isItemChecked }
                onChange={ this.onChange.bind(this, option) }
            />
        );
    }

    render() {
        return (
            <div>
                { this.getUnknownValueCheckbox() }
            </div>
        );
    }
}
