import React from 'react';
import RecentlyCreatedSegments from '../recentlyCreatedSegments/RecentlyCreatedSegments';
import TopHeaderContainer from '../topHeader/TopHeaderContainer';
import TrialNotificationContainer from '../trial/TrialNotificationContainer';
import UserUtils from 'utils/UserUtils';

export default class MyHgHome extends React.Component {
    render() {
        const { accountDetails } = this.props;
        const isPaidUser = UserUtils.isPaid(accountDetails);

        return (
            <div id="myhg-home" className="content-wrapper myhg-home">
                <TopHeaderContainer />
                { !isPaidUser ? <TrialNotificationContainer /> : null }
                <RecentlyCreatedSegments { ...this.props } />
            </div>
        );
    }
}
