import React from 'react';
import LinkedInConnectWrapper from './LinkedInConnectWrapper';

export default class LinkedInConnect extends React.Component {
    render() {
        return (
            <LinkedInConnectWrapper { ...this.props } />
        );
    }
}
