import React from 'react';
import { connect } from 'react-redux';
import {
    browserHistory,
    withRouter
} from 'react-router';
import Guid from 'guid';
import { UrlUtils, track } from 'utils/Utils';
import ShareSegment from './components/ShareSegment';
import ModalWizardWrapper from 'shared/ui/modalWizard/ModalWizardWrapper';
import {
    shareViaEmails,
    addShareEmail,
    removeShareEmail,
    cleanShareEmail,
    postSegmentShare
} from './actions';
import { ACTION, WHERE } from 'utils/track-constants';

function mapStateToProps(state) {
    const { shareViaEmail } = state.myhg.segment;
    return {
        prevLocationPath: state.root.prevLocationPath,
        currentSegment: state.myhg.segment.currentSegment,
        authData: state.authentication.authData,
        shareSuccess: shareViaEmail.shareSuccess,
        shareError: shareViaEmail.shareError,
        shareEmails: shareViaEmail.shareEmails
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

class ShareSegmentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shareId: Guid.raw()
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(postSegmentShare(this.state.shareId, this.props.currentSegment));
    }

    componentWillUnmount() {
        this.clear();
    }

    onClose = () => {
        const { prevLocationPath } = this.props;

        if (prevLocationPath) {
            this.props.router.goBack();
        } else {
            browserHistory.push('/dashboard');
        }
    };

    getSegmentUrl = (encodeURI) => {
        let segmentUrl = `${UrlUtils.getHostUrl()}/segment/${this.state.shareId}`;

        if (encodeURI) {
            segmentUrl = encodeURIComponent(segmentUrl);
        }

        return segmentUrl;
    }

    handleShareViaEmail = () => {
        const segmentUrl = this.getSegmentUrl();
        track(ACTION.SHARE_SEGMENT_CLIENT_EMAIL, { label: WHERE.SHARE_SEGMENT_PAGE });
        const myWindow = window.open('mailto:?&subject=Check out this segment&'
            .concat('body=Check%20out%20this%20segment%3A%20').concat(segmentUrl),
            '_blank',
            'width=200, height=100');
        setTimeout(() => {
            myWindow.close();
        }, 1000);
    };

    handleShareViaLinkedIn = () => {
        const segmentUrl = this.getSegmentUrl(true);
        track(ACTION.SHARE_SEGMENT_CLIENT_LINKEDIN, { label: WHERE.SHARE_SEGMENT_PAGE });
        return `https://www.linkedin.com/shareArticle?mini=true&url=${segmentUrl}&title=&summary=Check%20out%20this%20segment%3A%20`;
    };

    handleShareViaTwitter = () => {
        const segmentUrl = this.getSegmentUrl(true);
        track(ACTION.SHARE_SEGMENT_CLIENT_TWITTER, { label: WHERE.SHARE_SEGMENT_PAGE });
        return `https://twitter.com/intent/tweet?text=Check%20out%20this%20segment%3A%20&url=${segmentUrl}`;
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    handleSendEmails = (emails) => {
        const { dispatch } = this.props;
        const segmentUrl = this.getSegmentUrl();
        const segmentName = this.props.currentSegment.name;

        track(ACTION.SHARE_SEGMENT_CLIENT_API, { label: WHERE.SHARE_SEGMENT_PAGE });
        dispatch(shareViaEmails(segmentName, segmentUrl, emails));
    }

    addEmail = (email) => {
        const { dispatch } = this.props;
        dispatch(addShareEmail(email.toLowerCase()));
    }

    removeEmail = (index) => {
        const { dispatch } = this.props;
        dispatch(removeShareEmail(index));
    }

    clear = () => {
        const { dispatch } = this.props;
        dispatch(cleanShareEmail());
    }

    render() {
        return (
            <ModalWizardWrapper
                onClose={ this.onClose }
                onBack={ this.onClose }
            >
                <ShareSegment
                    shareViaEmail={ this.handleShareViaEmail }
                    segmentUrl={ this.getSegmentUrl }
                    shareViaLinkedIn={ this.handleShareViaLinkedIn }
                    shareViaTwitter={ this.handleShareViaTwitter }
                    sendEmails={ this.handleSendEmails }
                    addEmail={ this.addEmail }
                    removeEmail={ this.removeEmail }
                    isUserAuthenticated={ this.isUserAuthenticated() }
                    clearState={ this.clear }
                    { ...this.props }
                />
            </ModalWizardWrapper>
        );
    }
}

export default withRouter(ShareSegmentContainer);
