import React from 'react';
import SegmentName from './SegmentName';
import SegmentCompaniesCounterContainer from '../segmentMatches/SegmentCompaniesCounterContainer';

export default class SegmentTopSection extends React.Component {

    render() {
        return (
            <div id="segment-top-section" className="row">
                <div className="col-lg-6">
                    <SegmentName { ...this.props } />
                </div>
                <div className="col-lg-6">
                    <div className="segment-matches-section">
                        <SegmentCompaniesCounterContainer />
                    </div>
                </div>
            </div>
        );
    }
}
