import { combineReducers } from 'redux';
import {
    EXISTING_CAMPAIGNS_REQUEST,
    EXISTING_CAMPAIGNS_SUCCESS,
    EXISTING_CAMPAIGNS_FAILURE,
    EXISTING_CAMPAIGNS_TYPE,
    EXISTING_CAMPAIGNS_SELECT,
    NEW_CAMPAIGN_SET,
    SAVE_CAMPAIGN_REQUEST,
    SAVE_CAMPAIGN_SUCCESS,
    SAVE_CAMPAIGN_FAILURE,
    SAVE_CAMPAIGN_CLEAR_FAILURE
} from './constants';

export const existingCampaignsInitialState = {
    isFetching: false,
    error: null
};

export const saveCampaignInitialState = {
    isLoading: false,
    data: {},
    error: null,
    success: false
};

export const selectedCampaignInitialState = null;

export function parseCampaignsforDropdown(items) {
    // extract campaigns
    let campaigns = items.map((campaign) => {
        return {
            id: campaign.id,
            text: campaign.name
        };
    });

    // sort alphabetically
    campaigns = campaigns.sort((item1, item2) => {
        return item1.text.localeCompare(item2.text);
    });

    return [{
        type: EXISTING_CAMPAIGNS_TYPE,
        items: campaigns
    }];
}

export function selectedCampaign(state = selectedCampaignInitialState, action) {
    switch (action.type) {
        case EXISTING_CAMPAIGNS_SELECT:
            return action.campaign;

        case NEW_CAMPAIGN_SET:
            return action.campaign;

        default:
            return state;
    }
}

export function existingCampaigns(state = existingCampaignsInitialState, action) {
    switch (action.type) {
        case EXISTING_CAMPAIGNS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: null,
                error: null
            });

        case EXISTING_CAMPAIGNS_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null
            });

        case EXISTING_CAMPAIGNS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
}

export function saveCampaign(state = saveCampaignInitialState, action) {
    switch (action.type) {
        case SAVE_CAMPAIGN_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                error: null,
                success: false
            });

        case SAVE_CAMPAIGN_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                data: action.data,
                success: true
            });

        case SAVE_CAMPAIGN_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                success: false
            });

        case SAVE_CAMPAIGN_CLEAR_FAILURE:
            return Object.assign({}, state, {
                error: null
            });

        default:
            return state;
    }
}

export default combineReducers({
    existingCampaigns,
    selectedCampaign,
    saveCampaign
});
