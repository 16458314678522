import React from 'react';
import Helmet from 'react-helmet';
import Footer from 'shared/footer/Footer';
import { setApplicationError } from './actions';

export default class Error extends React.Component {

    componentDidMount() {
        window.onpopstate = (event) => {
            event.preventDefault();
            // clear out global application error on go back
            this.props.dispatch(setApplicationError(null));
        };
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    getErrorCode() {
        let content = '';
        const { applicationError: error } = this.props;
        if (error.code) {
            content = (
                <p className="error-id">
                    internal server error id
                    <span> { error.code } </span>
                </p>
            );
        }
        return content;
    }

    getErrorContent() {
        return (
            <div className="content-container">
                <div className="logo-icon"></div>
                <p className="title">
                    Oh no! Something went wrong.
                </p>
                { this.getErrorCode() }
                <p className="support">
                    Please try that again, and if it still doesn't work, contact
                    our customer support team with more details at
                    <span>
                        customersupport@hgdata.com
                    </span>
                    or call
                    <span>
                        805-880-1100
                    </span>
                </p>
            </div>
        );
    }

    render() {
        const title = 'Oh no! Something went wrong. | HG Insights';
        const description = '';
        const keywords = '';

        return (
            <div>
                <Helmet
                    htmlAttributes= { { lang: 'en' } }
                    title= { title }
                    meta={ [
                    { name: 'description', content: description },
                    { name: 'keywords', content: keywords }
                    ] }
                />
                <div id="error-page">
                    <div className="top-banner">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                   { this.getErrorContent() }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
           </div>
        );
    }
}
