export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_RESET_STATE = 'RESET_PASSWORD_RESET_STATE';

// login form texts
export const LOGIN_NO_ACCOUNT_YET = 'Don\'t have an account yet? ';
export const LOGIN_SIGN_UP_LINK_TEXT = 'Sign Up Now';
export const LOGIN_TITLE = 'Login to your HG Account';
