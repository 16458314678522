import PlatformService from 'common/services/PlatformService';
import {
    PLATFORM_CONTACTS_REQUEST,
    PLATFORM_CONTACTS_SUCCESS,
    PLATFORM_CONTACTS_FAILURE,
    RECORD_TYPE_ADD,
    RECORD_TYPE_REMOVE,
    RECORD_TYPE_CLEAR,
    RECORD_TYPE_SET
} from './constants';

export function getAccountsStatus(callback) {
    return (dispatch) => {
        dispatch({
            type: PLATFORM_CONTACTS_REQUEST,
            success: false
        });

        // USE common 'cloud element' api used to get loading status
        return PlatformService.instance.getContactsStatus()
            .then((response) => {
                dispatch({
                    type: PLATFORM_CONTACTS_SUCCESS,
                    success: true,
                    data: response.data
                });
                if (callback) {
                    callback(response.data);
                }
            })
            .catch((error) => {
                dispatch({
                    type: PLATFORM_CONTACTS_FAILURE,
                    success: false,
                    error
                });
            });
    };
}

export function addRecordType(recordType) {
    return (dispatch) => {
        return dispatch({
            type: RECORD_TYPE_ADD,
            recordType
        });
    };
}

export function removeRecordType(recordType) {
    return (dispatch) => {
        return dispatch({
            type: RECORD_TYPE_REMOVE,
            recordType
        });
    };
}

export function clearRecordType() {
    return (dispatch) => {
        return dispatch({
            type: RECORD_TYPE_CLEAR
        });
    };
}

export function setRecordsType(recordTypes) {
    return (dispatch) => {
        return dispatch({
            recordTypes,
            type: RECORD_TYPE_SET
        });
    };
}
