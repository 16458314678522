import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authorizeCode } from './actions';
import ApiErrorMessageHelper from 'utils/ApiErrorMessageHelper';
import { UrlUtils } from 'utils/Utils';

export default function (ComposedComponent) {
    class OAuthAuthorizeCode extends Component {
        componentDidMount() {
            if (this.isAuthenticated()) {
                return;
            }

            const { code, state } = this.props.location.query;
            let stateObject = {};

            if (state) {
                stateObject = UrlUtils.decodeQueryParamObject(state);
            }

            if (code) {
                this.props.dispatch(authorizeCode(
                    code, stateObject.platform, stateObject.campaignSessionId));
            }
        }

        getErrorData = () => {
            const { error, error_description } = this.props.location.query;
            let queryError = null;
            if (error) {
                queryError = ApiErrorMessageHelper.
                    getNormalizedErrorDataFromOauthQuery(error, error_description);
            }
            const errorData = this.props.oAuthConnect.error || queryError;
            return errorData;
        }

        isAuthenticated() {
            return this.props.oAuthConnect.isAuthenticated;
        }

        render() {
            return (
                <ComposedComponent
                    { ...this.props }
                    getErrorData={ this.getErrorData }
                    isAuthenticated={ this.isAuthenticated() }
                />
            );
        }
    }

    function mapStateToProps(state) {
        return { oAuthConnect: state.oAuthConnect };
    }

    return connect(mapStateToProps)(OAuthAuthorizeCode);
}
