import { setupAuthenticationData } from 'discovery/authenticationModal/actions';
import {
    MARKETO_AUTHORIZE,
    MARKETO_CONNECT_REQUEST,
    MARKETO_CONNECT_SUCCESS,
    MARKETO_CONNECT_ERROR
} from './constants';
import MarketoService from 'common/services/MarketoService';

export function marketoConnect(connectData, segmentId) {
    return (dispatch) => {
        dispatch({
            type: MARKETO_CONNECT_REQUEST
        });

        // Dispatch vanilla actions asynchronously
        return MarketoService.instance.connect(connectData, segmentId)
            .then((response) => {
                dispatch({
                    type: MARKETO_CONNECT_SUCCESS
                });

                // set new authentication data to reflect new platform auth
                return dispatch(setupAuthenticationData(
                    response.data,
                    MARKETO_AUTHORIZE
                ));
            })
            .catch((error) => {
                dispatch({
                    type: MARKETO_CONNECT_ERROR,
                    error
                });

                throw error;
            });
    };
}
