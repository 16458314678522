import {
    USER_LOAD_PREFERENCES_SUCCESS,
    USER_LOAD_PREFERENCES_FAILURE,
    USER_SAVE_PREFERENCES_SUCCESS,
    USER_SAVE_PREFERENCES_FAILURE
} from './constants';

export const initialState = {
    data: null
};

export function userPreferences(state = initialState, action) {
    switch (action.type) {
        case USER_LOAD_PREFERENCES_SUCCESS:
            return Object.assign({}, state, {
                fetched: false,
                data: action.data
            });

        case USER_LOAD_PREFERENCES_FAILURE:
            return Object.assign({}, state, {
                fetched: true,
                error: action.error
            });

        default:
            return state;
    }
}


export function updateUserPreferences(state = {}, action) {
    switch (action.type) {
        case USER_SAVE_PREFERENCES_SUCCESS :
            return Object.assign({}, state, {
                added: true,
                error: null
            });

        case USER_SAVE_PREFERENCES_FAILURE:
            return Object.assign({}, state, {
                error: action.error
            });

        default:
            return state;
    }
}
