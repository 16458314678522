import { combineReducers } from 'redux';
import collections from './collections/reducer';
import sampleCompanies from './sampleCompanies/reducer';
import similarProducts from './similarProducts/reducer';
import {
    LOAD_PRODUCT_DETAILS_REQUEST,
    LOAD_PRODUCT_DETAILS_SUCCESS,
    LOAD_PRODUCT_DETAILS_FAILURE,
    SET_NEW_PRODUCT_ID,
    INCREASE_INSIGHTS_INDEX,
    DECREASE_INSIGHTS_INDEX,
    TOGGLE_FAVORITE_PRODUCT,
    UPDATE_FAVORITE_PRODUCT_REQUEST,
    UPDATE_FAVORITE_PRODUCT_SUCCESS,
    UPDATE_FAVORITE_PRODUCT_FAILURE,
    TOGGLE_DESCRIPTION
} from './constants';

export const productDetailsInitialState = {
    isFetching: true,
    error: null,
    data: {
        product: null,
        productId: null,
        vendorUrl: null,
        category: null,
        categoryId: null,
        slug: null,
        insights: [],
        attributes: [],
        favorite: false
    },
    slug: null,
    insightsIndex: 0,
    showFullDescription: false
};

export function productDetails(state = productDetailsInitialState, action) {
    switch (action.type) {
        case LOAD_PRODUCT_DETAILS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null,
                slug: action.productId,
                insightsIndex: 0
            });

        case LOAD_PRODUCT_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null
            });

        case LOAD_PRODUCT_DETAILS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        case SET_NEW_PRODUCT_ID:
            return Object.assign({}, state, {
                isFetching: true,
                slug: action.productId
            });

        case INCREASE_INSIGHTS_INDEX: {
            let insightsIndex = state.insightsIndex + 1;
            if (insightsIndex === state.data.insights.length) {
                insightsIndex = 0;
            }
            return Object.assign({}, state, {
                insightsIndex
            });
        }

        case DECREASE_INSIGHTS_INDEX: {
            let insightsIndex = state.insightsIndex - 1;
            if (insightsIndex < 0) {
                insightsIndex = state.data.insights.length - 1;
            }
            return Object.assign({}, state, {
                insightsIndex
            });
        }

        case TOGGLE_DESCRIPTION: {
            return Object.assign({}, state, {
                showFullDescription: !state.showFullDescription
            });
        }

        case TOGGLE_FAVORITE_PRODUCT: {
            const newObject = Object.assign({}, state);
            newObject.data.favorite = !state.data.favorite;
            return newObject;
        }

        default:
            return state;
    }
}

export function updateFavoriteProduct(state = {}, action) {
    switch (action.type) {
        case UPDATE_FAVORITE_PRODUCT_REQUEST :
            return Object.assign({}, state, {
                inProgress: true,
                error: null
            });

        case UPDATE_FAVORITE_PRODUCT_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                error: null
            });

        case UPDATE_FAVORITE_PRODUCT_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                error: action.error
            });

        default:
            return state;
    }
}

export default combineReducers({
    productDetails,
    updateFavoriteProduct,
    collections,
    sampleCompanies,
    similarProducts
});
