import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import TabMenu from 'shared/ui/tabs/TabMenu';
import RevenueChart from 'shared/firmographics/components/RevenueChart';
import IndustryChart from 'shared/firmographics/components/IndustryChart';
import EmployeesChart from 'shared/firmographics/components/EmployeesChart';
import LocationsChart from 'shared/firmographics/components/LocationsChart';
import {
    FIRMOGRAPHIC_LOCATIONS_ROUTE,
    FIRMOGRAPHIC_EMPLOYEES_ROUTE,
    FIRMOGRAPHIC_REVENUE_ROUTE,
    FIRMOGRAPHIC_INDUSTRY_ROUTE,
} from './constants';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import loadFirmographicTaxonomy from 'shared/taxonomy/firmographic/actions';
import { setTechnologies } from 'myhg/segment/segmentFilters/technologiesFilter/actions';
import { resetSegmentView } from 'myhg/segment/actions';

function mapStateToProps(state) {
    return {
        firmographic: state.taxonomy.firmographic,
        productDetails: state.product.productDetails
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class FirmographicsTable extends React.Component {

    componentDidMount() {
        const {
            dispatch,
            firmographic
        } = this.props;

        if (!firmographic.data && !firmographic.isFetching) {
            dispatch(loadFirmographicTaxonomy());
        }
    }

    getTabContent() {
        const { errorMessage, selectedMenu, data } = this.props;

        if (errorMessage) {
            return errorMessage;
        }

        let tabComponent;
        switch (selectedMenu.name) {
            case FIRMOGRAPHIC_LOCATIONS_ROUTE:
                tabComponent = (
                    <LocationsChart
                        { ...this.props }
                        data={ data.locations }
                        createNewProductSegment={ this.newProductSegment }
                    />
                );
                break;

            case FIRMOGRAPHIC_EMPLOYEES_ROUTE:
                tabComponent = (
                    <EmployeesChart
                        { ...this.props }
                        data={ data.employees }
                        createNewProductSegment={ this.newProductSegment }
                    />
                );
                break;

            case FIRMOGRAPHIC_REVENUE_ROUTE:
                tabComponent = (
                    <RevenueChart
                        { ...this.props }
                        data={ data.revenue }
                        createNewProductSegment={ this.newProductSegment }
                    />
                );
                break;

            case FIRMOGRAPHIC_INDUSTRY_ROUTE:
                tabComponent = (
                    <IndustryChart
                        { ...this.props }
                        data={ data.industry }
                        createNewProductSegment={ this.newProductSegment }
                    />
                );
                break;

            default:
                tabComponent = null;
        }

        return tabComponent;
    }

    newProductSegment = () => {
        const { dispatch, productDetails } = this.props;

        dispatch(resetSegmentView());
        dispatch(setTechnologies([productDetails.slug]));
        browserHistory.push('/segment');
    }

    menuItemChanged(menuItem) {
        this.props.onTabSelection(menuItem);
        track(ACTION.FIRMOGRAPHIC_TAB, { label: menuItem.name });
    }

    render() {
        const {
            menus,
            selectedMenu
        } = this.props;

        return (
            <div>
                <TabMenu
                    menus={ menus }
                    selectedMenu={ selectedMenu }
                    menuItemChanged={ this.menuItemChanged.bind(this) }
                />
                { this.getTabContent() }
            </div>
        );
    }
}

FirmographicsTable.propTypes = {
    menus: PropTypes.array.isRequired,
    selectedMenu: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};
