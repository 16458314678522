import linkHeaderParser from 'parse-link-header';
import Symbol from 'es6-symbol';
import axios from 'axios';
import qs from 'qs';
import {
    browserHistory
} from 'react-router';
import ApiErrorMessageHelper from 'utils/ApiErrorMessageHelper';
import {
    logout
} from 'discovery/authenticationModal/actions';
import {
    setApplicationError
} from 'common/root/actions';
import {
    setPlatformGlobalError
} from 'myhg/platformGlobalError/actions';
import {
    LOG_OUT_REASON_FORBIDDEN
} from 'discovery/authenticationModal/constants';
import {
    HTTP_UNAUTHORIZED_CODE,
    HTTP_PRECONDITION_FAILED,
    API_ERROR_TYPE
} from 'shared/constants';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Object that provides access to making HTTP requests using axios and
 * has the base configuration setup
 *
 * * * * * * * * IMPORTANT !!!! server side issue * * * * * * * * *
 * TODO: Investigate and come with a solution so that APIs Wrappers/Services
 * are not shared across client requests. Same api wrapper instance is used by concurent requests
 * overwritting the store/auth token resulting in unexpected behavior.
 * * * * * * * * IMPORTANT !!!! server side issue * * * * * * * * *
 */
export default class ApiWrapper {

    constructor(enforcer) {
        // Disable self-signed certificate warnings for the API calls
        // FIXME: set this based on a FLAG that can be set by npm start
        process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;
        this.axios = axios.create({
            headers: {
                common: {},
                post: {}
            }
        });

        this.axios.defaults.timeout = 25000;
        this.axios.defaults.headers.post['Content-Type'] = 'application/json';
        this.axios.defaults.paramsSerializer = (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        };

        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new ApiWrapper(singletonEnforcer);
        }

        return this[singleton];
    }

    removeAuthorizationHeader() {
        delete this.axios.defaults.headers.common.Authorization;
    }

    setAuthorizationHeader(token) {
        if (token) {
            this.axios.defaults.headers.common.Authorization = token;
        } else {
            this.removeAuthorizationHeader();
        }
    }

    getAuthorizationToken() {
        return this.axios.defaults.headers.common.Authorization;
    }

    setupStore(store) {
        this.store = store;
        const apiUrl = this.store.getState().root.variables.apiUrl;
        if (apiUrl) {
            this.baseUrl = apiUrl;
            this.axios.defaults.baseURL = apiUrl;
        }
        this.setAuthorizationHeader(this.store.getState().authentication.authData.token);
        this.setGlobalErrorHandling();
    }

    setGlobalErrorHandling() {
        // make sure there is only one interceptor
        if (this.interceptors) {
            return;
        }

        // Add a response interceptor
        this.interceptors = this.axios.interceptors.response
        .use((response) => {
            // Do something with response data
            return response;
        }, (error) => {
            if (axios.isCancel(error)) {
                return Promise.reject(null);
            }

            let errorData;
            if (!error.config || !error.config.externalRequest) {
                // These handlers are only concerned with responses from the Discovery API
                // They should not run for external requests
                errorData = ApiErrorMessageHelper.getNormalizedErrorData(error.response || error);
                this.handleUnauthorizedRequests(errorData);
                this.handleUnexpectedServerError(errorData);
                this.handleCampaignBuilderError(errorData);
            } else {
                errorData = error;
            }
            return Promise.reject(errorData);
        });
    }

    handleUnauthorizedRequests(error) {
        if (error.status === HTTP_UNAUTHORIZED_CODE &&
            this.store.getState().authentication.authData.token) {
            // log out user if user is authenticated
            this.store.dispatch(logout(LOG_OUT_REASON_FORBIDDEN));
            if (!!browserHistory) {
                browserHistory.push('/');
            }
        }
    }

    handleUnexpectedServerError(error) {
        // handle unexpected 5xx error codes
        const regErrorStatusCode = /^[5][0-9][0-9]$/;
        if (regErrorStatusCode.test(error.status)) {
            this.store.dispatch(setApplicationError(error));
        }
    }

    handleCampaignBuilderError(error) {
        if (error.status === HTTP_PRECONDITION_FAILED && error.data &&
            error.data.type && error.data.type === API_ERROR_TYPE.CAMPAIGN_BUILDER) {
            this.store.dispatch(setPlatformGlobalError(error));
        }
    }

    getNextPageLink(linkHeader) {
        let nextUrl;

        // get next page url from header. @todo move this to an utility class
        if (linkHeader) {
            const pagination = linkHeaderParser(linkHeader);
            if (pagination.next) {
                nextUrl = pagination.next.url.trim().replace('/', '');
            }
        }

        return nextUrl;
    }
}
