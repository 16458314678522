import ProductService from 'common/services/ProductService';
import {
    COLLECTIONS_REQUEST,
    COLLECTIONS_SUCCESS,
    COLLECTIONS_FAILURE,
    COLLECTIONS_SHOW_NEXT,
    COLLECTIONS_TOGGLE_NOTIFICATON_BOX
} from './constants';

export function loadCollectionsByProduct(productIdFunc) {
    // React Router passes the param as an object
    let productId = productIdFunc;
    if (typeof productIdFunc === 'object') {
        productId = productIdFunc.productId;
    }

    return (dispatch) => {
        dispatch({
            type: COLLECTIONS_REQUEST,
            productId
        });

        return ProductService.instance.getCollectionsBySlugs([productId])
            .then((response) => {
                dispatch({
                    type: COLLECTIONS_SUCCESS,
                    data: response.data,
                    productId
                });
            })
            .catch((error) => {
                dispatch({
                    type: COLLECTIONS_FAILURE,
                    error
                });
            });
    };
}

export function showNextCollections() {
    return (dispatch) => {
        dispatch({
            type: COLLECTIONS_SHOW_NEXT
        });
    };
}

export function toggleNotificationBox() {
    return (dispatch) => {
        dispatch({
            type: COLLECTIONS_TOGGLE_NOTIFICATON_BOX
        });
    };
}
