import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import loadFirmographicTaxonomy from 'shared/taxonomy/firmographic/actions';
import {
    validatePlatformToken
} from 'myhg/platformConnection/actions';

export default function (ComposedComponent) {
    class UserAccess extends React.Component {

        static needs = [
            loadFirmographicTaxonomy,
            validatePlatformToken
        ];

        static contextTypes = {
            router: PropTypes.object
        }

        componentWillMount() {
            this.getUserOutOfDashboardIfRequired();
        }

        componentDidUpdate() {
            this.getUserOutOfDashboardIfRequired();
        }

        getUserOutOfDashboardIfRequired() {
            if (!this.isUserAuthenticated()) {
                this.context.router.push('/');
            }
        }

        isUserAuthenticated() {
            return !!this.props.authData.token;
        }

        render() {
            // the component will still be redered on server side...
            if (!this.isUserAuthenticated()) {
                return null;
            }

            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return {
            authData: state.authentication.authData,
            featureFlags: state.root.featureFlags,
            accountDetails: state.user.accountDetails.data
        };
    }

    return connect(mapStateToProps)(UserAccess);
}
