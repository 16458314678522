import {
    PERMISSION_COUNT_REQUEST,
    PERMISSION_COUNT_SUCCESS,
    PERMISSION_COUNT_FAILURE
} from './constants';

export const permissionCountInitialState = {
    loaded: false,
    error: null,
    data: {
        companies: 0,
        products: 0
    }
};

export default function permissionCount(state = permissionCountInitialState, action) {
    switch (action.type) {
        case PERMISSION_COUNT_REQUEST:
            return Object.assign({}, state, {
                loaded: false,
                error: null
            });

        case PERMISSION_COUNT_SUCCESS:
            return Object.assign({}, state, {
                loaded: true,
                data: {
                    companies: action.data.companies,
                    products: action.data.products
                },
                error: null
            });

        case PERMISSION_COUNT_FAILURE:
            return Object.assign({}, state, {
                loaded: false,
                error: action.error
            });

        default:
            return state;
    }
}
