import React from 'react';
import { Link } from 'react-router';
import { NumeralUtils } from 'utils/Utils';
import { HTTP_FORBIDEN_CODE } from 'shared/constants';
import SampleCompaniesTable from './SampleCompaniesTable';
import SampleCompaniesCreateSegment from './SampleCompaniesCreateSegment';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import Loader from 'shared/ui/loader/Loader';
import {
    SAMPLE_LIMIT_REACHED,
    SAMPLE_DOWNLOAD_TEXT,
    SAMPLE_DOWNLOAD_DESC
} from '../constants';
import {
    PERMISSION_TYPE
} from 'shared/user/permissions/constants';

export default class SampleCompanies extends React.Component {

    getDownloadLink() {
        let link;
        const {
            downloadSample: { downloadInProgress },
            isUserAuthenticated, handleDownloadSample, onRegisterClick
        } = this.props;

        if (downloadInProgress) {
            link = <Loader />;
        } else if (isUserAuthenticated) {
            link = (
                <a
                    className="default-link"
                    rel="nofollow"
                    onClick={ handleDownloadSample }
                >
                    { SAMPLE_DOWNLOAD_TEXT }
                </a>
            );
        } else {
            link = (
                <Link
                    className="default-link"
                    rel="nofollow"
                    to="/register"
                    onClick={ onRegisterClick }
                >
                    { SAMPLE_DOWNLOAD_TEXT }
                </Link>
            );
        }
        return link;
    }

    getDownloadDesc() {
        let desc;
        const {
            downloadSample: { downloadInProgress }
        } = this.props;

        if (!downloadInProgress) {
            desc = (
                <span>
                    ({ SAMPLE_DOWNLOAD_DESC })
                </span>
            );
        }

        return desc;
    }

    getDownloadSampleLinkSection() {
        let content;

        if (this.showDownloadSampleButton()) {
            content = (
                <div>
                    { this.getDownloadLink() }
                    { this.getDownloadDesc() }
                </div>
            );
        } else {
            content = (
                <div>
                    { SAMPLE_LIMIT_REACHED }
                </div>
            );
        }

        return content;
    }

    getInnerPanelTable() {
        let content = null;
        const { isFetched, error, companies } = this.props.loadSample;

        if (error) {
            content = error.message;
        } else if (!isFetched) {
            content = <Loader />;
        } else {
            content = <SampleCompaniesTable rows={ companies } />;
        }

        return content;
    }

    getDownloadSection() {
        let content = null;
        const { isFetched, error } = this.props.loadSample;

        if (!error && isFetched) {
            content = this.getDownloadSampleLinkSection();
        }

        return content;
    }

    getInnerBottomSection() {
        let content = null;
        const { totalCompaniesCount, isFetched, error } = this.props.loadSample;
        const { data: productDetails } = this.props.productDetails;
        const formattedTotalCount = NumeralUtils.formatIntegerNumber(totalCompaniesCount);

        if (!error && isFetched) {
            content = (
                <SampleCompaniesCreateSegment
                    productDetails={ productDetails }
                    totalCompaniesCount={ formattedTotalCount }
                    dispatch={ this.props.dispatch }
                    allowedSlugs={ this.props.allowedSlugs }
                    isUserAuthenticated={ this.props.isUserAuthenticated }
                />
            );
        }

        return content;
    }

    getCustomTitleHeader() {
        let content = '';
        const { isFetched, error } = this.props.loadSample;
        const { product } = this.props.productDetails.data;

        if (!error && isFetched) {
            content = (
                <div className="row panel-header">
                    <div className="col-lg-7">
                        <h2 className="panel-title">
                            { `Companies Currently Using ${product}` }
                        </h2>
                    </div>
                    <div className="col-lg-5 product-download-sample">
                        { this.getDownloadSection() }
                    </div>
                </div>
            );
        }

        return content;
    }

    showDownloadSampleButton() {
        const { permissions, downloadSample } = this.props;
        const { error } = downloadSample;

        // Has raised an error
        if (error && error.status === HTTP_FORBIDEN_CODE) {
            return false;
        }

        // Not yet loaded or no user
        if (!permissions) {
            return true;
        }

        // Has download samples left
        const { remaining, allowed } = permissions[PERMISSION_TYPE.DOWNLOAD_SAMPLE];
        if (!!remaining) {
            return true;
        }

        // Has downloaded before
        const { slug } = this.props.productDetails;
        if (allowed.indexOf(slug) !== -1) {
            return true;
        }

        return false;
    }

    render() {
        return (
            <PanelWrapper
                className="product-sample-companies"
                customTitleHeader={ this.getCustomTitleHeader() }
            >
                { this.getInnerPanelTable() }
                { this.getInnerBottomSection() }
            </PanelWrapper>
        );
    }
}
