import React from 'react';
import classNames from 'classnames';
import { NumeralUtils } from 'utils/Utils';
import CounterWrapper from 'shared/ui/counter/CounterWrapper';
import {
    SEGMENT_COMPANIES_TOTAL
} from '../constants';
import {
    SEGMENT_DATA_MENU_NAME
} from 'myhg/segment/segmentMenuActions/constants';
import {
    getMenuByName,
    segmentMenuSelect
} from 'myhg/segment/segmentMenuActions/actions';

export default class SegmentCompaniesCounter extends React.Component {

    onCompaniesCountClick = () => {
        const { dispatch } = this.props;
        const menu = getMenuByName(SEGMENT_DATA_MENU_NAME);

        dispatch(segmentMenuSelect(menu));
    }

    render() {
        const { totalCompanies, segmentMenu } = this.props;
        const formattedCompaniesCount =
             NumeralUtils.formatIntegerNumber(totalCompanies);
        const counterClass = classNames({
            'counter-clickable': segmentMenu.name !== SEGMENT_DATA_MENU_NAME
        });

        return (
            <div
                id="summary-section"
                className={ counterClass }
                onClick={ this.onCompaniesCountClick }
            >
                <CounterWrapper
                    isHeaderCounter
                    iconClass="icon-building"
                    subTitle={ SEGMENT_COMPANIES_TOTAL }
                    itemCounts= { formattedCompaniesCount }
                />
            </div>
        );
    }
}
