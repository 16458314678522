import React from 'react';
import { connect } from 'react-redux';
import withTaxonomy from 'shared/taxonomy/enhancer';
import SummaryDetails from './components/SummaryDetails';

function mapStateToProps(state) {
    const { segment } = state.myhg;
    const { source } = segment.currentSegment.data.status;
    const countsSource = source
        ? segment.segmentMatches.netNewMatches
        : segment.segmentMatches.summaryData;

    return {
        currentSegment: segment.currentSegment,
        chartSource: segment.segmentMatches.productSummaryData,
        accountDetails: state.user.accountDetails.data,
        isFiltersEditOpen: segment.segmentView.isFiltersEditOpen,
        countsSource
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

class SegmentSummaryDetailsContainer extends React.Component {

    render() {
        return <SummaryDetails { ...this.props } />;
    }
}

export default withTaxonomy(SegmentSummaryDetailsContainer);
