import UserService from 'common/services/UserService';
import {
    USER_TECHNOLOGIES_PERMISSIONS_REQUEST,
    USER_TECHNOLOGIES_PERMISSIONS_SUCCESS,
    USER_TECHNOLOGIES_PERMISSIONS_FAILURE
} from './constants';

export function loadTechnologiesPermissions() {
    return (dispatch) => {
        dispatch({
            type: USER_TECHNOLOGIES_PERMISSIONS_REQUEST
        });
        return UserService.instance.getTechnologiesPermissions()
            .then((response) => {
                dispatch({
                    type: USER_TECHNOLOGIES_PERMISSIONS_SUCCESS,
                    slugs: response.data.slugs,
                    all: response.data.all
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_TECHNOLOGIES_PERMISSIONS_FAILURE,
                    error
                });

                return {
                    serverShouldHandle: true,
                    error
                };
            });
    };
}
