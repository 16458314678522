export const CONFIRM_SIGN_UP_REQUEST = 'CONFIRM_SIGN_UP_REQUEST';
export const CONFIRM_SIGN_UP_SUCCESS = 'CONFIRM_SIGN_UP_SUCCESS';
export const CONFIRM_SIGN_UP_FAILURE = 'CONFIRM_SIGN_UP_FAILURE';

export const VALID_VERIFICATION_LINK = 'VALID_VERIFICATION_LINK';

// menu title
export const CONFIRM_SIGN_UP_REQUEST_TITLE = 'Please Wait...';
export const CONFIRM_SIGN_UP_SUCCESS_TITLE = 'Thank you for confirming your email address!';
export const CONFIRM_SIGN_UP_ACCOUNT_EXISTS_TITLE = 'Thanks Again!';
export const CONFIRM_SIGN_UP_FAILURE_TITLE = 'Error!';

// success messaging
export const CONFIRM_SIGN_UP_SUCCESS_MESSAGE =
    'Your account has been created. Here\'s how it works:';

export const VERIFICATION_MODAL_TYPE = 'VERIFICATION_MODAL_TYPE';
export const VERIFICATION_ROUTE = 'verification/:confirmToken';

export const CONFIRM_SIGN_UP_BUTTON_NEXT = 'Search for a Product';
export const CONFIRM_SIGN_UP_BUTTON_ALREADY_VERIFIED = 'Please Sign In';
export const CONFIRM_SIGN_UP_BUTTON_ERROR = 'Sign-up again';
export const CONFIRM_SIGN_UP_INVALID_LINK = 'Invalid Verification Link';
export const CONFIRM_SIGN_UP_ALREADY_VERIFIED_TEXT =
    'You have already confirmed your email address.';

// platform right side content
export const PLATFORM_CONFIRM_ACTION_TEXT = 'Compare to Your %1$s';
export const PLATFORM_CONFIRM_ACTION_TEXT_ACCOUNT = 'Compare to Your %1$s Account';
export const PLATFORM_CONFIRM_ACTION_RECORDS = 'sample records out of %1$s';
export const PLATFORM_CONFIRM_ACTION_RECORDS_LOW = 'sample records';
export const PLATFORM_MAX_FREE_RECORDS_COUNT = 500;
