import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        taxonomy: state.taxonomy.products
    };
};

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const withTaxonomy = (WrappedComponent) => {
    class HOCTaxonomyEnhancer extends React.Component {

        // by product slug
        findTaxonomyProduct = (slug) => {
            const { data } = this.props.taxonomy;
            return data.find((product) => {
                return (product.slug === slug);
            });
        }

        // by list of slugs
        findTaxonomyProductsForSlugs = (slugs) => {
            return slugs.map((slug) => {
                return this.findTaxonomyProduct(slug);
            }).filter((technology) => { return !!technology; });
        };

        // by product id
        findTaxonomyByProductId = (productId) => {
            const { data } = this.props.taxonomy;
            return data.find((product) => {
                return (product.productId === productId);
            });
        }

        // by product vendor
        findTaxonomyVendor = (vendor) => {
            const { data } = this.props.taxonomy;
            return data.find((product) => {
                return (product.vendor === vendor);
            });
        }

        render() {
            return (
                <WrappedComponent
                    findTaxonomyByProductId= { this.findTaxonomyByProductId }
                    findTaxonomyProduct={ this.findTaxonomyProduct }
                    findTaxonomyVendor={ this.findTaxonomyVendor }
                    findTaxonomyProductsForSlugs={ this.findTaxonomyProductsForSlugs }
                    {...this.props}
                />
            );
        }
    }
    HOCTaxonomyEnhancer.displayName = `WithTaxonomy(${getDisplayName(WrappedComponent)})`;
    return connect(mapStateToProps, null)(HOCTaxonomyEnhancer);
};

export default withTaxonomy;
