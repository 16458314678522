import React from 'react';
import UserUtils from 'utils/UserUtils';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import Loader from 'shared/ui/loader/Loader';
import SummaryDetailsOverall from './SummaryDetailsOverall';
import SummaryDetailsBarChart from './SummaryDetailsBarChart';
import {
    TOTAL_COUNTS_TITLE,
    BAR_CHART_TITLE_PAID,
    BAR_CHART_TITLE_UNPAID
} from '../constants';

export default class SummaryDetails extends React.Component {

    buildBarChartSource(data) {
        const { findTaxonomyProduct } = this.props;
        const { slugs } = this.props.currentSegment.data.filters;

        // sort retrieved data by slugs order
        data.sort((a, b) => {
            return slugs.indexOf(a.slug) - slugs.indexOf(b.slug);
        });

        return data.map(entry => {
            const technology = findTaxonomyProduct(entry.slug);
            const productCompaniesDiff = (entry.productCompanies - entry.companies) || 0;

            return Object.assign({}, entry, {
                product: technology.product,
                productCompaniesDiff
            });
        });
    }

    buildDonutSource(data) {
        const productCompaniesDiff = (data.productCompanies - data.companies) || 0;

        return Object.assign({}, data, {
            productCompaniesDiff
        });
    }

    render() {
        const { countsSource, chartSource, accountDetails } = this.props;
        const { status: segmentStatus } = this.props.currentSegment.data;

        let chartContent = null;
        let overallContent = null;

        if (chartSource.error) {
            chartContent = chartSource.error.message;
        } else if (chartSource.isFetching || countsSource.isFetching) {
            chartContent = <Loader />;
        } else {
            chartContent = (
                <SummaryDetailsBarChart
                    segmentStatus={ segmentStatus }
                    source={ this.buildBarChartSource(chartSource.data) }
                    { ...this.props }
                />
            );
        }

        if (countsSource.error) {
            overallContent = countsSource.error.message;
        } else if (countsSource.isFetching) {
            overallContent = <Loader />;
        } else {
            overallContent = (
                <SummaryDetailsOverall
                    segmentStatus={ segmentStatus }
                    donutSource={ this.buildDonutSource(segmentStatus) }
                    onRefineFilters={ this.props.onReviewFilters }
                    isFiltersEditOpen={ this.props.isFiltersEditOpen }
                    dispatch={ this.props.dispatch }
                />
            );
        }

        return (
            <PanelWrapper className="segment-details">
                <div className="row">
                    <div className="col-lg-6">
                        <h2 className="h2">
                            { TOTAL_COUNTS_TITLE }
                        </h2>
                        { overallContent }
                    </div>
                    <div className="col-lg-6">
                        <h2 className="h2">
                            {
                                UserUtils.isPaid(accountDetails)
                                    ? BAR_CHART_TITLE_PAID
                                    : BAR_CHART_TITLE_UNPAID
                            }
                        </h2>
                        { chartContent }
                    </div>
                </div>
            </PanelWrapper>
        );
    }
}
