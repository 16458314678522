import React from 'react';
import classNames from 'classnames';
import OverflowTooltip from 'shared/tooltip/OverflowTooltip';
import UserUtils from 'utils/UserUtils';
import {
    UNAVAILABLE
} from 'shared/constants';

export default class FixedGrid extends React.Component {
    getTableHead() {
        const tableHead = this.props.fixedTableHead;
        return tableHead.map((item, index) => {
            return (
                <div key={ index }>
                    <div className="table-head" >
                        { item.value }
                    </div>
                    { this.getTableData(item) }
                </div>
            );
        });
    }

    getNetNewAndMAtchesIcons(item) {
        if (item.matched !== undefined) {
            const { accountDetails } = this.props;
            const isPaidUser = UserUtils.isPaid(accountDetails);
            const cssClass = classNames({
                square: item.matched,
                circle: !item.matched,
                hide: !isPaidUser
            });

            return (
                <div className={ cssClass }></div>
            );
        }

        return null;
    }

    getTableData(options) {
        const fixedTableData = this.props[options.key] || [];
        const tableData = this.props.tableData || [];
        return fixedTableData.map((item, index) => {
            // temporary solution we need to find a better solution in
            // order to keep fixedGrid generic
            const findedItem = tableData.find((detailItem) => {
                return detailItem.companyName === item;
            });

            const cssClass = classNames('table-content', options.columnCssClass);

            const netNewCssClass = classNames('truncate', {
                netnew: findedItem.matched === false,
                unavailable: item === UNAVAILABLE || !item,
                obscured: findedItem.obscured,
            });

            return (
                <div
                    key={ index }
                    className={ cssClass }
                >
                    <div className={ netNewCssClass }>
                        { this.getNetNewAndMAtchesIcons(findedItem) }
                        <OverflowTooltip
                            title={ item }
                            key={ index }
                            className={ netNewCssClass }
                            value={ !item ? UNAVAILABLE : item }
                        />
                    </div>
                </div>
            );
        });
    }

    render() {
        const cssClass = classNames('table-column', 'fixed', this.props.fixedClass);

        return (
            <div className={ cssClass }>
                { this.getTableHead() }
            </div>
        );
    }
}
