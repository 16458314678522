import {
    USER_TECHNOLOGIES_PERMISSIONS_REQUEST,
    USER_TECHNOLOGIES_PERMISSIONS_SUCCESS,
    USER_TECHNOLOGIES_PERMISSIONS_FAILURE
} from './constants';

export const initialState = {
    fetched: false,
    error: null,
    all: false,
    slugs: []
};

export default function technologiesPermissions(state = initialState, action) {
    switch (action.type) {
        case USER_TECHNOLOGIES_PERMISSIONS_REQUEST:
            return Object.assign({}, state, {
                fetched: false
            });

        case USER_TECHNOLOGIES_PERMISSIONS_SUCCESS:
            return Object.assign({}, state, {
                fetched: true,
                slugs: action.slugs,
                all: action.all
            });

        case USER_TECHNOLOGIES_PERMISSIONS_FAILURE:
            return Object.assign({}, state, {
                fetched: false,
                error: action.error,
                slugs: [],
                all: false
            });

        default:
            return state;
    }
}
