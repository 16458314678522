import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import ValidationField from 'shared/ui/formFields/ValidationField';
import Validation from 'utils/Validation';
import Loader from 'shared/ui/loader/Loader';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';

export const fields = [
    'email',
    'password'
];

const validate = values => {
    const errors = {};

    if (!Validation.validateWhitespace(values.email)) {
        errors.email = 'Required';
    }

    if (!Validation.validateWhitespace(values.password)) {
        errors.password = 'Required';
    }

    return errors;
};

class PardotConnectForm extends React.Component {

    getError(error) {
        if (!error) {
            return null;
        }

        return (
            <p className="text-content error">
                { error.message }
            </p>
        );
    }

    isValidForm() {
        return Object.keys(this.props.errors).length === 0 &&
            this.props.errors.constructor === Object;
    }

    render() {
        const {
            fields: {
                email,
                password
            },
            handleSubmit,
            submitting,
            requestError
        } = this.props;

        return (
            <form
                id="pardot-connect"
                className="modal-form"
                onSubmit={ handleSubmit(this.props.onConnectSubmit) }
            >
                <ValidationField
                    type="text"
                    label="Email"
                    placeholder="e.g. julie.smith@company.com"
                    fieldType="input"
                    {...email}
                />
                <ValidationField
                    type="password"
                    label="Password"
                    placeholder=""
                    fieldType="input"
                    {...password}
                />
                { this.getError(requestError) }
                <FormButtonWrapper>
                    <button
                        id="pardot-connect-btn"
                        type="submit"
                        className="btn btn-primary"
                        disabled={ submitting || !this.isValidForm() }
                    >
                        Continue { submitting ?
                        <Loader className="small-loader white" /> : '' }
                    </button>
                </FormButtonWrapper>
            </form>
        );
    }
}

// https://www.npmjs.com/package/@jimpick/redux-form
PardotConnectForm.propTypes = {
    fields: PropTypes.object.isRequired,
    // It will run validation, both sync and async, and, if the form is valid,
    // it will call this.props.onConnectSubmit function
    handleSubmit: PropTypes.func.isRequired,
    // Whether or not your form is currently submitting.
    // This prop will only work if you have passed an onSubmit function
    // that returns a promise. It will be true until the promise is resolved or rejected.
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'pardotConnectForm',
    fields,
    validate
})(PardotConnectForm);
