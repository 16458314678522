import React from 'react';
import SegmentContactUS from 'myhg/segment/components/SegmentContactUS';
import {
    SEGMENT_CRM_END_TRIAL_TITLE,
    SEGMENT_CRM_END_TRIAL_BUTTON
} from '../constants';

export default class EndTrialMessage extends React.Component {
    render() {
        return (
            <div className="segment-crm-status-trial end-trial">
                <span className="title">{ SEGMENT_CRM_END_TRIAL_TITLE }</span>
                <span className="message">
                    { this.props.description }
                </span>
                <span className="contact-us">
                    <SegmentContactUS
                        linkLabel= { SEGMENT_CRM_END_TRIAL_BUTTON }
                        { ...this.props }
                    />
                </span>
            </div>
        );
    }
}
