/* global analytics:true */
import { default as moment } from 'moment';
import numeral from 'numeral';
import {
    DATE_FORMAT,
    DATE_TIME_FORMAT
} from 'shared/constants';

export class DateUtils {

    static toStandardFormatDateTime(date) {
        return moment(new Date(date)).format(DATE_TIME_FORMAT);
    }

    static toStandardFormatDate(date) {
        return moment(new Date(date)).format(DATE_FORMAT);
    }

    static getTimePassedSinceDate(date) {
        return moment(new Date(date)).fromNow();
    }
}

export class NumeralUtils {

    static formatIntegerNumber(number, isCurrency) {
        return numeral(number).format(isCurrency ? '$0,0' : '0,0');
    }

    static formatBigNumbers(number, isCurrency) {
        return numeral(number).format(isCurrency ? '$0a' : '0a');
    }

    static formatBigNumbersDecimal(number, isCurrency) {
        // Don't format small numbers
        const format = number < 999 ? '0,0' : '0.0a';
        let result = numeral(number).format(isCurrency ? `$${format}` : format);

        // Remove '.0' decimals
        if (!!result.match(/\.0[kmbt]/)) {
            result = result.replace(/\.0/, '');
        }

        return result;
    }
}

export class UrlUtils {

    static prefixWithProtocol(url, protocol = 'http') {
        const isAbsolute = new RegExp('^([a-z]+://|//)', 'i');
        let result = url;

        if (!isAbsolute.test(result)) {
            result = `${protocol}://${url}`;
        }
        return result;
    }

    static getHostUrl() {
        return typeof location === 'object' ? `${location.protocol}//${location.host}` : '';
    }

    static getAbsolutePath() {
        const hostPath = UrlUtils.getHostUrl();
        return `${hostPath}${location.pathname}`;
    }

    static getOneLevelUpPath(path) {
        return path.replace(/\/[^\/]*$/, '');
    }

    static validateContactPath(contactPath) {
        let path = contactPath;
        path = path
            .replace('/login', '')
            .replace('/register', '')
            .replace('/contact-us', '')
            .replace('/contact', '');
        return path;
    }

    static getFormattedPathName() {
        let pathname = window.location.pathname;
        const urlEndsWithSlash = pathname.substring(pathname.length - 1) === '/';
        pathname = urlEndsWithSlash ? pathname.substring(0, pathname.length - 1) : pathname;
        pathname = UrlUtils.validateContactPath(pathname);
        return pathname;
    }

    /**
     * Get the url query parameters list
     * @param {string} url The url the search for query params against
     * @returns {Object} Object representation with its properties
     * representing the query parameters.
     */
    static getUrlParams(url) {
        const params = {};
        url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
            params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
        });
        return params;
    }

    static encodeQueryParamObject(object) {
        return encodeURIComponent(JSON.stringify(object));
    }

    static decodeQueryParamObject(param) {
        return JSON.parse(decodeURIComponent(param));
    }
}

export class Cookies {

    static setPersistentCookie(name, value, exdays) {
        const date = new Date();
        date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/;`;
    }

    static deleteCookieByName(name) {
        const expires = 'expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = `${name}=; ${expires}; path=/;`;
    }

    static getCookieByName(cookieName) {
        const name = `${cookieName}=`;
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            let c = cookies[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}

export class ServerCookie {

    static getCookieByName(cookieName, request) {
        const name = `${cookieName}=`;
        if (request.headers.cookie) {
            const cookies = request.headers.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let c = cookies[i];
                if (c) {
                    while (c.charAt(0) === ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) === 0) {
                        return c.substring(name.length, c.length);
                    }
                }
            }
        }
        return null;
    }
}

// TRACK actions in Segment.com
// https://segment.com/docs/sources/website/analytics.js/#track
export function track(action, props = {}) {
    // log analytics event
    if (typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
        const properties = Object.assign({}, props);
        window.analytics.track(action, properties);
    }
}

// IDENTIFY users in Segment.com
// https://segment.com/docs/sources/website/analytics.js/#identify
export function identify(userId, properties) {
    if (typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
        if (userId) {
            window.analytics.identify(userId, properties);
        } else {
            window.analytics.reset();
        }
    }
}

// Check if feature is available
export function isAvailable(flags, feature) {
    return flags[feature] !== undefined ? flags[feature] : true;
}

// Return process.env value if set, otherwise RequestHeader if set, otherwise false
function getRequestHeaderValue(req, env) {
    const normalizedReq = req !== 'false' && typeof req !== 'undefined' ? req : false;
    const normalizedEnv = typeof env !== 'undefined' ? env : false;
    return normalizedReq || normalizedEnv;
}

// Extract environmental variables from request header and process.env;
export function getENV(req) {
    const ENV = {};

    ENV.SENTRY_DSN = getRequestHeaderValue(req.get('SentryDsn'), process.env.SENTRY_DSN);

    ENV.SENTRY_DSN_PUBLIC =
        getRequestHeaderValue(req.get('SentryDsnPublic'), process.env.SENTRY_DSN_PUBLIC);

    ENV.RELEASE_TAG = getRequestHeaderValue(req.get('ReleaseTag'), process.env.RELEASE_TAG);

    ENV.LEAD_FORENSICS_API_KEY =
        getRequestHeaderValue(req.get('LeadForensicsApiKey'), process.env.LEAD_FORENSICS_API_KEY);

    ENV.LAUNCH_DARKLY_API_KEY =
        getRequestHeaderValue(req.get('LaunchDarklyApiKey'), process.env.LAUNCH_DARKLY_API_KEY);

    ENV.LAUNCH_DARKLY_CLIENT_ID =
        getRequestHeaderValue(req.get('LaunchDarklyClientId'), process.env.LAUNCH_DARKLY_CLIENT_ID);

    ENV.API_URL = getRequestHeaderValue(req.get('ApiUrl'), process.env.API_URL);

    ENV.DISCOVERY_SEGMENT_API_KEY = getRequestHeaderValue(
        req.get('DiscoverySegmentApiKey'), process.env.DISCOVERY_SEGMENT_API_KEY);

    ENV.DISCOVERY_ROBOTS =
        getRequestHeaderValue(req.get('DiscoveryRobots'), process.env.DISCOVERY_ROBOTS);

    return ENV;
}
