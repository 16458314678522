import UserService from 'common/services/UserService';
import {
    USER_PERMISSIONS_REQUEST,
    USER_PERMISSIONS_SUCCESS,
    USER_PERMISSIONS_FAILURE
} from './constants';

export function loadPermissions() {
    return (dispatch) => {
        dispatch({
            type: USER_PERMISSIONS_REQUEST
        });
        return UserService.instance.getPermissions()
            .then((response) => {
                dispatch({
                    type: USER_PERMISSIONS_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_PERMISSIONS_FAILURE,
                    error
                });
                return {
                    serverShouldHandle: true,
                    error
                };
            });
    };
}
