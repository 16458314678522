import React from 'react';
import ProductHeaderContainer from 'shared/header/ProductHeaderContainer';
import ProductTopBanner from './ProductTopBanner';
import SampleCompaniesContainer from '../sampleCompanies/SampleCompaniesContainer';
import SimilarProductsContainer from '../similarProducts/SimilarProductsContainer';
import FirmographicContainer from '../firmographics/FirmographicContainer';
import CollectionsContainer from '../collections/CollectionsContainer';
import { STEP_ADD_PRODUCT } from 'shared/stepsBar/constants';

/**
 * Component to render the product page
 */
export default class Product extends React.Component {
    userIsInterested = () => {
        const { slug } = this.props;
        const { products } = this.props.cart;
        return (products.indexOf(slug) !== -1);
    }

    render() {
        return (
            <div id="product">
                <ProductHeaderContainer
                    stepBar={ STEP_ADD_PRODUCT }
                    showStepsBar
                />
                <ProductTopBanner
                    {...this.props}
                    userIsInterested={ this.userIsInterested }
                />
                <SimilarProductsContainer {...this.props} />
                <SampleCompaniesContainer {...this.props} />
                <CollectionsContainer {...this.props} />
                <FirmographicContainer {...this.props} />
            </div>
        );
    }
}
