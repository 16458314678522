import { combineReducers } from 'redux';
import signUp from './signUp/reducer';
import confirmRegistration from './confirmRegistration/reducer';
import resetPasswordConfirm from './resetPassword/reducer';
import logIn, { resetPassword } from './logIn/reducer';
import { CONFIRM_SIGN_UP_SUCCESS } from './confirmRegistration/constants';
import {
    LOGIN_SUCCESS,
} from './logIn/constants';
import {
    REFRESH_TOKEN_SUCCESS,
    LOG_OUT,
    REFRESH_TOKEN_REQUEST,
    REFRESH_TOKEN_FAILURE,
    REFERER_PATH_SET
} from './constants';
import {
    OAUTH_AUTHORIZE
} from 'shared/oAuth/constants';
import { MARKETO_AUTHORIZE } from 'myhg/platformConnection/marketo/constants';

export const authDataInitialState = {};
export const refreshTokenInitialState = {};

export function authData(state = authDataInitialState, action) {
    switch (action.type) {

        case CONFIRM_SIGN_UP_SUCCESS:
        case LOGIN_SUCCESS:
        case REFRESH_TOKEN_SUCCESS:
        case OAUTH_AUTHORIZE:
        case MARKETO_AUTHORIZE:
            return Object.assign({}, state, action.data);

        case LOG_OUT :
            return {};

        default:
            return state;
    }
}

export function refreshToken(state = refreshTokenInitialState, action) {
    switch (action.type) {
        case REFRESH_TOKEN_REQUEST:
            return Object.assign({}, state, {
                submitting: true,
                error: null
            });

        case REFRESH_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                submitting: false,
                authData: action.data,
                error: null
            });

        case REFRESH_TOKEN_FAILURE:
            return Object.assign({}, state, {
                submitting: false,
                error: action.error
            });

        default:
            return state;
    }
}

export const refererPathInitialState = '/';

export function refererPath(state = refererPathInitialState, action) {
    switch (action.type) {
        case REFERER_PATH_SET:
            return action.path;

        default:
            return state;
    }
}

export default combineReducers({
    authData,
    confirmRegistration,
    logIn,
    resetPassword,
    resetPasswordConfirm,
    signUp,
    refreshToken,
    refererPath
});
