import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { DateUtils, NumeralUtils } from 'utils/Utils';
import UserUtils from 'utils/UserUtils';
import {
    getPreviouslyMatchedFiles
} from 'myhg/segment/segmentMatches/actions';
import Loader from 'shared/ui/loader/Loader';
import {
    saveCurrentSegment
} from 'myhg/segment/actions';
import {
    MATCH_SOURCE_TYPE
} from 'myhg/segment/constants';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        accountDetails: state.user.accountDetails.data,
        segmentFilters: segment.segmentFilters,
        segmentId: segment.currentSegment.id,
        uploadedFiles: segment.segmentMatches.uploadedFiles
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class LoadFiles extends React.Component {
    componentDidMount() {
        const { segmentId } = this.props;
        if (!segmentId) {
            browserHistory.push('/dashboard');
        }

        this.props.dispatch(getPreviouslyMatchedFiles());
    }

    onSelect(fileId, fileName) {
        const { accountDetails, dispatch, segmentId } = this.props;
        const isPaidUser = UserUtils.isPaid(accountDetails);

        dispatch(saveCurrentSegment({
            source: MATCH_SOURCE_TYPE.FILE,
            sourceValue: fileId,
            fileName
        }, {
            fileId,
            includeNetNew: isPaidUser,
            includeMatches: true
        })).then(() => {
            browserHistory.push(`/dashboard/segment/view/${segmentId}`);
        });
    }

    getTable() {
        return (
            <table className="product-samples-layout">
                <thead>
                    <tr>
                        <th> FILE NAME </th>
                        <th> UPLOAD DATE </th>
                        <th> # UNIQUE URLS </th>
                    </tr>
                </thead>
                <tbody>
                    { this.getFilesRows() }
                </tbody>
            </table>
        );
    }

    getFilesRows() {
        return this.props.uploadedFiles.data.map(({ fileId, fileName, createdOn, urls }) => {
            const formattedFileDate = createdOn ? DateUtils.toStandardFormatDate(createdOn) : '-';
            const formattedUrlsCount = urls ? NumeralUtils.formatIntegerNumber(urls) : '-';

            return (
                <tr
                    key={ fileId }
                >
                    <td
                        onClick={ this.onSelect.bind(this, fileId, fileName) }
                        className="btn-link"
                    >
                        { fileName }
                    </td>
                    <td>{ formattedFileDate }</td>
                    <td className="uniques-urls">{ formattedUrlsCount }</td>
                </tr>
            );
        });
    }

    getNoData() {
        return (
            <span> No file was previously uploaded. </span>
        );
    }

    getContent() {
        const { isFetching, data } = this.props.uploadedFiles;
        let content;
        if (isFetching) {
            content = <Loader />;
        } else if (data.length === 0) {
            content = this.getNoData();
        } else {
            content = this.getTable();
        }
        return content;
    }

    render() {
        return (
            <div className="match-uploaded-files table-wrapper">
                { this.getContent() }
            </div>
        );
    }
}
