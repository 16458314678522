export const PARDOT_AUTHORIZE = 'PARDOT_AUTHORIZE';
export const PARDOT_CONNECT_REQUEST = 'PARDOT_CONNECT_REQUEST';
export const PARDOT_CONNECT_SUCCESS = 'PARDOT_CONNECT_SUCCESS';
export const PARDOT_CONNECT_ERROR = 'PARDOT_CONNECT_ERROR';

export const PARDOT_CONNECT_PAGE_TITLE = 'Connect to Pardot';
export const PARDOT_CONNECT_FORM_TITLE = 'Enter your Pardot user email and password.';
export const PARDOT_CONNECT_PAGE_DESCRIPTION =
    'HG Insights wants to connect to your Pardot account to:';
export const PARDOT_ALREADY_CONNECT_PAGE_DESCRIPTION =
    'Currently connected to this Pardot account:';
export const PARDOT_CONNECT_NOTE =
    'Note: HG Insights does not use or retain this data in any way.';
