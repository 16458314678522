import React from 'react';
import {
    sortableContainer,
    sortableElement,
    arrayMove,
    sortableHandle
} from 'react-sortable-hoc';
import classNames from 'classnames';
import OverflowTooltip from 'shared/tooltip/OverflowTooltip';
import {
    UNAVAILABLE
} from 'shared/constants';
import { UrlUtils } from 'utils/Utils';

const DragHandle = sortableHandle(({ data }) => {
    return (
        <div className="table-head draggable">
            { data }
        </div>
    );
});

const itemValue = (item, value) => {
    if (value.key === 'url' && !item.obscured) {
        const url = item[value.key];

        return (
            <a href={ UrlUtils.prefixWithProtocol(url) } target="_blank" rel="nofollow">
                { url }
            </a>);
    }

    return item[value.key];
};

const SortableItem = sortableElement(({ value, data }) => {
    const columnCssClass = classNames('table-column', value.columnCssClass);

    return (
        <div className={ columnCssClass }>
            <DragHandle
                data={ value.value }
            />
            <div className="table-content">
                { data.map((item, index) => {
                    const itemClass = classNames('truncate', {
                        unavailable: item[value.key] === UNAVAILABLE || !item[value.key],
                        obscured: item.obscured,
                        netnew: item.matched === false
                    });
                    return (
                        <OverflowTooltip
                            title={ item[value.key] }
                            key={ index }
                            className={ itemClass }
                            value={ !item[value.key] ? UNAVAILABLE : itemValue(item, value) }
                        />
                    );
                }) }
            </div>
        </div>
    );
});

const SortableList = sortableContainer(({ items, data }) => {
    return (
        <div className="draggable-table">
            { items.map((value, index) => {
                return (
                    <SortableItem
                        key={ `item-${index}` }
                        index={ index }
                        value={ value }
                        data={ data }
                    />
                );
            }) }
        </div>
    );
});

export default class DraggableGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: this.props.draggableTableHead.filter((item) => {
                return !!item.key;
            })
        };
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.draggableTableHead)
            !== JSON.stringify(this.props.draggableTableHead)) {
            this.setState({
                items: nextProps.draggableTableHead
            });
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const itemsPositions = arrayMove(this.state.items, oldIndex, newIndex);

        if (this.props.onSortEnd) {
            this.props.onSortEnd(itemsPositions);
        }
        this.setState({
            items: itemsPositions
        });
    };

    render() {
        return (
            <SortableList
                items={ this.state.items }
                data={ this.props.tableData }
                onSortEnd={ this.onSortEnd }
                axis="x"
                lockAxis="x"
                useDragHandle
                hideSortableGhost={ false }
            />
        );
    }
}
