import React from 'react';

export default class MatchesError extends React.Component {

    getErrorCodes() {
        const { code } = this.props.error;

        return (
            <div className="error-codes">
                HG Reference Code: <strong>{ code }</strong>
            </div>
        );
    }

    render() {
        const { message: errorDescription } = this.props.error;

        return (
            <div className="matches-error">
                <div className="error-title">
                    Error get matching data: { errorDescription }
                </div>
                { this.getErrorCodes() }
            </div>
        );
    }
}
