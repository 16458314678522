import React from 'react';
import OAuthConnectActionHandler
    from 'shared/oAuth/OAuthConnectActionHandler';

export default class OAuthSubmitAction extends React.Component {

    getState() {
        const { segmentId, platformName } = this.props;

        return {
            callback: '/dashboard/connect/platform-authorize',
            platform: platformName.toLowerCase(),
            segmentId
        };
    }

    render() {
        const { platformName } = this.props;

        return (
            <OAuthConnectActionHandler
                platformName={ platformName.toLowerCase() }
                state={ this.getState() }
                actionText={ this.props.actionText }
            />
        );
    }
}
