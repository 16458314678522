import React from 'react';
import sprintf from 'sprintf-js';
import {
    TRIAL_STATUS,
    TRIAL_NOTICE_TITLE,
    TRIAL_NOTICE_DESCRIPTION
} from './../constants';
import UserTrialNotification from './UserTrialNotification';

export default class TrialNotStartedNotification extends React.Component {

    render() {
        const status = TRIAL_STATUS.TRIAL_NOT_STARTED;
        const title = TRIAL_NOTICE_TITLE[status];
        const description = sprintf.sprintf(TRIAL_NOTICE_DESCRIPTION[status].TEXT,
            this.props.name);
        const link = (
            <a className="primary-link"
                onClick={ this.props.onStartTrial }
            >
                { TRIAL_NOTICE_DESCRIPTION[status].LINK }
            </a>
        );

        return (
            <UserTrialNotification
                title={ title }
                description={ description }
                actionLink={ link }
            />
        );
    }
}
