import { UrlUtils } from 'utils/Utils';
import RegistrationService from 'common/services/RegistrationService';
import {
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
    SIGN_UP_START_OVER,
    SIGN_UP_LOCAL_STATE_LOADED
} from './constants';

export function getRegistrationVerificationUrl(landingPage) {
    return (dispatch, getState) => {
        const { data: { filters } } = getState().myhg.segment.currentSegment;
        const hasFilters = Object.keys(filters).length > 0;

        const signupDetails = btoa(JSON.stringify({
            // this is the original path the user came from segment, product page...
            referer: getState().authentication.refererPath,
            // store segment on url if there is a segment created
            // this will be autosaved during signup completion
            segment: hasFilters ? getState().myhg.segment.currentSegment : null,
            // this is the landing page identifier that triggered the registration process
            // could be a connect to salesfore link, pardot... etc or empty (it is url driven)
            landingPage
        }));

        return `${UrlUtils.getHostUrl()}/verification/${signupDetails}`;
    };
}

export function register(registrationData, landingPage, resend) {
    return (dispatch) => {
        dispatch({
            type: SIGN_UP_REQUEST,
            resend: resend || false
        });

        const verificationUrl = dispatch(getRegistrationVerificationUrl(landingPage));

        // Dispatch vanilla actions asynchronously
        return RegistrationService.instance.createAccount(registrationData, verificationUrl)
            .then((response) => {
                dispatch({
                    type: SIGN_UP_SUCCESS,
                    data: response.data,
                    registrationData
                });

                RegistrationService.saveRegistrationProgress(registrationData);
            })
            .catch((error) => {
                dispatch({
                    type: SIGN_UP_FAILURE,
                    error
                });
            });
    };
}

export function startOver() {
    return (dispatch) => {
        dispatch({
            type: SIGN_UP_START_OVER
        });
        RegistrationService.saveRegistrationProgress(null);
    };
}

export function loadRegistrationProgressFromLocal() {
    return (dispatch) => {
        const registrationProgress = RegistrationService.getRegistrationProgress();
        dispatch({
            type: SIGN_UP_LOCAL_STATE_LOADED,
            registrationProgress
        });
    };
}
