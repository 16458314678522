import React from 'react';
import { browserHistory } from 'react-router';
import { UrlUtils } from 'utils/Utils';

export default class OAuthCallbackContainer extends React.Component {

    componentDidMount() {
        const { state } = this.props.location.query;
        const stateObject = UrlUtils.decodeQueryParamObject(state);

        browserHistory.replace(
            {
                pathname: stateObject.callback,
                query: this.props.location.query
            }
        );
    }

    render() {
        return null;
    }
}
