import React from 'react';
import withTaxonomy from 'shared/taxonomy/enhancer';
import SegmentContactUS from 'myhg/segment/components/SegmentContactUS';
import {
    SEGMENT_CONTACT_SALES_TITLE,
    SEGMENT_CONTACT_SALES_MESSAGE,
    SEGMENT_CONTACT_SALES_BUTTON_TEXT
} from '../../constants';

class SegmentTableContactSales extends React.Component {
    render() {
        return (
            <div className="segment-table-contact-sales">
                <h2 className="h2">{ SEGMENT_CONTACT_SALES_TITLE }</h2>
                <p>
                    { SEGMENT_CONTACT_SALES_MESSAGE }
                </p>
                <SegmentContactUS
                    linkLabel= { SEGMENT_CONTACT_SALES_BUTTON_TEXT }
                    cssClass="contact-us"
                    { ...this.props }
                />
            </div>
        );
    }
}

export default withTaxonomy(SegmentTableContactSales);
