import React from 'react';
import classNames from 'classnames';

export default class RadioButton extends React.Component {

    onSelect = () => {
        if (!this.props.checked && this.props.onSelectChange) {
            this.props.onSelectChange(this.props.item);
        }
    }

    render() {
        const checkClass = this.props.checked ? 'checked' : '';
        const radioClass = classNames('radio-button', checkClass, this.props.className);

        return (
            <div className={ radioClass } >
                <div
                    className="selection"
                    onClick={ this.onSelect }
                >
                    <span className="check" />
                </div>
                <div className="radion-button-container">
                    { this.props.children }
                </div>
            </div>
        );
    }
}
