import { combineReducers } from 'redux';
import FirmographicUtils from 'utils/FirmographicUtils';
import {
    SEGMENT_MATCHES_DATA_REQUEST,
    SEGMENT_MATCHES_DATA_SUCCESS,
    SEGMENT_MATCHES_DATA_FAILURE,
    SEGMENT_NEW_FILTERS
} from './constants';
import {
    SEGMENT_RESET_VIEW
} from 'myhg/segment/constants';

export const matchesDataLoadInitialState = {
    inProgress: false,
    success: false,
    error: null,
    receivedData: [],
    data: [],
    cancelToken: null
};

function getProductsObjects(data) {
    const newData = [];
    for (let i = 0; i < data.length; i++) {
        const obj = Object.assign({}, data[i]);
        const newObj = Object.assign({}, obj);

        newObj.revenue = FirmographicUtils.getDomainRangeByItem(
            {
                from: Number(obj.revenueRangeFrom),
                to: Number(obj.revenueRangeTo)
            },
            {
                isCurrency: true
            }
        );
        newObj.employees = FirmographicUtils.getDomainRangeByItem(
            {
                from: Number(obj.employeesRangeFrom),
                to: Number(obj.employeesRangeTo)
            },
            {
                isCurrency: false
            }
        );

        newData.push(newObj);
    }

    return newData;
}

export function matchesDataLoad(state = matchesDataLoadInitialState, action) {
    switch (action.type) {
        case SEGMENT_MATCHES_DATA_REQUEST:
            return Object.assign({}, state, {
                inProgress: true,
                cancelToken: action.cancelToken
            });

        case SEGMENT_MATCHES_DATA_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                receivedData: action.data,
                data: action.clearData ?
                    getProductsObjects(action.data) :
                    state.data.concat(getProductsObjects(action.data)),
                error: null,
                cancelToken: null
            });

        case SEGMENT_MATCHES_DATA_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                error: action.error,
                cancelToken: null
            });

        case SEGMENT_RESET_VIEW: {
            return Object.assign({}, state, {
                inProgress: false,
                error: null,
                receivedData: [],
                data: []
            });
        }

        default:
            return state;
    }
}

export function hasNewFilters(state = false, action) {
    switch (action.type) {
        case SEGMENT_NEW_FILTERS:
            return action.hasFilters;

        default:
            return state;
    }
}

export default combineReducers({
    matchesDataLoad,
    hasNewFilters
});
