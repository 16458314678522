import { LANDING_PAGE_TYPES } from '../../constants';
import OAuthSubmitAction
    from './platformActions/OAuthSubmitAction';
import RedirectAction
    from './platformActions/RedirectAction';

export const connectActionsComponents = {
    [LANDING_PAGE_TYPES.LP_SALESFORCE]: OAuthSubmitAction,
    [LANDING_PAGE_TYPES.LP_PARDOT]: RedirectAction,
    [LANDING_PAGE_TYPES.LP_ELOQUA]: OAuthSubmitAction,
    [LANDING_PAGE_TYPES.LP_MARKETO]: RedirectAction,
    [LANDING_PAGE_TYPES.LP_FILE]: RedirectAction
};
