export const FILTER_PRODUCT_ADD = 'FILTER_PRODUCT_ADD';
export const FILTER_PRODUCT_REMOVE = 'FILTER_PRODUCT_REMOVE';
export const FILTER_PRODUCT_SET = 'FILTER_PRODUCT_SET';
export const FILTER_PRODUCT_CLEAR = 'FILTER_PRODUCT_CLEAR';
export const FILTER_PRODUCT_MAX_COUNT = 3;
export const FILTER_PRODUCT_SEARCH_PLACEHOLDER = 'Search and add a technology product(s)';
export const FILTER_PRODUCT_SEARCH_PLACEHOLDER_FREE =
    'As a free user you can add up to 3 technology product(s)';
export const FILTER_PRODUCT_SEARCH_ONE_PLACEHOLDER =
    'Please add at least one technology to apply filters';
export const FILTER_PRODUCTS_ADD = 'FILTER_PRODUCTS_ADD';

export const PRODUCTS_RELATION_SET = 'PRODUCTS_RELATION_SET';
export const PRODUCTS_RELATION_TEXT = ['Companies using ', ' of the following products'];
export const PRODUCTS_RELATION_OPTIONS = [
    { text: 'any', value: false },
    { text: 'all', value: true }
];
