import React from 'react';
import { connect } from 'react-redux';
import GenericModal from 'shared/ui/modal/GenericModal';
import {
    SEGMENT_LIMIT_NOTIFICATION_TITLE,
    SEGMENT_LIMIT_NOTIFICATION_CONTENT_FREE,
    SEGMENT_LIMIT_NOTIFICATION_CONTENT_TRIAL
} from 'myhg/segment/constants';
import UserUtils from 'utils/UserUtils';

function mapStateToProps(state) {
    return {
        accountDetails: state.user.accountDetails.data
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class LimitReachedModalContainer extends React.Component {

    render() {
        const { accountDetails } = this.props;
        const trialData = UserUtils.getCampaignTrialInfo(accountDetails);

        const limitReachedNotification = trialData.isActive ?
            SEGMENT_LIMIT_NOTIFICATION_CONTENT_TRIAL :
            SEGMENT_LIMIT_NOTIFICATION_CONTENT_FREE;

        return (
            <GenericModal
                open = { this.props.open }
                title={ SEGMENT_LIMIT_NOTIFICATION_TITLE }
                content={ limitReachedNotification }
                onSecondaryActionClick={ this.props.onLimitOkClick }
                secondaryActionText="Ok"
            />
        );
    }
}
