import React from 'react';
import Modal from 'react-modal';

export default class GenericModal extends React.Component {

    getActions() {
        const {
            onPrimaryActionClick,
            onSecondaryActionClick,
            primaryActionText,
            secondaryActionText
        } = this.props;

        const primaryAction = onPrimaryActionClick ? (
                <button className="btn btn-primary" onClick={ onPrimaryActionClick }>
                    { primaryActionText }
                </button>
            ) : null;
        const secondaryAction = onSecondaryActionClick ? (
                <button className="btn btn-default" onClick={ onSecondaryActionClick }>
                    { secondaryActionText }
                </button>
            ) : null;

        return (
            <div className="actions">
                { primaryAction }
                { secondaryAction }
            </div>
        );
    }

    getCloseButton() {
        const { onCloseActionClick } = this.props;

        return onCloseActionClick
            ? <span className="close icon icon-close-x" onClick={ onCloseActionClick }></span>
            : null;
    }

    render() {
        const {
            open,
            className,
            title,
            content
        } = this.props;

        return (
            <Modal
                isOpen={ open }
                closeTimeoutMS={ 150 }
                className={ className || 'generic-modal' }
                contentLabel="contentLabel"
            >
                { this.getCloseButton() }
                <h4>
                    { title }
                </h4>
                {
                    content ? (
                        <div className="main-content">
                            { content }
                        </div>
                    ) : null
                }
                { this.getActions() }
            </Modal>
        );
    }
}
