import { combineReducers } from 'redux';
import {
    FILTERS_ADD_INDUSTRY,
    FILTERS_REMOVE_INDUSTRY,
    FILTERS_SET_INDUSTRIES,
    FILTERS_ALL_INDUSTRIES,
    FILTERS_TOP_INDUSTRIES
} from '../constants';

export const selectedIndustriesFilterInitialState = [];

export function parseIndustriesforDropdown(items, topItems, excludeIndustries) {
    // extract industries
    let industryItems = items.map((industry) => {
        return {
            id: industry,
            text: industry
        };
    });

    let topIndustryItems = topItems.map((industry) => {
        return {
            id: industry.name,
            text: industry.name
        };
    });

    // exclude industries
    industryItems = industryItems.filter((industry) => {
        return excludeIndustries.findIndex((excludedIndustryId) => {
            return industry.id === excludedIndustryId;
        }) === -1;
    });

    // exclude top industries from list
    industryItems = industryItems.filter((industry) => {
        return topIndustryItems.findIndex((topIndustry) => {
            return topIndustry.id === industry.id;
        }) === -1;
    });

    // separate list of top industries
    topIndustryItems = topIndustryItems.filter((industry) => {
        return excludeIndustries.findIndex((excludedIndustryId) => {
            return industry.id === excludedIndustryId;
        }) === -1;
    });

    return [{
        type: FILTERS_TOP_INDUSTRIES,
        items: topIndustryItems
    }, {
        type: FILTERS_ALL_INDUSTRIES,
        items: industryItems
    }];
}

export function selectedIndustries(state = selectedIndustriesFilterInitialState, action) {
    switch (action.type) {
        case FILTERS_ADD_INDUSTRY:
            return [...state, action.industry];

        case FILTERS_REMOVE_INDUSTRY: {
            const index = state.indexOf(action.industry);
            return [
                ...state.slice(0, index),
                ...state.slice(index + 1)
            ];
        }

        case FILTERS_SET_INDUSTRIES:
            return action.industries;

        default:
            return state;
    }
}

export default combineReducers({
    selectedIndustries
});
