import { combineReducers } from 'redux';
import {
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_CLEAN,
    UPLOAD_FILE_PROGRESS,
    UPLOAD_FILE_ON_HOLD,
    ADD_PHONE_NUMBER_REQUEST,
    ADD_PHONE_NUMBER_SUCCESS,
    ADD_PHONE_NUMBER_FAILURE,
} from './constants';


// uploadFile reducer

const uploadFileInitialState = {
    uploadInProgress: false,
    uploadProgress: 0,
    uploadOnHold: false,
    uploadOnHoldFile: {},
    uploaded: false,
    error: null
};

function uploadFile(state = uploadFileInitialState, action) {
    switch (action.type) {
        case UPLOAD_FILE_REQUEST:
            return Object.assign({}, state, {
                uploadInProgress: true,
                error: null
            });
        case UPLOAD_FILE_SUCCESS:
            return Object.assign({}, state, {
                uploadInProgress: false,
                uploadOnHold: false,
                uploadOnHoldFile: {},
                uploaded: true,
                error: null
            });
        case UPLOAD_FILE_FAILURE:
            return Object.assign({}, state, {
                uploadInProgress: false,
                uploadOnHold: false,
                error: action.error
            });
        case UPLOAD_FILE_CLEAN:
            return Object.assign({}, state, {
                uploadInProgress: false,
                uploadOnHold: false,
                uploadOnHoldFile: {},
                uploaded: false,
                error: null
            });
        case UPLOAD_FILE_PROGRESS:
            return Object.assign({}, state, {
                uploadProgress: Number.parseInt(action.progress, 10)
            });
        case UPLOAD_FILE_ON_HOLD:
            return Object.assign({}, state, {
                uploadInProgress: false,
                uploadOnHold: true,
                uploadOnHoldFile: action.file,
                error: null
            });
        default:
            return state;
    }
}


// addPhoneNumber reducer

const addPhoneNumberInitialState = {
    added: false,
    error: null
};

function addPhoneNumber(state = addPhoneNumberInitialState, action) {
    switch (action.type) {
        case ADD_PHONE_NUMBER_REQUEST:
            return Object.assign({}, state, {
                error: null
            });
        case ADD_PHONE_NUMBER_SUCCESS:
            return Object.assign({}, state, {
                added: true,
                error: null
            });
        case ADD_PHONE_NUMBER_FAILURE:
            return Object.assign({}, state, {
                error: action.error
            });
        default:
            return state;
    }
}


// exports

export const initialState = {
    uploadFile: uploadFileInitialState,
    addPhoneNumber: addPhoneNumberInitialState
};

export default combineReducers({
    uploadFile,
    addPhoneNumber
});
