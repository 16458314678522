import { HTTP_CONFLICT_CODE } from 'shared/constants';
import {
    CONFIRM_SIGN_UP_ACCOUNT_EXISTS_TITLE,
    CONFIRM_SIGN_UP_FAILURE_TITLE,
    CONFIRM_SIGN_UP_REQUEST,
    CONFIRM_SIGN_UP_REQUEST_TITLE,
    CONFIRM_SIGN_UP_SUCCESS,
    CONFIRM_SIGN_UP_FAILURE,
    VALID_VERIFICATION_LINK
} from './constants';

export const confirmRegistrationInitialState = {
    submitting: false,
    authData: null,
    isValidLink: true,
    displayMenuTitle: ''
};

function getConfirmErrorTitle(error) {
    let errorTitle;
    if (HTTP_CONFLICT_CODE === error.status) {
        errorTitle = CONFIRM_SIGN_UP_ACCOUNT_EXISTS_TITLE;
    } else {
        errorTitle = CONFIRM_SIGN_UP_FAILURE_TITLE;
    }

    return errorTitle;
}

export default function confirmRegistration(state = confirmRegistrationInitialState, action) {
    switch (action.type) {
        case CONFIRM_SIGN_UP_REQUEST :
            return Object.assign({}, state, {
                submitting: true,
                authData: null,
                error: null,
                isValidLink: true,
                displayMenuTitle: CONFIRM_SIGN_UP_REQUEST_TITLE
            });

        case CONFIRM_SIGN_UP_SUCCESS :
            return Object.assign({}, state, {
                authData: action.data,
                submitting: false,
                displayMenuTitle: ''
            });

        case CONFIRM_SIGN_UP_FAILURE :
            return Object.assign({}, state, {
                submitting: false,
                error: action.error,
                displayMenuTitle: getConfirmErrorTitle(action.error)
            });

        case VALID_VERIFICATION_LINK :
            return Object.assign({}, state, {
                submitting: false,
                isValidLink: action.isValid,
                displayMenuTitle: action.isValid ? '' : CONFIRM_SIGN_UP_FAILURE_TITLE
            });

        default:
            return state;
    }
}
