import React from 'react';
import {
    ACCOUNT_BENEFITS_COMPONENTS
} from './constants';

export default class SignUpGenericPanel extends React.Component {

    getTitle() {
        return (
            <div className="title">
                HG Insights Account Benefits
            </div>
        );
    }

    getFlowDetails() {
        const landingPage = this.props;
        const landingPageName = landingPage.landingPage || '';
        const AccountBenefitsComp = ACCOUNT_BENEFITS_COMPONENTS[landingPageName];

        return <AccountBenefitsComp />;
    }

    render() {
        return (
            <div className="signup-landing-page">
                { this.getTitle() }
                { this.getFlowDetails() }
            </div>
        );
    }
}
