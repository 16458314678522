import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import UserUtils from 'utils/UserUtils';
import {
    SEGMENT_DATA_MENU_NAME
} from './constants';
import {
    getMenuByName,
    segmentMenuSelect
} from './actions';
import {
    saveCurrentSegment
} from 'myhg/segment/actions';
import { HTTP_FORBIDEN_CODE } from 'shared/constants';
import LimitReachedModalContainer from 'myhg/segment/LimitReachedModalContainer';
import SegmentMenuActions from './components/SegmentMenuActions';
import SegmentTabMenus from './components/SegmentTabMenus';
import { track } from 'utils/Utils';
import { ACTION, WHERE } from 'utils/track-constants';

function mapStateToProps(state) {
    return {
        segment: state.myhg.segment,
        segmentMenu: state.myhg.segment.segmentMenu,
        accountDetailsData: state.user.accountDetails.data
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class SegmentMenuActionsContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showLimitReachedModal: false
        };
    }

    componentWillMount() {
        const { dispatch, accountDetailsData } = this.props;
        const paidUser = UserUtils.isPaid(accountDetailsData);

        if (paidUser) {
            const menu = getMenuByName(SEGMENT_DATA_MENU_NAME);
            dispatch(segmentMenuSelect(menu));
        }
    }

    onLimitOkClick = () => {
        this.setState({
            showLimitReachedModal: false
        });
    }

    getLimitReachedModal() {
        return (
            <LimitReachedModalContainer
                onLimitOkClick={ this.onLimitOkClick }
                open={ this.state.showLimitReachedModal }
            />
        );
    }

    handleShareClick = () => {
        track(ACTION.SHARE_SEGMENT, { label: WHERE.SEGMENT_MENU });
        browserHistory.push('/segment/share');
    }

    handleSaveClick = () => {
        const { dispatch, isUserAuthenticated } = this.props;

        if (!isUserAuthenticated) {
            browserHistory.push('/register');
        } else {
            dispatch(saveCurrentSegment()).catch((error) => {
                if (error && error.status === HTTP_FORBIDEN_CODE) {
                    this.setState({
                        showLimitReachedModal: true
                    });
                }
            });
        }

        track(ACTION.SAVE_SEGMENT_MENU, { label: WHERE.SEGMENT_MENU });
    }

    render() {
        return (
            <div id="segment-top-section">
                <SegmentTabMenus { ...this.props } />
                <SegmentMenuActions
                    { ...this.props }
                    handleShareClick={ this.handleShareClick }
                    handleSaveClick={ this.handleSaveClick }
                />
                { this.getLimitReachedModal() }
            </div>
        );
    }
}
