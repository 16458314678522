import React from 'react';
import ReactDOM from 'react-dom';
import { NumeralUtils } from 'utils/Utils';
import UsMapChart from 'shared/ui/charts/UsMapChart';
import UserUtils from 'utils/UserUtils';
import {
    MAP_LOCATION_TOOLTIP,
    NET_NEW,
    MATCHES
} from '../constants';

/**
 * Component that renders the Locations Chart
 */
export default class USMapLocationsChart extends React.Component {

    componentDidMount() {
        this.locationsChart = new UsMapChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        this.locationsChart.update(this.getChartState());
    }

    componentWillUnmount() {
        this.locationsChart.destroy();
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getChartState() {
        if (this.props.data && this.props.data.usLocations) {
            const isTrialExpired = UserUtils.isTrialExpired(this.props.accountDetails);
            const data = this.props.data.usLocations || [];
            const chartData = [];
            data.forEach((item) => {
                const newItem = item;
                newItem.value = (!this.props.isMatched || isTrialExpired) ?
                newItem.value : (newItem.netNew + newItem.matches);
                chartData.push(Object.assign({}, newItem, {
                    tooltip: `<span>${MAP_LOCATION_TOOLTIP} ${newItem.name} </span> <br />
                            ${(!this.props.isMatched || isTrialExpired) ?
                                this.noMatchesNumberTooltip(newItem) :
                                this.matchesNumberTooltip(newItem)
                            }`
                }));
            });
            return {
                data: chartData,
                chartHeight: 630,
                tooltip: true,
                color: '#16a6ba' // $primary-50
            };
        }

        return {};
    }

    matchesNumberTooltip(item) {
        return (
            `<span class='high-light matched'>
                ${NET_NEW}: ${NumeralUtils.formatIntegerNumber(item.netNew)} <br />
                ${MATCHES}: ${NumeralUtils.formatIntegerNumber(item.matches)}
            </span>`
        );
    }

    noMatchesNumberTooltip(item) {
        return (
            `<span class='high-light'>
                ${NumeralUtils.formatIntegerNumber(item.value)}
            </span>`
        );
    }

    render() {
        return (
            <div className="locations-map-container"></div>
        );
    }
}
