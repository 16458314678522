import React from 'react';

export default class ProductCounter extends React.Component {

    getPercentageData() {
        const { percentage, trendInconClass } = this.props;
        // We should only display the 12-month change (growth rate) if it is above 0%.
        // We don't want to show negative growth.
        if (percentage <= 0) {
            return null;
        }

        return (
            <div>
                <span className={ trendInconClass } />
                <div className="percentage">
                    <span> { percentage }% </span> <br /> 12 MONTHS CHANGE
                </div>
            </div>
        );
    }

    render() {
        const { counts, techName } = this.props;

        return (
            <div className="counter-wrapper" >
                <span className="title" > COMPANIES WE TRACK USING { techName } <br /> </span>
                <div className="counts">
                    <span className="icon icon-building" />
                    <div className="counts-value">
                        { counts }
                    </div>
                    { this.getPercentageData() }
                </div>
            </div>
        );
    }
}
