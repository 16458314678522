export const SELECT_LOCATIONS_PLACEHOLDER = 'Select countries or regions to target';
export const SELECT_LOCATIONS_ALL_PLACEHOLDER = 'All locations';
export const APAC_REGION_NAME = 'APAC';
export const APAC_FULL_REGION_NAME = 'APAC (Asia & Pacific)';
export const EMEA_REGION_NAME = 'EMEA';
export const EMEA_FULL_REGION_NAME = 'EMEA (Europe, Middle East & Africa)';
export const NORTH_AMERICA_REGION_NAME = 'North America';
export const NORTH_AMERICA_FULL_REGION_NAME = 'North America';
export const LATAM_REGION_NAME = 'LATAM';
export const LATAM_FULL_REGION_NAME = 'LATAM (Latin America & The Carribbean)';
