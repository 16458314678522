import React from 'react';
import { connect } from 'react-redux';
import SegmentSetupFilters from './components/SegmentSetupFilters';
import Loader from 'shared/ui/loader/Loader';
import loadFirmographicTaxonomy from 'shared/taxonomy/firmographic/actions';
import {
    saveCurrentSegment,
    applyFilters,
    cancelUnresolvedRelatedSegmentRequests,
    filtersChanged,
    loadRelatedSegmentData,
    updateSegmentUrl
} from 'myhg/segment/actions';
import { cleanFirmographicsData } from 'myhg/segment/firmographicProfile/actions';
import { showEditFilterView } from './actions';
import {
    SEGMENT_FILTERS_EDIT_VIEW_CHANGE,
    SEGMENT_ADVANCED_FILTERS_SET_VISIBILITY
} from './constants';
import { ACTION } from 'utils/track-constants';
import { DateUtils, track } from 'utils/Utils';
import { HTTP_FORBIDEN_CODE } from 'shared/constants';
import LimitReachedModalContainer from 'myhg/segment/LimitReachedModalContainer';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        segment,
        accountDetails: state.user.accountDetails.data,
        isFiltersEditOpen: segment.segmentView.isFiltersEditOpen,
        firmographic: state.taxonomy.firmographic,
        segmentFiltersView: segment.segmentFilters.segmentFiltersView
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class SegmentFiltersContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showLimitReachedModal: false
        };
    }

    componentDidMount() {
        const { segmentId } = this.props;

        if (!this.props.firmographic.data) {
            this.props.dispatch(loadFirmographicTaxonomy());
        }

        // hide filters for existing segments
        if (segmentId) {
            this.props.dispatch({
                type: SEGMENT_FILTERS_EDIT_VIEW_CHANGE,
                value: false
            });
        }
    }

    componentDidUpdate() {
        if (!this.props.firmographic.data && !this.props.firmographic.isFetching) {
            this.props.dispatch(loadFirmographicTaxonomy());
        }
    }

    onLimitOkClick = () => {
        this.setState({
            showLimitReachedModal: false
        });
    }

    onViewToggle = () => {
        this.props.dispatch({
            type: SEGMENT_FILTERS_EDIT_VIEW_CHANGE,
            value: !this.props.isFiltersEditOpen
        });
        window.scrollTo(0, 0);
    }

    onAdvancedViewToggle = () => {
        this.props.dispatch({
            type: SEGMENT_ADVANCED_FILTERS_SET_VISIBILITY,
            value: !this.props.segmentFiltersView.isAdvancedOpen
        });
    }

    applyFilters = () => {
        const { dispatch } = this.props;
        const shouldSaveCountsOnComplete = false;
        // clean firmographics data
        dispatch(cleanFirmographicsData());
        // apply new filters on curent segment
        dispatch(applyFilters());
        // trigger a filters changed action
        dispatch(filtersChanged());
        dispatch(loadRelatedSegmentData(shouldSaveCountsOnComplete));
        dispatch(showEditFilterView(false));
        dispatch(updateSegmentUrl());
    }

    saveSegmentFilters = () => {
        const { dispatch } = this.props;
        const { currentSegment } = this.props.segment;

        dispatch(saveCurrentSegment())
            .then(() => {
                // cancel unresolved related segment requests
                dispatch(cancelUnresolvedRelatedSegmentRequests());
                // clean firmographics data
                dispatch(cleanFirmographicsData());
                // trigger a filters changed action
                dispatch(filtersChanged());
                // load any related segment data
                dispatch(loadRelatedSegmentData());
                // hide segment filters edit mode
                dispatch(showEditFilterView(false));
                dispatch(updateSegmentUrl());

                track(currentSegment.id ? ACTION.SAVE_SEGMENT_UPDATED : ACTION.SAVE_SEGMENT_NEW, {
                    label: currentSegment.name || DateUtils.toStandardFormatDateTime(new Date)
                });
            })
            .catch((error) => {
                if (error && error.status === HTTP_FORBIDEN_CODE) {
                    this.setState({
                        showLimitReachedModal: true
                    });
                }
            });
    }

    displayModal() {
        const { showLimitReachedModal } = this.state;
        return (
            <LimitReachedModalContainer
                onLimitOkClick={ showLimitReachedModal ? this.onLimitOkClick : null }
                open={ showLimitReachedModal }
            />
        );
    }

    render() {
        const { segmentId, isFiltersEditOpen } = this.props;
        const { segmentLoad } = this.props.segment;

        // display filter after segment data loads
        const shouldFiltersDisplayWait = segmentId && !segmentLoad.loaded;

        let content = null;
        if (!this.props.firmographic.data || shouldFiltersDisplayWait) {
            content = <Loader />;
        } else {
            content = (
                <div>
                    <SegmentSetupFilters
                        { ...this.props }
                        onViewToggle={ this.onViewToggle }
                        onAdvancedViewToggle={ this.onAdvancedViewToggle }
                        isFiltersEditOpen={ isFiltersEditOpen }
                        saveSegmentFilters={ this.saveSegmentFilters }
                        applyFilters={ this.applyFilters }
                    />
                    { this.displayModal() }
                </div>
            );
        }

        return content;
    }
}
