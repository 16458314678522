import React from 'react';
import AvailablePlatformsIcons from 'myhg/platforms/AvailablePlatformsIcons';

export default class UserTrialNotification extends React.Component {

    render() {
        return (
            <div className="trial-notice">
                <div className="container">
                    <div className="trial-notice-header">
                        <div className="trial-notice-title">
                            { this.props.title }
                        </div>
                        <AvailablePlatformsIcons />
                    </div>
                    <div className="trial-notice-description">
                        { this.props.description }
                        { this.props.actionLink }
                    </div>
                </div>
            </div>
        );
    }
}
