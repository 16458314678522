import React from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import ModalWizardHeader from 'shared/header/ModalWizardHeader';

/**
 * Modal Wizard Wrapper
 */
export default class ModalWizardWrapper extends React.Component {

    render() {
        const panelClasses = classNames('modal-wizard-wrapper transparent', this.props.className, {
            large: !!this.props.isLarge
        });

        return (
            <div className="modal-container">
                <Helmet
                    htmlAttributes= { { lang: 'en' } }
                    title= { this.props.title }
                />
                <ModalWizardHeader
                    onBack={ this.props.onBack }
                    onClose={ this.props.onClose }
                    title={ this.props.title }
                    customHeader={ this.props.customHeader }
                />
                <PanelWrapper className={ panelClasses }>
                    { this.props.children }
                </PanelWrapper>
            </div>
        );
    }
}
