import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
    DateUtils,
    UrlUtils
} from 'utils/Utils';
import {
    DEFAULT_VALUE,
    LAST_VERIFIED_ICON_TEXT
} from '../constants';

export default class SampleCompaniesTable extends React.Component {
    getTableHeaderSchema() {
        return (
            [
                {
                    name: 'Company Name',
                    key: 'name',
                    icon: 'icon-building',
                    classNames: ['first-child']
                },
                {
                    name: 'Website',
                    key: 'url',
                    formatter: this.formattedURL,
                    classNames: ['width-160']
                },
                {
                    name: 'HQ Address',
                    key: 'address',
                    classNames: ['width-160']
                },
                {
                    name: 'City',
                    key: 'city',
                    classNames: ['width-120']
                },
                {
                    name: 'State',
                    key: 'state'
                },
                {
                    name: 'Zip',
                    key: 'zip'
                },
                {
                    name: 'Country',
                    key: 'iso'
                },
                {
                    name: 'Top Level Industry',
                    key: 'topLevelIndustry',
                    classNames: ['width-160']
                },
                {
                    name: 'Sub Level Industry',
                    key: 'subLevelIndustry',
                    classNames: ['width-300']
                },
                {
                    name: 'Employees',
                    key: 'employeeRange',
                    classNames: ['width-160']
                },
                {
                    name: 'Revenue (USD)',
                    key: 'revenueRange',
                    classNames: ['width-200']
                },
                {
                    name: 'Phone',
                    key: 'phone',
                    classNames: ['width-120']
                },
                {
                    name: 'Last Verified',
                    key: 'lastVerifiedDate',
                    info: LAST_VERIFIED_ICON_TEXT,
                    formatter: DateUtils.getTimePassedSinceDate,
                    classNames: ['width-140']
                }
            ]
        );
    }

    getTableHeaderColumn(th, key) {
        let tooltipComp = '';
        let tooltipSpan = '';
        if (th.info) {
            tooltipComp = (
                <ReactTooltip
                    effect="solid"
                    place="top"
                    id="lastVerified"
                    offset={ { right: 10 } }
                />
            );
            tooltipSpan = (
                <span
                    data-for="lastVerified"
                    className="tooltip-info icon-info"
                    data-tip={ th.info }
                    data-html
                />
            );
        }

        return (
            <th className={ th.classNames } key={ key }>
                { th.name }
                { tooltipComp }
                { tooltipSpan }
            </th>
        );
    }

    getTableDataForRow(th, tr, key) {
        let dataIcon = null;
        let dataText = !!tr[th.key] ? tr[th.key] : DEFAULT_VALUE;

        if (!!th.icon) {
            dataIcon = <span className={ th.icon } aria-hidden="true" />;
        }

        if (!!th.formatter) {
            dataText = th.formatter(dataText);
        }

        return (
            <td className={ th.classNames } key={ key }>
                { dataIcon }
                <span className="text"> { dataText } </span>
            </td>
        );
    }

    getTableBodyRow(tr, key) {
        return (
            <tr key={ key }>
                {
                    this.getTableHeaderSchema().map((th, i) => {
                        return this.getTableDataForRow(th, tr, i);
                    })
                }
            </tr>
        );
    }

    getTableHeader() {
        return (
            <tr>
                {
                    this.getTableHeaderSchema().map((th, i) => {
                        return this.getTableHeaderColumn(th, i);
                    })
                }
            </tr>
        );
    }

    getTableBody() {
        const { rows } = this.props;

        return (
            rows.map((tr, i) => {
                return this.getTableBodyRow(tr, i);
            })
        );
    }

    formattedURL(url) {
        return (
            <a
                href={ UrlUtils.prefixWithProtocol(url) }
                target="_blank"
                rel="nofollow"
            >
                { url }
            </a>
        );
    }

    render() {
        return (
            <div className="table-wrapper">
                <table className="product-samples-layout">
                    <thead>
                        { this.getTableHeader() }
                    </thead>
                    <tbody>
                        { this.getTableBody() }
                    </tbody>
                </table>
            </div>
        );
    }
}
