// action constants
export const FILTERS_SET_LAST_CHECK_VALUE = 'FILTERS_SET_LAST_CHECK_VALUE';
export const FILTERS_LAST_CHECK_CLEAR = 'FILTERS_LAST_CHECK_CLEAR';
// options
export const FILTERS_LAST_CHECK_TITLE = 'Target this time span:';
export const FILTERS_LAST_CHECK_OPTIONS = [
    {
        id: 1,
        text: '6 Months',
        value: 6
    },
    {
        id: 2,
        text: '3 Months',
        value: 3
    },
    {
        id: 3,
        text: '2 Months',
        value: 2
    },
    {
        id: 4,
        text: '1 Month',
        value: 1
    }
];
