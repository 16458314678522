import React from 'react';

export default class FormButtonWrapper extends React.Component {

    render() {
        return (
            <div className="form-button-wrapper">
                <div className="line" />
                <div className="line-button-wrapper">
                    { this.props.children }
                </div>
            </div>
        );
    }
}
