import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { setRefererPath } from './actions';

function mapStateToProps(state) {
    return {
        prevLocationPath: state.root.prevLocationPath,
        refererPath: state.authentication.refererPath
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

/**
 * Page Panel Wrapper
 */
export default class AuthModalWrapperContainer extends React.Component {

    componentWillMount() {
        const { prevLocationPath, dispatch } = this.props;
        dispatch(setRefererPath(prevLocationPath || '/'));
    }

    onBack = () => {
        browserHistory.goBack();
    }

    onClose = () => {
        browserHistory.push(this.props.refererPath);
    }

    render() {
        // modal page/route will always have a single children
        // use this method to pass additional props to the children
        const child = React.Children.only(this.props.children);
        return React.cloneElement(child, {
            onClose: this.onClose,
            onBack: this.onBack
        });
    }
}
