import React from 'react';
import Helmet from 'react-helmet';
import {
    browserHistory,
    withRouter
} from 'react-router';
import { connect } from 'react-redux';
import CollectionDetails from './components/CollectionDetails';
import BlankPageWrapper from 'shared/blankPage/BlankPageWrapper';
import { HTTP_NOTFOUND_CODE } from 'shared/constants';
import { loadCollectionById } from './actions';
import HomeContainer from 'discovery/home/HomeContainer';
import {
    PRODUCT_COLLECTION_TITLE_PAGE
} from './constants';

function mapStateToProps(state) {
    return {
        authData: state.authentication.authData,
        collection: state.collection,
        prevLocationPath: state.root.prevLocationPath
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

/**
 * Product page container
 */
class CollectionContainer extends React.Component {

    // actions to fulfill before pageload
    static needs = [
        loadCollectionById
    ];

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(loadCollectionById(this.props.params.collectionId));
    }

    onClose = () => {
        const { prevLocationPath } = this.props;

        if (!!prevLocationPath) {
            // We want to go back in order to
            // preserve the scroll position
            this.props.router.goBack();
        } else {
            browserHistory.push('/');
        }
    }

    getHelmetPage() {
        const { data } = this.props.collection;
        const description = (data && data.description);
        const name = (data && data.name);

        return (
            <Helmet
                htmlAttributes= { { lang: 'en' } }
                title= { name }
                meta={ [{
                    name: 'description',
                    content: description }
                ] }
            />
        );
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    render() {
        if (this.props.collection.error &&
            this.props.collection.error.status === HTTP_NOTFOUND_CODE) {
            return <HomeContainer route={ { status: HTTP_NOTFOUND_CODE } } />;
        }

        return (
            <div>
                { this.getHelmetPage() }
                <BlankPageWrapper
                    title={ PRODUCT_COLLECTION_TITLE_PAGE }
                    onClose={ this.onClose }
                >
                    <CollectionDetails
                        isUserAuthenticated={ this.isUserAuthenticated() }
                        { ...this.props }
                    />
                </BlankPageWrapper>
                { this.props.children }
            </div>
        );
    }
}

export default withRouter(CollectionContainer);
