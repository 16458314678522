import { combineReducers } from 'redux';
import {
    FIRMOGRAPHIC_LOCATIONS_ROUTE,
    FIRMOGRAPHIC_EMPLOYEES_ROUTE,
    FIRMOGRAPHIC_REVENUE_ROUTE,
    FIRMOGRAPHIC_INDUSTRY_ROUTE,
    FIRMOGRAPHIC_TAB_SELECT,
    FIRMOGRAPHIC_DATA_REQUEST,
    FIRMOGRAPHIC_DATA_SUCCESS,
    FIRMOGRAPHIC_DATA_FAILURE
} from './constants';

export const initialState = {
    menus: [{
        displayName: 'Locations',
        name: FIRMOGRAPHIC_LOCATIONS_ROUTE
    }, {
        displayName: '# Employees',
        name: FIRMOGRAPHIC_EMPLOYEES_ROUTE
    }, {
        displayName: 'Revenue (USD)',
        name: FIRMOGRAPHIC_REVENUE_ROUTE
    }, {
        displayName: 'Industry',
        name: FIRMOGRAPHIC_INDUSTRY_ROUTE
    }],
    isFetching: false,
    error: null,
    data: null
};

function menus(state = initialState.menus, action) {
    switch (action.type) {
        default:
            return state;
    }
}

function selectedMenu(state = FIRMOGRAPHIC_LOCATIONS_ROUTE, action) {
    switch (action.type) {
        case FIRMOGRAPHIC_TAB_SELECT :
            return action.name;

        default:
            return state;
    }
}

function data(state = initialState.data, action) {
    switch (action.type) {
        case FIRMOGRAPHIC_DATA_SUCCESS:
            return action.data;

        case FIRMOGRAPHIC_DATA_FAILURE:
        case FIRMOGRAPHIC_DATA_REQUEST:
            return initialState.data;

        default:
            return state;
    }
}

function isFetching(state = initialState.isFetching, action) {
    switch (action.type) {
        case FIRMOGRAPHIC_DATA_REQUEST:
            return true;

        case FIRMOGRAPHIC_DATA_FAILURE:
        case FIRMOGRAPHIC_DATA_SUCCESS:
            return initialState.isFetching;

        default:
            return state;
    }
}

function error(state = initialState.error, action) {
    switch (action.type) {
        case FIRMOGRAPHIC_DATA_FAILURE:
            return action.error;

        case FIRMOGRAPHIC_DATA_REQUEST:
        case FIRMOGRAPHIC_DATA_SUCCESS:
            return initialState.error;

        default:
            return state;
    }
}

export default combineReducers({
    menus,
    selectedMenu,
    data,
    isFetching,
    error
});
