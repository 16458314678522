import React from 'react';
import { connect } from 'react-redux';
import UserUtils from 'utils/UserUtils';
import TopPanelWrapper from 'shared/ui/panel/TopPanelWrapper';
import SegmentMenuActionsContainer
    from 'myhg/segment/segmentMenuActions/SegmentMenuActionsContainer';
import SegmentSummaryDetailsContainer
    from 'myhg/segment/segmentSummaryDetails/SegmentSummaryDetailsContainer';
import SegmentPlatformStatus
    from 'myhg/segment/components/SegmentPlatformStatus';
import SegmentTableContainer
    from 'myhg/segment/segmentTable/SegmentTableContainer';
import FirmographicProfileContainer
    from 'myhg/segment/firmographicProfile/FirmographicProfileContainer';
import {
    SEGMENT_DATA_MENU_NAME
} from './constants';

function mapStateToProps(state) {
    return {
        accountDetails: state.user.accountDetails.data,
        platformTokenStatus: state.myhg.platformConnection.platformToken.data,
        segmentMenu: state.myhg.segment.segmentMenu,
        segment: state.myhg.segment,
        downloadLimit: -1 // TODO: get from api response
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class SegmentContentContainer extends React.Component {

    getContentByMenu() {
        const {
            segmentMenu,
            isPlatformTypeAndNotConnected,
            isPlatformTypeAndOtherAccount,
            platformTokenStatus,
            segmentSource,
            segmentAccount,
            authData
        } = this.props;
        // check if trial period expired or not for non paid users;
        const isTrialExpired = UserUtils.isTrialExpired(this.props.accountDetails);

        if ((isPlatformTypeAndNotConnected || isPlatformTypeAndOtherAccount)
            && !isTrialExpired) {
            return (
                <SegmentPlatformStatus
                    isPlatformTypeAndNotConnected={ isPlatformTypeAndNotConnected }
                    isPlatformTypeAndOtherAccount={ isPlatformTypeAndOtherAccount }
                    segmentSource={ segmentSource }
                    segmentAccount={ segmentAccount }
                    currentAccount={ authData.salesforceUsername }
                    platformTokenStatus={ platformTokenStatus }
                />
            );
        }

        switch (segmentMenu.name) {
            case SEGMENT_DATA_MENU_NAME:
                return <SegmentTableContainer { ...this.props } />;

            default:
                return this.summaryContent();
        }
    }


    summaryContent() {
        return (
            <div>
                <SegmentSummaryDetailsContainer { ...this.props } />
                <FirmographicProfileContainer />
            </div>
        );
    }

    render() {
        return (
            <div className="segment-data-content">
                <TopPanelWrapper className="segment-menu">
                    <SegmentMenuActionsContainer { ...this.props } />
                </TopPanelWrapper>
                { this.getContentByMenu() }
            </div>
        );
    }
}
