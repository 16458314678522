import {
    FILTER_COLLECTION_ADD,
    FILTER_COLLECTION_REMOVE,
    FILTER_COLLECTION_SET,
    FILTER_COLLECTION_CLEAR
} from './constants';

export const collectionsInitialState = [];

export default function collections(state = collectionsInitialState, action) {
    switch (action.type) {
        case FILTER_COLLECTION_ADD: {
            const index = state.findIndex(collection => {
                return collection.slug === action.collection.slug;
            });
            if (index >= 0) {
                return state;
            }
            return [...state, action.collection];
        }

        case FILTER_COLLECTION_REMOVE: {
            const index = state.findIndex(collection => {
                return collection.slug === action.collection.slug;
            });
            return [...state.slice(0, index), ...state.slice(index + 1)];
        }

        case FILTER_COLLECTION_SET:
            return action.collections;

        case FILTER_COLLECTION_CLEAR:
            return collectionsInitialState;

        default:
            return state;
    }
}
