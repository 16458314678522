import storage from 'local-storage-fallback';

export default class LocalStorage {

    static getItemById(id, decode) {
        if (typeof window !== 'undefined') {
            let item = storage.getItem(id);
            if (decode) {
                item = item ? window.atob(item) : null;
            }
            return JSON.parse(item);
        }
        return null;
    }

    static saveItemById(id, data, encode) {
        if (typeof window !== 'undefined') {
            let dataToStore = data ? JSON.stringify(data) : null;
            if (encode && dataToStore) {
                dataToStore = window.btoa(unescape(encodeURIComponent(dataToStore)));
            }
            storage.setItem(id, dataToStore);
        }
    }

    static deleteItemById(id) {
        storage.removeItem(id);
    }
}
