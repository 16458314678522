import React from 'react';
import { ACTION, WHERE } from 'utils/track-constants';
import { track } from 'utils/Utils';
import {CONTACT_US_URL} from "../../../../shared/footer/constants";
import {Link} from "react-router";

/**
 * Component that renders the Limit Reached content
 */
export default class LimitReached extends React.Component {

    track(action) {
        track(action, { label: WHERE.FIRMOGRAPHICS });
    }

    render() {
        return (
            <div id="limit-reached" className="modal-form">
                <p className="text-content title">
                    You Have Exceeded Your Limits
                </p>
                <p className="text-content light-title">
                    Sorry, but we limit the number of graphs that can be viewed
                    within a specific time period, and you have reached this limit.
                </p>
                <p className="text-content light-title">
                    If you would like to increase this limit, please&nbsp;
                    <Link
                        to={CONTACT_US_URL}
                        target="_blank"
                        rel="nofollow"
                        className="primary-link"
                        id="limitreached-contact-us"
                        onClick={this.track.bind(this, ACTION.CONTACT_US)}
                    >
                        contact&nbsp;us
                    </Link>
                    .
                </p>
            </div>
        );
    }
}
