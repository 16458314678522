import React from 'react';
import sprintf from 'sprintf-js';
import { NumeralUtils } from 'utils/Utils';
import {
    MY_HG_ADDITIONAL_COMPANIES_TITLE,
    PERIOD_SELECTION_OPTIONS
} from './constants';
import Dropdown from 'shared/ui/dropdown/Dropdown';

export default class AdditionalCompanies extends React.Component {

    getPeriodName(periodNumber) {
        return periodNumber > 1 ? `${periodNumber} months` : `${periodNumber} month`;
    }

    getDropdownSelections() {
        const { period } = this.props;
        const periodsToShow = PERIOD_SELECTION_OPTIONS.filter((option) => {
            return option !== period;
        });

        return periodsToShow.map((periodToShow) => {
            return this.getPeriodName(periodToShow);
        });
    }

    render() {
        const { newCompanies: { data }, period } = this.props;
        const additionalCompaniesNumber = NumeralUtils.formatIntegerNumber(
            data ? data.companies : 0);
        const pastMontsAdditionalCompaniesText = sprintf.sprintf(
            MY_HG_ADDITIONAL_COMPANIES_TITLE,
            additionalCompaniesNumber
        );

        const dropdownActivator = (
            <div>
                { this.getPeriodName(period) }
                <span className="icon icon-small-arrow-down" />
            </div>
        );

        return (
            <div>
                <div className="subtitle">
                    { pastMontsAdditionalCompaniesText }
                    <Dropdown
                        activator={ dropdownActivator }
                        selections={ this.getDropdownSelections() }
                        onSelectionChange={ this.props.onPeriodChange }
                    />
                </div>
            </div>
        );
    }
}
