import React from 'react';
import { connect } from 'react-redux';
import AdditionalCompanies from './AdditionalCompanies';
import Loader from 'shared/ui/loader/Loader';
import UserUtils from 'utils/UserUtils';
import {
    PERIOD_SELECTION_OPTIONS
} from './constants';
import {
    loadAdditionalCompanies,
    showAdditionalCompanies
} from './actions';

function mapStateToProps(state) {
    const {
        additionalCompaniesByMonth,
        additionalCompaniesByMonth: { period }
    } = state.myhg.home;

    return {
        accountDetails: state.user.accountDetails.data,
        period,
        newCompanies: additionalCompaniesByMonth[period],
        additionalCompaniesByMonth
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class AdditionalCompaniesContainer extends React.Component {

    componentDidMount() {
        const { accountDetails, newCompanies, period, dispatch } = this.props;

        if (!newCompanies && UserUtils.isPaid(accountDetails)) {
            dispatch(loadAdditionalCompanies(period));
        }
    }

    onPeriodChange = (periodIndex) => {
        const { dispatch, additionalCompaniesByMonth, period } = this.props;
        const periodsToShow = PERIOD_SELECTION_OPTIONS.filter((option) => {
            return option !== period;
        });

        if (!additionalCompaniesByMonth[periodsToShow[periodIndex]]) {
            dispatch(loadAdditionalCompanies(periodsToShow[periodIndex]));
        } else {
            dispatch(showAdditionalCompanies(periodsToShow[periodIndex]));
        }
    }

    renderContent() {
        const { accountDetails, newCompanies } = this.props;
        if (UserUtils.isPaid(accountDetails)) {
            let content;

            if (!newCompanies || newCompanies.isFetching) {
                content = <Loader className="medium-loader" />;
            } else if (newCompanies.error) {
                content = <div>{ newCompanies.error.message }</div>;
            } else {
                content = (
                    <AdditionalCompanies
                        { ...this.props }
                        onPeriodChange={ this.onPeriodChange }
                    />
                );
            }

            return (
                <div className="additional-companies">
                    <div className="container">
                        { content }
                    </div>
                </div>
            );
        }

        return null;
    }

    render() {
        return this.renderContent();
    }
}
