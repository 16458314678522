import React from 'react';
import { connect } from 'react-redux';
import ExportTabs from './components/ExportTabs';
import Loader from 'shared/ui/loader/Loader';
import PlatformExportContainerWrapper from 'myhg/export/PlatformExportContainerWrapper';
import {
    loadPlatformCampaigns,
    saveCampaign,
    clearSaveCampaignError
} from 'myhg/export/actions';
import { MATCH_SOURCE_NAMES } from 'myhg/segment/constants';
import { EXPORT_CONFIGS } from './constants';
import segmentEnforcer from 'myhg/segment/segmentEnforcer';

function mapStateToProps(state) {
    return {
        platformExport: state.myhg.exports,
        currentSegment: state.myhg.segment.currentSegment
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@segmentEnforcer
@connect(mapStateToProps, mapDispatchToProps)

export default class ExportContainer extends React.Component {

    componentDidMount() {
        const { dispatch, currentSegment } = this.props;

        if (currentSegment.id) {
            dispatch(loadPlatformCampaigns(this.getPlatformBySegmentSource()));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSaveCampaignError());
    }

    onSaveExistingCampaignSubmit = (existingCampaign) => {
        const { dispatch } = this.props;
        const campaign = {
            campaignId: existingCampaign.id,
            campaignName: existingCampaign.text
        };
        dispatch(saveCampaign(campaign));
    }

    onNewCampaignSubmit = (campaign) => {
        const { dispatch } = this.props;

        // dispatch(sendSalesforceTelemetryData(EXPORT_TO_SALESFORCE, currentSegment.id));
        return dispatch(saveCampaign(campaign, true));
    }

    getPlatformBySegmentSource() {
        const { currentSegment } = this.props;
        const { data: { status: { source } } } = currentSegment;

        return MATCH_SOURCE_NAMES[source].toLowerCase();
    }

    render() {
        const { platformExport, currentSegment } = this.props;
        const { data: { status: { source } } } = currentSegment;
        const platform = this.getPlatformBySegmentSource();

        let content = null;
        if (!platformExport.existingCampaigns.data) {
            content = <Loader />;
        } else {
            content = (
                <ExportTabs
                    { ...this.props }
                    onNewCampaignSubmit={ this.onNewCampaignSubmit }
                    onSaveExistingCampaignSubmit={ this.onSaveExistingCampaignSubmit }
                />
            );
        }

        const customHeaderTitle = (
            <span>
                { EXPORT_CONFIGS[source].TITLE }
                <span className={ `platform-title-logo ${platform}-img` } />
            </span>
        );

        return (
            <div id="salesforce-export">
                <PlatformExportContainerWrapper
                    title={ EXPORT_CONFIGS[source].TITLE }
                    customHeader={ customHeaderTitle }
                >
                    { content }
                </PlatformExportContainerWrapper>
            </div>
        );
    }
}
