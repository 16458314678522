import { saveAs } from 'file-saver';
import ProductService from 'common/services/ProductService';
import {
    SAMPLE_COMPANIES_REQUEST,
    SAMPLE_COMPANIES_SUCCESS,
    SAMPLE_COMPANIES_FAILURE,
    PRODUCT_SAMPLE_COMPANIES_COUNT,
    DOWNLOAD_SAMPLE_REQUEST,
    DOWNLOAD_SAMPLE_SUCCESS,
    DOWNLOAD_SAMPLE_FAILURE
} from './constants';

export function loadSampleCompanies(productIdFunc) {
    let productId = productIdFunc;
    if (typeof productIdFunc === 'object') {
        productId = productIdFunc.productId;
    }

    const count = PRODUCT_SAMPLE_COMPANIES_COUNT;
    return (dispatch) => {
        dispatch({
            type: SAMPLE_COMPANIES_REQUEST,
            productId
        });

        // Dispatch vanilla actions asynchronously
        return ProductService.instance.getSampleCompaniesByProductId(productId, count, 'US')
            .then((response) => {
                dispatch({
                    type: SAMPLE_COMPANIES_SUCCESS,
                    data: response.data,
                    productId
                });
            })
            .catch((error) => {
                dispatch({
                    type: SAMPLE_COMPANIES_FAILURE,
                    error,
                    productId
                });
            });
    };
}

export function downloadSampleFile(technology) {
    return (dispatch) => {
        dispatch({
            type: DOWNLOAD_SAMPLE_REQUEST
        });

        // Dispatch vanilla actions asynchronously
        return ProductService.instance.downloadSample(technology.slug)
            .then((response) => {
                const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
                saveAs(blob, `${technology.product}-sample-data.csv`);
                dispatch({
                    type: DOWNLOAD_SAMPLE_SUCCESS
                });
            })
            .catch((error) => {
                dispatch({
                    type: DOWNLOAD_SAMPLE_FAILURE,
                    error
                });
            });
    };
}
