import React from 'react';
import root from 'window-or-global';
import classNames from 'classnames';

export default class GenericHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showBackground: false
        };

        this.handleWindowScrolling = this.handleScroll.bind(this);
    }

    componentDidMount() {
        root.addEventListener('scroll', this.handleWindowScrolling);
        if (root.scrollY >= 50) {
            window.setTimeout(() => {
                this.setState({
                    showBackground: true
                });
            }, 0);
        }
    }

    componentWillUnmount() {
        root.removeEventListener('scroll', this.handleWindowScrolling);
    }

    handleScroll(event) {
        const showBackground = event.currentTarget.scrollY >= 50;
        if (showBackground !== this.state.showBackground) {
            this.setState({ showBackground });
        }
    }

    render() {
        const className = classNames(this.props.className, 'discovery-generic-header-wrapper',
            { background: this.state.showBackground }
        );

        return (
            <div className={ className } >
                { this.props.children }
            </div>
        );
    }
}
