function filterItems(options, items, sortHandler) {
    let filteredItems;
    const { filterText, top } = options;

    if (!filterText) {
        filteredItems = items;
    } else {
        filteredItems = items.filter((item) => {
            return item.text.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
        }).sort(sortHandler);
    }

    return filteredItems.slice(0, top || 1000);
}

function getSortedFilteredItems(options, items) {
    const itemToSearch = options.filterText.toLowerCase();
    return filterItems(options, items, (left, right) => {
        const leftVal = left.text.toLowerCase().indexOf(itemToSearch);
        const rightVal = right.text.toLowerCase().indexOf(itemToSearch);
        return leftVal > rightVal ? 1 : -1;
    });
}

export function getFilteredTypeaheadData(options, searchDataItems) {
    const filteredSearchData = searchDataItems.map((searchItem) => {
        return {
            type: searchItem.type,
            items: getSortedFilteredItems(options, searchItem.items)
        };
    });
    return filteredSearchData;
}
