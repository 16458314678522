import React from 'react';
import ReactDOM from 'react-dom';
import UserUtils from 'utils/UserUtils';
import { NumeralUtils } from 'utils/Utils';
import StackedBarChart from 'shared/ui/charts/StackedBarChart';
import {
    TOTAL_COMPANIES_LABEL,
    FILTERED_COMPANIES_LABEL,
    NET_NEW_COMPANIES_LABEL,
    MATCHED_COMPANIES_LABEL,
    CHART_TRENDING_12_MONTHS,
    TREND_ICONS
} from '../constants';
import {
    DISPLAYED_SUMMARY_PRODUCTS_LIMIT
} from 'myhg/segment/constants';

export default class SummaryDetailsBarChart extends React.Component {

    constructor(props) {
        super(props);

        // check if trial period expired or not for non paid users
        const accountDetails = props.accountDetails;
        const isTrialExpired = UserUtils.isTrialExpired(accountDetails);
        this.chartData = this.initChartData(props.segmentStatus.source, isTrialExpired);
    }

    componentDidMount() {
        this.barChart = new StackedBarChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        this.barChart.update(this.getChartData());
    }

    componentWillUnmount() {
        this.barChart.destroy(this.getDOMNode());
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getChartState() {
        const totalTypes = {
            productCompanies: TOTAL_COMPANIES_LABEL,
            companies: FILTERED_COMPANIES_LABEL,
            netNew: NET_NEW_COMPANIES_LABEL,
            matched: MATCHED_COMPANIES_LABEL
        };

        return {
            data: this.getChartData(),
            dataX: 'product',
            dataY: 'count',
            vOffset: 55,
            tooltipData: d => {
                return `<span>${totalTypes[d.type]}</span><br />
                        <span class='high-light'>
                            ${NumeralUtils.formatIntegerNumber(d[`${d.type}Tip`])}
                        </span>`;
            }
        };
    }

    getChartData() {
        if (!this.props.source.length) {
            return this.chartData;
        }

        this.buildChartData(this.props.source);

        // insert dummy product data into the chart up to the max shown bars limit
        this.zeroPadChartData(DISPLAYED_SUMMARY_PRODUCTS_LIMIT);

        return this.chartData;
    }

    initChartData(source, isTrialExpired) {
        let chartData;

        if (source && !isTrialExpired) {
            chartData = [
                { type: 'matched', values: [] },
                { type: 'netNew', values: [] }
            ];
        } else {
            chartData = [
                { type: 'companies', values: [] },
                { type: 'productCompanies', values: [] }
            ];
        }

        return chartData;
    }

    buildChartData(source) {
        this.chartData.forEach(data => {
            source.forEach(totals => {
                const duplicate = data.values.find(value => {
                    return value.slug === totals.slug;
                });

                const count = totals[`${data.type}Diff`] !== undefined
                            ? totals[`${data.type}Diff`]
                            : totals[data.type] || 0;

                if (!!duplicate) {
                    duplicate.count = count;
                    return;
                }

                data.values.push({
                    count,
                    product: this.buildProductLabelText(totals),
                    slug: totals.slug,
                    [`${data.type}Tip`]: totals[data.type] || 0
                });
            });
        });
    }

    buildProductLabelText(totals) {
        if (totals.trend === undefined) {
            return totals.product;
        }

        let trendType = 'flat';
        let trend = totals.trend || 0;

        if (totals.trend > 0) {
            trendType = 'growth';
            trend = `+${trend}`;
        } else if (totals.trend < 0) {
            trendType = 'drop';
        }

        return [
            totals.product,
            `<tspan class="icon ${trendType}">${TREND_ICONS[trendType]}</tspan> ${trend}%`,
            CHART_TRENDING_12_MONTHS
        ];
    }

    zeroPadChartData(limit) {
        const existingProductsNumber = this.chartData[0].values.length;
        const interval = limit - existingProductsNumber;

        for (let i = 1; i <= interval; i++) {
            this.chartData.forEach(type => {
                type.values.push({
                    count: 0,
                    product: ' '.repeat(i)
                });
            });
        }
    }

    render() {
        return (
            <div className="stacked-bar-chart summary-details-chart"></div>
        );
    }
}
