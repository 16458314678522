import React from 'react';
import OAuthConnectActionHandler
    from 'shared/oAuth/OAuthConnectActionHandler';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';

export default class SalesforceConnectOptions extends React.Component {

    render() {
        return (
            <div className="saleforce-connect-actions">
                <FormButtonWrapper>
                    <input
                        type="button"
                        className="btn btn-primary"
                        onClick={ this.props.goToNextStep }
                        defaultValue="Continue With This Account"
                    />
                </FormButtonWrapper>
                <p>
                    or
                </p>
                <OAuthConnectActionHandler
                    state={ this.props.state }
                    platformName="salesforce"
                    switchAccount
                />
            </div>
        );
    }
}
