import React from 'react';
import PropTypes from 'prop-types';

const Status = {
    PENDING: 'pending',
    LOADING: 'loading',
    LOADED: 'loaded',
    FAILED: 'failed',
};

export default class ImageLoader extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        preloader: PropTypes.object,
        src: PropTypes.string,
        onLoad: PropTypes.func,
        onError: PropTypes.func,
        imgProps: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            status: props.src ? Status.LOADING : Status.PENDING
        };
    }

    componentDidMount() {
        if (this.state.status === Status.LOADING) {
            this.createLoader();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.src !== nextProps.src) {
            this.setState({
                status: nextProps.src ? Status.LOADING : Status.PENDING,
            });
        }
    }

    componentDidUpdate() {
        if (this.state.status === Status.LOADING && !this.img) {
            this.createLoader();
        }
    }

    componentWillUnmount() {
        this.destroyLoader();
    }

    getClassName() {
        let className = `imageloader ${this.state.status}`;
        if (this.props.className) {
            className = `${className} ${this.props.className}`;
        }
        return className;
    }

    createLoader() {
        this.destroyLoader();  // We can only have one loader at a time.

        this.img = new Image();
        this.img.onload = this.handleLoad;
        this.img.onerror = this.handleError;
        this.img.src = this.props.src;
    }

    destroyLoader() {
        if (this.img) {
            this.img.onload = null;
            this.img.onerror = null;
            this.img = null;
        }
    }

    handleLoad = (event) => {
        this.destroyLoader();
        this.setState({
            status: Status.LOADED
        });

        if (this.props.onLoad) this.props.onLoad(event);
    }

    handleError = (error) => {
        this.destroyLoader();
        this.setState({
            status: Status.FAILED
        });

        if (this.props.onError) {
            this.props.onError(error);
        }
    }

    renderImg() {
        const { src, imgProps } = this.props;
        const props = { src };

        for (const k in imgProps) {
            if (imgProps.hasOwnProperty(k)) {
                props[k] = imgProps[k];
            }
        }

        return <img {...props} />;
    }

    render() {
        const wrapperProps = {
            className: this.getClassName()
        };

        if (this.props.style) {
            wrapperProps.style = this.props.style;
        }

        let content = null;

        switch (this.state.status) {
            case Status.LOADED:
                content = this.renderImg();
                break;

            case Status.FAILED:
                if (this.props.children) {
                    content = this.props.children;
                }
                break;

            default:
                if (this.props.preloader) {
                    content = this.props.preloader;
                }
                break;
        }

        return (
            <span { ...wrapperProps }>
                { content }
            </span>
        );
    }
}
