import React from 'react';
import { connect } from 'react-redux';
import { loadIndustriesFirmos } from './actions';
import Loader from 'shared/ui/loader/Loader';
import IndustriesChart from './IndustriesChart';
import IndustriesNetNewChart from './IndustriesNetNewChart';
import UserUtils from 'utils/UserUtils';

function mapStateToProps(state) {
    const { segment } = state.myhg;
    return {
        currentSegment: segment.currentSegment,
        lastSegmentFiltersUpdate: segment.lastSegmentFiltersUpdate,
        industriesFirmographic: segment.firmographic.industriesFirmographic
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class IndustriesChartContainer extends React.Component {

    componentDidMount() {
        const { dispatch, industriesFirmographic: { data, isFetching } } = this.props;
        if (!data && !isFetching) {
            dispatch(loadIndustriesFirmos());
        }
    }

    componentDidUpdate() {
        const { dispatch, industriesFirmographic: {
            error, isFetching, data
        } } = this.props;

        if (!data && !isFetching && !error) {
            // reload industries firmo data on segment filters change
            dispatch(loadIndustriesFirmos());
        }
    }

    render() {
        const isTrialExpired = UserUtils.isTrialExpired(this.props.accountDetails);
        const { currentSegment: { data: { filters, status } } } = this.props;
        const { industriesFirmographic: {
            error, isFetching, data
        } } = this.props;
        let content = null;

        // errors
        if (error) {
            content = <div>{ error.message }</div>;
        // loader
        } else if (isFetching || !data) {
            content = <Loader />;
        // display chart
        } else {
            const Chart = !status.source || isTrialExpired ?
                IndustriesChart : IndustriesNetNewChart;
            content = (
                <Chart
                    data={ data }
                    segmentIndustries={ filters.industries }
                />
            );
        }

        return content;
    }
}
