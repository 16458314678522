import {
    LIGHTBOX_OPEN,
    LIGHTBOX_UPDATE,
    LIGHTBOX_CLOSE
} from './constants';

export function openLightbox(props) {
    return {
        type: LIGHTBOX_OPEN,
        props
    };
}

export function updateLightbox(props) {
    return {
        type: LIGHTBOX_UPDATE,
        props
    };
}

export function closeLightbox() {
    return {
        type: LIGHTBOX_CLOSE
    };
}
