export const FILTERS_SET_REVENUE_INTERVAL = 'FILTERS_SET_REVENUE_INTERVAL';
export const FILTERS_SET_EMPLOYEES_INTERVAL = 'FILTERS_SET_EMPLOYEES_INTERVAL';

export const FILTERS_ADD_LOCATION = 'FILTERS_ADD_LOCATION';
export const FILTERS_REMOVE_LOCATION = 'FILTERS_REMOVE_LOCATION';
export const FILTERS_SET_LOCATIONS = 'FILTERS_SET_LOCATIONS';
export const FILTERS_CLEAR_LOCATIONS = 'FILTERS_CLEAR_LOCATIONS';
export const FILTERS_LOCATION_TYPE_COUNTRY = 'countries';
export const FILTERS_LOCATION_TYPE_REGION = 'regions';

export const FILTERS_ADD_INDUSTRY = 'FILTERS_ADD_INDUSTRY';
export const FILTERS_REMOVE_INDUSTRY = 'FILTERS_REMOVE_INDUSTRY';
export const FILTERS_SET_INDUSTRIES = 'FILTERS_SET_INDUSTRIES';
export const FILTERS_TOP_INDUSTRIES = 'FILTERS_TOP_INDUSTRIES';
export const FILTERS_ALL_INDUSTRIES = 'FILTERS_ALL_INDUSTRIES';
export const SEGMENT_FILTERS_CLEAR = 'SEGMENT_FILTERS_CLEAR';
export const SEGMENT_FILTERS_CHANGED = 'SEGMENT_FILTERS_CHANGED';

export const FILLTERS_SET_ALL = 'FILLTERS_SET_ALL';

export const TOGGLE_FILTER_TEXT_OPEN = 'OPEN FILTERS';
export const TOGGLE_FILTER_TEXT_CLOSE = 'CLOSE FILTERS';
export const SEGMENT_FILTERS_EDIT_VIEW_CHANGE = 'SEGMENT_FILTERS_EDIT_VIEW_CHANGE';
export const SEGMENT_APPLY_FILTERS_SAVE_BUTTON_TEXT = 'Apply Filters and Save';
export const SEGMENT_APPLY_FILTERS_BUTTON_TEXT = 'Apply Filters';
export const SEGMENT_CREATE_BUTTON_TEXT = 'Create Target Segment';
export const SEGMENT_CLOSE_FILTER_BUTTON_TEXT = 'Close';

export const COMPARABLE_FILTERS = [
    'matchAllProducts',
    'cc',
    'employeesFrom',
    'employeesTo',
    'includes',
    'includeUnknownFirmos',
    'industries',
    'signalScores',
    'regions',
    'revenueFrom',
    'revenueTo',
    'slugs'
];

// Usage timeframe
export const SEGMENT_ADVANCED_FILTERS_SET_VISIBILITY = 'SEGMENT_ADVANCED_FILTERS_SET_VISIBILITY';
export const SEGMENT_ADVANCED_FILTERS_CLEAR = 'SEGMENT_ADVANCED_FILTERS_CLEAR';
export const USAGE_TIME_FRAME_SIGNAL_SCORE = 'USAGE_TIME_FRAME_SIGNAL_SCORE';
export const USAGE_TIME_FRAME_LAST_CHECK = 'USAGE_TIME_FRAME_LAST_CHECK';
export const ADVANCED_FILTERS_SET_ACTIVE_TIME_FRAME = 'ADVANCED_FILTERS_SET_ACTIVE_TIME_FRAME';
