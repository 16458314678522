import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import ModalWizardWrapper from 'shared/ui/modalWizard/ModalWizardWrapper';
import segmentEnforcer from 'myhg/segment/segmentEnforcer';

function mapStateToProps(state) {
    return {
        segmentId: state.myhg.segment.currentSegment.id
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@segmentEnforcer
@connect(mapStateToProps, mapDispatchToProps)

/**
 * Platform Page Wrapper
 */
export default class PlatformExportContainerWrapper extends React.Component {

    onBack = () => {
        browserHistory.goBack();
    }

    onClose = () => {
        const { segmentId } = this.props;
        browserHistory.push(`/dashboard/segment/view/${segmentId}`);
    }

    render() {
        return (
            <ModalWizardWrapper
                title={ this.props.title }
                onClose={ this.onClose }
                onBack={ this.onBack }
                customHeader={ this.props.customHeader }
            >
                { this.props.children }
            </ModalWizardWrapper>
        );
    }
}
