import React from 'react';
import { Route } from 'react-router';
import LogInContainer from './logIn/LogInContainer';
import SignupContainer from './signUp/SignUpContainer';
import ConfirmRegistrationContainer from './confirmRegistration/ConfirmRegistrationContainer';
import ResetPasswordContainer from './resetPassword/ResetPasswordContainer';
import SecureRouteHandler from 'shared/secureRouteHandler/SecureRouteHandler';
import AuthModalWrapperContainer from './AuthModalWrapperContainer';
import AuthPageWrapper from './AuthPageWrapper';

export const authenticationModalRoutes = (
    <Route component={ SecureRouteHandler } state={ { unauthenticationRequired: true } }>
        <Route component={ AuthModalWrapperContainer }>
            <Route component={ AuthPageWrapper }>
                <Route path="login" component={ LogInContainer } />
                <Route path="reset-password/:confirmToken" component={ ResetPasswordContainer } />
            </Route>
            <Route
                path="verification/:signupDetails/:confirmToken"
                component={ ConfirmRegistrationContainer }
            />
            <Route path="register(/:landingPage)" component={ SignupContainer } />
        </Route>
    </Route>
);
