import React from 'react';
import { Link } from 'react-router';
import Loader from 'shared/ui/loader/Loader';
import ModalWizardWrapper from 'shared/ui/modalWizard/ModalWizardWrapper';
import { HTTP_CONFLICT_CODE } from 'shared/constants';
import {
    CONFIRM_SIGN_UP_BUTTON_ALREADY_VERIFIED,
    CONFIRM_SIGN_UP_BUTTON_ERROR,
    CONFIRM_SIGN_UP_INVALID_LINK,
    CONFIRM_SIGN_UP_ALREADY_VERIFIED_TEXT
} from './constants';
import CampaignConfirmSignUpFlowContainer
    from './components/CampaignConfirmSignUpFlowContainer';
import GenericConfirmSignUpFlow from './components/GenericConfirmSignUpFlow';

export default class ConfirmRegistration extends React.Component {

    getSuccessContent() {
        const { signupDetails } = this.props.params;
        const decodedSignupData = JSON.parse(atob(signupDetails));
        let content = null;

        if (decodedSignupData.landingPage) {
            content = (
                <CampaignConfirmSignUpFlowContainer
                    { ...this.props }
                    segment={ decodedSignupData.segment }
                    landingPage={ decodedSignupData.landingPage }
                    referer={ decodedSignupData.referer }
                />
            );
        } else {
            content = this.getClassicModal(
                <GenericConfirmSignUpFlow
                    { ...this.props }
                    landingPage={ decodedSignupData.landingPage }
                    referer={ decodedSignupData.referer }
                />
            );
        }

        return content;
    }

    getAccountAlreadyVerifiedContent() {
        return (
            <div>
                <p className="text-content title">
                    { CONFIRM_SIGN_UP_ALREADY_VERIFIED_TEXT }
                </p>
                <div className="confirmation-email" />
                <p className="text-content light-title" />
                <Link
                    id="confirmation-signin"
                    className="btn btn-primary"
                    to="/login"
                >
                    { CONFIRM_SIGN_UP_BUTTON_ALREADY_VERIFIED }
                </Link>
            </div>
        );
    }

    getConfirmRegistrationErrorMessage(error) {
        let message;
        if (error.status === HTTP_CONFLICT_CODE) {
            message = this.getAccountAlreadyVerifiedContent();
        } else {
            message = (
                <div>
                    <p className="text-content large error">
                        { error.message }
                    </p>
                    <div className="confirmation-email error" />
                    <p className="text-content light-title" />
                    <Link
                        id="confirmation-signup"
                        className="btn btn-primary"
                        to="/register"
                    >
                        { CONFIRM_SIGN_UP_BUTTON_ERROR }
                    </Link>
                </div>
            );
        }
        return message;
    }

    getInvalidLinkErrorMessage() {
        return (
            <p className="text-content large error">
                { CONFIRM_SIGN_UP_INVALID_LINK }
            </p>
        );
    }

    getClassicModal(content) {
        return (
            <ModalWizardWrapper
                className="modal-form"
                onClose={ this.props.onClose }
                onBack={ this.props.onBack }
            >
                { content }
            </ModalWizardWrapper>
        );
    }

    render() {
        let content = null;
        const error = this.props.confirmRegistration.error;

        if (this.props.confirmRegistration.submitting) {
            content = this.getClassicModal(<Loader />);
        } else if (error) {
            content = this.getClassicModal(this.getConfirmRegistrationErrorMessage(error));
        } else if (!this.props.confirmRegistration.isValidLink) {
            content = this.getClassicModal(this.getInvalidLinkErrorMessage());
        } else if (this.props.confirmRegistration.authData) {
            content = this.getSuccessContent();
        }

        return content;
    }
}
