import React from 'react';
import { Link } from 'react-router';
import Loader from 'shared/ui/loader/Loader';
import {
    SEGMENT_MATCHING_SERVICES,
    MATCH_SOURCE_PLATFORM
} from 'myhg/segment/constants';
import { PLATFORM_SOURCE_NAME } from 'shared/constants';

export default class SegmentPlatformStatus extends React.Component {

    getMatchingService() {
        const { segmentSource } = this.props;
        const matchingService = SEGMENT_MATCHING_SERVICES.find(item => {
            return MATCH_SOURCE_PLATFORM[item.type] === segmentSource;
        });

        return matchingService;
    }

    getOtherPlatformAccountSegmentStatus() {
        const { segmentAccount, currentAccount } = this.props;
        const platformName = PLATFORM_SOURCE_NAME[this.getMatchingService().type];

        return (
            <div>
                <p className="error">
                    This segment was previously matched
                    using { platformName } account <strong>{ segmentAccount } </strong>.
                    The segment results are disabled for the current { platformName } account
                    you are using, <strong>{ currentAccount } </strong>.
                    <br />
                    To match against you new account. please duplicate this segment and try again.
                </p>
            </div>
        );
    }

    getUnauthenticatedUserStatus() {
        const matchingService = this.getMatchingService();
        const platformName = PLATFORM_SOURCE_NAME[matchingService.type];

        return (
            <div>
                <p className="error">
                    This segment is tied to a <strong>{ platformName }</strong> account
                    . Please authenticate first in order to get segment data.
                </p>
                <br />
                <Link
                    id="platform-connect"
                    className="default-link"
                    to={ matchingService.url }
                >
                    <strong>Connect</strong>
                </Link>
            </div>
        );
    }

    render() {
        const { platformTokenStatus, isPlatformTypeAndNotConnected } = this.props;
        let content;

        if (!platformTokenStatus) {
            content = <Loader />;
        } else if (isPlatformTypeAndNotConnected) {
            content = this.getUnauthenticatedUserStatus();
        } else {
            content = this.getOtherPlatformAccountSegmentStatus();
        }

        return (
            <div className="segment-platform-status container">
                { content }
            </div>
        );
    }
}
