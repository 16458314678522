import d3 from 'd3';
import d3TipModule from 'd3-tip';
import Guid from 'guid';
import {
    VERTICAL_BARCHART_TICK_SIZE,
    VERTICAL_BARCHART_BAR_ANIMATION_MS,
    STACKED_BARCHART_RANGEBANDS_PADDING,
    STACKED_BARCHART_HORIZONTAL_OFFSET,
    STACKED_BARCHART_VERTICAL_OFFSET,
    STACKED_BARCHART_AXIS_ANIMATION_MS,
    STACKED_BARCHART_X_LABEL_LINE_HEIGHT
} from './constants';

export default class StackedBarChart {

    constructor(el, props = {}) {
        this.el = el;
        this.props = props;
        this.dataX = this.props.dataX || 'x';
        this.dataY = this.props.dataY || 'y';
        this.tooltipId = `tooltip-${Guid.raw()}`;
        this.vOffset = this.props.vOffset || STACKED_BARCHART_VERTICAL_OFFSET;

        this.initChart();
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (this.svg) {
            this.svg.remove();
        }

        this.initChart();
    }

    initChart() {
        const chartContainerWidth = this.el.offsetWidth;
        const chartContainerHeight = this.el.offsetHeight;

        this.width = chartContainerWidth - STACKED_BARCHART_HORIZONTAL_OFFSET;
        this.height = chartContainerHeight - this.vOffset;

        this.stack = d3.layout.stack()
            .values(d => { return d.values; })
            .x(d => { return d[this.dataX]; })
            .y(d => { return d[this.dataY]; });

        this.y = d3.scale.linear()
            .range([this.height, 0]);

        this.x = d3.scale.ordinal()
            .rangeRoundBands([0, this.width], STACKED_BARCHART_RANGEBANDS_PADDING);

        this.yAxis = d3.svg.axis()
            .scale(this.y)
            .ticks(6)
            .innerTickSize(-this.width)
            .outerTickSize(VERTICAL_BARCHART_TICK_SIZE)
            .orient('left')
            .tickFormat(d3.format('s'));

        this.xAxis = d3.svg.axis()
            .scale(this.x)
            .outerTickSize(VERTICAL_BARCHART_TICK_SIZE)
            .orient('bottom');

        this.svg = d3.select(this.el)
            .append('svg')
                .attr('width', chartContainerWidth)
                .attr('height', chartContainerHeight);

        this.chart = this.svg
            .append('g')
                .attr('transform', `translate(${STACKED_BARCHART_HORIZONTAL_OFFSET - 1})`);

        this.chart.append('g')
            .attr('class', 'y axis');

        this.chart.append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(0, ${this.height})`);

        this.update(this.props.data);
    }

    update(data) {
        this.props.data = data;
        const dataset = this.props.data;
        const stacked = this.stack(dataset);

        // Note: X labels can be strings or arrays of strings
        const xDomain = dataset.length && dataset[0].values
            ? dataset[0].values.map(d => { return d[this.dataX]; })
            : [];

        const maxY = d3.max(stacked, d => {
            return d3.max(d.values, v => { return v.y0 + v.y; });
        });

        this.y.domain([0, maxY]);
        this.x.domain(xDomain);

        this.chart.select('.y.axis')
            .transition()
            .duration(STACKED_BARCHART_AXIS_ANIMATION_MS)
            .call(this.yAxis);

        this.chart.select('.x.axis')
            .transition()
            .duration(STACKED_BARCHART_AXIS_ANIMATION_MS)
            .call(this.xAxis)
            .selectAll('.tick text')
                .call(this.buildXaxisLabels);

        if (this.props.tooltipData) {
            this.removeTooltip();

            this.tip = d3TipModule()
                .attr('id', this.tooltipId)
                .attr('class', 'd3-tip')
                .html(this.props.tooltipData)
                .offset([-12, 0]);

            this.svg.call(this.tip);
        }

        const layers = this.chart.selectAll('g.layer')
            .data(stacked, d => { return d.type; });

        layers.enter()
            .append('g')
                .attr('class', d => { return `layer ${d.type}`; });

        const rects = layers.selectAll('rect')
            .data(d => {
                return d.values.map(v => {
                    return Object.assign(v, { type: d.type });
                });
            });

        rects.exit().remove();

        rects.enter()
            .append('rect')
                .attr('x', d => { return this.x(d[this.dataX]); })
                .attr('width', this.x.rangeBand())
                .attr('y', this.height)
                .attr('height', 0)
                .on('mouseover', d => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip animate').show(d);
                    }
                })
                .on('click', d => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip animate').show(d);
                    }
                })
                .on('mouseout', d => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip').show(d);
                        this.tip.hide();
                    }
                });

        rects
            .transition()
                .duration(STACKED_BARCHART_AXIS_ANIMATION_MS)
                .attr('x', d => { return this.x(d[this.dataX]); })
                .attr('width', this.x.rangeBand())
            .transition()
                .duration(VERTICAL_BARCHART_BAR_ANIMATION_MS)
                .ease('sin-in-out')
                .attr('y', d => { return this.y(d.y0 + d.y); })
                .attr('height', d => { return this.height - this.y(d.y); });
    }

    removeTooltip() {
        d3.select(`#${this.tooltipId}`).remove();
    }

    buildXaxisLabels(texts) {
        if (!texts || !texts[0]) {
            return;
        }

        texts[0].forEach(txt => {
            const text = d3.select(txt);
            const lines = text.text().split(',');

            const y = text.attr('y');
            const dy = parseFloat(text.attr('dy'));

            text.text(null);

            // if X labels are arrays of strings, elements are put on new rows
            lines.forEach((line, i) => {
                text.append('tspan')
                        .attr('x', 0)
                        .attr('y', y)
                        .attr('dy', `${dy + i * STACKED_BARCHART_X_LABEL_LINE_HEIGHT}em`)
                        .html(line);
            });
        });
    }

    destroy() {
        this.removeTooltip();
        window.removeEventListener('resize', this.handleResize);
    }
}
