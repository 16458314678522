import React from 'react';

export default class TypeaheadSelectedItem extends React.Component {

    onRemove(item) {
        this.props.onRemove(item);
    }

    render() {
        const { divClassName } = this.props;

        return (
            <div className={ divClassName || 'selected-item' }>
                <span className="item-name">
                    { this.props.item.text }
                </span>
                <span
                    onClick={ this.onRemove.bind(this, this.props.item) }
                    className="item-close icon-close-x"
                />
            </div>
        );
    }
}
