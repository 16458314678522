import React from 'react';
import classNames from 'classnames';

export default class NotificationBar extends React.Component {

    render() {
        const { notification } = this.props;
        const className = classNames('notification-bar', this.props.className);

        return (
            <div id={ this.props.id } className={ className }>
                <div className="container">
                    <div className="notification">
                        <div className="notification-title">
                            { notification.title }
                        </div>
                        <span className="notification-description">
                            { notification.description }
                        </span>
                    </div>
                    <div className="notification-action">
                        <span
                            onClick={ this.props.onClose }
                            className="close icon-close-x"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
