import React from 'react';
import sprintf from 'sprintf-js';
import { connect } from 'react-redux';
import Collections from './Collections';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import {
    COLLECTION_PANEL_TITLE
} from './constants';

function mapStateToProps(state) {
    return {
        collections: state.product.collections,
        productDetails: state.product.productDetails,
        firstTimeActions: state.firstTimeActions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

/**
 * Component container that fetches similar products data in the same category
 */
export default class CollectionsContainer extends React.Component {

    getTitle() {
        const { product } = this.props.productDetails.data;
        const panelTitle = sprintf.sprintf(
            COLLECTION_PANEL_TITLE,
            product || ''
        );

        return (
            <div className="collections-title">
                { panelTitle }
            </div>
        );
    }

    render() {
        return (
            <PanelWrapper
                className="collections-panel transparent"
                title={ this.getTitle() }
            >
                 <Collections { ...this.props } />
            </PanelWrapper>
        );
    }
}
