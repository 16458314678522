import React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

export const TechnologyIcon = (props) => {
    const { technology: { vendorUrl, product, name, collectionType } } = props;
    const iconName = vendorUrl || 'default_favicon';

    const iconClassName = classNames('fav-icon', {
        [`icon-${iconName}`]: true
    });
    const showTooltip = props.showTooltip;

    let tooltipContent;
    if (showTooltip) {
        tooltipContent = (
            <ReactTooltip
                effect="solid"
                place="top"
                id="technology-tooltip"
            />
        );
    }

    return (
        <span className="icon-circle favicon-wrapper">
            { tooltipContent }
            <span
                className={ collectionType === 2 || collectionType === 3 ?
                    'icon-category' : iconClassName }
                data-tip={ showTooltip ? product || name : null }
                data-for={ showTooltip ? 'technology-tooltip' : null }
            />
        </span>
    );
};
