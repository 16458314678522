import React from 'react';
import classNames from 'classnames';

export default class Checkbox extends React.Component {

    toggle() {
        if (this.props.onChange) {
            this.props.onChange(!this.props.value);
        }
    }

    render() {
        const checkClass = this.props.value ? 'checked' : '';
        const className = classNames('custom-checkbox', checkClass, this.props.className);
        let labelContent = null;
        if (this.props.label) {
            labelContent = this.props.label;
        }
        return (
            <div
                className="checkbox-container"
            >
                <div
                    className={ className }
                    onClick={ this.toggle.bind(this) }
                    data-qa="checkbox-custom"
                >
                    <span className="icon-check-mark"></span>
                </div>
                { labelContent }
            </div>
        );
    }
}
