import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import sprintf from 'sprintf-js';
import ValidationField from 'shared/ui/formFields/ValidationField';
import Loader from 'shared/ui/loader/Loader';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import { MATCH_SOURCE_NAMES } from 'myhg/segment/constants';
import { EXPORT_SUB_NOTE } from '../constants';

export const fields = ['campaignName'];

const validate = values => {
    const errors = {};

    if (!values.campaignName) {
        errors.campaignName = 'Required';
    }

    return errors;
};

class NewCampaignForm extends React.Component {

    getError(error) {
        if (!error) {
            return null;
        }

        return (
            <p className="save-error note-message">
                Error: { error.message }
            </p>
        );
    }

    getPlatformName() {
        const { currentSegment } = this.props;
        const { data: { status: { source } } } = currentSegment;

        return MATCH_SOURCE_NAMES[source];
    }

    getActionButton() {
        const { submitting } = this.props;

        const actionButton = (
            <button
                id="save-campaign"
                type="submit"
                className="btn btn-primary"
                disabled={ submitting }
                onClick={ this.trackSave.bind(this) }
                data-qa="save-campaign"
            >
                Save to { this.getPlatformName() }
                { submitting ? <Loader className="small-loader white" /> : '' }
            </button>
        );

        return actionButton;
    }

    getSavingPleaseWait() {
        const { submitting } = this.props;
        let content = null;

        if (submitting) {
            content = (
                <p>
                    Exporting to { this.getPlatformName() }...please wait.<br />
                    (Note: This may take a few moments)
                </p>
            );
        }

        return content;
    }

    trackSave() {
        const { submitting } = this.props;
        if (!submitting) {
            track(ACTION.CREATE_CAMPAIGN_SAVE, {
                label: 'New Campaign'
            });
        }
    }

    render() {
        const { fields: { campaignName }, handleSubmit } = this.props;
        const { error } = this.props.platformExport.saveCampaign;

        return (
            <form
                id="new-campaign"
                onSubmit={ handleSubmit(this.props.onNewCampaignSubmit) }
            >
                <ValidationField
                    type="text"
                    placeholder="Enter the Name of Your New Campaign"
                    fieldType="input"
                    { ...campaignName }
                    data-qa="input-campaign"
                />
                <span className="note">
                    { sprintf.sprintf(EXPORT_SUB_NOTE, this.getPlatformName()) }
                </span>
                { this.getError(error) }
                <div className="action-content">
                    { this.getActionButton() }
                </div>
                { this.getSavingPleaseWait() }
            </form>
        );
    }
}

NewCampaignForm.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'newCampaignForm',
    fields,
    validate
})(NewCampaignForm);
