import React from 'react';
import SalesforceConnectWrapper from './SalesforceConnectWrapper';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import OAuthConnectActionHandler
    from 'shared/oAuth/OAuthConnectActionHandler';

export default class SalesforceConnect extends React.Component {

    handleSubmit() {
        track(ACTION.CREATE_CAMPAIGN_AUTHORIZE);
    }

    render() {
        return (
            <SalesforceConnectWrapper>
                <OAuthConnectActionHandler
                    state={ this.props.state }
                    onSubmit={ this.handleSubmit }
                    platformName="salesforce"
                />
            </SalesforceConnectWrapper>
        );
    }
}
