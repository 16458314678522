import React from 'react';
import classNames from 'classnames';
import { NumeralUtils } from 'utils/Utils';
import UserUtils from 'utils/UserUtils';
import {
    SEGMENT_TABLE_RECORDS_PER_PAGE,
    NON_PAID_NUMBER_OF_RECORDS
} from '../constants';

export default class SegmentPagination extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            page: this.props.currentPage
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentPage !== this.state.page) {
            this.setState({
                page: nextProps.currentPage
            });
        }
    }

    onPreviousClick = () => {
        const pageNumber = this.state.page - 1 >= 1 ? this.state.page - 1 : 1;
        this.setPageNumber(pageNumber);
    }

    onNextClick = () => {
        const { accountDetails, slugsLength } = this.props;
        const isPaidUser = UserUtils.isPaid(accountDetails);

        if (isPaidUser ||
            (!isPaidUser && this.state.page <=
            Math.floor((slugsLength * 25) / SEGMENT_TABLE_RECORDS_PER_PAGE))) {
            const pageNumber = this.state.page + 1;
            this.setPageNumber(pageNumber);
        }
    }

    setPageNumber(pageNumber) {
        this.props.onClick(pageNumber);
    }

    rightArrow() {
        const { accountDetails, tableCountRecords } = this.props;
        const isPaidUser = UserUtils.isPaid(accountDetails);
        const isPaidAndDownloadDisabled = UserUtils.isPaidAndDownloadDisabled(accountDetails);
        const perPage = isPaidUser && !isPaidAndDownloadDisabled ?
            SEGMENT_TABLE_RECORDS_PER_PAGE : NON_PAID_NUMBER_OF_RECORDS;
        const to = this.state.page * perPage;
        // if the user is not authenticated or if the user is not paid and
        // the page reach the limit the right arrow should be grayed out
        const inactiveArrow = !isPaidUser || isPaidAndDownloadDisabled;
        if (inactiveArrow) {
            return null;
        }

        const rightArrowClassNames = classNames(
            'nav-arrows icon-small-arrow-right', {
                hide: to >= (tableCountRecords || 0),
                'inactive-arrow': inactiveArrow
            }
        );
        return (
            <span
                className={ rightArrowClassNames }
                onClick={ inactiveArrow ? null : this.onNextClick }
            />
        );
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    // TO DO: We should rewrite this method, right now is hard to read and to understand
    generatePaginationElements() {
        const { accountDetails, tableCountRecords } = this.props;
        if (!tableCountRecords) {
            return null;
        }
        const isPaidUser = UserUtils.isPaid(accountDetails);
        const isPaidAndDownloadDisabled = UserUtils.isPaidAndDownloadDisabled(accountDetails);
        const totalCount = NumeralUtils.formatIntegerNumber(tableCountRecords, false);
        const rowText = tableCountRecords === 1 ? 'row' : 'rows';
        const perPage = isPaidUser && !isPaidAndDownloadDisabled ?
            SEGMENT_TABLE_RECORDS_PER_PAGE : NON_PAID_NUMBER_OF_RECORDS;
        const from = ((this.state.page - 1) * perPage) + 1;

        let to = this.state.page * perPage;
        to = to >= tableCountRecords ? tableCountRecords : to;

        const leftArrowClassNames = classNames(
            'nav-arrows icon-small-arrow-left', {
                hide: this.state.page === 1
            });

        return (
            <div>
                <span
                    className={ leftArrowClassNames }
                    onClick={ this.onPreviousClick }
                />
                <span className="text">
                    { from } - { to } of { totalCount } { rowText }
                </span>
                { this.rightArrow() }
            </div>
        );
    }

    render() {
        return (
            <div className="col-12 col-lg-4 table-pagination">
                { this.generatePaginationElements() }
            </div>
        );
    }
}
