export const MARKETO_AUTHORIZE = 'MARKETO_AUTHORIZE';
export const MARKETO_CONNECT_REQUEST = 'MARKETO_CONNECT_REQUEST';
export const MARKETO_CONNECT_SUCCESS = 'MARKETO_CONNECT_SUCCESS';
export const MARKETO_CONNECT_ERROR = 'MARKETO_CONNECT_ERROR';

export const MARKETO_CONNECT_PAGE_TITLE = 'Connect to Marketo';
export const MARKETO_CONNECT_PAGE_SUBTITLE =
    'Don\'t know where to find these details?';
export const MARKETO_API_URLS_DOC =
    'https://hgdata.zendesk.com/hc/en-us/articles/360026780892-Start-Here';

export const MARKETO_ALREADY_CONNECT_PAGE_TITLE =
    'Marketo Account Connected';
export const MARKETO_ALREADY_CONNECT_PAGE_DESCRIPTION =
    'You are already connected with a Marketo account:';
export const MARKETO_CONNECT_NOTE =
    'Note: HG Insights does not use or retain this data in any way.';
export const MARKETO_MATCH_FIRST_TITLE =
    'Match Your Segment to Marketo First';
export const MARKETO_MATCH_FIRST_TEXT =
    `In order to create a Marketo campaign with this segment's data, \n
    please match your leads from Marketo.`;
export const MARKETO_MATCH_FIRST_BUTTON =
    'Match Segment to Marketo';
