import { PLATFORM_SOURCE } from 'shared/constants';

export const AUTH_DATA = 'AUTH_DATA';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_REASON_USER_REQUEST = 'LOG_OUT_REASON_USER_REQUEST';
export const LOG_OUT_REASON_FORBIDDEN = 'LOG_OUT_REASON_FORBIDDEN';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const REFERER_PATH_SET = 'REFERER_PATH_SET';

export const LANDING_PAGE_TYPES = {
    NONE: '',
    LP_SALESFORCE: PLATFORM_SOURCE.SALESFORCE,
    LP_PARDOT: PLATFORM_SOURCE.PARDOT,
    LP_MARKETO: PLATFORM_SOURCE.MARKETO,
    LP_FILE: PLATFORM_SOURCE.FILE,
    LP_ELOQUA: PLATFORM_SOURCE.ELOQUA,
    LP_LINKEDIN: PLATFORM_SOURCE.LINKEDIN
};
