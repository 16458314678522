import { combineReducers } from 'redux';
import marketoConnect from './marketo/reducer';
import linkedInConnect from './linkedin/reducer';
import {
    VALIDATE_PLATFORM_TOKEN_REQUEST,
    VALIDATE_PLATFORM_TOKEN_SUCCESS,
    VALIDATE_PLATFORM_TOKEN_FAILURE,
    VALIDATE_PLATFORM_TOKEN_RESET
} from './constants';
import { MARKETO_CONNECT_SUCCESS } from 'myhg/platformConnection/marketo/constants';
import { OAUTH_AUTHORIZE_SUCCESS } from 'shared/oAuth/constants';

export const platformTokenInitialState = {
    data: null,
    error: null,
    isFetching: false
};

export function platformToken(state = platformTokenInitialState, action) {
    switch (action.type) {
        case VALIDATE_PLATFORM_TOKEN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case VALIDATE_PLATFORM_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null
            });

        case OAUTH_AUTHORIZE_SUCCESS:
        case MARKETO_CONNECT_SUCCESS:
            // on platforms authorization complete set flag to true
            return Object.assign({}, state, {
                data: { valid: true }
            });

        case VALIDATE_PLATFORM_TOKEN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        case VALIDATE_PLATFORM_TOKEN_RESET:
            return platformTokenInitialState;

        default:
            return state;
    }
}

export default combineReducers({
    marketoConnect,
    linkedInConnect,
    platformToken
});
