import React from 'react';
import {
    SEARCH_LIST_LIMIT
} from '../constants';

export default class SearchListContainer extends React.Component {

    getListName() {
        const { listName } = this.props;
        if (!listName) {
            return null;
        }

        return (
            <span className="search-list-title">
                { listName }
            </span>
        );
    }

    getMoreMatches() {
        let content = null;
        const { items } = this.props;
        const hidden = items ? items.matched - SEARCH_LIST_LIMIT : 0;

        if (hidden > 0) {
            content = (
                <span className="search-list-more">
                    ... and { hidden } more
                </span>
            );
        }

        return content;
    }

    render() {
        return (
            <div
                id={ this.props.containerId }
                className="search-list-container"
            >
                { this.getListName() }
                <ul className="search-list-results">
                    { this.props.children }
                </ul>
                { this.getMoreMatches() }
            </div>
        );
    }
}
