import React from 'react';
import { Route } from 'react-router';
import Root from './root/RootContainer';
// Home
import HomeContainer from 'discovery/home/HomeContainer';
// Product
import ProductContainer from 'discovery/product/ProductContainer';
import {
    authenticationModalRoutes
} from 'discovery/authenticationModal/authenticationModalRoutes';
// oauth callbacks
import OAuthCallbackContainer from
    'shared/oAuth/OAuthCallbackContainer';
import {
    HTTP_NOTFOUND_CODE
} from 'shared/constants';
import CollectionContainer from 'discovery/collection/CollectionContainer';
import ProductSegment from 'discovery/segment/ProductSegment';
import ShareSegmentContainer from 'myhg/segment/segmentShare/ShareSegmentContainer';
// MyHG
import { myHgRoutesRoutes } from 'myhg/myhgRoutes';

export default function routes() {
    // track url changes as segment.io page events
    function trackPage() {
        if (typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
            window.analytics.page();
        }
    }

    return (
        <Route name="app" component= { Root } onChange={ trackPage } onEnter={ trackPage }>
            <Route path="/" component={ HomeContainer } />
            <Route path="product/:productId" component={ ProductContainer } />
            <Route
                path="collection/:collectionId"
                component={ CollectionContainer }
            />
            <Route path="salesforce/oauth/callback" component={ OAuthCallbackContainer } />
            <Route path="cloud/eloqua/oauth/callback" component={ OAuthCallbackContainer } />
            <Route path="segment" component={ ProductSegment } />
            <Route path="segment/share" component={ ShareSegmentContainer } />
            <Route path="segment/:sharedSegmentId" component={ ProductSegment } />
            { authenticationModalRoutes }
            { myHgRoutesRoutes }
            <Route path="*" status={ HTTP_NOTFOUND_CODE } components={ HomeContainer } />
        </Route>
    );
}
