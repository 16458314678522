import React from 'react';
import { connect } from 'react-redux';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import ChartRevenueContainer from './revenueChart/RevenueChartContainer';
import EmployeesChartContainer from './employeesChart/EmployeesChartContainer';
import IndustriesChartContainer from './industriesChart/IndustriesChartContainer';
import LocationsChartContainer from './locationsChart/LocationsChartContainer';
import TabMenu from 'shared/ui/tabs/TabMenu';
import {
    FIRMOGRAPHIC_PROFILE_COMPONENT_TITLE,
    TAB_MENU,
    FIRMOGRAPHIC_LOCATIONS,
    FIRMOGRAPHIC_EMPLOYEES,
    FIRMOGRAPHIC_REVENUE,
    FIRMOGRAPHIC_INDUSTRY
} from './constants';

function mapStateToProps(state) {
    return {
        accountDetails: state.user.accountDetails.data
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class FirmographicProfileContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: TAB_MENU[0]
        };
    }

    getTabContent() {
        let tabComponent;
        switch (this.state.selectedMenu.name) {
            case FIRMOGRAPHIC_LOCATIONS:
                tabComponent = <LocationsChartContainer data={ [] } { ...this.props } />;
                break;

            case FIRMOGRAPHIC_EMPLOYEES:
                tabComponent = <EmployeesChartContainer { ...this.props } />;
                break;

            case FIRMOGRAPHIC_REVENUE:
                tabComponent = <ChartRevenueContainer { ...this.props } />;
                break;

            case FIRMOGRAPHIC_INDUSTRY:
                tabComponent = <IndustriesChartContainer { ...this.props } />;
                break;

            default:
                tabComponent = null;
        }

        return tabComponent;
    }

    menuItemChanged = (menuItem) => {
        this.setState({
            selectedMenu: menuItem
        });
    }

    render() {
        return (
            <PanelWrapper
                title={ FIRMOGRAPHIC_PROFILE_COMPONENT_TITLE }
                className="segment-firmographic-profile"
            >
                <TabMenu
                    menus={ TAB_MENU }
                    selectedMenu={ this.state.selectedMenu }
                    menuItemChanged={ this.menuItemChanged }
                />
                { this.getTabContent() }
            </PanelWrapper>
        );
    }
}
