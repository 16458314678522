import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import SegmentTopSection from './components/SegmentTopSection';
import SegmentDataMatchTiles from './components/SegmentDataMatchTiles';
import SegmentFiltersContainer from './segmentFilters/SegmentFiltersContainer';
import SegmentContentContainer from './segmentMenuActions/SegmentContentContainer';
import { loadPermissions } from 'shared/user/permissions/actions';
import { cleanFirmographicsData } from 'myhg/segment/firmographicProfile/actions';
import { loadAccountDetails } from 'shared/user/accountDetails/actions';
import SegmentCrmStatusContainer from 'myhg/segment/segmentCrmStatus/SegmentCrmStatusContainer';
import UserUtils from 'utils/UserUtils';
import Loader from 'shared/ui/loader/Loader';
import {
    segmentNameChanged,
    loadSegment,
    cancelUnresolvedRelatedSegmentRequests,
    applyFilters,
    loadSegmentCounts,
    loadRelatedSegmentData,
    loadMiscSegmentData
} from './actions';
import {
    loadProductsSummary
} from './segmentMatches/actions';
import {
    getSegmentShare
} from './segmentShare/actions';
import {
    SEGMENT_STATUS,
    MATCH_SOURCE_TYPE,
    MATCH_SOURCE_PLATFORM,
    SEGMENT_FILTERS_EDIT_VIEW_CHANGE
} from './constants';
import { HTTP_NOTFOUND_CODE } from 'shared/constants';
import {
    setFiltersBySegment,
    showEditFilterView
} from './segmentFilters/actions';
import NotificationContainer from '../../shared/notification/NotificationContainer';

function isPlatformTypeAndNotConnectedCheck(currentSegment, tokenStatus, authData) {
    const { source: segmentSource } = currentSegment.data.status;
    const { platformSource: userPlatformSource } = authData;
    const isPlatformSegmentType = segmentSource && segmentSource !== MATCH_SOURCE_TYPE.FILE;
    const isPlatformUserAuthenticated =
        MATCH_SOURCE_PLATFORM[userPlatformSource] === segmentSource &&
        tokenStatus && tokenStatus.valid;

    return isPlatformSegmentType && !isPlatformUserAuthenticated;
}

function isPlatformTypeAndOtherAccountCheck(currentSegment, authData) {
    const { source: segmentSource, sourceValue: segmentAccountName } = currentSegment.data.status;
    const { platformSource: userPlatformSource, salesforceUsername } = authData;
    const isPlatformSegmentType = segmentSource && segmentSource !== MATCH_SOURCE_TYPE.FILE;
    const isOtherPlatformUser =
        MATCH_SOURCE_PLATFORM[userPlatformSource] === segmentSource &&
        salesforceUsername !== segmentAccountName;

    return isPlatformSegmentType && isOtherPlatformUser;
}

function mapStateToProps(state) {
    const platformTokenStatus = state.myhg.platformConnection.platformToken.data;
    const segment = state.myhg.segment;

    return {
        accountDetails: state.user.accountDetails.data,
        authData: state.authentication.authData,
        permissions: state.user.permissions.data,
        isPlatformTypeAndNotConnected: isPlatformTypeAndNotConnectedCheck(
            segment.currentSegment, platformTokenStatus, state.authentication.authData),
        isPlatformTypeAndOtherAccount: isPlatformTypeAndOtherAccountCheck(
            segment.currentSegment, state.authentication.authData),
        segmentView: segment.segmentView,
        currentSegment: segment.currentSegment,
        isFiltersEditOpen: segment.segmentView.isFiltersEditOpen,
        segment
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class SegmentContainer extends React.Component {

    componentDidMount() {
        const { dispatch, accountDetails, params: { segmentId, sharedSegmentId } } = this.props;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);

        if (!UserUtils.isPaid(accountDetails) && !campaignTrialInfo.isActive) {
            // in case that the user is not paid and the campaign active flag is set on false
            // we need to load account details to see if the trial campaign started
            // the fix is for the folowing situation: a new user create a segment then matched it
            // with a file (in this case if we don't get the account details again we don't know
            // if the trial period has started) , the same scneario is applied when a user matched
            // a segment with salesforce or marketo
            dispatch(loadAccountDetails());
        }

        // load account limits
        if (this.isUserAuthenticated()) {
            dispatch(loadPermissions());
        }

        if (segmentId) {
            // load segment if available param id
            this.loadSegment(segmentId);
        } else if (sharedSegmentId) {
            dispatch(showEditFilterView(false));
            dispatch(getSegmentShare(sharedSegmentId))
                .then((response) => {
                    // set and load segment data based on url filters
                    const segment = response.data;
                    // set filters segment
                    dispatch(setFiltersBySegment(segment));
                    // save filters locally (current segment)
                    dispatch(applyFilters(segment.data.filters));
                    dispatch(segmentNameChanged(segment.name || ''));
                    dispatch(loadRelatedSegmentData(false));
                });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(cancelUnresolvedRelatedSegmentRequests());
        // clean firmographics data
        dispatch(cleanFirmographicsData());
    }

    onReviewFilters = () => {
        this.props.dispatch({
            type: SEGMENT_FILTERS_EDIT_VIEW_CHANGE,
            value: !this.props.isFiltersEditOpen
        });
        window.scrollTo(0, 0);
    }

    onSegmentNameChange = (name) => {
        const { dispatch } = this.props;
        dispatch(segmentNameChanged(name));
    }

    getSummaryDataContent() {
        const {
            segment
        } = this.props;
        const {
            source: segmentSource,
            sourceValue: segmentAccount
        } = segment.currentSegment.data.status;

        return (
            <SegmentContentContainer
                { ...this.props }
                segmentSource={ segmentSource }
                segmentAccount={ segmentAccount }
                isUserAuthenticated={ this.isUserAuthenticated() }
                onReviewFilters={ this.onReviewFilters }
            />
        );
    }

    getTopSection() {
        const {
            params: { segmentId, encodedSegment },
            isPlatformTypeAndNotConnected,
            isPlatformTypeAndOtherAccount
        } = this.props;
        const { data: { filters } } = this.props.segment.currentSegment;
        const hasFilters = Object.keys(filters).length > 0;

        if ((encodedSegment) && !hasFilters) {
            return <Loader />;
        }

        return (
            <PanelWrapper className="segment-top-section segment-filters">
                <SegmentTopSection
                    { ...this.props }
                    onSegmentNameChange={ this.onSegmentNameChange }
                    segmentId={ segmentId }
                    isUserAuthenticated={ this.isUserAuthenticated() }
                />
                <SegmentFiltersContainer
                    segmentId={ segmentId }
                    isUserAuthenticated={ this.isUserAuthenticated() }
                    hasFilters={ this.hasFilters }
                    disabled={ isPlatformTypeAndNotConnected || isPlatformTypeAndOtherAccount }
                    { ...this.props }
                />
            </PanelWrapper>
        );
    }

    getMatchingSection() {
        const { data: { status } } = this.props.segment.currentSegment;
        // don't show the match data options if segment already tied to a source
        if (status.source) {
            return null;
        }

        return (
            <SegmentDataMatchTiles
                { ...this.props }
                isUserAuthenticated={ this.isUserAuthenticated() }
            />
        );
    }

    getCRMStatus() {
        const { data: { status } } = this.props.segment.currentSegment;

        if (status.status === SEGMENT_STATUS.NON_MATCHED) {
            return null;
        }

        return (
            <SegmentCrmStatusContainer
                { ...this.props }
                isUserAuthenticated={ this.isUserAuthenticated() }
                onReviewFilters={ this.onReviewFilters }
            />
        );
    }

    hasFilters = () => {
        const { data: { filters } } = this.props.segment.currentSegment;
        return Object.keys(filters).length > 0;
    }

    loadSegment = (segmentId) => {
        const {
            dispatch,
            accountDetails
        } = this.props;
        const isTrialExpired = UserUtils.isTrialExpired(accountDetails);

        dispatch(loadSegment(segmentId))
            .then((segment) => {
                const {
                    isPlatformTypeAndNotConnected,
                    isPlatformTypeAndOtherAccount
                } = this.props;
                if (isTrialExpired) {
                    dispatch(loadProductsSummary(segment.data.filters.slugs));
                    return;
                } else if (
                    isPlatformTypeAndNotConnected ||
                    isPlatformTypeAndOtherAccount) {
                    return;
                }

                this.checkAndRefreshSegment(segment.data).then(() => {
                    dispatch(loadMiscSegmentData());
                });
            })
            .catch((error) => {
                if (error && error.status === HTTP_NOTFOUND_CODE) {
                    browserHistory.replace('/dashboard');
                }
            });
    }

    checkAndRefreshSegment(segmentData) {
        const { dispatch } = this.props;
        const { status } = segmentData;

        const counts = !status.source ? ['companies', 'productCompanies'] : ['matched', 'netNew'];
        const validCounts = counts.filter(count => {
            return status[count] !== null && status[count] !== undefined;
        });

        if (validCounts.length) {
            return Promise.resolve();
        }

        return dispatch(loadSegmentCounts());
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    render() {
        return (
            <div id="view-segment" className="content-wrapper">
                <NotificationContainer />
                { this.getTopSection() }
                { this.hasFilters() ? this.getCRMStatus() : null }
                { this.hasFilters() ? this.getMatchingSection() : null }
                { this.hasFilters() ? this.getSummaryDataContent() : null }
            </div>
        );
    }
}
