import { combineReducers } from 'redux';
import {
    SAMPLE_COMPANIES_REQUEST,
    SAMPLE_COMPANIES_SUCCESS,
    SAMPLE_COMPANIES_FAILURE,
    DOWNLOAD_SAMPLE_REQUEST,
    DOWNLOAD_SAMPLE_SUCCESS,
    DOWNLOAD_SAMPLE_FAILURE
} from './constants';

export const initialSampleCompaniesState = {
    isFetched: false,
    companies: [],
    error: null,
    totalCompanies: null
};

export function loadSample(state = initialSampleCompaniesState, action) {
    switch (action.type) {
        case SAMPLE_COMPANIES_REQUEST:
            return Object.assign({}, state, {
                isFetched: false,
                error: null
            });

        case SAMPLE_COMPANIES_SUCCESS:
            return Object.assign({}, state, {
                error: null,
                isFetched: true,
                companies: action.data.installDetails,
                totalCompaniesCount: action.data.totalInstalls
            });

        case SAMPLE_COMPANIES_FAILURE:
            return Object.assign({}, state, {
                isFetched: false,
                error: action.error
            });

        default:
            return state;
    }
}

// downloadSample reducer

export const downloadSampleInitialState = {
    downloadInProgress: false,
    downloaded: false,
    error: null
};

export function downloadSample(state = downloadSampleInitialState, action) {
    switch (action.type) {
        case DOWNLOAD_SAMPLE_REQUEST:
            return Object.assign({}, state, {
                downloadInProgress: true,
                error: null
            });
        case DOWNLOAD_SAMPLE_SUCCESS:
            return Object.assign({}, state, {
                downloadInProgress: false,
                downloaded: true,
                error: null
            });
        case DOWNLOAD_SAMPLE_FAILURE:
            return Object.assign({}, state, {
                downloadInProgress: false,
                downloaded: false,
                error: action.error
            });
        default:
            return state;
    }
}

export default combineReducers({
    loadSample,
    downloadSample
});
