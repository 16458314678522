import React from 'react';
import classNames from 'classnames';

export default class Loader extends React.Component {

    render() {
        const className = classNames('loading-wrapper', this.props.className);

        return (
            <div className={ className } >
                <span className="loading"></span>
                { this.props.children }
            </div>
        );
    }
}
