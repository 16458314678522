import { browserHistory } from 'react-router';
import LoginService from 'common/services/LoginService';
import RegistrationService from 'common/services/RegistrationService';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_RESET_STATE
} from './constants';
import { setupAuthenticationData } from '../actions';

export function login(loginData) {
    return (dispatch, getState) => {
        dispatch({
            type: LOGIN_REQUEST
        });

        // Dispatch vanilla actions asynchronously
        return LoginService.instance.login(loginData)
            .then((response) => {
                dispatch(setupAuthenticationData(
                    response.data,
                    LOGIN_SUCCESS
                )).then(() => {
                    const { refererPath } = getState().authentication;
                    const returnPath = refererPath || '/dashboard';

                    browserHistory.push(returnPath);
                });
            })
            .catch((error) => {
                dispatch({
                    type: LOGIN_FAILURE,
                    error
                });
            });
    };
}

export function passwordResetRequest(email) {
    return (dispatch) => {
        dispatch({
            type: RESET_PASSWORD_REQUEST
        });

        return RegistrationService.instance.forgotPassword(email)
            .then((response) => {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: RESET_PASSWORD_FAILURE,
                    error
                });
            });
    };
}

export function resetPasswordResetRequest() {
    return {
        type: RESET_PASSWORD_RESET_STATE
    };
}
