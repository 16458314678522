import d3 from 'd3';
import {
    HORIZONTAL_BARCHART_VERTICAL_MARGINS,
    HORIZONTAL_BARCHART_HORIZONTAL_MARGINS,
    HORIZONTAL_BARCHART_RANGEBANDS_PADDING,
    HORIZONTAL_BARCHART_TICK_PADDING,
    HORIZONTAL_BARCHART_MAX_DOMAIN_SCALE_RATIO,
    HORIZONTAL_BARCHART_BAR_ANIMATION_MS,
    HORIZONTAL_BARCHART_BAR_TIPTEXT_ANIMATION_MS,
    HORIZONTAL_BARCHART_UPDATE_AXIS_ANIMATION_MS,
    CHART_HEIGHT_WIDTH_ASPECT_RATIO
} from './constants';

/**
* Basic Bar Chart
*/
export default class HorizontalBarChart {

    constructor(el, props = {}) {
        this.el = el;
        this.props = props;
        this.verticalMargin = HORIZONTAL_BARCHART_VERTICAL_MARGINS;
        this.horizontalMargin = HORIZONTAL_BARCHART_HORIZONTAL_MARGINS;
        this.tickPaddingSpacesFraction = this.props.tickPaddingSpacesFraction || 5;
        this.drawChart(this.props);
        this.handleResizeHandler = this.handleResize.bind(this);
        // on window rezise automatically adjust bar chart size
        window.addEventListener('resize', this.handleResizeHandler);
    }

    handleResize() {
        if (this.svg) {
            this.svg.remove();
        }

        this.drawChart(this.props);
    }

    drawChart(props) {
        this.chartContainerWidth = this.el.offsetWidth;
        this.chartContainerHeight = this.el.offsetWidth * CHART_HEIGHT_WIDTH_ASPECT_RATIO;

        this.width = this.chartContainerWidth - 2 * this.horizontalMargin;
        this.height = this.chartContainerHeight - 2 * this.verticalMargin;

        // create X and Y scales
        this.x = d3.scale.linear()
            .range([0, this.width]);

        this.y = d3.scale.ordinal()
            .rangeRoundBands([0, this.height], HORIZONTAL_BARCHART_RANGEBANDS_PADDING);

        this.configureXAxis();
        this.configureYAxis();

        // create svg and chart containers
        this.svg = d3.select(this.el)
            .append('svg')
                .attr('class', 'bar-chart horizontal')
                .attr('width', this.chartContainerWidth)
                .attr('height', this.chartContainerHeight);

        this.chart = this.svg
            .append('g')
            .attr('transform', `translate(${this.horizontalMargin}, ${this.verticalMargin} )`);

        // update chart with data
        this.update(props);
    }

    configureXAxis() {
        this.xAxis = d3.svg.axis()
            .scale(this.x)
            .orient('bottom');
    }

    configureYAxis() {
        this.yAxis = d3.svg.axis()
            .scale(this.y)
            .tickSize(0)
            .tickPadding(HORIZONTAL_BARCHART_TICK_PADDING)
            .orient('left');

        this.yAxisRightLabels = d3.svg.axis()
            .scale(this.y)
            .tickSize(0)
            .tickPadding(HORIZONTAL_BARCHART_TICK_PADDING)
            .orient('right');
    }

    update(props) {
        this.props = props;
        const data = this.props.data || [];
        this.setupXYDomains(data);
        this.drawAxis(data);
        this.drawBars(data);
        this.drawBarTipTexts(data);
    }

    setupXYDomains(data) {
        // update domain for the X and Y scales based on the data

        // compute interval domains to accomodate spacing/visual appealing
        let minimValue = d3.min(data, (d) => { return d.value; });
        let maxValue = d3.max(data, (d) => { return d.value; });

        minimValue =
            (minimValue >= 0 || Math.abs(minimValue) < maxValue / this.tickPaddingSpacesFraction)
            ? -maxValue / this.tickPaddingSpacesFraction : minimValue;
        maxValue =
            (maxValue <= 0 || Math.abs(minimValue) > maxValue * this.tickPaddingSpacesFraction)
            ? -minimValue / this.tickPaddingSpacesFraction : maxValue;

        this.x.domain([
            minimValue * HORIZONTAL_BARCHART_MAX_DOMAIN_SCALE_RATIO,
            maxValue * HORIZONTAL_BARCHART_MAX_DOMAIN_SCALE_RATIO
        ]);
        this.y.domain(data.map((d) => { return d.name; }));
    }

    drawBars(data) {
        // bind data
        const bars = this.chart.selectAll('.bar').data(data);

        // bar
        bars.enter()
            .append('rect')
                .attr('class', 'bar')
                .attr('class',
                    (d) => {
                        const barType = d.value < 0 ? 'bar-negative' : 'bar-positive';
                        return `bar ${barType}`;
                    })
                .attr('x', () => { return this.x(0); })
                .attr('y', (d) => { return this.y(d.name); })
                .attr('width', () => { return 0; })
                .attr('height', this.y.rangeBand())
                .attr('opacity', 0)
                .on('click', (d, i) => {
                    if (this.props.onClick) {
                        this.props.onClick(d, i);
                    }
                });

        // animated bar
        bars.transition()
            .duration(HORIZONTAL_BARCHART_BAR_ANIMATION_MS).ease('sin-in-out')
            .attr('x', (d) => { return this.x(Math.min(0, d.value)); })
            .attr('width', (d) => { return Math.abs(this.x(d.value) - this.x(0)); })
            .attr('opacity', 1);
    }

    drawBarTipTexts(data) {
        const barTipTexts = this.chart.append('g')
            .selectAll('text')
                .data(data);

        barTipTexts.enter()
            .append('text')
                .attr('class', 'bar-text')
                .attr('opacity', 0)
                .attr('x', (d) => {
                    const valueLength = (d.value || 0).toString().length;
                    return this.x(d.value) + (d.value < 0 ? -20 : valueLength * 10);
                })
                .attr('y', (d) => { return this.y(d.name) + this.y.rangeBand() / 2 + 4; })
                .text((d) => {
                    return d3.format(',')(d.value);// M, K format
                });
        barTipTexts.transition()
            .duration(HORIZONTAL_BARCHART_BAR_TIPTEXT_ANIMATION_MS)
            .ease('sin-in-out')
            .attr('opacity', 1);
    }

    drawAxis(data) {
        if (this.chart.select('.y.axis')[0][0] !== null) {
            // rescale on data update
            this.chart.select('.y.axis')
                .transition()
                .duration(HORIZONTAL_BARCHART_UPDATE_AXIS_ANIMATION_MS).ease('sin-in-out')
                .call(this.yAxis);
        } else {
            this.chart.append('g')
                .attr('class', 'y axis')
                .attr('opacity', 0)
                .attr('transform', `translate(${this.x(0)}, 0)`)
                .call(this.yAxis)
                .selectAll('.tick')
                    .attr('style', (d, i) => {
                        return data[i].value >= 0 ? 'opacity: 1' : 'opacity: 0';
                    });

            this.chart.append('g')
                .attr('class', 'y axis')
                .attr('opacity', 0)
                .attr('transform', `translate(${this.x(0)}, 0)`)
                .call(this.yAxisRightLabels)
                .selectAll('.tick')
                    .attr('style', (d, i) => {
                        return data[i].value < 0 ? 'opacity: 1' : 'opacity: 0';
                    });

            this.chart
                .selectAll('.y.axis')
                    .transition().duration(1200).ease('sin-in-out')
                        .attr('opacity', 1);
        }
    }

    destroy() {
        window.removeEventListener('resize', this.handleResizeHandler);
    }
}
