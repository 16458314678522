import React from 'react';
import { Route } from 'react-router';
import SalesforceAccountsContainer from
    'myhg/import/salesforce/SalesforceAccountsContainer';
import PardotAccountsContainer from
    'myhg/import/pardot/PardotAccountsContainer';
import MarketoAccountsContainer from
    'myhg/import/marketo/MarketoAccountsContainer';
import EloquaAccountsContainer from
    'myhg/import/eloqua/EloquaAccountsContainer';
import ImportFileContainer from 'myhg/import/file/ImportFileContainer';

export const importPlatformRoutes = (
    <Route
        path="import"
    >
        <Route
            path="file"
            component={ ImportFileContainer }
        />
        <Route
            path="salesforce"
            component={ SalesforceAccountsContainer }
        />
        <Route
            path="pardot"
            component={ PardotAccountsContainer }
        />
        <Route
            path="marketo"
            component={ MarketoAccountsContainer }
        />
        <Route
            path="eloqua"
            component={ EloquaAccountsContainer }
        />
    </Route>
);
