import React from 'react';
import {
    MY_HG_TOP_HEADER_TITLE,
    MY_HG_NEW_SEGMENT_BTN
} from '../constants';

export default class TopHeaderFreeUser extends React.Component {

    render() {
        const { boxMessage } = this.props;

        return (
            <div id="free-summary-header" className="myhghome-top-header">
                <div className="box message">
                    {
                        boxMessage || (
                            <div>
                                <h2>{ MY_HG_TOP_HEADER_TITLE }</h2>
                            </div>
                        )
                    }
                </div>
                <div className="box button">
                    <button
                        id="add-new-segment"
                        className="btn btn-primary"
                        onClick={ this.props.onNewSegmentClick }
                        data-qa="create-segment-button"
                    >
                        { MY_HG_NEW_SEGMENT_BTN }
                    </button>
                </div>
            </div>
        );
    }
}
