import {
    CONFIRM_RESET_PASSWORD_TITLE,
    CONFIRM_RESET_PASSWORD_REQUEST,
    CONFIRM_RESET_PASSWORD_REQUEST_TITLE,
    CONFIRM_RESET_PASSWORD_SUCCESS,
    CONFIRM_RESET_PASSWORD_SUCCESS_TITLE,
    CONFIRM_RESET_PASSWORD_FAILURE,
    CONFIRM_RESET_PASSWORD_FAILURE_TITLE,
    DECODE_VERIFICATION_TOKEN_DATA
} from './constants';

export const confirmResetPasswordInitialState = {
    submitting: false,
    data: null,
    decodedData: {
        isValid: true,
        decoded: false
    },
    displayMenuTitle: CONFIRM_RESET_PASSWORD_TITLE
};

function decodePasswordConfirmToken(base64EncodedToken) {
    let decodedData;

    try {
        decodedData = JSON.parse(atob(base64EncodedToken));
    } catch (exc) {
        decodedData = null;
    }

    return decodedData;
}

export default function resetPasswordConfirm(state = confirmResetPasswordInitialState, action) {
    switch (action.type) {
        case CONFIRM_RESET_PASSWORD_REQUEST :
            return Object.assign({}, state, {
                submitting: true,
                data: null,
                error: null,
                displayMenuTitle: CONFIRM_RESET_PASSWORD_REQUEST_TITLE
            });

        case CONFIRM_RESET_PASSWORD_SUCCESS :
            return Object.assign({}, state, {
                data: action.data,
                submitting: false,
                passwordChanged: true,
                displayMenuTitle: CONFIRM_RESET_PASSWORD_SUCCESS_TITLE
            });

        case CONFIRM_RESET_PASSWORD_FAILURE :
            return Object.assign({}, state, {
                submitting: false,
                error: action.error,
                displayMenuTitle: CONFIRM_RESET_PASSWORD_FAILURE_TITLE
            });

        case DECODE_VERIFICATION_TOKEN_DATA : {
            const decodedData = decodePasswordConfirmToken(action.token);
            return Object.assign({}, state, {
                submitting: false,
                decodedData: {
                    isDecoded: true,
                    data: decodedData,
                    isValid: !!decodedData
                },
                displayMenuTitle: !!decodedData ?
                    CONFIRM_RESET_PASSWORD_TITLE :
                    CONFIRM_RESET_PASSWORD_FAILURE_TITLE
            });
        }

        default:
            return state;
    }
}
