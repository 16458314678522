import {
    FILTER_PRODUCT_ADD,
    FILTER_PRODUCT_REMOVE,
    FILTER_PRODUCT_SET,
    FILTER_PRODUCT_CLEAR,
    FILTER_PRODUCTS_ADD,
    PRODUCTS_RELATION_SET
} from './constants';

export function addTechnology(technology) {
    return (dispatch) => {
        return dispatch({
            type: FILTER_PRODUCT_ADD,
            technology
        });
    };
}

export function removeTechnology(technology) {
    return (dispatch) => {
        return dispatch({
            type: FILTER_PRODUCT_REMOVE,
            technology
        });
    };
}

export function setTechnologies(technologies) {
    return (dispatch) => {
        return dispatch({
            type: FILTER_PRODUCT_SET,
            technologies
        });
    };
}

export function clearTechnologies() {
    return (dispatch) => {
        return dispatch({
            type: FILTER_PRODUCT_CLEAR
        });
    };
}

export function addTechnologies(technologies) {
    return (dispatch) => {
        return dispatch({
            type: FILTER_PRODUCTS_ADD,
            technologies
        });
    };
}

export function setProductsRelation(matchAllProducts) {
    return (dispatch) => {
        return dispatch({
            type: PRODUCTS_RELATION_SET,
            matchAllProducts: matchAllProducts || false
        });
    };
}
