import React from 'react';
import { connect } from 'react-redux';
import USMapLocationsChart from './components/USMapLocationsChart';
import OtherMapsLocationsChart from './components/OtherMapsLocationsChart';
import Loader from 'shared/ui/loader/Loader';
import {
    loadLocationFirmos
} from './actions';

function mapStateToProps(state) {
    const { segment } = state.myhg;
    return {
        lastSegmentFiltersUpdate: segment.lastSegmentFiltersUpdate,
        locationsFirmographic: segment.firmographic.locationsFirmographic,
        matchedSource: segment.currentSegment.data.status.source

    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

/**
 * Component that renders the Locations Chart Container
 */
export default class LocationsChartContainer extends React.Component {

    componentDidMount() {
        const { dispatch, locationsFirmographic: { data, isFetching } } = this.props;
        if (!isFetching && !data) {
            dispatch(loadLocationFirmos());
        }
    }

    componentDidUpdate() {
        const { dispatch, locationsFirmographic: {
            error, isFetching, data
        } } = this.props;

        if (!data && !isFetching && !error) {
            // reload locations firmo data on segment filters change
            dispatch(loadLocationFirmos());
        }
    }

    renderContent(data) {
        return (
            <div className="segment-firmographic-locations">
                <USMapLocationsChart
                    data = { data }
                    isMatched = { !!this.props.matchedSource }
                    accountDetails = { this.props.accountDetails }
                />
                <OtherMapsLocationsChart
                    isMatched = { !!this.props.matchedSource }
                    { ...this.props }
                />
            </div>
        );
    }

    render() {
        const { locationsFirmographic: {
            error, isFetching, data
        } } = this.props;
        let content = null;

        // errors
        if (error) {
            content = <div>{ error.message }</div>;
        // loader
        } else if (isFetching) {
            content = <Loader className="location-loader" />;
        // display chart
        } else {
            content = this.renderContent(data);
        }

        return content;
    }
}
