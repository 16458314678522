// segment menu actions
export const BTN_TEXT_MATCH_MY_DATA = 'Match to My Data';
export const BTN_SHARE_SEGMENT = 'Share Segment';
export const BTN_SAVE_SEGMENT = 'Save Segment';
// segment menu names
export const SEGMENT_SET_MENU = 'SEGMENT_SET_MENU';
export const SEGMENT_SUMMARY_MENU_NAME = 'summary';
export const SEGMENT_DATA_MENU_NAME = 'data';
export const SEGMENT_TAB_MENUS = [
    {
        displayName: 'Summary',
        name: SEGMENT_SUMMARY_MENU_NAME
    }, {
        displayName: 'Results',
        name: SEGMENT_DATA_MENU_NAME
    }
];
