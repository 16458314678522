import {
    USER_PERMISSIONS_REQUEST,
    USER_PERMISSIONS_SUCCESS,
    USER_PERMISSIONS_FAILURE,
    PERMISSION_TYPE
} from './constants';

export const permissionsInitialState = {
    isFetching: false,
    error: null,
    data: null
};

export function normalizePermissions(permissionsData) {
    let normalizedPermissions = null;
    if (permissionsData) {
        normalizedPermissions = {
            [PERMISSION_TYPE.CREATE_CAMPAIGN]: {
                isUnlimited: permissionsData.unlimitedCampaigns,
                remaining: permissionsData.campaigns,
                total: permissionsData.maxCampaigns
            },
            [PERMISSION_TYPE.DOWNLOAD_SAMPLE]: {
                remaining: permissionsData.downloads,
                total: permissionsData.maxDownloads,
                allowed: permissionsData.downloadedSamples
            },
            [PERMISSION_TYPE.APPEND_LIST]: {
                remaining: permissionsData.uploads,
                total: permissionsData.maxUploads
            },
            [PERMISSION_TYPE.CREATE_SEGMENT]: {
                // TODO: update with segment permission instead when api ready
                remaining: permissionsData.campaigns,
                total: permissionsData.maxCampaigns
            }
        };
    }

    return normalizedPermissions;
}

export default function permissions(state = permissionsInitialState, action) {
    switch (action.type) {
        case USER_PERMISSIONS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case USER_PERMISSIONS_SUCCESS:
            return Object.assign({}, state, {
                data: normalizePermissions(action.data),
                isFetching: false,
                error: null
            });

        case USER_PERMISSIONS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
}
