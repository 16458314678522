import TaxonomyService from 'common/services/TaxonomyService';
import {
    COLLECTION_TAXONOMY_REQUEST,
    COLLECTION_TAXONOMY_SUCCESS,
    COLLECTION_TAXONOMY_FAILURE
} from './constants';

export function loadCollectionTaxonomy() {
    return (dispatch) => {
        dispatch({
            type: COLLECTION_TAXONOMY_REQUEST
        });
        return TaxonomyService.instance.getCollections()
            .then((response) => {
                dispatch({
                    type: COLLECTION_TAXONOMY_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: COLLECTION_TAXONOMY_FAILURE,
                    error
                });
                return {
                    serverShouldHandle: true,
                    error
                };
            });
    };
}
