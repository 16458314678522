import {
    LINK_EXPIRATION_PERIOD_MS,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
    SIGN_UP_START_OVER,
    SIGN_UP_LOCAL_STATE_LOADED
} from './constants';
import { CONFIRM_SIGN_UP_SUCCESS } from '../confirmRegistration/constants';

export const signUpInitialState = {
    // localStateLoaded - know if the registration progress from local storage is loaded
    localStateLoaded: false,
    // inProgress tell us there is an undergoing registration process (not api call request)
    inProgress: false,
    authData: null
};

/**
 * Determine wheter or not the link has an expiration state
 * @param {object} registrationProgress The progress registration saved
 * @returns {boolean} wheter or not the link expired
 */
function isLinkedExpired(registrationProgress) {
    return registrationProgress &&
        (registrationProgress.timestamp + LINK_EXPIRATION_PERIOD_MS) <
        new Date().getTime();
}

export default function signUp(state = signUpInitialState, action) {
    switch (action.type) {
        case SIGN_UP_REQUEST :
            return Object.assign({}, state, {
                inProgress: action.resend || false,
                error: null
            });

        case SIGN_UP_SUCCESS :
            return Object.assign({}, state, {
                inProgress: true,
                authData: action.data,
                registrationData: action.registrationData,
                error: null
            });

        case SIGN_UP_FAILURE :
            return Object.assign({}, state, {
                inProgress: false,
                error: action.error
            });

        case SIGN_UP_START_OVER :
            return Object.assign({}, state, {
                inProgress: false,
                registrationData: null
            });

        case CONFIRM_SIGN_UP_SUCCESS :
            return Object.assign({}, state, {
                inProgress: false,
                registrationData: null,
                authData: action.data
            });

        case SIGN_UP_LOCAL_STATE_LOADED :
            return Object.assign({}, state, {
                error: null,
                registrationData: action.registrationProgress &&
                    action.registrationProgress.registrationData,
                localStateLoaded: true,
                inProgress: action.registrationProgress !== null,
                linkExpired: isLinkedExpired(action.registrationProgress)
            });

        default:
            return state;
    }
}
