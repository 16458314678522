import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import numeral from 'numeral';
import Loader from 'shared/ui/loader/Loader';
import ImageLoader from 'shared/ui/imageLoader/ImageLoader';
import { toggleDescription } from '../actions';
import { TEXT_LENGTH } from '../constants';
import { resetSegmentView } from 'myhg/segment/actions';
import { setTechnologies } from 'myhg/segment/segmentFilters/technologiesFilter/actions';
import { ACTION, WHERE } from 'utils/track-constants';

export default class ProductDetails extends React.Component {

    onCreateSegment = () => {
        const { slug, dispatch } = this.props;
        dispatch(resetSegmentView());
        dispatch(setTechnologies([slug]));
        track(ACTION.PRODUCT_CREATE_TARGET_SEGMENT, { label: WHERE.PRODUCT_HEADER });
    }

    getLogo(product) {
        const imgUrl = `https://logo.clearbit.com/${product.vendorUrl}?size=32`;
        return (
            <ImageLoader
                src={ imgUrl }
                imgProps={ { alt: `${product.product} logo` } }
                preloader={ this.defaultIconPreloader() }
            >
                <i className="icon-global default-logo"></i>
            </ImageLoader>
        );
    }

    getFullDescriptionActionText() {
        let content = {
            linkLabel: '',
            dots: ''
        };
        const productDescription = this.props.productDetails.data.description;
        if (productDescription !== '' && productDescription.length > TEXT_LENGTH) {
            if (this.props.productDetails.showFullDescription) {
                content = {
                    linkLabel: 'show less',
                    dots: ''
                };
            } else {
                content = {
                    linkLabel: 'show more',
                    dots: '...'
                };
            }
        }
        return content;
    }

    getTotalCompanies() {
        return numeral(this.props.sampleCompanies.totalCompaniesCount).format('0,0');
    }

    toggleFullDescription() {
        const productDescription = this.props.productDetails.data.description;
        const showFullDescription = this.props.productDetails.showFullDescription;
        return showFullDescription ?
            productDescription : productDescription.substr(0, TEXT_LENGTH);
    }

    defaultIconPreloader = () => {
        return <Loader className="logo-loader" />;
    }

    toggleDescription() {
        this.props.dispatch(toggleDescription());
    }

    render() {
        const { productDetails } = this.props;
        const description = this.props.productDetails.data.description;

        const toggleProductDescription = classNames('product-description', {
            hidden: !description
        });
        const content = this.getFullDescriptionActionText();

        return (
            <div className="product-details">
                <h1>
                    <span className="large-logo-container">
                        { this.getLogo(productDetails.data) }
                    </span>
                    <span className="product-title">
                        { productDetails.data.product }
                    </span>
                </h1>

                <p className={ toggleProductDescription }>
                    { this.toggleFullDescription() }
                    { content.dots }
                    &nbsp;
                    <span
                        className="more-less"
                        onClick={ this.toggleDescription.bind(this) }
                    >
                        { content.linkLabel }
                    </span>
                </p>
            </div>
        );
    }
}

ProductDetails.propTypes = {
    slug: PropTypes.string.isRequired
};
