import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import ValidationField from 'shared/ui/formFields/ValidationField';
import Validation from 'utils/Validation';
import Loader from 'shared/ui/loader/Loader';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';

export const fields = [
    'apiKey',
    'apiSecret',
    'marketoIdentityUrl',
    'marketoRestUrl',
    'marketoAlias'
];

const validate = values => {
    const errors = {};

    if (!Validation.validateWhitespace(values.apiKey)) {
        errors.apiKey = 'Required';
    }

    if (!Validation.validateWhitespace(values.apiSecret)) {
        errors.apiSecret = 'Required';
    }
    if (!Validation.validateWhitespace(values.marketoIdentityUrl)) {
        errors.marketoIdentityUrl = 'Required';
    }
    if (!Validation.validateWhitespace(values.marketoRestUrl)) {
        errors.marketoRestUrl = 'Required';
    }
    if (!values.marketoAlias) {
        errors.marketoAlias = 'Required';
    }

    return errors;
};

class MarketoConnectForm extends React.Component {

    constructor(props) {
        super(props);

        this.marketoAliasDefaultValue = undefined;
        if (this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.name) {
            this.marketoAliasDefaultValue = this.props.location.state.name;
        }
    }

    componentDidMount() {
        if (this.marketoAliasDefaultValue) {
            // Need to focus() then blur() to set the value attribute,
            // otherwise the user has to click into and out of the marketoAlias field.
            document.getElementById('marketoAlias').focus();
            document.getElementById('marketoAlias').blur();
        }
    }

    getError(error) {
        if (!error) {
            return null;
        }

        return (
            <p
                className="text-content error"
                dangerouslySetInnerHTML={ { __html: error.message } }
            />
        );
    }

    isValidForm() {
        return Object.keys(this.props.errors).length === 0 &&
            this.props.errors.constructor === Object;
    }

    render() {
        const {
            fields: {
                apiKey,
                apiSecret,
                marketoIdentityUrl,
                marketoRestUrl,
                marketoAlias
            },
            handleSubmit,
            submitting,
            requestError
        } = this.props;

        return (
            <form
                id="marketo-connect"
                className="modal-form"
                onSubmit={ handleSubmit(this.props.onConnectSubmit) }
            >
                <ValidationField
                    type="text"
                    label="Client Id"
                    placeholder="e.g. 9a92b55b-1248-4070-a8f6-dc24b646b9ce"
                    fieldType="input"
                    {...apiKey}
                    data-qa="client-id"
                />
                <ValidationField
                    type="text"
                    label="Client Secret"
                    placeholder="e.g. 0nW2Sm8zX6QbfL4u6CoZJ0utDxnyKan"
                    fieldType="input"
                    {...apiSecret}
                    data-qa="client-secret"
                />
                <ValidationField
                    type="text"
                    label="Endpoint (Rest) Url"
                    placeholder="e.g. https://100-AEK-913.mktorest.com/rest"
                    fieldType="input"
                    {...marketoRestUrl}
                    data-qa="endpoint-url"
                />
                <ValidationField
                    type="text"
                    label="Identity Url"
                    placeholder="e.g. https://100-AEK-913.mktorest.com/identity"
                    fieldType="input"
                    {...marketoIdentityUrl}
                    data-qa="identity-url"
                />
                <ValidationField
                    id="marketoAlias"
                    type="text"
                    label="Name This Marketo Account"
                    placeholder="e.g. My Marketo Test Account"
                    fieldType="input"
                    {...marketoAlias}
                    defaultValue={ this.marketoAliasDefaultValue }
                    data-qa="marketo-account-name"
                />
                { this.getError(requestError) }
                <FormButtonWrapper>
                    <button
                        id="marketo-connect-btn"
                        type="submit"
                        className="btn btn-primary"
                        disabled={ submitting || !this.isValidForm() }
                        data-qa="connect-button"
                    >
                        Continue { submitting ?
                        <Loader className="small-loader white" /> : '' }
                    </button>
                </FormButtonWrapper>
            </form>
        );
    }
}

// https://www.npmjs.com/package/@jimpick/redux-form
MarketoConnectForm.propTypes = {
    fields: PropTypes.object.isRequired,
    // It will run validation, both sync and async, and, if the form is valid,
    // it will call this.props.onConnectSubmit function
    handleSubmit: PropTypes.func.isRequired,
    // Whether or not your form is currently submitting.
    // This prop will only work if you have passed an onSubmit function
    // that returns a promise. It will be true until the promise is resolved or rejected.
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'marketoConnectForm',
    fields,
    validate
})(MarketoConnectForm);
