export const SALESFORCE_CONNECT_PAGE_TITLE = 'Connect to Salesforce';
export const SALESFORCE_CONNECT_PAGE_DESCRIPTION =
    'HG Insights wants to connect to your Salesforce account to:';

export const SALESFORCE_ALREADY_CONNECT_PAGE_TITLE =
    'Salesforce Account Connected';
export const SALESFORCE_ALREADY_CONNECT_PAGE_DESCRIPTION =
    'You are already authenticated with a Salesforce account:';
export const SALESFORCE_CONNECT_NOTE =
    'Note: HG Insights does not use or retain this data in any way.';
export const SALESFORCE_MATCH_FIRST_TITLE =
    'Match Your Segment to Salesforce First';
export const SALESFORCE_MATCH_FIRST_TEXT =
    `In order to create a Salesforce campaign with this segment's data, \n
    please match your contacts and/or leads from Saleforce.`;
export const SALESFORCE_MATCH_FIRST_BUTTON =
    'Match Segment to Salesforce';
