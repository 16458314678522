import React from 'react';
import classNames from 'classnames';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';

export default class MyHgPanelWrapper extends React.Component {

    render() {
        const className = classNames('myhg-panel transparent', this.props.className);

        return (
            <PanelWrapper
                { ...this.props }
                className={ className }
            >
                { this.props.children }
            </PanelWrapper>
        );
    }
}
