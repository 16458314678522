import { browserHistory } from 'react-router';
import PlatformService from 'common/services/PlatformService';
import {
    EXISTING_CAMPAIGNS_REQUEST,
    EXISTING_CAMPAIGNS_SUCCESS,
    EXISTING_CAMPAIGNS_FAILURE,
    EXISTING_CAMPAIGNS_SELECT,
    NEW_CAMPAIGN_SET,
    SAVE_CAMPAIGN_REQUEST,
    SAVE_CAMPAIGN_SUCCESS,
    SAVE_CAMPAIGN_FAILURE,
    SAVE_CAMPAIGN_CLEAR_FAILURE
} from './constants';
import {
    SEGMENT_STATUS
} from 'myhg/segment/constants';
import {
    saveCurrentSegment
} from 'myhg/segment/actions';

export function setNewCampaign(campaign) {
    return (dispatch) => {
        dispatch({
            type: NEW_CAMPAIGN_SET,
            campaign
        });
    };
}

export function loadPlatformCampaigns(platformSource) {
    return (dispatch) => {
        dispatch({
            type: EXISTING_CAMPAIGNS_REQUEST
        });
        return PlatformService.instance.getCampaigns(platformSource)
            .then((response) => {
                dispatch({
                    type: EXISTING_CAMPAIGNS_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: EXISTING_CAMPAIGNS_FAILURE,
                    error
                });
            });
    };
}

export function saveCampaign(campaign, isNew) {
    return (dispatch, getState) => {
        // collect filters data
        const { campaignName, campaignId } = campaign;
        const { currentSegment } = getState().myhg.segment;
        const { id: segmentId, data: { filters } } = currentSegment;

        dispatch({
            type: SAVE_CAMPAIGN_REQUEST
        });

        const payload = isNew ? { campaignName } : { campaignId };

        return PlatformService.instance.saveCampaign(payload, filters, segmentId)
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: SAVE_CAMPAIGN_SUCCESS,
                    data
                });

                const externalCampaignLink = data.authorizationUrl ?
                    `${data.authorizationUrl}/${data.id}` : null;

                dispatch(saveCurrentSegment({
                    status: SEGMENT_STATUS.EXPORTED,
                    externalCampaignLink,
                    campaignName
                }));

                if (isNew) {
                    // set selected new campaign
                    dispatch(setNewCampaign({
                        id: response.data.id,
                        text: campaignName
                    }));
                }

                const returnLink = `/dashboard/segment/view/${segmentId}`;
                browserHistory.push(returnLink);
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_CAMPAIGN_FAILURE,
                    error
                });
            });
    };
}

export function selectExistingCampaign(campaign) {
    return (dispatch) => {
        dispatch({
            type: EXISTING_CAMPAIGNS_SELECT,
            campaign
        });
    };
}

export function clearSaveCampaignError() {
    return (dispatch) => {
        dispatch({
            type: SAVE_CAMPAIGN_CLEAR_FAILURE
        });
    };
}
