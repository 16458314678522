export const MY_HG_NEW_SEGMENT_BTN = 'Create a Target Segment';
export const MY_HG_TOP_HEADER_TITLE = 'My Dashboard';
// paid users
export const MY_HG_TOP_HEADER_VIEW_LIST = 'view list';
export const MY_HG_TOP_HEADER_SUBTITLE =
    'You have access to %1$s Products from %2$s Collections.';
export const MY_HG_TOP_HEADER_PRODUCTS_TITLE = '# PRODUCTS';
export const MY_HG_TOP_HEADER_COMPANIES_TITLE = '# UNIQUE COMPANIES';
// free users
export const MY_HG_TOP_HEADER_LIMITED_ACCESS_SUBTITLE =
    'You have trial/limited access to all products.';
export const MY_HG_TOP_HEADER_PRODUCTS_TITLE_FREE = '# FULL/TRIAL PRODUCTS';
export const MY_HG_TOP_HEADER_COMPANIES_TITLE_FREE = '# TOTAL TRIAL COMPANIES';
