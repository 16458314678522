import axios from 'axios';
import { saveAs } from 'file-saver';
import { browserHistory } from 'react-router';
import { DateUtils } from 'utils/Utils';
import SegmentService from 'common/services/SegmentService';
import {
    setFiltersBySegment,
    extractSegmentFiltersFromState,
    extractSegmentVisualFiltersFromState,
    clearFilters
} from './segmentFilters/actions';
import {
    setMatchesBySegmentStatus,
    loadProductsSummary,
    loadSegmentSummary,
    loadAvailableRecords,
    loadPlatformNetNewMatches,
    loadPlatformAvailableRecords,
    loadFileNetNewMatches,
    loadFileAvailableRecords
} from './segmentMatches/actions';
import {
    SEGMENT_LOAD_REQUEST,
    SEGMENT_LOAD_SUCCESS,
    SEGMENT_LOAD_FAILURE,
    SEGMENT_SAVE_REQUEST,
    SEGMENT_SAVE_SUCCESS,
    SEGMENT_SAVE_FAILURE,
    SEGMENT_NAME_CHANGED,
    SEGMENT_FILTERS_CHANGED,
    SEGMENT_RESET_VIEW,
    SEGMENT_UPDATE,
    SEGMENT_UPDATE_FILTERS,
    SEGMENT_DOWNLOAD_CSV_REQUEST,
    SEGMENT_DOWNLOAD_CSV_SUCCESS,
    SEGMENT_DOWNLOAD_CSV_FAILURE,
    SEGMENT_STATUS,
    SEGMENT_DELETE_SUCCESS,
    MATCH_SOURCE_TYPE,
    SEGMENT_NAME_EDIT_VIEW_CHANGE
} from './constants';
import {
    loadMatchData
} from 'myhg/segment/segmentTable/actions';

export function segmentNameChanged(name) {
    return (dispatch) => {
        return dispatch({
            type: SEGMENT_NAME_CHANGED,
            name
        });
    };
}

export function filtersChanged(filters) {
    return (dispatch) => {
        return dispatch({
            type: SEGMENT_FILTERS_CHANGED,
            filters
        });
    };
}

export function loadSegment(id) {
    return (dispatch) => {
        dispatch({
            type: SEGMENT_LOAD_REQUEST,
            id
        });

        return SegmentService.instance.getSegment(id)
            .then((response) => {
                const { data: segment } = response;

                dispatch({
                    type: SEGMENT_LOAD_SUCCESS,
                    data: segment
                });
                dispatch(setFiltersBySegment(segment));
                dispatch(setMatchesBySegmentStatus(segment.data.status));

                return segment;
            })
            .catch((error) => {
                dispatch({
                    type: SEGMENT_LOAD_FAILURE,
                    error
                });
                return Promise.reject(error);
            });
    };
}

export function updateSegment(segment) {
    return (dispatch) => {
        return dispatch({
            type: SEGMENT_UPDATE,
            segment
        });
    };
}

// update segment filters (on client only)
export function applyFilters(customFilters = {}) {
    return (dispatch, getState) => {
        const { segment } = getState().myhg;
        // updated filters
        let filters = extractSegmentFiltersFromState(segment.segmentFilters);
        filters = Object.assign({}, filters, customFilters);
        dispatch({
            type: SEGMENT_UPDATE_FILTERS,
            filters
        });
    };
}

// persist segment current state on server
export function saveCurrentSegment(newStatus = {}, customFilters = {},
    saveSegmentFilters = true, shouldSaveSegment = true) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        if (shouldSaveSegment) {
            dispatch({
                type: SEGMENT_SAVE_REQUEST,
                cancelToken
            });
        }
        const { segment } = getState().myhg;
        const { currentSegment } = JSON.parse(JSON.stringify(segment));
        // updated filters
        let filtersData = {};
        let visualFiltersData = {};
        if (saveSegmentFilters) {
            filtersData = Object.assign({}, extractSegmentFiltersFromState(segment.segmentFilters));
            visualFiltersData = extractSegmentVisualFiltersFromState(segment.segmentFilters);
        }
        filtersData = Object.assign({}, currentSegment.data.filters, filtersData, customFilters);
        visualFiltersData = Object.assign({}, currentSegment.data.visualFilters, visualFiltersData);
        // new status
        const newSegmentStatus = Object.assign({}, currentSegment.data.status, newStatus);
        // segment object to save
        const segmentToSave = Object.assign({}, currentSegment, {
            name: currentSegment.name || DateUtils.toStandardFormatDateTime(new Date)
        });
        // set dynamic data (from api perspective)
        segmentToSave.data.filters = filtersData;
        segmentToSave.data.visualFilters = visualFiltersData;
        segmentToSave.data.status = newSegmentStatus;
        // concurency issues when updating status from multiple places
        // we need to update segment status locally before the segment is saved
        dispatch(updateSegment(segmentToSave));

        if (shouldSaveSegment) {
            return SegmentService.instance.saveSegment(segmentToSave, cancelToken)
                .then((response) => {
                    dispatch({
                        type: SEGMENT_SAVE_SUCCESS,
                        data: { ...segmentToSave, ...response.data }
                    });

                    return response;
                })
                .catch((error) => {
                    dispatch({
                        type: SEGMENT_SAVE_FAILURE,
                        error
                    });
                    return Promise.reject(error);
                });
        }

        return null;
    };
}

// load miscellaneous data using current segmentSegment data
export function loadMiscSegmentData(loadProductsSummaryFlag = true) {
    return (dispatch, getState) => {
        const { data: { filters } } = getState().myhg.segment.currentSegment;

        const actions = [
            // load segment data records
            dispatch(loadMatchData())
        ];

        if (loadProductsSummaryFlag) {
            // load segment summary for each product in the segment
            actions.push(dispatch(loadProductsSummary(filters.slugs)));
        }

        return actions;
    };
}

export function loadSegmentCounts(shouldSaveSegment) {
    return (dispatch, getState) => {
        const { currentSegment } = getState().myhg.segment;
        const { source, sourceValue } = currentSegment.data.status;
        let promise;

        // Load segment top counts summary or net new data based on segment status
        if (!source) {
            promise = dispatch(loadSegmentSummary(shouldSaveSegment)).then(() => {
                dispatch(loadAvailableRecords(shouldSaveSegment));
            });
        } else if (source === MATCH_SOURCE_TYPE.FILE) {
            promise = dispatch(loadFileNetNewMatches(sourceValue)).then(() => {
                dispatch(loadFileAvailableRecords(sourceValue));
            });
        } else {
            // TODO: need to determine if sf account is logged in and has accounts imported
            promise = dispatch(loadPlatformNetNewMatches(currentSegment.id)).then(() => {
                dispatch(loadPlatformAvailableRecords(currentSegment.id));
            });
        }

        return promise;
    };
}

export function loadRelatedSegmentData(shouldSaveSegment = true) {
    return (dispatch, getState) => {
        const { data: { filters } } = getState().myhg.segment.currentSegment;
        // load segment counts
        dispatch(loadSegmentCounts(shouldSaveSegment)).then(() => {
            // load all additional data for the segment
            dispatch(loadProductsSummary(filters.slugs));
        });
        dispatch(loadMiscSegmentData(false));
    };
}

export function copySegment() {
    return (dispatch, getState) => {
        // clear current segment filters only and preserve filters
        const { name } = getState().myhg.segment.currentSegment;
        dispatch({ type: SEGMENT_RESET_VIEW });
        dispatch(segmentNameChanged(`${name}-copy`));
    };
}

export function resetSegmentView() {
    return (dispatch) => {
        // clear shared segment filters
        dispatch(clearFilters());
        // clear segment data
        return dispatch({
            type: SEGMENT_RESET_VIEW
        });
    };
}

export function deleteSegment(segmentId) {
    return (dispatch) => {
        return SegmentService.instance.deleteSegment(segmentId)
            .then(() => {
                dispatch(clearFilters());
                dispatch({
                    type: SEGMENT_DELETE_SUCCESS
                });
            });
    };
}

// for shareable segment update path with latest encoded url
// for saved segment update segment id in the path
export function updateSegmentUrl() {
    return (dispatch, getState) => {
        const { currentSegment } = getState().myhg.segment;
        const { locationPath } = getState().root;
        if (currentSegment.id && locationPath === '/dashboard/segment/new') {
            // should not redirect from shareable page to dashboard page version of segment
            browserHistory.replace(`/dashboard/segment/view/${currentSegment.id}`);
        }
    };
}

function getDownloadURL(statusObj) {
    let url;
    const { status, source } = statusObj;

    if (!status || status === SEGMENT_STATUS.NON_MATCHED) {
        url = 'filtering/matches/download';
    } else if (source === MATCH_SOURCE_TYPE.FILE) {
        url = 'filtering/csv/matches/download';
    } else {
        // common platforms url matches url
        url = 'salesforce/matches/download';
    }

    return url;
}

export function downloadAsCSV() {
    return (dispatch, getState) => {
        const currentState = getState();
        const segmentState = currentState.myhg.segment;
        const { id, name, data: { filters, status } } = segmentState.currentSegment;

        dispatch({
            type: SEGMENT_DOWNLOAD_CSV_REQUEST
        });

        // Dispatch vanilla actions asynchronously
        return SegmentService.instance.downloadCSV(getDownloadURL(status), filters, id)
            .then((response) => {
                const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
                saveAs(blob, `${name.replace(/\W+/g, '')}.csv`);
                dispatch({
                    type: SEGMENT_DOWNLOAD_CSV_SUCCESS
                });
            })
            .catch((error) => {
                dispatch({
                    type: SEGMENT_DOWNLOAD_CSV_FAILURE,
                    error
                });
            });
    };
}

export function cancelUnresolvedRelatedSegmentRequests() {
    return (dispatch, getState) => {
        const {
            segmentTable: { matchesDataLoad },
            segmentMatches: { availableRecordsData, summaryData }
        } = getState().myhg.segment;

        if (summaryData.cancelToken) {
            summaryData.cancelToken.cancel();
        }

        if (availableRecordsData.cancelToken) {
            availableRecordsData.cancelToken.cancel();
        }

        if (matchesDataLoad.cancelToken) {
            matchesDataLoad.cancelToken.cancel();
        }
    };
}

export function nameEditViewShow(isEditEnabled) {
    return (dispatch) => {
        return dispatch({
            type: SEGMENT_NAME_EDIT_VIEW_CHANGE,
            value: isEditEnabled
        });
    };
}
