/* global chrome */
import React from 'react';
import { Link } from 'react-router';
import sprintf from 'sprintf-js';
import {
    ABOUT_SECTION,
    HELP_SECTION,
    HG_DATA_SECTION,
    FOOTER_COPYRIGHT_1,
    FOOTER_COPYRIGHT_2,
    FOOTER_LOGO_PROVIDER,
    FOOTER_LOGO_PROVIDER_LINK,
    FOOTER_LINKS,
    FOOTER_ADRESS
} from './constants';

export default class Footer extends React.Component {

    getLinks(dataObj) {
        const content = FOOTER_LINKS[dataObj.link].map((item, index) => {
            return (
                <li key={ index }>
                    <a href={ item.link }>
                        { item.title }
                    </a>
                </li>
            );
        });

        return (
            <ul data-title={ dataObj.title } className={ dataObj.cssClass }>
                { content }
            </ul>
        );
    }

    getConnectLinks() {
        const links = FOOTER_LINKS.connect;
        const contentOne = links.slice(0, 2).map((item) => {
            const socialIcon = `social-icons ${item.icon}`;
            return (
                <li key={ item.name }>
                    <Link
                        to={ item.link }
                        target="_blank"
                        rel="nofollow"
                    >
                        <span className={ socialIcon } />
                    </Link>
                </li>
            );
        });

        const contentTwo = links.slice(2, 4).map((item) => {
            const socialIcon = `social-icons ${item.icon}`;
            return (
                <li key={ item.name }>
                    <Link
                        to={ item.link }
                        target="_blank"
                        rel="nofollow"
                    >
                        <span className={ socialIcon } />
                    </Link>
                </li>
            );
        });

        return (
            <ul data-title="CONNECT" className="connect">
                { contentOne }
                <br />
                { contentTwo }
            </ul>
        );
    }

    getCopyrightSection() {
        const year = (new Date).getFullYear();
        const copyRights = sprintf.sprintf(
            FOOTER_COPYRIGHT_1,
            year
        );
        return (
            <div className="footer-right-content">
                <div className="footer-address">
                    { FOOTER_ADRESS }
                </div>
                <div className="footer-copyright">
                    { copyRights }
                    <br />
                    { FOOTER_COPYRIGHT_2 }
                    &nbsp;
                    <a
                        href={ FOOTER_LOGO_PROVIDER_LINK }
                        target="_blank"
                        rel="nofollow"
                    >
                        { FOOTER_LOGO_PROVIDER }
                    </a>
                    .
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="footer">
                <div className="container">
                    { this.getLinks(HG_DATA_SECTION) }
                    { this.getLinks(ABOUT_SECTION) }
                    { this.getConnectLinks() }
                    { this.getLinks(HELP_SECTION) }
                    { this.getCopyrightSection() }
                </div>
            </div>
        );
    }
}
