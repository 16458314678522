/* eslint-disable max-len */
import React from 'react';
import Select from 'react-select';
import { browserHistory } from 'react-router';
import Loader from 'shared/ui/loader/Loader';
import { matchUrlsToAccount } from '../actions';
import { LINKEDIN_UPLOAD_NOTE } from '../constants';
import { showNotification } from 'shared/notification/actions';

export default class LinkedInConnectOptions extends React.Component {
    state = {
        selectedAccount: null
    };

    onAccountChange = (selectedAccount) => {
        this.setState({ selectedAccount: selectedAccount.value });
    };

    onUploadClick = () => {
        const { dispatch, currentSegment, accessToken } = this.props;
        const filters = currentSegment.data.filters;
        delete filters.matchAllProducts;
        filters.products = this.productNamesFromSlugs(filters.slugs);
        const linkedInSegmentName = 'HG_Insights_'.concat(currentSegment.name);
        dispatch(matchUrlsToAccount(linkedInSegmentName, this.state.selectedAccount, currentSegment, accessToken))
            .then(() => {
                browserHistory.push('/dashboard/segment/view/'.concat(currentSegment.id));
                dispatch(showNotification({
                    title: 'Segment Uploaded To LinkedIn',
                    description: `Your segment is being uploaded to your Uploaded Lists in LinkedIn Matched Audiences. You can find your segment under Account Assets > Matched Lists > ${linkedInSegmentName}.`
                }));
            });
    };

    productNamesFromSlugs(slugs) {
        const products = this.props.technologies;
        let productNames = [];
        products.forEach((product) => {
            if (slugs.indexOf(product.slug) >= 0) {
                productNames = productNames.concat(product.product);
            }
        });

        return productNames;
    }

    getDropdownSelections() {
        const { accounts } = this.props;

        return accounts.map((account) => {
            return { value: account.id, label: account.name };
        });
    }

    render() {
        const buttonDisabled = this.props.submitting;
        const selectStyle = {
            control: styles => {
                return (
                    { ...styles, marginTop: '50px' }
                );
            }
        };

        return (
            <div className="platform-connect-content">
                <Select
                    options={ this.getDropdownSelections() }
                    onChange={ this.onAccountChange }
                    placeholder="Select an Account"
                    styles={ selectStyle }
                />
                <span className="note-message">{ LINKEDIN_UPLOAD_NOTE }</span>
                <button
                    id="linkedin-upload"
                    disabled={ buttonDisabled }
                    className="btn btn-primary linkedin-upload-btn"
                    href="#"
                    onClick={ this.onUploadClick }
                >
                    Upload To LinkedIn
                    { buttonDisabled ?
                        <Loader className="small-loader white" /> : null
                    }
                </button>
            </div>
        );
    }
}
