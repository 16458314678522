import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import SampleCompanies from './components/SampleCompanies';
import { ACTION } from 'utils/track-constants';
import { downloadSampleFile } from './actions';
import { track } from 'utils/Utils';
import { loadPermissions } from 'shared/user/permissions/actions';

function mapStateToProps(state) {
    const { sampleCompanies: { loadSample, downloadSample }, productDetails } = state.product;

    return {
        loadSample,
        downloadSample,
        productDetails,
        permissions: state.user.permissions.data
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}
@connect(mapStateToProps, mapDispatchToProps)

export default class SampleCompaniesContainer extends React.Component {
    componentDidMount() {
        if (this.props.isUserAuthenticated) {
            this.props.dispatch(loadPermissions());
        }
    }

    onRegisterClick = () => {
        browserHistory.push('/login');
    }

    handleDownloadSample = (event) => {
        event.preventDefault();
        const { dispatch, productDetails } = this.props;

        track(ACTION.DOWNLOAD_SAMPLE_DOWNLOAD, { label: productDetails.data.product });
        return dispatch(downloadSampleFile(productDetails.data));
    }

    render() {
        return (
            <SampleCompanies
                { ...this.props }
                onRegisterClick={ this.onRegisterClick }
                handleDownloadSample={ this.handleDownloadSample }
            />
        );
    }
}
