import React from 'react';
import classNames from 'classnames';
import Checkbox from 'shared/ui/checkbox/Checkbox';

export default class SignalScoreSelection extends React.Component {

    handleSelection = () => {
        const { item, isChecked } = this.props;
        this.props.onChangeSelection(item, !isChecked);
    }

    render() {
        const { item, isChecked } = this.props;
        const signalClassName = classNames('signal-score-tile', {
            active: isChecked
        });

        return (
            <div
                id={ `${item.name.toLowerCase()}-signal-score` }
                className={ signalClassName }
                onClick={ this.handleSelection }
            >
                <Checkbox className="signal-score-check" value={ isChecked } />
                <span className="title">
                    { item.name }
                </span>
                <div className="description">
                    <span>
                        { item.description }
                    </span>
                </div>
            </div>
        );
    }
}
