import {
    ABOVE_VALUE_TEXT,
    BELOW_VALUE_TEXT,
    UNAVAILABLE
} from 'shared/constants';
import { NumeralUtils } from './Utils';

export default class FirmographicUtils {

    /**
     * Get the domain name by parsing naming that has natural language format
     * @param {string} name Special name format (ie: 'From $10,000 to $50,000')
     * @param {integer} index Item position in list
     * @param {boolean} isCurrency Whether or not using the currency format ($10M)
     * @returns {string} return domain name formatted:
     *      'below $10,000' -> 'below $10K'
     *      'From $10,000 to $10,000' -> '$10K - 50K
     *      'above $1,000,000' -> 'above $10K'
     */
    static getDomainNameByNaturalLanguage(name, index, isCurrency) {
        // get comma formatted numbers
        const numbersRegex = /(\d+[,.]*)+/g;
        const numbers = name.match(numbersRegex).map((item) => {
            // unformat them by replacing commas
            return item.replace(/,*/g, '');
        });

        let domainName;
        if (numbers && numbers.length === 1) {
            const preText = index ? ABOVE_VALUE_TEXT : BELOW_VALUE_TEXT;
            domainName =
                `${preText} ${FirmographicUtils.getFormattedNumber(numbers[0], isCurrency)}`;
        } else {
            domainName = `${FirmographicUtils.getFormattedNumber(numbers[0], isCurrency)}
                - ${FirmographicUtils.getFormattedNumber(numbers[1], isCurrency)}`;
        }

        return domainName;
    }

    /**
     * Get the domain name by parsing naming that has natural language format
     * @param {object} item Object with 'from' and 'to' range values
     *                  (add + 1 to the "to" field because of cosmetic consideration)
     * @param {object} options
     *          - index {integer} Item position in list
     *          - isCurrency {bool} Whether or not using the currency format ($10M)
     *          - totalItems {integer} items count in list
     * @returns {string} return formatted domain name.
     */
    static getDomainNameByItem(item, options) {
        let domainName;
        const format = FirmographicUtils.getFormattedNumber;
        const { isCurrency, index, totalItems } = options;
        if (index === 0) {
            // first item
            domainName = `${BELOW_VALUE_TEXT} ${format(item.to + 1, isCurrency)}`;
        } else if (totalItems === index + 1) {
            // last item
            domainName = `${ABOVE_VALUE_TEXT} ${format(item.from, isCurrency)}`;
        } else {
            domainName = `${format(item.from, isCurrency)} - ${format(item.to, isCurrency)}`;
        }

        return domainName;
    }

    static getDomainRangeByItem(item, options) {
        if (!item.from || !item.to) {
            return UNAVAILABLE;
        }

        return FirmographicUtils.getDomainNameByItem(item, options);
    }

    static getFormattedNumber(number, isCurrency, isLast) {
        let formattedNumber;
        if (number <= 999) {
            formattedNumber = NumeralUtils.formatIntegerNumber(number, isCurrency).toUpperCase();
        } else {
            formattedNumber = NumeralUtils.formatBigNumbers(number + 1, isCurrency).toUpperCase();
        }

        if (isLast) {
            formattedNumber = `${formattedNumber}+`;
        }

        return formattedNumber;
    }

    static getIntervalsIncludingBoundaries(data) {
        return [...data, data[data.length - 1] + 1];
    }

    static parseDomainUnformattedData(apiData, isCurrency) {
        // make sure data is properly sorted
        const data = apiData.sort((left, right) => {
            return left.from < right.from ? -1 : 1;
        });

        return data.map((item, index, array) => {
            return {
                value: item.value,
                name: FirmographicUtils.getDomainNameByItem(
                    item,
                    {
                        isCurrency,
                        index,
                        totalItems: array.length
                    })
            };
        });
    }

    static getInnerIntervalsIndexes(data, from, to) {
        const startIndex = data.findIndex((item) => {
            return item.from === from;
        });
        const endIndex = data.findIndex((item) => {
            return item.to === to - 1;
        });

        return {
            startIndex: startIndex >= 0 ? startIndex : 0,
            endIndex: endIndex >= 0 ? endIndex : data.length - 1,
        };
    }

    /**
     * Get the array data necessary for stacked bar charts
     * @param {array} raw data objects with 'from' and 'to' range values
     * @param {boolean} isInnerInterval data for inner interval range
     * @param {int} from Data interval to start with
     * @param {int} to Data interval to end with
     *          - index {integer} Item position in list
     *          - isCurrency {bool} Whether or not using the currency format ($10M)
     *          - totalItems {integer} items count in list
     * @returns {array} array objects with { name, value} keys for stacked bars
     */
    static getStackedBarData(data, isInnerInterval, from, to, isCurrency) {
        const domainData = FirmographicUtils.parseDomainUnformattedData(data, isCurrency);
        const innerIndexes = FirmographicUtils.getInnerIntervalsIndexes(data, from, to);
        return domainData.map((domain, index) => {
            const isInRange = index >= innerIndexes.startIndex && index <= innerIndexes.endIndex;
            return {
                name: domain.name,
                value: (isInnerInterval && isInRange) || (!isInnerInterval && !isInRange)
                    ? domain.value : 0
            };
        });
    }

    static getIndustriesStackedBarData(data, selectedIndustries, isIncluded) {
        return data.map((item) => {
            const isPresent = selectedIndustries.indexOf(item.name || item.industry) >= 0 ||
                selectedIndustries.length === 0;

            return {
                name: item.name || item.industry,
                value: (isPresent && isIncluded) || (!isPresent && !isIncluded) ? item.value : 0
            };
        });
    }
}
