import {
    MOVERS_MAKERS_REQUEST,
    MOVERS_MAKERS_SUCCESS,
    MOVERS_MAKERS_FAILURE,
    MOVERS_MAKERS_SHOW_MORE,
    MOVERS_MAKERS_SHOW_PER_ROW
} from './constants';

export const moversShakersInitialState = {
    isFetching: false,
    error: null,
    data: null,
    showTopCount: MOVERS_MAKERS_SHOW_PER_ROW * 3
};

/**
 * Fetches list of movers and shakers.
 * @param {object} action Action requested.
 * @returns {state} Reduced state for request, success or failure action types.
 */
export default function moversShakers(state = moversShakersInitialState, action) {
    switch (action.type) {
        case MOVERS_MAKERS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case MOVERS_MAKERS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data,
                error: null
            });

        case MOVERS_MAKERS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        case MOVERS_MAKERS_SHOW_MORE:
            return Object.assign({}, state, {
                showTopCount: state.showTopCount + MOVERS_MAKERS_SHOW_PER_ROW
            });

        default:
            return state;
    }
}
