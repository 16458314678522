import Symbol from 'es6-symbol';
import base64url from 'base64url';
import axios from 'axios';
import ApiWrapper from './ApiWrapper';
import { UrlUtils } from 'utils/Utils';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton class that encapsulates authentication related API calls
 */
export default class LoginService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new LoginService(singletonEnforcer);
        }
        return this[singleton];
    }

    /**
     * Login to discovery app
     * @param {string} HTTP Basic Authorization header
     * @returns {string} The bearer token needed for all subsequent operations.
     */
    login(loginData) {
        const tokenEncoded = btoa(unescape(
            encodeURIComponent(`${loginData.username}:${loginData.password}`)));
        const config = {
            headers: {
                Authorization: `Basic ${tokenEncoded}`
            },
            params: {
                timestamp: Date.now().toString()
            }
        };
        // prevent caching the request using timestamp ( this seems to be relieable on IE too )
        // Note: setting no-cache header will not be enough for some stupid browsers
        return ApiWrapper.instance.axios.get('account/login', config);
    }

    /**
     * Send a reset password request
     * @param {email} account email required to reset password for
     * @returns {object} The promise object of the api call.
     */
    forgotPassword(email) {
        const data = {
            email,
            verificationLink: `${UrlUtils.getHostUrl()}/forgot-password`
        };
        return ApiWrapper.instance.axios.post('account/password/forgot', data);
    }

    /**
     * Send a reset password request
     * @param {data} token, ttl
     * @returns {object} The promise object of the api call.
     */
    resetPassword(token, password) {
        const config = {
            headers: {
                Authorization: `Basic ${token}`
            }
        };
        return ApiWrapper.adminInstance.put(
            'account/password/confirm',
            { password },
            config);
    }

    /**
     * Send a secure auth data request
     * @param {authData} token, username and email stored as http only cookies
     * @returns {object} The promise object of the api call.
     */
    secureAuthData(authData) {
        return axios({
            method: 'post',
            url: '/secureAuthData',
            data: {
                authData: base64url.encode(JSON.stringify(authData))
            }
        });
    }

    /**
     * Send a logout request to node js service
     * This clears the auth data cookies
     * @returns {object} The promise object of the api call.
     */
    logout() {
        ApiWrapper.instance.removeAuthorizationHeader();
        return axios({
            method: 'post',
            url: '/logout'
        });
    }

    /**
     * Send a refresh token request to the api
     * @returns {object} The promise object of the api call.
     */
    refreshToken() {
        return ApiWrapper.instance.axios.get('token/refresh');
    }
}
