export const UNKNOWN_VALUES_ADD = 'UNKNOWN_VALUES_ADD';
export const UNKNOWN_VALUES_REMOVE = 'UNKNOWN_VALUES_REMOVE';
export const UNKNOWN_VALUES_RESET = 'UNKNOWN_VALUES_RESET';
export const UNKNOWN_VALUES_SET = 'UNKNOWN_VALUES_SET';
export const UNKNOWN_VALUES_TITLE = 'Include companies with unknown values';

export const UNKNOWN_VALUES_REVENUE_FILTER_NAME = 'revenue';
export const UNKNOWN_VALUES_EMPLOYEES_FILTER_NAME = 'employees';
export const UNKNOWN_VALUES_INDUSTRIES_FILTER_NAME = 'industries';
export const UNKNOWN_VALUES_LOCATIONS_FILTER_NAME = 'locations';

export const UNKNOWN_VALUES_OPTIONS = [
    {
        id: UNKNOWN_VALUES_REVENUE_FILTER_NAME,
        checked: true
    },
    {
        id: UNKNOWN_VALUES_EMPLOYEES_FILTER_NAME,
        checked: true
    },
    {
        id: UNKNOWN_VALUES_INDUSTRIES_FILTER_NAME,
        checked: true
    },
    {
        id: UNKNOWN_VALUES_LOCATIONS_FILTER_NAME,
        checked: true
    },
];
