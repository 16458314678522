import React from 'react';

export default class LeftPageWizardContent extends React.Component {

    renderBackButton() {
        if (this.props.onBack) {
            return (
                <span
                    className="back icon icon-arrow-left"
                    onClick={ this.props.onBack }
                    data-qa="back-button"
                >
                </span>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="left-page-wizard-content">
                { this.renderBackButton() }
                { this.props.content }
            </div>
        );
    }
}
