import React from 'react';
import { browserHistory } from 'react-router';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import {
    SALESFORCE_MATCH_FIRST_TITLE,
    SALESFORCE_MATCH_FIRST_TEXT,
    SALESFORCE_MATCH_FIRST_BUTTON
} from './constants';

export default class SalesforceMatchFirst extends React.Component {
    clickMatchButton() {
        browserHistory.push('/dashboard/import/salesforce-connect');
    }

    render() {
        return (
            <ImportPageContainerWrapper
                title={ SALESFORCE_MATCH_FIRST_TITLE }
            >
                <div className="match-first">
                    <p className="match-first-text">
                        { SALESFORCE_MATCH_FIRST_TEXT }
                    </p>
                    <button
                        className="btn btn-primary match-first-button"
                        onClick={ this.clickMatchButton.bind(this) }
                    >
                        { SALESFORCE_MATCH_FIRST_BUTTON }
                    </button>
                </div>
            </ImportPageContainerWrapper>
        );
    }
}
