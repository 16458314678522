import React from 'react';
import { browserHistory } from 'react-router';
import onClickOutside from 'react-onclickoutside';
import { HIDDEN, VISIBLE } from 'shared/constants';
import { downloadAsCSV } from 'myhg/segment/actions';
import Loader from 'shared/ui/loader/Loader';
import DownloadLimitModal from 'myhg/segment/components/DownloadLimitModal';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import UserUtils from 'utils/UserUtils';

class SegmentExportTo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isArrowDown: false,
            dropDown: HIDDEN,
            modalOpen: false
        };
    }

    onContinueDownload = () => {
        const { dispatch } = this.props;

        dispatch(downloadAsCSV());

        track(ACTION.EXPORT_SEGMENT_CSV, {
            label: this.props.currentSegment.name
        });

        this.closeModal();
    }

    onCSVExport = () => {
        const { isUserAuthenticated, downloadLimit } = this.props;

        if (isUserAuthenticated) {
            if (downloadLimit >= 0) {
                this.setState({ modalOpen: true });
            } else {
                this.onContinueDownload();
            }
        } else {
            browserHistory.push('/register');
        }
    }

    getContent = () => {
        const { inProgress } = this.props.segmentExport;
        const {
            accountDetails, isUserAuthenticated, segmentTable: { matchesDataLoad }
        } = this.props;
        const hasNoData = !matchesDataLoad.data.length;
        const isPaid = UserUtils.isPaid(accountDetails);
        const dldNotEnabled =
            // user is Paid but has download flag disabled
            (accountDetails && !accountDetails.downloadEnabled && isPaid)
            // user is not Paid
            || !isPaid;

        if (!isUserAuthenticated || dldNotEnabled || hasNoData) {
            return null;
        } else if (inProgress) {
            return (
                <div className="segment-export-to">
                    <Loader className="export-loader medium-loader" />
                    <span className="message">
                        Please wait for download to start...
                    </span>
                </div>
            );
        } else if (this.props.currentSegment.id) {
            return (
                <div className="segment-export-to">
                    <a onClick={ this.onCSVExport }
                        key="button"
                        data-qa="export-csv"
                    >
                        Export CSV
                        <span className="icon icon-download" />
                    </a>
                </div>
            );
        }

        return null;
    }

    closeModal = () => {
        this.setState({ modalOpen: false });
    };

    handleClickOutside = () => {
        this.setState({ dropDown: HIDDEN });
    };

    openDropDownIfHidden = () => {
        if (this.state.dropDown === HIDDEN) {
            this.setState({ dropDown: VISIBLE });
        }
    };

    render() {
        const content = this.getContent();

        if (content || this.props.downloadLimit >= 0) {
            return (
                <div className="col-12 col-lg-4">
                    { content }
                    { this.props.downloadLimit >= 0 ? (
                        <DownloadLimitModal
                            { ...this.props }
                            open={ this.state.modalOpen }
                            onPrimaryActionClick={ this.onContinueDownload }
                            onCloseActionClick = { this.closeModal }
                        />
                    ) : null }
                </div>
            );
        }

        return null;
    }
}

export default onClickOutside(SegmentExportTo);
