import React from 'react';
import { Link } from 'react-router';
import { TechnologyIcon } from 'shared/ui/technologyIcon/TechnologyIcon';

export default class TechnologyList extends React.Component {

    render() {
        const { technologies } = this.props;

        const technologyList = technologies.map((technology, index) => {
            return (
                <li key={ index }>
                    <TechnologyIcon technology= { technology } />
                    <Link
                        className="default-link"
                        to={ `/product/${technology.slug}` }
                    >
                        { technology.product }
                    </Link>
                </li>
            );
        });

        return (
            <ul className="generic-panel-list collection-technology-list">
                { technologyList }
            </ul>
        );
    }
}
