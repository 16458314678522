import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import sprintf from 'sprintf-js';
import ModalWizardWrapper from 'shared/ui/modalWizard/ModalWizardWrapper';
import segmentEnforcer from 'myhg/segment/segmentEnforcer';
import PlatformGlobalError from './PlatformGlobalError';
import Loader from 'shared/ui/loader/Loader';
import { setPlatformGlobalError } from './actions';
import {
    GLOBAL_PLATFORM_ERROR_TITLE,
    GENERIC_PLATFORM_NAME
} from './constants';
import { MATCH_SOURCE_NAMES } from 'myhg/segment/constants';

function mapStateToProps(state) {
    return {
        currentSegment: state.myhg.segment.currentSegment,
        platformGlobalError: state.myhg.platformGlobalError
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@segmentEnforcer
@connect(mapStateToProps, mapDispatchToProps)

/**
 * Salesforce Export Page Wrapper
 */
export default class PlatformGlobalErrorContainer extends React.Component {

    componentDidMount() {
        const { platformGlobalError } = this.props;

        if (!platformGlobalError.error) {
            browserHistory.push('/dashboard');
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        // clear out error
        dispatch(setPlatformGlobalError(null));
    }

    onClose = () => {
        browserHistory.push('/dashboard');
    }

    render() {
        const { platformGlobalError, currentSegment } = this.props;
        const { source } = currentSegment.data.status;
        const title = sprintf.sprintf(
            GLOBAL_PLATFORM_ERROR_TITLE, MATCH_SOURCE_NAMES[source] || GENERIC_PLATFORM_NAME);

        return (
            <ModalWizardWrapper
                title={ title }
                onClose={ this.onClose }
                onBack={ this.onClose }
            >
                { !platformGlobalError.error ?
                    <Loader /> :
                    (
                        <PlatformGlobalError
                            source={ source }
                            onGoBack={ this.onClose }
                            error={ platformGlobalError.error }
                        />
                    )
                }
            </ModalWizardWrapper>
        );
    }
}
