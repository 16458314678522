import React from 'react';
import { connect } from 'react-redux';
import RecordTypeSelection from 'myhg/import/accounts/RecordTypeSelection';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import {
    ELOQUA_ACCOUNTS_PAGE_TITLE,
    ELOQUA_SUMMARY_LOAD_1,
    ELOQUA_SUMMARY_LOAD_2
} from './constants';
import accountsStatusHandler from 'myhg/import/accounts/accountsStatusHandler';
import {
    ACCOUNT_TYPES
} from 'myhg/import/accounts/constants';
import {
    MATCH_SOURCE_TYPE
} from 'myhg/segment/constants';
import { PLATFORM_SOURCE } from 'shared/constants';
import { setRecordsType } from 'myhg/import/accounts/actions';

function mapStateToProps(state) {
    return {
        accountDetails: state.user.accountDetails.data,
        segmentId: state.myhg.segment.currentSegment.id,
        authData: state.authentication.authData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const platformRecordTypes = [ACCOUNT_TYPES.ELOQUA_CONTACT];

@accountsStatusHandler(platformRecordTypes, MATCH_SOURCE_TYPE.ELOQUA)
@connect(mapStateToProps, mapDispatchToProps)

export default class EloquaAccountsContainer extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;
        // since there is only one selection available set as default
        dispatch(setRecordsType(platformRecordTypes));
    }

    render() {
        const content = (
            <div className="import-platform-accounts">
                <div className="summary">
                    { ELOQUA_SUMMARY_LOAD_1 }
                    <strong>{ this.props.authData.salesforceUsername }</strong>
                    { ELOQUA_SUMMARY_LOAD_2 }
                </div>
                <RecordTypeSelection
                    { ...this.props }
                />
            </div>
        );
        const customHeaderTitle = (
            <span>
                { ELOQUA_ACCOUNTS_PAGE_TITLE }
                <span className={ `platform-title-logo ${PLATFORM_SOURCE.ELOQUA}-img` } />
            </span>
        );

        return (
            <ImportPageContainerWrapper
                title={ ELOQUA_ACCOUNTS_PAGE_TITLE }
                customHeader={ customHeaderTitle }
            >
                { content }
            </ImportPageContainerWrapper>
        );
    }
}
