import UserService from 'common/services/UserService';
import {
    OPEN_SEARCH_PRODUCT,
    CLOSE_SEARCH_PRODUCT,
    SELECT_SEARCH_COLLECTION,
    FILTER_SEARCH_PRODUCT,
    SUGGEST_PRODUCT_REQUEST,
    SUGGEST_PRODUCT_SUCCESS,
    SUGGEST_PRODUCT_FAILURE,
    SUGGEST_PRODUCT_RESET
} from './constants';

export function openSearchProduct(source) {
    return {
        type: OPEN_SEARCH_PRODUCT,
        source
    };
}

export function closeSearchProduct(source) {
    return {
        type: CLOSE_SEARCH_PRODUCT,
        source
    };
}

export function setSelectedCollection(source, collection) {
    return {
        type: SELECT_SEARCH_COLLECTION,
        source,
        collection
    };
}

export function searchProductByFilter(text) {
    return {
        type: FILTER_SEARCH_PRODUCT,
        filterText: text
    };
}

export function createSuggestTechnologyEvent(technologyName) {
    return (dispatch) => {
        dispatch({
            type: SUGGEST_PRODUCT_REQUEST,
            technologyName
        });

        UserService.instance.suggestTechnology(technologyName)
            .then(() => {
                dispatch({
                    type: SUGGEST_PRODUCT_SUCCESS
                });
            })
            .catch((error) => {
                dispatch({
                    type: SUGGEST_PRODUCT_FAILURE,
                    error
                });
            });
    };
}

export function resetTechnologySuggest() {
    return {
        type: SUGGEST_PRODUCT_RESET
    };
}
