import {
    LOAD_SIMILAR_PRODUCTS_REQUEST,
    LOAD_SIMILAR_PRODUCTS_SUCCESS,
    LOAD_SIMILAR_PRODUCTS_FAILURE,
} from './constants';

export const initialSimilarProductsState = {
    isFetched: false,
    error: null,
    data: []
};

export default function similarProducts(state = initialSimilarProductsState, action) {
    switch (action.type) {
        case LOAD_SIMILAR_PRODUCTS_REQUEST:
            return Object.assign({}, state, {
                isFetched: false,
                error: null
            });

        case LOAD_SIMILAR_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                isFetched: true,
                error: null,
                data: action.data
            });

        case LOAD_SIMILAR_PRODUCTS_FAILURE:
            return Object.assign({}, state, {
                isFetched: false,
                error: action.error
            });

        default:
            return state;
    }
}
