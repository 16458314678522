import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        authData: state.authentication.authData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

// Use this whenever you are exposing your modals to the router
export default class SecureRouteHandler extends React.Component {

    static contextTypes = {
        router: PropTypes.object.isRequired
    }

    componentWillMount() {
        // whenever user tries to access a page/route that requires be auth or not
        // simply get user to the home page for now
        if (this.isAccessRestricted()) {
            this.context.router.push('/');
        }
    }

    isAccessRestricted() {
        return (this.props.route.state.authenticationRequired && !this.props.authData.token)
            || (this.props.route.state.unauthenticationRequired && this.props.authData.token);
    }

    render() {
        return this.props.children;
    }
}
