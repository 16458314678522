/* eslint-disable max-len */
import React from 'react';
import {
    LINKEDIN_BASE_URL,
    LINKEDIN_CONNECT_NOTE,
    LINKEDIN_RESPONSE_TYPE_QUERY_PARAM,
    LINKEDIN_SCOPE_PARAM
} from '../constants';

export default class LinkedInConnectWrapper extends React.Component {

    getLinkedInURL() {
        return `${LINKEDIN_BASE_URL}${LINKEDIN_RESPONSE_TYPE_QUERY_PARAM}&client_id=${process.env.LINKED_IN_CLIENT_ID}&redirect_uri=${process.env.LINKED_IN_REDIRECT_URL}?segmentId=${this.props.segmentId}&${LINKEDIN_SCOPE_PARAM}`;
    }

    render() {
        return (
            <div className="platform-connect-content">
                <ol>
                    <li>
                        Access a list of your Campaign Manager Accounts
                    </li>
                    <li>
                        Add an uploaded list from HG Insights segment to your selected Campaign Manager Account
                    </li>
                </ol>
                <span className="note-message">{ LINKEDIN_CONNECT_NOTE }</span>
                <a
                    className="btn btn-primary linkedin-connect-btn"
                    href={ this.getLinkedInURL() }
                >
                    Connect
                </a>
            </div>
        );
    }
}
