import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton class that encapsulates user related API calls
 */
export default class UserService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new UserService(singletonEnforcer);
        }
        return this[singleton];
    }

    /**
     * Get the user details.
     * @returns {object} The promise object of the api call.
     */
    getUserDetails() {
        return ApiWrapper.instance.axios.get('account');
    }

    sendContactEmail(contactData) {
        const contactRequest = {
            senderEmail: contactData.username,
            subject: contactData.subject,
            message: contactData.message
        };
        return ApiWrapper.instance.axios.post(
            'account/contact',
            contactRequest
        );
    }

    suggestTechnology(technology) {
        return ApiWrapper.instance.axios.put(
            'account/suggest',
            { technology }
        );
    }

    addPhoneNumber(phoneNumber) {
        return ApiWrapper.instance.axios.put(
            'account',
            { phoneNumber }
        );
    }

    getPermissions() {
        return ApiWrapper.instance.axios.get('account/limits');
    }

    getTechnologiesPermissions() {
        return ApiWrapper.instance.axios.get('account/permissions/slugs');
    }

    getUserPreferences() {
        return ApiWrapper.instance.axios.get('account/userPreferences');
    }

    putUserPreferences(userPreferences) {
        return ApiWrapper.instance.axios.put(
            'account/userPreferences',
            userPreferences
        );
    }

    startUserTrial() {
        return ApiWrapper.instance.axios.put('account/trial');
    }
}
