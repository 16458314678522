// import { setupAuthenticationData } from 'discovery/authenticationModal/actions';
// import {
//     PARDOT_AUTHORIZE,
//     PARDOT_CONNECT_REQUEST,
//     PARDOT_CONNECT_SUCCESS,
//     PARDOT_CONNECT_ERROR
// } from './constants';
// import PardotService from 'common/services/PardotService';

// export function pardotConnect(connectData, segmentId) {
//     return (dispatch) => {
//         dispatch({
//             type: PARDOT_CONNECT_REQUEST
//         });

//         return PardotService.instance.connect(connectData, segmentId)
//             .then((response) => {
//                 dispatch({
//                     type: PARDOT_CONNECT_SUCCESS
//                 });

//                 // set new authentication data to reflect new platform auth
//                 return dispatch(setupAuthenticationData(
//                     response.data,
//                     PARDOT_AUTHORIZE
//                 ));
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: PARDOT_CONNECT_ERROR,
//                     error
//                 });

//                 throw error;
//             });
//     };
// }

// TODO: remove when integrating api
export function pardotConnect() {
    return () => {
        return Promise.resolve({});
    };
}
