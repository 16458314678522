import React from 'react';
import { browserHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import onClickOutside from 'react-onclickoutside';
import {
    LINK_LOG_OUT,
    LOG_OUT_HEADER_TOOLTIP,
    SEGMENTS_LINK
} from './constants';

class HeaderDropDown extends React.Component {
    onDashboardClick() {
        browserHistory.push('/dashboard');
    }

    getLogOutLink() {
        return (
            <li
                id="logout"
                className="btn btn-link extended"
                onClick={ this.props.onLogout }
            >
                <span
                    className="item"
                    data-tip
                    data-multiline
                    data-delay-show="400"
                    data-for={ this.props.tooltipId }
                    data-qa="logout-dropdown"
                >
                    { LINK_LOG_OUT }
                </span>
                <ReactTooltip
                    effect="float"
                    id={ this.props.tooltipId }
                >
                    <span>{ LOG_OUT_HEADER_TOOLTIP }</span>
                </ReactTooltip>
            </li>
        );
    }

    getSegmentsLink() {
        return (
            <li
                id="dashboard-dropdown"
                className="btn btn-link extended"
                onClick={ this.onDashboardClick }
            >
                <span className="item" data-qa="dashboard-dropdown">{ SEGMENTS_LINK }</span>
            </li>
        );
    }

    handleClickOutside = () => {
        this.props.handleClickOutSide();
    };

    render() {
        return (
            <div className="user-logout">
                <p className="btn-link"
                    onClick={ this.props.toggleDropDown }
                >
                    <span className="item username" data-qa="dropdown">
                        { this.props.authData.fullName }
                    </span>
                    <span className="panel-icon icon-small-arrow-down">
                    </span>
                </p>
                <ul className={ this.props.className }>
                    { this.props.children }
                    { this.getSegmentsLink() }
                    { this.getLogOutLink() }
                </ul>
            </div>
        );
    }
}

export default onClickOutside(HeaderDropDown);
