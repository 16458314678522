/* eslint-disable import/no-namespace */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { loadSegment } from './actions';

export default function (ComposedComponent) {
    class SegmentRequirement extends React.Component {
        static contextTypes = {
            router: PropTypes.object
        }

        componentWillMount() {
            const { segmentId } = this.props;
            if (!segmentId && this.getSegmentFromPath()) {
                this.context.router.push('/dashboard');
            }
        }

        componentDidMount() {
            const { segmentId } = this.props;
            if (!segmentId && this.getSegmentFromPath()) {
                this.context.router.push('/dashboard');
            }
        }

        getSegmentFromPath = () => {
            const { segmentId } = this.props.location.query;

            if (!!segmentId) {
                this.props.dispatch(loadSegment(segmentId));
            }

            return !segmentId;
        };

        render() {
            const { segmentId } = this.props;
            // the component will still be redered on server side...
            if (!segmentId) {
                return null;
            }

            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return { segmentId: state.myhg.segment.currentSegment.id };
    }

    function mapDispatchToProps(dispatch) {
        return {
            dispatch
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(SegmentRequirement);
}
