export const POPULAR_TECHNOLOGIES_REQUEST = 'POPULAR_TECHNOLOGIES_REQUEST';
export const POPULAR_TECHNOLOGIES_SUCCESS = 'POPULAR_TECHNOLOGIES_SUCCESS';
export const POPULAR_TECHNOLOGIES_FAILURE = 'POPULAR_TECHNOLOGIES_FAILURE';
export const POPULAR_TECHNOLOGIES_LIST = [
    'salesforce-crm',
    'amazon-aws',
    'tableau',
    'magento',
    'vmware-vcloud',
    'dell-emc-san-storage-area-network'
];
