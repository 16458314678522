import React from 'react';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';

export default class PardotConnectOptions extends React.Component {

    render() {
        return (
            <div className="pardot-connect-actions">
                <FormButtonWrapper>
                    <input
                        type="button"
                        className="btn btn-primary"
                        onClick={ this.props.goToNextStep }
                        defaultValue="Continue With This Account"
                    />
                </FormButtonWrapper>
                <p>
                    or
                </p>
                <input
                    type="button"
                    className="btn btn-link"
                    onClick={ this.props.onSwitchAccount }
                    defaultValue="Switch Pardot Account"
                />
            </div>
        );
    }
}
