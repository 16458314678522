import React from 'react';
import ReactDom from 'react-dom';
import classNames from 'classnames';

export default class SearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        };

        this.changeInputValue = this.handleTyping.bind(this);
    }

    componentDidMount() {
        if (this.props.autoFocus) {
            const searchInputDOM = ReactDom.findDOMNode(this.refs.searchInput);
            if (searchInputDOM !== null) {
                searchInputDOM.focus();
            }
        }
    }

    componentWillUpdate(nextProps) {
        // clear input when typeahead is closed
        if (!nextProps.isOpen && this.props.isOpen) {
            this.setState({
                inputValue: ''
            });
        }
    }

    clearText() {
        this.setState({
            inputValue: ''
        });
    }

    handleTyping(ev) {
        this.setState({
            inputValue: ev.target.value
        });
        this.props.onSearchTextChange(ev.target.value);
    }

    handleClearClick = () => {
        this.props.onClearInputSelection();
    }

    render() {
        const className = classNames('search-form',
            { open: this.props.isOpen }
        );
        return (
            <div className={ className }>
                <span className="search-icon icon-search" />
                <input
                    placeholder={ this.props.placeholder ? this.props.placeholder : '' }
                    ref="searchInput"
                    className="search-input"
                    value= { this.props.inputSelection || this.state.inputValue }
                    onChange={ this.changeInputValue }
                    type="text"
                    data-qa="search-bar"
                />
                {
                    this.props.inputSelection ? (
                        <span
                            id="selection-clear-input"
                            className="clear-icon icon-close-x"
                            onClick={ this.handleClearClick }
                        />
                    ) : null
                }
            </div>
        );
    }
}
