import React from 'react';
import { connect } from 'react-redux';
import Loader from 'shared/ui/loader/Loader';
import EloquaConnect from './components/EloquaConnect';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import {
    ELOQUA_CONNECT_PAGE_TITLE
} from './constants';
import {
    validatePlatformToken
} from 'myhg/platformConnection/actions';
import { PLATFORM_SOURCE } from 'shared/constants';
import segmentEnforcer from 'myhg/segment/segmentEnforcer';

function mapStateToProps(state) {
    return {
        segmentId: state.myhg.segment.currentSegment.id,
        authData: state.authentication.authData,
        platformToken: state.myhg.platformConnection.platformToken
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@segmentEnforcer
@connect(mapStateToProps, mapDispatchToProps)

export default class EloquaConnectContainer extends React.Component {

    componentDidMount() {
        this.props.dispatch(validatePlatformToken());
    }

    render() {
        let content = <Loader />;

        const platformTokenData = this.props.platformToken.data;
        const { authData } = this.props;

        const customHeaderTitle = (
            <span>
                { ELOQUA_CONNECT_PAGE_TITLE }
                <span className={ `platform-title-logo ${PLATFORM_SOURCE.ELOQUA}-img` } />
            </span>
        );

        if (platformTokenData) {
            const isValidConnection = !!platformTokenData.valid &&
                authData.platformSource === PLATFORM_SOURCE.ELOQUA;
            content = (
                <EloquaConnect
                    { ...this.props }
                    isValidConnection={ isValidConnection }
                />
            );
        }

        return (
            <ImportPageContainerWrapper
                title={ ELOQUA_CONNECT_PAGE_TITLE }
                customHeader={ customHeaderTitle }
            >
                { content }
            </ImportPageContainerWrapper>
        );
    }
}
