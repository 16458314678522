import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';
import { UrlUtils } from 'utils/Utils';
import LocalStorage from 'utils/LocalStorage';

const singleton = Symbol();
const singletonEnforcer = Symbol();
const localStorageRegistrationDataIdentifier = 'registrationProgress';

/**
 * Singleton class that encapsulates authentication related API calls
 */
export default class RegistrationService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new RegistrationService(singletonEnforcer);
        }
        return this[singleton];
    }

    static getRegistrationProgress() {
        return LocalStorage.getItemById(
            localStorageRegistrationDataIdentifier,
            true);
    }

    static saveRegistrationProgress(registrationData) {
        if (!registrationData) {
            LocalStorage.deleteItemById(localStorageRegistrationDataIdentifier);
            return null;
        }

        const registrationProgress = {
            timestamp: new Date().getTime(),
            registrationData
        };
        return LocalStorage.saveItemById(
            localStorageRegistrationDataIdentifier,
            registrationProgress,
            true);
    }

    /**
     * Create new discovery account
     * @param {string} productName The roduct name.
     * @param {string} slug - product id user came from.
     * @returns {object} The promise object of the api call.
     */
    createAccount(registrationData, verificationLink = '') {
        const registrationRequest = {
            companyDetails: {
                name: registrationData.companyName,
                type: 'discovery'
            },
            accountDetails: {
                username: registrationData.email,
                firstName: registrationData.firstName,
                lastName: registrationData.lastName,
                password: registrationData.password,
                phoneNumber: registrationData.phoneNumber
            },
            verificationLink
        };

        return ApiWrapper.instance.axios.post(
            'account/register',
            JSON.stringify(registrationRequest)
        );
    }

    /**
     * Complete registration
     * @param {confirmationToken} token extracted from link
     * @returns {object} The promise object of the api call.
     */
    confirmRegistration(token) {
        // set the token on the auth header
        return ApiWrapper.instance.axios.get('account/register/confirm', {
            headers: {
                Authorization: token
            }
        });
    }

    /**
     * Send a reset password email request
     * @param {confirmationToken} token extracted from link
     * @returns {object} The promise object of the api call.
     */
    forgotPassword(email) {
        const data = {
            email,
            verificationLink: `${UrlUtils.getHostUrl()}/reset-password`
        };
        return ApiWrapper.instance.axios.post('account/password/forgot', JSON.stringify(data));
    }

    /**
     * Send a reset password confirm
     * @param {password} user's new password
     * @param {confirmationToken} token extracted from link
     * @returns {object} The promise object of the api call.
     */
    resetPasswordConfirm(password, confirmationToken) {
        const data = {
            password
        };
        return ApiWrapper.instance.axios
            .put('account/password/forgot/confirm', JSON.stringify(data), {
                headers: {
                    Authorization: confirmationToken
                }
            });
    }
}
