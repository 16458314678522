import { combineReducers } from 'redux';
import {
    FILTER_SEARCH_PRODUCT,
    SELECT_SEARCH_COLLECTION,
    OPEN_SEARCH_PRODUCT,
    CLOSE_SEARCH_PRODUCT,
    SUGGEST_PRODUCT_REQUEST,
    SUGGEST_PRODUCT_SUCCESS,
    SUGGEST_PRODUCT_FAILURE,
    SUGGEST_PRODUCT_RESET,
    SEARCH_LIST_LIMIT
} from './constants';

// currentFilter reducer

const currentFilterInitialState = '';

export function currentFilter(state = currentFilterInitialState, action) {
    switch (action.type) {
        case FILTER_SEARCH_PRODUCT:
            return action.filterText;
        default:
            return state;
    }
}

// searchBySource reducer

const searchBySourceInitialState = {};

function searchState(state = {}, action) {
    switch (action.type) {
        case OPEN_SEARCH_PRODUCT:
            return Object.assign({}, state, {
                isOpen: true
            });
        case SELECT_SEARCH_COLLECTION:
            return Object.assign({}, state, {
                selectedCollection: action.collection
            });
        case CLOSE_SEARCH_PRODUCT:
            return false;
        default:
            return state;
    }
}

export function searchBySource(state = searchBySourceInitialState, action) {
    switch (action.type) {
        case CLOSE_SEARCH_PRODUCT:
        case SELECT_SEARCH_COLLECTION:
        case OPEN_SEARCH_PRODUCT:
            if (action.source) {
                return Object.assign({}, state, {
                    [action.source]: searchState(state[action.source], action)
                });
            }
            return false;
        default:
            return state;
    }
}

// suggest reducer

const suggestInitialState = {
    technologyName: '',
    inProgress: false,
    isSent: false,
    error: null
};

export function suggest(state = suggestInitialState, action) {
    switch (action.type) {
        case SUGGEST_PRODUCT_REQUEST:
            return Object.assign({}, state, {
                technologyName: action.technologyName,
                inProgress: true,
                error: null
            });

        case SUGGEST_PRODUCT_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                error: null,
                isSent: true
            });

        case SUGGEST_PRODUCT_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                isSent: true,
                error: action.error
            });

        case SUGGEST_PRODUCT_RESET:
            return suggestInitialState;

        default:
            return state;
    }
}


// getSortedFilteredTechnologies helpers

/**
 * Filter items and apply a sorting handler
 * @param {object} options
 *          - technologies {array} item list to filter against
 *          - filterText {string} text to search for
 *          - top {int} top items to match the search criteria
 * @param {function} sortHandler
 * @returns {object} top items sorted by provided sorting handler
 */
const filterItems = (options, sortHandler) => {
    const { filterText, technologies, excludeTechnologies } = options;
    const excludedSlugs = excludeTechnologies.map((item) => {
        return item.slug;
    });

    let items;

    if (!filterText) {
        items = technologies;
    } else {
        items = technologies.filter((item) => {
            let result = false;
            // Should not be excluded
            if (excludedSlugs.indexOf(item.slug) === -1) {
                result = item.product.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
            }
            return result;
        }).sort(sortHandler);
    }

    return items;
};

const findItemInList = (list, item) => {
    return !!list.find((current) => {
        return current === item;
    });
};

export function getSortedTechnologiesByPermissions(technologies, allowedSlugs) {
    return technologies.sort((left, right) => {
        const foundLeft = findItemInList(allowedSlugs, left.slug || left);
        const foundRight = findItemInList(allowedSlugs, right.slug || right);
        if (foundLeft === foundRight) {
            return 0;
        } else if (foundLeft) {
            return -1;
        }
        return 1;
    });
}

export function getSortedFilteredTechnologiesByInstalls(options, allowedSlugs) {
    let items = filterItems(options, (left, right) => {
        return left.installs < right.installs ? 1 : -1;
    });

    if (allowedSlugs) {
        items = getSortedTechnologiesByPermissions(items, allowedSlugs);
    }

    return ({
        matched: items.length,
        technologies: items.slice(0, options.top || SEARCH_LIST_LIMIT)
    });
}

export function getSortedFilteredCollections(options) {
    const { filterText, collections, top } = options;
    const filter = filterText.toLowerCase();

    const items = collections.filter((item) => {
        return item.name.toLowerCase().indexOf(filter) >= 0;
    }).sort((a, b) => {
        return a.name.toLowerCase().indexOf(filter) <
            b.name.toLowerCase().indexOf(filter) ? 1 : -1;
    });

    return {
        matched: items.length,
        collections: items.slice(0, top || SEARCH_LIST_LIMIT)
    };
}

// exports

export const initialState = {
    currentFilter: currentFilterInitialState,
    searchBySource: searchBySourceInitialState,
    suggest: suggestInitialState
};

export default combineReducers({
    currentFilter,
    searchBySource,
    suggest
});
