export const COLLECTIONS_REQUEST = 'COLLECTIONS_REQUEST';
export const COLLECTIONS_SUCCESS = 'COLLECTIONS_SUCCESS';
export const COLLECTIONS_FAILURE = 'COLLECTIONS_FAILURE';
export const COLLECTIONS_SHOW_NEXT = 'COLLECTIONS_SHOW_NEXT';
export const COLLECTIONS_TOGGLE_NOTIFICATON_BOX = 'COLLECTIONS_TOGGLE_NOTIFICATON_BOX';

export const COLLECTIONS_TOP_PRODUCT_COLLECTIONS = 3;
export const COLLECTION_PRODUCTS_TITLE = 'Products';
export const COLLECTION_COMPANIES_TITLE = 'Companies';
export const COLLECTION_PANEL_TITLE = 'Collections related to %1$s';
export const COLLECTION_TOOLTIP = 'Collections are groupings of related ' +
    'products by category or vendor. You can subscribe to one or more Collections.';
export const COLLECTION_VIEW_DETAILS = 'view list';
export const COLLECTIONS_SHOW_NEXT_TEXT =
    'Load Next %1$s Collections';
