import React from 'react';
import sprintf from 'sprintf-js';
import Typeahead from 'shared/ui/typeahead/Typeahead';
import { parseCampaignsforDropdown } from 'myhg/export/reducer';
import { selectExistingCampaign } from 'myhg/export/actions';
import Loader from 'shared/ui/loader/Loader';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import { EXPORT_CONFIGS, EXPORT_SUB_NOTE } from '../constants';
import { MATCH_SOURCE_NAMES } from 'myhg/segment/constants';

export default class ExistingCampaigns extends React.Component {

    onSelectItem(item) {
        this.props.dispatch(selectExistingCampaign(item));
    }

    onSaveClick() {
        const { platformExport, onSaveExistingCampaignSubmit } = this.props;

        onSaveExistingCampaignSubmit(platformExport.selectedCampaign);

        track(ACTION.CREATE_CAMPAIGN_SAVE, {
            label: 'Existing Campaign'
        });
    }

    getError() {
        const { saveCampaign } = this.props.platformExport;

        return (
            <div className="save-error note-message">
                Error: { saveCampaign.error.message }
            </div>
        );
    }

    getPlatformName() {
        const { currentSegment } = this.props;
        const { data: { status: { source } } } = currentSegment;

        return MATCH_SOURCE_NAMES[source];
    }

    getActionButton() {
        const { saveCampaign } = this.props.platformExport;

        const actionButton = (
            <button
                id="save-campaign"
                className="btn btn-primary"
                onClick={ this.onSaveClick.bind(this) }
                disabled={ this.isSaveDisabled() }
                data-qa="save-campaign"
            >
                Save to { this.getPlatformName() }
                { saveCampaign.isLoading ? <Loader className="small-loader white" /> : '' }
            </button>
        );

        return actionButton;
    }

    getSavingPleaseWait() {
        const { isLoading } = this.props.platformExport.saveCampaign;
        let content = null;

        if (isLoading) {
            content = (
                <p>
                    Exporting to { this.getPlatformName() }... please wait.<br />
                    (Note: This may take a few moments)
                </p>
            );
        }

        return content;
    }

    isSaveDisabled() {
        const { platformExport } = this.props;
        const { isLoading } = platformExport.saveCampaign;

        return isLoading || !platformExport.selectedCampaign;
    }

    render() {
        const { platformExport, currentSegment } = this.props;
        const { data: { status: { source } } } = currentSegment;
        const campaigns = platformExport.existingCampaigns.data;
        const selectedCampaign = platformExport.selectedCampaign;
        const typeaheadCampaigns = parseCampaignsforDropdown(campaigns);

        let errorContent = null;
        if (platformExport.saveCampaign.error) {
            errorContent = this.getError();
        }

        return (
            <div>
                <div className="action-content">
                    <Typeahead
                        inputPlaceholderText={ EXPORT_CONFIGS[source].SEARCH_INPUT }
                        searchData={ typeaheadCampaigns }
                        onSelectItem={ this.onSelectItem.bind(this) }
                        selectedItem={ selectedCampaign }
                        isSelectInput
                    />
                    <span className="note">
                        { sprintf.sprintf(EXPORT_SUB_NOTE, this.getPlatformName()) }
                    </span>
                    { errorContent }
                    <div>
                        { this.getActionButton() }
                    </div>
                </div>
                { this.getSavingPleaseWait() }
            </div>
        );
    }
}
