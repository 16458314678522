export const TRIAL_STATUS = {
    TRIAL_NOT_STARTED: 'TRIAL_NOT_STARTED',
    TRIAL_STARTED: 'TRIAL_STARTED',
    TRIAL_EXPIRED: 'TRIAL_EXPIRED'
};
export const TRIAL_NOTICE_TITLE = {
    TRIAL_NOT_STARTED: 'Experience Technographics in Your Platforms',
    TRIAL_STARTED: 'Your Test Drive Has Started: %1$s Days Remaining',
    TRIAL_EXPIRED: 'Thank You For Trying HG Discovery'
};
export const TRIAL_NOTICE_DESCRIPTION = {
    TRIAL_NOT_STARTED: {
        TEXT: `Hi %1$s. Try HG Discovery for 10 days, 
            create up to 10 segments and compare them to your accounts. `,
        LINK: 'Get Started Here'
    },
    TRIAL_STARTED: {
        TEXT: `Schedule a demo with one of our reps to see how you can access 
            Technographics directly in Salesforce. `,
        LINK: 'Schedule Demo'
    },
    TRIAL_EXPIRED: {
        TEXT: `Schedule a demo with one of our reps to see how you can access 
            Technographics directly in Salesforce. `,
        LINK: 'Schedule Demo'
    }
};
export const START_TRIAL = 'START_TRIAL';
export const START_TRIAL_SUCCESS = 'START_TRIAL_SUCCESS';
export const START_TRIAL_ERROR = 'START_TRIAL_ERROR';
