import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import ValidationField from 'shared/ui/formFields/ValidationField';
import Validation from 'utils/Validation';
import { UrlUtils } from 'utils/Utils';
import Loader from 'shared/ui/loader/Loader';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';

export const fields = ['email'];

const validate = values => {
    // TODO implement validator utilities for required, minimum length, email, etc..
    const errors = {};

    if (!values.email) {
        errors.email = 'Required';
    } else if (!Validation.validateEmail(values.email)) {
        errors.email = 'Invalid email address';
    }

    return errors;
};

class ForgotPasswordForm extends React.Component {

    getError(error) {
        if (!error) {
            return null;
        }

        return (
            <p className="text-content error">
                { error.message }
            </p>
        );
    }

    getFormContent() {
        const { requestSuccess } = this.props;
        let content = null;
        if (!requestSuccess) {
            content = this.getForgotPasswordInputs();
        } else {
            content = this.getSuccessContent();
        }
        return content;
    }

    getForgotPasswordInputs() {
        const {
            fields: { email },
            submitting,
            requestError } = this.props;

        return (
            <div>
                <div className="title">
                    Forgot Your Password?
                </div>
                <p className="text-content light-title">
                   Enter your registered email address to send you password reset instructions.
                </p>
                <div className="inputs-container">
                    <ValidationField
                        type="text"
                        label="Email Address"
                        fieldType="input"
                        {...email}
                    />
                    <FormButtonWrapper>
                        <button
                            id="forgot-password-btn"
                            type="submit"
                            className="btn btn-primary"
                            disabled={ submitting }
                        >
                            Send Email
                            { submitting ?
                                <Loader className="small-loader white" /> : ''
                            }
                        </button>
                    </FormButtonWrapper>
                </div>
                { this.getError(requestError) }
                <p className="text-content large">
                    <a
                        className="default-link"
                        onClick={ this.goToLoginHandler.bind(this) }
                    >
                        Back to Login
                    </a>
                </p>
            </div>
        );
    }

    getSuccessContent() {
        return (
            <div>
                <p className="text-content title">
                   Check Your Email!
                </p>
                <p className="text-content light-title">
                   We just sent you an email with instructions to reset your password.
                </p>
                <p className="text-content large">
                    <button
                        id="forgot-password-ok-btn"
                        type="button"
                        className="btn btn-primary"
                        onClick={ this.goToLoginHandler.bind(this) }
                    >
                        Ok
                    </button>
                </p>
                <input
                    type="hidden"
                    name="resetToken"
                    value={ this.props.resetToken || '' }
                />
                <input
                    type="hidden"
                    name="verificationCallbackUrl"
                    value={ `${UrlUtils.getHostUrl()}/reset-password` }
                />
            </div>
        );
    }

    goToLoginHandler() {
        this.props.onLoginFormChange('login');
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <form
                className="modal-form"
                id="forgot-password-request"
                onSubmit={ handleSubmit(this.props.onForgotPasswordSubmit) }
            >
                { this.getFormContent() }
            </form>
        );
    }
}

ForgotPasswordForm.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'forgotPasswordForm',
    fields,
    validate
})(ForgotPasswordForm);
