import {
    ACCOUNT_DETAILS_REQUEST,
    ACCOUNT_DETAILS_SUCCESS,
    ACCOUNT_DETAILS_FAILURE,
    ACCOUNT_DETAILS_SET
} from './constants';
import UserService from 'common/services/UserService';

export function loadAccountDetails() {
    return (dispatch, getState) => {
        // get account details if user is authenticated only
        if (!getState().authentication.authData.token) {
            return null;
        }

        dispatch({
            type: ACCOUNT_DETAILS_REQUEST
        });

        return UserService.instance.getUserDetails()
            .then((response) => {
                dispatch({
                    type: ACCOUNT_DETAILS_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: ACCOUNT_DETAILS_FAILURE,
                    error
                });

                return {
                    serverShouldHandle: true,
                    error
                };
            });
    };
}

export function setAccountDetails(data) {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_DETAILS_SET,
            data
        });
    };
}
