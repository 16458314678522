import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import ValidationField from 'shared/ui/formFields/ValidationField';
import Validation from 'utils/Validation';
import Loader from 'shared/ui/loader/Loader';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';

export const fields = [
    'password',
    'confirmPassword'];

const validate = values => {
    const errors = {};

    if (!values.password) {
        errors.password = 'Required';
    } else if (!Validation.validatePassword(values.password)) {
        errors.password = `
            Invalid password`;
    }

    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
};

class ResetPasswordConfirmForm extends React.Component {

    getError(error) {
        if (!error) {
            return null;
        }

        return (
                <p className="text-content error">
                    { error.message }
                </p>
            );
    }

    render() {
        const {
            fields: { password, confirmPassword },
            handleSubmit,
            submitting,
            decodedData,
            requestError } = this.props;

        return (
            <form
                id="reset-password-confirm-form"
                className="modal-form"
                onSubmit={ handleSubmit(this.props.onResetPasswordSubmit) }
            >
                <p className="text-content title">
                   { decodedData.data.username }
                </p>
                <p className="text-content light-title">
                    Password must contain at least one lower case letter,
                    one upper case letter,
                    one digit and must be at least 6 characters
                </p>
                <div className="inputs-container">
                    <ValidationField
                        type="password"
                        label="New Password"
                        fieldType="input"
                        data-tip
                        data-for="invalidPasswordTooltip"
                        data-event="click"
                        data-class="custom-tooltip"
                        {...password}
                    />
                    <ValidationField
                        type="password"
                        label="Confirm New Password"
                        fieldType="input"
                        {...confirmPassword}
                    />
                </div>
                { this.getError(requestError) }
                <div className="inputs-container">
                    <FormButtonWrapper>
                        <button
                            id="reset-password-btn"
                            type="submit"
                            className="btn btn-primary"
                            disabled={ submitting }
                        >
                            Reset Password
                            { submitting ?
                                <Loader className="small-loader white" /> : ''
                            }
                        </button>
                    </FormButtonWrapper>
                </div>
            </form>
        );
    }
}

// https://www.npmjs.com/package/@jimpick/redux-form
ResetPasswordConfirmForm.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'resetPasswordConfirmForm',
    fields,
    validate
})(ResetPasswordConfirmForm);
