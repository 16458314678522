import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Home from './components/Home';
import { loadPopularTechnologies } from './popularTechnologies/actions';
import Footer from 'shared/footer/Footer';

function mapStateToProps(state) {
    return {
        home: state.home,
        authData: state.authentication.authData
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class HomeContainer extends React.Component {
    // @todo: Uncomment this once we havea api support for getting specific technologies
    // @todo: add proper title and meta tags
    // actions to fulfill before pageload
    static needs = [
        loadPopularTechnologies
    ];

    componentDidUpdate(prevProps) {
        if (!this.isUserAuthenticated() && prevProps.authData.token) {
            // reload product data data if user logs out
            this.props.dispatch(loadPopularTechnologies());
        }
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    render() {
        const title = 'Competitive Intelligence for Lead Generation & Qualification';
        const description = 'Use technology trends as competitive intelligence for lead ' +
            'generation, lead qualification, and marketing research.';
        const keywords = 'Competitive intelligence, lead generation, lead qualification, ' +
            'technology trends, use of technology';

        return (
            <div>
                <Helmet
                    htmlAttributes= { { lang: 'en' } }
                    title= { title }
                    meta={ [
                        { name: 'description', content: description },
                        { name: 'keywords', content: keywords }
                    ] }
                />
                <Home
                    isUserAuthenticated={ this.isUserAuthenticated() }
                    {...this.props}
                />
                { this.props.children }
                <Footer />
            </div>
        );
    }
}
