import React from 'react';
import { connect } from 'react-redux';
import RecordTypeSelection from 'myhg/import/accounts/RecordTypeSelection';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import {
    MARKETO_ACCOUNTS_PAGE_TITLE,
    MARKETO_SUMMARY_LOAD
} from './constants';
import accountsStatusHandler from 'myhg/import/accounts/accountsStatusHandler';
import {
    ACCOUNT_TYPES
} from 'myhg/import/accounts/constants';
import {
    MATCH_SOURCE_TYPE
} from 'myhg/segment/constants';
import { PLATFORM_SOURCE } from 'shared/constants';
import { setRecordsType } from 'myhg/import/accounts/actions';

function mapStateToProps(state) {
    return {
        accountDetails: state.user.accountDetails.data,
        segmentId: state.myhg.segment.currentSegment.id,
        authData: state.authentication.authData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const platformRecordTypes = [ACCOUNT_TYPES.MARKETO_LEAD];

@accountsStatusHandler(platformRecordTypes, MATCH_SOURCE_TYPE.MARKETO)
@connect(mapStateToProps, mapDispatchToProps)

// TODO: implement Accounts HOC instead that will handle accounts loading by record types
// so it can be reused by all platforms/source types
export default class MarketoAccountsContainer extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;
        // since there is only one selection available set as default
        dispatch(setRecordsType(platformRecordTypes));
    }

    render() {
        const content = (
            <div className="import-platform-accounts">
                <div className="summary">
                    { MARKETO_SUMMARY_LOAD }
                </div>
                <RecordTypeSelection
                    { ...this.props }
                />
            </div>
        );
        const customHeaderTitle = (
            <span>
                { MARKETO_ACCOUNTS_PAGE_TITLE }
                <span className={ `platform-title-logo ${PLATFORM_SOURCE.MARKETO}-img` } />
            </span>
        );

        return (
            <ImportPageContainerWrapper
                title={ MARKETO_ACCOUNTS_PAGE_TITLE }
                customHeader={ customHeaderTitle }
            >
                { content }
            </ImportPageContainerWrapper>
        );
    }
}
