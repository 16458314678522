import React from 'react';
import ReactDOM from 'react-dom';
import DonutChart from 'shared/ui/charts/DonutChart';

export default class SummaryDetailsDonut extends React.Component {

    componentDidMount() {
        this.donutChart = new DonutChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        this.donutChart.update(this.getChartData());
    }

    componentWillUnmount() {
        this.donutChart.destroy(this.getDOMNode());
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getChartState() {
        return {
            data: this.getChartData(),
            dataKey: 'count'
        };
    }

    getChartData() {
        return this.buildChartData(this.props.source);
    }

    buildChartData(sourceData) {
        const allowedTypes = sourceData.source
            ? ['matched', 'netNew']
            : ['companies', 'productCompanies'];

        return allowedTypes.map(type => {
            const count = sourceData[`${type}Diff`] !== undefined
                        ? sourceData[`${type}Diff`]
                        : sourceData[type] || 0;

            return {
                type,
                count
            };
        });
    }

    render() {
        return (
            <div className="donut-chart summary-details-donut"></div>
        );
    }
}
