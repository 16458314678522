import SegmentService from 'common/services/SegmentService';
import {
    PERMISSION_COUNT_REQUEST,
    PERMISSION_COUNT_SUCCESS,
    PERMISSION_COUNT_FAILURE
} from './constants';

export function loadPermissionCount() {
    return (dispatch) => {
        dispatch({
            type: PERMISSION_COUNT_REQUEST
        });

        return SegmentService.instance.getPermissionCount()
            .then((response) => {
                dispatch({
                    type: PERMISSION_COUNT_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: PERMISSION_COUNT_FAILURE,
                    error
                });
            });
    };
}
