import React from 'react';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import DropdownSelection from './DropdownSelection';


class Dropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        };
    }

    onSelectionClick = (index) => {
        const { onSelectionChange } = this.props;

        if (onSelectionChange) {
            onSelectionChange(index);
        }

        this.setState({
            isVisible: false
        });
    };

    showIfNotVisible = () => {
        if (!this.state.isVisible) {
            this.setState({
                isVisible: true
            });
        }
    };

    handleClickOutside = () => {
        this.setState({
            isVisible: false
        });
    };

    render() {
        const { activator, selections } = this.props;
        const dropdownClassName = classNames('hg-dropdown', this.props.className);

        return (
            <div className={ dropdownClassName } >
                <div
                    className="dropdown-activator"
                    onClick={ this.showIfNotVisible }
                >
                    { activator }
                </div>
                {
                    this.state.isVisible ? (
                        <DropdownSelection
                            selections={ selections }
                            handleClickOutside={ this.handleClickOutside }
                            onSelectionClick={ this.onSelectionClick }
                        />
                    ) : null
                }
            </div>
        );
    }
}

export default onClickOutside(Dropdown);
