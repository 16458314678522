import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import sprintf from 'sprintf-js';
import { NumeralUtils } from 'utils/Utils';
import Loader from 'shared/ui/loader/Loader';
import Dropdown from 'shared/ui/dropdown/Dropdown';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';
import PageWizardWrapper from 'shared/ui/modalWizard/PageWizardWrapper';
import PlatformConfirmInfo from './platformInfo/PlatformConfirmInfo';
import { SEGMENT_MATCHING_SERVICES } from 'myhg/segment/constants';
import {
    PLATFORM_CONFIRM_ACTION_TEXT,
    PLATFORM_CONFIRM_ACTION_TEXT_ACCOUNT,
    PLATFORM_CONFIRM_ACTION_RECORDS,
    PLATFORM_CONFIRM_ACTION_RECORDS_LOW,
    PLATFORM_MAX_FREE_RECORDS_COUNT
} from '../constants';
import { PLATFORM_SOURCE_NAME } from 'shared/constants';
import { connectActionsComponents } from './constants';

function mapStateToProps(state) {
    return {
        currentSegment: state.myhg.segment.currentSegment
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class CampaignConfirmSignUpFlowContainer extends React.Component {

    constructor(props) {
        super(props);

        this.availablePlatforms = SEGMENT_MATCHING_SERVICES.filter(item => {
            return item.available;
        });

        this.state = {
            platform: props.landingPage
        };
    }

    onCompareClick(url) {
        browserHistory.replace(url);
    }

    onSelectionChange = (selectedIndex) => {
        this.setState({
            platform: this.availablePlatforms[selectedIndex].type
        });
    }

    getDropdownSelections() {
        return this.availablePlatforms.map(item => {
            const displaySelection = item.type === 'file' ?
                'YOUR FILE' : PLATFORM_SOURCE_NAME[item.type];

            return (
                <span>
                    <span className={ `dd-icon ${item.iconClass}` } />
                    { displaySelection }
                </span>
            );
        });
    }

    getDropdown(activeOption) {
        const displaySelection = activeOption.type === 'file' ?
            'YOUR FILE' : PLATFORM_SOURCE_NAME[activeOption.type];
        const dropdownActivator = (
            <div>
                <span className="dd-label">
                    <span className={ `dd-icon ${activeOption.iconClass}` } />
                    { displaySelection }
                </span>
                <span className="icon-small-arrow-down" />
            </div>
        );

        return (
            <div className="confirm-compare">
                <p>Compare to Your Accounts In</p>
                <Dropdown
                    className="confirm-dropdown"
                    activator={ dropdownActivator }
                    selections={ this.getDropdownSelections() }
                    onSelectionChange={ this.onSelectionChange }
                />
            </div>
        );
    }

    getAction(activeOption) {
        const { currentSegment } = this.props;
        const { platform } = this.state;

        const placeholder = platform === 'file' ?
            PLATFORM_CONFIRM_ACTION_TEXT : PLATFORM_CONFIRM_ACTION_TEXT_ACCOUNT;
        const platformName = PLATFORM_SOURCE_NAME[activeOption.type];
        const actionText = sprintf.sprintf(placeholder, platformName);
        const ActionComponent = connectActionsComponents[activeOption.type];

        if (!currentSegment.id) {
            // this screen requires a segment, so till it's saved show the loader
            return <Loader />;
        }

        return (
            <FormButtonWrapper>
                <ActionComponent
                    segmentId={ currentSegment.id }
                    platformName={ platformName }
                    actionText={ actionText }
                />
            </FormButtonWrapper>
        );
    }

    getLeftContent() {
        return (
            <PlatformConfirmInfo
                landingPage={ this.state.platform }
            />
        );
    }

    getSampleRecordsText() {
        const { currentSegment } = this.props;
        const totalRecords = currentSegment.data.status.companies;
        let content;

        if (!totalRecords) {
            return null;
        }

        if (totalRecords < PLATFORM_MAX_FREE_RECORDS_COUNT) {
            content = (
                <p>
                    <b>{ totalRecords }</b>
                    <em>{ PLATFORM_CONFIRM_ACTION_RECORDS_LOW }</em>
                </p>
            );
        } else {
            const recordsCountText = sprintf.sprintf(
                PLATFORM_CONFIRM_ACTION_RECORDS,
                NumeralUtils.formatIntegerNumber(totalRecords));

            content = (
                <p>
                    <b>{ PLATFORM_MAX_FREE_RECORDS_COUNT }</b>
                    <em>{ recordsCountText }</em>
                </p>
            );
        }

        return content;
    }

    getRightContent() {
        const { currentSegment } = this.props;
        const activeOption = SEGMENT_MATCHING_SERVICES.find(item => {
            // platform is the landing page actually
            return item.type === this.state.platform;
        });

        return (
            <div id="confirm-registration" className="confirm-content modal-form">
                <h2 className="h2">Thanks for confirming your email!</h2>
                { this.getDropdown(activeOption) }
                <div className="confirm-campaign">
                    <h2 className="h2">{ currentSegment.name }</h2>
                    { this.getSampleRecordsText() }
                </div>
                { this.getAction(activeOption) }
                <p className="text-content">
                    This HG Campaign test drive lasts 10 days, with up to 10 campaigns.
                    We don’t require special permissions, and we don’t modify any of your records.
                </p>
            </div>
        );
    }

    render() {
        return (
            <PageWizardWrapper
                leftContent={ this.getLeftContent() }
                rightContent={ this.getRightContent() }
                onClose={ this.props.onClose || null }
            />
        );
    }
}
