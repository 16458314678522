import {
    UNKNOWN_VALUES_ADD,
    UNKNOWN_VALUES_REMOVE,
    UNKNOWN_VALUES_SET,
    UNKNOWN_VALUES_RESET,
    UNKNOWN_VALUES_OPTIONS
} from './constants';

export const includeUVInitialState = UNKNOWN_VALUES_OPTIONS.map((item) => {
    return item.checked === false ? false : item.id;
}).filter((item) => { return item; });

export default function includeUnknownFirmos(state = includeUVInitialState, action) {
    switch (action.type) {
        case UNKNOWN_VALUES_ADD: {
            const index = state.indexOf(action.id);
            if (index < 0) {
                return [...state, action.id];
            }
            return state;
        }

        case UNKNOWN_VALUES_REMOVE: {
            const index = state.indexOf(action.id);
            if (index >= 0) {
                return [...state.slice(0, index), ...state.slice(index + 1)];
            }
            return state;
        }

        case UNKNOWN_VALUES_SET:
            return action.ids;

        case UNKNOWN_VALUES_RESET:
            return includeUVInitialState;

        default:
            return state;
    }
}
