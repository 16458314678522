import {
    COLLECTION_REQUEST,
    COLLECTION_SUCCESS,
    COLLECTION_FAILURE
} from './constants';

export const collectionInitialState = {
    isFetched: false,
    inProgress: false,
    data: null,
    error: null
};

export default function collection(state = collectionInitialState, action) {
    switch (action.type) {
        case COLLECTION_REQUEST:
            return Object.assign({}, state, {
                isFetched: false,
                inProgress: true,
                error: null
            });

        case COLLECTION_SUCCESS:
            return Object.assign({}, state, {
                isFetched: true,
                inProgress: false,
                data: action.data,
                error: null
            });

        case COLLECTION_FAILURE:
            return Object.assign({}, state, {
                isFetched: false,
                inProgress: false,
                error: action.error
            });

        default:
            return state;
    }
}
