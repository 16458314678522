import ProductService from 'common/services/ProductService';
import {
    COLLECTION_REQUEST,
    COLLECTION_SUCCESS,
    COLLECTION_FAILURE,
    COLLECTION_SEO_SUFIX
} from './constants';

export function normalizeForAPI(id) {
    const exp = new RegExp(`-${COLLECTION_SEO_SUFIX}$`);
    return id.replace(exp, '');
}

export function denormalizeForSEO(id) {
    return `${id}-${COLLECTION_SEO_SUFIX}`;
}

// Arguments
// - collectionIdFunc - object or string
// Description
// - this method is executed both server side, as a promise, or client-side
// - on the server side we pass all the rendring props as an object
export function loadCollectionById(collectionIdFunc) {
    let id = collectionIdFunc;
    if (typeof id === 'object') {
        id = id.collectionId;
    }

    id = normalizeForAPI(id);

    return (dispatch) => {
        dispatch({
            type: COLLECTION_REQUEST,
            id
        });

        return ProductService.instance.getCollectionDetails(id)
            .then((response) => {
                return dispatch({
                    type: COLLECTION_SUCCESS,
                    data: response.data,
                    id
                });
            })
            .catch((error) => {
                dispatch({
                    type: COLLECTION_FAILURE,
                    error
                });
            });
    };
}
