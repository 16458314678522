import React from 'react';
import { connect } from 'react-redux';
import {
    loadRegistrationProgressFromLocal,
    register,
    startOver
} from './actions';
import SignUpForm from './SignUpForm';
import SignUpInProgress from './SignUpInProgress';
import Loader from 'shared/ui/loader/Loader';
import { ACTION, WHERE } from 'utils/track-constants';
import { track } from 'utils/Utils';
import SignUpGenericPanel from './components/SignUpGenericPanel';
import PageWizardWrapper from 'shared/ui/modalWizard/PageWizardWrapper';

function mapStateToProps(state) {
    return {
        signUp: state.authentication.signUp
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class SignUpContainer extends React.Component {

    componentDidMount() {
        if (!this.props.signUp.localStateLoaded) {
            this.props.dispatch(loadRegistrationProgressFromLocal());
        }
    }

    onRegisterSubmit(registrationData) {
        const { dispatch, params: { landingPage } } = this.props;

        track(ACTION.SIGN_UP_SUBMIT, { label: WHERE.PRELOGIN_LIGHTBOX });
        return dispatch(register(registrationData, landingPage));
    }

    onRegistrationStartOver() {
        this.props.dispatch(startOver());
    }

    getLandingPageContent() {
        const { landingPage } = this.props.params;
        return <SignUpGenericPanel landingPage={ landingPage } />;
    }

    resendConfirmationEmail() {
        const { dispatch, params: { landingPage }, signUp } = this.props;

        // resend confirmation uses the same api to register
        return dispatch(register(signUp.registrationData, landingPage, true));
    }

    render() {
        let content = null;
        if (!this.props.signUp.localStateLoaded) {
            content = <Loader />;
        }

        if (this.props.signUp.inProgress) {
            content = (
                <SignUpInProgress
                    { ...this.props }
                    resendConfirmationEmail={ this.resendConfirmationEmail.bind(this) }
                    onRegistrationStartOver={ this.onRegistrationStartOver.bind(this) }
                />
            );
        } else {
            content = (
                <SignUpForm
                    onRegisterSubmit={ this.onRegisterSubmit.bind(this) }
                    requestError={ this.props.signUp.error }
                    { ...this.props }
                />
            );
        }

        return (
            <PageWizardWrapper
                leftContent={ this.getLandingPageContent() }
                rightContent={ content }
                onClose={ this.props.onClose || null }
                onBack={ this.props.onBack || null }
            />
        );
    }
}
