import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import numeral from 'numeral';
import { Link } from 'react-router';
import ProductCounter from 'shared/ui/counter/ProductCounter';
import { NO_PRODUCT_PERMISSIONS } from '../constants';
import { URL } from '../../../shared/constants';

export default class InsightsWidget extends React.Component {

    getCreateSegmentLink() {
        if (!this.props.isUserAuthenticated) {
            return (
                <span className="create-segment-wrapper">
                    <text id="create-segment-top" fontSize="20px">
                        <a href={ URL.DISCOVERY_DEMO_REQUEST }
                           color="#c90053"
                           id="discovery-demo-request"
                           target="_blank"
                        >
                            Create a Target Segment
                        </a>
                    </text>
                </span>
            );
        }

        const { slug, allowedSlugs } = this.props;
        let disabled = false;
        let linkAttr = {
            to: '/segment',
            onClick: this.props.onCreateSegment
        };
        let tooltip = null;

        if (allowedSlugs && allowedSlugs.indexOf(slug) === -1) {
            linkAttr = {
                'data-tip': NO_PRODUCT_PERMISSIONS,
            };
            disabled = true;
            tooltip = <ReactTooltip effect="solid" />;
        }

        return (
            <span className="create-segment-wrapper">
                <Link
                    id="create-segment-top"
                    disabled={ disabled }
                    { ...linkAttr }
                >
                    Create a Target Segment
                </Link>
                { tooltip }
            </span>
        );
    }

    render() {
        const { percent, product } = this.props.productDetails.data;
        const { sampleCompanies } = this.props;
        const floatVal = numeral().unformat(percent);

        const iconIncreaseChangeClassname = classNames('trending', {
            'icon-growth': floatVal > 0,
            'icon-decline': floatVal < 0,
            'icon-flat': floatVal === 0,
        });

        const percentChange = numeral(percent).format('+0.00');
        const totalCompanies = numeral(sampleCompanies.totalCompaniesCount).format('0,0');

        return (
            <div className="product-widget">
                <ProductCounter
                    techName={ product }
                    counts={ totalCompanies }
                    percentage={ percentChange }
                    trendInconClass={ iconIncreaseChangeClassname }
                />
                { this.getCreateSegmentLink() }
            </div>
        );
    }
}
