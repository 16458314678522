import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import Loader from 'shared/ui/loader/Loader';
import SalesforceConnect from './components/SalesforceConnect';
import SalesforceConnectOptions from './components/SalesforceConnectOptions';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import {
    SALESFORCE_CONNECT_PAGE_TITLE,
    SALESFORCE_CONNECT_PAGE_DESCRIPTION,
    SALESFORCE_ALREADY_CONNECT_PAGE_DESCRIPTION
} from './constants';
import {
    validatePlatformToken
} from 'myhg/platformConnection/actions';
import { PLATFORM_SOURCE } from 'shared/constants';
import segmentEnforcer from 'myhg/segment/segmentEnforcer';

function mapStateToProps(state) {
    return {
        segmentId: state.myhg.segment.currentSegment.id,
        authData: state.authentication.authData,
        platformToken: state.myhg.platformConnection.platformToken
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@segmentEnforcer
@connect(mapStateToProps, mapDispatchToProps)

export default class SalesforceConnectContainer extends React.Component {

    componentDidMount() {
        // always validate the token
        this.props.dispatch(validatePlatformToken());
    }

    getState() {
        const { segmentId } = this.props;

        return {
            callback: '/dashboard/connect/platform-authorize',
            platform: 'salesforce',
            segmentId
        };
    }

    getAuthorizeStepContent() {
        return (
            <div className="platform-connect">
                <h4 className="sub-title">
                    { SALESFORCE_CONNECT_PAGE_DESCRIPTION }
                </h4>
                <SalesforceConnect state={ this.getState() } { ...this.props } />
            </div>
        );
    }

    getAuthorizeOptionsStepContent() {
        const description = (
            <span className="sub-title-light">
                { SALESFORCE_ALREADY_CONNECT_PAGE_DESCRIPTION }
                <br />
                <br />
                <strong>{ this.props.authData.salesforceUsername }</strong>
                <br />
            </span>
        );

        return (
            <div className="platform-connect modal-form">
                <div className="summary">
                    { description }
                </div>
                <SalesforceConnectOptions
                    { ...this.props }
                    state={ this.getState() }
                    goToNextStep={ this.continueWithImportData }
                />
            </div>
        );
    }

    continueWithImportData() {
        browserHistory.push('/dashboard/import/salesforce');
    }

    render() {
        let content = <Loader />;
        let title = SALESFORCE_CONNECT_PAGE_TITLE;

        const checkTokenData = this.props.platformToken.data;
        const { authData } = this.props;

        if (checkTokenData) {
            if (!checkTokenData.valid
                || authData.platformSource !== PLATFORM_SOURCE.SALESFORCE) {
                content = this.getAuthorizeStepContent();
            } else {
                content = this.getAuthorizeOptionsStepContent();
            }
        }

        const customHeaderTitle = (
            <span>
                { SALESFORCE_CONNECT_PAGE_TITLE }
                <span className={ `platform-title-logo ${PLATFORM_SOURCE.SALESFORCE}-img` } />
            </span>
        );

        return (
            <ImportPageContainerWrapper
                title={ title }
                customHeader={ customHeaderTitle }
            >
                { content }
            </ImportPageContainerWrapper>
        );
    }
}
