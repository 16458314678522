export const PLURALIZE_LETTER = 's';

// DOM EVENTS
export const EVENT_DOM_KEYDOWN = 'keydown';
export const EVENT_DOM_KEYUP = 'keyup';
export const EVENT_DOM_KEYPRESS = 'keypress';

// KEY CODES
export const KEYCODE_KEY_ENTER = 13;
export const KEYCODE_KEY_UP = 38;
export const KEYCODE_KEY_DOWN = 40;
export const KEYCODE_CTRL = 17;
export const KEYCODE_TAB = 9;

// HTTP STATUS CODES
export const HTTP_OK = 200;
export const HTTP_PERMANENT_REDIRECT = 301;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED_CODE = 401;
export const HTTP_FORBIDEN_CODE = 403;
export const HTTP_NOTFOUND_CODE = 404;
export const HTTP_CONFLICT_CODE = 409;
export const HTTP_PRECONDITION_FAILED = 412;
export const HTTP_TOO_MANY_REQUESTS_CODE = 429;
export const HTTP_SERVERERROR_CODE = 500;

export const BREAKPOINT_XS = '0px';
export const BREAKPOINT_SM = '544px';
export const BREAKPOINT_MD = '767px';
export const BREAKPOINT_LG = '992px';
export const BREAKPOINT_XL = '1200px';

export const HIDDEN = 'hidden';
export const VISIBLE = 'visible';

// ENVIRONMENTS
export const DEVELOPMENT = 'development';
export const STAGING = 'staging';
export const PRODUCTION = 'production';

export const COOKIE_AUTH_TOKEN = 'authToken';
export const COOKIE_USERNAME = 'username';
export const COOKIE_EMAIL = 'email';
export const COOKIE_EXPIRATION_TIME = 120;

// UTILS
export const DATE_FORMAT = 'MMMM Do, YYYY';
export const DATE_TIME_FORMAT = 'MMMM Do YYYY, h:mm:ss a';

// GET MORE DATA MODAL ROUTES
export const DOWNLOAD_SAMPLE_ROUTE = 'download-sample';
export const DISCOVER_NEW_LEADS_ROUTE = 'discover-new-leads';
export const CONTACT_US_ROUTE = 'contact-us';
export const CONTACT_US_LOGGEDIN_ROUTE = 'contact';

// BAR CHARTS
export const ABOVE_VALUE_TEXT = 'Above';
export const BELOW_VALUE_TEXT = 'Below';

// REACT-TYPIST
export const AVG_TYPING_DELAY = 100;
export const HIDE_WHEN_DONE_DELAY = 1000;
export const DELAY_BEFORE_NEXT_ITEM = 4000;

export const URL = {
    ABOUT_US: '//www.hgdata.com/about/#team',
    PRIVACY_POLICY: '//www.hgdata.com/privacy-policy/',
    TERMS_OF_USE: 'https://www.hgdata.com/terms-of-use/#hg-discovery-terms-of-use',
    FOCUS_INSTALL: 'https://chrome.google.com/webstore/detail/achggfpaahjmndigmakfebpjlmfjmmfj',
    HG4SLF_INSTALL: 'https://chrome.google.com/webstore/detail/dajgookkolcoopglmfbeejafclaahcke',
    RESOURCES: 'https://www.hgdata.com/resources/',
    HOW_WE_BUILD: 'https://www.hgdata.com/product-build-method/',
    DISCOVERY_DEMO_REQUEST: 'https://hginsights.com/hgi/lp/discovery-demo-request?utm_source=sumo&utm_medium=popup&utm_campaign=demo-request-home&utm_content=see-demo'
};

export const API_ERROR_TYPE = {
    GENERIC: 'generic',
    CAMPAIGN_BUILDER: 'campaign_builder'
};

// COLLECTION TYPES
export const COLLECTION_TYPE = {
    VENDOR: 1,
    ATTRIBUTE: 2,
    CATEGORY: 3,
    SIMILAR_VENDOR: 4
};

export const COLLECTION_TYPE_NAMES = {
    [COLLECTION_TYPE.VENDOR]: 'vendor',
    [COLLECTION_TYPE.ATTRIBUTE]: 'category',
    [COLLECTION_TYPE.CATEGORY]: 'category',
    [COLLECTION_TYPE.SIMILAR_VENDOR]: 'vendor'
};

export const TAXONOMY_PERMISSION_TYPE = {
    CATEGORY: 'categories',
    PARENT_CATEGORY: 'parentCategories',
    VENDOR: 'vendors',
    PRODUCT: 'products',
    ATTRIBUTE: 'attributes'
};

export const UNAVAILABLE = 'N/A';

export const SIGNAL_SCORE_OPTIONS = [
    {
        id: 1,
        name: 'Current',
        description: `This product usage is highly
            reliable for precision targeting.`
    },
    {
        id: 2,
        name: 'Recent',
        description: `Extend your reach to include more
            companies likely using these products.`,
        isDefaultChecked: false
    },
    {
        id: 3,
        name: 'Past',
        description: `Get historical technology profile
            data for companies in your segment.`,
        isDefaultChecked: false
    }
];

// launch darkly feature flags
export const LAUNCH_DARKLY_CAMPAIGN_BUILDER_FLAG = 'campaign-builder';
export const LAUNCH_DARKLY_CREATE_SEGMENT_FLAG = 'free-segment-creation';
export const LAUNCH_DARKLY_DASHBOARD_FLAG = 'dashboard-access';

// platform user source types
export const PLATFORM_SOURCE = {
    NONE: '',
    SALESFORCE: 'sfdc',
    PARDOT: 'pardot',
    MARKETO: 'marketo',
    FILE: 'file',
    ELOQUA: 'eloqua',
    LINKEDIN: 'linkedin'
};

export const PLATFORM_SOURCE_NAME = {
    [PLATFORM_SOURCE.SALESFORCE]: 'Salesforce',
    [PLATFORM_SOURCE.PARDOT]: 'Pardot',
    [PLATFORM_SOURCE.MARKETO]: 'Marketo',
    [PLATFORM_SOURCE.FILE]: 'File',
    [PLATFORM_SOURCE.ELOQUA]: 'Eloqua',
    [PLATFORM_SOURCE.LINKEDIN]: 'LinkedIn'
};
