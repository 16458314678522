import {
    COLLECTION_TAXONOMY_REQUEST,
    COLLECTION_TAXONOMY_SUCCESS,
    COLLECTION_TAXONOMY_FAILURE
} from './constants';

export const collectionTaxonomyInitialState = {
    isFetching: false,
    error: null,
    data: null
};

export default function collections(state = collectionTaxonomyInitialState, action) {
    switch (action.type) {
        case COLLECTION_TAXONOMY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case COLLECTION_TAXONOMY_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null
            });

        case COLLECTION_TAXONOMY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
}
