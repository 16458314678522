import {
    MARKETO_CONNECT_REQUEST,
    MARKETO_CONNECT_SUCCESS,
    MARKETO_CONNECT_ERROR
} from './constants';

export const marketoConnectInitialState = {
    error: null,
    isAuthenticated: false,
    submitting: true
};

export default function marketoConnect(state = marketoConnectInitialState, action) {
    switch (action.type) {
        case MARKETO_CONNECT_REQUEST:
            return Object.assign({}, state, {
                error: null,
                isAuthenticated: false,
                submitting: true,
            });

        case MARKETO_CONNECT_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated: true,
                submitting: false
            });

        case MARKETO_CONNECT_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                isAuthenticated: false,
                submitting: false
            });

        default:
            return state;
    }
}
