import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

export default class OverlordService {
    constructor(enforcer) {
        this.overlordUrl = process.env.OVERLORD_API_URL;
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new OverlordService(singletonEnforcer);
        }
        return this[singleton];
    }

    getLinkedInAccessToken(redirectUri, accessCode) {
        return ApiWrapper.instance.axios({
            url: '/linkedIn/accessToken',
            baseURL: this.overlordUrl,
            method: 'GET',
            headers: {
                Authorization: `bearer ${ApiWrapper.instance.getAuthorizationToken()}`
            },
            params: {
                redirectUri,
                accessCode
            },
            externalRequest: true
        });
    }

    getAccounts(accessToken) {
        return ApiWrapper.instance.axios({
            url: '/linkedIn/accounts',
            baseURL: this.overlordUrl,
            method: 'GET',
            headers: {
                Authorization: `bearer ${ApiWrapper.instance.getAuthorizationToken()}`
            },
            params: {
                accessToken
            },
            externalRequest: true
        });
    }

    matchUrls(name, accountLocation, segment, accessToken) {
        return ApiWrapper.instance.axios({
            url: '/linkedIn/matchedUrls',
            baseURL: this.overlordUrl,
            method: 'POST',
            headers: {
                Authorization: `bearer ${ApiWrapper.instance.getAuthorizationToken()}`
            },
            params: {
                accessToken
            },
            data: {
                name,
                location: accountLocation,
                segment
            },
            externalRequest: true
        });
    }
}
