import React from 'react';
import classNames from 'classnames';
import root from 'window-or-global';
import {
    EVENT_DOM_KEYDOWN,
    KEYCODE_KEY_ENTER
} from 'shared/constants';

export default class TypeaheadSelection extends React.Component {

    constructor(props) {
        super(props);
        this.handleKeyboardKeydown = this.handleKeyboardKeydown.bind(this);
    }

    componentDidMount() {
        root.addEventListener(EVENT_DOM_KEYDOWN, this.handleKeyboardKeydown);
    }

    componentWillUnmount() {
        root.removeEventListener(EVENT_DOM_KEYDOWN, this.handleKeyboardKeydown);
    }

    getItemText(item) {
        return this.getHighlightedTextForItem(item.text, this.props.textFilter);
    }

    getList(items) {
        return items.map((item, index) => {
            // Assemble class names
            const itemClassName = classNames('select-item clickable');
            return (
                <li
                    key={ index }
                    className={ itemClassName }
                    onClick={ this.itemSelectHandler.bind(this, item) }
                >
                    { this.getItemText(item) }
                </li>
            );
        });
    }

    getSelectionList() {
        const lists = this.props.searchData.map((item, index) => {
            let list = null;
            if (item.items.length) {
                list = (
                    <ul className="selection-list" key={ index }>
                        { this.getList(item.items) }
                    </ul>
                );
            }
            return list;
        });

        return lists;
    }

    /**
    * Get highlighted text component for item text found.
    * @param {string} text Full text to display.
    * @param {string} filterText string part that should be highlighted
    * @returns {Array} Array react components to form the final higlighted text
    */
    getHighlightedTextForItem(text, filterText) {
        const startPos = text.toLowerCase().indexOf(filterText.toLowerCase());

        return [
            <span key={ 0 }>
                { text.substring(0, startPos) }
            </span>,
            <span key={ 1 } className="highlight-search">
                { text.substr(startPos, filterText.length) }
            </span>,
            <span key={ 2 }>
                { text.substr(startPos + filterText.length) }
            </span>
        ];
    }

    itemSelectHandler(item) {
        this.props.onSelectItem(item);
    }

    handleKeyboardKeydown(ev) {
        switch (ev.which) {

            case KEYCODE_KEY_ENTER: {
                const group = this.props.searchData.find((groupItem) => {
                    return groupItem.items.length > 0;
                });

                if (group) {
                    this.props.onSelectItem(group.items[0]);
                }
                break;
            }

            default:
                break;
        }
    }

    render() {
        return (
            <div className="search-selection">
                { this.getSelectionList() }
            </div>
        );
    }
 }
