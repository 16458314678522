import React from 'react';
import { connect } from 'react-redux';
import SegmentCrmStatus from './components/SegmentCrmStatus';

function mapStateToProps(state) {
    const segment = state.myhg.segment;

    return {
        accountDetails: state.user.accountDetails.data,
        matchesDataLoad: segment.segmentTable.matchesDataLoad,
        netNewMatches: segment.segmentMatches.netNewMatches,
        segmentExport: segment.segmentExport,
        segment,
        downloadLimit: -1 // TODO: get from api response
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class SegmentCrmStatusContainer extends React.Component {

    render() {
        return <SegmentCrmStatus { ...this.props } />;
    }
}
