import React from 'react';
import sprintf from 'sprintf-js';
import SegmentSourceDetails from './SegmentSourceDetails';
import SegmentCrmTrialStatus from './SegmentCrmTrialStatus';
import {
    SEGMENT_CRM_STATUS_TITLE,
    SEGMENT_CRM_STATUS_FILE_TITLE
} from '../constants';
import {
    MATCH_SOURCE_TYPE,
    MATCH_SOURCE_NAMES
} from 'myhg/segment/constants';

export default class SegmentCrmStatus extends React.Component {

    getTitle() {
        const { status } = this.props.segment.currentSegment.data;
        let content = '';

        switch (status.source) {
            case MATCH_SOURCE_TYPE.FILE:
                content = SEGMENT_CRM_STATUS_FILE_TITLE;
                break;

            default: {
                content = sprintf.sprintf(
                    SEGMENT_CRM_STATUS_TITLE,
                    MATCH_SOURCE_NAMES[status.source]);
                break;
            }
        }

        return content;
    }

    render() {
        const { status } = this.props.segment.currentSegment.data;

        return (
            <div className="segment-status-wrapper container">
                <div className="row">
                    <div className="col-md-8 segment-crm-status">
                        <div className="segment-crm-status-details">
                            <span className="segment-crm-status-title">
                                { this.getTitle() }
                            </span>
                            <SegmentSourceDetails
                                { ...this.props }
                                status={ status }
                            />
                        </div>
                    </div>
                </div>
                <SegmentCrmTrialStatus
                    { ...this.props }
                    status={ status }
                />
            </div>
        );
    }
}
