import React from 'react';
import Dropzone from 'react-dropzone';
import {
  DRAG_AND_DROP_TEXT,
  BROWSE_TO_UPLOAD_TEXT
} from './constants';

export default class UploadFileArea extends React.Component {
    onManualUpload = () => {
      this.refs.dropzone.open();
    }

    getDropZone() {
      return (
        <Dropzone
          ref="dropzone"
          onDrop={ this.props.onDrop }
          disableClick
          className="inner-upload-file"
          activeClassName="active-upload-area"
        >
          <div className="drag-and-drop-text">
            { DRAG_AND_DROP_TEXT }
          </div>
          <div className="text-content error">
            {' '}
            { this.props.getUploadErrorMessage() }
            {' '}
          </div>
          <div className="qualify-leads-file-types" />
          <div>
                    OR
          </div>
          <div className="browse-file-link">
            <a onClick={ this.onManualUpload }>
              { BROWSE_TO_UPLOAD_TEXT }
            </a>
          </div>
        </Dropzone>
      );
    }

    getUploading(progress) {
      return (
        <div className="progress">
          <span>
            {' '}
Uploaded
            { progress }
% percent
            {' '}
          </span>
        </div>
      );
    }

    render() {
      const { inProgress, progress } = this.props;
      let content;

      if (inProgress) {
        content = this.getUploading(progress);
      } else {
        content = this.getDropZone();
      }

      return (
        <div className="upload-file">
          { content }
        </div>
      );
    }
}
