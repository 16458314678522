import TaxonomyService from 'common/services/TaxonomyService';
import {
    FIRMOGRAPHIC_TAXONOMY_REQUEST,
    FIRMOGRAPHIC_TAXONOMY_SUCCESS,
    FIRMOGRAPHIC_TAXONOMY_FAILURE
} from './constants';

export default function loadFirmographicTaxonomy() {
    return (dispatch) => {
        dispatch({
            type: FIRMOGRAPHIC_TAXONOMY_REQUEST
        });
        return TaxonomyService.instance.getFirmographic()
            .then((response) => {
                const data = JSON.parse(JSON.stringify(response.data));
                if (data.employeesSteps) {
                    data.employeesSteps = response.data.employeesSteps.filter(step => {
                        return (step > 0);
                    });
                }

                if (data.revenueSteps) {
                    data.revenueSteps = response.data.revenueSteps.filter(step => {
                        return (step > 0);
                    });
                }
                dispatch({
                    type: FIRMOGRAPHIC_TAXONOMY_SUCCESS,
                    data
                });
            })
            .catch((error) => {
                dispatch({
                    type: FIRMOGRAPHIC_TAXONOMY_FAILURE,
                    error
                });
                return {
                    serverShouldHandle: true,
                    error
                };
            });
    };
}
