import React from 'react';
import classNames from 'classnames';
import { TechnologyIcon } from '../technologyIcon/TechnologyIcon';

export default class TechnologyIconText extends React.Component {
    getRemoveContent() {
        let result = null;
        if (!!this.props.onRemoveClick) {
            result = (
                <span className="icon-close-x" onClick={ this.props.onRemoveClick } />
            );
        }

        return result;
    }

    render() {
        const technologyClass = classNames({
            'technology-icon-text': this.props.displayText
        });
        const hideLabel = classNames({
            'icon-hide-label': !this.props.displayText
        });
        const { technology } = this.props;

        return (
            <div className={ technologyClass }>
                <TechnologyIcon technology={ technology } />
                <span className={ hideLabel }>
                    { technology.product || technology.name }
                </span>
                { this.getRemoveContent() }
            </div>
        );
    }
}
