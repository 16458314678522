import React from 'react';
import { NumeralUtils } from 'utils/Utils';
import SummaryDetailsDonut from './SummaryDetailsDonut';
import {
    TOTAL_COMPANIES,
    FILTERED_COMPANIES,
    NET_NEW_COMPANIES1,
    NET_NEW_COMPANIES2,
    NET_NEW_COMPANIES3,
    MATCHING_COMPANIES1,
    MATCHING_COMPANIES2,
    MATCHING_COMPANIES3,
    REFINE_SEGMENT_FILTERS
} from '../constants';
import {
    SEGMENT_DATA_MENU_NAME
} from 'myhg/segment/segmentMenuActions/constants';
import {
    getMenuByName,
    segmentMenuSelect
} from 'myhg/segment/segmentMenuActions/actions';

export default class SummaryDetailsOverall extends React.Component {

    onCompaniesCountClick = () => {
        const { dispatch } = this.props;
        const menu = getMenuByName(SEGMENT_DATA_MENU_NAME);

        dispatch(segmentMenuSelect(menu));
    }

    getTotalText(type) {
        const value = this.props.segmentStatus[type];

        return NumeralUtils.formatIntegerNumber(value);
    }

    getRefineFilters() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <a
                        className="refine-filters"
                        onClick={ this.props.onRefineFilters }
                    >
                        { REFINE_SEGMENT_FILTERS }
                    </a>
                </div>
            </div>
        );
    }

    getNonMatchedSection() {
        return (
            <div className="col-md-6">
                <div className="row segment-count-section">
                    <div className="col-lg-12">
                        <span
                            className="segment-total count-type-filtered"
                            onClick={ this.onCompaniesCountClick }
                        >
                            { this.getTotalText('companies') }
                        </span>
                        <p>
                            { FILTERED_COMPANIES }
                        </p>
                    </div>
                </div>
                <div className="row segment-count-section">
                    <div className="col-lg-12">
                        <span className="segment-total count-type-product-companies">
                            { this.getTotalText('productCompanies') }
                        </span>
                        <p>
                            { TOTAL_COMPANIES }
                        </p>
                    </div>
                </div>
                { this.props.isFiltersEditOpen ? null : this.getRefineFilters() }
            </div>
        );
    }

    getMatchedSection() {
        return (
            <div className="col-md-6">
                <div className="row segment-count-section">
                    <div className="col-lg-12">
                        <span className="segment-total count-type-matched">
                            { this.getTotalText('matched') }
                        </span>
                        <p>
                            { MATCHING_COMPANIES1 }
                            <b>{ MATCHING_COMPANIES2 }</b>
                            { MATCHING_COMPANIES3 }
                        </p>
                    </div>
                </div>
                <div className="row segment-count-section">
                    <div className="col-lg-12">
                        <span className="segment-total count-type-net-new">
                            { this.getTotalText('netNew') }
                        </span>
                        <p>
                            { NET_NEW_COMPANIES1 }
                            <b>{ NET_NEW_COMPANIES2 }</b>
                            { NET_NEW_COMPANIES3 }
                        </p>
                    </div>
                </div>
                { this.props.isFiltersEditOpen ? null : this.getRefineFilters() }
            </div>
        );
    }

    render() {
        const { donutSource, segmentStatus } = this.props;

        return (
            <div className="segment-details-overall">
                <div className="row">
                    <div className="col-md-6">
                        <SummaryDetailsDonut
                            source={ donutSource }
                        />
                    </div>
                    {
                        segmentStatus.source
                            ? this.getMatchedSection()
                            : this.getNonMatchedSection()
                    }
                </div>
            </div>
        );
    }
}
