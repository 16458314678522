import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import Loader from 'shared/ui/loader/Loader';
import LimitReached from './LimitReached';
import {
    FIRMOGRAPHIC_TOOLTIP
} from '../constants';
import FirmographicsTable from 'shared/firmographics/FirmographicsTable';
import { HTTP_TOO_MANY_REQUESTS_CODE } from 'shared/constants';

export default class FirmographicChartPanel extends React.Component {

    componentDidMount() {
        if (!this.props.data) {
            this.props.loadFirmographicData(this.props.slug);
        }
    }

    getTitle() {
        const { productDetails } = this.props;

        return (
            <div>
                { `Firmographics of Companies using ${productDetails.data.product}` }
                <ReactTooltip
                    effect="solid"
                    place="top"
                    id="product-firmographics"
                    offset={ { right: 15 } }
                />
                <span
                    className="tooltip-info icon-info"
                    data-for="product-firmographics"
                    data-tip={ FIRMOGRAPHIC_TOOLTIP }
                    data-html
                />
            </div>
        );
    }

    getErrorContent() {
        let content;
        const { error } = this.props;

        if (error.status === HTTP_TOO_MANY_REQUESTS_CODE) {
            content = <LimitReached productSlug = { this.props.slug } />;
        } else {
            content = this.getContent(error.message);
        }
        return content;
    }

    getContent(errorMessage) {
        const { menus, data, selectedMenu, onTabSelection } = this.props;

        return (
            <FirmographicsTable
                menus={ menus }
                data={ data }
                selectedMenu={ selectedMenu }
                errorMessage={ errorMessage }
                onTabSelection={ onTabSelection }
            />
        );
    }

    render() {
        const {
            productDetails,
            data,
            isFetching,
            error
        } = this.props;

        let content;
        let title = '';

        // @todo - if api returns 204 ok status we should probably use that not the data itself
        // to identify whether a product has firmographics data or not
        if (data === '') {
            return null;
        }

        // ERRORS
        if (productDetails.error) {
            content = productDetails.error.message;
        // LOADING
        } else if (productDetails.isFetching || isFetching) {
            content = <Loader />;
        // CONTENT
        } else if (error) {
            title = this.getTitle();
            content = this.getErrorContent();
        } else if (!!data) {
            title = this.getTitle();
            content = this.getContent();
        }

        return (
            <PanelWrapper title={ title } className="firmographics-panel">
                { content }
            </PanelWrapper>
        );
    }
}

FirmographicChartPanel.propTypes = {
    productDetails: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired
};
