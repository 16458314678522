import {
    FILTERS_SET_LAST_CHECK_VALUE,
    FILTERS_LAST_CHECK_CLEAR
} from './constants';

export function setLastCheckByValue(lastCheckValue) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_SET_LAST_CHECK_VALUE,
            lastCheckValue
        });
    };
}

export function resetLastCheck() {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_LAST_CHECK_CLEAR
        });
    };
}
