import React from 'react';
import Typist from 'react-typist';
import { Link } from 'react-router';
import withTaxonomy from 'shared/taxonomy/enhancer';
import SearchContainer from 'shared/search/SearchContainer';
import {
    HOME_TOP_BANNER,
    SEARCH_PLACEHOLDER_TEXT
} from 'shared/search/constants';
import techs from './TypingTechs';
import {
    DELAY_BEFORE_NEXT_ITEM,
    AVG_TYPING_DELAY,
    HIDE_WHEN_DONE_DELAY
} from 'shared/constants';
import { ACTION, WHERE } from 'utils/track-constants';
import { track } from 'utils/Utils';

class HomeTopBanner extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            textIndex: 0,
            tech: props.findTaxonomyByProductId(techs[0])
        };
    }

    componentWillUnmount() {
        clearTimeout(this.nextItemTimeout);
    }

    nextItem() {
        const { findTaxonomyByProductId } = this.props;

        this.nextItemTimeout = setTimeout(() => {
            let textIndex;

            if (this.state.textIndex === techs.length - 1) {
                textIndex = 0;
            } else {
                textIndex = this.state.textIndex + 1;
            }

            this.setState({
                textIndex,
                tech: findTaxonomyByProductId(techs[textIndex])
            });
        }, DELAY_BEFORE_NEXT_ITEM);
    }

    track(action) {
        track(action, { label: WHERE.TYPIST });
    }

    filterProductName(name) {
        return name.replace(/\s*\(unspecified product\)/i, '');
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h1>
                            <div className="prefix">Find out what companies are using</div>
                            <Typist
                                onTypingDone={ this.nextItem.bind(this) }
                                key={ this.state.textIndex }
                                avgTypingDelay={ AVG_TYPING_DELAY }
                                cursor={ { show: true,
                                          blink: true,
                                          element: '|',
                                          hideWhenDone: false,
                                          hideWhenDoneDelay: HIDE_WHEN_DONE_DELAY,
                                        } }
                            >
                                <Link
                                    to={ `product/${this.state.tech.slug}` }
                                    onClick={
                                        this.track.bind(this, ACTION.VIEW_PRODUCT)
                                    }
                                >
                                    { this.filterProductName(this.state.tech.product) }
                                </Link>
                            </Typist>
                        </h1>
                        <p className="lead">
                            Your competitive advantage begins with the best technographics.<br />
                            What do you want to know?
                        </p>
                        <SearchContainer
                            autoFocus
                            showAsLink
                            isHybrid
                            className="home-search"
                            source={ HOME_TOP_BANNER }
                            inputPlaceholderText={ SEARCH_PLACEHOLDER_TEXT }
                            onTechnologySelect={ this.props.onTechnologySelect }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTaxonomy(HomeTopBanner);
