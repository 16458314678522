// VERTICAL BAR CHARTS DEFAULT SIZES
export const VERTICAL_BARCHART_CONTAINER_HEIGHT = 420;
export const VERTICAL_BARCHART_VERTICAL_MARGINS_RATIO = 0.05;
export const VERTICAL_BARCHART_HORIZONTAL_MARGINS_RATIO = 0.10;
export const VERTICAL_BARCHART_TICK_SIZE = 10;
// https://github.com/d3/d3/wiki/Ordinal-Scales#ordinal_rangeBands
export const VERTICAL_BARCHART_RANGEBANDS_PADDING = 0.34;
export const VERTICAL_BARCHART_MAX_DOMAIN_SCALE_RATIO = 1.10;
export const VERTICAL_BARCHART_BAR_ANIMATION_MS = 600;
export const VERTICAL_BARCHART_SHADOWBAR_ANIMATION_MS = 1600;
export const VERTICAL_BARCHART_UPDATE_AXIS_ANIMATION_MS = 1200;

export const STACKED_BARCHART_HORIZONTAL_OFFSET = 40;
export const STACKED_BARCHART_VERTICAL_OFFSET = 30;
export const STACKED_BARCHART_RANGEBANDS_PADDING = 0.4;
export const STACKED_BARCHART_AXIS_ANIMATION_MS = 300;
export const STACKED_BARCHART_X_LABEL_LINE_HEIGHT = 1.1;

// HORIZONTAL BAR CHARTS DEFAULT SIZES
export const HORIZONTAL_BARCHART_CONTAINER_HEIGHT = 420;
export const HORIZONTAL_BARCHART_VERTICAL_MARGINS = 30;
export const HORIZONTAL_BARCHART_HORIZONTAL_MARGINS = 10;
// https://github.com/d3/d3/wiki/Ordinal-Scales#ordinal_rangeBands
export const HORIZONTAL_BARCHART_RANGEBANDS_PADDING = 0.2;
export const HORIZONTAL_BARCHART_MAX_DOMAIN_SCALE_RATIO = 1.20;
export const HORIZONTAL_BARCHART_BAR_ANIMATION_MS = 600;
export const HORIZONTAL_BARCHART_BAR_TIPTEXT_ANIMATION_MS = 1200;
export const HORIZONTAL_BARCHART_UPDATE_AXIS_ANIMATION_MS = 1200;
export const HORIZONTAL_BARCHART_TICK_PADDING = 8;

// use to calculate expected width/size size
export const CHART_HEIGHT_WIDTH_ASPECT_RATIO = 0.66;
export const CHART_WIDTH_HEIGHT_ASPECT_RATIO = 0.40;
