import React, { Component } from 'react';
import root from 'window-or-global';
import {
    EVENT_DOM_KEYUP,
    EVENT_DOM_KEYDOWN,
    KEYCODE_KEY_ENTER,
    KEYCODE_KEY_UP,
    KEYCODE_KEY_DOWN,
    KEYCODE_CTRL,
} from 'shared/constants';

// keyboard/item selection handler HOC
// props:
//      items - the array with item to navigate/select from
//      isSearchActive - determine wheter or not the keyboard search is active
//      itemSelected - selected item handler
//      [multipleItemsSelected] - multiple items selection handler (if not present this is disabled)
export default function (ComposedComponent) {
    class KeyboardSelectionHOC extends Component {

        constructor(props) {
            super(props);
            this.state = {
                selectedIndex: this.props.isSearchActive ? 0 : -1,
                allowMultipleSelections: !!this.props.multipleItemsSelected,
                isMultipleSelectionEnabled: false,
                selectedItems: []
            };
            this.handleKeyboardKeydown = this.handleKeyboardKeydown.bind(this);
            this.handleKeyboardKeyup = this.handleKeyboardKeyup.bind(this);
        }

        componentDidMount() {
            root.addEventListener(EVENT_DOM_KEYDOWN, this.handleKeyboardKeydown);
            root.addEventListener(EVENT_DOM_KEYUP, this.handleKeyboardKeyup);
        }

        componentDidUpdate(prevProps) {
            const { isSearchActive } = this.props;
            if (prevProps.isSearchActive !== this.props.isSearchActive) {
                setTimeout(() => {
                    this.setState({
                        selectedIndex: isSearchActive ? 0 : -1
                    });
                });
            }
        }

        componentWillUnmount() {
            root.removeEventListener(EVENT_DOM_KEYDOWN, this.handleKeyboardKeydown);
            root.removeEventListener(EVENT_DOM_KEYUP, this.handleKeyboardKeyup);
        }

        itemSelectHandler = (item) => {
            const {
                allowMultipleSelections,
                isMultipleSelectionEnabled
            } = this.state;

            if (allowMultipleSelections && isMultipleSelectionEnabled) {
                this.selectMultipleItemsHandler(item);
            } else {
                this.props.itemSelected(item);
            }
        }

        selectMultipleItemsHandler(item) {
            const selectedItems = [...this.state.selectedItems];

            const itemIndex = selectedItems.findIndex(selectedItem => {
                // todo match entire object instead using specific field names
                // for now multiple selection is available for products only
                return selectedItem.productId === item.productId;
            });

            if (itemIndex !== -1) {
                selectedItems.splice(itemIndex, 1);
            } else {
                selectedItems.push(item);
            }

            this.setState({
                selectedItems
            });
        }

        handleKeyboardKeydown = (ev) => {
            if (!this.props.isSearchActive) {
                return;
            }

            const {
                allowMultipleSelections,
                selectedIndex
            } = this.state;
            const { searchableItems } = this.props;

            switch (ev.which) {
                case KEYCODE_CTRL:
                    if (allowMultipleSelections) {
                        this.setState({
                            selectedIndex: -1,
                            isMultipleSelectionEnabled: true
                        });
                    }
                    break;

                case KEYCODE_KEY_DOWN:
                    if (selectedIndex < searchableItems.length - 1) {
                        this.setState({
                            selectedIndex: selectedIndex + 1
                        });
                    }
                    if (selectedIndex >= searchableItems.length) {
                        this.setState({
                            selectedIndex: 0
                        });
                    }
                    break;

                case KEYCODE_KEY_UP:
                    if (selectedIndex > 0) {
                        this.setState({
                            selectedIndex: selectedIndex - 1
                        });
                    }
                    if (selectedIndex >= searchableItems.length) {
                        this.setState({
                            selectedIndex: searchableItems.length - 1
                        });
                    }
                    break;

                case KEYCODE_KEY_ENTER: {
                    if (searchableItems.length > 0) {
                        const item = searchableItems[selectedIndex];
                        if (item) {
                            this.itemSelectHandler(item);
                        }
                    } else {
                        this.itemSelectHandler(null);
                    }
                    break;
                }

                default:
                    this.setState({
                        selectedIndex: 0
                    });
                    break;
            }
        }

        handleKeyboardKeyup = (ev) => {
            if (!this.props.isSearchActive) {
                return;
            }

            const { multipleItemsSelected } = this.props;

            switch (ev.which) {
                case KEYCODE_CTRL: {
                    if (!this.state.isMultipleSelectionEnabled) {
                        break;
                    }

                    const selectedItems = [...this.state.selectedItems];

                    this.setState({
                        selectedIndex: 0,
                        selectedItems: []
                    });

                    if (selectedItems.length) {
                        multipleItemsSelected(selectedItems);
                    }

                    break;
                }

                default:
                    break;
            }
        }

        render() {
            return (
                <ComposedComponent
                    itemSelectHandler={ this.itemSelectHandler }
                    selectedItems={ this.state.selectedItems }
                    selectedIndex={ this.state.selectedIndex }
                    { ...this.props }
                />
            );
        }
    }

    return KeyboardSelectionHOC;
}
