import { combineReducers } from 'redux';
import {
    SET_LOCATION_PATH,
    SET_PREV_LOCATION_PATH,
    SET_FOCUS_ID,
    SET_HG4SF_ID,
    SET_APP_ERROR,
    SET_FEATURE_FLAGS
} from './constants';

export const locationPathInitialState = null;
export const prevLocationPathInitialState = null;
export const focusExtensionIdInitialState = null;
export const extensionIdInitialState = null;
export const appErrorInitialState = null;
export const variablesInitialState = {};
export const featureFlagsInitialState = {};

export function locationPath(state = locationPathInitialState, action) {
    switch (action.type) {
        case SET_LOCATION_PATH:
            return action.locationPath;

        default:
            return state;
    }
}

export function prevLocationPath(state = prevLocationPathInitialState, action) {
    switch (action.type) {
        case SET_PREV_LOCATION_PATH:
            return action.locationPath;

        default:
            return state;
    }
}

export function focusExtensionId(state = focusExtensionIdInitialState, action) {
    switch (action.type) {
        case SET_FOCUS_ID:
            return action.focusExtensionId;

        default:
            return state;
    }
}

export function HG4SFExtensionId(state = extensionIdInitialState, action) {
    switch (action.type) {
        case SET_HG4SF_ID:
            return action.extensionId;

        default:
            return state;
    }
}

export function applicationError(state = appErrorInitialState, action) {
    switch (action.type) {
        case SET_APP_ERROR:
            return action.applicationError;

        default:
            return state;
    }
}

export function variables(state = variablesInitialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export function featureFlags(state = featureFlagsInitialState, action) {
    switch (action.type) {
        case SET_FEATURE_FLAGS:
            return action.flags;

        default:
            return state;
    }
}

export default combineReducers({
    locationPath,
    prevLocationPath,
    focusExtensionId,
    HG4SFExtensionId,
    applicationError,
    variables,
    featureFlags
});
