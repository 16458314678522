import { default as moment } from 'moment';
import { TAXONOMY_PERMISSION_TYPE } from 'shared/constants';

const dataPermissionTypes = [
    TAXONOMY_PERMISSION_TYPE.CATEGORY,
    TAXONOMY_PERMISSION_TYPE.PARENT_CATEGORY,
    TAXONOMY_PERMISSION_TYPE.VENDOR,
    TAXONOMY_PERMISSION_TYPE.PRODUCT,
    TAXONOMY_PERMISSION_TYPE.ATTRIBUTE
];

export default class UserUtils {

    /**
     * Determine if a user has permissions based on ther account details data
     * @param {object} userData -> Account details data
     * @returns {boolean}
     */
    static hasDataPermissions(userData) {
        let hasPermissions = false;

        if (userData && userData.permissions) {
            // check if user has at least one data permission set
            for (const permissionType of dataPermissionTypes) {
                const userPermission = userData.permissions[permissionType];
                hasPermissions = userPermission.include.length || userPermission.exclude.length;
                if (hasPermissions) {
                    break;
                }
            }

            // check if user has all data permissions set
            hasPermissions = hasPermissions || userData.permissions.all;
        }

        return hasPermissions;
    }

    static hasLinkedInEnabled(userData) {
        let linkedInEnabled = false;

        if (userData && userData.linkedinEnabled) {
            linkedInEnabled = true;
        }

        return linkedInEnabled;
    }

    /**
     * Determine if a user is a paid user type
     * @param {object} userData -> Account details data
     * @returns {boolean}
     */
    static isPaid(userData) {
        // User is paid if it has at least one data permission set
        return UserUtils.hasDataPermissions(userData);
    }

    static isPaidAndDownloadDisabled(userData) {
        return userData && UserUtils.isPaid(userData) && !userData.downloadEnabled;
    }

    static getCampaignTrialInfo(userData) {
        if (!userData) {
            return {
                isActive: false,
                remainingDays: 0,
                trialEndDate: null
            };
        }
        const currentDate = moment().startOf('day');
        const endTrialDate = moment(userData.trialEndDate, 'YYYY/MM/DD');
        const remainingDays = endTrialDate.diff(currentDate, 'days');

        return {
            isActive: userData.trialActive,
            remainingDays: remainingDays < 0 ? 0 : remainingDays,
            trialEndDate: userData.trialEndDate
        };
    }

    /**
     * Determine if the trial period has ended for non paid users
     * @param { object } accountDetails - contain details about de current logged in user
     * @returns { boolean }
     */
    static isTrialExpired(accountDetails) {
        if (!accountDetails) {
            return false;
        }
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        const isPaidUser = UserUtils.isPaid(accountDetails);

        return !isPaidUser && campaignTrialInfo.isActive && campaignTrialInfo.remainingDays === 0;
    }

    /**
     * Gets an allowed technologies slugs list or null
     * @param {object} userData -> Account details data
     * @param {object} technologiesPermissions -> Allowed technologies data
     * @returns {(Array|null)}
     */
    static getAllowedSlugs(userData, technologiesPermissions) {
        if (!userData || !technologiesPermissions) {
            return null;
        }

        const isPaidAccount = this.isPaid(userData);
        const paidAccountWithAllPermissions = technologiesPermissions.all;
        const freeAccountWithNoPermissions =
            !technologiesPermissions.all && !technologiesPermissions.slugs.length;

        return paidAccountWithAllPermissions || freeAccountWithNoPermissions || !isPaidAccount
                ? null
                : technologiesPermissions.slugs;
    }
}
