import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';
import { UPLOAD_FILE_TIMEOUT } from './constants';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton class that encapsulates product related API calls
 */
export default class ProductService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new ProductService(singletonEnforcer);
        }
        return this[singleton];
    }

    /**
     * Get the product details.
     * @param {string} productName The roduct name.
     * @returns {object} The promise object of the api call.
     */
    getProductDetailsById(productId) {
        return ApiWrapper.instance.axios.get(`data/products/details/${productId}`);
    }

    /**
     * Get the top companies using the product.
     * @param {int} productId The id of the product.
     * @param {int} count Top count companies using the product.
     * @param {string} country The country abbreviation ( ie USA -> US)
     * @returns {object} The promise object of the api call.
     */
    getSampleCompaniesByProductId(productId, count, countries) {
        return ApiWrapper.instance.axios.get(`data/products/installs/${productId}`, {
            params: {
                count,
                countries
            }
        });
    }

    /**
     * Get top products
     * @param {int} count Top count products.
     * @returns {object} The promise object of the api call.
     */
    getPopularTechnologies(slugs) {
        return ApiWrapper.instance.axios.get('data/products/installs', {
            params: {
                slugs
            }
        });
    }

    /**
     * Get similar products for a product id
     * @param {int} productId
     * @param {int} count
     * @returns {object} The promise object of the api call.
     */
    getSimilarProducts(productId, count) {
        return ApiWrapper.instance.axios.get(`/data/products/relatedTechnologies/${productId}`, {
            params: {
                count
            }
        });
    }

    /**
     * Get the product's insights
     * @param {int} productId The id of the product.
     * @returns {object} The promise object of the api call.
     */
    getProductInsights(productId) {
        return ApiWrapper.instance.axios.get(`data/products/insights/${productId}`);
    }

    /**
     * Get the product's firmograhic data
     * @param {int} productId The id of the product.
     * @returns {object} The promise object of the api call.
     */
    getProductFirmographics(productId) {
        return ApiWrapper.instance.axios.get(`/data/products/${productId}/firmographics`);
    }

    downloadSample(productId) {
        return ApiWrapper.instance.axios.get(`/data/products/samples/${productId}`);
    }

    uploadFile(fileName, data, technologies, onUploadProgress, source = '/account/upload') {
        return ApiWrapper.instance.axios.put(source,
            data, {
                params: {
                    fileName,
                    technologies
                },
                timeout: UPLOAD_FILE_TIMEOUT,
                onUploadProgress
            });
    }

    getFiles() {
        return ApiWrapper.instance.axios.get('/account/upload');
    }

    uploadFileFailure(fileName, data, technologies) {
        return ApiWrapper.instance.axios.put('/account/upload/failed', { size: data }, {
            params: {
                fileName,
                technologies
            }
        });
    }

    updateFavoriteProduct(productName, isFavorite) {
        let promiseObject = null;
        if (isFavorite) {
            promiseObject = ApiWrapper.instance.axios.post('favorites/products', {}, {
                params: {
                    product_name: productName
                }
            });
        } else {
            promiseObject = ApiWrapper.instance.axios.delete('favorites/products', {
                params: {
                    product_name: productName
                }
            });
        }
        return promiseObject;
    }

    getCollectionDetails(slug) {
        return ApiWrapper.instance.axios.get(`/collections/${slug}`);
    }

    getCollectionsBySlugs(slugs) {
        return ApiWrapper.instance.axios.get('/collections', {
            params: {
                slugs
            }
        });
    }

    getCollectionsPricing(slugs) {
        return ApiWrapper.instance.axios.get('/collections/pricing/', {
            params: {
                slug: slugs
            }
        });
    }

    getMoversAndShakers() {
        return ApiWrapper.instance.axios.get('/segments/moversShakers');
    }
}
