import React from 'react';
import Loader from 'shared/ui/loader/Loader';
import ProductDetails from './ProductDetails';
import InsightsWidget from './InsightsWidget';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';

export default class ProductTopBanner extends React.Component {

    render() {
        const { sampleCompanies, productDetails } = this.props;
        const isFetching = productDetails.isFetching ||
            !sampleCompanies.isFetched;

        let content;
        if (productDetails.error) {
            content = <div>{ productDetails.error.message }</div>;
        } else if (isFetching) {
            content = <Loader className="white" />;
        } else {
            content = (
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <ProductDetails {...this.props} />
                    </div>

                    <div className="col-12 col-lg-5">
                        <InsightsWidget {...this.props} />
                    </div>
                </div>
            );
        }
        return (
            <PanelWrapper className="product-top-banner">
                { content }
            </PanelWrapper>
        );
    }
}
