import { browserHistory } from 'react-router';
import {
    SET_GLOBAL_PLATFORM_ERROR
} from './constants';

export function setPlatformGlobalError(error) {
    return (dispatch) => {
        dispatch({
            type: SET_GLOBAL_PLATFORM_ERROR,
            error
        });

        if (error) {
            browserHistory.push('/dashboard/platform-error');
        }
    };
}
