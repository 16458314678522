import d3 from 'd3';
import {
    HORIZONTAL_BARCHART_VERTICAL_MARGINS,
    HORIZONTAL_BARCHART_HORIZONTAL_MARGINS,
    CHART_HEIGHT_WIDTH_ASPECT_RATIO
} from './constants';
import usTopology from './us-topology.json';

/**
* US Map Chart
*/
export default class UsMapChart {

    constructor(el, props = {}) {
        this.el = el;
        this.props = props;

        this.verticalMargin = HORIZONTAL_BARCHART_VERTICAL_MARGINS;
        this.horizontalMargin = HORIZONTAL_BARCHART_HORIZONTAL_MARGINS;
        this.tooltipText = props.tooltipText;

        this.drawChart(this.props);

        // on window rezise automatically adjust bar chart size
        this.handleResizeHandler = this.handleResize.bind(this);
        window.addEventListener('resize', this.handleResizeHandler);
    }

    handleResize() {
        if (this.svg) {
            this.svg.remove();
        }
        this.drawChart(this.props);
    }

    drawChart(props = {}) {
        this.chartContainerWidth = this.el.offsetWidth;
        this.chartContainerHeight = this.el.offsetWidth * CHART_HEIGHT_WIDTH_ASPECT_RATIO;

        this.width = this.chartContainerWidth - 2 * this.horizontalMargin;
        this.height = this.chartContainerHeight - 2 * this.verticalMargin;

        // D3 Projection
        const scale = this.width * 1.25;
        const projection = d3.geo.albersUsa()
            .translate([this.width / 2, this.height / 2])
            .scale([scale]);

        // Define path generator
        this.path = d3.geo.path()
            .projection(projection);

        // Create SVG element and append map to the SVG
        this.svg = d3.select('.locations-map-container')
            .append('svg')
            .attr('width', this.width)
            .attr('height', this.height);

        // Append Div for tooltip to SVG
        if (!this.tooltip) {
            this.tooltip = d3.select('body')
                .append('div')
                .style('position', 'absolute')
                .style('z-index', 500);
        }
        // update chart with data
        this.update(props);
    }

    /**
    * Create and configure the tooltip
    */
    configureTooltip(props) {
        this.createTooltip = props.tooltip;
        if (!this.createTooltip) return;
        // remove all existing divs from the tooltip
        this.tooltip
            .selectAll('div')
            .remove();
        // intialize the styling
        this.tooltip
            .style('display', 'none');
        // create a div for each of the tooltip props
        this.tooltip
            .attr('class', 'locations-tooltip d3-tip animate')
            .append('div')
                .attr('class', '.tooltip');
    }

    update(props) {
        this.props = props;
        const data = this.props.data || [];

        this.tooltip
            .selectAll('div')
            .remove();

        // Map the firmographic data to the topology map
        const installs = [];
        const states = JSON.parse(JSON.stringify(usTopology.states));
        states.forEach((state) => {
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                const mapItem = state;
                if (item.name === mapItem.properties.code) {
                    installs.push(item.value);
                    mapItem.properties.installs = item.value;
                    mapItem.properties.tooltip = item.tooltip;
                    break;
                }
            }
        });

        // const opacity = d3.scale.linear()
        const opacity = d3.scale.sqrt()
            .domain([d3.min(installs), d3.max(installs)])
            .range([0.1, 1]);

        // Draw states
        this.svg.selectAll('path')
            .data(states)
            .enter()
            .append('path')
            .attr('d', this.path)
            .style('stroke', '#fff')
            .style('stroke-width', '1')
            .style('fill', this.props.color || '#006699')
            .style('fill-opacity', d => { return opacity(d.properties.installs); })
            .on('mouseover', this._tooltipMouseOver)
            .on('mousemove', this._tooltipMouseOver)
            .on('mouseout', this._tooltipMouseOut)
            .on('click', (d) => {
                try {
                    if (this.props.onClick) {
                        this.props.onClick(d);
                    }
                } catch (error) {
                    // noop
                }
            });

        this.configureTooltip(props);
    }


    /**
    * On mouseover of a bubble, populate the tooltip with that elements info
    * (if this.createTooltip is true of course)
    */
    _tooltipMouseOver = (d) => {
        if (!this.createTooltip) {
            return;
        }

        try {
            this.tooltip
                .html(d.properties.tooltip)
                .style('display', 'block');

            const tooltipNode = this.tooltip.node();
            const width = tooltipNode.offsetWidth + 1; // +1 for rounding reasons
            const height = tooltipNode.offsetHeight;

            const top = d3.event.pageY - height - 15;
            const left = d3.event.pageX - width / 2;

            this.tooltip
                .style('left', `${left}px`)
                .style('top', `${top}px`);
        } catch (error) {
            // noop
        }
    }

    /**
    * On tooltip mouseout, hide the tooltip.
    */
    _tooltipMouseOut = () => {
        if (!this.createTooltip) {
            return;
        }

        try {
            this.tooltip
                .style('display', 'none')
                .style('top', '')
                .style('left', '');
        } catch (error) {
            // noop
        }
    }

    destroy() {
        this.tooltip.remove();
        window.removeEventListener('resize', this.handleResizeHandler);
    }
}
