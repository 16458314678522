import React from 'react';

export default class ModalWizardHeader extends React.Component {

    render() {
        return (
            <div className="wizard-modal-header">
                {
                    this.props.onBack ? (
                        <span
                            onClick={ this.props.onBack }
                            className="back icon icon-arrow-left"
                            data-qa="back-button"
                        />
                    ) : null
                }
                <div className="header-title">
                    { this.props.customHeader || this.props.title }
                </div>
                <span
                    onClick={ this.props.onClose }
                    className="close icon icon-close-x"
                    data-qa="close-button"
                />
            </div>
        );
    }
}
