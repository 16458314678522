import UserService from 'common/services/UserService';
import { loadAccountDetails } from 'shared/user/accountDetails/actions';
import ProductService from 'common/services/ProductService';
import {
    UPLOAD_FILE_REQUEST,
    FILE_SIZE_LIMIT,
    UPLOAD_FILE_FAILURE,
    FILE_SIZE_TOO_LARGE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_CLEAN,
    UPLOAD_FILE_PROGRESS,
    UPLOAD_FILE_ON_HOLD,
    ADD_PHONE_NUMBER_REQUEST,
    ADD_PHONE_NUMBER_SUCCESS,
    ADD_PHONE_NUMBER_FAILURE
} from './constants';
import { NumeralUtils } from 'utils/Utils';

export function uploadFileOnHold(file) {
    return {
        type: UPLOAD_FILE_ON_HOLD,
        file
    };
}

export function uploadFile(file, technologies, bowser = false) {
    return (dispatch) => {
        let promise;
        dispatch({
            type: UPLOAD_FILE_REQUEST,
            file
        });

        function onUploadProgress(event) {
            dispatch({
                type: UPLOAD_FILE_PROGRESS,
                progress: NumeralUtils.formatIntegerNumber((event.loaded / event.total) * 100)
            });
        }

        if (file.size > FILE_SIZE_LIMIT) {
            promise = ProductService.instance.uploadFileFailure(file.name, file.size, technologies)
                .then(() => {
                    dispatch({
                        type: UPLOAD_FILE_FAILURE,
                        error: FILE_SIZE_TOO_LARGE
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: UPLOAD_FILE_FAILURE,
                        error: error.message
                    });
                });
        } else {
            const source = bowser ? '/account/upload/bowser' : '/account/upload';
            promise = ProductService.instance.uploadFile(file.name, file, technologies,
                onUploadProgress, source).then((response) => {
                    dispatch({
                        type: UPLOAD_FILE_SUCCESS
                    });
                    return response.data;
                })
                .catch((error) => {
                    dispatch({
                        type: UPLOAD_FILE_FAILURE,
                        error: error.message
                    });
                });
        }
        return promise;
    };
}

export function uploadFileClean() {
    return {
        type: UPLOAD_FILE_CLEAN
    };
}

export function addPhoneNumber(phoneNumber) {
    return (dispatch) => {
        dispatch({
            type: ADD_PHONE_NUMBER_REQUEST
        });

        // Dispatch vanilla actions asynchronously
        return UserService.instance.addPhoneNumber(phoneNumber)
            .then((response) => {
                const data = response.data;
                dispatch({
                    type: ADD_PHONE_NUMBER_SUCCESS,
                    data
                });
                dispatch(loadAccountDetails());
            })
            .catch((error) => {
                dispatch({
                    type: ADD_PHONE_NUMBER_FAILURE,
                    error
                });
            });
    };
}
