import React from 'react';
import ReactDOM from 'react-dom';
import { NumeralUtils } from 'utils/Utils';
import UsMapChart from 'shared/ui/charts/UsMapChart';
import {
    FILTERS_LOCATION_TYPE_COUNTRY,
    FILTERS_LOCATION_TYPE_REGION
} from 'myhg/segment/segmentFilters/constants';
import { setLocations } from 'myhg/segment/segmentFilters/locationsFilter/actions';

/**
 * Component that renders the Locations Chart
 */
export default class LocationsChart extends React.Component {

    componentDidMount() {
        this.locationsChart = new UsMapChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        this.locationsChart.update(this.getChartState());
    }

    componentWillUnmount() {
        this.locationsChart.destroy();
    }

    onUsMapSelect = () => {
        const { dispatch } = this.props;
        const locations = {
            [FILTERS_LOCATION_TYPE_COUNTRY]: ['US'],
            [FILTERS_LOCATION_TYPE_REGION]: []
        };

        this.props.createNewProductSegment();
        dispatch(setLocations(locations));
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getChartState() {
        const data = this.props.data || [];
        const chartData = [];
        data.forEach((item) => {
            chartData.push(Object.assign({}, item, {
                tooltip: `<span>Number of companies in ${item.name}<br />
                    <span class='high-light'>
                        ${NumeralUtils.formatIntegerNumber(item.value)}
                    </span>`
            }));
        });
        return {
            data: chartData,
            chartHeight: 630,
            tooltip: true,
            color: '#16a6ba', // $primary-50
            onClick: this.onUsMapSelect
        };
    }

    render() {
        return (
            <div className="locations-map-container"></div>
        );
    }
}
