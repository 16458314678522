import React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { browserHistory } from 'react-router';
import sprintf from 'sprintf-js';
import UserUtils from 'utils/UserUtils';
import Loader from 'shared/ui/loader/Loader';
import {
    SEGMENT_APPLY_FILTERS_BUTTON_TEXT,
    SEGMENT_APPLY_FILTERS_SAVE_BUTTON_TEXT,
    SEGMENT_CLOSE_FILTER_BUTTON_TEXT,
    SEGMENT_CREATE_BUTTON_TEXT,
    COMPARABLE_FILTERS
} from '../constants';
import {
    FIRMOGRAPHIC_FILTERS,
    LIMIT_TECH_EXCEEDED,
    FILTER_COMPANIES_TITLE
} from './constants';
import SegmentSummaryFiltersContainer from
    '../segmentSummaryFilters/SegmentSummaryFiltersContainer';
import TechnologiesFilterContainer from '../technologiesFilter/TechnologiesFilterContainer';
import UnknownValuesFilterContainer from '../unknownValuesFilter/UnknownValuesFilterContainer';
import { extractSegmentFiltersFromState } from '../actions';
import UsageTimeFrame from './UsageTimeFrame';
import { FILTER_PRODUCT_MAX_COUNT } from '../technologiesFilter/constants';

export default class SegmentSetupFilters extends React.Component {

    onCloseFilters = () => {
        const { currentSegment } = this.props.segment;

        if (!currentSegment.id) {
            browserHistory.goBack();
        } else {
            this.props.onViewToggle();
        }
    }

    getTooltip(tooltipData) {
        let content = null;
        if (tooltipData) {
            content = [
                <ReactTooltip
                    effect="solid"
                    place="top"
                    id={ tooltipData.id }
                    offset={ { right: 12 } }
                    key="tooltip-component"
                />,
                <span
                    className="tooltip-info icon-info"
                    data-for={ tooltipData.id }
                    data-tip={ tooltipData.message }
                    data-html
                    key="tooltip-span"
                />
            ];
        }

        return content;
    }

    getFilterRow(filter) {
        const SpecificFilter = filter.component;
        const { segmentFiltersView } = this.props;

        return (
            <div
                id={ `${filter.text.toLowerCase()}-filter` }
                key={ filter.text }
                className={ `filter-row ${filter.type}` }
            >
                <div className="filter-cell filter-name">
                    { filter.text }
                    { this.getTooltip(filter.tooltipData) }
                </div>
                <div className="filter-cell filter">
                    <SpecificFilter />
                </div>
                <div className="filter-cell filter-check">
                    {
                        segmentFiltersView.isAdvancedOpen ? (
                            <UnknownValuesFilterContainer
                                unknownValuesId={ filter.unknownValueFilter }
                            />
                        ) : null
                    }
                </div>
            </div>
        );
    }

    getAdvancedToggleClick() {
        const { segmentFiltersView, onAdvancedViewToggle } = this.props;
        const toggleSpanClassName = classNames('panel-icon', {
            'icon-small-arrow-down': !segmentFiltersView.isAdvancedOpen,
            'icon-small-arrow-up': segmentFiltersView.isAdvancedOpen
        });

        return (
            <a className="toggle-filters advanced" onClick={ onAdvancedViewToggle }>
                Advanced
                <span className={ toggleSpanClassName } />
            </a>
        );
    }

    getFirmographicsFilters() {
        const { segmentFiltersView } = this.props;
        const firmoFilters = FIRMOGRAPHIC_FILTERS.map((filter) => {
            return this.getFilterRow(filter);
        });

        const header = (
            <div className="filter-row title">
                <div className="filter-cell filter-name">
                    <h3>{ FILTER_COMPANIES_TITLE }</h3>
                </div>
                <div className="filter-cell filter" />
                <div className="filter-cell filter-check title">
                    <span className="column-title">
                        {
                            !segmentFiltersView.isAdvancedOpen ?
                            '' : 'Include companies with unknown values'
                        }
                    </span>
                    <span className="toggle-filters-advanced">
                        { this.getAdvancedToggleClick() }
                    </span>
                </div>
            </div>
        );

        return (
            <div className="filters-section">
                { header }
                { firmoFilters }
            </div>
        );
    }

    getTechnologiesFilter() {
        return (
            <div className="filters-section">
                <TechnologiesFilterContainer />
            </div>
        );
    }

    getUsageTimeframe() {
        return (
            <UsageTimeFrame { ...this.props } />
        );
    }

    getPlusIcon() {
        const { onViewToggle, disabled } = this.props;

        if (disabled) {
            return null;
        }

        return (
            <a className="plus-filters" onClick={ onViewToggle } data-qa="edit-segment">
                <span className="icon-plus" />
            </a>
        );
    }

    getCloseFilterButton() {
        return (
            <button
                id="close-filters-button"
                className="btn btn-link primary"
                onClick={ this.onCloseFilters }
                data-qa="close-filters"
            >
                { SEGMENT_CLOSE_FILTER_BUTTON_TEXT }
            </button>
        );
    }

    getApplyFiltersButton() {
        const { isUserAuthenticated } = this.props;
        const { segmentFilters, currentSegment, segmentSave } = this.props.segment;
        const { collections, technologies } = segmentFilters;

        const newFilters = !currentSegment.id ? {} : extractSegmentFiltersFromState(segmentFilters);
        const oldFilters = currentSegment.data.filters;
        const newFiltersStr = JSON.stringify(newFilters, COMPARABLE_FILTERS);
        const oldFiltersStr = JSON.stringify(oldFilters, COMPARABLE_FILTERS);
        let content;

        if (!currentSegment.id || (newFiltersStr !== oldFiltersStr)) {
            const collectionsSlugs = collections.length ? collections.map(collection => {
                return collection.techSlugs;
            }).reduce((a, b) => { return a.concat(b); }) : [];

            const technologiesCount = [...new Set([...collectionsSlugs, ...technologies])].length;

            const buttonApplyFiltersText = isUserAuthenticated ?
                SEGMENT_APPLY_FILTERS_SAVE_BUTTON_TEXT : SEGMENT_APPLY_FILTERS_BUTTON_TEXT;
            const buttonSaveText = this.props.hasFilters() ?
                buttonApplyFiltersText : SEGMENT_CREATE_BUTTON_TEXT;
            const isTechsFilterLimitExceeded =
                !this.isUserPaid() && technologiesCount > FILTER_PRODUCT_MAX_COUNT;
            const isButtonDisabled =
                segmentSave.inProgress || !technologiesCount || isTechsFilterLimitExceeded;

            content = (
                <div className="segment-filters-action">
                    <button
                        id="save-filters"
                        disabled={ isButtonDisabled }
                        className="btn btn-primary"
                        data-qa="save-filters"
                        onClick={ isUserAuthenticated ?
                            this.props.saveSegmentFilters : this.props.applyFilters }
                    >
                        {
                            !isTechsFilterLimitExceeded ?
                            buttonSaveText :
                            sprintf.sprintf(LIMIT_TECH_EXCEEDED, FILTER_PRODUCT_MAX_COUNT)
                        }
                        { segmentSave.inProgress ?
                            <Loader className="small-loader white" /> : null
                        }
                    </button>
                    { this.getCloseFilterButton() }
                </div>
            );
        } else {
            content = (
                <div className="segment-filters-action">
                    { this.getCloseFilterButton() }
                </div>
            );
        }

        return content;
    }

    isUserPaid() {
        const { accountDetails } = this.props;
        return UserUtils.isPaid(accountDetails);
    }

    render() {
        const { isFiltersEditOpen } = this.props;
        const filtersContainerClass = classNames('segment-filters-container', {
            open: isFiltersEditOpen
        });

        return (
            <div className={ filtersContainerClass } data-qa="segment-filters-container">
                { !isFiltersEditOpen ? <h4>SEGMENT FILTERS</h4> : null }
                <div>
                    { !isFiltersEditOpen ? (<SegmentSummaryFiltersContainer />) : null }
                    { !isFiltersEditOpen ? this.getPlusIcon() : null }
                </div>
                {
                    isFiltersEditOpen ? (
                        <div className="filters-section-container">
                            { this.getTechnologiesFilter() }
                            { this.getFirmographicsFilters() }
                            { this.getUsageTimeframe() }
                            { this.getApplyFiltersButton() }
                        </div>
                    ) : null
                }
            </div>
        );
    }
}
