import React from 'react';
import classNames from 'classnames';

export default class SearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        };

        this.changeInputValue = this.handleTyping.bind(this);
    }

    componentWillUpdate(nextProps) {
        // clear input when typeahead is closed
        if (!nextProps.isOpen && this.props.isOpen) {
            this.setState({
                inputValue: ''
            });
        }
    }

    clearText() {
        this.setState({
            inputValue: ''
        });
    }

    handleTyping(ev) {
        this.setState({
            inputValue: ev.target.value
        });
        this.props.onSearchTextChange(ev.target.value);
    }

    render() {
        const inputClassName = classNames('search-input',
            { open: this.props.isOpen }
        );
        const iconClassName = classNames('typeahead-icon', {
            'icon-small-arrow-up': this.props.isOpen,
            'icon-small-arrow-down': !this.props.isOpen
        });
        return (
            <div className={ inputClassName } onClick={ this.props.onToggleOpenState } >
                <input
                    placeholder={ this.props.placeholder ? this.props.placeholder : '' }
                    ref="searchInput"
                    className="search-input"
                    value= { this.state.inputValue }
                    onChange={ this.changeInputValue }
                    type="text"
                />
                <span className={ iconClassName }></span>
            </div>
        );
    }
}
