import PlatformService from 'common/services/PlatformService';
import {
    VALIDATE_PLATFORM_TOKEN_REQUEST,
    VALIDATE_PLATFORM_TOKEN_SUCCESS,
    VALIDATE_PLATFORM_TOKEN_FAILURE,
    VALIDATE_PLATFORM_TOKEN_RESET
} from './constants';

export function validatePlatformToken(sessionId) {
    return (dispatch) => {
        dispatch({
            type: VALIDATE_PLATFORM_TOKEN_REQUEST
        });

        return PlatformService.instance.checkToken(sessionId)
            .then(response => {
                dispatch({
                    type: VALIDATE_PLATFORM_TOKEN_SUCCESS,
                    data: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: VALIDATE_PLATFORM_TOKEN_FAILURE,
                    error
                });
            });
    };
}

export function resetPlatformTokenCheck() {
    return (dispatch) => {
        return dispatch({
            type: VALIDATE_PLATFORM_TOKEN_RESET
        });
    };
}
