import React from 'react';
import classNames from 'classnames';
import {
    INPUT,
    TEXTAREA
} from './constants';

/* eslint-disable no-unused-vars */
// TODO: remove once upgraded to redux-form v6, which makes this redundant.
// Using destructuring to pick out the props that redux form cares about so
// that we can pass back everything else
const domOnlyProps = ({
    initialValue,
    autofill,
    onUpdate,
    valid,
    invalid,
    dirty,
    pristine,
    active,
    touched,
    visited,
    autofilled,
    error,
    fieldType,
    feedback,
    feedbackHandler,
    ...domProps
}) => { return domProps; };

const removeValueProp = ({
    value,
    ...domProps
}) => { return domProps; };
/* eslint-enable no-unused-vars */

export default class ValidationField extends React.Component {

    getFeedback() {
        const { feedback } = this.props;
        return (
            <label
                className="feedback clickable-text"
                onClick={ () => {
                    return this.props.feedbackHandler ? this.props.feedbackHandler() : null;
                } }
                data-qa="forgot-link"
            >
                { feedback }
            </label>
        );
    }

    render() {
        let content = null;
        const { error, touched, feedback, label } = this.props;
        const inputClass = classNames(
            this.props.className,
            {
                'validate-form-control': true,
                'has-error': error && touched,
                'has-feedback': !!feedback
            }
        );

        let cleanedProps = domOnlyProps(this.props);
        if (this.props.defaultValue && this.props.defaultValue > '') {
            // props may only have "value" or "defaultValue", not both.
            cleanedProps = removeValueProp(cleanedProps);
        }

        let control;
        switch (this.props.fieldType) {
            case INPUT:
                control = <input {...cleanedProps} />;
                break;
            case TEXTAREA:
                control = <textarea {...cleanedProps} />;
                break;

            default:
                control = <input {...cleanedProps} />;
                break;
        }

        content = (
            <div className={ inputClass }>
                <label className="input-label">{ label }</label>
                <div className="input-container">
                    { control }
                    { touched && error && <label className="error truncate">{ error }</label> }
                    { feedback && this.getFeedback() }
                </div>
            </div>
        );
        return content;
    }
}
