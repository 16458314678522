import {
    MATCH_SOURCE_TYPE_SALESFORCE,
    MATCH_SOURCE_TYPE_PARDOT,
    MATCH_SOURCE_TYPE_MARKETO,
    MATCH_SOURCE_TYPE_FILE,
    MATCH_SOURCE_TYPE_ELOQUA,
    MATCH_SOURCE_TYPE_LINKEDIN
} from './segmentMatches/constants';
import { NumeralUtils } from 'utils/Utils';
import { LANDING_PAGE_TYPES } from 'discovery/authenticationModal/constants';
import { PLATFORM_SOURCE } from 'shared/constants';
// export const PLATFORM_SOURCE = {
//     SALESFORCE: 'salesforce',
//     MARKETO: 'marketo'
// };

// load segment
export const SEGMENT_LOAD_SUCCESS = 'SEGMENT_LOAD_SUCCESS';
export const SEGMENT_LOAD_FAILURE = 'SEGMENT_LOAD_FAILURE';
export const SEGMENT_LOAD_REQUEST = 'SEGMENT_LOAD_REQUEST';
// save segment
export const SEGMENT_SAVE_SUCCESS = 'SEGMENT_SAVE_SUCCESS';
export const SEGMENT_SAVE_FAILURE = 'SEGMENT_SAVE_FAILURE';
export const SEGMENT_SAVE_REQUEST = 'SEGMENT_SAVE_REQUEST';

// update segment (locally only)
export const SEGMENT_UPDATE = 'SEGMENT_UPDATE';
export const SEGMENT_UPDATE_FILTERS = 'SEGMENT_UPDATE_FILTERS';
// MISC
export const SEGMENT_NAME_CHANGED = 'SEGMENT_SEGMENT_NAME_CHANGED';
export const SEGMENT_DOWNLOAD_CSV_REQUEST = 'SEGMENT_DOWNLOAD_CSV_REQUEST';
export const SEGMENT_DOWNLOAD_CSV_SUCCESS = 'SEGMENT_DOWNLOAD_CSV_SUCCESS';
export const SEGMENT_DOWNLOAD_CSV_FAILURE = 'SEGMENT_DOWNLOAD_CSV_FAILURE';
export const SEGMENT_FILTERS_CHANGED = 'SEGMENT_FILTERS_CHANGED';
export const SEGMENT_FILTERS_SET = 'SEGMENT_FILTERS_SET';
export const SEGMENT_NEW = 'SEGMENT_NEW';
export const SEGMENT_RESET_VIEW = 'SEGMENT_RESET_VIEW';
export const SEGMENT_FILTERS_EDIT_VIEW_CHANGE = 'SEGMENT_FILTERS_EDIT_VIEW_CHANGE';
export const SEGMENT_NAME_EDIT_VIEW_CHANGE = 'SEGMENT_NAME_EDIT_VIEW_CHANGE';
export const SEGMENT_APPLY_FILTERS_BUTTON_TEXT = 'Create Target Segment';
export const SEGMENT_DELETE_SUCCESS = 'SEGMENT_DELETE_SUCCESS';

export const LINKEDIN_MAX_SEGMENT_SIZE = 300000;
/* eslint-disable max-len */
export const LINKEDIN_SEGMENT_TOO_LARGE_MSG = [
    `Campaign sizes above ${NumeralUtils.formatIntegerNumber(LINKEDIN_MAX_SEGMENT_SIZE)} companies are not supported.`,
    'To upload a segment, update your segment filters.'
].join('<br />');
export const LINKEDIN_NOT_PROVISIONED_MSG = [
    'You need permissioned products in order to connect LinkedIn Campaign Manager.',
    'Please contact us with any questions.'
].join('<br />');
/* eslint-enable max-len */

// limits
export const SEGMENT_LIMIT_NOTIFICATION_TITLE = 'You have reached your limits';
export const SEGMENT_LIMIT_NOTIFICATION_CONTENT_FREE =
    `As a free user you are limited to a maximum of 3 segments saves. Please
    contact us if you want to access more data.`;
export const SEGMENT_LIMIT_NOTIFICATION_CONTENT_TRIAL =
    `As a trial user you are limited to a maximum of 10 segments saves. Please
    contact us if you want to access more data.`;

export const SEGMENT_STATUS = {
    NON_MATCHED: 'NON_MATCHED',
    MATCHED: 'MATCHED',
    EXPORTED: 'EXPORTED'
};

export const MATCH_SOURCE_TYPE = {
    SALESFORCE: MATCH_SOURCE_TYPE_SALESFORCE,
    PARDOT: MATCH_SOURCE_TYPE_PARDOT,
    MARKETO: MATCH_SOURCE_TYPE_MARKETO,
    FILE: MATCH_SOURCE_TYPE_FILE,
    ELOQUA: MATCH_SOURCE_TYPE_ELOQUA,
    LINKEDIN: MATCH_SOURCE_TYPE_LINKEDIN
};

export const MATCH_SOURCE_PLATFORM = {
    [PLATFORM_SOURCE.SALESFORCE]: MATCH_SOURCE_TYPE.SALESFORCE,
    [PLATFORM_SOURCE.PARDOT]: MATCH_SOURCE_TYPE.PARDOT,
    [PLATFORM_SOURCE.MARKETO]: MATCH_SOURCE_TYPE.MARKETO,
    [PLATFORM_SOURCE.FILE]: MATCH_SOURCE_TYPE.FILE,
    [PLATFORM_SOURCE.ELOQUA]: MATCH_SOURCE_TYPE.ELOQUA,
    [PLATFORM_SOURCE.LINKEDIN]: MATCH_SOURCE_TYPE.LINKEDIN
};

export const MATCH_SOURCE_NAMES = {
    [MATCH_SOURCE_TYPE.SALESFORCE]: 'Salesforce',
    [MATCH_SOURCE_TYPE.PARDOT]: 'Pardot',
    [MATCH_SOURCE_TYPE.MARKETO]: 'Marketo',
    [MATCH_SOURCE_TYPE.FILE]: 'File',
    [MATCH_SOURCE_TYPE.ELOQUA]: 'Eloqua',
    [MATCH_SOURCE_TYPE.LINKEDIN]: 'LinkedIn'
};

export const MATCH_ON_RECORD_TYPES = 'Matched on: ';
export const CREATED_ON = 'Created on';
export const UPDATED_ON = 'Updated on';
export const DISPLAYED_SUMMARY_PRODUCTS_LIMIT = 3;

export const SEGMENT_MATCHING_TITLE = 'Compare this segment to your accounts';
export const SEGMENT_MATCHING_SERVICES = [
/*    {
        type: PLATFORM_SOURCE.SALESFORCE,
        text: ['Compare to', 'Salesforce'],
        url: '/dashboard/connect/salesforce',
        regUrl: `/register/${LANDING_PAGE_TYPES.LP_SALESFORCE}`,
        iconClass: 'salesforce-img',
        available: true
    },
    {
        type: PLATFORM_SOURCE.MARKETO,
        text: ['Compare to', 'Marketo'],
        url: '/dashboard/connect/marketo',
        regUrl: `/register/${LANDING_PAGE_TYPES.LP_MARKETO}`,
        iconClass: 'marketo-img',
        available: true
    },*/
    {
        type: PLATFORM_SOURCE.LINKEDIN,
        text: ['Upload segment to', 'Campaign Manager'],
        url: '/dashboard/connect/linkedin',
        regUrl: `/register/${LANDING_PAGE_TYPES.LP_LINKEDIN}`,
        iconClass: 'linkedin-img',
        available: true
    },
    {
        type: PLATFORM_SOURCE.FILE,
        text: ['Compare to', 'a file'],
        url: '/dashboard/import/file',
        regUrl: `/register/${LANDING_PAGE_TYPES.LP_FILE}`,
        iconClass: 'icon-check-list',
        available: true
    }
    /*
    ,
    {
        type: PLATFORM_SOURCE.ELOQUA,
        // text: ['Compare to', 'Eloqua'],
        text: ['Eloqua', 'Coming soon'], // use the above after enabling
        url: '/dashboard/connect/eloqua',
        regUrl: `/register/${LANDING_PAGE_TYPES.LP_ELOQUA}`,
        iconClass: 'eloqua-img',
        available: false
    },
    {
        type: PLATFORM_SOURCE.PARDOT,
        // text: ['Compare to', 'Pardot'],
        text: ['Pardot', 'Coming soon'], // use the above after enabling
        url: '/dashboard/connect/pardot',
        regUrl: `/register/${LANDING_PAGE_TYPES.LP_PARDOT}`,
        iconClass: 'pardot-img',
        available: false
    },
    {
        type: 'hubspot',
        text: ['Hubspot', 'Coming soon'],
        url: '',
        iconClass: 'hubspot-img',
        available: false
    }
    */
];

export const NO_RECORDS = {
    text: 'None of your accounts match this segment.',
    link: 'Try reviewing your segment filters.'
};
export const CONTACT_SALES_BODY_MESSAGE = 'I would like to learn more about how to access ' +
    'technographics in my Salesforce.\r\n\r\nThanks,\r\n%1$s\r\n\r\n';
export const REMAINING_DAYS = 'Days';
export const REMAINING_DAY = 'Day';
export const UNMATCH_SEGMENT_REMAINING_DAYS = 'Remaining for Your Test Drive';

export const DOWNLOAD_LIMIT_MODAL_TITLE = 'You Are About To Download';
export const DOWNLOAD_LIMIT_MODAL_CONTENT = `You have %1$s records available.
    After this download is complete, you will have %2$s records remaining.`;
export const DOWNLOAD_LIMIT_MODAL_BTN = 'Continue with Download';
export const SEGMENT_NAME_PLACEHOLDER = 'Enter Segment Name';
