import {
    UNKNOWN_VALUES_ADD,
    UNKNOWN_VALUES_REMOVE,
    UNKNOWN_VALUES_SET,
    UNKNOWN_VALUES_RESET
} from './constants';

export function addUnknowValuesId(id) {
    return (dispatch) => {
        return dispatch({
            type: UNKNOWN_VALUES_ADD,
            id
        });
    };
}

export function removeUnknowValuesId(id) {
    return (dispatch) => {
        return dispatch({
            type: UNKNOWN_VALUES_REMOVE,
            id
        });
    };
}

export function setUnknowValuesIds(ids = []) {
    return (dispatch) => {
        return dispatch({
            type: UNKNOWN_VALUES_SET,
            ids
        });
    };
}

export function resetUnknowValues() {
    return (dispatch) => {
        return dispatch({
            type: UNKNOWN_VALUES_RESET
        });
    };
}
