import { combineReducers } from 'redux';
import {
    NET_NEW_MATCHES_REQUEST,
    NET_NEW_MATCHES_SUCCESS,
    NET_NEW_MATCHES_FAILURE,
    NET_NEW_MATCHES_RESET,
    NET_NEW_MATCHES_SET,
    SEGMENT_SUMMARY_REQUEST,
    SEGMENT_SUMMARY_SUCCESS,
    SEGMENT_SUMMARY_FAILURE,
    SEGMENT_SUMMARY_RESET,
    SEGMENT_SUMMARY_SET,
    SEGMENT_AVAILABLE_RECORDS_REQUEST,
    SEGMENT_AVAILABLE_RECORDS_SET,
    SEGMENT_AVAILABLE_RECORDS_SUCCESS,
    SEGMENT_AVAILABLE_RECORDS_FAILURE,
    SEGMENT_AVAILABLE_RECORDS_RESET,
    SEGMENT_AVAILABLE_RECORDS_RESET_VIEW,
    SEGMENT_PREVIOUS_FILES_REQUEST,
    SEGMENT_PREVIOUS_FILES_SUCCESS,
    SEGMENT_PREVIOUS_FILES_FAILURE,
    PRODUCT_SUMMARY_REQUEST,
    PRODUCT_SUMMARY_SUCCESS,
    PRODUCT_SUMMARY_FAILURE,
    PRODUCT_SUMMARY_RESET
} from './constants';
import {
    SEGMENT_RESET_VIEW
} from 'myhg/segment/constants';

export const netNewMatchesInitialState = {
    data: null,
    error: null,
    isFetching: false,
    cancelToken: null
};

export function netNewMatches(state = netNewMatchesInitialState, action) {
    switch (action.type) {
        case NET_NEW_MATCHES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null,
                cancelToken: action.cancelToken
            });

        case NET_NEW_MATCHES_SET:
            return Object.assign({}, state, {
                data: action.data,
                error: null
            });

        case NET_NEW_MATCHES_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null,
                cancelToken: null
            });

        case NET_NEW_MATCHES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                cancelToken: null
            });

        case NET_NEW_MATCHES_RESET:
        case SEGMENT_RESET_VIEW:
            return netNewMatchesInitialState;

        default:
            return state;
    }
}

export const summaryDataInitialState = {
    data: null,
    error: null,
    isFetching: false,
    cancelToken: null
};

export function summaryData(state = summaryDataInitialState, action) {
    switch (action.type) {
        case SEGMENT_SUMMARY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null,
                cancelToken: action.cancelToken
            });

        case SEGMENT_SUMMARY_SET:
        case SEGMENT_SUMMARY_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null,
                cancelToken: action.cancelToken
            });

        case SEGMENT_SUMMARY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                cancelToken: action.cancelToken
            });

        case SEGMENT_SUMMARY_RESET:
        case SEGMENT_RESET_VIEW:
            return summaryDataInitialState;

        default:
            return state;
    }
}

export const productSummaryDataInitialState = {
    data: [],
    requestNo: 0,
    error: null,
    isFetching: false,
    cancelToken: null
};

export function productSummaryData(state = productSummaryDataInitialState, action) {
    switch (action.type) {
        case PRODUCT_SUMMARY_REQUEST:
            return Object.assign({}, state, {
                requestNo: state.requestNo + 1,
                isFetching: true,
                error: null,
                cancelToken: action.cancelToken
            });

        case PRODUCT_SUMMARY_SUCCESS:
            return Object.assign({}, state, {
                data: [...state.data, action.data],
                isFetching: state.data.length < (state.requestNo - 1),
                error: null,
                cancelToken: action.cancelToken
            });

        case PRODUCT_SUMMARY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                cancelToken: action.cancelToken
            });

        case PRODUCT_SUMMARY_RESET:
            return productSummaryDataInitialState;

        default:
            return state;
    }
}

export const availableRecordsDataInitialState = {
    data: null,
    error: null,
    isFetching: false,
    cancelToken: null
};

export function availableRecordsData(state = availableRecordsDataInitialState, action) {
    switch (action.type) {
        case SEGMENT_AVAILABLE_RECORDS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null,
                cancelToken: action.cancelToken
            });

        case SEGMENT_AVAILABLE_RECORDS_SET:
        case SEGMENT_AVAILABLE_RECORDS_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null,
                cancelToken: null
            });

        case SEGMENT_AVAILABLE_RECORDS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                cancelToken: null
            });

        case SEGMENT_AVAILABLE_RECORDS_RESET:
        case SEGMENT_AVAILABLE_RECORDS_RESET_VIEW:
            return availableRecordsDataInitialState;

        default:
            return state;
    }
}

export const uploadedFilesInitialState = {
    data: [],
    error: null,
    isFetching: false
};

export function uploadedFiles(state = uploadedFilesInitialState, action) {
    switch (action.type) {
        case SEGMENT_PREVIOUS_FILES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case SEGMENT_PREVIOUS_FILES_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null
            });

        case SEGMENT_PREVIOUS_FILES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
}

export default combineReducers({
    netNewMatches,
    summaryData,
    productSummaryData,
    uploadedFiles,
    availableRecordsData
});
