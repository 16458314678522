import React from 'react';

export default class RightWizardPanel extends React.Component {

    render() {
        return (
            <div className="right-wizard-panel">
                <span
                    className="close icon icon-close-x"
                    onClick={ this.props.onClose }
                    data-qa="close-button"
                ></span>
                { this.props.content }
            </div>
        );
    }
}
