export default class SegmentUtils {

    static removeMatchesAndIncludeFilters(filters, source, isTrialExpired) {
        const segmentFilters = JSON.parse(JSON.stringify(filters));

        if (source && isTrialExpired) {
            delete segmentFilters.includeMatches;
            delete segmentFilters.includeNetNew;
        }

        return segmentFilters;
    }
}
