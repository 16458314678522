import {
    SET_LOCATION_PATH,
    SET_PREV_LOCATION_PATH,
    SET_FOCUS_ID,
    SET_HG4SF_ID,
    SET_APP_ERROR,
    SET_FEATURE_FLAGS
} from './constants';

export function setLocationPath(path) {
    return {
        type: SET_LOCATION_PATH,
        locationPath: path
    };
}

export function setPrevLocationPath(path) {
    return {
        type: SET_PREV_LOCATION_PATH,
        locationPath: path
    };
}

export function setFeatureFlags(flags) {
    return {
        type: SET_FEATURE_FLAGS,
        flags
    };
}

export function setFocusExtensionId(focusExtensionId) {
    return {
        type: SET_FOCUS_ID,
        focusExtensionId
    };
}

export function setHG4SFExtensionId(extensionId) {
    return {
        type: SET_HG4SF_ID,
        extensionId
    };
}

export function setApplicationError(applicationError) {
    return {
        type: SET_APP_ERROR,
        applicationError
    };
}
