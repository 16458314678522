export const LINKEDIN_CONNECT_PAGE_TITLE = 'Connect to LinkedIn';
export const LINKEDIN_CONNECT_PAGE_DESCRIPTION =
    'HG Insights wants to connect to your LinkedIn account to:';

export const LINKEDIN_OPTIONS_PAGE_DESCRIPTION =
    'Please select the account you want the matched URLs list uploaded to:';

export const LINKEDIN_CONNECT_NOTE =
    'Note: HG Insights does not use or retain this data in any way.';

export const LINKEDIN_UPLOAD_NOTE =
    'Note: Depending on the size of your target segment this could take a few minutes.';

export const LINKEDIN_BASE_URL = 'https://www.linkedin.com/oauth/v2/authorization?';
export const LINKEDIN_RESPONSE_TYPE_QUERY_PARAM = 'response_type=code';
export const LINKEDIN_SCOPE_PARAM = 'scope=r_ads rw_dmp_segments';

export const LINKEDIN_ACCESS_TOKEN_REQUEST = 'LINKEDIN_ACCESS_TOKEN_REQUEST';
export const LINKEDIN_ACCESS_TOKEN_SUCCESS = 'LINKEDIN_ACCESS_TOKEN_SUCCESS';
export const LINKEDIN_ACCESS_TOKEN_FAILURE = 'LINKEDIN_ACCESS_TOKEN_FAILURE';

export const LINKEDIN_ACCOUNTS_REQUEST = 'LINKEDIN_ACCOUNTS_REQUEST';
export const LINKEDIN_ACCOUNTS_SUCCESS = 'LINKEDIN_ACCOUNTS_SUCCESS';
export const LINKEDIN_ACCOUNTS_FAILURE = 'LINKEDIN_ACCOUNTS_FAILURE';

export const LINKEDIN_MATCHED_URLS_REQUEST = 'LINKEDIN_MATCHED_URLS_REQUEST';
export const LINKEDIN_MATCHED_URLS_SUCCESS = 'LINKEDIN_MATCHED_URLS_SUCCESS';
export const LINKEDIN_MATCHED_URLS_FAILURE = 'LINKEDIN_MATCHED_URLS_FAILURE';
