import React from 'react';
import CollectionSection from 'shared/ui/collection/CollectionSection';
import {
    COLLECTION_PRODUCTS_TITLE,
    COLLECTION_COMPANIES_TITLE,
    COLLECTION_VENDORS_TITLE,
    COLLECTION_INSTALLS_TITLE
} from '../constants';
import TechnologyList from './TechnologyList';
import Loader from 'shared/ui/loader/Loader';
import withTaxonomy from 'shared/taxonomy/enhancer';
import { NumeralUtils } from 'utils/Utils';

class CollectionDetails extends React.Component {

    getProductsSlides() {
        const { findTaxonomyProductsForSlugs } = this.props;
        const { productSlugs } = this.props.collection.data;

        const technologies = findTaxonomyProductsForSlugs(productSlugs);

        const alphabeticallySorted = technologies.sort((a, b) => {
            return a.product.localeCompare(b.product);
        });

        const technologiesChunks = [];
        const countsPerColumn = Math.ceil(alphabeticallySorted.length / 3);

        for (let i = 0; i < alphabeticallySorted.length; i += countsPerColumn) {
            technologiesChunks.push(alphabeticallySorted.slice(i, countsPerColumn + i));
        }

        const techSlides = technologiesChunks.map((techs, index) => {
            return (
                <div
                    className="col-lg-4"
                    key={ index }
                >
                    <TechnologyList technologies={ techs } />
                </div>
            );
        });

        return (
            <div className="collection-details-products row" key={ 'tech-slides' }>
                { techSlides }
            </div>
        );
    }

    getDescription() {
        const { data: collection } = this.props.collection;
        const formattedInstalls =
            NumeralUtils.formatBigNumbersDecimal(collection.installs).toUpperCase();
        const formattedCompanies =
            NumeralUtils.formatBigNumbersDecimal(collection.companies).toUpperCase();

        return (
            <div className="collection-details-description" key={ 'description' }>
                <div className="collection-details-title">
                    <h2> { collection.name } </h2>
                </div>
                <div className="collection-details-description">
                    { collection.description }
                </div>
                <div className="collection-details-summary">
                    <CollectionSection
                        iconClass="icon-product"
                        title={ `# ${COLLECTION_PRODUCTS_TITLE}` }
                        itemCounts={ collection.productSlugs.length }
                    />
                    <CollectionSection
                        iconClass="icon-vendor"
                        title={ `# ${COLLECTION_VENDORS_TITLE}` }
                        itemCounts={ collection.vendorsCount }
                    />
                    <CollectionSection
                        iconClass="icon-building"
                        title={ `# ${COLLECTION_COMPANIES_TITLE}` }
                        itemCounts={ formattedCompanies }
                    />
                    <CollectionSection
                        iconClass="icon-record"
                        title={ `# ${COLLECTION_INSTALLS_TITLE}` }
                        itemCounts={ formattedInstalls }
                    />
                </div>
            </div>
        );
    }

    render() {
        let content = null;
        const { collection } = this.props;

        if (collection.error) {
            content = <div>{ collection.error.message }</div>;
        } else if (!collection.isFetched) {
            content = <Loader />;
        } else {
            content = [
                this.getDescription(),
                this.getProductsSlides()
            ];
        }
        return (
            <div className="collection-details">
                { content }
            </div>
        );
    }
}

export default withTaxonomy(CollectionDetails);
