import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();
const defaultTimeout = 10 * 60 * 1000;

/**
 * Singleton class that encapsulates segment firmos related API calls
 */
export default class SegmentFirmographicsService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new SegmentFirmographicsService(singletonEnforcer);
        }
        return this[singleton];
    }

    getData(url, params, campaignSessionId, cancelToken) {
        return ApiWrapper.instance.axios
            .post(url, params,
            {
                timeout: defaultTimeout,
                cancelToken: cancelToken.token,
                headers: {
                    campaignSessionId
                }
            }
        );
    }

    getFirmographicsLocation(url, filters, cancelToken, campaignSessionId) {
        return ApiWrapper.instance.axios.post(url, filters, {
            timeout: defaultTimeout,
            cancelToken: cancelToken.token,
            headers: {
                campaignSessionId
            }
        });
    }
}
