import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton class that encapsulates segment related API calls
 */
export default class SegmentService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new SegmentService(singletonEnforcer);
        }
        return this[singleton];
    }

    getSegment(id) {
        return ApiWrapper.instance.axios.get(`segments/${id}`);
    }

    getSegments() {
        return ApiWrapper.instance.axios.get('segments');
    }

    saveSegment(segment) {
        if (segment.id) {
            return ApiWrapper.instance.axios.put(`segments/${segment.id}`, segment);
        }

        return ApiWrapper.instance.axios.post('segments', segment);
    }

    getMatchesData(source, params, campaignSessionId, cancelToken) {
        return ApiWrapper.instance.axios
            .post(source, params,
            {
                timeout: 10 * 60 * 1000,
                cancelToken: cancelToken.token,
                headers: {
                    campaignSessionId
                }
            }
        );
    }

    getPermissionCount() {
        return ApiWrapper.instance.axios.get('/segments/permissionCounts');
    }

    getAdditionalCompaniesByPeriod(createdAfter) {
        return ApiWrapper.instance.axios.get('/segments/permissionCounts/', {
            params: {
                createdAfter
            }
        });
    }

    getSegmentSummary(filters, campaignSessionId, cancelToken) {
        return ApiWrapper.instance.axios.post('filtering/matches/companies', filters, {
            timeout: 10 * 60 * 1000,
            cancelToken: cancelToken.token,
            headers: {
                campaignSessionId
            }
        });
    }

    getAvailableRecords(filters, campaignSessionId, cancelToken) {
        return ApiWrapper.instance.axios.post('filtering/matches', filters, {
            timeout: 10 * 60 * 1000,
            cancelToken: cancelToken.token,
            headers: {
                campaignSessionId
            }
        });
    }

    getFileMatchAndNetNew(filters, fileId, cancelToken) {
        return ApiWrapper.instance.axios.post('filtering/csv/matches/companies', {
            ...filters,
            fileId
        }, {
            timeout: 10 * 60 * 1000,
            cancelToken: cancelToken.token
        });
    }

    getCSVAvailableRecords(filters, fileId, cancelToken) {
        return ApiWrapper.instance.axios.post('filtering/csv/matches', {
            ...filters,
            fileId
        }, {
            timeout: 10 * 60 * 1000,
            cancelToken: cancelToken.token
        });
    }

    downloadCSV(source, params, segmentId) {
        return ApiWrapper.instance.axios.post(source, params, {
            timeout: 60 * 60 * 1000,
            headers: {
                campaignSessionId: segmentId,
                segmentId
            }
        }); // 60 minutes timeout
    }

    deleteSegment(id) {
        return ApiWrapper.instance.axios.delete(`segments/${id}`);
    }

    postShareSegment(key, segment) {
        return ApiWrapper.instance.axios.post(`segments/share/${key}`, segment);
    }

    getShareSegment(key) {
        return ApiWrapper.instance.axios.get(`segments/share/${key}`);
    }
}
