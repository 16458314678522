import React from 'react';
import { connect } from 'react-redux';
import ResetPasswordSuccessContent from './ResetPasswordSuccessContent';
import ResetPasswordConfirmForm from './ResetPasswordConfirmForm';
import {
    decodeResetPasswordToken,
    resetPasswordConfirmAction
} from './actions';

function mapStateToProps(state) {
    return {
        resetPasswordConfirm: state.authentication.resetPasswordConfirm
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class ResetPasswordContainer extends React.Component {

    componentDidMount() {
        this.props.dispatch(
            decodeResetPasswordToken(this.props.params.confirmToken)
        );
    }

    onResetPasswordSubmit(data) {
        return this.props.dispatch(
            resetPasswordConfirmAction(
                data.password,
                this.props.resetPasswordConfirm.decodedData.data.token
            )
        );
    }

    getModalTitle(title) {
        return (
            <div className="title">
                { title }
            </div>
        );
    }

    getMenuContent() {
        let content = null;
        const {
            resetPasswordConfirm: { decodedData },
            resetPasswordConfirm
        } = { ...this.props };

        if (resetPasswordConfirm.passwordChanged) {
            content = (
                <ResetPasswordSuccessContent />
            );
        } else if (decodedData.isDecoded && decodedData.isValid) {
            content = (
                <ResetPasswordConfirmForm
                    decodedData={ decodedData }
                    onResetPasswordSubmit={ this.onResetPasswordSubmit.bind(this) }
                    requestError={ resetPasswordConfirm.error }
                />
            );
        } else if (!decodedData.isValid) {
            content = (
                <div className="modal-form">
                    <p className="text-content large error">
                        Invalid Verification Link
                    </p>
                </div>
            );
        }
        return content;
    }

    render() {
        return (
            <div>
                { this.getModalTitle(this.props.resetPasswordConfirm.displayMenuTitle) }
                <div className="form-container">
                    { this.getMenuContent() }
                </div>
            </div>
        );
    }
}
