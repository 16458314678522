import React from 'react';
import { NumeralUtils } from 'utils/Utils';

/**
 * Component that renders total companies info
 */
export default class TotalCompanies extends React.Component {
    /**
     * Returns the additional companies count
     */
    additionalCompaniesCount() {
        return this.props.count - this.props.products.length;
    }

    render() {
        return (
            <p>
                <span className="icon-circle icon-ampersand"> </span>
                { NumeralUtils.formatIntegerNumber(this.additionalCompaniesCount()) } more
            </p>
        );
    }
}
