import React from 'react';
import { connect } from 'react-redux';
import {browserHistory, Link} from 'react-router';
import UserUtils from 'utils/UserUtils';
import { MATCH_CSV_DETAIL } from './constants';
import { uploadFile } from './actions';
import UploadFileArea from 'shared/ui/upload/UploadFileArea';
import {
    saveCurrentSegment
} from '../../segment/actions';
import {
    MATCH_SOURCE_TYPE
} from 'myhg/segment/constants';
import { CONTACT_US_URL } from "../../../shared/footer/constants";

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        accountDetails: state.user.accountDetails.data,
        segmentFilters: segment.segmentFilters,
        file: state.myhg.imports.file,
        segmentId: state.myhg.segment.currentSegment.id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class ImportFile extends React.Component {

    onDrop = (files) => {
        const { accountDetails, dispatch, segmentId } = this.props;
        const { technologies } = this.props.segmentFilters;
        const isPaidUser = UserUtils.isPaid(accountDetails);

        dispatch(uploadFile(files[0], technologies, true)).then((fileData) => {
            if (fileData && fileData.fileId) {
                dispatch(saveCurrentSegment({
                    sourceValue: fileData.fileId,
                    fileName: fileData.fileName,
                    source: MATCH_SOURCE_TYPE.FILE
                }, {
                    fileId: fileData.fileId,
                    includeNetNew: isPaidUser,
                    includeMatches: true
                })).then(() => {
                    browserHistory.push(`/dashboard/segment/view/${segmentId}`);
                });
            }
        });
    }

    getUploadErrorMessage = () => {
        return this.props.file.uploadFile.error
            ? this.props.file.uploadFile.error : '';
    }

    render() {
        return (
            <div className="match-csv">
                <p className="match-csv-info">
                    { MATCH_CSV_DETAIL }
                    <Link
                        to={ CONTACT_US_URL }
                        target="_blank"
                        rel="nofollow"
                    >
                        Contact&nbsp;Us
                    </Link>
                    .
                </p>
                <UploadFileArea
                    onDrop={ this.onDrop }
                    getUploadErrorMessage={ this.getUploadErrorMessage }
                    progress={ this.props.file.uploadFile.uploadProgress }
                    inProgress={ this.props.file.uploadFile.uploadInProgress }
                />
            </div>
        );
    }
}
