import { reducer as form } from 'redux-form';

function trim(value) {
    return value && value.trim();
}

// http://redux-form.com/5.2.5/#/examples/normalizing?_k=55omd0
// apply normalization for specific fields and forms
export const normalizedForms = form.normalize({
    loginForm: {
        username: trim,
        password: trim
    },
    signUpForm: {
        email: trim,
        password: trim,
        confirmPassword: trim
    },
    contactForm: {
        senderEmail: trim
    },
    forgotPasswordForm: {
        email: trim,
    },
    resetPasswordConfirmForm: {
        password: trim,
        confirmPassword: trim
    },
    marketoConnectForm: {
        marketoApiKey: trim,
        marketoApiSecret: trim,
        marketoIdentityUrl: trim,
        marketoRestUrl: trim
    }
});
