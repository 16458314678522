import React from 'react';
import { connect } from 'react-redux';
import {
    browserHistory,
    Link
} from 'react-router';
import GenericHeader from 'shared/header/GenericHeader';
import SearchContainer from 'shared/search/SearchContainer';
import LoginLogoutNavBar from './components/LoginLogoutNavBar';
import {
    PRODUCTS_HEADER,
    SEARCH_PLACEHOLDER_TEXT
} from 'shared/search/constants';
import {
    CONTACT_US
} from './constants';
import {CONTACT_US_URL} from "../footer/constants";

const DASHBOARD_PATH = '/dashboard';
const SEGMENTS_PATH = '/dashboard/segments';

function mapStateToProps(state) {
    return { segmentId: state.myhg.segment.currentSegment.id };
}

@connect(mapStateToProps)
export default class ProductHeader extends React.Component {
    onDashboardClick() {
        browserHistory.push(DASHBOARD_PATH);
    }

    onSegmentsClick() {
        browserHistory.push(SEGMENTS_PATH);
    }

    getSearchContainer() {
        return (
            <div
                id="hybrid-search-container"
                className="search-input-wrapper"
            >
                <SearchContainer
                    showAsLink
                    isHybrid
                    isWide
                    className="product-search"
                    source={ PRODUCTS_HEADER }
                    inputPlaceholderText={ SEARCH_PLACEHOLDER_TEXT }
                    onTechnologySelect={ this.props.onTechnologySelect }
                />
            </div>
        );
    }

    isActive(path) {
        return this.props.locationPath === path;
    }

    render() {
        return (
            <div>
                <GenericHeader className="product-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="logo-wrapper">
                                <Link className="header-logo" to="/">
                                    <div className="logo"></div>
                                </Link>
                            </div>
                            {
                                !this.props.deviceMode && this.props.mounted ?
                                    this.getSearchContainer() : null
                            }
                            <div>
                                <div className="contact-us">
                                    <Link to={CONTACT_US_URL} data-qa="contactus-button" target="_blank">
                                        { CONTACT_US }
                                    </Link>
                                </div>
                                <LoginLogoutNavBar
                                    { ...this.props }
                                />
                            </div>
                            {
                                this.props.deviceMode && this.props.mounted ?
                                    this.getSearchContainer() : null
                            }
                        </div>
                    </div>
                </GenericHeader>
            </div>
        );
    }
}
