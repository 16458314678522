export const STEPS_TITLE = 'Welcome to HG Insights';
export const STEPS_TITLE_DESCRIPTION = 'Find out what companies are using the products you\'re' +
                                    ' interested in. Select your first product by clicking "YES".';
export const STEP_ADD_PRODUCT_TITLE = 'SELECT PRODUCTS';
export const STEP_ADD_PRODUCT_DESCRIPTION = 'Tell us what you’re interested in.';
export const STEP_SELECT_COLLECTION_TITLE = 'SELECT COLLECTIONS';
export const STEP_SELECT_COLLECTION_DESCRIPTION = 'A Collection is a group of products you ' +
                                                    'subscribe to.';
export const STEP_SELECT_PACKAGE_TITLE = 'VIEW PLANS & PRICING';
export const STEP_SELECT_PACKAGE_DESCRIPTION = 'Get started for free.';
export const STEPS_DATA = [
    {
        title: STEP_ADD_PRODUCT_TITLE,
        description: STEP_ADD_PRODUCT_DESCRIPTION
    },
    {
        title: STEP_SELECT_COLLECTION_TITLE,
        description: STEP_SELECT_COLLECTION_DESCRIPTION
    },
    {
        title: STEP_SELECT_PACKAGE_TITLE,
        description: STEP_SELECT_PACKAGE_DESCRIPTION
    }
];

export const STEPS_DATA_LINKS = [
    null,
    '/recommended-business-intelligence-subscriptions',
    '/business-intelligence-pricing-packages-features'
];

export const STEP_ADD_PRODUCT = 0;
export const STEP_SELECT_COLLECTION = 1;
export const STEP_SELECT_PACKAGE = 2;
