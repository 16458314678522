import React from 'react';
import classNames from 'classnames';
import BlankHeader from 'shared/header/BlankHeader';

export default class BlankPageWrapper extends React.Component {

    getHeader() {
        return (
            <BlankHeader
                title={ this.props.title }
                onClose={ this.props.onClose }
            />
        );
    }

    getContainer() {
        const containerClassName = classNames(
            'container blank-container',
            this.props.className
        );

        return (
            <div className={ containerClassName }>
                { this.props.children }
            </div>
        );
    }

    render() {
        return (
            <div className="blank-page">
                { this.getHeader() }
                { this.getContainer() }
            </div>
        );
    }
}
