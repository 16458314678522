import React from 'react';
import { browserHistory } from 'react-router';
import ModalWizardWrapper from 'shared/ui/modalWizard/ModalWizardWrapper';
import {
    PRODUCTS_LIST_TITLE
} from './constants';

/**
 * Import Page Wrapper
 */
export default class MyProductsWrapper extends React.Component {

    onBack = () => {
        browserHistory.goBack();
    }

    onClose = () => {
        browserHistory.goBack();
    }

    render() {
        return (
            <ModalWizardWrapper
                title={ PRODUCTS_LIST_TITLE }
                onClose={ this.onClose }
                onBack={ this.onBack }
                isLarge
            >
                { this.props.children }
            </ModalWizardWrapper>
        );
    }
}
