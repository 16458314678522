import React from 'react';
import { Link } from 'react-router';
import { MATCH_SOURCE_NAMES } from 'myhg/segment/constants';
import { GENERIC_PLATFORM_NAME } from './constants';
import { CONTACT_US_URL } from "../../shared/footer/constants";

export default class PlatformGlobalError extends React.Component {

    getErrorCodes() {
        const { code, data } = this.props.error;
        const { source } = this.props;
        const salesforceStatusCode = data ? data.salesforceStatusCode : '';
        const platformNameBySegmentSource = MATCH_SOURCE_NAMES[source];

        return (
            <div className="error-codes">
                { platformNameBySegmentSource || GENERIC_PLATFORM_NAME } Error
                Code: <strong>{ salesforceStatusCode }</strong><br />
                HG Reference Code: <strong>{ code }</strong>
            </div>
        );
    }

    getTryAgainMessage() {
        const { source } = this.props;
        const platformName = MATCH_SOURCE_NAMES[source];

        return (
            <div>
                Please try again and, if you keep getting errors, contact
                your <strong>{ platformName || GENERIC_PLATFORM_NAME } Administrator</strong> or
                your customer support team with error details.
                <br />
                {
                    platformName ? (
                        <Link
                            id="error-platform-connect"
                            className="default-link"
                            to={ `/dashboard/connect/${platformName.toLowerCase()}` }
                        >
                            Re-connect
                        </Link>
                    ) : null
                }
                { platformName ? ', ' : null }
                <a
                    id="error-go-back"
                    className="default-link"
                    onClick={ this.props.onGoBack }
                >
                    Go back
                </a>
                { " or " }
                <Link
                    to={CONTACT_US_URL}
                    target="_blank"
                    rel="nofollow"
                    className="default-link"
                    id="error-contact"
                >
                    Contact&nbsp;Us
                </Link>
            </div>
        );
    }

    render() {
        const { message: errorDescription } = this.props.error;

        return (
            <div className="platform-global-error">
                <div className="note-message">
                    { errorDescription }
                </div>
                { this.getErrorCodes() }
                { this.getTryAgainMessage() }
            </div>
        );
    }
}
