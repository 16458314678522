import React from 'react';
import { connect } from 'react-redux';
import RecordTypeSelection from 'myhg/import/accounts/RecordTypeSelection';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import {
    PARDOT_ACCOUNTS_PAGE_TITLE,
    PARDOT_SUMMARY_LOAD
} from './constants';
import accountsStatusHandler from 'myhg/import/accounts/accountsStatusHandler';
import {
    ACCOUNT_TYPES
} from 'myhg/import/accounts/constants';
import {
    MATCH_SOURCE_TYPE
} from 'myhg/segment/constants';
import { PLATFORM_SOURCE } from 'shared/constants';
import { clearRecordType } from 'myhg/import/accounts/actions';

function mapStateToProps(state) {
    return {
        accountDetails: state.user.accountDetails.data,
        segmentId: state.myhg.segment.currentSegment.id,
        authData: state.authentication.authData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const platformRecordTypes = [ACCOUNT_TYPES.CONTACT, ACCOUNT_TYPES.PARDOT_LEAD];

@accountsStatusHandler(platformRecordTypes, MATCH_SOURCE_TYPE.PARDOT)
@connect(mapStateToProps, mapDispatchToProps)

export default class PardotAccountsContainer extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;
        // always clear record types
        dispatch(clearRecordType());
    }

    render() {
        const content = (
            <div className="import-platform-accounts">
                <div className="summary">
                    { PARDOT_SUMMARY_LOAD }
                </div>
                <RecordTypeSelection
                    { ...this.props }
                />
            </div>
        );
        const customHeaderTitle = (
            <span>
                { PARDOT_ACCOUNTS_PAGE_TITLE }
                <span className={ `platform-title-logo ${PLATFORM_SOURCE.PARDOT}-img` } />
            </span>
        );

        return (
            <ImportPageContainerWrapper
                title={ PARDOT_ACCOUNTS_PAGE_TITLE }
                customHeader={ customHeaderTitle }
            >
                { content }
            </ImportPageContainerWrapper>
        );
    }
}
