import {
    SEGMENT_TAB_MENUS,
    SEGMENT_SET_MENU
} from './constants';

// utility function that returns the menu object by its name
export function getMenuByName(selectedName) {
    return SEGMENT_TAB_MENUS.find((item) => {
        return item.name === selectedName;
    });
}

export function segmentMenuSelect(menu) {
    return {
        type: SEGMENT_SET_MENU,
        menu
    };
}
