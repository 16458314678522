import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router';
import { resetSegmentView } from 'myhg/segment/actions';
import { setTechnologies } from 'myhg/segment/segmentFilters/technologiesFilter/actions';
import { PLATFORM_SOURCE } from 'shared/constants';
import { SEGMENT_MATCHING_SERVICES } from 'myhg/segment/constants';
import { NO_PRODUCT_PERMISSIONS } from '../../constants';
import { track } from 'utils/Utils';
import { ACTION, WHERE } from 'utils/track-constants';
import { URL } from '../../../../shared/constants';

export default class SampleCompaniesCreateSegment extends React.Component {

    onCreateSegment = () => {
        const { productDetails: { slug }, dispatch } = this.props;
        dispatch(resetSegmentView());
        dispatch(setTechnologies([slug]));
        track(ACTION.PRODUCT_CREATE_TARGET_SEGMENT, { label: WHERE.PRODUCT_BODY });
    }

    getTitle() {
        return <h2>Personalize. Target. Analyze.</h2>;
    }

    getSubtitle() {
        const { productDetails: { product } } = this.props;
        return (
            <p>
                Discover companies using <strong>{ product }</strong> by locations, employees,
                revenue, industries, and more. Compare your target to your CRM or
                marketing platform. Create campaigns & analyze your results.
            </p>
        );
    }

    getPlatformLogos() {
        const platforms = SEGMENT_MATCHING_SERVICES.filter(service => {
            return service.available && service.type !== PLATFORM_SOURCE.FILE;
        });

        return (
            <div className="platform-logos">
            {
                platforms.map((platform, index) => {
                    return <span key={ index } className={ `icon ${platform.iconClass}` }></span>;
                })
            }
            </div>
        );
    }

    getCreateSegmentButton() {
        if (!this.props.isUserAuthenticated) {
            return (
                <div id="sample-create-segment">
                    <a href="https://hginsights.com/hgi/lp/discovery-demo-request?utm_source=sumo&utm_medium=popup&utm_campaign=demo-request-home&utm_content=see-demo"
                       id="discovery-demo-request"
                       target="_blank"
                       className="btn btn-primary"
                    >
                        Create a Target Segment
                    </a>
                </div>
            );
        }

        const { productDetails: { slug }, allowedSlugs } = this.props;
        let disabled = false;
        let linkAttr = {
            to: '/segment',
            onClick: this.onCreateSegment.bind()
        };
        let tooltip = null;

        if (allowedSlugs && allowedSlugs.indexOf(slug) === -1) {
            linkAttr = {
                'data-tip': NO_PRODUCT_PERMISSIONS,
            };
            disabled = true;
            tooltip = <ReactTooltip effect="solid" />;
        }

        return (
            <div>
                <Link
                    className="btn btn-primary"
                    id="sample-create-segment"
                    disabled={ disabled }
                    { ...linkAttr }
                >
                    Create a Target Segment
                </Link>
                { tooltip }
            </div>
        );
    }

    getBottomDescription() {
        const { totalCompaniesCount } = this.props;
        return (
            <div>
                <Link
                    to={URL.DISCOVERY_DEMO_REQUEST}
                    target="_blank"
                    rel="nofollow"
                >
                    Contact&nbsp;Us
                </Link>&nbsp;to view all { totalCompaniesCount } companies
            </div>
        );
    }

    render() {
        return (
            <div className="create-segment-sample">
                { this.getTitle() }
                { this.getSubtitle() }
                { this.getPlatformLogos() }
                { this.getCreateSegmentButton() }
                { this.getBottomDescription() }
            </div>
        );
    }
}
