import React from 'react';
import {Link} from "react-router";
import {CONTACT_US_URL} from "../../../shared/footer/constants";

export default class SegmentContactUS extends React.Component {

    getContent() {
        const { cssClass } = this.props;

        if (this.props.isButton) {
            return (
                <button
                    id={ this.props.elementId }
                    className={ cssClass }
                    onClick={ (e) => {window.open(CONTACT_US_URL, '_blank')} }
                >
                    { this.props.linkLabel }
                </button>
            );
        }

        return (
            <Link to={CONTACT_US_URL}
                  data-qa="contactus-link"
                  target="_blank"
                  className={ cssClass }
            >
                { this.props.linkLabel }
            </Link>
        );
    }

    render() {
        return this.getContent();
    }

}
