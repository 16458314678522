import {
    SHOW_NOTIFICATION,
    CLEAR_NOTIFICATION
} from './constants';

const notificationInitialState = null;

// notification object data structure:
// notification = {
//     title,
//     description
// }

export default function notification(state = notificationInitialState, action) {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return action.notification;

        case CLEAR_NOTIFICATION:
            return null;

        default:
            return state;
    }
}
