/**
 * Created by mihaela on 29.05.2017.
 */
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router';
import { NumeralUtils } from 'utils/Utils';
import CounterWrapper from 'shared/ui/counter/CounterWrapper';
import {
    COLLECTION_TYPE_NAMES
} from 'shared/constants';
import {
    denormalizeForSEO
} from 'discovery/collection/actions';

export default class CollectionCard extends React.Component {
    getSubscribeLink() {
        const { subscribeText, subscribeAction } = this.props;

        if (!subscribeAction) {
            return null;
        }

        return (
            <div className="subscribe" onClick={ subscribeAction } >
                { subscribeText }
            </div>
        );
    }

    cardTo(slug) {
        return `/collection/${denormalizeForSEO(slug)}`;
    }

    render() {
        const { collection } = this.props;
        const collectionType = COLLECTION_TYPE_NAMES[collection.collectionType];
        const collectionTypeClass = classNames(
            'collection-type',
            `collection-type-${collectionType}`
        );

        const getVendorIconClass = () => {
            return collection.vendorUrl ?
                `fav-icon icon-${collection.vendorUrl}` : 'fav-icon icon-default_favicon';
        };

        const getCategoryIconClass = () => {
            return 'icon-category';
        };

        const iconClass = collection.collectionType === 1 || collection.collectionType === 4 ?
            getVendorIconClass() : getCategoryIconClass();

        const formattedCompanies = collection.companies ?
            NumeralUtils.formatIntegerNumber(collection.companies) : '-';
        const formattedProducts = collection.productsCount ?
            NumeralUtils.formatIntegerNumber(collection.productsCount) : '-';
        const formattedChange = collection.sixMonthsChange ?
            NumeralUtils.formatIntegerNumber(collection.sixMonthsChange) : '-';

        return (
            <div className="collection-card">
                <Link to={ this.cardTo(collection.slug) } >
                    <div className="top-section">
                        <div className="left">
                            <div id="name"> { collection.name } </div>
                            <div id="type" className={ collectionTypeClass } >
                                { COLLECTION_TYPE_NAMES[collection.collectionType] }
                            </div>
                        </div>
                        <div className="right">
                        <span className="icon-circle favicon-wrapper">
                            <span className={ iconClass } />
                        </span>
                        </div>
                    </div>
                    <div id="counts" className="bottom-section">
                        <CounterWrapper
                            iconClass="icon-product"
                            title="# Companies"
                            itemCounts={ formattedCompanies }
                        />
                        <CounterWrapper
                            iconClass="icon-vendor"
                            title="6 Months Change"
                            itemCounts={ formattedChange }
                        />
                        <CounterWrapper
                            iconClass="icon-product"
                            title="# Products"
                            itemCounts={ formattedProducts }
                        >
                            <span className="icon-search search" />
                        </CounterWrapper>

                    </div>
                </Link>
                { this.getSubscribeLink() }
            </div>
        );
    }
}
