import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

function mapStateToProps(state) {
    return {
        refererPath: state.authentication.refererPath
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class GenericConfirmSignUpFlow extends React.Component {

    onContinue = () => {
        const { refererPath } = this.props;
        browserHistory.push(refererPath);
    }

    getTitle() {
        return (
            <div className="title">
                Thanks for confirming your email address!<br />
            </div>
        );
    }

    getAction() {
        const buttonText = 'Click to Continue';

        return (
            <button
                id="confirmation-success"
                className="btn btn-primary"
                onClick={ this.onContinue }
                data-qa="continue-button"
            >
                { buttonText }
            </button>
        );
    }

    render() {
        const { landingPage } = this.props;

        return (
            <div id="confirm-registration" className="confirm-content modal-form">
                { this.getTitle() }
                { landingPage ? null : this.getAction() }
            </div>
        );
    }
}
