import React from 'react';
import { connect } from 'react-redux';
import SimilarProducts from './components/SimilarProducts';

function mapStateToProps(state) {
    return {
        similarProducts: state.product.similarProducts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class SimilarProductsContainer extends React.Component {
    render() {
        let content = null;

        if (this.props.similarProducts.data.length > 0) {
            content = (<SimilarProducts { ...this.props } />);
        }

        return content;
    }
}
