import React from 'react';
import { Route, IndexRoute } from 'react-router';
import MyHgHomeContainer from 'myhg/home/MyHgHomeContainer';
import DashboardAppContainer from 'myhg/DashboardAppContainer';
import ViewAllSegmentsContainer from 'myhg/viewallsegments/ViewAllSegmentsContainer';
import ViewMyProductsContainer from 'myhg/myproducts/ViewMyProductsContainer';
import SegmentContainer from 'myhg/segment/SegmentContainer';
import PlatformGlobalErrorContainer from
    'myhg/platformGlobalError/PlatformGlobalErrorContainer';
import SegmentPage from 'myhg/segment/SegmentPage';
import SalesforceMatchFirst from 'myhg/import/salesforce/matchFirst/SalesforceMatchFirst';
import { importPlatformRoutes } from 'myhg/import/importPlatformRoutes';
import { connectPlatformRoutes } from 'myhg/platformConnection/connectPlatformRoutes';
import ExportContainer from 'myhg/export/ExportContainer';

const scrollToTop = () => {
    if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
    }
};

export const myHgRoutesRoutes = (
    <Route
        path="dashboard"
        component={ DashboardAppContainer }
        onChange={ scrollToTop }
    >
        <IndexRoute component={ MyHgHomeContainer } />
        <Route
            path="segments"
            component={ ViewAllSegmentsContainer }
        />
        <Route
            path="my-products"
            component={ ViewMyProductsContainer }
        />
        <Route
            path="segment"
            component={ SegmentPage }
        >
            <Route
                path="new"
                component={ SegmentContainer }
            />
            <Route
                path="view/:segmentId"
                component={ SegmentContainer }
            />
        </Route>
        { importPlatformRoutes }
        { connectPlatformRoutes }
        <Route
            path="export"
            component={ ExportContainer }
        />
        <Route
            path="platform-error"
            component={ PlatformGlobalErrorContainer }
        />
        <Route
            path="match-first"
            component={ SalesforceMatchFirst }
        />
    </Route>
);
