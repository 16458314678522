import {
    SHOW_NOTIFICATION,
    CLEAR_NOTIFICATION
} from './constants';

export function showNotification(notification) {
    return {
        type: SHOW_NOTIFICATION,
        notification
    };
}

export function clearNotification() {
    return {
        type: CLEAR_NOTIFICATION
    };
}
