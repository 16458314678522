import React from 'react';
import { connect } from 'react-redux';
import ConfirmRegistration from './ConfirmRegistration';
import {
    confirmRegistration,
    validLink
} from './actions';
import {
    setFiltersBySegment
} from 'myhg/segment/segmentFilters/actions';
import {
    updateSegment,
    saveCurrentSegment
} from 'myhg/segment/actions';
import { setRefererPath } from 'discovery/authenticationModal/actions';

function mapStateToProps(state) {
    return {
        confirmRegistration: state.authentication.confirmRegistration,
        authData: state.authentication.authData
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class ConfirmRegistrationContainer extends React.Component {

    componentDidMount() {
        const { confirmToken, signupDetails } = this.props.params;
        const decodedSignupData = JSON.parse(atob(signupDetails));
        const { dispatch, authData } = this.props;
        const decodedToken = this.getConfirmToken(confirmToken);

        if (decodedToken && !authData.token) {
            dispatch(confirmRegistration(decodedToken))
                .then(() => {
                    // auto save segment that was previously created
                    if (decodedSignupData.segment) {
                        dispatch(updateSegment(decodedSignupData.segment));
                        dispatch(setFiltersBySegment(decodedSignupData.segment));
                        dispatch(saveCurrentSegment())
                            .then((response) => {
                                // overwrite referer path with saved segment
                                dispatch(setRefererPath(
                                    `/dashboard/segment/view/${response.data.id}`));
                            });
                    }
                });
        }

        // set the modal referer path so we properly handle close events
        dispatch(setRefererPath(decodedSignupData.referer || '/'));
    }

    getConfirmToken(base64EncocodedToken) {
        let confirmToken = null;

        try {
            const data = JSON.parse(atob(base64EncocodedToken));
            confirmToken = data.token;
        } catch (exc) {
            this.props.dispatch(validLink(false));
        }
        return confirmToken;
    }

    render() {
        return (
            <ConfirmRegistration
                { ...this.props }
                title={ this.props.confirmRegistration.displayMenuTitle }
            />
        );
    }
}
