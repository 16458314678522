import {
    setTechnologies,
    setProductsRelation
} from './technologiesFilter/actions';
import { setCollections } from './collectionsFilter/actions';
import { setEmployeesInterval } from './employeesFilter/actions';
import { setRevenueInterval } from './revenueFilter/actions';
import { setIndustries } from './industriesFilter/actions';
import { setLocations, clearLocations } from './locationsFilter/actions';
import { setSignalScoreIds, resetSignalScores } from './signalScores/actions';
import { setUnknowValuesIds, resetUnknowValues } from './unknownValuesFilter/actions';
import { setLastCheckByValue, resetLastCheck } from './lastCheckFilter/actions';
import {
    SEGMENT_ADVANCED_FILTERS_SET_VISIBILITY,
    SEGMENT_ADVANCED_FILTERS_CLEAR,
    ADVANCED_FILTERS_SET_ACTIVE_TIME_FRAME,
    USAGE_TIME_FRAME_SIGNAL_SCORE,
    USAGE_TIME_FRAME_LAST_CHECK,
    SEGMENT_FILTERS_EDIT_VIEW_CHANGE
} from './constants';

export function setAdvancedVisibility(isVisible) {
    return (dispatch) => {
        return dispatch({
            type: SEGMENT_ADVANCED_FILTERS_SET_VISIBILITY,
            value: isVisible
        });
    };
}

export function setActiveTimeframe(activeTimeFrame) {
    return (dispatch) => {
        return dispatch({
            type: ADVANCED_FILTERS_SET_ACTIVE_TIME_FRAME,
            activeTimeFrame
        });
    };
}

export function clearAdvancedFilters() {
    return (dispatch) => {
        return dispatch({
            type: SEGMENT_ADVANCED_FILTERS_CLEAR
        });
    };
}

export function extractSegmentFilters(filters, visualFilters) {
    const {
        slugs,
        matchAllProducts,
        industries,
        cc,
        regions,
        employeesFrom,
        employeesTo,
        revenueFrom,
        revenueTo,
        signalScores,
        includeUnknownFirmos
    } = filters;

    const filtersData = {
        technologies: slugs, // visual data (backward compatibility mode on)
        collections: [], // visual data
        matchAllProducts,
        industries: industries || [],
        locations: {
            countries: cc || [],
            regions: regions || []
        },
        employees: {
            from: employeesFrom,
            to: employeesTo
        },
        revenue: {
            from: revenueFrom,
            to: revenueTo
        },
        signalScores,
        includeUnknownFirmos: includeUnknownFirmos || []
    };

    const visualFiltersData = visualFilters || {};

    return { ...filtersData, ...visualFiltersData };
}

export function extractSegmentFiltersFromRawSegment(segment) {
    return extractSegmentFilters(segment.data.filters, segment.data.visualFilters);
}

export function extractSegmentFiltersFromQuery(queryFilters) {
    const dataFilters = JSON.parse(atob(queryFilters));
    return extractSegmentFilters(dataFilters);
}

export function extractSegmentFiltersFromState(segmentFiltersState) {
    const {
        technologies,
        collections,
        matchAllProducts,
        industries: { selectedIndustries },
        locations: { countries, regions },
        signalScores,
        lastCheck,
        includeUnknownFirmos,
        segmentFiltersView: { activeTimeFrame }
    } = segmentFiltersState;

    let { employees, revenue } = segmentFiltersState;
    employees = employees || {};
    revenue = revenue || {};

    const collectionsSlugs = collections.length ? collections.map(collection => {
        return collection.techSlugs;
    }).reduce((a, b) => { return a.concat(b); }) : [];

    const slugs = [...new Set([...collectionsSlugs, ...technologies])];

    // pack filters data
    const filtersData = {
        slugs,
        matchAllProducts,
        employeesFrom: employees.from,
        employeesTo: employees.to,
        revenueFrom: revenue.from,
        revenueTo: revenue.to,
        industries: selectedIndustries,
        cc: countries,
        regions,
        signalScores: activeTimeFrame === USAGE_TIME_FRAME_SIGNAL_SCORE ? signalScores : undefined,
        lastCheck: activeTimeFrame === USAGE_TIME_FRAME_LAST_CHECK ? lastCheck : undefined,
        includeUnknownFirmos: includeUnknownFirmos || []
    };

    return filtersData;
}

export function extractSegmentVisualFiltersFromState(segmentFiltersState) {
    const {
        technologies,
        collections
    } = segmentFiltersState;

    return {
        technologies,
        collections
    };
}

export function setFilters(segmentFilters) {
    const {
        technologies,
        collections,
        matchAllProducts,
        industries,
        locations,
        employees,
        revenue,
        signalScores,
        lastCheck,
        includeUnknownFirmos
    } = segmentFilters;
    const activeTimeFrame = signalScores ?
        USAGE_TIME_FRAME_SIGNAL_SCORE : USAGE_TIME_FRAME_LAST_CHECK;

    return (dispatch) => {
        dispatch(setTechnologies(technologies));
        dispatch(setCollections(collections));
        dispatch(setProductsRelation(matchAllProducts));
        dispatch(setEmployeesInterval(employees));
        dispatch(setRevenueInterval(revenue));
        dispatch(setIndustries(industries));
        dispatch(setLocations(locations));
        dispatch(setSignalScoreIds(signalScores));
        dispatch(setLastCheckByValue(lastCheck));
        dispatch(setUnknowValuesIds(includeUnknownFirmos));
        dispatch(setActiveTimeframe(activeTimeFrame));
    };
}

export function setFiltersBySegment(segment) {
    const segmentFilters = extractSegmentFiltersFromRawSegment(segment);
    return setFilters(segmentFilters);
}

export function setFiltersByQuery(queryFilters) {
    const segmentFilters = extractSegmentFiltersFromQuery(queryFilters);
    return setFilters(segmentFilters);
}

export function clearFilters() {
    return (dispatch) => {
        dispatch(setTechnologies([]));
        dispatch(setCollections([]));
        dispatch(setProductsRelation(false));
        dispatch(setEmployeesInterval(null));
        dispatch(setRevenueInterval(null));
        dispatch(setIndustries([]));
        dispatch(clearLocations());
        dispatch(resetSignalScores());
        dispatch(resetLastCheck());
        dispatch(resetUnknowValues());
        dispatch(clearAdvancedFilters());
    };
}

export function showEditFilterView(isVisible) {
    return (dispatch) => {
        dispatch({
            type: SEGMENT_FILTERS_EDIT_VIEW_CHANGE,
            value: isVisible
        });
    };
}
