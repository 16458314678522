export const SEGMENT_LOCATIONS_DATA_REQUEST = 'SEGMENT_LOCATIONS_DATA_REQUEST';
export const SEGMENT_LOCATIONS_DATA_SUCCESS = 'SEGMENT_LOCATIONS_DATA_SUCCESS';
export const SEGMENT_LOCATIONS_DATA_FAILURE = 'SEGMENT_LOCATIONS_DATA_FAILURE';
export const SEGMENT_LOCATIONS_CLEAN_DATA = 'SEGMENT_LOCATIONS_CLEAN_DATA';
export const MAP_LOCATION_TOOLTIP = 'Number of companies in';
export const LOCATIONS_GLOBAL = 'GLOBAL';
export const LOCATIONS_LATAM = 'LATAM';
export const LOCATIONS_APAC = 'APAC';
export const LOCATIONS_EMEA = 'EMEA';
export const NET_NEW = 'Net New';
export const MATCHES = 'Matches';
