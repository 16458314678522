import React from 'react';
import { connect } from 'react-redux';
import userAccessEnforcer from 'myhg/userAccessEnforcer';
import Loader from 'shared/ui/loader/Loader';
import loadFirmographicTaxonomy from 'shared/taxonomy/firmographic/actions';
import {
    validatePlatformToken
} from 'myhg/platformConnection/actions';
import {
    loadPermissionCount
} from 'myhg/home/permissionCount/actions';

function mapStateToProps(state) {
    return {
        authData: state.authentication.authData,
        firmographic: state.taxonomy.firmographic,
        permissionCount: state.myhg.home.permissionCount,
        platformTokenStatus: state.myhg.platformConnection.platformToken.data,
        featureFlags: state.root.featureFlags
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@userAccessEnforcer
@connect(mapStateToProps, mapDispatchToProps)

export default class DashboardAppContainer extends React.Component {

    static needs = [
        loadFirmographicTaxonomy,
        validatePlatformToken
    ];

    componentDidMount() {
        const {
            dispatch,
            firmographic,
            permissionCount,
            platformTokenStatus
        } = this.props;

        if (!permissionCount.loaded) {
            dispatch(loadPermissionCount());
        }

        if (!firmographic.data && !firmographic.isFetching) {
            dispatch(loadFirmographicTaxonomy());
        }

        if (!platformTokenStatus) {
            dispatch(validatePlatformToken());
        }
    }

    render() {
        const { firmographic } = this.props;

        if (!firmographic.data || firmographic.isFetching) {
            return <Loader />;
        }

        return this.props.children;
    }
}
