import React from 'react';
import classNames from 'classnames';
import {
    SEGMENT_MATCHING_SERVICES
} from '../segment/constants';

export default class AvailablePlatformsIcons extends React.Component {
    render() {
        const icons = SEGMENT_MATCHING_SERVICES.map((item, index) => {
            const iconClasses = classNames('available-platforms', item.iconClass, {
                'not-available': !item.available
            });

            return <span key={ index } className={ iconClasses }></span>;
        });

        return (
            <div className="trial">
                { icons }
            </div>
        );
    }
}
