import React from 'react';
import classNames from 'classnames';
import SegmentPagination from './SegmentPagination';
import SegmentExportTo from './SegmentExportTo';
import SegmentTableNetNewAndMatches from './SegmentTableNetNewAndMatches';
import GridContainer from 'shared/grid/GridContainer';
import Loader from 'shared/ui/loader/Loader';
import SegmentTableContactSales from './tablelimitmessage/SegmentTableContactSales';
import UserUtils from 'utils/UserUtils';
import MatchesLoader from '../../segmentMatches/components/MatchesLoader';
import {
    loadMatchData
} from '../actions';
import {
    SEGMENT_TABLE_START_PAGE,
    SEGMENT_TABLE_RECORDS_PER_PAGE,
    SEGMENT_RECORDS_TO_GET_IN_ONE_REQUEST,
    draggableTableHead,
    fixedTableHead,
    NON_PAID_NUMBER_OF_RECORDS,
    SEGMENT_FREE_TRIAL_ENDED
} from '../constants';
import {
    loadUserPreferences,
    saveUserPreferences
} from 'shared/user/userPreferences/actions';
import NoRecords from '../../NoRecords';
import { DateUtils } from "../../../../utils/Utils";

export default class SegmentTable extends React.Component {

    constructor(props) {
        super(props);

        const { accountDetails } = this.props;
        const isPaidUser = UserUtils.isPaid(accountDetails);

        this.state = {
            page: SEGMENT_TABLE_START_PAGE,
            perPage: isPaidUser ? SEGMENT_TABLE_RECORDS_PER_PAGE : NON_PAID_NUMBER_OF_RECORDS
        };
    }

    componentWillMount() {
        const { isUserAuthenticated, dispatch } = this.props;
        if (isUserAuthenticated) {
            dispatch(loadUserPreferences());
        }
    }

    componentWillReceiveProps(nextProps) {
        const newFilters = JSON.stringify(nextProps.currentSegment.data.filters);
        const oldFilters = JSON.stringify(this.props.currentSegment.data.filters);

        if (newFilters !== oldFilters) {
            this.setState({
                page: SEGMENT_TABLE_START_PAGE
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.accountDetails && this.props.accountDetails) {
            const { dispatch } = this.props;
            dispatch(loadMatchData());
        }
    }

    onClick = (pageNumber) => {
        const {
            accountDetails,
            matchesDataLoad,
            currentSegment: { data: { status: { tableCountRecords } } }
        } = this.props;
        const perPage = this.state.perPage;
        const currentData = matchesDataLoad.data;
        const isPaidUser = UserUtils.isPaid(accountDetails);

        if (currentData[(pageNumber * perPage) - 1] === undefined
            && currentData.length < tableCountRecords) {
            const pageToRequest = Math.round(
                (currentData.length / SEGMENT_RECORDS_TO_GET_IN_ONE_REQUEST) + 1
            );
            if (pageToRequest > Math.round(this.state.page / 2) && isPaidUser) {
                this.loadData(pageToRequest);
            }
        }

        this.setState({
            page: pageNumber
        });
    }

    onSortEnd = (sortedItems) => {
        const { dispatch } = this.props;
        const oldPreferences = this.props.userPreferences || {};
        if (oldPreferences.table) {
            oldPreferences.table.columns = sortedItems;
        } else {
            oldPreferences.table = {
                columns: sortedItems
            };
        }

        dispatch(saveUserPreferences(oldPreferences));
    }

    getPageData() {
        const currentData = this.props.matchesDataLoad.data;
        const from = (this.state.page * this.state.perPage) - this.state.perPage;
        const to = (this.state.page * this.state.perPage) - 1;
        return currentData.slice(from, to);
    }

    getTableHeaderOptions() {
        const { accountDetails } = this.props;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        const isPaidUser = UserUtils.isPaid(accountDetails);
        if (campaignTrialInfo.isActive && campaignTrialInfo.remainingDays === 0 && !isPaidUser) {
            return null;
        }

        return (
            <>
            <SegmentTableNetNewAndMatches { ...this.props } />
            { this.displayPaginationDetails() }
            <SegmentExportTo { ...this.props } />
            </>
        );
    }

    displayPaginationDetails() {
        const isSummaryDataFetching = this.props.segmentMatches.summaryData.isFetching;
        const isNetNewMatchesFetching = this.props.segmentMatches.netNewMatches.isFetching;
        const availableRecordsData = this.props.segmentMatches.availableRecordsData.isFetching;
        const { tableCountRecords } = this.props.currentSegment.data.status;
        let content;

        if (isSummaryDataFetching || isNetNewMatchesFetching || availableRecordsData) {
            content = (
                <div className="col-12 col-lg-4 table-pagination">
                    <Loader />
                </div>
            );
        } else if (!this.props.matchesDataLoad.data.length) {
            content = (
                <div className="col-12 col-lg-4 table-pagination"></div>
            );
        } else {
            content = (
                <SegmentPagination
                    tableCountRecords={ tableCountRecords }
                    currentPage={ this.state.page }
                    onClick={ this.onClick }
                    slugsLength={ this.props.currentSegment.data.filters.slugs.length }
                    { ...this.props }
                />
            );
        }

        return content;
    }

    /**
     * this is a temporary implementation since we need to give
     * the posibility to the user to chose what colums he want to see
     */
    columnPreferences() {
        const newColumns = [];
        const notfoundcolumns = [];
        const { table } = this.props.userPreferences || {};
        if (table && table.columns) {
            for (let i = 0; i < draggableTableHead.length; i++) {
                const findItem = table.columns.find((item, index) => {
                    if (item && item.key === draggableTableHead[i].key) {
                        newColumns[index] = draggableTableHead[i];
                        return true;
                    }
                    return false;
                });

                if (!findItem) {
                    notfoundcolumns.push(draggableTableHead[i]);
                }
            }
            // the new added columns is added on the end
            // remove the null objects
            return newColumns.filter((item) => { return item; }).concat(notfoundcolumns);
        }

        return draggableTableHead;
    }

    loadData(pageNumber) {
        const { dispatch } = this.props;
        const paginationParams = {
            page: pageNumber,
            perPage: SEGMENT_RECORDS_TO_GET_IN_ONE_REQUEST
        };

        dispatch(loadMatchData(
            paginationParams,
            false
        ));
    }

    renderTable() {
        const tableContent = this.getPageData();
        const tableClassName = classNames('segment-table', {
            disabled: this.props.matchesDataLoad.inProgress
        });

        return (
            <div className={ tableClassName }>
                <div className="table-content">
                    { !this.props.matchesDataLoad.inProgress ?
                        (
                            <div className="row no-selection pagination-header">
                                { this.getTableHeaderOptions() }
                            </div>
                        ) : null
                    }
                    {
                        this.props.matchesDataLoad.inProgress ? <Loader className="table-loader" />
                        : this.renderGridContainer(tableContent)
                    }
                </div>
            </div>
        );
    }

    renderGridContainer(tableContent) {
        const { accountDetails, isUserAuthenticated, netNewMatches } = this.props;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        const isPaidUser = UserUtils.isPaid(accountDetails);

        if (campaignTrialInfo.isActive && campaignTrialInfo.remainingDays === 0 && !isPaidUser) {
            return (
                <div className="row end-free-trial">
                    { SEGMENT_FREE_TRIAL_ENDED.TEXT }
                    &nbsp;
                    <b>{ SEGMENT_FREE_TRIAL_ENDED.BOLDTEXT }</b>
                    <SegmentTableContactSales
                        { ...this.props }
                    />
                </div>
            );
        } else if (netNewMatches.isFetching) {
            return <MatchesLoader />;
        } else if (this.props.matchesDataLoad.data.length === 0) {
            return (
                <div className="row">
                    <NoRecords { ...this.props } />
                </div>
            );
        }

        // Convert Unix milliseconds to human-readable
        if (tableContent !== undefined) {
            for (let i = 0; i < tableContent.length; i++) {
                if (typeof (tableContent[i].dateLastVerified) === 'number') {
                    tableContent[i].dateLastVerified = DateUtils.toStandardFormatDate(tableContent[i].dateLastVerified);
                }
            }
        }

        return (
            <div>
                <GridContainer
                    draggableTableHead={ this.columnPreferences() }
                    fixedTableHead={ fixedTableHead }
                    tableData={ tableContent }
                    fixedClass="custom-fixed-column"
                    onSortEnd={ isUserAuthenticated ? this.onSortEnd : null }
                    { ...this.props }
                />
                {
                    !UserUtils.isPaid(accountDetails) ?
                    <SegmentTableContactSales
                        { ...this.props }
                    /> : null
                }
            </div>
        );
    }

    render() {
        return this.renderTable();
    }
}
