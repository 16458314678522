import React from 'react';
import ReactSlider from 'react-slider';

export default class Slider extends React.Component {

    constructor(props) {
        super(props);

        const defaultPositions = this.getDefaultValues();
        this.state = {
            positions: defaultPositions
        };
    }

    onChange(positions) {
        this.setState({
            positions
        });

        if (this.props.onChange) {
            this.props.onChange(positions);
        }
    }

    onAfterChange(positions) {
        if (this.props.onPositionChangeEnded) {
            this.props.onPositionChangeEnded(positions);
        }
    }

    getDefaultValues() {
        return [
            this.props.defaulLeftIndex || 0,
            this.props.defaulRigthIndex || this.props.data.length - 1
        ];
    }

    getControlledSlider() {
        const positions = this.getDefaultValues();

        return (
            <ReactSlider
                min={ 0 }
                max={ this.props.data.length - 1 }
                value={ positions }
                minDistance={ 1 }
                withBars
                onChange={ this.onChange.bind(this) }
                onAfterChange={ this.onAfterChange.bind(this) }
            >
                <div className="handle-text left">
                    { this.props.data[positions[0]] }
                </div>
                <div className="handle-text">
                    { this.props.data[positions[1]] }
                </div>
            </ReactSlider>
        );
    }

    getSelfControlledSlider() {
        return (
            <ReactSlider
                min={ 0 }
                max={ this.props.data.length - 1 }
                defaultValue={ this.getDefaultValues() }
                minDistance={ 1 }
                withBars
                onChange={ this.onChange.bind(this) }
                onAfterChange={ this.onAfterChange.bind(this) }
            >
                <div className="handle-text left">
                    { this.props.data[this.state.positions[0]] }
                </div>
                <div className="handle-text">
                    { this.props.data[this.state.positions[1]] }
                </div>
            </ReactSlider>
        );
    }

    render() {
        const { isControlled } = this.props;

        return isControlled ?
            this.getControlledSlider() : this.getSelfControlledSlider();
    }
}
