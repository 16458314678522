import React from 'react';
import classNames from 'classnames';
import GenericModal from 'shared/ui/modal/GenericModal';
import { track } from 'utils/Utils';

export default class GenericDeleteButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false
        };
    }

    componentDidMount() {
        const element = this.getCurrentElement();
        if (element) {
            element.addEventListener('click', this.stopEventPropagation);
        }
    }

    componentWillUnmount() {
        const element = this.getCurrentElement();
        if (element) {
            element.removeEventListener('click', this.stopEventPropagation);
        }
    }

    onClose = () => {
        this.setState({ modalOpen: false });
    }

    getCurrentElement() {
        const { item } = this.props;
        const hrefId = 'href_'.concat(item.id);
        return document.getElementById(hrefId);
    }

    stopEventPropagation(event) {
        event.preventDefault();
        return false;
    }

    triggerConfirm = (event) => {
        event.stopPropagation();
        this.setState({ modalOpen: true });
    }

    delete = () => {
        const { onError, afterDelete, item, dispatch } = this.props;

        track(this.props.tracker, {
            label: item.name
        });

        return dispatch(this.props.deleteAction(item.id))
            .then(() => {
                if (afterDelete) {
                    afterDelete();
                }
            }).catch(error => {
                this.onClose();
                if (onError) {
                    onError(error);
                }
            });
    }

    render() {
        const deleteClassNames = classNames('segment-delete-button', this.props.className);
        return (
            <div
                className={ deleteClassNames }
                onClick={ this.triggerConfirm }
                data-qa="segment-delete"
            >
                <span className="icon icon-delete" title="Delete" />
                <GenericModal
                    title={ `Are you sure you want to delete ${this.props.item.name}?` }
                    open={ this.state.modalOpen }
                    onPrimaryActionClick={ this.delete }
                    onSecondaryActionClick={ this.onClose }
                    primaryActionText="Delete"
                    secondaryActionText="Cancel"
                />
            </div>
        );
    }
}
