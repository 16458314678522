import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import UserUtils from 'utils/UserUtils';
import {
    updateProductId,
    loadProductDetails,
    loadProductData
} from './actions';
import { loadSampleCompanies } from './sampleCompanies/actions';
import { loadCollectionsByProduct } from './collections/actions';
import { loadSimilarProducts } from './similarProducts/actions';
import { loadAccountDetails } from 'shared/user/accountDetails/actions';
import Product from './components/Product';
import HomeContainer from 'discovery/home/HomeContainer';
import Footer from 'shared/footer/Footer';
import { HTTP_NOTFOUND_CODE } from 'shared/constants';
import { clearNotification } from 'shared/notification/actions';
import { loadTechnologiesPermissions } from 'shared/user/technologiesPermissions/actions';
import { resetSegmentView } from 'myhg/segment/actions';
import { setTechnologies } from 'myhg/segment/segmentFilters/technologiesFilter/actions';

function mapStateToProps(state) {
    const { productDetails, sampleCompanies: { loadSample }, similarProducts } = state.product;

    return {
        productDetails,
        loadSample,
        similarProducts,
        accountDetails: state.user.accountDetails.data,
        authData: state.authentication.authData,
        featureFlags: state.root.featureFlags,
        technologiesPermissions: state.user.technologiesPermissions
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

/**
 * Product page container
 */
export default class ProductContainer extends React.Component {

    // actions to fulfill before pageload
    static needs = [
        updateProductId,
        loadProductDetails,
        loadSampleCompanies,
        loadSimilarProducts,
        loadCollectionsByProduct
    ];

    componentDidMount() {
        // reload data if no product is loaded or another product is requested
        if (!this.props.productDetails.slug ||
            this.props.params.productId !== this.props.productDetails.slug) {
            const productId = this.props.params.productId;
            this.props.dispatch(loadProductData(productId));
        }
        if (!this.props.accountDetails && !!this.props.authData.token) {
            this.props.dispatch(loadAccountDetails());
        }
        if (this.isUserAuthenticated()) {
            this.props.dispatch(loadTechnologiesPermissions());
        }
    }

    componentDidUpdate(prevProps) {
        const productId = this.props.params.productId;
        if (this.props.params.productId !== prevProps.params.productId) {
            this.props.dispatch(loadProductData(productId));
            this.props.dispatch(clearNotification());
        }

        if (this.isUserAuthenticated() && !prevProps.authData.token) {
            // reload product details data if user logs in
            // since some data is not available untill user is authenticated
            this.props.dispatch(loadProductDetails(productId));
        }

        if (!this.isUserAuthenticated() && prevProps.authData.token) {
            // reload product data data if user logs out
            this.props.dispatch(loadProductData(productId));
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearNotification());
    }

    onCreateSegment = () => {
        const { productId: slug } = this.props.params;
        const { dispatch } = this.props;

        dispatch(resetSegmentView());
        dispatch(setTechnologies([slug]));
    }

    getAllowedSlugs() {
        const { accountDetails, technologiesPermissions } = this.props;

        return UserUtils.getAllowedSlugs(accountDetails, technologiesPermissions);
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    render() {
        if (this.props.productDetails.error &&
            this.props.productDetails.error.status === HTTP_NOTFOUND_CODE) {
            return <HomeContainer route={ { status: HTTP_NOTFOUND_CODE } } />;
        }

        const productDetails = this.props.productDetails;
        const productName = productDetails.data.product ? productDetails.data.product : '';

        const title = `Companies Using ${productName}, Market Share, Customers and Competitors`;
        const description = `See what companies are using ${productName}, its ` +
            'competitors and similar technologies, and how its market share breaks down by ' +
            'location, number of employees, revenue, and industry';
        const keywords = `${productName} market share, companies using ${productName}, ` +
            `${productName} customers, ${productName} competitors, ` +
            `similar technologies to ${productName}`;

        return (
            <div>
                <Helmet
                    htmlAttributes= { { lang: 'en' } }
                    title= { title }
                    meta={ [
                    { name: 'description', content: description },
                    { name: 'keywords', content: keywords }
                    ] }
                />
                <Product
                    slug={ this.props.params.productId }
                    allowedSlugs={ this.getAllowedSlugs() }
                    productDetails={ this.props.productDetails }
                    sampleCompanies={ this.props.loadSample }
                    similarProducts={ this.props.similarProducts }
                    isUserAuthenticated={ this.isUserAuthenticated() }
                    dispatch={ this.props.dispatch }
                    featureFlags={ this.props.featureFlags }
                    onCreateSegment={ this.onCreateSegment }
                />
                <Footer />
            </div>
        );
    }
}
