import React from 'react';
import SignalScoresContainer from '../signalScores/SignalScoresContainer';
import LastCheckFilterContainer from '../lastCheckFilter/LastCheckFilterContainer';
import RadioButtonList from 'shared/ui/radioButton/RadioButtonList';
import { setActiveTimeframe } from '../actions';
import {
    USAGE_TIME_FRAME_SIGNAL_SCORE,
    USAGE_TIME_FRAME_LAST_CHECK
} from '../constants';

export default class UsageTimeFrame extends React.Component {

    onSelectChange = (option) => {
        const { dispatch } = this.props;
        dispatch(setActiveTimeframe(option.id));
    }

    getUsageTimeframeRadioList() {
        const { segmentFiltersView: { activeTimeFrame } } = this.props;
        const usageTimeframes = [{
            id: USAGE_TIME_FRAME_SIGNAL_SCORE,
            checked: activeTimeFrame === USAGE_TIME_FRAME_SIGNAL_SCORE
        }, {
            id: USAGE_TIME_FRAME_LAST_CHECK,
            checked: activeTimeFrame === USAGE_TIME_FRAME_LAST_CHECK
        }];

        return (
            <RadioButtonList
                className="usage-timeframe"
                items={ usageTimeframes }
                itemComponents={ [<SignalScoresContainer />, <LastCheckFilterContainer />] }
                onSelectChange={ this.onSelectChange }
            />
        );
    }

    getUsageTimeframeSelection() {
        const { segmentFiltersView: { activeTimeFrame } } = this.props;
        let content;

        if (activeTimeFrame === USAGE_TIME_FRAME_SIGNAL_SCORE) {
            content = <SignalScoresContainer />;
        } else {
            content = <LastCheckFilterContainer />;
        }
        return content;
    }

    render() {
        const { segmentFiltersView } = this.props;
        let content = null;

        if (!segmentFiltersView.isAdvancedOpen) {
            content = this.getUsageTimeframeSelection();
        } else {
            // TODO: enable radio button list selection when api available
            content = this.getUsageTimeframeSelection();
        }

        return (
            <div className="filters-section">
                { content }
            </div>
        );
    }
}
