/* global chrome */
import ApiWrapper from 'common/services/ApiWrapper';
import LoginService from 'common/services/LoginService';
import UserService from 'common/services/UserService';
import { identify } from 'utils/Utils';
import { fetchFeatureFlagsClient } from 'utils/FeatureFlags';
import {
    LOG_OUT_REASON_USER_REQUEST,
    LOG_OUT,
    REFRESH_TOKEN_REQUEST,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILURE,
    REFERER_PATH_SET
} from './constants';
import {
    setAccountDetails
} from 'shared/user/accountDetails/actions';

export function logout(reason) {
    return (dispatch, getState) => {
        identify(null);

        if (typeof chrome === 'object' && LOG_OUT_REASON_USER_REQUEST === reason) {
            const username = getState().authentication.authData.username;
            const focusExtensionId = getState().root.focusExtensionId;
            if (focusExtensionId) {
                chrome.runtime.sendMessage(
                    focusExtensionId,
                    {
                        type: 'logOut',
                        username
                    }
                );
            }
        }

        return LoginService.instance.logout()
            .then(() => {
                dispatch({
                    type: LOG_OUT
                });
            });
    };
}

/**
 * This is common action used to set the authentication data on login, registration or reset token
 *  - sets the auth data at the reducer state
 *  - applies the new token for our api wrapper
 *  - and send a request to set the new authdata cookies
 * @param {object} authData that has ttl, token, username and fullName values
 * @param {string} actionCreatorNotifierType action creator type
 * @returns {object} The promise object of the api call.
 */
export function setupAuthenticationData(data, actionCreatorNotifierType) {
    if (!data) {
        return null;
    }

    return (dispatch, getState) => {
        ApiWrapper.instance.setAuthorizationHeader(data.token);

        // create proper authData object
        const authData = {
            token: data.token,
            username: data.username,
            ttl: data.ttl * 1000,
            timestamp: new Date().getTime(),
            salesforceUsername: data.aliasAccountName || data.salesforceUsername || null,
            platformSource: data.element || null // this is platform source
        };

        return UserService.instance.getUserDetails() // Get UserDetails for FullName
            .then(userDetails => {
                const user = userDetails.data;

                // Populate authData with fullName and UserId
                const name = `${user.firstName} ${user.lastName}`;
                const email = user.username;
                const company = user.companyDetails.name;

                authData.fullName = name;
                authData.userId = user.id;
                authData.company = company;
                authData.email = email;

                // Identify user for Segment
                identify(user.id, {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    name,
                    company,
                    email,
                    created: user.createdOn,
                    phone: user.phoneNumber
                });

                // Set Auth Data
                dispatch({
                    type: actionCreatorNotifierType,
                    data: authData
                });

                // set account details data
                dispatch(setAccountDetails(user));

                // Get feature flags
                fetchFeatureFlagsClient(dispatch, getState);

                // save auth data as http only cookies using nodejs defined api
                // use the return statement here so the promise returned will help us
                // determine when the operation is complete
                return LoginService.instance.secureAuthData(authData);
            });
    };
}

export function refreshToken() {
    return (dispatch) => {
        dispatch({
            type: REFRESH_TOKEN_REQUEST
        });

        return LoginService.instance.refreshToken()
            .then((response) => {
                dispatch(setupAuthenticationData(
                    response.data,
                    REFRESH_TOKEN_SUCCESS
                ));
            })
            .catch((error) => {
                dispatch({
                    type: REFRESH_TOKEN_FAILURE,
                    error
                });
            });
    };
}

export function setRefererPath(path) {
    return {
        type: REFERER_PATH_SET,
        path
    };
}
