import {
    SEGMENT_REVENUE_CLEAN_DATA
} from './revenueChart/constants';
import {
    SEGMENT_LOCATIONS_CLEAN_DATA
} from './locationsChart/constants';
import {
    SEGMENT_INDUSTRIES_CLEAN_DATA
} from './industriesChart/constants';
import {
    SEGMENT_EMPLOYEES_CLEAN_DATA
} from './employeesChart/constants';


export function cleanFirmographicsData() {
    return (dispatch) => {
        dispatch({
            type: SEGMENT_REVENUE_CLEAN_DATA
        });
        dispatch({
            type: SEGMENT_LOCATIONS_CLEAN_DATA
        });
        dispatch({
            type: SEGMENT_INDUSTRIES_CLEAN_DATA
        });
        dispatch({
            type: SEGMENT_EMPLOYEES_CLEAN_DATA
        });
    };
}
