import React from 'react';
import sprintf from 'sprintf-js';
import classNames from 'classnames';
import {
    TOTAL_SEGMENT_PAGES,
    START_INDEX
} from '../constants';

export default class PaginationData extends React.Component {

    onPreviousClick = () => {
        const { from, step } = this.props.paginationDetails;
        this.rangeCalculation(from - step);
    }

    onNextClick = () => {
        const { from, step } = this.props.paginationDetails;
        this.rangeCalculation(from + step);
    }

    rangeCalculation(from) {
        const { until, step } = this.props.paginationDetails;
        const to = from + step - 1;
        const toVal = to > until ? until : to;
        let fromVal = from;

        if (fromVal <= 0) {
            fromVal = START_INDEX;
        }

        this.props.onArrowClick(fromVal, toVal);
    }

    render() {
        const { from, to, until } = this.props.paginationDetails;
        const packageText = sprintf.sprintf(
            TOTAL_SEGMENT_PAGES,
            from,
            to,
            until
        );

        const previousArrowCss = classNames('nav-arrows icon-small-arrow-left', {
            hide: from === START_INDEX
        });

        const nextArrowCss = classNames('nav-arrows icon-small-arrow-right', {
            hide: to >= until
        });

        return (
            <div className="pagination-data no-selection">
                <div className="table-pagination">
                    <span
                        className={ previousArrowCss }
                        onClick={ this.onPreviousClick }
                    />
                    <span className="text">
                        { packageText }
                    </span>
                    <span
                        className={ nextArrowCss }
                        onClick={ this.onNextClick }
                    />
                </div>
            </div>
        );
    }
}
