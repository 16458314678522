export const SEGMENT_CRM_STATUS_TITLE = 'SEGMENT CONNECTED TO %1$s';
export const SEGMENT_CRM_STATUS_FILE_TITLE = 'SEGMENT CONNECTED TO YOUR FILE';
export const SEGMENT_CRM_CONTACT_US = 'upgrade to HG Campaigns';
export const SEGMENT_CRM_DOWNLOAD_SAMPLE = 'Download Sample';
export const SEGMENT_CRM_DOWNLOAD_CSV = 'Download';
export const SEGMENT_CRM_UPLOAD_FILE = 'UPLOADED FILE';
export const SEGMENT_CRM_CAMPAIGM_NAME = 'CAMPAIGN NAME';
export const SEGMENT_CRM_PLATFORM_ACCOUNT = '%1$s ACCOUNT';
export const SEGMENT_CRM_END_TRIAL_TITLE = 'Thank You For Trying HG Campaigns';
export const SEGMENT_CRM_END_TRIAL_BUTTON = 'Upgrade to HG Campaigns';
export const SEGMENT_CRM_END_TRIAL_DOWNLOAD_BUTTON = 'Upgrade to Download Matches';
