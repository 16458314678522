export const MATCH_CSV_TITLE = 'Upload a list of company URLs';
export const MATCH_CSV_DETAIL = 'Your file must be less than 20MB and contain at' +
    ' least 10 distinct URLs (example.com). For larger files, please';
export const UPLOAD_NEW_FILE = 'upload_new_file';
export const PREVIOUSLY_UPLOADED = 'previously_uploaded';
export const MENU = [{
    displayName: 'Upload New File',
    name: 'upload_new_file'
}, {
    displayName: 'Previously Uploaded',
    name: 'previously_uploaded'
}];

export const FILE_SIZE_TOO_LARGE =
    'Please upload a file smaller than 20 Mb!';

export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_CLEAN = 'UPLOAD_FILE_CLEAN';
export const UPLOAD_FILE_ON_HOLD = 'UPLOAD_FILE_ON_HOLD';
export const FILE_SIZE_LIMIT = 1024 * 1024 * 20; // 20 Mb

export const ADD_PHONE_NUMBER_REQUEST = 'ADD_PHONE_NUMBER_REQUEST';
export const ADD_PHONE_NUMBER_SUCCESS = 'ADD_PHONE_NUMBER_SUCCESS';
export const ADD_PHONE_NUMBER_FAILURE = 'ADD_PHONE_NUMBER_FAILURE';

export const LIMIT_REACHED_UPLOADS_TITLE = 'You have reached your free uploads limit';
export const LIMIT_REACHED_UPLOADS_INFO = ' ';
