import React from 'react';
import { Route } from 'react-router';
// connect
import SalesforceConnectContainer from
    'myhg/platformConnection/salesforce/SalesforceConnectContainer';
import PardotConnectContainer from
    'myhg/platformConnection/pardot/PardotConnectContainer';
import EloquaConnectContainer from
    'myhg/platformConnection/eloqua/EloquaConnectContainer';
import MarketoConnectContainer from
    'myhg/platformConnection/marketo/MarketoConnectContainer';
import LinkedInConnectContainer from
        'myhg/platformConnection/linkedin/LinkedInConnectContainer';
import OAuthorizeContainer from
    'myhg/platformConnection/oAuthCallback/OAuthorizeContainer';

export const connectPlatformRoutes = (
    <Route
        path="connect"
    >
        <Route
            path="salesforce"
            component={ SalesforceConnectContainer }
        />
        <Route
            path="pardot"
            component={ PardotConnectContainer }
        />
        <Route
            path="eloqua"
            component={ EloquaConnectContainer }
        />
        <Route
            path="marketo"
            component={ MarketoConnectContainer }
        />
        <Route
            path="linkedin"
            component={ LinkedInConnectContainer }
        />
        <Route
            path="platform-authorize"
            component={ OAuthorizeContainer }
        />
    </Route>
);
