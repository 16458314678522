import React from 'react';
import {
    ELOQUA_CONNECT_NOTE
} from '../constants';

export default class EloquaConnectWrapper extends React.Component {

    render() {
        return (
            <div className="platform-connect-content">
                <ol>
                    <li>
                        Access your contact/lead list
                    </li>
                    <li>
                        Match your contact/lead list with HG's installed technology data
                    </li>
                    <li>
                        Refine your audience using our firmographic data
                    </li>
                    <li>
                        Export it back to Eloqua in a new or existing campaign
                    </li>
                </ol>
                <span className="note-message">{ ELOQUA_CONNECT_NOTE }</span>
                { this.props.children }
            </div>
        );
    }
}
