import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

export default class TaxonomySevice {

    constructor(enforcer) {
        this.cachedData = {};
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new TaxonomySevice(singletonEnforcer);
        }
        return this[singleton];
    }

    // getData - load taxonomy data
    //
    // @usedCahed: boolean - determine if cached data should be used when retrieving data
    // @taxonomyData: string - data type requested ( ie : products, categories, parentCategories,
    // vendors)
    // @url: string - the relative url for the next data page
    // @return Promise object
    getData(useCached, taxonomyData, url, params = {}) {
        if (!url) { // don't use cached data if this is a next url request
            const cachedData = this.cachedData[taxonomyData];
            if (cachedData && cachedData.length && useCached) {
                return new Promise(
                    (resolve, reject) => {
                        resolve({
                            data: this.cachedData[taxonomyData]
                        });
                        reject({});
                    }
                );
            }

            this.cachedData[taxonomyData] = [];
        }
        return ApiWrapper.instance.axios.get(url || `data/taxonomy/${taxonomyData}`,
            { params }).then((response) => {
                const nextUrl = ApiWrapper.instance.getNextPageLink(response.headers.link);
                // concat previous data and current bunch of data
                // this.cachedData[taxonomyData] = [
                //     ...this.cachedData[taxonomyData],
                //     ...response.data
                // ];
                // TODO: caching data is causing duplicate items (on server side)
                // due to singleton nature of the class.
                // Revert code once we refactor the api wrappers/services.
                this.cachedData[taxonomyData] = response.data;
                // return next url ( could be null ) and full loaded data
                return {
                    nextUrl,
                    data: this.cachedData[taxonomyData]
                };
            });
    }

    getProducts(url) {
        const params = {
            perPage: 100000
        };
        return this.getData(false, 'products', url, params);
    }

    getCollections() {
        const params = {
            perPage: 10000
        };
        return this.getData(false, 'collections', null, params);
    }

    getFirmographic() {
        return this.getData(false, 'firmographics');
    }

    suggestTechnology(technologyName) {
        return ApiWrapper.instance.axios.get(`data/taxonomy/suggest/${technologyName}`);
    }

    getTaxonomyData() {
        // todo, api route will change
        return ApiWrapper.instance.axios.get('salesforce/taxonomy');
    }
}
