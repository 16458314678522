export const ACTION = {
    // Navigation
    VIEW_PRODUCT: 'Click Product',

    // Sign up
    SIGN_UP: 'Click Sign Up',
    SIGN_UP_SUBMIT: 'Sign Up: Submit Form',

    // Contact us
    CONTACT_US: 'Click Contact Us',
    CONTACT_US_SUBMIT: 'Contact Us: Send Message',

    // Download Sample
    DOWNLOAD_SAMPLE: 'Click Download Sample',
    DOWNLOAD_SAMPLE_DOWNLOAD: 'Download Sample: Download CSV',

    // Append list
    APPEND_LIST: 'Click Upload List',
    APPEND_LIST_NEXT: 'Upload List: Next Button',
    APPEND_LIST_UPLOAD: 'Upload List: Upload',

    // Campaign Builder
    CREATE_CAMPAIGN: 'Click Create Campaign',
    CREATE_CAMPAIGN_NEXT: 'Create Campaign: Next',
    CREATE_CAMPAIGN_AUTHORIZE: 'Create Campaign: Authorize Connection',
    CREATE_CAMPAIGN_SETUP: 'Create Campaign: Campaign Setup',
    CREATE_CAMPAIGN_REVENUE: 'Create Campaign: Adjust Revenue',
    CREATE_CAMPAIGN_EMPLOYEES: 'Create Campaign: Adjust Employees',
    CREATE_CAMPAIGN_LOCATIONS: 'Create Campaign: Adjust Locations',
    CREATE_CAMPAIGN_INDUSTRIES: 'Create Campaign: Adjust Industries',
    CREATE_CAMPAIGN_EXPORT: 'Create Campaign: Export to Salesforce',
    CREATE_CAMPAIGN_SAVE: 'Create Campaign: Save to Salesforce',
    CREATE_CAMPAIGN_OPEN: 'Create Campaign: Open in Salesforce',
    CREATE_CAMPAIGN_CHECK_PROGRESS_SF: 'Check Progress in Salesforce',
    CREATE_CAMPAIGN_RESTART: 'Create Campaign: Build a new campaign',

    // Product page
    RELATED_TECHNOLOGIES_TOGGLE: 'Related Tech: Toggle View',
    RELATED_TECHNOLOGIES_TAG: 'Related Tech: Toggle Tags',
    FIRMOGRAPHIC_TAB: 'Firmographics: Click Tab',
    PRODUCT_INTEREST_YES: 'Interested in this product (Yes)',
    PRODUCT_INTEREST_NO: 'Interested in this product (No)',
    VIEW_PRODUCT_LIST: 'View Collection\'s Product List',
    PRODUCT_REMOVED_FROM_CART: 'Product: Product Removed from Cart',
    PRODUCT_VIEW_COLLECTIONS: 'Product: View Collections',
    PRODUCT_CREATE_TARGET_SEGMENT: 'Product: Create Target Segment',

    // Recommended Collections page
    SELECT_COLLECTION: 'Collection: Selected',
    DE_SELECT_COLLECTION: 'Collection: De-selected',
    SEE_NEXT_COLLECTIONS: 'Collection: See Next Collections',
    VIEW_PLANS_AND_PRICING: 'Collection: View Plans and Pricing',

    // P&P page
    CONTACT_SALES: 'Pricing & Plans: Contact Sales',
    PP_DOWNLOAD_FOCUS: 'Pricing & Plans: Download Focus',
    PP_GET_SAMPLE_DATA: 'Pricing & Plans: Get Sample Data',
    PP_MATCH_MY_DATA: 'Pricing & Plans: Match to My Data',
    PP_BUILD_CAMPAIGN: 'Pricing & Plans: Build a Campaign',

    // Segments
    OPEN_SEGMENT: 'Click to open segment',
    CREATE_SEGMENT: 'Click Create Segment',
    SAVE_SEGMENT_MENU: 'Segment: Click Save',
    SAVE_SEGMENT_NEW: 'Segment: Apply Filters and Save (New)',
    SAVE_SEGMENT_UPDATED: 'Segment: Apply Filters and Save (Updated)',
    DELETE_SEGMENT: 'Segment: Delete',
    MATCH_DATA_SEGMENT: 'Segment: Match to My Data',
    EXPORT_SEGMENT_CSV: 'Segment: Export to CSV',
    SHARE_SEGMENT: 'Segment: Share',
    SHARE_SEGMENT_CLIENT_EMAIL: 'Segment: Share using Client Email',
    SHARE_SEGMENT_CLIENT_TWITTER: 'Segment: Share using Twitter',
    SHARE_SEGMENT_CLIENT_LINKEDIN: 'Segment: Share using LinkedIn',
    SHARE_SEGMENT_CLIENT_API: 'Segment: Share using Api',

    // SalesForce Lite Slideout
    VIEW_LITE_SLIDEOUT: 'View SalesForce Lite Slideout',
    CLOSE_LITE_SLIDEOUT: 'Close SalesForce Lite Slideout',
    LITE_SLIDEOUT_LEARN_MORE: 'Click Learn More on SalesForce Lite Slideout',
    LITE_SLIDEOUT_INSTALL_EXTENSION: 'Click Install Chrome Extension on SalesForce Lite Slideout',

    // Chrome Extension
    DOWNLOAD_EXTENSION: 'Download Chrome Extension',
};

export const WHERE = {
    // General
    FOOTER: 'Footer',
    PAGE_NOT_FOUND: '404 Page',

    // Lightbox
    PRELOGIN_LIGHTBOX: 'Pre-login Lightbox',
    POSTLOGIN_LIGHTBOX: 'Post-login Lightbox',

    // Home
    TYPIST: 'Home: Typist',
    TRENDING_TECHNOLOGIES: 'Home: Trending Tech',
    HOME_CTA: 'Home: CTA',
    HOME_SEARCH: 'Home: Search',

    // Product
    COMPANIES_USING: 'Product: Top Companies',
    RELATED_TECHNOLOGIES_CHART: 'Product: Related Tech Chart',
    RELATED_TECHNOLOGIES_LIST: 'Product: Related Tech List',
    FIRMOGRAPHICS: 'Product: Firmographics',
    PRODUCT_CTA: 'Product: CTA',
    PRODUCT_SEARCH: 'Product: Search',
    PRODUCT_HEADER: 'Product: Header',
    PRODUCT_BODY: 'Product: Body',
    COLLECTIONS: 'Product: Collections',

    // My Data
    MY_DATA: 'Dashboard',
    CREATE_CAMPAIGN_EXPORT_SUMMARY: 'Create Campaign: Summary',

    // Collection Recommendation page
    COLLECTIONS_REC_TOP_SECTION: 'Recommended Collections: Top Section',
    COLLECTIONS_LIST: 'Recommended Collections: Collection List',

    // P&P page
    PRICING_AND_PLANS_TRIAL_INFO: 'PRICING_AND_PLANS: Free Trial Info Section',
    PRICING_AND_PLANS_CONTACT: 'PRICING_AND_PLANS: Contact Sales',

    // User Account Homepage
    USER_ACCOUNT_HEADER: 'User Account: Header',
    USER_ACCOUNT_BODY: 'User Account: Body',

    // SEGMENT
    SEGMENT_MENU: 'Segment: Menu Actions',

    // SHARE SEGMENT PAGE
    SHARE_SEGMENT_PAGE: 'Share Segment Page',

    SALESFORCE_LITE_SLIDEOUT: 'SalesForce Lite Slideout'

};

export const VIEW = {
    CHART: 'Chart',
    LIST: 'List'
};

export const USER = {
    PRELOGIN: 'Visitor (pre-authentication)',
    POSTLOGIN: 'User (authenticated) '
};
