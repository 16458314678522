import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import AllSegments from './components/AllSegments';
import ProductHeaderContainer from 'shared/header/ProductHeaderContainer';
import Footer from 'shared/footer/Footer';
import {
    getSegments
} from './actions';

function mapStateToProps(state) {
    return {
        allSegments: state.myhg.viewallsegments.allSegments,
        permissionCount: state.myhg.home.permissionCount,
        accountDetails: state.user.accountDetails.data
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class ViewAllSegmentsContainer extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(getSegments());
    }

    render() {
        const title = 'View All Segments';

        return (
            <div>
                <Helmet
                    htmlAttributes= { { lang: 'en' } }
                    title= { title }
                />
                <ProductHeaderContainer />
                <AllSegments { ...this.props } />
                <Footer />
            </div>
        );
    }
}
