import {
    FILTERS_SET_EMPLOYEES_INTERVAL,
    SEGMENT_FILTERS_CLEAR
} from '../constants';

export const employeesFilterInitialState = null;

export default function employees(state = employeesFilterInitialState, action) {
    switch (action.type) {
        case FILTERS_SET_EMPLOYEES_INTERVAL:
            return action.employees;

        case SEGMENT_FILTERS_CLEAR:
            return employeesFilterInitialState;

        default:
            return state;
    }
}
