import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton class that encapsulates emailing API calls
 */
export default class EmailService {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new EmailService(singletonEnforcer);
        }
        return this[singleton];
    }

    /**
     * Send an email
     * @param recipients {array} array of email recipients
     * @param url {string} the url of the segment
     * @param name {string} the name of the segment
     * @returns {object} The promise object of the api call.
     */
    sendEmail(recipients, url, name) {
        return ApiWrapper.instance.axios.post('segments/share/email',
            {
                segmentName: name,
                segmentLink: url,
                recipients
            });
    }
}
