import React from 'react';
import { CONFIRM_STEPS_INFO } from './constants';

export default class PlatformConfirmInfo extends React.Component {

    getFlowDetails() {
        const { landingPage } = this.props;
        const list = CONFIRM_STEPS_INFO[landingPage].steps.map((item, index) => {
            return (
                <li key={ index }>
                    <span className="icon number">
                        { index + 1 }
                    </span>
                    <span className="item">
                        <span className="item-group">
                            <span>{ item }</span>
                        </span>
                    </span>
                </li>
            );
        });

        return (
            <ol className="landing-page-benefits">
                { list }
            </ol>
        );
    }

    getVideo() {
        const { landingPage } = this.props;

        return (
            <div className="info-video">
                <p>View a quick demo</p>
                <iframe
                    width="497"
                    height="280"
                    src={ CONFIRM_STEPS_INFO[landingPage].videoUrl }
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        );
    }

    render() {
        return (
            <div className="signup-landing-page campaign">
                <div className="title">
                    How It Works
                </div>
                { this.getFlowDetails() }
                { this.getVideo() }
            </div>
        );
    }
}
