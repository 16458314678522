import React from 'react';
import ReactTooltip from 'react-tooltip';
import sprintf from 'sprintf-js';
import UserUtils from 'utils/UserUtils';
import {
    BTN_SHARE_SEGMENT,
    BTN_SAVE_SEGMENT
} from '../constants';
import {
    LIMIT_TECH_EXCEEDED
} from 'myhg/segment/segmentFilters/components/constants';
import {
    FILTER_PRODUCT_MAX_COUNT
} from 'myhg/segment/segmentFilters/technologiesFilter/constants';

export default class SegmentMenuActions extends React.Component {

    getShareSegmentButton() {
        return (
            <a
                id="share-segment"
                className="menu-action"
                onClick={ this.props.handleShareClick }
                data-qa="share-segment"
            >
                <span className="icon icon-share"></span>
                { BTN_SHARE_SEGMENT }
            </a>
        );
    }

    getSaveSegmentButton() {
        const { segmentFilters } = this.props.segment;
        const technologiesCount = segmentFilters.technologies.length;
        const isTechsFilterLimitExceeded =
            !this.isUserPaid() && technologiesCount > FILTER_PRODUCT_MAX_COUNT;

        let content = null;

        if (isTechsFilterLimitExceeded) {
            content = (
                <span>
                    <span
                        className="menu-action inactive"
                        data-tip={ sprintf.sprintf(LIMIT_TECH_EXCEEDED, FILTER_PRODUCT_MAX_COUNT) }
                    >
                        <span className="icon icon-save"></span>
                        { BTN_SAVE_SEGMENT }
                    </span>
                    <ReactTooltip effect="solid" />
                </span>
            );
        } else {
            content = (
                <a
                    id="save-segment"
                    className="menu-action"
                    onClick={ this.props.handleSaveClick }
                >
                    <span className="icon icon-save"></span>
                    { BTN_SAVE_SEGMENT }
                </a>
            );
        }

        return content;
    }

    isUserPaid() {
        const { accountDetailsData } = this.props;
        return UserUtils.isPaid(accountDetailsData);
    }

    render() {
        const { segment } = this.props;
        return (
            <div className="segment-menu-actions">
                { segment.currentSegment.id ? null : this.getSaveSegmentButton() }
                { this.getShareSegmentButton() }
            </div>
        );
    }
}
