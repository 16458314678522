import { combineReducers } from 'redux';
import moversShakers from './moversShakers/reducer';
import permissionCount from './permissionCount/reducer';
import additionalCompaniesByMonth from './additionalCompanies/reducer';

export default combineReducers({
    moversShakers,
    permissionCount,
    additionalCompaniesByMonth
});
