import React from 'react';
import SearchContainer from 'shared/search/SearchContainer';
import { ACTION, WHERE } from 'utils/track-constants';
import { track } from 'utils/Utils';
import {CONTACT_US_URL} from "../../../shared/footer/constants";
import {Link} from "react-router";

export default class NotFound extends React.Component {

    onContactClick = () => {
        track(ACTION.CONTACT_US, { label: WHERE.PAGE_NOT_FOUND });
    }

    getMessage() {
        return (
            <div>
                <p className="lead">
                    This page doesn't exist or it was moved someplace else.
                </p>
                <p className="lead">
                    Either way, we apologize for the inconvenience. Please&nbsp;
                    <Link
                        to={CONTACT_US_URL}
                        target="_blank"
                        rel="nofollow"
                        onClick={this.onContactClick()}
                    >
                        contact&nbsp;us
                    </Link>
                    &nbsp;to report the link, or resume your search for a technology below.
                </p>
            </div>
        );
    }

    getSearchContainer() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h1 className="error">
                            <div className="prefix ">404 Error</div>
                            <div className="prefix ">Oh no! We can't find that page.</div>
                        </h1>
                        { this.getMessage() }
                        <SearchContainer
                            autoFocus
                            className="home-search"
                            source="homeTopBanner"
                            onTechnologySelect={ this.props.onTechnologySelect }
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="not-found">
                { this.getSearchContainer() }
            </div>
        );
    }
}
