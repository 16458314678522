export default class Validation {

    static validateEmail(email) {
        // username can contain only alphanumeric values, '._%-' symbols,
        // must be followed by '@', the domain name can contain alphanumeric values,
        // must be followed by '.'
        // and the top level domain can contain only letters and the length be >=2 and <=1024
        // The only exception to these rules is alias qa_test@hginsights.com email
        // with the aliased portion being exactly 7 characters between the '+' & `@` symbol
        // e.g. "qa_test+1234567@hginsights.com
        const emailValidation = /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,1024}$/i;
        const qaEmailValidation = /^qa_test\+[A-Z0-9]{7}@hginsights\.com$/i;
        return (emailValidation.test(email) || qaEmailValidation.test(email));
    }

    static validatePassword(password) {
        // Password must contain at least one lower case letter,
        // one upper case letter, one digit and must be at least 6 characters.
        const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
        return passwordValidation.test(password);
    }

    static validateWhitespace(field) {
        const whitespaceValidation = field ? field.trim() : field;
        return whitespaceValidation;
    }
}
