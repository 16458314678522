import React from 'react';
import ReactDOM from 'react-dom';

function isTextOverflow(element) {
    return element.clientWidth < element.scrollWidth;
}

export default class OverflowTooltip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            overflow: false
        };
    }

    componentDidMount() {
        this.checkOverflow();
    }

    componentWillReceiveProps() {
        this.setState({ overflow: false });
    }

    componentDidUpdate() {
        this.checkOverflow();
    }

    checkOverflow() {
        const element = ReactDOM.findDOMNode(this);

        const overflow = isTextOverflow(element);
        if (overflow !== this.state.overflow) {
            this.setState({ overflow: true });
        }
    }

    render() {
        let dataTitle = null;
        if (this.state.overflow) {
            dataTitle = this.props.title;
        }

        return (
            <div
                data-title={ dataTitle }
                className={ this.props.className }
            >
                { this.props.value }
            </div>
        );
    }
}
