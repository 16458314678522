import { combineReducers } from 'redux';
import revenueFirmographic from 'myhg/segment/firmographicProfile/revenueChart/reducer';
import employeesFirmographic from 'myhg/segment/firmographicProfile/employeesChart/reducer';
import industriesFirmographic from 'myhg/segment/firmographicProfile/industriesChart/reducer';
import locationsFirmographic from 'myhg/segment/firmographicProfile/locationsChart/reducer';

// TODO the firmographics reducers and actions are very similar (only urls differs)
// Implement single action/reducer that loads data by "taxonomy type"
export default combineReducers({
    revenueFirmographic,
    employeesFirmographic,
    industriesFirmographic,
    locationsFirmographic
});

export const denormalizeNetNewMatchesData = (data) => {
    const matched = data.map((item) => {
        const { matches, ...rest } = item;
        return { value: matches, ...rest };
    });

    const netNewData = data.map((item) => {
        const { netNew, ...rest } = item;
        return { value: netNew, ...rest };
    });

    return {
        matched,
        netNew: netNewData
    };
};
