import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PlatformService from 'common/services/PlatformService';

function mapStateToProps(state) {
    return {
        authData: state.authentication.authData
    };
}

@connect(mapStateToProps)

// Form submit Component for oauth
export default class OAuthConnectActionHandler extends React.Component {

    handleSubmit = () => {
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }

    render() {
        const { switchAccount, platformName } = this.props;
        const btnClass = classNames('btn', {
            'btn-primary': !switchAccount,
            'btn-link': !!switchAccount,
        });
        const actionMessage = switchAccount ?
            'Switch Account' : 'Connect';

        return (
                <form
                    action={ PlatformService.instance.getSubmitAuthUrl(platformName) }
                    method="GET"
                    onSubmit={ this.handleSubmit }
                >
                    <input
                        type="hidden"
                        name="state"
                        defaultValue={ JSON.stringify(this.props.state) }
                    />
                    <input
                        type="hidden"
                        name="authorization"
                        defaultValue={ this.props.authData.token }
                    />
                    <input type="hidden" name="force_login"
                        defaultValue={ switchAccount }
                    />
                    <input
                        className={ btnClass }
                        type="submit"
                        defaultValue={ this.props.actionText || actionMessage }
                        data-qa="connect-button"
                    />
                </form>
        );
    }
}

OAuthConnectActionHandler.propTypes = {
    platformName: PropTypes.string.isRequired
};
