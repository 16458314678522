export const TOTAL_COUNTS_TITLE = 'Your Custom Segment';
export const BAR_CHART_TITLE_PAID = 'Top 3 Products';
export const BAR_CHART_TITLE_UNPAID = 'Product Analysis';

export const TOTAL_COMPANIES =
    'All companies using these products (without any filters)';

export const FILTERED_COMPANIES =
    'Companies using these products within your segment filters';

export const NET_NEW_COMPANIES1 = 'Companies that are';
export const NET_NEW_COMPANIES2 = ' Net New ';
export const NET_NEW_COMPANIES3 = 'to your imported data';

export const MATCHING_COMPANIES1 = 'Companies that';
export const MATCHING_COMPANIES2 = ' Matched ';
export const MATCHING_COMPANIES3 = 'your imported data';

export const REFINE_SEGMENT_FILTERS = 'Refine Segment Filters';

export const TOTAL_COMPANIES_LABEL = 'Total Companies';
export const FILTERED_COMPANIES_LABEL = 'Filtered Companies';
export const NET_NEW_COMPANIES_LABEL = 'Net New Companies';
export const MATCHED_COMPANIES_LABEL = 'Matched Companies';

export const CHART_TRENDING_12_MONTHS = 'in last 12 months';

export const TREND_ICONS = {
    flat: '\u2192',
    growth: '\u2197',
    drop: '\u2198'
};
