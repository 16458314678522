import React from 'react';
import { connect } from 'react-redux';
import SegmentTable from './components/SegmentTable';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        accountDetails: state.user.accountDetails.data,
        authData: state.authentication.authData,
        currentSegment: segment.currentSegment,
        hasFilterChanged: segment.segmentTable.hasNewFilters,
        matchesDataLoad: segment.segmentTable.matchesDataLoad,
        segmentExport: segment.segmentExport,
        userPreferences: state.user.userPreferences.data,
        segmentMatches: segment.segmentMatches,
        summaryData: segment.segmentMatches.summaryData,
        segmentTable: segment.segmentTable,
        netNewMatches: segment.segmentMatches.netNewMatches,
        segmentSave: segment.segmentSave
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class SegmentTableContainer extends React.Component {
    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    render() {
        const { currentSegment } = this.props;
        if (Object.keys(currentSegment.data.filters).length === 0) {
            return null;
        }

        return (
            <PanelWrapper
                className="table-wrapper"
            >
                <SegmentTable
                    { ...this.props }
                    isUserAuthenticated={ this.isUserAuthenticated() }
                />
            </PanelWrapper>
        );
    }
}
