import {
    ADDITIONAL_COMPANIES_REQUEST,
    ADDITIONAL_COMPANIES_SUCCESS,
    ADDITIONAL_COMPANIES_FAILURE,
    SHOW_ADDITIONAL_COMPANIES,
    DEFAULT_PERIOD
} from './constants';

export const additionalCompaniesInitialState = {
    isFetching: false,
    error: null,
    data: null
};

const additionalCompanies = (state = additionalCompaniesInitialState, action) => {
    switch (action.type) {
        case ADDITIONAL_COMPANIES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case ADDITIONAL_COMPANIES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data,
                error: null
            });

        case ADDITIONAL_COMPANIES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
};

export const newCompaniesInitialState = {
    period: DEFAULT_PERIOD
};

export default function additionalCompaniesByMonth(state = newCompaniesInitialState, action) {
    switch (action.type) {
        case ADDITIONAL_COMPANIES_REQUEST:
        case ADDITIONAL_COMPANIES_SUCCESS:
        case ADDITIONAL_COMPANIES_FAILURE:
        case SHOW_ADDITIONAL_COMPANIES:
            return Object.assign({}, state, {
                [action.period]: additionalCompanies(state[action.period], action),
                period: action.period
            });

        default:
            return state;
    }
}
