import React from 'react';
import { connect } from 'react-redux';
import {
    SELECT_INDUSTRIES_PLACEHOLDER,
    SELECT_INDUSTRIES_ALL_PLACEHOLDER
} from './constants';
import {
    addIndustry,
    removeIndustry
} from './actions';
import { parseIndustriesforDropdown } from './reducer';
import Typeahead from 'shared/ui/typeahead/Typeahead';
import TypeaheadSelectedItem from 'shared/ui/typeahead/TypeaheadSelectedItem';
import { removeUnknowValuesId } from '../unknownValuesFilter/actions';
import { UNKNOWN_VALUES_INDUSTRIES_FILTER_NAME } from '../unknownValuesFilter/constants';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        firmographic: state.taxonomy.firmographic,
        technologies: segment.segmentFilters.technologies,
        industries: segment.segmentFilters.industries,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class IndustriesFilterContainer extends React.Component {

    onSelectItem(item) {
        const { dispatch, industries: { selectedIndustries } } = this.props;

        // unselect unknown firmo value for industries
        if (!selectedIndustries.length) {
            dispatch(removeUnknowValuesId(UNKNOWN_VALUES_INDUSTRIES_FILTER_NAME));
        }
        dispatch(addIndustry(item.id));
    }

    onRemove(item) {
        const { dispatch } = this.props;
        dispatch(removeIndustry(item.id));
    }

    getSelectedIndustries(industries) {
        return industries.map((industryId, index) => {
            const industryItem = {
                id: industryId,
                text: industryId
            };

            return (
                <TypeaheadSelectedItem
                    key={ index }
                    divClassName={ 'technology-icon-text' }
                    item={ industryItem }
                    onRemove={ this.onRemove.bind(this, industryItem) }
                />
            );
        });
    }

    render() {
        const { industriesList } = this.props.firmographic.data;
        const { industries } = this.props;
        // top industries feature is not supported anymore we decided to set as
        // an empty array until the method parseIndustriesforDropdown is rewritten
        const topIndustries = [];
        const typeaheadIndustries = parseIndustriesforDropdown(
            industriesList,
            topIndustries,
            industries.selectedIndustries
        );

        const inputPlaceholderText = industries.selectedIndustries.length ?
            SELECT_INDUSTRIES_PLACEHOLDER : SELECT_INDUSTRIES_ALL_PLACEHOLDER;

        return (
            <div>
                <Typeahead
                    inputPlaceholderText={ inputPlaceholderText }
                    searchData={ typeaheadIndustries }
                    onSelectItem={ this.onSelectItem.bind(this) }
                />
                <div className="typeahead-selecteditems-container">
                    { this.getSelectedIndustries(industries.selectedIndustries) }
                </div>
            </div>
        );
    }
}
