import React from 'react';
import classNames from 'classnames';
import ValidationField from 'shared/ui/formFields/ValidationField';
import Validation from 'utils/Validation';

export default class EmailsShare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '',
            error: null,
        };
    }

    onEmailChange = (event) => {
        const email = event.target.value;
        this.setState({
            input: email
        });
    }

    onType = (event) => {
        if (event.keyCode === 13) {
            this.addEmail();
        }
    }

    getShareEmailsList() {
        const emails = this.props.shareEmails;

        const emailsBubbles = emails.map((email, index) => {
            const bubbleClass = classNames('email-wrapper');

            return (
                <div
                    key={ index }
                    className={ bubbleClass }
                >
                    <span className="share-email" > { email } </span>
                    <span className="icon-close-x-small remove-share-email"
                        onClick={ this.removeEmail.bind(this, index) }
                    />
                </div>
            );
        });

        return <div className="share-emails"> { emailsBubbles } </div>;
    }

    getActionFeedback() {
        let feedbackMessage;
        let cssClass = this.props.shareSuccess ? 'share-success' : 'share-error';

        if (this.props.shareSuccess) {
            feedbackMessage = (
                <div>
                    Email(s) successfully sent
                    <a onClick={ this.shareCleanup.bind(this) }> OK </a>
                </div>
            );
        } else {
            feedbackMessage = this.props.shareError;
        }

        if (this.state.error) {
            cssClass = 'share-error';
            feedbackMessage = this.state.error;
        }

        return (
            <span className={ cssClass }> { feedbackMessage } </span>
        );
    }

    removeEmail = (index) => {
        this.props.removeEmail(index);
    }

    validateShare() {
        return this.props.shareEmails.length > 0;
    }

    sendEmails = () => {
        const emails = this.props.shareEmails;

        this.props.sendEmails(emails);
    }

    shareCleanup = () => {
        this.props.clearState();
    }

    addEmail = () => {
        const emails = this.props.shareEmails;
        const email = this.state.input;

        if (Validation.validateEmail(email)) {
            if (emails.indexOf(email.toLowerCase()) < 0) {
                this.props.addEmail(email);
                this.setState({
                    input: '',
                    error: null
                });
            } else {
                this.setState({
                    error: 'Email already added'
                });
            }
        } else {
            let validationMessage = 'Invalid email';

            if (!email) {
                validationMessage = 'Email is required';
            }

            this.setState({
                error: validationMessage
            });
        }
    }

    render() {
        return (
            <div>
                <ValidationField
                    type="input"
                    label="Type 1 or more emails to share"
                    placeholder="julie@example.com"
                    fieldType="input"
                    value={ this.state.input }
                    feedback="+ more"
                    feedbackHandler={ this.addEmail.bind(this) }
                    onChange={ this.onEmailChange }
                    onKeyUp={ this.onType }
                />
                { this.getActionFeedback() }
                { this.getShareEmailsList() }
                <button
                    id="share-button"
                    type="submit"
                    className="btn btn-primary share-button"
                    onClick={ this.sendEmails }
                    disabled={ !this.validateShare() }
                >
                    Send Segment Link
                </button>
            </div>
        );
    }
}
