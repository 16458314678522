import { combineReducers } from 'redux';
import segmentMenu from 'myhg/segment/segmentMenuActions/reducer';
import segmentMatches from 'myhg/segment/segmentMatches/reducer';
import segmentTable from 'myhg/segment/segmentTable/reducer';
import segmentFilters from 'myhg/segment/segmentFilters/reducer';
import firmographic from 'myhg/segment/firmographicProfile/reducer';
import shareViaEmail from 'myhg/segment/segmentShare/reducer';
import {
    SEGMENT_LOAD_REQUEST,
    SEGMENT_LOAD_SUCCESS,
    SEGMENT_LOAD_FAILURE,
    SEGMENT_NEW,
    SEGMENT_SAVE_REQUEST,
    SEGMENT_SAVE_SUCCESS,
    SEGMENT_SAVE_FAILURE,
    SEGMENT_NAME_CHANGED,
    SEGMENT_FILTERS_CHANGED,
    SEGMENT_FILTERS_EDIT_VIEW_CHANGE,
    SEGMENT_NAME_EDIT_VIEW_CHANGE,
    SEGMENT_RESET_VIEW,
    SEGMENT_UPDATE,
    SEGMENT_UPDATE_FILTERS,
    SEGMENT_STATUS,
    SEGMENT_DOWNLOAD_CSV_REQUEST,
    SEGMENT_DOWNLOAD_CSV_SUCCESS,
    SEGMENT_DOWNLOAD_CSV_FAILURE,
    SEGMENT_DELETE_SUCCESS
} from './constants';

export function getEditableData(data) {
    return {
        name: data.name
    };
}

export const currentSegmentInitialState = {
    name: '',
    data: {
        filters: {},
        status: {
            netNew: null,
            matched: null,
            companies: null,
            productCompanies: null,
            status: SEGMENT_STATUS.NON_MATCHED,
            source: '',
            sourceValue: ''
        },
        // used for expected visualization data for some of the filters
        visualFilters: {}
    }
};

export function currentSegment(state = currentSegmentInitialState, action) {
    switch (action.type) {
        case SEGMENT_SAVE_SUCCESS:
        case SEGMENT_LOAD_SUCCESS: {
            return Object.assign({}, state, action.data);
        }

        case SEGMENT_NAME_CHANGED: {
            return Object.assign({}, state, { name: action.name });
        }

        case SEGMENT_UPDATE_FILTERS: {
            const newFilters = { ...state.data.filters, ...action.filters };
            const newData = { ...state.data, ...{ filters: newFilters } };
            return Object.assign({}, state, { data: newData });
        }

        case SEGMENT_UPDATE:
            return Object.assign({}, action.segment);

        case SEGMENT_DELETE_SUCCESS:
        case SEGMENT_NEW:
        case SEGMENT_RESET_VIEW:
            return currentSegmentInitialState;

        default:
            return state;
    }
}

export const segmentLoadInitialState = {
    inProgress: false,
    loaded: false,
    error: null
};

export function segmentLoad(state = segmentLoadInitialState, action) {
    switch (action.type) {
        case SEGMENT_LOAD_REQUEST:
            return Object.assign({}, state, {
                inProgress: true,
                loaded: false,
                error: null
            });

        case SEGMENT_LOAD_SUCCESS:
        case SEGMENT_SAVE_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                loaded: true,
                error: null
            });

        case SEGMENT_LOAD_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                error: action.error
            });

        case SEGMENT_NEW:
        case SEGMENT_RESET_VIEW:
            return segmentLoadInitialState;

        default:
            return state;
    }
}

export const saveSegmentInitialState = {
    inProgress: false,
    success: false,
    error: null
};

export function segmentSave(state = saveSegmentInitialState, action) {
    switch (action.type) {
        case SEGMENT_SAVE_REQUEST:
            return Object.assign({}, state, {
                inProgress: true,
                error: null,
                cancelToken: action.cancelToken
            });

        case SEGMENT_SAVE_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                success: true,
                error: null,
                cancelToken: null
            });

        case SEGMENT_SAVE_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                error: action.error,
                cancelToken: null
            });

        default:
            return state;
    }
}

export const segmentExportInitialState = {
    inProgress: false
};

export function segmentExport(state = segmentExportInitialState, action) {
    switch (action.type) {
        case SEGMENT_DOWNLOAD_CSV_REQUEST:
            return Object.assign({}, state, { inProgress: true });

        case SEGMENT_DOWNLOAD_CSV_SUCCESS:
        case SEGMENT_DOWNLOAD_CSV_FAILURE:
            return Object.assign({}, state, { inProgress: false });

        default:
            return state;
    }
}

export const segmentViewInitialState = {
    isFiltersEditOpen: true,
    isNameEdit: false
};

export function segmentView(state = segmentViewInitialState, action) {
    switch (action.type) {
        case SEGMENT_FILTERS_EDIT_VIEW_CHANGE:
            return Object.assign({}, state, { isFiltersEditOpen: action.value });

        case SEGMENT_NAME_EDIT_VIEW_CHANGE:
            return Object.assign({}, state, { isNameEdit: action.value });

        case SEGMENT_NEW:
        case SEGMENT_RESET_VIEW:
            return segmentViewInitialState;

        default:
            return state;
    }
}

export function lastSegmentFiltersUpdate(state = 1, action) {
    switch (action.type) {
        case SEGMENT_FILTERS_CHANGED:
            return Date.now();

        default:
            return state;
    }
}

export default combineReducers({
    currentSegment,
    segmentLoad,
    segmentSave,
    segmentMatches,
    segmentTable,
    segmentExport,
    segmentView,
    segmentFilters,
    segmentMenu,
    firmographic,
    lastSegmentFiltersUpdate,
    shareViaEmail
});
