import React from 'react';
import sprintf from 'sprintf-js';
import { Link, browserHistory } from 'react-router';
import { track } from 'utils/Utils';
import UserUtils from 'utils/UserUtils';
import { ACTION, WHERE } from 'utils/track-constants';
import {
    MY_SEGMENTS_DESCRIPTION,
    MY_SEGMENTS_TITLE
} from '../constants';
import {
    MY_HG_TOP_HEADER_VIEW_LIST
} from 'myhg/home/topHeader/constants';
import TopHeaderPaidUser from 'myhg/home/topHeader/components/TopHeaderPaidUser';
import TopHeaderFreeUser from 'myhg/home/topHeader/components/TopHeaderFreeUser';
import { resetSegmentView } from 'myhg/segment/actions';

export default class AllSegmentTopContent extends React.Component {

    onNewSegmentClick = () => {
        const { dispatch } = this.props;

        dispatch(resetSegmentView());

        track(ACTION.CREATE_SEGMENT, {
            label: WHERE.USER_ACCOUNT_HEADER
        });

        browserHistory.push('/dashboard/segment/new');
    }

    getViewListElement() {
        return (
            <Link className="view-list" to="/dashboard/my-products">
                <span className="icon icon-search" />
                { MY_HG_TOP_HEADER_VIEW_LIST }
            </Link>
        );
    }

    render() {
        const { allSegments, permissionCount, accountDetails } = this.props;
        const descriptionText = sprintf.sprintf(
            MY_SEGMENTS_DESCRIPTION,
            allSegments.data.length
        );

        const boxMessage = (
            <div>
                <h2> { MY_SEGMENTS_TITLE } </h2>
                <p>{ allSegments.isFetching ? '' : descriptionText }</p>
            </div>
        );

        let topHeader = null;

        if (UserUtils.isPaid(accountDetails)) {
            topHeader = (
                <TopHeaderPaidUser
                    boxMessage={ boxMessage }
                    onNewSegmentClick={ this.onNewSegmentClick }
                    permissionCount={ permissionCount }
                    allPermissions={ accountDetails.permissions.all }
                />
            );
        } else {
            topHeader = (
                <TopHeaderFreeUser
                    boxMessage={ boxMessage }
                    onNewSegmentClick={ this.onNewSegmentClick }
                    permissionCount={ permissionCount }
                />
            );
        }

        return topHeader;
    }
}
