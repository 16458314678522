import React from 'react';
import { Link } from 'react-router';

export default class BlankHeader extends React.Component {

    render() {
        return (
            <div className="blank-header">
                <Link className="header-logo" to="/">
                    <div className="logo"></div>
                </Link>
                <div className="header-limiter">
                    { '/' }
                </div>
                <div className="header-title">
                    { this.props.title }
                </div>
                <div className="header-action">
                    <span
                        onClick={ this.props.onClose }
                        className="close icon-close-x"
                    />
                </div>
            </div>
        );
    }
}
