import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getAccountsStatus,
    clearRecordType
} from './actions';

const refreshContactsStatusInterval = 3 * 1000;

// HOC that handles platform accounts data loading mechanisms
export default function (platformRecordTypes, sourceType) {
    return function wrapWithConnect(ComposedComponent) {
        class PlatformAccountsContainer extends Component {

            componentDidMount() {
                if (!this.allRecordsTypeAreLoaded()) {
                    this.props.dispatch(getAccountsStatus(
                        this.onContactsStatusCallback.bind(this)));
                }
            }

            componentWillUnmount() {
                const { dispatch } = this.props;

                clearTimeout(this.timeoutHandler);
                dispatch(clearRecordType());
            }

            onContactsStatusCallback(data) {
                if (data && !this.allRecordsTypeAreLoaded()) {
                    this.timeoutHandler = setTimeout(() => {
                        this.props.dispatch(getAccountsStatus(
                            this.onContactsStatusCallback.bind(this)));
                    }, refreshContactsStatusInterval);
                }
            }

            onRetry = () => {
                this.props.dispatch(getAccountsStatus());
            }

            allRecordsTypeAreLoaded() {
                const { platformRecordTypes: recordTypes, accountsStatus } = this.props;
                const { data } = accountsStatus;
                let allLoadedOrError = true;

                for (const recordType of recordTypes) {
                    if ((!data || !data[recordType]) ||
                        (!data[recordType].loaded && !data[recordType].hasError)) {
                        allLoadedOrError = false;
                        break;
                    }
                }
                return allLoadedOrError;
            }

            render() {
                return (
                    <ComposedComponent
                        { ...this.props }
                        onRetry={ this.onRetry }
                        allRecordsTypeAreLoaded={ this.allRecordsTypeAreLoaded() }
                    />
                );
            }
        }

        function mapStateToProps(state) {
            const { accounts: { status, selectedRecordTypes } } = state.myhg.imports;
            const { currentSegment } = state.myhg.segment;

            return {
                accountsStatus: status,
                selectedRecordTypes,
                platformRecordTypes,
                currentSegment,
                sourceType
            };
        }

        return connect(mapStateToProps)(PlatformAccountsContainer);
    };
}
