export const SEGMENT_MATCHES_DATA_SUCCESS = 'SEGMENT_MATCHES_DATA_SUCCESS';
export const SEGMENT_MATCHES_DATA_REQUEST = 'SEGMENT_MATCHES_DATA_REQUEST';
export const SEGMENT_MATCHES_DATA_FAILURE = 'SEGMENT_MATCHES_DATA_FAILURE';
export const SEGMENT_MATCHES_CLEAN_DATA = 'SEGMENT_MATCHES_CLEAN_DATA';
export const SEGMENT_TOTAL_AVAILABLE_RECORDS_REQUEST = 'SEGMENT_TOTAL_AVAILABLE_RECORDS_REQUEST';
export const SEGMENT_TOTAL_AVAILABLE_RECORDS_SUCCESS = 'SEGMENT_TOTAL_AVAILABLE_RECORDS_SUCCESS';
export const SEGMENT_TOTAL_AVAILABLE_RECORDS_FAILURE = 'SEGMENT_TOTAL_AVAILABLE_RECORDS_FAILURE';
export const SEGMENT_NEW_FILTERS = 'SEGMENT_NEW_FILTERS';
export const SEGMENT_OLD_FILTERS = 'SEGMENT_OLD_FILTERS';
export const SEGMENT_TABLE_START_PAGE = 1;
export const SEGMENT_TABLE_RECORDS_PER_PAGE = 25;
export const SEGMENT_RECORDS_TO_GET_IN_ONE_REQUEST = 50;
export const SEGMENT_LIMITATION_MESSAGE = 'Enter your email address to view %1$s records.';
export const SEGMENT_LIMITATION_BUTTON_TEXT = 'View More Data';
export const SEGMENT_CONTACT_SALES_TITLE = 'Want access to these accounts and more?';

/* eslint-disable max-len */
export const SEGMENT_CONTACT_SALES_MESSAGE = 'Schedule a demo with us to learn how HG Insights can help you identify prospects and close opportunities faster.';
/* eslint-enable max-len */

export const SEGMENT_CONTACT_SALES_BUTTON_TEXT = 'Contact Us';
export const SEGMENT_TABLE_NET_NEW = 'Net New ( %1$s )';
export const SEGMENT_TABLE_MATCHED = 'Matched ( %1$s )';
export const SEGMENT_FREE_TRIAL_ENDED = {
    TEXT: 'Although your trial has ended, you can view all records with',
    BOLDTEXT: 'HG Campaigns Plus.'
};
export const SEGMENT_DOWNLOAD_TOOLTIP = 'Downloads have been disabled for your account. \n' +
    'To enable them, please contact us at customersupport@hgdata.com.';
// Contact sales message
export const CONTACT_SALES_SUBJECT = 'Quote Request';

export const REGISTERED_USER_NUMBER_OF_RECORDS = 25;
export const NON_PAID_NUMBER_OF_RECORDS = 6;
export const SEGMENT_EXAMPLE_DATA_RECORD = {
    companyName: 'Example Company',
    url: 'example-url.com',
    product: 'Example Product',
    vendor: 'Example Vendor',
    signalSore: 'Current',
    dateLastVerified: '2018-08-20',
    categoryParent: 'Example Category Parent',
    category: 'Example Category',
    revenue: '$1M - $10M',
    employees: '10 - 100',
    topLevelIndustry: 'Example Industry',
    subLevelIndustry: 'Example Sub Industry',
    city: 'Some City',
    state: 'State',
    zip: '93101',
    country: 'United States',
    phone: '111-111-1111',
    attributes: 'Example Attributes',
    obscured: true
};

export const draggableTableHead = [
    {
        key: 'url',
        value: 'URL',
        columnCssClass: 'url'
    }, {
        key: 'product',
        value: 'PRODUCT',
        columnCssClass: 'product'
    }, {
        key: 'vendor',
        value: 'Vendor',
        columnCssClass: 'vendor'
    }, {
        key: 'signalScore',
        value: 'Signal Score',
        columnCssClass: 'signalScore'
    }, {
        key: 'dateLastVerified',
        value: 'Date Last Verified',
        columnCssClass: 'lastVerified'
    }, {
        key: 'categoryParent',
        value: 'Category Parent',
        columnCssClass: 'categoryParent'
    }, {
        key: 'category',
        value: 'Category',
        columnCssClass: 'category'
    }, {
        key: 'revenue',
        value: 'REVENUE(USD)',
        columnCssClass: 'revenue'
    }, {
        key: 'employees',
        value: '#EMPLOYEES',
        columnCssClass: 'employees'
    }, {
        key: 'topLevelIndustry',
        value: 'Industry',
        columnCssClass: 'industry'
    }, {
        key: 'subLevelIndustry',
        value: 'Sub Industry',
        columnCssClass: 'subindustry'
    }, {
        key: 'address',
        value: 'Address',
        columnCssClass: 'address'
    }, {
        key: 'city',
        value: 'City',
        columnCssClass: 'city'
    }, {
        key: 'state',
        value: 'State',
        columnCssClass: 'state'
    }, {
        key: 'zip',
        value: 'ZIP',
        columnCssClass: 'zip'
    }, {
        key: 'country',
        value: 'Country',
        columnCssClass: 'country'
    }, {
        key: 'phone',
        value: 'Phone',
        columnCssClass: 'phone'
    }, {
        key: 'attributes',
        value: 'Attributes',
        columnCssClass: 'attributes'
    }
];

export const fixedTableHead = [
    {
        key: 'companyName',
        value: 'COMPANY',
        columnCssClass: 'company'
    }
];
