import React from 'react';
import { connect } from 'react-redux';
import {
    SELECT_LOCATIONS_PLACEHOLDER,
    SELECT_LOCATIONS_ALL_PLACEHOLDER
} from './constants';
import {
    addLocation,
    removeLocation
} from './actions';
import {
    locationsForDropdown,
    getSearchableLocations
} from './reducer';
import Typeahead from 'shared/ui/typeahead/Typeahead';
import TypeaheadSelectedItem from 'shared/ui/typeahead/TypeaheadSelectedItem';
import {
    FILTERS_LOCATION_TYPE_COUNTRY,
    FILTERS_LOCATION_TYPE_REGION
} from '../constants';
import { removeUnknowValuesId } from '../unknownValuesFilter/actions';
import { UNKNOWN_VALUES_LOCATIONS_FILTER_NAME } from '../unknownValuesFilter/constants';

function mapStateToProps(state) {
    const { locationsList } = state.taxonomy.firmographic.data;
    const { segment } = state.myhg;

    return {
        locations: segment.segmentFilters.locations,
        firmographic: state.taxonomy.firmographic,
        dropdownLocations: locationsForDropdown(locationsList)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class LocationsFilterContainer extends React.Component {

    onSelectItem(item) {
        const { dispatch, locations } = this.props;

        // unselect unknown firmo value for locations
        if (!locations[FILTERS_LOCATION_TYPE_COUNTRY].length &&
            !locations[FILTERS_LOCATION_TYPE_REGION].length) {
            dispatch(removeUnknowValuesId(UNKNOWN_VALUES_LOCATIONS_FILTER_NAME));
        }

        dispatch(addLocation(item));
    }

    onRemove(item) {
        const { dispatch } = this.props;
        dispatch(removeLocation(item));
    }

    getSelectedLocations() {
        const { locations, dropdownLocations } = this.props;

        const countries = locations[FILTERS_LOCATION_TYPE_COUNTRY]
            .map((countryId) => {
                const countryItem = dropdownLocations[FILTERS_LOCATION_TYPE_COUNTRY]
                    .find((country) => {
                        return country.id === countryId;
                    });
                return (
                    <TypeaheadSelectedItem
                        key={ countryId }
                        item={ countryItem }
                        divClassName={ 'technology-icon-text' }
                        onRemove={ this.onRemove.bind(this, countryItem) }
                    />
                );
            });
        const regions = locations[FILTERS_LOCATION_TYPE_REGION]
            .map((regionId) => {
                const regionItem = dropdownLocations[FILTERS_LOCATION_TYPE_REGION]
                    .find((region) => {
                        return region.id === regionId;
                    });
                return (
                    <TypeaheadSelectedItem
                        key={ regionId }
                        item={ regionItem }
                        divClassName={ 'technology-icon-text' }
                        onRemove={ this.onRemove.bind(this, regionItem) }
                    />
                );
            });
        return [...regions, ...countries];
    }

    render() {
        const {
            locations,
            locations: { countries, regions },
            dropdownLocations
        } = this.props;

        const typeaheadSearchLocations = getSearchableLocations(
            dropdownLocations,
            locations
        );
        const inputPlaceholderText = countries.length || regions.length ?
            SELECT_LOCATIONS_PLACEHOLDER : SELECT_LOCATIONS_ALL_PLACEHOLDER;

        return (
            <div>
                <Typeahead
                    inputPlaceholderText={ inputPlaceholderText }
                    searchData={ typeaheadSearchLocations }
                    onSelectItem={ this.onSelectItem.bind(this) }
                />
                <div className="typeahead-selecteditems-container">
                    { this.getSelectedLocations() }
                </div>
            </div>
        );
    }
}
