export const FIRMOGRAPHIC_PROFILE_COMPONENT_TITLE =
    'Firmographic Profile of Companies in this Segment';

export const FIRMOGRAPHIC_LOCATIONS = 'locations';
export const FIRMOGRAPHIC_EMPLOYEES = 'employees';
export const FIRMOGRAPHIC_REVENUE = 'revenue';
export const FIRMOGRAPHIC_INDUSTRY = 'industry';
export const TAB_MENU = [{
    displayName: 'Locations',
    name: FIRMOGRAPHIC_LOCATIONS
}, {
    displayName: '# Employees',
    name: FIRMOGRAPHIC_EMPLOYEES
}, {
    displayName: 'Revenue (USD)',
    name: FIRMOGRAPHIC_REVENUE
}, {
    displayName: 'Industry',
    name: FIRMOGRAPHIC_INDUSTRY
}];

export const TIP_DATA_TYPE_NETNEW_TEXT = 'net new';
export const TIP_DATA_TYPE_MATCHED_TEXT = 'matched';
export const TIP_DATA_TYPE_ALL_TEXT = '';
