import {
    PRODUCT_TAXONOMY_REQUEST,
    PRODUCT_TAXONOMY_SUCCESS,
    PRODUCT_TAXONOMY_FAILURE
} from './constants';

export const productTaxonomyInitialState = {
    isFetching: false,
    error: null,
    data: []
};

export default function products(state = productTaxonomyInitialState, action) {
    switch (action.type) {
        case PRODUCT_TAXONOMY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });

        case PRODUCT_TAXONOMY_SUCCESS:
            return Object.assign({}, state, {
                data: action.data,
                isFetching: false,
                error: null
            });

        case PRODUCT_TAXONOMY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });

        default:
            return state;
    }
}
