import React from 'react';
import onClickOutside from 'react-onclickoutside';

class DropdownSelection extends React.Component {

    onSelectionClick(index) {
        const { onSelectionClick } = this.props;
        onSelectionClick(index);
    }

    getAvailableSelections() {
        const { selections } = this.props;
        return selections.map((selection, index) => {
            return (
                <li
                    key={ index }
                    className="dropdown-selection"
                    onClick={ this.onSelectionClick.bind(this, index) }
                >
                    { selection }
                </li>
            );
        });
    }

    handleClickOutside = () => {
        this.props.handleClickOutside();
    };

    render() {
        return (
            <ul className="dropdown-selector-container" >
                { this.getAvailableSelections() }
            </ul>
        );
    }
}

export default onClickOutside(DropdownSelection);
