// import { AUTHENTICATION_MODAL_TYPE } from 'discovery/authenticationModal/constants';
// import { VERIFICATION_MODAL_TYPE }
//     from 'discovery/authenticationModal/confirmRegistration/constants';
// import { CONFIRM_RESET_PASSWORD_MODAL_TYPE }
//     from 'discovery/authenticationModal/resetPassword/constants';
// import AuthenticationModalContainer
//     from 'discovery/authenticationModal/AuthenticationModalContainer';
// import VerificationModalContainer
//     from 'discovery/authenticationModal/confirmRegistration/VerificationModalContainer';
// import ResetPasswordModalContainer
//     from 'discovery/authenticationModal/resetPassword/ResetPasswordModalContainer';

// export const LightboxComponents = {
//     [AUTHENTICATION_MODAL_TYPE]: AuthenticationModalContainer,
//     [VERIFICATION_MODAL_TYPE]: VerificationModalContainer,
//     [CONFIRM_RESET_PASSWORD_MODAL_TYPE]: ResetPasswordModalContainer
// };
export const LightboxComponents = {};
