import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import ValidationField from 'shared/ui/formFields/ValidationField';
import Validation from 'utils/Validation';
import Loader from 'shared/ui/loader/Loader';
import FormButtonWrapper from 'shared/ui/formFields/FormButtonWrapper';
import {
    LOGIN_NO_ACCOUNT_YET,
    LOGIN_SIGN_UP_LINK_TEXT,
    LOGIN_TITLE
} from './constants';
import {URL} from "../../../shared/constants";
import {browserHistory} from "react-router";

export const fields = [
    'username',
    'password'];

const validate = values => {
    const errors = {};

    if (!values.username) {
        errors.username = 'Required';
    } else if (!Validation.validateEmail(values.username)) {
        errors.username = 'Invalid email address';
    }

    if (!values.password) {
        errors.password = 'Required';
    }

    return errors;
};

class LogInForm extends React.Component {

    getError(error) {
        if (!error) {
            return null;
        }

        return (
            <p className="text-content error">
                { error.message }
            </p>
        );
    }

    getTitle() {
        return (
            <div className="title">
                { LOGIN_TITLE }
            </div>
        );
    }

    signUpHandler() {
        this.props.openSignUpModal();
    }

    onClick() {
        browserHistory.push("/")
    }

    render() {
        const {
            fields: { username, password },
            handleSubmit,
            submitting,
            requestError } = this.props;

        return (
            <form className="modal-form"
                id="login" onSubmit={ handleSubmit(this.props.onLoginSubmit) }
            >
                { this.getTitle() }
                <ValidationField
                    type="text"
                    label="Work Email Address"
                    placeholder="julie@example.com"
                    fieldType="input"
                    { ...username }
                    data-qa="email-input"
                />
                <ValidationField
                    type="password"
                    label="Password"
                    placeholder="6+ characters"
                    fieldType="input"
                    feedback="Forgot?"
                    feedbackHandler={ () => { this.props.onLoginFormChange('reset-password'); } }
                    { ...password }
                    data-qa="password-input"
                />
                { this.getError(requestError) }
                <FormButtonWrapper>
                    <button
                        id="login-btn"
                        type="submit"
                        className="btn btn-primary"
                        disabled={ submitting }
                        data-qa="login-button"
                    >
                        Login { submitting ?
                        <Loader className="small-loader white" /> : '' }
                    </button>
                </FormButtonWrapper>
                <p className="text-content large">
                    <span>{ LOGIN_NO_ACCOUNT_YET }</span>
                    <a href={ URL.DISCOVERY_DEMO_REQUEST }
                       color="#c90053"
                       id="discovery-demo-request"
                       target="_blank"
                       onClick={ this.onClick }
                    >
                        { LOGIN_SIGN_UP_LINK_TEXT }
                    </a>
                </p>
            </form>
        );
    }
}

LogInForm.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'loginForm',
    fields,
    validate
})(LogInForm);
