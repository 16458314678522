import React from 'react';
import { connect } from 'react-redux';
import CollectionProductsSelection from './CollectionProductsSelection';
import { loadCollectionById } from 'discovery/collection/actions';
import Loader from 'shared/ui/loader/Loader';
import keyboardSelectionHOC from './keyboardSelectionHOC';
import SearchListContainer from './SearchListContainer';
import { getSortedTechnologiesByPermissions } from '../reducer';

function mapStateToProps(state) {
    return {
        collectionState: state.collection
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@keyboardSelectionHOC
@connect(mapStateToProps, mapDispatchToProps)
export default class CollectionProductsSelectionContainer extends React.Component {

    componentDidMount() {
        const { dispatch, collection } = this.props;

        dispatch(loadCollectionById(collection.slug));
    }

    getCollectionSearchTitle() {
        const { collectionState: { data, inProgress }, allowedSlugs } = this.props;
        const listNameTitle = `${this.props.collection.name} products`;
        let content = (
            <a
                className="primary-link"
                onClick={ this.allProductsCollectionSelection }
            >
                Select All
            </a>
        );

        // determine if there are any available items to select
        if (inProgress) {
            content = null;
        } else if (!!allowedSlugs) {
            const allowedSlugsSet = new Set(allowedSlugs);
            const intersetion = [...new Set(data ? data.productSlugs : [])]
                .filter(x => {
                    return allowedSlugsSet.has(x);
                });

            if (!intersetion.length) {
                content = (
                    <a className="primary-link">
                        There are no allowed items to select
                    </a>
                );
            }
        }

        return (
            <span>
                { listNameTitle }
                { content }
            </span>
        );
    }

    allProductsCollectionSelection = () => {
        const { selectCollection, collection } = this.props;
        if (selectCollection) {
            selectCollection(collection);
        }
    }

    render() {
        const { collectionState, collectionState: { data }, allowedSlugs } = this.props;
        let sortedTechs = data ? data.productSlugs : [];
        sortedTechs = sortedTechs.sort((a, b) => {
            return a.localeCompare(b);
        });

        if (allowedSlugs) {
            sortedTechs = getSortedTechnologiesByPermissions(
                data ? data.productSlugs : [],
                allowedSlugs
            );
        }

        let content = null;

        if (collectionState.inProgress) {
            content = <Loader className="search-list-loader" />;
        } else {
            content = (
                <CollectionProductsSelection
                    { ...this.props }
                    searchableItems={ sortedTechs }
                />
            );
        }

        return (
            <SearchListContainer
                containerId="collection-products-search-container"
                listName={ this.getCollectionSearchTitle() }
            >
                { content }
            </SearchListContainer>
        );
    }
}
