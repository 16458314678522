import {
    LINKEDIN_ACCESS_TOKEN_REQUEST,
    LINKEDIN_ACCESS_TOKEN_SUCCESS,
    LINKEDIN_ACCESS_TOKEN_FAILURE,
    LINKEDIN_ACCOUNTS_REQUEST,
    LINKEDIN_ACCOUNTS_FAILURE,
    LINKEDIN_ACCOUNTS_SUCCESS, LINKEDIN_MATCHED_URLS_REQUEST
} from './constants';

export const linkedInConnectInitialState = {
    error: null,
    accessToken: null,
    accounts: null,
    submitting: false
};

export default function linkedInConnect(state = linkedInConnectInitialState, action) {
    switch (action.type) {
        case LINKEDIN_ACCESS_TOKEN_REQUEST:
        case LINKEDIN_ACCOUNTS_REQUEST:
        case LINKEDIN_MATCHED_URLS_REQUEST:
            return Object.assign({}, state, {
                error: null,
                accounts: null,
                submitting: true,
            });

        case LINKEDIN_ACCESS_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                accessToken: action.data.accessToken,
                submitting: false
            });

        case LINKEDIN_ACCOUNTS_SUCCESS:
            return Object.assign({}, state, {
                accounts: action.data.accounts,
                submitting: false
            });

        case LINKEDIN_ACCESS_TOKEN_FAILURE:
        case LINKEDIN_ACCOUNTS_FAILURE:
            return Object.assign({}, state, {
                error: action.error,
                submitting: false
            });

        default:
            return state;
    }
}
