import React from 'react';
import AllSegmentTopContent from './AllSegmentTopContent';
import MySegmentListItemContainer from
        '../../components/mysegmentslistitem/MySegmentListItemContainer';
import TopPanelWrapper from 'shared/ui/panel/TopPanelWrapper';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import PaginationData from './PaginationData';
import EmptySegmentList from './EmptySegmentList';
import Loader from 'shared/ui/loader/Loader';
import {
    START_INDEX,
    PAGINATION_STEP
} from '../constants';

export default class AllSegments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            from: START_INDEX,
            to: PAGINATION_STEP
        };
    }

    onArrowClick = (from, to) => {
        this.setState({
            from,
            to
        });
    }

    getSegments() {
        const { data: segments } = this.props.allSegments;
        return segments.filter((item, index) => {
            if ((index + 1) >= this.state.from && (index + 1) <= this.state.to) {
                return true;
            }
            return false;
        })
        .map((item, index) => {
            return <MySegmentListItemContainer segment={ item } key={ index } />;
        });
    }

    getPaginationData() {
        const { allSegments } = this.props;
        const paginationDetails = {
            from: this.state.from,
            to: allSegments.data.length < PAGINATION_STEP ? allSegments.data.length : this.state.to,
            until: allSegments.data.length,
            step: PAGINATION_STEP
        };

        return (
            <div className="mysegments-panel-pagination">
                <PaginationData
                    onArrowClick = { this.onArrowClick }
                    paginationDetails = { paginationDetails }
                />
            </div>);
    }

    getSegmentList() {
        return (
            <div>
                { this.getPaginationData() }
                { this.getSegments() }
                { this.getPaginationData() }
            </div>
        );
    }

    getMainContent() {
        const { allSegments } = this.props;
        const isEmptyList = !allSegments.isFetching && !allSegments.data.length;
        let content = null;

        if (allSegments.isFetching) {
            content = <Loader />;
        } else {
            content = isEmptyList ?
                <EmptySegmentList { ...this.props } /> : this.getSegmentList();
        }

        return content;
    }

    render() {
        return (
            <div className="content-wrapper myhg-home">
                <TopPanelWrapper className="myhg-home-top">
                    <AllSegmentTopContent { ...this.props } />
                </TopPanelWrapper>
                <PanelWrapper className="transparent">
                    { this.getMainContent() }
                </PanelWrapper>
            </div>
        );
    }
}
