import thunk from 'redux-thunk';
import multi from 'redux-multi';
import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose
} from 'redux';
import { LOG_OUT } from 'discovery/authenticationModal/constants';
import * as reducers from '../reducers'; // eslint-disable-line import/no-namespace
import ApiWrapper from '../services/ApiWrapper';

function getDevtools() {
    // turn on Redux DevTools Extension
    // only for development ENV
    return typeof window === 'object' &&
        typeof window.devToolsExtension !== 'undefined' &&
        ['localhost', 'discovery.api'].indexOf(window.location.hostname) >= 0 ?
        window.devToolsExtension() : (f) => { return f; };
}

export default function configureStore(initialState) {
    const appReducer = combineReducers(reducers);
    const rootReducer = (state, action) => {
        let dupState = state;
        if (action.type === LOG_OUT) {
            // The only part of the state we want to
            // keep after the logout was performed
            dupState = Object.assign({}, {
                root: {
                    focusExtensionId: state.root.focusExtensionId,
                    HG4SFExtensionId: state.root.HG4SFExtensionId,
                    locationPath: state.root.locationPath,
                    prevLocationPath: state.root.prevLocationPath,
                    variables: state.root.variables
                },
                taxonomy: state.taxonomy,
                firstTimeActions: state.firstTimeActions,
                product: {
                    stickyState: state.product.stickyState
                }
            });
        }

        return appReducer(dupState, action);
    };
    const store = createStore(rootReducer, initialState, compose(
        // apply Redux-Thunk middleware
        applyMiddleware(multi, thunk),
        getDevtools())
    );

    ApiWrapper.instance.setupStore(store);
    return store;
}
