import RevenueFilterContainer from '../revenueFilter/RevenueFilterContainer';
import EmployeesFilterContainer from '../employeesFilter/EmployeesFilterContainer';
import IndustriesFilterContainer from '../industriesFilter/IndustriesFilterContainer';
import LocationsFilterContainer from '../locationsFilter/LocationsFilterContainer';
import {
    UNKNOWN_VALUES_REVENUE_FILTER_NAME,
    UNKNOWN_VALUES_EMPLOYEES_FILTER_NAME,
    UNKNOWN_VALUES_INDUSTRIES_FILTER_NAME,
    UNKNOWN_VALUES_LOCATIONS_FILTER_NAME
} from '../unknownValuesFilter/constants';

export const FIRMOGRAPHIC_FILTERS = [{
    text: 'Revenue',
    unknownValueFilter: UNKNOWN_VALUES_REVENUE_FILTER_NAME,
    component: RevenueFilterContainer,
    type: 'slider'
}, {
    text: 'Employees',
    unknownValueFilter: UNKNOWN_VALUES_EMPLOYEES_FILTER_NAME,
    component: EmployeesFilterContainer,
    type: 'slider'
}, {
    text: 'Locations',
    unknownValueFilter: UNKNOWN_VALUES_LOCATIONS_FILTER_NAME,
    component: LocationsFilterContainer,
    type: 'dropdown'
}, {
    text: 'Industries',
    unknownValueFilter: UNKNOWN_VALUES_INDUSTRIES_FILTER_NAME,
    component: IndustriesFilterContainer,
    type: 'dropdown'
}];

export const FILTER_COMPANIES_TITLE = 'Filter Companies By';
export const LIMIT_TECH_EXCEEDED = 'You are limited to %1$s technologies';
