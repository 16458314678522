import React from 'react';
import { connect } from 'react-redux';
import { default as moment } from 'moment';
import TrialExpiredNotification from './components/TrialExpiredNotification';
import TrialNotStartedNotification from './components/TrialNotStartedNotification';
import TrialStartedNotification from './components/TrialStartedNotification';
import UserUtils from 'utils/UserUtils';
import {
    startTrialForLegacyUser
} from './actions';
import {
    TRIAL_STATUS
} from './constants';
import {
    SEGMENT_STATUS
} from '../../segment/constants';

function mapStateToProps(state) {
    const segment = state.myhg.segment;

    return {
        accountDetails: state.user.accountDetails.data,
        authData: state.authentication.authData,
        currentSegment: segment.currentSegment,
        segments: state.myhg.viewallsegments.allSegments.data
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class TrialNotificationContainer extends React.Component {
    onStartTrial = () => {
        const { dispatch } = this.props;
        dispatch(startTrialForLegacyUser());
    }

    getTrialStatus = () => {
        const { accountDetails } = this.props;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        let status;

        if (!campaignTrialInfo.isActive) {
            status = TRIAL_STATUS.TRIAL_NOT_STARTED;
        } else if (campaignTrialInfo.remainingDays === 0) {
            status = TRIAL_STATUS.TRIAL_EXPIRED;
        } else {
            status = TRIAL_STATUS.TRIAL_STARTED;
        }

        return status;
    }

    allSegmentsMatched() {
        const { segments } = this.props;
        let allMatched = true;

        if (segments.length === 0) {
            allMatched = false;
        } else {
            const nonMatched = segments.find((segment) => {
                return segment.data.status.status === SEGMENT_STATUS.NON_MATCHED;
            });
            allMatched = (nonMatched === undefined);
        }
        return allMatched;
    }

    isLegacyUser() {
        const { accountDetails } = this.props;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        let legacyUser = false;

        if (!campaignTrialInfo.isActive && this.allSegmentsMatched()) {
            legacyUser = true;
        }

        return legacyUser;
    }

    render() {
        const trialStatus = this.getTrialStatus();
        const isLegacyUser = this.isLegacyUser();

        let content = null;
        const { accountDetails } = this.props;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        const displayDate = moment(campaignTrialInfo.trialEndDate, 'YYYY/MM/DD').format('MMM Do');

        if (trialStatus === TRIAL_STATUS.TRIAL_NOT_STARTED && isLegacyUser) {
            content = (<TrialNotStartedNotification
                onStartTrial={ this.onStartTrial }
                name={ accountDetails.firstName }
            />);
        } else if (trialStatus === TRIAL_STATUS.TRIAL_STARTED) {
            content = (<TrialStartedNotification
                remainingDays={ campaignTrialInfo.remainingDays }
                endDate={ displayDate }
                { ...this.props }
            />);
        } else if (trialStatus === TRIAL_STATUS.TRIAL_EXPIRED) {
            content = (<TrialExpiredNotification
                { ...this.props }
            />);
        } else {
            content = null;
        }

        return content;
    }
}
