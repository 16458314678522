import React from 'react';
import { connect } from 'react-redux';
import SegmentCompaniesCounter from './components/SegmentCompaniesCounter';
import MatchesLoader from './components/MatchesLoader';
import MatchesError from './components/MatchesError';

const mapStateToProps = (state) => {
    const { segment } = state.myhg;
    const { source } = segment.currentSegment.data.status;
    const segmentMatches = source
        ? segment.segmentMatches.netNewMatches
        : segment.segmentMatches.summaryData;

    return {
        currentSegment: segment.currentSegment,
        segmentMenu: segment.segmentMenu,
        segmentMatches
    };
};

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
};

@connect(mapStateToProps, mapDispatchToProps)

/**
 * Segment Companies Counter Container
 */
export default class SegmentCompaniesCounterContainer extends React.Component {

    componentWillUnmount() {
        const { segmentMatches } = this.props;
        if (segmentMatches.cancelToken) {
            segmentMatches.cancelToken.cancel();
        }
    }

    render() {
        const { segmentMatches, segmentMenu, currentSegment } = this.props;
        let content = null;

        if (segmentMatches.isFetching) {
            content = <MatchesLoader />;
        } else if (segmentMatches.error) {
            content = <MatchesError error={ segmentMatches.error } />;
        } else if (segmentMatches.data) {
            content = (
                <SegmentCompaniesCounter
                    disableMatchesButton={ !currentSegment.id }
                    totalCompanies={ currentSegment.data.status.companies }
                    segmentMenu={ segmentMenu }
                    dispatch={ this.props.dispatch }
                />
            );
        }

        return content;
    }
}
