import React from 'react';
import classNames from 'classnames';

export default class TopPanelWrapper extends React.Component {

    render() {
        const className = classNames('top-panel', this.props.className);

        return (
            <div className={ className }>
                <div className="container">
                    { this.props.children }
                </div>
            </div>
        );
    }
}
