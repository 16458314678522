import React from 'react';
import { connect } from 'react-redux';
import withTaxonomy from 'shared/taxonomy/enhancer';
import TechnologyIconText from 'shared/ui/technologyIconText/TechnologyIconText';
import FilterWrapper from 'shared/filterwrapper/FilterWrapper';
import FirmographicUtils from 'utils/FirmographicUtils';
import { SIGNAL_SCORE_OPTIONS } from 'shared/constants';
import {
    USAGE_TIME_FRAME_SIGNAL_SCORE
} from '../constants';
import {
    FILTERS_LAST_CHECK_OPTIONS
} from '../lastCheckFilter/constants';

function mapStateToProps(state) {
    return {
        segmentFilters: state.myhg.segment.segmentFilters,
        revenueSteps: FirmographicUtils.getIntervalsIncludingBoundaries(
            state.taxonomy.firmographic.data.revenueSteps),
        employeesSteps: FirmographicUtils.getIntervalsIncludingBoundaries(
            state.taxonomy.firmographic.data.employeesSteps),
        locationsList: state.taxonomy.firmographic.data.locationsList
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

class SegmentSummaryFiltersContainer extends React.Component {

    getFiltersForTechnologies() {
        const { technologies } = this.props.segmentFilters;
        const { collections } = this.props.segmentFilters;
        const { findTaxonomyProduct } = this.props;

        const technologiesItems = technologies.map((item) => {
            return (
                <TechnologyIconText
                    technology={ findTaxonomyProduct(item) }
                    key={ `${item}-product` }
                    displayText
                />
            );
        });

        const collectionItems = collections.map((collection) => {
            const collectionDisplayItem = {
                slug: collection.slug,
                name: `${collection.name} (${collection.techSlugs.length})`,
                vendorUrl: collection.vendorUrl,
                collectionType: collection.collectionType
            };

            return (
                <TechnologyIconText
                    technology={ collectionDisplayItem }
                    key={ `${collectionDisplayItem.slug}-collection` }
                    displayText
                />
            );
        });

        return [...collectionItems, ...technologiesItems];
    }

    getFiltersForIndustries() {
        const { selectedIndustries } = this.props.segmentFilters.industries;
        if (!selectedIndustries.length) {
            return this.getFilterWrapper('All Industries');
        }

        return selectedIndustries.map((item) => {
            return this.getFilterWrapper(item);
        });
    }

    getFiltersForEmployees() {
        const { employees } = this.props.segmentFilters;
        if (!employees) {
            return [];
        }

        const { employeesSteps } = this.props;
        const fromFormatted = FirmographicUtils.getFormattedNumber(
            employees.from,
            false
        );
        const toFormatted = FirmographicUtils.getFormattedNumber(
            employees.to,
            false,
            employees.to === employeesSteps[employeesSteps.length - 1]
        );

        const employeesText = `${fromFormatted} - ${toFormatted} Employees`;
        return this.getFilterWrapper(employeesText);
    }

    getFiltersForRevenue() {
        const { revenue } = this.props.segmentFilters;
        if (!revenue) {
            return [];
        }

        const { revenueSteps } = this.props;
        const fromFormatted = FirmographicUtils.getFormattedNumber(
            revenue.from,
            true
        );
        const toFormatted = FirmographicUtils.getFormattedNumber(
            revenue.to,
            true,
            revenue.to === revenueSteps[revenueSteps.length - 1]
        );

        const revenueText = `${fromFormatted} - ${toFormatted} Revenue`;
        return this.getFilterWrapper(revenueText);
    }

    getLocationsFilters() {
        const { countries } = this.props.segmentFilters.locations;
        const { regions } = this.props.segmentFilters.locations;
        let locations = [];

        regions.forEach((region) => {
            locations = locations.concat(this.getFilterWrapper(region));
        });

        countries.forEach((country) => {
            const found = this.props.locationsList.find((loc) => {
                return loc.iso === country;
            });
            if (found) {
                locations = locations.concat(this.getFilterWrapper(found.country));
            }
        });

        if (!locations.length) {
            locations = this.getFilterWrapper('All Locations');
        }

        return locations;
    }

    getFilterWrapper(text) {
        return (
            <FilterWrapper
                key={ text }
                className="technology-icon-text"
                innerClassName="favicon-wrapper"
            >
                { text }
            </FilterWrapper>
        );
    }

    getSignalScoresFilters() {
        const { signalScores } = this.props.segmentFilters;
        let signalScoresFilters = signalScores.map((id) => {
            const option = SIGNAL_SCORE_OPTIONS.find((item) => {
                return id === item.id;
            });
            return this.getFilterWrapper(option.name);
        });

        if (signalScoresFilters && signalScoresFilters.length === SIGNAL_SCORE_OPTIONS.length) {
            signalScoresFilters = this.getFilterWrapper('All Signal Scores');
        }

        return signalScoresFilters;
    }

    getFiltersForLastCheck() {
        const { lastCheck } = this.props.segmentFilters;
        const activeOption = FILTERS_LAST_CHECK_OPTIONS.find((item) => {
            return item.value === lastCheck;
        });

        return activeOption ? this.getFilterWrapper(activeOption.text) : null;
    }

    getUsageTimeFrame() {
        const { segmentFiltersView: { activeTimeFrame } } = this.props.segmentFilters;
        let content = null;

        if (activeTimeFrame === USAGE_TIME_FRAME_SIGNAL_SCORE) {
            content = this.getSignalScoresFilters();
        } else {
            content = this.getFiltersForLastCheck();
        }

        return content;
    }

    render() {
        return (
            <div>
                { this.getFiltersForTechnologies() }
                { this.getFiltersForRevenue() }
                { this.getFiltersForEmployees() }
                { this.getLocationsFilters() }
                { this.getFiltersForIndustries() }
                { this.getUsageTimeFrame() }
            </div>
        );
    }
}

export default withTaxonomy(SegmentSummaryFiltersContainer);
