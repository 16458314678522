import React from 'react';
import RadioButton from './RadioButton';

export default class RadioButtonList extends React.Component {

    onSelectChange = (item) => {
        this.props.onSelectChange(item);
    }

    render() {
        const { itemComponents } = this.props;
        const radioButtonList = this.props.items.map((item, index) => {
            return (
                <RadioButton
                    className={ this.props.className }
                    key={ index }
                    item={ item }
                    checked={ !!item.checked }
                    onSelectChange={ this.onSelectChange }
                >
                    { itemComponents ? itemComponents[index] : null }
                </RadioButton>
            );
        });

        return (
            <div>
                { radioButtonList }
            </div>
        );
    }
}
