import {
    SIGNAL_SCORE_ADD,
    SIGNAL_SCORE_REMOVE,
    SIGNAL_SCORE_RESET,
    SIGNAL_SCORE_SET
} from './constants';
import {
    SIGNAL_SCORE_OPTIONS
} from 'shared/constants';

export const signalScoreInitialState = SIGNAL_SCORE_OPTIONS.map((score) => {
    return score.isDefaultChecked === false ? false : score.id;
}).filter((item) => { return item; });

export default function signalScoreIds(state = signalScoreInitialState, action) {
    switch (action.type) {
        case SIGNAL_SCORE_ADD:
            return [...state, action.scoreId];

        case SIGNAL_SCORE_REMOVE: {
            const index = state.indexOf(action.scoreId);
            return [...state.slice(0, index), ...state.slice(index + 1)];
        }

        case SIGNAL_SCORE_SET:
            return action.scoreIds || signalScoreInitialState;

        case SIGNAL_SCORE_RESET:
            return signalScoreInitialState;

        default:
            return state;
    }
}
