import React from 'react';
import sprintf from 'sprintf-js';
import { Link } from 'react-router';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import Loader from 'shared/ui/loader/Loader';
import TechnologyIconText from 'shared/ui/technologyIconText/TechnologyIconText';
import withTaxonomy from 'shared/taxonomy/enhancer';
import {
    SIMILAR_PRODUCTS_TITLE
} from '../constants';

class SimilarProducts extends React.Component {
    getTitle() {
        const { product } = this.props.productDetails.data;
        let result = null;

        if (!!product) {
            result = sprintf.sprintf(
                SIMILAR_PRODUCTS_TITLE,
                product
            );
        }
        return result;
    }

    getTechList = () => {
        const {
            findTaxonomyProduct,
            similarProducts
        } = this.props;

        return similarProducts.data.map((tech, index) => {
            return (
                <Link
                    key={ index }
                    to={ `/product/${tech.slug}` }
                >
                    <TechnologyIconText
                        technology={ findTaxonomyProduct(tech.slug) }
                        displayText
                    />
                </Link>
            );
        });
    }

    render() {
        const { isFetched, error } = this.props.similarProducts;

        let content = null;
        if (error) {
            content = error.message;
        } else if (!isFetched) {
            content = <Loader />;
        } else {
            content = this.getTechList();
        }

        return (
            <PanelWrapper
                className="similar-products"
                title={ this.getTitle() }
            >
                { content }
            </PanelWrapper>
        );
    }
}

export default withTaxonomy(SimilarProducts);
