export const LOAD_PRODUCT_DETAILS_REQUEST = 'LOAD_PRODUCT_DETAILS_REQUEST';
export const LOAD_PRODUCT_DETAILS_SUCCESS = 'LOAD_PRODUCT_DETAILS_SUCCESS';
export const LOAD_PRODUCT_DETAILS_FAILURE = 'LOAD_PRODUCT_DETAILS_FAILURE';

export const INCREASE_INSIGHTS_INDEX = 'INCREASE_INSIGHTS_INDEX';
export const DECREASE_INSIGHTS_INDEX = 'DECREASE_INSIGHTS_INDEX';
export const SET_NEW_PRODUCT_ID = 'SET_NEW_PRODUCT_ID';

export const TOGGLE_DESCRIPTION = 'TOGGLE_DESCRIPTION';
export const TEXT_LENGTH = '360';

export const UPDATE_FAVORITE_PRODUCT_REQUEST = 'UPDATE_FAVORITE_PRODUCT_REQUEST';
export const UPDATE_FAVORITE_PRODUCT_SUCCESS = 'UPDATE_FAVORITE_PRODUCT_SUCCESS';
export const UPDATE_FAVORITE_PRODUCT_FAILURE = 'UPDATE_FAVORITE_PRODUCT_FAILURE';

export const TOGGLE_FAVORITE_PRODUCT = 'TOGGLE_FAVORITE_PRODUCT';
export const NO_PRODUCT_PERMISSIONS = "You don't have permissions for this product";
export const PRODUCT_NOTIFICATION_TITLE = 'Congratulations! You\'ve added %1$s.';
export const PRODUCT_NOTIFICATION_DESCRIPTION =
    'Show your interest in more products, or click "View Collections" to see your ' +
    'recommended Collections. The more you add, the better our ' +
    'recommendations will be.';

export const TELEMETRY_ACTION_NAME = 'PRODUCT_INTERESTED_IN';
