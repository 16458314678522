import React from 'react';
import { connect } from 'react-redux';
import Dropdown from 'shared/ui/dropdown/Dropdown';
import {
    FILTERS_LAST_CHECK_OPTIONS,
    FILTERS_LAST_CHECK_TITLE
} from './constants';
import { setLastCheckByValue } from './actions';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        lastCheck: segment.segmentFilters.lastCheck
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class LastCheckFilterContainer extends React.Component {

    onSelectionChange = (selectedIndex) => {
        const { dispatch } = this.props;
        dispatch(setLastCheckByValue(FILTERS_LAST_CHECK_OPTIONS[selectedIndex].value));
    }

    getDropdownSelections() {
        return FILTERS_LAST_CHECK_OPTIONS.map((item) => {
            return item.text;
        });
    }

    render() {
        const { lastCheck } = this.props;
        const activeOption = FILTERS_LAST_CHECK_OPTIONS.find((item) => {
            return item.value === lastCheck;
        });
        const dropdownActivator = (
            <div>
                { activeOption.text }
                <span className="icon icon-small-arrow-down" />
            </div>
        );

        return (
            <div>
                <span className="filter-section-title">
                    <h3>{ FILTERS_LAST_CHECK_TITLE }</h3>
                </span>
                <Dropdown
                    className="last-check-dropdown"
                    activator={ dropdownActivator }
                    selections={ this.getDropdownSelections() }
                    onSelectionChange={ this.onSelectionChange }
                />
            </div>
        );
    }
}
