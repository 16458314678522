import ProductService from 'common/services/ProductService';
import {
    POPULAR_TECHNOLOGIES_REQUEST,
    POPULAR_TECHNOLOGIES_SUCCESS,
    POPULAR_TECHNOLOGIES_FAILURE,
    POPULAR_TECHNOLOGIES_LIST
} from './constants';

export function loadPopularTechnologies() {
    return (dispatch) => {
        const slugs = POPULAR_TECHNOLOGIES_LIST;
        dispatch({
            type: POPULAR_TECHNOLOGIES_REQUEST,
            slugs
        });
        return ProductService.instance.getPopularTechnologies(slugs)
            .then((response) => {
                dispatch({
                    type: POPULAR_TECHNOLOGIES_SUCCESS,
                    data: response.data,
                    slugs
                });
            })
            .catch((error) => {
                dispatch({
                    type: POPULAR_TECHNOLOGIES_FAILURE,
                    error,
                    slugs
                });
            });
    };
}
