import React from 'react';
import { connect } from 'react-redux';
import {
    firmographicTabSelect,
    loadFirmographicData
} from './actions';
import FirmographicChartPanel from './chartComponents/FirmographicChartPanel';

function findSelectedMenu(menus, selectedName) {
    return menus.find((item) => {
        return item.name === selectedName;
    });
}

function mapStateToProps(state) {
    return {
        menus: state.firmographics.menus,
        data: state.firmographics.data,
        error: state.firmographics.error,
        isFetching: state.firmographics.isFetching,
        selectedMenu: findSelectedMenu(
            state.firmographics.menus,
            state.firmographics.selectedMenu
        )
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onTabSelection: (menuItem) => {
            dispatch(firmographicTabSelect(menuItem.name));
        },
        loadFirmographicData: (productId) => {
            dispatch(loadFirmographicData(productId));
        },
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class FirmographicContainer extends React.Component {

    render() {
        return <FirmographicChartPanel { ...this.props } />;
    }
}
