import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { Link } from 'react-router';
import { NumeralUtils, track } from 'utils/Utils';
import { ACTION, WHERE } from 'utils/track-constants';

/**
 * Component that renders the table with top 6 popular technologies
 */
export default class PopularTechnologiesList extends React.Component {
    getTableHead() {
        return (
            <tr>
                <td colSpan="2">
                    Product Name
                    <span className="custom-column relative-tooltip"> Total Companies Using
                        <span className="tooltip-info icon-info"
                            data-tip
                            data-for={ this.props.tooltipId }
                            data-class="custom-tooltip"
                        />
                    </span>
                    <ReactTooltip
                        effect="solid"
                        id={ this.props.tooltipId }
                    >
                        <span>
                            This number indicates the amount of unique companies
                            that we have confirmed are using the technology worldwide
                        </span>
                    </ReactTooltip>
                </td>
            </tr>
        );
    }

    getListItems() {
        const row = this.props.technologies.map((item, index) => {
            if (!item) { return null; }
            const iconName = item.vendorUrl ? item.vendorUrl : 'default_favicon';
            const className = classNames('fav-icon', {
                [`icon-${iconName}`]: true
            });
            return (
                <tr
                    className="clickable"
                    key={ index }
                    onClick={ this.itemSelectHandler.bind(this, item) }
                >
                    <td>
                        <span className="icon-circle favicon-wrapper">
                            <span className={ className } />
                        </span>
                        <Link
                            to={ `/product/${item.slug}` }
                        >
                            { item.product }
                        </Link>
                    </td>
                    <td>
                        { NumeralUtils.formatIntegerNumber(item.installs) }
                    </td>
                </tr>
            );
        });
        return row;
    }

    itemSelectHandler(item) {
        if (item.slug) {
            this.props.dispatch(this.props.selectNewProduct(item.slug));
            track(ACTION.VIEW_PRODUCT, { label: WHERE.TRENDING_TECHNOLOGIES });
        }
    }

    render() {
        return (
            <div className="popular-items">
                <table>
                    <thead>
                    { this.getTableHead() }
                    </thead>

                    <tbody>
                    { this.getListItems() }
                    </tbody>
                </table>
            </div>
        );
    }
}

PopularTechnologiesList.contextTypes = {
    router: PropTypes.object.isRequired
};
