import {
    FILTER_COLLECTION_ADD,
    FILTER_COLLECTION_REMOVE,
    FILTER_COLLECTION_SET,
    FILTER_COLLECTION_CLEAR
} from './constants';

export function addCollection(collection) {
    return (dispatch) => {
        return dispatch({
            type: FILTER_COLLECTION_ADD,
            collection
        });
    };
}

export function removeCollection(collection) {
    return (dispatch) => {
        return dispatch({
            type: FILTER_COLLECTION_REMOVE,
            collection
        });
    };
}

export function setCollections(collections) {
    return (dispatch) => {
        return dispatch({
            type: FILTER_COLLECTION_SET,
            collections
        });
    };
}

export function clearCollections() {
    return (dispatch) => {
        return dispatch({
            type: FILTER_COLLECTION_CLEAR
        });
    };
}
