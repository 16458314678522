import ProductService from 'common/services/ProductService';
import {
    TOP_SIMILAR_TECH_TO_SHOW,
    LOAD_SIMILAR_PRODUCTS_REQUEST,
    LOAD_SIMILAR_PRODUCTS_SUCCESS,
    LOAD_SIMILAR_PRODUCTS_FAILURE,
} from './constants';

function removeProductFromProducts(productId, products) {
    return products.filter((obj) => {
        return obj.slug !== productId &&
            obj.name.indexOf('Unspecified Product') === -1;
    });
}

export function loadSimilarProducts(product) {
    let productId = product;
    if (typeof product === 'object') {
        productId = product.productId;
    }

    return (dispatch) => {
        dispatch({
            type: LOAD_SIMILAR_PRODUCTS_REQUEST,
            productId
        });

        return ProductService.instance.getSimilarProducts(productId, TOP_SIMILAR_TECH_TO_SHOW)
            .then((response) => {
                dispatch({
                    type: LOAD_SIMILAR_PRODUCTS_SUCCESS,
                    data: removeProductFromProducts(productId, response.data.products)
                });
            })
            .catch((error) => {
                dispatch({
                    type: LOAD_SIMILAR_PRODUCTS_FAILURE,
                    error
                });
            });
    };
}
