import React from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import GenericHeader from './GenericHeader';
import HeaderDropDown from './HeaderDropDown';
import {
    logout
} from 'discovery/authenticationModal/actions';
import {
    LOG_OUT_REASON_USER_REQUEST
} from 'discovery/authenticationModal/constants';
import {
    HIDDEN,
    VISIBLE,
} from 'shared/constants';

function mapStateToProps(state) {
    return {
        authData: state.authentication.authData
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

class GenericHeaderContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dropDown: HIDDEN
        };
    }

    onLogout = () => {
        this.props.dispatch(logout(LOG_OUT_REASON_USER_REQUEST));
        this.setState({ dropDown: HIDDEN });
    }

    onLoginClick(event) {
        event.preventDefault();
        browserHistory.push('/login');
    }

    getActionOptions() {
        const content = (!this.props.authData || !this.props.authData.token) ?
            this.loginNavBar() : this.logOutNavBar();
        return (
            content
        );
    }

    handleClickOutside = () => {
        this.setState({ dropDown: HIDDEN });
    };

    loginNavBar() {
        return (
        <div className="col-8 col-sm-6 col-md-8">
            <Link
                to={ '/login' }
                className="btn btn-link"
                onClick={ this.onLoginClick.bind(this) }
                data-qa="login-button"
            >
                Login
            </Link>
        </div>
        );
    }

    toggleDropDown = () => {
        if (this.state.dropDown === HIDDEN) {
            this.setState({ dropDown: VISIBLE });
        } else {
            this.setState({ dropDown: HIDDEN });
        }
    }

    logOutNavBar() {
        const className = classNames('dropdown-container', this.state.dropDown);
        return (
            <div className="col-8 col-sm-6 col-md-8">
                <HeaderDropDown
                    authData={ this.props.authData }
                    className={ className }
                    onLogout={ this.onLogout }
                    toggleDropDown={ this.toggleDropDown }
                    handleClickOutSide={ this.handleClickOutside }
                />
            </div>
        );
    }

    render() {
        return (
            <GenericHeader className="home-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4 col-sm-6 col-md-4">
                            <Link className="header-logo" to="/">
                                <div className="homepage-logo"></div>
                            </Link>
                        </div>
                        { this.getActionOptions() }
                    </div>
                </div>
            </GenericHeader>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(GenericHeaderContainer));
