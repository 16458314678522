import {
    SET_GLOBAL_PLATFORM_ERROR
} from './constants';

export const salesforceGlobalErrorInitialState = {
    error: null
};

export default function platformGlobalError(state = salesforceGlobalErrorInitialState, action) {
    switch (action.type) {
        case SET_GLOBAL_PLATFORM_ERROR:
            return Object.assign({}, state, { error: action.error });

        default:
            return state;
    }
}
