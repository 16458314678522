import React from 'react';
import {browserHistory, Link} from 'react-router';
import sprintf from 'sprintf-js';
import Checkbox from 'shared/ui/checkbox/Checkbox';
import UserUtils from 'utils/UserUtils';
import {
    UNEXPECTED_CONTACTS_LOAD_ERROR,
    ACCOUNT_TYPES_NAMES,
    HELP_MESSAGE
} from './constants';
import {
    saveCurrentSegment
} from 'myhg/segment/actions';
import {
    SEGMENT_STATUS,
    MATCH_SOURCE_TYPE,
    MATCH_SOURCE_NAMES
} from 'myhg/segment/constants';
import {
    addRecordType,
    removeRecordType
} from './actions';
import {CONTACT_US_URL} from "../../../shared/footer/constants";

export default class RecordTypeSelection extends React.Component {

    onRecordTypeSelection(recordType, checked) {
        const { dispatch } = this.props;
        const { data } = this.props.accountsStatus;

        if (!data || !data[recordType].loaded) {
            return;
        }

        if (checked) {
            dispatch(addRecordType(recordType));
        } else {
            dispatch(removeRecordType(recordType));
        }
    }

    onContinueClick() {
        const {
            dispatch,
            segmentId,
            selectedRecordTypes,
            authData,
            sourceType,
            accountDetails,
            currentSegment
        } = this.props;
        const isPaidUser = UserUtils.isPaid(accountDetails);
        const segmentPageUrl = `/dashboard/segment/view/${segmentId}`;
        const { status: segmentStatus } = currentSegment.data.status;

        if (!segmentStatus || segmentStatus === SEGMENT_STATUS.NON_MATCHED) {
            // save record types
            dispatch(saveCurrentSegment({
                status: SEGMENT_STATUS.MATCHED,
                source: sourceType,
                sourceValue: authData.salesforceUsername
            }, {
                recordTypes: selectedRecordTypes,
                includeNetNew: isPaidUser,
                includeMatches: true
            })).then(() => {
                browserHistory.push(segmentPageUrl);
            });
        } else {
            // segment already matched or exported, redirect to segment page
            browserHistory.push(segmentPageUrl);
        }
    }

    getUnexpectedError() {
        const { accountsStatus, platformRecordTypes } = this.props;
        const { data } = accountsStatus;
        let content = null;
        let hasOneRecordTypeFailed = false;

        if (data) {
            for (const recordType of platformRecordTypes) {
                if (data[recordType] && data[recordType].failed) {
                    hasOneRecordTypeFailed = true;
                    break;
                }
            }
        }

        if (hasOneRecordTypeFailed || accountsStatus.error) {
            const errorMessage = hasOneRecordTypeFailed ?
                UNEXPECTED_CONTACTS_LOAD_ERROR : accountsStatus.error.message;
            content = (
                <div>
                    <span className="note-message">
                        Error: { errorMessage } { " " }
                    </span>
                    <a className="default-link" onClick={ this.props.onRetry }>
                        Retry
                    </a>
                    { " or " }
                    <Link
                        to={CONTACT_US_URL}
                        target="_blank"
                        rel="nofollow"
                        className="default-link"
                    >
                        Contact&nbsp;Us
                    </Link>
                    .
                </div>
            );
        }

        return content;
    }

    getContinueButton() {
        const { platformRecordTypes } = this.props;
        let enabled = false;
        for (const recordType of platformRecordTypes) {
            if (this.recordIsSelectedAndLoaded(recordType)) {
                enabled = true;
                break;
            }
        }

        return (
            <input
                disabled={ !enabled }
                type="button"
                className="btn btn-primary"
                value="Continue"
                onClick= { this.onContinueClick.bind(this) }
                data-qa="continue-button"

            />
        );
    }

    getSetupItemsList() {
        const { selectedRecordTypes, platformRecordTypes } = this.props;
        let content = null;
        if (platformRecordTypes.length === 1 && selectedRecordTypes.length) {
            content = (
                <div
                    className="accounts-item"
                >
                    <span className="account-type">
                        { ACCOUNT_TYPES_NAMES[selectedRecordTypes[0]] }
                        { this.getRecordProgressByType(selectedRecordTypes[0]) }
                    </span>
                </div>
            );
        } else {
            content = platformRecordTypes.map((recordType, index) => {
                const label = (
                    <span className="account-type">
                        { ACCOUNT_TYPES_NAMES[recordType] }
                        { this.getRecordProgressByType(recordType) }
                    </span>
                );
                return (
                    <div
                        className="accounts-item"
                        key={ index }
                    >
                        <Checkbox
                            label={ label }
                            value={ selectedRecordTypes.indexOf(recordType) >= 0 }
                            onChange={ this.onRecordTypeSelection.bind(this, recordType) }
                        />
                    </div>
                );
            });
        }

        return content;
    }

    getRecordProgressByType(recordType) {
        const { accountsStatus, sourceType } = this.props;
        let content = '';

        if (accountsStatus.error) {
            content = '-';
        } else if (!accountsStatus.data) {
            content = 'Connecting... Please wait.';
        } else if (this.recordErrorByType(recordType)) {
            content = this.recordErrorByType(recordType);
        } else if (!this.recordIsLoadedByType(recordType)) {
            content = this.getLoadingProgressByType(recordType, sourceType);
        } else {
            const totalRecords = this.getTotalRecordsByType(recordType);
            content = `(${totalRecords.toLocaleString()})`;
        }

        return (
            <span className="loading-progress">
                { content }
            </span>
        );
    }

    getTotalRecordsByType(recordType) {
        const { data } = this.props.accountsStatus;
        let totalRecords = 0;

        if (data && data[recordType]) {
            totalRecords = data[recordType].totalRecords;
        }

        return totalRecords;
    }

    getLoadingProgressByType(recordType, sourceType) {
        const { data } = this.props.accountsStatus;
        let content = 'Loading...';

        if (data && data[recordType]) {
            switch (sourceType) {
                case MATCH_SOURCE_TYPE.MARKETO:
                    content = `Loading... ${data[recordType].processedRecords}`;
                    break;

                default: {
                    const loadingProgress =
                        data[recordType].processedRecords * 100 /
                        (data[recordType].totalRecords || 1);

                    content = `Loading... ${loadingProgress.toFixed(0)}%`;
                    break;
                }
            }
        }

        return content;
    }

    recordIsSelectedAndLoaded(recordType) {
        const { selectedRecordTypes } = this.props;
        return (
            selectedRecordTypes.indexOf(recordType) >= 0 &&
            this.recordIsLoadedByType(recordType)
        );
    }

    recordIsLoadedByType(recordType) {
        const { data } = this.props.accountsStatus;
        let isLoaded = false;

        if (data && (data[recordType] && data[recordType].loaded)) {
            isLoaded = true;
        }

        return isLoaded;
    }

    recordErrorByType(recordType) {
        const { data } = this.props.accountsStatus;
        let error = null;

        if (data && (data[recordType] && data[recordType].failed)) {
            error = data[recordType].failedMessage;
        }

        return error;
    }

    render() {
        const { platformRecordTypes, sourceType } = this.props;
        const helpMessage = platformRecordTypes.length > 1 ?
            sprintf.sprintf(HELP_MESSAGE, MATCH_SOURCE_NAMES[sourceType]) : null;
        return (
            <div className="import-accounts">
                { this.getSetupItemsList() }
                { this.getUnexpectedError() }
                { this.getContinueButton() }
                <p className="help">
                    { helpMessage }
                </p>
            </div>
        );
    }
}
