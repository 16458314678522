import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import TopPanelWrapper from 'shared/ui/panel/TopPanelWrapper';
import TopHeaderPaidUser from './components/TopHeaderPaidUser';
import TopHeaderFreeUser from './components/TopHeaderFreeUser';
import AdditionalCompaniesContainer from '../additionalCompanies/AdditionalCompaniesContainer';
import { resetSegmentView } from 'myhg/segment/actions';
import { ACTION, WHERE } from 'utils/track-constants';
import { track } from 'utils/Utils';
import UserUtils from 'utils/UserUtils';

function mapStateToProps(state) {
    return {
        accountDetails: state.user.accountDetails.data,
        permissionCount: state.myhg.home.permissionCount
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class TopHeaderContainer extends React.Component {

    onNewSegmentClick = () => {
        const { dispatch } = this.props;

        dispatch(resetSegmentView());

        track(ACTION.CREATE_SEGMENT, {
            label: WHERE.USER_ACCOUNT_HEADER
        });

        browserHistory.push('/dashboard/segment/new');
    }

    render() {
        const { accountDetails, permissionCount } = this.props;
        let topHeader = null;

        if (UserUtils.isPaid(accountDetails)) {
            topHeader = (
                <TopHeaderPaidUser
                    onNewSegmentClick={ this.onNewSegmentClick }
                    permissionCount={ permissionCount }
                    allPermissions={ accountDetails.permissions.all }
                />
            );
        } else {
            topHeader = (
                <TopHeaderFreeUser
                    onNewSegmentClick={ this.onNewSegmentClick }
                    permissionCount={ permissionCount }
                />
            );
        }

        return (
            <div>
                <TopPanelWrapper className="myhg-home-top">
                    { topHeader }
                </TopPanelWrapper>
                <AdditionalCompaniesContainer
                    onNewSegmentClick={ this.onNewSegmentClick }
                />
            </div>
        );
    }
}
