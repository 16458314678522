import React from 'react';
import { Link } from 'react-router';
import OAuthConnectActionHandler
    from 'shared/oAuth/OAuthConnectActionHandler';
import { CONTACT_US_URL } from "../../../shared/footer/constants";

export default class OauthError extends React.Component {

    render() {
        return (
            <div>
                <div className="container oauth-error-container">
                    <div className="title">
                        Error: { this.props.error.status }
                    </div>
                    <div className="description">
                        { this.props.error.message }<br />
                        Please try again and, if you keep getting errors, contact
                        our customer support team with more details.
                    </div>
                    <span className="actions">
                        <OAuthConnectActionHandler
                            state={ this.props.state }
                            platformName={ this.props.state.platform }
                            switchAccount
                        />
                        { ', ' }
                        <Link className="default-link" to="/dashboard">
                            Go back
                        </Link>
                        { ' or ' }
                        <Link
                            to={CONTACT_US_URL}
                            target="_blank"
                            rel="nofollow"
                            className="default-link"
                        >
                            Contact&nbsp;Us
                        </Link>
                    </span>
                </div>
            </div>
        );
    }
}
