import React from 'react';
import {
    TRIAL_STATUS,
    TRIAL_NOTICE_TITLE,
    TRIAL_NOTICE_DESCRIPTION
} from './../constants';
import UserTrialNotification from './UserTrialNotification';
import SegmentContactUS from 'myhg/segment/components/SegmentContactUS';

export default class TrialExpiredNotification extends React.Component {

    render() {
        const status = TRIAL_STATUS.TRIAL_EXPIRED;
        const title = TRIAL_NOTICE_TITLE[status];
        const description = TRIAL_NOTICE_DESCRIPTION[status].TEXT;
        const link = (
            <strong>
                <SegmentContactUS
                    linkLabel={ TRIAL_NOTICE_DESCRIPTION[status].LINK }
                    noUrl
                    { ...this.props }
                />
            </strong>
        );

        return (
            <UserTrialNotification
                title={ title }
                description={ description }
                actionLink={ link }
            />
        );
    }
}
