import {
    FILTERS_SET_EMPLOYEES_INTERVAL
} from '../constants';

export function setEmployeesInterval(employees) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_SET_EMPLOYEES_INTERVAL,
            employees
        });
    };
}
