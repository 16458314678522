import React from 'react';
import { connect } from 'react-redux';
import FirmographicUtils from 'utils/FirmographicUtils';
import Slider from 'shared/ui/slider/Slider';
import { setEmployeesInterval } from './actions';
import { removeUnknowValuesId } from '../unknownValuesFilter/actions';
import { UNKNOWN_VALUES_EMPLOYEES_FILTER_NAME } from '../unknownValuesFilter/constants';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        employeesSteps: FirmographicUtils.getIntervalsIncludingBoundaries(
            state.taxonomy.firmographic.data.employeesSteps),
        employees: segment.segmentFilters.employees
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class EmployeesFilterContainer extends React.Component {

    componentDidMount() {
        const { employees, employeesSteps } = this.props;

        if (!employees) {
            const employeesInterval = {
                from: employeesSteps[0],
                to: employeesSteps[employeesSteps.length - 1],
            };

            this.props.dispatch(setEmployeesInterval(employeesInterval));
        }
    }

    onEmployeesSliderChange(item) {
        const { employees, employeesSteps, dispatch } = this.props;
        const employeesInterval = {
            from: employeesSteps[item[0]],
            to: employeesSteps[item[1]]
        };

        if (employees.from === employeesSteps[0] &&
            employees.to === employeesSteps[employeesSteps.length - 1]) {
            // unselect unknown firmo value for employees
            dispatch(removeUnknowValuesId(UNKNOWN_VALUES_EMPLOYEES_FILTER_NAME));
        }
        dispatch(setEmployeesInterval(employeesInterval));
        // const label = `${employeesInterval.from} - ${employeesInterval.to}`;
        // track(ACTION.CREATE_CAMPAIGN_EMPLOYEES, { label });
    }

    onPositionChange(item) {
        this.onEmployeesSliderChange(item);
    }

    getEmployeesStepsWithStepBoundaries(data) {
        return FirmographicUtils.getIntervalsIncludingBoundaries(data);
    }

    render() {
        const { employees, employeesSteps } = this.props;
        const isCurrency = false;

        const formattedEmployeesArray = employeesSteps.map((number, index) => {
            return FirmographicUtils.getFormattedNumber(
                number, isCurrency, index === employeesSteps.length - 1);
        });
        const defLeftIndex = employees ?
            employeesSteps.indexOf(employees.from) : 0;
        const defRigthIndex = employees ?
            employeesSteps.indexOf(employees.to) : employeesSteps.length - 1;

        return (
            <Slider
                isControlled
                defaulLeftIndex={ defLeftIndex }
                defaulRigthIndex={ defRigthIndex }
                data={ formattedEmployeesArray }
                onChange={ this.onPositionChange.bind(this) }
            />
        );
    }
}
