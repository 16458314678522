import Symbol from 'es6-symbol';
import ApiWrapper from './ApiWrapper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Singleton class that encapsulates platform related API calls
 */
export default class PlatformService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new PlatformService(singletonEnforcer);
        }
        return this[singleton];
    }

    getSubmitAuthUrl(platformName) {
        return `${ApiWrapper.instance.baseUrl}${platformName}/code`;
    }

    authorizeCode(code, platformName, campaignSessionId) {
        return ApiWrapper.instance.axios.get(`${platformName}/access-token`,
            {
                params: {
                    code
                },
                headers: {
                    campaignSessionId
                },
                timeout: 60 * 1000
            }
        );
    }

    checkToken(campaignSessionId) {
        const config = {
            headers: {
                campaignSessionId
            }
        };
        return ApiWrapper.instance.axios.get('salesforce/check-token', config);
    }

    saveCampaign(campaign, dataFilter, campaignSessionId) {
        const params = { ...dataFilter, ...campaign };

        return ApiWrapper.instance.axios.post('salesforce/campaigns', {}, {
            params,
            timeout: 3 * 60 * 1000,
            headers: {
                campaignSessionId
            }
        });
    }

    getCampaigns(platformSource) {
        return ApiWrapper.instance.axios.get(`${platformSource}/campaigns`);
    }

    getContactsStatus() {
        return ApiWrapper.instance.axios.get('salesforce/loading-status', {
            timeout: 60 * 1000
        });
    }

    netNewMatches(filters, campaignSessionId, cancelToken) {
        const axiosInstance = ApiWrapper.instance.axios;
        const requestSettings = {
            timeout: 10 * 60 * 1000,
            headers: {
                campaignSessionId
            },
            cancelToken: cancelToken ? cancelToken.token : null
        };

        return axiosInstance.post('salesforce/matches/companies', filters, requestSettings);
    }

    getAvailableRecords(filters, campaignSessionId, cancelToken) {
        const axiosInstance = ApiWrapper.instance.axios;
        const requestSettings = {
            timeout: 10 * 60 * 1000,
            headers: {
                campaignSessionId
            },
            cancelToken: cancelToken.token
        };

        return axiosInstance.post('salesforce/matches', filters, requestSettings);
    }
}
