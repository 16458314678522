import RegistrationService from 'common/services/RegistrationService';
import {
    DECODE_VERIFICATION_TOKEN_DATA,
    CONFIRM_RESET_PASSWORD_REQUEST,
    CONFIRM_RESET_PASSWORD_SUCCESS,
    CONFIRM_RESET_PASSWORD_FAILURE
} from './constants';

export function decodeResetPasswordToken(token) {
    return {
        type: DECODE_VERIFICATION_TOKEN_DATA,
        token
    };
}

export function resetPasswordConfirmAction(password, confirmToken) {
    return (dispatch) => {
        dispatch({
            type: CONFIRM_RESET_PASSWORD_REQUEST
        });

        // Dispatch vanilla actions asynchronously
        return RegistrationService.instance.resetPasswordConfirm(password, confirmToken)
            .then((response) => {
                const data = response.data;
                dispatch({
                    type: CONFIRM_RESET_PASSWORD_SUCCESS,
                    data
                });
            })
            .catch((error) => {
                dispatch({
                    type: CONFIRM_RESET_PASSWORD_FAILURE,
                    error
                });
            });
    };
}
