import React from 'react';
import classNames from 'classnames';
import withTaxonomy from 'shared/taxonomy/enhancer';

class CollectionProductsSelection extends React.Component {

    getCollectionProductsList() {
        const {
            searchableItems,
            allowedSlugs,
            findTaxonomyProduct,
            existingProducts
        } = this.props;

        return searchableItems.map((productSlug, index) => {
            const tech = findTaxonomyProduct(productSlug);

            // Disable if product is not allowed or is already selected
            const productNotAllowed = !!allowedSlugs && !allowedSlugs.find((slug) => {
                return tech.slug === slug;
            });
            const productExists = existingProducts.find(prod => {
                return prod.slug === productSlug;
            });

            const disabled = productNotAllowed || productExists;

            const isSelectedProduct = !!this.props.selectedItems.find(product => {
                return product && (product.productId === tech.productId);
            });

            // Assemble class names
            const itemClassName = classNames('select-item', {
                disabled,
                clickable: !disabled,
                selected: (this.props.selectedIndex === index || isSelectedProduct) && !disabled
            });
            const iconName = tech.vendorUrl ? tech.vendorUrl : 'default_favicon';
            const iconClassName = classNames('', {
                'fav-icon': !disabled,
                [`icon-${iconName}`]: !disabled,
                'icon-key': disabled
            });

            return (
                <li
                    key={ index }
                    className={ itemClassName }
                    onClick={ this.productSelectHandler.bind(this, tech) }
                >
                    <span className="icon-circle favicon-wrapper">
                        <span className={ iconClassName } />
                    </span>
                   <span>
                        { tech.product }
                    </span>
                </li>
            );
        });
    }

    productSelectHandler(tech) {
        this.props.itemSelectHandler(tech);
    }

    render() {
        return (
            <div>
                { this.getCollectionProductsList() }
            </div>
        );
    }
}

export default withTaxonomy(CollectionProductsSelection);
