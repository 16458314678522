import React from "react";
import { URL } from '../constants';

class DeprecationBanner extends React.Component {

    render() {
        return (
            <div className="deprecation-banner">
                <text>
                    <b>NOTE</b>: This data is refreshed every 3 months. For faster refreshes talk to our&nbsp;
                    <a href={ URL.DISCOVERY_DEMO_REQUEST }
                       color="#ffffff"
                       id="discovery-demo-request"
                       target="_blank"
                    >
                        Sales&nbsp;Team
                    </a>
                    &nbsp;about the HG Platform!
                </text>
            </div>
        );
    }
}

export default DeprecationBanner;
