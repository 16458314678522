import ProductService from 'common/services/ProductService';
import {
    FIRMOGRAPHIC_TAB_SELECT,
    FIRMOGRAPHIC_DATA_REQUEST,
    FIRMOGRAPHIC_DATA_SUCCESS,
    FIRMOGRAPHIC_DATA_FAILURE
} from 'shared/firmographics/constants';

export function firmographicTabSelect(route) {
    return {
        type: FIRMOGRAPHIC_TAB_SELECT,
        name: route
    };
}

export function loadFirmographicData(productIdFunc) {
    // React Router passes the param as an object
    let productId = productIdFunc;
    if (typeof productIdFunc === 'object') {
        productId = productIdFunc.productId;
    }
    return (dispatch) => {
        dispatch({
            type: FIRMOGRAPHIC_DATA_REQUEST,
            productId
        });
        return ProductService.instance.getProductFirmographics(productId)
            .then((response) => {
                dispatch({
                    type: FIRMOGRAPHIC_DATA_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: FIRMOGRAPHIC_DATA_FAILURE,
                    error
                });
            });
    };
}
