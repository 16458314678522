import React from 'react';
import {
    SIGNUP_BENEFITS
} from '../../constants';
import {
    LANDING_PAGE_TYPES
} from '../../../constants';

export default class PardotBenefits extends React.Component {
    render() {
        const list = SIGNUP_BENEFITS[LANDING_PAGE_TYPES.LP_PARDOT].map((item, index) => {
            return (
                <li key={ index }>
                    <span className="icon icon-check-mark" />
                    <div className="item">
                        <strong>{ item.strong }</strong>
                        { item.normal }
                    </div>
                </li>
            );
        });

        return (
            <ul className="landing-page-benefits">
                { list }
            </ul>
        );
    }
}
