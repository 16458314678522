import React from 'react';
import ModalWizardWrapper from 'shared/ui/modalWizard/ModalWizardWrapper';

/**
 * Authentication Page Wrapper
 */
export default class AuthPageWrapper extends React.Component {

    render() {
        return (
            <ModalWizardWrapper
                onClose={ this.props.onClose }
                onBack={ this.props.onBack }
            >
                { this.props.children }
            </ModalWizardWrapper>
        );
    }
}
