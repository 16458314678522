import React from 'react';
import { browserHistory } from 'react-router';
import MarketoConnectForm from './MarketoConnectForm';
import MarketoConnectOptions from './MarketoConnectOptions';
import {
    MARKETO_CONNECT_PAGE_SUBTITLE,
    MARKETO_ALREADY_CONNECT_PAGE_DESCRIPTION,
    MARKETO_API_URLS_DOC
} from '../constants';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import {
    marketoConnect
} from '../actions';

export default class MarketoConnect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showConnectForm: false
        };
    }

    onConnectSubmit = (data) => {
        track(ACTION.CREATE_CAMPAIGN_AUTHORIZE);
        const { dispatch, segmentId } = this.props;
        return dispatch(marketoConnect(data, segmentId))
            .then(() => {
                this.continueToAccountsImport();
            });
    }

    onSwitchAccount = () => {
        this.setState({
            showConnectForm: true
        });
    }

    getAuthorizeStepContent() {
        const link = (
            <a
                target="_blank"
                className="default-link"
                href={ MARKETO_API_URLS_DOC }
            >
                Marketo Guide
            </a>
        );

        return (
            <div className="platform-connect modal-form">
                <h4 className="sub-title-light">
                    { MARKETO_CONNECT_PAGE_SUBTITLE }
                    <br />
                    Check out our { link } to
                    find out how we get these details.
                </h4>
                <MarketoConnectForm
                    { ...this.props }
                    onConnectSubmit={ this.onConnectSubmit }
                />
            </div>
        );
    }

    getAuthorizeOptionsStepContent() {
        const description = (
            <span className="sub-title-light">
                { MARKETO_ALREADY_CONNECT_PAGE_DESCRIPTION }
                <br />
                <br />
                <strong>{ this.props.authData.salesforceUsername }</strong>
                <br />
            </span>
        );

        return (
            <div className="platform-connectt modal-form">
                <div className="summary">
                    { description }
                </div>
                <MarketoConnectOptions
                    { ...this.props }
                    goToNextStep={ this.continueToAccountsImport }
                    onSwitchAccount={ this.onSwitchAccount }
                />
            </div>
        );
    }

    continueToAccountsImport = () => {
        browserHistory.push('/dashboard/import/marketo');
    }

    render() {
        const { showConnectForm } = this.state;
        const { isValidConnection } = this.props;
        let content = null;

        if (showConnectForm || !isValidConnection) {
            content = this.getAuthorizeStepContent();
        } else {
            content = this.getAuthorizeOptionsStepContent();
        }

        return content;
    }
}
