import { LANDING_PAGE_TYPES } from 'discovery/authenticationModal/constants';

export const CONFIRM_STEPS_INFO = {
    [LANDING_PAGE_TYPES.LP_SALESFORCE]: {
        steps: [
            `Access your Salesforce contact/lead list,
            then match this segment to your data.`,
            'Refine your segment filters.',
            `Build a campaign based on matched data,
            exporting to a new or existing Salesforce campaign.`
        ],
        videoUrl: 'https://player.vimeo.com/video/230827146'
    },
    [LANDING_PAGE_TYPES.LP_PARDOT]: {
        steps: [
            `Access your Pardot contact/lead list,
            then match this segment to your data.`,
            'Refine your segment filters.',
            `Build a campaign based on matched data,
            exporting to a new or existing Pardot campaign.`
        ],
        videoUrl: 'https://www.youtube.com/embed/ILXtE-SKBMg?rel=0'
    },
    [LANDING_PAGE_TYPES.LP_MARKETO]: {
        steps: [
            `Access your Marketo lead list,
            then match this segment to your data.`,
            'Refine your segment filters.',
            `Build a list based on matched data,
            exporting to a new existing Marketo list.`
        ],
        videoUrl: 'https://player.vimeo.com/video/232512793'
    },
    [LANDING_PAGE_TYPES.LP_ELOQUA]: {
        steps: [
            `Access your Eloqua contacts,
            then match this segment to your data.`,
            'Refine your segment filters.',
            `Build a campaign based on matched data,
            exporting to an existing Eloqua campaign.`
        ],
        videoUrl: 'https://www.youtube.com/embed/ILXtE-SKBMg?rel=0'
    },
    [LANDING_PAGE_TYPES.LP_FILE]: {
        steps: [
            'Upload your account list to match to the segment you have created.',
            'Refine your segment filters.',
            'Download the first 500 matched accounts to run a sample campaign.'
        ],
        videoUrl: 'https://player.vimeo.com/video/230825615'
    }
};
