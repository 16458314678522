import {
    FILTER_PRODUCT_ADD,
    FILTER_PRODUCT_REMOVE,
    FILTER_PRODUCT_SET,
    FILTER_PRODUCT_CLEAR,
    FILTER_PRODUCTS_ADD,
    PRODUCTS_RELATION_SET
} from './constants';

export const technologiesFilterInitialState = [];

export function technologies(state = technologiesFilterInitialState, action) {
    switch (action.type) {
        case FILTER_PRODUCT_ADD:
            return [...state, action.technology];

        case FILTER_PRODUCTS_ADD:
            return [...new Set([...state, ...action.technologies])];

        case FILTER_PRODUCT_REMOVE: {
            const index = state.indexOf(action.technology);
            return [...state.slice(0, index), ...state.slice(index + 1)];
        }

        case FILTER_PRODUCT_SET:
            return action.technologies;

        case FILTER_PRODUCT_CLEAR:
            return technologiesFilterInitialState;

        default:
            return state;
    }
}

export const matchAllProductsInitialState = false;

export function matchAllProducts(state = matchAllProductsInitialState, action) {
    switch (action.type) {
        case PRODUCTS_RELATION_SET:
            return action.matchAllProducts;

        default:
            return state;
    }
}
