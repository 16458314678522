import {
    LIGHTBOX_OPEN,
    LIGHTBOX_UPDATE,
    LIGHTBOX_CLOSE
} from './constants';

export const lightboxInitialState = {
    isOpen: false,
    initialPath: null
};

export default function (state = lightboxInitialState, action) {
    switch (action.type) {
        case LIGHTBOX_OPEN :
            return Object.assign({}, state, {
                isOpen: true,
                ...action.props
            });

        case LIGHTBOX_UPDATE :
            return Object.assign({}, state, {
                ...action.props
            });

        case LIGHTBOX_CLOSE :
            return Object.assign({}, state, {
                isOpen: false,
                initialPath: null
            });

        default:
            return state;
    }
}
