import React from 'react';
import {
    NO_RECORDS
} from './constants';

export default class NoRecords extends React.Component {
    render() {
        return (
            <div className="segment-no-data">
                <span > { NO_RECORDS.text } </span>
                <a className="menu-action" onClick={ this.props.onReviewFilters } >
                    <span> { NO_RECORDS.link } </span>
                </a>
            </div>
        );
    }
}
