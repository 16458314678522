import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import GenericHeaderContainer from 'shared/header/GenericHeaderContainer';
import HomeTopBanner from './HomeTopBanner';
import PopularTechnologiesContainer from '../popularTechnologies/PopularTechnologiesContainer';
import DeprecationBanner from "../../../shared/deprecation/DeprecationBanner";
import NotFound from './NotFound';
import { selectNewProduct } from 'discovery/product/actions';
import { HTTP_NOTFOUND_CODE } from 'shared/constants';
import { ACTION, WHERE } from 'utils/track-constants';
import { track } from 'utils/Utils';

function mapStateToProps(state) {
    return {
        authData: state.authentication.authData,
        location: state.root.locationPath
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class Home extends React.Component {

    onTechnologySelect(technology) {
        if (technology && technology.slug) {
            this.props.dispatch(selectNewProduct(technology.slug));
            track(ACTION.VIEW_PRODUCT, { label: WHERE.HOME_SEARCH });
        }
    }

    getTopBannerContent() {
        let content = null;
        if (this.props.route.status === HTTP_NOTFOUND_CODE) {
            content = (
                <NotFound
                    { ...this.props }
                    onTechnologySelect={ this.onTechnologySelect.bind(this) }
                    isUserAuthenticated={ this.props.isUserAuthenticated }
                />
            );
        } else {
            content = (
                <HomeTopBanner onTechnologySelect={ this.onTechnologySelect.bind(this) } />
            );
        }

        return content;
    }

    handleRegistration = () => {
        browserHistory.push('/register');
    }

    render() {
        return (
            <div id="home">

                <div className="top-header-content-wrapper">
                    <DeprecationBanner />
                    <GenericHeaderContainer />
                </div>

                <div className="top-banner">
                    <div className="container">
                        { this.getTopBannerContent() }
                    </div>
                </div>

                <div className="popular-items first">
                    <div className="container">
                        <PopularTechnologiesContainer
                            {...this.props}
                            handleRegistration={ this.handleRegistration }
                        />
                    </div>
                </div>
            </div>
        );
    }
}
