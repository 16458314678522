import React from 'react';
import UserUtils from 'utils/UserUtils';
import SegmentContactUS from 'myhg/segment/components/SegmentContactUS';

export default class SegmentCrmTrialStatus extends React.Component {
    render() {
        const { accountDetails } = this.props;
        const campaignTrialInfo = UserUtils.getCampaignTrialInfo(accountDetails);
        const isPaidUser = UserUtils.isPaid(accountDetails);
        if (!campaignTrialInfo.isActive || isPaidUser) {
            return null;
        }

        return (
            <div className="col-md-4 segment-crm-status-trial">
                <span className="title">
                    Get HG Insights directly into your CRM with our&nbsp;
                    <a href="https://www.hgdata.com/products/" target="_blank">Salesforce AppExchange</a> product
                </span>
                <span className="message">
                    Schedule a demo to see how you can enrich your account data&nbsp;
                    with Technographics to improve prospecting, workflows, and reporting&nbsp;
                    natively in Salesforce.
                </span>
                <span className="contact-us">
                    <SegmentContactUS
                        linkLabel={ 'Schedule a Demo' }
                        { ...this.props }
                    />
                </span>
            </div>
        );
    }
}
