import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import SearchInput from './SearchInput';
import SelectInput from './SelectInput';
import TypeaheadSelection from './TypeaheadSelection';
import { getFilteredTypeaheadData } from './typeaheadFilters';

class Typeahead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            currentFilter: '',
            filteredData: []
        };
    }

    onSearchTextChange(text) {
        this.setState({
            isOpen: true,
            currentFilter: text,
            filteredData: getFilteredTypeaheadData({
                filterText: text
            }, this.props.searchData)
        });
    }

    onSelectItem(item) {
        this.setState({
            isOpen: false,
            selectedItem: item,
            currentFilter: ''
        });

        if (this.props.onSelectItem) {
            this.props.onSelectItem(item);
        }
    }

    onInputFocus(text) {
        this.setState({
            currentFilter: text
        });
    }

    onSelectInput() {
        this.setState({
            isOpen: !this.state.isOpen,
            filteredData: getFilteredTypeaheadData({
                filterText: ''
            }, this.props.searchData)
        });
    }

    onToggleOpenState() {
        this.setState({
            isOpen: !this.state.isOpen,
            filteredData: getFilteredTypeaheadData({
                filterText: this.state.currentFilter
            }, this.props.searchData)
        });
    }

    getInput() {
        let input = null;

        if (this.props.isSelectInput) {
            input = (
                <SelectInput
                    placeholder={ this.props.inputPlaceholderText }
                    onSelectInput={ this.onSelectInput.bind(this) }
                    selectedItem={ this.props.selectedItem }
                    isOpen={ this.state.isOpen }
                />
            );
        } else {
            input = (
                <SearchInput
                    ref="searchInput"
                    placeholder={ this.props.inputPlaceholderText }
                    autoFocus={ this.props.autoFocus }
                    onSearchTextChange={ this.onSearchTextChange.bind(this) }
                    onInputFocus={ this.onInputFocus.bind(this) }
                    isOpen={ this.state.isOpen }
                    onToggleOpenState={ this.onToggleOpenState.bind(this) }
                />
            );
        }

        return input;
    }

    handleClickOutside = () => {
        this.setState({
            isOpen: false,
            currentFilter: ''
        });
    };

    render() {
        let listSearchSelection;

        if (this.state.isOpen) {
            listSearchSelection = (
                <TypeaheadSelection
                    searchData={ this.state.filteredData }
                    textFilter={ this.state.currentFilter }
                    onSelectItem={ this.onSelectItem.bind(this) }
                />
            );
        }

        const className = classNames('typeahead-container', this.props.className);

        return (
            <div className={ className }>
                { this.getInput() }
                { listSearchSelection }
            </div>
        );
    }
}

export default onClickOutside(Typeahead);

Typeahead.propTypes = {
    onItemSelect: PropTypes.func,
    inputPlaceholderText: PropTypes.string,
    excludeItems: PropTypes.array,
    eventTypes: PropTypes.array
};

Typeahead.defaultProps = {
    excludeItems: [],
    inputPlaceholderText: 'Search',
    eventTypes: ['click', 'touchend']
};
