export const FOOTER_COPYRIGHT_1 = '© Copyright %1$s HG Insights. All rights reserved.';
export const FOOTER_COPYRIGHT_2 = 'Logos provided by';
export const FOOTER_LOGO_PROVIDER = 'Clearbit';
export const FOOTER_LOGO_PROVIDER_LINK = 'https://clearbit.com/logo';
export const FOOTER_ADRESS =
    '1 N Calle Cesar Chavez, STE 100 Santa Barbara, CA 93103 805.880.1100';
export const CONTACT_US_URL = 'https://hginsights.com/contact-us-for-hg-discovery/';

export const ABOUT_SECTION = {
    title: 'ABOUT',
    link: 'about',
    cssClass: 'about'
};
export const HELP_SECTION = {
    title: 'HELP',
    link: 'help',
    cssClass: 'help'
};
export const HG_DATA_SECTION = {
    title: 'HG Insights',
    link: 'hg_data',
    cssClass: 'hgdata'
};

export const FOOTER_LINKS = {
    hg_data: [
        {
            title: 'Solutions',
            link: 'https://www.hgdata.com/solutions/'
        },
        {
            title: 'How We Build It',
            link: 'https://www.hgdata.com/product-build-method/'
        },
        {
            title: 'Partnering With HG Insights',
            link: 'https://www.hgdata.com/hg-data-partner/'
        }
    ],
    about: [
        {
            title: 'About Us',
            link: 'https://www.hgdata.com/about/'
        },
        {
            title: 'Blog',
            link: 'https://www.hgdata.com/blog/'
        },
        {
            title: 'Careers',
            link: 'https://www.hgdata.com/about/#careers'
        },
        {
            title: 'Terms of Use',
            link: 'https://www.hgdata.com/terms-of-use'
        },
        {
            title: 'Privacy Policy',
            link: 'https://www.hgdata.com/privacy-policy'
        }
    ],
    connect: [
        {
            name: 'facebook',
            icon: 'icon-social-facebook',
            link: 'https://www.facebook.com/HGData'
        }, {
            name: 'twitter',
            icon: 'icon-social-twitter',
            link: 'https://twitter.com/hgdata'
        }, {
            name: 'linkedin',
            icon: 'icon-social-linkedin',
            link: 'https://www.linkedin.com/company/hg-data'
        }, {
            name: 'youtube',
            icon: 'icon-social-youtube',
            link: 'https://www.youtube.com/channel/UCflVE08dBRRjjvDhhPi46LA'
        }
    ],
    help: [
        {
            title: 'Contact Us',
            link: CONTACT_US_URL
        },
        {
            title: 'Resources',
            link: 'https://www.hgdata.com/resources/'
        },
        {
            title: 'FAQ',
            link: 'https://www.hgdata.com/faq/'
        }
    ]
};
