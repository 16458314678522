export const SAMPLE_COMPANIES_REQUEST = 'SAMPLE_COMPANIES_REQUEST';
export const SAMPLE_COMPANIES_SUCCESS = 'SAMPLE_COMPANIES_SUCCESS';
export const SAMPLE_COMPANIES_FAILURE = 'SAMPLE_COMPANIES_FAILURE';

export const DOWNLOAD_SAMPLE_REQUEST = 'DOWNLOAD_SAMPLE_REQUEST';
export const DOWNLOAD_SAMPLE_FAILURE = 'DOWNLOAD_SAMPLE_FAILURE';
export const DOWNLOAD_SAMPLE_SUCCESS = 'DOWNLOAD_SAMPLE_SUCCESS';

export const PRODUCT_SAMPLE_COMPANIES_COUNT = 6;
export const DEFAULT_VALUE = '-';
export const LAST_VERIFIED_ICON_TEXT = 'This shows the most recent date ' +
    'where we <br> have found a document indicating this technology is in use';

export const SAMPLE_LIMIT_REACHED =
    'Your sample download limit has been reached.';
export const SAMPLE_COMPANIES_INFO =
    'Here is a small sample of companies that we track using %1$s. <br />' +
    'To get the full list of companies, add this product,' +
    ' then subscribe to a Collection.';

export const SAMPLE_DOWNLOAD_TEXT = 'Download CSV Sample ';
export const SAMPLE_DOWNLOAD_DESC = '25 companies';
