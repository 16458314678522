import React from 'react';
import FilterWrapper from 'shared/filterwrapper/FilterWrapper';
import FirmographicUtils from 'utils/FirmographicUtils';
import withTaxonomy from 'shared/taxonomy/enhancer';
import { TechnologyIcon } from 'shared/ui/technologyIcon/TechnologyIcon';
import { SIGNAL_SCORE_OPTIONS } from 'shared/constants';
import {
    MY_SEGMENT_FILTER_REVENUE,
    MY_SEGMENT_FILTER_EMPLOYEES
} from '../constants';


class SegmentFilters extends React.Component {

    getRevenueRangeFilter() {
        const { revenueFrom, revenueTo } = this.props.segment.data.filters;
        const { revenueSteps } = this.props;
        const fromFormatted = FirmographicUtils.getFormattedNumber(
            revenueFrom,
            true
        );
        const toFormatted = FirmographicUtils.getFormattedNumber(
            revenueTo,
            true,
            revenueTo === revenueSteps[revenueSteps.length - 1]
        );

        return (<FilterWrapper>
            { fromFormatted } - { toFormatted } { MY_SEGMENT_FILTER_REVENUE }
        </FilterWrapper>);
    }

    getEmployeesRangeFilter() {
        const { employeesFrom, employeesTo } = this.props.segment.data.filters;
        const { employeesSteps } = this.props;
        const fromFormatted = FirmographicUtils.getFormattedNumber(
            employeesFrom,
            false
        );
        const toFormatted = FirmographicUtils.getFormattedNumber(
            employeesTo,
            false,
            employeesTo === employeesSteps[employeesSteps.length - 1]
        );

        return (<FilterWrapper>
            { fromFormatted } - { toFormatted } { MY_SEGMENT_FILTER_EMPLOYEES }
        </FilterWrapper>);
    }

    getNonFormatedFilters() {
        const { cc = [], industries, regions } = this.props.segment.data.filters;

        const filterTexts = cc.concat(industries).concat(regions);

        return filterTexts.map((item, index) => {
            return <FilterWrapper key={ index }>{ item }</FilterWrapper>;
        });
    }

    getSignalScoresFilters() {
        const { signalScores } = this.props.segment.data.filters;
        let signalScoresFilters = signalScores.map((id, index) => {
            const option = SIGNAL_SCORE_OPTIONS.find((item) => {
                return id === item.id;
            });
            return <FilterWrapper key={ index }>{ option.text }</FilterWrapper>;
        });

        if (signalScoresFilters && signalScoresFilters.length === SIGNAL_SCORE_OPTIONS.length) {
            signalScoresFilters = <FilterWrapper>All Time</FilterWrapper>;
        }

        return signalScoresFilters;
    }

    getProductFilters() {
        const { findTaxonomyProduct } = this.props;
        const { visualFilters } = this.props.segment.data;
        const { slugs = [] } = this.props.segment.data.filters;
        const results = [];

        if (visualFilters && Object.keys(visualFilters).length) {
            visualFilters.collections.forEach((collection) => {
                const collectionDisplayItem = {
                    slug: collection.slug,
                    name: `${collection.name} (${collection.techSlugs.length})`,
                    vendorUrl: collection.vendorUrl,
                    collectionType: collection.collectionType
                };

                results.push(
                    <TechnologyIcon technology={ collectionDisplayItem } showTooltip />
                );
            });

            visualFilters.technologies.forEach((techSlug) => {
                const technology = findTaxonomyProduct(techSlug);
                if (technology) {
                    results.push(
                        <TechnologyIcon technology={ technology } showTooltip />
                    );
                }
            });
        } else {
            for (let i = 0; i < slugs.length; i++) {
                const item = findTaxonomyProduct(slugs[i]);
                if (item) {
                    results.push(
                        <TechnologyIcon technology= { item } showTooltip />
                    );
                }
            }
        }

        return results.map((productItem, index) => {
            return (
                <FilterWrapper key={ index } className="mysegment-details-filters-product" >
                    { productItem }
                </FilterWrapper>
            );
        });
    }

    render() {
        return (
            <span className="mysegment-details-filters">
                { this.getProductFilters() }
                { this.getRevenueRangeFilter() }
                { this.getNonFormatedFilters() }
                { this.getEmployeesRangeFilter() }
            </span>
        );
    }
}

export default withTaxonomy(SegmentFilters);
