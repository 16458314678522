import React from 'react';
import { connect } from 'react-redux';
import NotificationBar from './components/NotificationBar';
import { clearNotification } from './actions';

function mapStateToProps(state) {
    return {
        notification: state.notification
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class NotificationContainer extends React.Component {

    onClose = () => {
        const { dispatch } = this.props;

        if (this.props.onClose) {
            // overwrites default close behaviour
            this.props.onClose();
        } else {
            dispatch(clearNotification());
        }
    }

    render() {
        const { notification } = this.props;
        let content = null;

        if (notification) {
            content = (
                <NotificationBar
                    { ...this.props }
                    onClose={ this.onClose }
                />
            );
        }

        return content;
    }
}
