import React from 'react';
import classNames from 'classnames';
import sprintf from 'sprintf-js';
import UserUtils from 'utils/UserUtils';
import { NumeralUtils } from 'utils/Utils';
import {
    saveCurrentSegment
} from 'myhg/segment/actions';
import {
    loadMatchData
} from '../actions';
import {
    SEGMENT_TABLE_NET_NEW,
    SEGMENT_TABLE_MATCHED
} from '../constants';
import {
    loadPlatformAvailableRecords,
    loadFileAvailableRecords
} from 'myhg/segment/segmentMatches/actions';
import {
    MATCH_SOURCE_TYPE_FILE
} from 'myhg/segment/segmentMatches/constants';

export default class SegmentTableNetNewAndMatches extends React.Component {

    onNetNewClick = () => {
        const { filters: { includeNetNew, includeMatches } } =
            this.props.currentSegment.data;
        const netNew = !includeNetNew;
        const matched = !netNew && !includeMatches ? true : includeMatches;

        this.saveSegment(netNew, matched);
    }

    onMatchedClick = () => {
        const { filters: { includeNetNew, includeMatches } } =
            this.props.currentSegment.data;
        const matched = !includeMatches;
        const netNew = !matched && !includeNetNew ? true : includeNetNew;

        this.saveSegment(netNew, matched);
    }

    saveSegment(netNew, matched) {
        const { accountDetails, dispatch } = this.props;
        const { currentSegment } = this.props;
        const { source, sourceValue } = currentSegment.data.status;
        const isPaidUser = UserUtils.isPaid(accountDetails);

        dispatch(saveCurrentSegment({}, {
            includeNetNew: isPaidUser ? netNew : false,
            includeMatches: matched
        }, false)).then(() => {
            dispatch(loadMatchData());
            if (source === MATCH_SOURCE_TYPE_FILE) {
                dispatch(loadFileAvailableRecords(sourceValue, false));
            } else {
                // TODO: need to determine if sf account is logged in and has accounts imported
                dispatch(loadPlatformAvailableRecords(currentSegment.id, false));
            }
        });
    }

    render() {
        const { accountDetails, segmentSave } = this.props;
        const { filters: { includeNetNew, includeMatches },
            status: { netNew, matched, source } } =
            this.props.currentSegment.data;
        const isNetNewActive = includeNetNew === undefined ? true : includeNetNew;
        const isMatchedActive = includeMatches === undefined ? false : includeMatches;
        const isPaidUser = UserUtils.isPaid(accountDetails);
        const hasMatches = !!this.props.currentSegment.data.status.matched;
        const netNewCss = classNames('netnew', {
            active: isPaidUser ? isNetNewActive : false,
            hide: source === '',
            notPaid: !isPaidUser
        });
        const matchedCss = classNames('matched', {
            active: isPaidUser && hasMatches ? isMatchedActive : false,
            hide: source === '',
            notPaid: !isPaidUser || !hasMatches
        });
        const isSaveReqInProgres = !segmentSave.cancelToken;
        const netNewLabel = sprintf.sprintf(
            SEGMENT_TABLE_NET_NEW,
            NumeralUtils.formatIntegerNumber(netNew)
        );
        const matchedLabel = sprintf.sprintf(
            SEGMENT_TABLE_MATCHED,
            NumeralUtils.formatIntegerNumber(matched)
        );

        return (
            <div className="segment-table-filters col-12 col-lg-4">
                <div
                    className={ netNewCss }
                    onClick={ isPaidUser && isSaveReqInProgres ? this.onNetNewClick : null }
                    data-qa="net-new"
                >
                    { isPaidUser && isNetNewActive ? <span className="icon icon-check-mark" /> :
                        <span className="icon icon-close-x" /> }
                        { netNewLabel }
                </div>
                <div
                    className={ matchedCss }
                    onClick={
                        isPaidUser && hasMatches && isSaveReqInProgres ? this.onMatchedClick : null
                    }
                    data-qa="matched-data"
                >
                    { isPaidUser && isMatchedActive && hasMatches ?
                        <span className="icon icon-check-mark" /> :
                        <span className="icon icon-close-x" />
                    }
                        { matchedLabel }
                </div>
            </div>
        );
    }
}
