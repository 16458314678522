import React from 'react';
import { connect } from 'react-redux';
import UserUtils from 'utils/UserUtils';
import { loadRevenueFirmos } from './actions';
import Loader from 'shared/ui/loader/Loader';
import RevenueChart from './RevenueChart';
import RevenueNetNewChart from './RevenueNetNewChart';

function mapStateToProps(state) {
    const { segment } = state.myhg;
    return {
        currentSegment: segment.currentSegment,
        lastSegmentFiltersUpdate: segment.lastSegmentFiltersUpdate,
        revenueFirmographic: segment.firmographic.revenueFirmographic
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class RevenueChartContainer extends React.Component {

    componentDidMount() {
        const { dispatch, revenueFirmographic: { data, isFetching } } = this.props;
        if (!data && !isFetching) {
            dispatch(loadRevenueFirmos());
        }
    }

    componentDidUpdate() {
        const { dispatch, revenueFirmographic: {
            error, isFetching, data
        } } = this.props;

        if (!data && !isFetching && !error) {
            // reload revenue firmo data on segment filters change
            dispatch(loadRevenueFirmos());
        }
    }

    render() {
        const isTrialExpired = UserUtils.isTrialExpired(this.props.accountDetails);
        const { currentSegment: { data: { filters, status } } } = this.props;
        const { revenueFirmographic: {
            error, isFetching, data
        } } = this.props;
        let content = null;

        // errors
        if (error) {
            content = <div>{ error.message }</div>;
        // loader
        } else if (isFetching || !data) {
            content = <Loader />;
        // display chart
        } else {
            // if segment is tied to a source the display the net new version chart
            const Chart = !status.source || isTrialExpired ? RevenueChart : RevenueNetNewChart;
            content = (
                <Chart
                    data={ data }
                    revenueFrom={ filters.revenueFrom }
                    revenueTo={ filters.revenueTo }
                />
            );
        }

        return content;
    }
}
