export const PLATFORM_CONTACTS_REQUEST = 'PLATFORM_CONTACTS_REQUEST';
export const PLATFORM_CONTACTS_SUCCESS = 'PLATFORM_CONTACTS_SUCCESS';
export const PLATFORM_CONTACTS_FAILURE = 'PLATFORM_CONTACTS_FAILURE';
export const RECORD_TYPE_ADD = 'RECORD_TYPE_ADD';
export const RECORD_TYPE_REMOVE = 'RECORD_TYPE_REMOVE';
export const RECORD_TYPE_CLEAR = 'RECORD_TYPE_CLEAR';
export const RECORD_TYPE_SET = 'RECORD_TYPE_SET';
export const UNEXPECTED_CONTACTS_LOAD_ERROR =
    'There was a problem retrieving some of your data.';

export const ACCOUNT_TYPES = {
    CONTACT: 'contacts',
    LEAD: 'leads',
    PARDOT_LEAD: 'pardot_leads',
    MARKETO_LEAD: 'marketo_leads',
    ELOQUA_CONTACT: 'eloqua_contacts'
};

export const ACCOUNT_TYPES_NAMES = {
    [ACCOUNT_TYPES.CONTACT]: 'Contacts',
    [ACCOUNT_TYPES.LEAD]: 'Leads',
    [ACCOUNT_TYPES.PARDOT_LEAD]: 'Leads',
    [ACCOUNT_TYPES.MARKETO_LEAD]: 'Leads',
    [ACCOUNT_TYPES.ELOQUA_CONTACT]: 'Contacts'
};

export const HELP_MESSAGE =
    `Not sure what to choose? Ask your %1$s Administrator
    for more information about how your account is set up.`;
