import React from 'react';
import classNames from 'classnames';

export default class PanelWrapper extends React.Component {

    getTitleHeader() {
        let icon;
        if (!this.props.title) {
            return null;
        }
        if (this.props.iconName) {
            icon = <span className={ `panel-icon ${this.props.iconName}` } />;
        }

        return (
            <div className="panel-header">
                { icon }
                <h2 className="panel-title">{ this.props.title }</h2>
            </div>
        );
    }

    getDescription() {
        const { description } = this.props;
        return description ?
            (
                <div>
                    { description }
                </div>
            ) : null;
    }

    render() {
        const className = classNames('panel-wrapper', this.props.className);
        return (
            <div className={ className }>
                <div className="container">
                    { this.props.customTitleHeader ?
                        this.props.customTitleHeader : this.getTitleHeader() }
                    { this.getDescription() }
                    <div className="panel-content">
                        { this.props.children }
                    </div>
                </div>
            </div>
        );
    }
}
