import React from 'react';
import ReactDOM from 'react-dom';
import HorizontalStackedBarChart from 'shared/ui/charts/HorizontalStackedBarChart';
import { NumeralUtils } from 'utils/Utils';
import FirmographicUtils from 'utils/FirmographicUtils';

/**
 * Component that renders the Industry Chart
 */
export default class IndustriesChart extends React.Component {

    componentDidMount() {
        this.barChart = new HorizontalStackedBarChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        if (this.barChart) {
            this.barChart.update(this.getChartState());
        }
    }

    componentWillUnmount() {
        if (this.barChart) {
            this.barChart.destroy(this.getDOMNode());
        }
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getChartState() {
        const { data, segmentIndustries } = this.props;

        let overallData = [];
        let companiesData = [];

        let topData = data;

        if (data.length) {
            topData = topData.filter((item) => {
                return item.value > 0;
            }) || [];

            topData = topData.sort((left, right) => {
                return left.value < right.value ? 1 : -1;
            }).slice(0, 26);

            companiesData = FirmographicUtils.getIndustriesStackedBarData(
                topData, segmentIndustries, true);
            overallData = FirmographicUtils.getIndustriesStackedBarData(
                topData, segmentIndustries, false);
        }

        const stackedDataFormat = [{
            type: 'companies',
            values: companiesData
        }, {
            type: 'overall',
            values: overallData
        }];

        return {
            data: stackedDataFormat,
            dataY: 'value',
            dataX: 'name',
            chartHeight: topData.length * 40,
            tickPaddingSpacesFraction: 1,
            tooltipData: (d) => {
                return `<span>Number of companies for </br>${d.name}</span></br>
                    <span class='high-light'>${NumeralUtils.formatIntegerNumber(d.value)}</span>`;
            }
        };
    }

    render() {
        return (
            <div
                id="industry-bar-chart"
                className="stacked-bar-chart horizontal stacked-bar-chart-firmographic"
            />
        );
    }
}

