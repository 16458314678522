import { MATCH_SOURCE_TYPE } from 'myhg/segment/constants';

export const SEGMENT_EXPORT_TEXTS = {
    EXPORT_CAMPAIGN: {
        [MATCH_SOURCE_TYPE.SALESFORCE]: 'Build a Sample Campaign',
        [MATCH_SOURCE_TYPE.MARKETO]: 'Build a Sample List'
    },
    EXPORT_CAMPAIGN_TRIAL_EXPIRED: {
        [MATCH_SOURCE_TYPE.SALESFORCE]: 'Upgrade to Build a Campaign',
        [MATCH_SOURCE_TYPE.MARKETO]: 'Upgrade to Build a List'
    },
    EXPORT_CAMPAIGN_PAID_USER: {
        [MATCH_SOURCE_TYPE.SALESFORCE]: 'Build a Campaign',
        [MATCH_SOURCE_TYPE.MARKETO]: 'Build a List'
    }
};

export const NO_MATCHES_TEXTS = {
    title: 'None of your accounts match this segment. To build a campaign, try to',
    reviewLink: 'review your segment filters'
};
