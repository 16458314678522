import SegmentService from 'common/services/SegmentService';
import {
    SEGMENTS_REQUEST,
    SEGMENTS_SUCCESS,
    SEGMENTS_FAILURE
} from './constants';

export function getSegments() {
    return (dispatch) => {
        dispatch({
            type: SEGMENTS_REQUEST
        });

        return SegmentService.instance.getSegments()
            .then(response => {
                dispatch({
                    type: SEGMENTS_SUCCESS,
                    data: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: SEGMENTS_FAILURE,
                    error
                });
            });
    };
}
