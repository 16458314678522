import { MATCH_SOURCE_TYPE } from 'myhg/segment/constants';

export const EXISTING_CAMPAIGNS_REQUEST = 'EXISTING_CAMPAIGNS_REQUEST';
export const EXISTING_CAMPAIGNS_SUCCESS = 'EXISTING_CAMPAIGNS_SUCCESS';
export const EXISTING_CAMPAIGNS_FAILURE = 'EXISTING_CAMPAIGNS_FAILURE';
export const EXISTING_CAMPAIGNS_TYPE = 'EXISTING_CAMPAIGNS_TYPE';
export const EXISTING_CAMPAIGNS_SELECT = 'EXISTING_CAMPAIGNS_SELECT';
export const NEW_CAMPAIGN_SET = 'NEW_CAMPAIGN_SET';

export const SAVE_CAMPAIGN_REQUEST = 'SAVE_CAMPAIGN_REQUEST';
export const SAVE_CAMPAIGN_SUCCESS = 'SAVE_CAMPAIGN_SUCCESS';
export const SAVE_CAMPAIGN_FAILURE = 'SAVE_CAMPAIGN_FAILURE';
export const SAVE_CAMPAIGN_CLEAR_FAILURE = 'SAVE_CAMPAIGN_CLEAR_FAILURE';

export const EXPORT_SUB_TITLE = 'Where Do You Want To Save The Data?';
export const EXPORT_SUB_NOTE =
    `Note: Net New companies cannot be exported to %1$s directly,
    but they can be exported as a CSV.`;

export const EXPORT_CONFIGS = {
    [MATCH_SOURCE_TYPE.SALESFORCE]: {
        TITLE: 'Build a Salesforce Campaign',
        MENUS: ['Existing Salesforce Campaigns', 'New Salesforce Campaign'],
        SEARCH_INPUT: 'Select an existing Salesforce Campaign'
    },
    [MATCH_SOURCE_TYPE.PARDOT]: {
        TITLE: 'Build a Pardot Campaign',
        MENUS: ['Existing Pardot Campaign', 'New Pardot Campaign'],
        SEARCH_INPUT: 'Select an existing Pardot Campaign'
    },
    [MATCH_SOURCE_TYPE.MARKETO]: {
        TITLE: 'Build a Marketo List',
        MENUS: ['Existing Marketo List'],
        SEARCH_INPUT: 'Select an existing Marketo List'
    },
    [MATCH_SOURCE_TYPE.ELOQUA]: {
        TITLE: 'Build an Eloqua Campaign',
        MENUS: ['Existing Eloqua Campaigns'],
        SEARCH_INPUT: 'Select an existing Eloqua Campaign'
    }
};
