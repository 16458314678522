import React from 'react';
import { browserHistory } from 'react-router';
import EloquaConnectWrapper from './EloquaConnectWrapper';
import EloquaConnectOptions from './EloquaConnectOptions';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import OAuthConnectActionHandler
    from 'shared/oAuth/OAuthConnectActionHandler';
import {
    ELOQUA_ALREADY_CONNECT_PAGE_DESCRIPTION,
    ELOQUA_CONNECT_PAGE_DESCRIPTION
} from '../constants';

export default class EloquaConnect extends React.Component {

    getState() {
        const { segmentId } = this.props;

        return {
            callback: '/dashboard/connect/platform-authorize',
            platform: 'eloqua',
            segmentId
        };
    }

    getConnectContent() {
        return (
            <div className="platform-connect">
                <h4 className="sub-title">
                    { ELOQUA_CONNECT_PAGE_DESCRIPTION }
                </h4>
                <EloquaConnectWrapper>
                    <OAuthConnectActionHandler
                        state={ this.getState() }
                        onSubmit={ this.handleSubmit }
                        platformName="eloqua"
                    />
                </EloquaConnectWrapper>
            </div>
        );
    }

    getAlreadyConnectedContent() {
        const description = (
            <span className="sub-title-light">
                { ELOQUA_ALREADY_CONNECT_PAGE_DESCRIPTION }
                <br />
                <br />
                <strong>{ this.props.authData.salesforceUsername }</strong>
                <br />
            </span>
        );

        return (
            <div className="platform-connect modal-form">
                <div className="summary">
                    { description }
                </div>
                <EloquaConnectOptions
                    { ...this.props }
                    state={ this.getState() }
                    goToNextStep={ this.continueWithImportData }
                />
            </div>
        );
    }

    handleSubmit() {
        track(ACTION.CREATE_CAMPAIGN_AUTHORIZE);
    }

    continueWithImportData() {
        browserHistory.push('/dashboard/import/eloqua');
    }

    render() {
        const { isValidConnection } = this.props;

        if (!isValidConnection) {
            return this.getConnectContent();
        }

        return this.getAlreadyConnectedContent();
    }
}
