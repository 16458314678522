import axios from 'axios';
import UserUtils from 'utils/UserUtils';
import SegmentUtils from '../../SegmentUtils';
import SegmentFirmographicsService from 'common/services/SegmentFirmographicsService';
import {
    SEGMENT_EMPLOYEES_DATA_REQUEST,
    SEGMENT_EMPLOYEES_DATA_SUCCESS,
    SEGMENT_EMPLOYEES_DATA_FAILURE
} from './constants';
import {
    MATCH_SOURCE_TYPE
} from 'myhg/segment/constants';

function getFirmoUrlBySegmentStatus(segmentStatus, isTrialExpired) {
    let url;
    const { source } = segmentStatus;

    if (!source || isTrialExpired) {
        url = 'filtering/firmographics/employees';
    } else if (source === MATCH_SOURCE_TYPE.FILE) {
        url = 'filtering/csv/firmographics/employees';
    } else {
        // platforms matches url
        url = 'salesforce/firmographics/employees';
    }

    return url;
}

export function loadEmployeesFirmos() {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const currentState = getState();
        const { data: { filters, status }, id } = currentState.myhg.segment.currentSegment;

        // check if trial period expired or not for non paid users
        const accountDetails = currentState.user.accountDetails.data;
        const isTrialExpired = UserUtils.isTrialExpired(accountDetails);
        const url = getFirmoUrlBySegmentStatus(status, isTrialExpired);
        const segmentFilters =
            SegmentUtils.removeMatchesAndIncludeFilters(filters, status, isTrialExpired);

        dispatch({
            type: SEGMENT_EMPLOYEES_DATA_REQUEST,
            cancelToken
        });


        return SegmentFirmographicsService.instance.getData(url, segmentFilters, id, cancelToken)
            .then((response) => {
                dispatch({
                    type: SEGMENT_EMPLOYEES_DATA_SUCCESS,
                    data: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: SEGMENT_EMPLOYEES_DATA_FAILURE,
                    error
                });
            });
    };
}
