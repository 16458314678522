import React from 'react';
import { browserHistory } from 'react-router';
import PardotConnectForm from './PardotConnectForm';
import PardotConnectOptions from './PardotConnectOptions';
import {
    PARDOT_CONNECT_FORM_TITLE,
    PARDOT_ALREADY_CONNECT_PAGE_DESCRIPTION
} from '../constants';
import { ACTION } from 'utils/track-constants';
import { track } from 'utils/Utils';
import { pardotConnect } from '../actions';

export default class MarketoConnect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showConnectForm: false
        };
    }

    onConnectSubmit = (data) => {
        track(ACTION.CREATE_CAMPAIGN_AUTHORIZE);
        const { dispatch, segmentId } = this.props;
        return dispatch(pardotConnect(data, segmentId))
            .then(() => {
                this.continueToAccountsImport();
            });
    }

    onSwitchAccount = () => {
        this.setState({
            showConnectForm: true
        });
    }

    getAuthorizeStepContent() {
        return (
            <div className="platform-connect modal-form">
                <h4 className="sub-title-light">
                    { PARDOT_CONNECT_FORM_TITLE }
                </h4>
                <PardotConnectForm
                    { ...this.props }
                    onConnectSubmit={ this.onConnectSubmit }
                />
            </div>
        );
    }

    getAuthorizeOptionsStepContent() {
        const description = (
            <span className="sub-title-light">
                { PARDOT_ALREADY_CONNECT_PAGE_DESCRIPTION }
                <br />
                <br />
                <strong>{ this.props.authData.salesforceUsername }</strong>
                <br />
            </span>
        );

        return (
            <div className="platform-connect modal-form">
                <div className="summary">
                    { description }
                </div>
                <PardotConnectOptions
                    { ...this.props }
                    goToNextStep={ this.continueToAccountsImport }
                    onSwitchAccount={ this.onSwitchAccount }
                />
            </div>
        );
    }

    continueToAccountsImport = () => {
        browserHistory.push('/dashboard/import/pardot');
    }

    render() {
        const { showConnectForm } = this.state;
        const { isValidConnection } = this.props;
        let content = null;

        if (showConnectForm || !isValidConnection) {
            content = this.getAuthorizeStepContent();
        } else {
            content = this.getAuthorizeOptionsStepContent();
        }

        return content;
    }
}
