import {
    COLLECTIONS_REQUEST,
    COLLECTIONS_SUCCESS,
    COLLECTIONS_FAILURE,
    COLLECTIONS_SHOW_NEXT,
    COLLECTIONS_TOP_PRODUCT_COLLECTIONS,
    COLLECTIONS_TOGGLE_NOTIFICATON_BOX
} from './constants';

export const collectionsInitialState = {
    isFetched: false,
    data: null,
    error: null,
    showTopCount: COLLECTIONS_TOP_PRODUCT_COLLECTIONS,
    showNotificationBox: false
};

export default function collections(state = collectionsInitialState, action) {
    switch (action.type) {
        case COLLECTIONS_REQUEST:
            return Object.assign({}, state, {
                isFetched: false,
                error: null,
                showTopCount: COLLECTIONS_TOP_PRODUCT_COLLECTIONS
            });

        case COLLECTIONS_SUCCESS:
            return Object.assign({}, state, {
                isFetched: true,
                data: action.data.slice(0, 30),
                error: null
            });

        case COLLECTIONS_FAILURE:
            return Object.assign({}, state, {
                isFetched: false,
                error: action.error
            });

        case COLLECTIONS_SHOW_NEXT:
            return Object.assign({}, state, {
                showTopCount: state.showTopCount + COLLECTIONS_TOP_PRODUCT_COLLECTIONS
            });

        case COLLECTIONS_TOGGLE_NOTIFICATON_BOX:
            return Object.assign({}, state, {
                showNotificationBox: !state.showNotificationBox
            });

        default:
            return state;
    }
}
