import PropTypes from 'prop-types';
/**
 * Created by mihaela on 29.05.2017.
 */
import React from 'react';
import sprintf from 'sprintf-js';

export default class CardsList extends React.Component {

    getShowNextItems() {
        const { cards, topCount, countPerRow, nextText } = this.props;

        const loadMoreText = sprintf.sprintf(
            nextText,
            countPerRow
        );

        let content = null;

        if (cards && topCount < cards.length) {
            content = (
                <div className="display-more">
                    <a
                        className="show-more-items primary-link"
                        onClick={ this.props.showMore }
                    >
                        { loadMoreText }
                    </a>
                </div>
            );
        }

        return content;
    }

    getRow(cards) {
        return cards.map((card) => {
            return card;
        });
    }

    getAllData() {
        const { cards, topCount, countPerRow } = this.props;
        const chunks = [];

        for (let i = 0; i < topCount; i += countPerRow) {
            chunks.push(cards.slice(i, countPerRow + i));
        }

        return chunks.map((chunk, index) => {
            return (
                <div className="card-row" key={ index }>
                    { this.getRow(chunk) }
                </div>
            );
        });
    }

    render() {
        return (
            <div className="cards-list-wrapper">
                { this.getAllData() }
                { this.getShowNextItems() }
            </div>
        );
    }
}

CardsList.propTypes = {
    cards: PropTypes.array.isRequired
};
