import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
    SIGN_UP_RESEND_EMAIL_BUTTON_TEXT,
    SIGN_UP_INPROGRESS_TITLE_EXPIRED,
    SIGN_UP_INPROGRESS_TITLE,
    SIGN_UP_INPROGRESS_MESSAGE_EXPIRED,
    SIGN_UP_INPROGRESS_MESSAGE,
    SIGN_UP_INPROGRESS_NOTE,
    SIGN_UP_INPROGRESS_NO_EMAIL
} from './constants';
import Loader from 'shared/ui/loader/Loader';
import { getRegistrationVerificationUrl } from './actions';

function mapStateToProps(state) {
    return {
        signUp: state.authentication.signUp
    };
}

@connect(mapStateToProps)

class SignUpInProgress extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            emailConfirmationSent: false
        };
    }

    onSubmit() {
        return this.props.resendConfirmationEmail()
            .then(() => {
                this.setState({
                    emailConfirmationSent: true
                });
            });
    }

    onRegistrationStartOver() {
        this.props.onRegistrationStartOver();
    }

    getActionButton() {
        let actionContent = null;

        if (this.state.emailConfirmationSent) {
            actionContent = (
                <p className="text-content large">
                    Email Confirmation Successfully Sent.
                </p>
            );
        } else {
            actionContent = (
                <p className="text-content resend">
                    { SIGN_UP_INPROGRESS_NO_EMAIL }
                    <button
                        id="resend-email-btn"
                        className="btn btn-primary"
                        disabled={ this.props.submitting }
                        type="submit"
                        data-qa="resend-link"
                    >
                        { SIGN_UP_RESEND_EMAIL_BUTTON_TEXT }
                        { this.props.submitting ?
                            <Loader className="small-loader black" /> : '' }
                    </button>
                    or
                    <a
                        className="default-link"
                        onClick={ this.onRegistrationStartOver.bind(this) }
                        data-qa="startover"
                    >
                        Start Over
                    </a>
             </p>
            );
        }
        return actionContent;
    }

    getError(error) {
        if (!error) {
            return null;
        }

        return (
                <p className="text-content error">
                    { error.message || `Error! ${error.status} (${error.statusText})` }
                </p>
            );
    }

    getTitle() {
        let titleText = null;

        if (this.props.signUp.linkExpired) {
            titleText = SIGN_UP_INPROGRESS_TITLE_EXPIRED;
        } else {
            titleText = SIGN_UP_INPROGRESS_TITLE;
        }

        const { dispatch, params: { landingPage } } = this.props;
        const verificationUrl = dispatch(getRegistrationVerificationUrl(landingPage));

        return (
            <p className="text-content title main">
               { titleText }
                <input
                    type="hidden"
                    name="registerToken"
                    value={ this.props.signUp.authData || '' }
                />
                <input
                    type="hidden"
                    name="verificationCallbackUrl"
                    value={ verificationUrl }
                />
            </p>
        );
    }

    getTextContent() {
        let text = null;

        if (this.props.signUp.linkExpired) {
            text = SIGN_UP_INPROGRESS_MESSAGE_EXPIRED;
        } else {
            text = SIGN_UP_INPROGRESS_MESSAGE;
        }

        return (
            <p className="text-content large subtitle">
               { text }
            </p>
        );
    }

    getBottomTextContent() {
        let content = null;

        if (!this.props.signUp.linkExpired) {
            content = (
                <p className="text-content large">
                    Note: This link is available for 24 hours
                </p>
            );
        }

        return content;
    }

    render() {
        const {
            signUp } = this.props;

        return (
            <form
                id="signup-in-progress"
                className="modal-form"
                onSubmit={ this.props.handleSubmit(this.onSubmit.bind(this)) }
            >
                { this.getTitle() }
                { this.getTextContent() }
                <p className="text-content title mail">
                    { signUp.registrationData.email }
                </p>
                <span className="note"> { SIGN_UP_INPROGRESS_NOTE } </span>
                { this.getError(signUp.error) }
                { this.getActionButton() }
            </form>
        );
    }
}

SignUpInProgress.propTypes = {
    resendConfirmationEmail: PropTypes.func.isRequired,
    onRegistrationStartOver: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'signUpInProgress',
    fields: []
})(SignUpInProgress);
