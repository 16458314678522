import React from 'react';
import { NumeralUtils } from 'utils/Utils';
import UserUtils from 'utils/UserUtils';
import {
    LOCATIONS_GLOBAL,
    LOCATIONS_LATAM,
    LOCATIONS_APAC,
    LOCATIONS_EMEA,
    MAP_LOCATION_TOOLTIP,
    NET_NEW,
    MATCHES
} from '../constants';

/**
 * Component that renders the Other Maps Locations Chart
 */
export default class OtherMapsLocationsChart extends React.Component {

    generateTooltip(name) {
        const { accountDetails, locationsFirmographic: { data } } = this.props;
        const isTrialExpired = UserUtils.isTrialExpired(accountDetails);

        if (data && data.regions && (!this.props.isMatched || isTrialExpired)) {
            let value = 0;
            data.regions.forEach((item) => {
                // if it's Global Location we should calculate the total
                if (name === LOCATIONS_GLOBAL) {
                    value = value + item.value;
                } else {
                    if (item.name === name) {
                        value = item.value;
                    }
                }
            });

            return this.tooltip(name, value);
        } else if (data && data.regions && this.props.isMatched) {
            let netNew = 0;
            let matches = 0;
            data.regions.forEach((item) => {
                // if it's Global Location we should calculate the total
                if (name === LOCATIONS_GLOBAL) {
                    netNew = netNew + item.netNew || 0;
                    matches = matches + item.matches || 0;
                } else {
                    if (item.name === name) {
                        netNew = item.netNew;
                        matches = item.matches;
                    }
                }
            });

            return this.matchesTooltip(name, netNew, matches);
        }

        return null;
    }

    tooltip(name, value) {
        return (
            <div>
                <span className="tooltip-text"> { MAP_LOCATION_TOOLTIP } { name } </span>
                <span className="value">
                    { NumeralUtils.formatIntegerNumber(value) }
                </span>
            </div>
        );
    }

    matchesTooltip(name, netNew, matches) {
        return (
            <div>
                <span className="tooltip-text"> { MAP_LOCATION_TOOLTIP } { name } </span>
                <span className="value">
                    { NET_NEW }: { NumeralUtils.formatIntegerNumber(netNew) } <br />
                    { MATCHES }: { NumeralUtils.formatIntegerNumber(matches) }
                </span>
            </div>
        );
    }

    render() {
        return (
            <div className="other-maps">
                <div className="latam-map">
                    <div className="tooltip"> { this.generateTooltip(LOCATIONS_LATAM) } </div>
                    <span className="text">{ LOCATIONS_LATAM }</span>
                </div>
                <div className="apac-map">
                    <div className="tooltip"> { this.generateTooltip(LOCATIONS_APAC) } </div>
                    <span className="text">{ LOCATIONS_APAC }</span>
                </div>
                <div className="emea-map">
                    <div className="tooltip"> { this.generateTooltip(LOCATIONS_EMEA) } </div>
                    <span className="text">{ LOCATIONS_EMEA }</span>
                </div>
                <div className="global-map">
                    <div className="tooltip"> { this.generateTooltip(LOCATIONS_GLOBAL) } </div>
                    <span className="text">{ LOCATIONS_GLOBAL }</span>
                </div>
            </div>
        );
    }
}
