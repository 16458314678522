import React from 'react';
import { connect } from 'react-redux';
import Loader from 'shared/ui/loader/Loader';
import ImportPageContainerWrapper from 'myhg/import/ImportPageContainerWrapper';
import {
    validatePlatformToken
} from 'myhg/platformConnection/actions';
import segmentEnforcer from 'myhg/segment/segmentEnforcer';
import {
    LINKEDIN_CONNECT_PAGE_TITLE,
    LINKEDIN_CONNECT_PAGE_DESCRIPTION,
    LINKEDIN_OPTIONS_PAGE_DESCRIPTION
} from './constants';
import LinkedInConnect from './components/LinkedInConnect';
import { getAccounts, linkedInConnect } from './actions';
import LinkedInConnectOptions from './components/LinkedInConnectOptions';

function mapStateToProps(state) {
    return {
        segmentId: state.myhg.segment.currentSegment.id,
        currentSegment: state.myhg.segment.currentSegment,
        authData: state.authentication.authData,
        accessToken: state.myhg.platformConnection.linkedInConnect.accessToken,
        accounts: state.myhg.platformConnection.linkedInConnect.accounts,
        submitting: state.myhg.platformConnection.linkedInConnect.submitting,
        requestError: state.myhg.platformConnection.linkedInConnect.error,
        technologies: state.taxonomy.products.data
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@segmentEnforcer
@connect(mapStateToProps, mapDispatchToProps)

// TODO : marketo will relies on salesforce existing apis (but without oauth)
// we need to update salesforce naming to cloud elements and parameterize source type only
export default class LinkedInConnectContainer extends React.Component {

    componentDidMount() {
        const checkTokenData = this.props.location.query.code;

        // always validate the token
        this.props.dispatch(validatePlatformToken());

        if (!!checkTokenData) {
            this.props.dispatch(linkedInConnect(checkTokenData, this.props.segmentId));
        }
    }

    componentDidUpdate() {
        if (!!this.props.accessToken && !this.props.accounts) {
            this.props.dispatch(getAccounts(this.props.accessToken));
        }
    }

    getAuthorizeStepContent() {
        return (
            <div className="platform-connect">
                <h4 className="sub-title">
                    { LINKEDIN_CONNECT_PAGE_DESCRIPTION }
                </h4>
                <LinkedInConnect { ...this.props } />
            </div>
        );
    }

    getAccountSelectStepContent() {
        return (
            <div className="platform-connect">
                <h4 className="sub-title">
                    { LINKEDIN_OPTIONS_PAGE_DESCRIPTION }
                </h4>

                <LinkedInConnectOptions { ...this.props } />
            </div>
        );
    }

    /* eslint-disable no-else-return */
    getImportPageContent() {
        if (this.props.submitting) {
            return <Loader />;
        } else if (!!this.props.accounts) {
            return this.getAccountSelectStepContent();
        } else {
            return this.getAuthorizeStepContent();
        }
    }
    /* eslint-enable no-else-return */

    render() {
        let title = LINKEDIN_CONNECT_PAGE_TITLE;

        const customHeaderTitle = (
            <span>
                { title }
                <span className="platform-title-logo linkedin-img" />
            </span>
        );

        return (
            <ImportPageContainerWrapper title={ title } customHeader={ customHeaderTitle }>
                { this.getImportPageContent() }
            </ImportPageContainerWrapper>
        );
    }
}
