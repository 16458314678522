import React from 'react';
import ReactDOM from 'react-dom';
import HorizontalStackedBarChart from 'shared/ui/charts/HorizontalStackedBarChart';
import { NumeralUtils } from 'utils/Utils';
import FirmographicUtils from 'utils/FirmographicUtils';
import {
    denormalizeNetNewMatchesData
} from '../reducer';
import {
    TIP_DATA_TYPE_NETNEW_TEXT,
    TIP_DATA_TYPE_MATCHED_TEXT,
    TIP_DATA_TYPE_ALL_TEXT
} from '../constants';

/**
 * Component that renders the Industry Chart
 */
export default class IndustriesNetNewChart extends React.Component {

    componentDidMount() {
        this.barChart = new HorizontalStackedBarChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        if (this.barChart) {
            this.barChart.update(this.getChartState());
        }
    }

    componentWillUnmount() {
        if (this.barChart) {
            this.barChart.destroy(this.getDOMNode());
        }
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getChartState() {
        const { data, segmentIndustries } = this.props;

        const stackedDataFormat = [{
            type: 'matched',
            values: []
        }, {
            type: 'netNew',
            values: []
        }, {
            type: 'overall',
            values: []
        }];

        let topData = data;
        // get top industries
        if (data.length) {
            topData = topData.filter((item) => {
                return item.netNew + item.matches > 0;
            }) || [];

            topData = topData.sort((left, right) => {
                return (left.netNew + left.matches) < (right.netNew + right.matches) ? 1 : -1;
            }).slice(0, 26);

            // get denormalized data
            const denData = denormalizeNetNewMatchesData(topData);
            const mergedData = denData.netNew.map((netNew, index) => {
                return {
                    name: netNew.name || netNew.industry,
                    value: netNew.value + denData.matched[index].value
                };
            });

            stackedDataFormat[0].values = FirmographicUtils.getIndustriesStackedBarData(
                denData.netNew, segmentIndustries, true);
            stackedDataFormat[1].values = FirmographicUtils.getIndustriesStackedBarData(
                denData.matched, segmentIndustries, true);
            stackedDataFormat[2].values = FirmographicUtils.getIndustriesStackedBarData(
                mergedData, segmentIndustries, false);
        }

        const tooltipDataLabels = {
            netNew: TIP_DATA_TYPE_NETNEW_TEXT,
            matched: TIP_DATA_TYPE_MATCHED_TEXT,
            overall: TIP_DATA_TYPE_ALL_TEXT
        };

        return {
            data: stackedDataFormat,
            dataY: 'value',
            dataX: 'name',
            chartHeight: topData.length * 40,
            tickPaddingSpacesFraction: 1,
            tooltipData: (d) => {
                return `<span>Number of ${tooltipDataLabels[d.type]} companies for
                    </br>${d.name}</span></br>
                    <span class='high-light'>${NumeralUtils.formatIntegerNumber(d.value)}</span>`;
            }
        };
    }

    render() {
        return (
            <div
                id="industry-bar-chart"
                className="stacked-bar-chart horizontal stacked-bar-chart-firmographic"
            />
        );
    }
}

