export const COLLECTION_REQUEST = 'COLLECTION_REQUEST';
export const COLLECTION_SUCCESS = 'COLLECTION_SUCCESS';
export const COLLECTION_FAILURE = 'COLLECTION_FAILURE';

export const COLLECTION_PRODUCTS_TITLE = 'Products';
export const COLLECTION_COMPANIES_TITLE = 'Companies';

export const COLLECTION_VENDORS_TITLE = 'Vendors';
export const COLLECTION_INSTALLS_TITLE = 'Records';

export const COLLECTION_SEO_SUFIX = 'products-services';

export const PRODUCT_COUNT_PER_COLUMN = 8;
export const PRODUCT_IN_COLLECTION_TITLE = 'Products in Collection';
export const PRODUCT_COLLECTION_TITLE_PAGE = 'Collection Details';

