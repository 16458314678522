import {
    LOAD_FIRST_TIME_ACTIONS,
    SHOW_COLLECTIONS_NOTIFICATION_BOX
} from './constants';

export const firstTimeActionsInitialState = {
    [SHOW_COLLECTIONS_NOTIFICATION_BOX]: true
};

export default function firstTimeActions(state = firstTimeActionsInitialState, action) {
    switch (action.type) {
        case LOAD_FIRST_TIME_ACTIONS:
            return action.firstTimeActions || {};

        default:
            return state;
    }
}
