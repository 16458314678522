import { combineReducers } from 'redux';
import {
    PLATFORM_CONTACTS_REQUEST,
    PLATFORM_CONTACTS_SUCCESS,
    PLATFORM_CONTACTS_FAILURE,
    RECORD_TYPE_ADD,
    RECORD_TYPE_REMOVE,
    RECORD_TYPE_CLEAR,
    RECORD_TYPE_SET
} from './constants';

export const contactsStatusInitialState = {
    isFetching: false,
    error: null,
    data: null
};

export function contactsStatus(state = contactsStatusInitialState, action) {
    switch (action.type) {
        case PLATFORM_CONTACTS_REQUEST:
            return Object.assign({}, state, {
                error: null,
                isFetching: true
            });

        case PLATFORM_CONTACTS_SUCCESS:
            return Object.assign({}, state, {
                error: null,
                data: action.data,
                isFetching: false
            });

        case PLATFORM_CONTACTS_FAILURE:
            return Object.assign({}, state, {
                error: action.error,
                isFetching: false
            });

        case RECORD_TYPE_CLEAR:
            return contactsStatusInitialState;

        default:
            return state;
    }
}

export const selectedRecordTypeInitialState = [];

export function selectedRecordTypes(state = selectedRecordTypeInitialState, action) {
    switch (action.type) {
        case RECORD_TYPE_ADD:
            return [...state, action.recordType];

        case RECORD_TYPE_REMOVE: {
            const index = state.findIndex((recordType) => {
                return recordType === action.recordType;
            });
            return [...state.slice(0, index), ...state.slice(index + 1)];
        }

        case RECORD_TYPE_SET:
            return action.recordTypes;

        case RECORD_TYPE_CLEAR:
            return selectedRecordTypeInitialState;

        default:
            return state;
    }
}

export default combineReducers({
    selectedRecordTypes,
    status: contactsStatus
});
