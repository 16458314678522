export const CONFIRM_RESET_PASSWORD_REQUEST = 'CONFIRM_RESET_PASSWORD_REQUEST';
export const CONFIRM_RESET_PASSWORD_SUCCESS = 'CONFIRM_RESET_PASSWORD_SUCCESS';
export const CONFIRM_RESET_PASSWORD_FAILURE = 'CONFIRM_RESET_PASSWORD_FAILURE';

export const DECODE_VERIFICATION_TOKEN_DATA = 'DECODE_VERIFICATION_TOKEN_DATA';

// menu title
export const CONFIRM_RESET_PASSWORD_TITLE = 'Reset Your Password';
export const CONFIRM_RESET_PASSWORD_REQUEST_TITLE = 'Please Wait...';
export const CONFIRM_RESET_PASSWORD_SUCCESS_TITLE = 'Congratulations!';
export const CONFIRM_RESET_PASSWORD_FAILURE_TITLE = 'Error!';

export const CONFIRM_RESET_PASSWORD_MODAL_TYPE = 'CONFIRM_RESET_PASSWORD_MODAL_TYPE';
export const RESET_PASSWORD_ROUTE = 'reset-password/:confirmToken';
