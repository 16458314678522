import { LANDING_PAGE_TYPES } from 'discovery/authenticationModal/constants';
import { PLATFORM_SOURCE } from 'shared/constants';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const SIGN_UP_START_OVER = 'SIGN_UP_START_OVER';
export const SIGN_UP_LOCAL_STATE_LOADED = 'SIGN_UP_LOCAL_STATE_LOADED';
export const LINK_EXPIRATION_PERIOD_MS = 1000 * 3600 * 24; // 24 hour

// signup progress texts
export const SIGN_UP_RESEND_EMAIL_BUTTON_TEXT =
    'Re-send Confirmation Email';
export const SIGN_UP_INPROGRESS_MESSAGE =
    'Please click on the link in the email we sent you to verify your email address.';
export const SIGN_UP_INPROGRESS_NOTE = 'Note: The verification link is available only for 24 hours';
export const SIGN_UP_INPROGRESS_MESSAGE_EXPIRED =
    `Click the "${SIGN_UP_RESEND_EMAIL_BUTTON_TEXT}" button below to verify your email address.`;
export const SIGN_UP_INPROGRESS_TITLE =
    'Check Your Email';
export const SIGN_UP_INPROGRESS_TITLE_EXPIRED =
    'Looks like you haven\'t finished registration.';
export const SIGN_UP_INPROGRESS_NO_EMAIL = 'Didn\'t receive the email?';
export const SIGN_UP_TITLE = {
    [PLATFORM_SOURCE.NONE]: 'Create Your HG Insights Account',
    [PLATFORM_SOURCE.FILE]: 'Create Your HG Insights Account,',
    [PLATFORM_SOURCE.SALESFORCE]: 'Create Your HG Insights Account,',
    [PLATFORM_SOURCE.PARDOT]: 'Create Your HG Insights Account,',
    [PLATFORM_SOURCE.MARKETO]: 'Create Your HG Insights Account,',
    [PLATFORM_SOURCE.ELOQUA]: 'Create Your HG Insights Account,'
};

export const SIGN_UP_SUBTITLE = {
    [PLATFORM_SOURCE.FILE]: 'then upload your file',
    [PLATFORM_SOURCE.SALESFORCE]: 'then connect to',
    [PLATFORM_SOURCE.PARDOT]: 'then connect to',
    [PLATFORM_SOURCE.MARKETO]: 'then connect to',
    [PLATFORM_SOURCE.ELOQUA]: 'then connect to'
};

export const SIGNUP_BENEFITS = {
    [LANDING_PAGE_TYPES.LP_SALESFORCE]: [
        {
            strong: 'Find the best accounts to target. ',
            normal: 'Qualify and rank your existing Salesforce account ' +
                'and identify net new opportunities.'
        },
        {
            strong: 'You connect, we’ll compare. ',
            normal: 'We’ll analyze your accounts, match to your segment, ' +
                'and visualize the results.'
        },
        {
            strong: 'Increase your ROI. ',
            normal: 'Boost email opens and clicks by creating personalized ' +
                'segments in a simple workflow.'
        },
        {
            strong: 'Uncover insights. ',
            normal: 'Discover which accounts are using your competitor’s products.'
        },
        {
            strong: 'Safe, secure, read-only process. ',
            normal: 'We don’t require special permissions, and don’t modify ' +
                'any of your Salesfore records.'
        }
    ],
    [LANDING_PAGE_TYPES.LP_PARDOT]: [
        {
            strong: 'Find the best accounts to target. ',
            normal: `Qualify and rank your existing Pardot accounts 
                and identify net new opportunities.`
        },
        {
            strong: 'You connect, we’ll compare. ',
            normal: `We’ll analyze your accounts, match to your segment,
                and visualize the results.`
        },
        {
            strong: 'Increase your ROI. ',
            normal: `Boost email opens and clicks by creating personalized
                segments in a simple workflow.`
        },
        {
            strong: 'Uncover insights. ',
            normal: 'Discover which accounts are using your competitor’s products.'
        },
        {
            strong: 'Safe, secure, read-only process. ',
            normal: `We don’t require special permissions, and don’t modify
                any of your Pardot records.`
        }
    ],
    [LANDING_PAGE_TYPES.LP_MARKETO]: [
        {
            strong: 'Find the best accounts to target. ',
            normal: 'Qualify and rank your existing Marketo leads ' +
            'and identify net new opportunities.'
        },
        {
            strong: 'You connect, we’ll compare. ',
            normal: 'We’ll analyze your leads, match to your segment, ' +
            'and visualize the results.'
        },
        {
            strong: 'Increase your ROI. ',
            normal: 'Boost email opens and clicks by creating personalized ' +
            'segments in a simple workflow.'
        },
        {
            strong: 'Uncover insights. ',
            normal: 'Discover which leads are using your competitor’s products.'
        },
        {
            strong: 'Safe, secure, read-only process. ',
            normal: 'We don’t require special permissions, and don’t modify ' +
            'any of your Marketo records.'
        }
    ],
    [LANDING_PAGE_TYPES.LP_ELOQUA]: [
        {
            strong: 'Find the best accounts to target. ',
            normal: `Qualify and rank your existing Eloqua contacts
                and identify net new opportunities.`
        },
        {
            strong: 'You connect, we’ll compare. ',
            normal: 'We’ll analyze your contacts, match to your segment, ' +
            'and visualize the results.'
        },
        {
            strong: 'Increase your ROI. ',
            normal: `Boost email opens and clicks by creating personalized
                segments in a simple workflow.`
        },
        {
            strong: 'Uncover insights. ',
            normal: 'Discover which contacts are using your competitor’s products.'
        },
        {
            strong: 'Safe, secure, read-only process. ',
            normal: `We don’t require special permissions, and don’t modify
                any of your Eloqua records.`
        }
    ],
    [LANDING_PAGE_TYPES.LP_FILE]: [
        {
            strong: 'Find the best accounts to target. ',
            normal: 'Qualify and rank your existing account and identify ' +
                'net new opportunities.'
        },
        {
            strong: 'You connect, we’ll compare. ',
            normal: 'We’ll analyze your accounts, match to your segment, and visualize ' +
                'the results.'
        },
        {
            strong: 'Increase your ROI. ',
            normal: 'Boost email opens and clicks by creating personalized segments ' +
                'in a simple workflow.'
        },
        {
            strong: 'Uncover insights. ',
            normal: 'Discover which accounts are using your competitor’s products.'
        }
    ]
};
