import PlatformService from 'common/services/PlatformService';
import { setupAuthenticationData } from 'discovery/authenticationModal/actions';
import {
    OAUTH_AUTHORIZE_REQUEST,
    OAUTH_AUTHORIZE_SUCCESS,
    OAUTH_AUTHORIZE_FAILURE,
    OAUTH_AUTHORIZE
} from './constants';

export function authorizeCode(code, sessionId, successCallback) {
    return (dispatch) => {
        dispatch({
            type: OAUTH_AUTHORIZE_REQUEST
        });
        // this returns a new token so we can use salesforce wrapped api's
        return PlatformService.instance.authorizeCode(code, sessionId)
            .then(response => {
                // set new authentication data cookies
                dispatch(setupAuthenticationData(
                    response.data,
                    OAUTH_AUTHORIZE
                ));

                dispatch({
                    type: OAUTH_AUTHORIZE_SUCCESS
                });

                if (successCallback) {
                    successCallback();
                }
            })
            .catch(error => {
                dispatch({
                    type: OAUTH_AUTHORIZE_FAILURE,
                    error
                });
            });
    };
}
