/* global chrome */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    setLocationPath,
    setPrevLocationPath,
    setFocusExtensionId,
    setHG4SFExtensionId
} from './actions';
import {
    refreshToken
} from 'discovery/authenticationModal/actions';
import LightboxContainer from 'shared/lightbox/LightboxContainer';
import Error from './Error';
import { identify } from 'utils/Utils';
import { loadProductTaxonomy } from 'shared/taxonomy/products/actions';
import { loadCollectionTaxonomy } from 'shared/taxonomy/collections/actions';
import { loadAccountDetails } from 'shared/user/accountDetails/actions';


function mapStateToProps(state) {
    return {
        authData: state.authentication.authData,
        applicationError: state.root.applicationError,
        locationPath: state.root.locationPath
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class RootContainer extends React.Component {

    static needs = [
        loadProductTaxonomy,
        loadCollectionTaxonomy,
        loadAccountDetails
    ];

    componentDidMount() {
        // @todo look into https://github.com/reactjs/react-router-redux as
        // this might be the right lib to use instead
        browserHistory.listen((location) => {
            // manage the pathname on your own
            this.props.dispatch(setLocationPath(location.pathname));
            this.refreshTokenIfApplicable();
        });

        this.refreshTokenIfApplicable();

        if (this.isUserAuthenticated()) {
            identify(this.props.authData.userId);
        }

        if (typeof chrome === 'object') {
            // try to connect to Focus to automatically sign in or handle sign out
            // using Focus Chrome Application
            window.addEventListener('message', this.handleFocusIdMessage.bind(this), false);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locationPath !== this.props.locationPath && this.props.locationPath) {
            this.props.dispatch(setPrevLocationPath(this.props.locationPath));
        }
    }

    isUserAuthenticated() {
        return !!this.props.authData.token;
    }

    // Get HG Focus chrome extension's id via to start the communication with the extension.
    // The extension ID is always the same on production but will
    // be different on staging or development unless we set a specific key generation.
    // But, the easiest approach is to actually have the extension send its id to discovery site
    // using the window.postMessage api. The reason we only send the id and not the required data
    // is that chrome extension should not use this type of communication from security concerns,
    // see details here: (https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage)
    // Hence we only get the id passed by the extension via 'postMessage' api and then use the
    // secured communication channel to exchange messages with the chrome extension.
    handleFocusIdMessage(event) {
        if (event.source !== window) {
            return false;
        }

        if (event.data.type && event.data.type === 'focusId') {
            const focusExtensionId = event.data.chromeId;
            this.props.dispatch(setFocusExtensionId(focusExtensionId));
        } else if (event.data.type && event.data.type === 'HG4SalesforceId') {
            const hg4SfExtensionId = event.data.chromeId;
            this.props.dispatch(setHG4SFExtensionId(hg4SfExtensionId));
        }
        return true;
    }

    refreshTokenIfApplicable(forceRefresh) {
        // try to reset token begining with half of the exiration time
        const tokenRefreshTime = this.props.authData.timestamp ?
            (this.props.authData.timestamp + this.props.authData.ttl / 2) : 0;

        if (forceRefresh ||
            (this.isUserAuthenticated() && (tokenRefreshTime < new Date().getTime()))) {
            this.props.dispatch(refreshToken());
        }
    }

    render() {
        let pageContent = null;

        if (this.props.applicationError) {
            // unhandled server errors page content
            pageContent = <Error { ...this.props } />;
        } else {
            // natural route page content
            pageContent = (
                <div className="page-container">
                    { this.props.children }
                    <LightboxContainer />
                </div>
            );
        }

        return pageContent;
    }
}
