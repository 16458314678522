import d3 from 'd3';
import d3TipModule from 'd3-tip';
import Guid from 'guid';
import {
    HORIZONTAL_BARCHART_VERTICAL_MARGINS,
    HORIZONTAL_BARCHART_HORIZONTAL_MARGINS,
    HORIZONTAL_BARCHART_RANGEBANDS_PADDING,
    HORIZONTAL_BARCHART_TICK_PADDING,
    HORIZONTAL_BARCHART_BAR_ANIMATION_MS
} from './constants';

export default class HorizontalStackedBarChart {

    constructor(el, props = {}) {
        this.el = el;
        this.props = props;
        this.verticalMargin = HORIZONTAL_BARCHART_VERTICAL_MARGINS;
        this.horizontalMargin = HORIZONTAL_BARCHART_HORIZONTAL_MARGINS;
        this.tickPaddingSpacesFraction = this.props.tickPaddingSpacesFraction || 5;
        this.dataX = this.props.dataX || 'x';
        this.dataY = this.props.dataY || 'y';
        this.tooltipId = `tooltip-${Guid.raw()}`;
        this.initChart(this.props);
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (this.svg) {
            this.svg.remove();
        }

        this.initChart(this.props);
    }

    initChart(props) {
        const dataset = props.data;
        const barsNumber = dataset.length && dataset[0].values
            ? dataset[0].values.map(d => { return d[this.dataX]; })
            : [];
        const chartContainerWidth = this.el.offsetWidth;

        this.width = chartContainerWidth - 2 * this.horizontalMargin;
        this.height = 25 * barsNumber.length;
        const chartContainerHeight = this.height + 2 * this.verticalMargin;

        this.stack = d3.layout.stack()
            .values(d => { return d.values; })
            .x(d => { return d[this.dataX]; })
            .y(d => { return d[this.dataY]; });

        // create X and Y scales
        this.x = d3.scale.linear()
            .range([0, this.width]);
        this.y = d3.scale.ordinal()
            .rangeRoundBands([0, this.height], HORIZONTAL_BARCHART_RANGEBANDS_PADDING);

        this.configureXAxis();
        this.configureYAxis();

        this.svg = d3.select(this.el)
            .append('svg')
                .attr('width', chartContainerWidth)
                .attr('height', chartContainerHeight);

        this.chart = this.svg
            .append('g')
            .attr('transform', `translate(${this.horizontalMargin}, ${this.verticalMargin} )`);

        this.update(this.props);
    }

    configureXAxis() {
        this.xAxis = d3.svg.axis()
            .scale(this.x)
            .orient('bottom');
    }

    configureYAxis() {
        this.yAxis = d3.svg.axis()
            .scale(this.y)
            .tickSize(0)
            .tickPadding(HORIZONTAL_BARCHART_TICK_PADDING)
            .orient('left');
    }

    update(props) {
        const dataset = props.data;
        const stacked = this.stack(dataset);
        const yDomain = dataset.length && dataset[0].values
            ? dataset[0].values.map(d => { return d[this.dataX]; })
            : [];

        const maxX = d3.max(stacked, d => {
            return d3.max(d.values, v => { return v.y0 + v.y; });
        });

        this.y.domain(yDomain);
        this.x.domain([-maxX, maxX]);

        this.drawAxis();

        if (this.props.tooltipData) {
            this.removeTooltip();

            this.tip = d3TipModule()
                .attr('id', this.tooltipId)
                .attr('class', 'd3-tip')
                .html(this.props.tooltipData)
                .offset([-12, 0]);

            this.svg.call(this.tip);
        }

        const layers = this.chart.selectAll('g.layer')
            .data(stacked, d => { return d.type; })
            .enter()
            .append('g')
                .attr('class', d => { return `layer ${d.type}`; });

        const rects = layers.selectAll('rect')
            .data(d => {
                return d.values.map(v => {
                    return Object.assign(v, { type: d.type });
                });
            })
            .enter()
            .append('rect')
                .attr('x', () => { return this.x(0); })
                .attr('width', 0)
                .attr('y', d => { return this.y(d[this.dataX]); })
                .attr('height', this.y.rangeBand())
                .on('mouseover', d => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip animate').show(d);
                    }
                })
                .on('click', d => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip animate').show(d);
                    }
                })
                .on('mouseout', d => {
                    if (this.tip) {
                        this.tip.attr('class', 'd3-tip').show(d);
                        this.tip.hide();
                    }
                });

        rects.transition()
            .duration(HORIZONTAL_BARCHART_BAR_ANIMATION_MS)
            .ease('sin-in-out')
            .attr('x', d => { return this.x(d.y0); })
            .attr('width', d => { return Math.abs(this.x(d[this.dataY]) - this.x(0));});
    }

    drawAxis() {
        if (this.chart.select('.y.axis')[0][0]) {
            return;
        }

        this.chart.append('g')
            .attr('class', 'y axis')
            .attr('opacity', 0)
            .attr('transform', `translate(${this.x(0)}, 0)`)
            .call(this.yAxis);

        this.chart
            .selectAll('.y.axis')
                .transition().duration(1200).ease('sin-in-out')
                    .attr('opacity', 1);
    }

    removeTooltip() {
        // d3.select(`#${this.tooltipId}`).remove();
    }

    destroy() {
        this.removeTooltip();
        window.removeEventListener('resize', this.handleResize);
    }
}
