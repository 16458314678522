import {
    ACCOUNT_DETAILS_REQUEST,
    ACCOUNT_DETAILS_SUCCESS,
    ACCOUNT_DETAILS_FAILURE,
    ACCOUNT_DETAILS_SET
} from './constants';
import { LOG_OUT } from 'discovery/authenticationModal/constants';

export const accountDetailsInitialState = {
    data: null
};

export default function accountDetails(state = accountDetailsInitialState, action) {
    switch (action.type) {
        case ACCOUNT_DETAILS_REQUEST :
            return Object.assign({}, state, {
                inProgress: action.resend || false,
                error: null
            });

        case ACCOUNT_DETAILS_SUCCESS :
        case ACCOUNT_DETAILS_SET :
            return Object.assign({}, state, {
                inProgress: true,
                data: action.data,
                error: null
            });

        case ACCOUNT_DETAILS_FAILURE :
            return Object.assign({}, state, {
                inProgress: false,
                error: action.error
            });

        case LOG_OUT :
            return Object.assign({}, state, {
                inProgress: false,
                data: null
            });

        default:
            return state;
    }
}
