import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from 'classnames';
import EmailsShare from './EmailsShare';
import {
    SHARE_SEGMENT_TITLE
} from '../constants';

export default class ShareSegment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            copied: false
        };
    }

    onCopyToClipboard = () => {
        this.setState({ copied: true });
        this.timeoutHandler = setTimeout(() => {
            this.setState({ copied: false });
        }, 1000);
    }

    getTitle() {
        return (
            <div className="share-title">
                { SHARE_SEGMENT_TITLE }
            </div>
        );
    }

    getSeparator() {
        return (
            <div className="separator">
                <span className="separator-text">
                    OR
                </span>
                <div className="separator-line" > </div>
            </div>
        );
    }

    getSocialShare() {
        const copyClass = classNames('url-copied-text', {
            visible: this.state.copied
        });

        return (
            <div className="social-icons">
                    <div className="social-share-bubble" >
                        <span className="icon-email"
                            onClick={ this.props.shareViaEmail }
                        />
                    </div>
                    <div className="social-share-bubble" >
                        <a href={ this.props.shareViaLinkedIn() } target="_blank" >
                            <span className="icon-social-linkedin social-share" />
                        </a>
                    </div>
                    <div className="social-share-bubble" >
                        <a href={ this.props.shareViaTwitter() } target="_blank" >
                            <span className="icon-social-twitter social-share" />
                        </a>
                    </div>
                    <div className="social-share-bubble" >
                        <CopyToClipboard
                            text={ this.props.segmentUrl() }
                            onCopy={ this.onCopyToClipboard }
                        >
                            <div className="copy-link">
                                <span className="icon-record" />
                                <span className={ copyClass }>
                                    Segment URL copied to clipboard
                                </span>
                            </div>
                        </CopyToClipboard>
                    </div>
            </div>
        );
    }

    getShareViaEmails() {
        const { isUserAuthenticated } = this.props;

        if (isUserAuthenticated) {
            return (
                <div>
                    <EmailsShare { ...this.props } />
                    { this.getSeparator() }
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="share-form" id="share">
                { this.getTitle() }
                { this.getShareViaEmails() }
                { this.getSocialShare() }
            </div>
        );
    }
}
