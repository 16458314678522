export const MATCH_SEGMENT_BTN_TEXT = 'Match & Net New';
export const SEGMENT_COMPANIES_TOTAL = '# COMPANIES IN THIS SEGMENT';
export const SEGMENT_COMPANIES_CHANGE = '# 6 MONTH CHANGE';
export const SEGMENT_COMPANIES_MATCHES_TITLE = '# MATCHED COMPANIES';
export const SEGMENT_COMPANIES_NET_NEW_TITLE = '# NET NEW COMPANIES';
export const SEGMENT_PEOPLE_MATCHES_TITLE = '# MATCHED PEOPLE';

export const MATCH_SOURCE_TYPE_SALESFORCE = 'MATCH_SOURCE_TYPE_SALESFORCE';
export const MATCH_SOURCE_TYPE_PARDOT = 'MATCH_SOURCE_TYPE_PARDOT';
export const MATCH_SOURCE_TYPE_MARKETO = 'MATCH_SOURCE_TYPE_MARKETO';
export const MATCH_SOURCE_TYPE_FILE = 'MATCH_SOURCE_TYPE_FILE';
export const MATCH_SOURCE_TYPE_ELOQUA = 'MATCH_SOURCE_TYPE_ELOQUA';
export const MATCH_SOURCE_TYPE_LINKEDIN = 'MATCH_SOURCE_TYPE_LINKEDIN';

export const NET_NEW_MATCHES_REQUEST = 'NET_NEW_MATCHES_REQUEST';
export const NET_NEW_MATCHES_SUCCESS = 'NET_NEW_MATCHES_SUCCESS';
export const NET_NEW_MATCHES_FAILURE = 'NET_NEW_MATCHES_FAILURE';
export const NET_NEW_MATCHES_RESET = 'NET_NEW_MATCHES_RESET';
export const NET_NEW_MATCHES_SET = 'NET_NEW_MATCHES_SET';

export const SEGMENT_SUMMARY_REQUEST = 'SEGMENT_SUMMARY_REQUEST';
export const SEGMENT_SUMMARY_SUCCESS = 'SEGMENT_SUMMARY_SUCCESS';
export const SEGMENT_SUMMARY_FAILURE = 'SEGMENT_SUMMARY_FAILURE';
export const SEGMENT_SUMMARY_RESET = 'SEGMENT_SUMMARY_RESET';
export const SEGMENT_SUMMARY_SET = 'SEGMENT_SUMMARY_RESET';

export const SEGMENT_AVAILABLE_RECORDS_REQUEST = 'SEGMENT_AVAILABLE_RECORDS_REQUEST';
export const SEGMENT_AVAILABLE_RECORDS_SET = 'SEGMENT_AVAILABLE_RECORDS_SET';
export const SEGMENT_AVAILABLE_RECORDS_SUCCESS = 'SEGMENT_AVAILABLE_RECORDS_SUCCESS';
export const SEGMENT_AVAILABLE_RECORDS_FAILURE = 'SEGMENT_AVAILABLE_RECORDS_FAILURE';
export const SEGMENT_AVAILABLE_RECORDS_RESET = 'SEGMENT_AVAILABLE_RECORDS_RESET';
export const SEGMENT_AVAILABLE_RECORDS_RESET_VIEW = 'SEGMENT_AVAILABLE_RECORDS_RESET_VIEW';

export const SET_MATCHES_ERROR = 'SET_MATCHES_ERROR';

export const SEGMENT_PREVIOUS_FILES_REQUEST = 'SEGMENT_PREVIOUS_FILES_REQUEST';
export const SEGMENT_PREVIOUS_FILES_SUCCESS = 'SEGMENT_PREVIOUS_FILES_SUCCESS';
export const SEGMENT_PREVIOUS_FILES_FAILURE = 'SEGMENT_PREVIOUS_FILES_FAILURE';

export const PRODUCT_SUMMARY_REQUEST = 'PRODUCT_SUMMARY_REQUEST';
export const PRODUCT_SUMMARY_SUCCESS = 'PRODUCT_SUMMARY_SUCCESS';
export const PRODUCT_SUMMARY_FAILURE = 'PRODUCT_SUMMARY_FAILURE';
export const PRODUCT_SUMMARY_RESET = 'PRODUCT_SUMMARY_RESET';
