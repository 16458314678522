import React from 'react';
import { Link } from 'react-router';
import MySegmentListItemContainer from
        '../../components/mysegmentslistitem/MySegmentListItemContainer';
import MyHgPanelWrapper from 'shared/ui/panel/MyHgPanelWrapper';
import Loader from 'shared/ui/loader/Loader';
import EmptySegmentList from 'myhg/viewallsegments/components/EmptySegmentList';
import {
    VIEW_ALL_SEGMENT_LINK,
    MY_SEGMENTS_TOP_COUNT_RECENT
} from './constants';

export default class RecentlyCreatedSegments extends React.Component {

    getTitle() {
        return (
            <div className="panel-header">
                <div>
                    <h2 className="panel-title">
                        Recently Created Segments
                    </h2>
                    <Link
                        className="primary-link mysegments-panel-viewall"
                        to="/dashboard/segments"
                    >
                        { VIEW_ALL_SEGMENT_LINK }
                    </Link>
                </div>
            </div>
        );
    }

    getTopRecentSegments() {
        const { allSegments } = this.props;

        return allSegments.data.slice(0, MY_SEGMENTS_TOP_COUNT_RECENT)
            .map((item, index) => {
                return <MySegmentListItemContainer segment={ item } key={ index } />;
            });
    }

    getContent(isEmptyList) {
        return isEmptyList ?
            <EmptySegmentList { ...this.props } /> : this.getTopRecentSegments();
    }

    render() {
        const { allSegments } = this.props;
        const isEmptyList = !allSegments.isFetching && !allSegments.data.length;

        return (
            <MyHgPanelWrapper
                className="mysegments-panel"
                customTitleHeader={ isEmptyList ? '' : this.getTitle() }
            >
                { allSegments.isFetching ? <Loader /> : this.getContent(isEmptyList) }
            </MyHgPanelWrapper>
        );
    }
}
