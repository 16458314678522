import UserService from 'common/services/UserService';
import {
    START_TRIAL,
    START_TRIAL_SUCCESS,
    START_TRIAL_ERROR
} from './constants';
import { loadAccountDetails } from 'shared/user/accountDetails/actions';

export function startTrialForLegacyUser() {
    return (dispatch) => {
        dispatch({
            type: START_TRIAL
        });

        return UserService.instance.startUserTrial()
            .then(() => {
                dispatch({
                    type: START_TRIAL_SUCCESS
                });
                dispatch(loadAccountDetails());
            })
            .catch((error) => {
                dispatch({
                    type: START_TRIAL_ERROR,
                    error
                });
            });
    };
}
