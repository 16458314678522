import PropTypes from 'prop-types';
import React from 'react';
import ReactDom from 'react-dom';
import { browserHistory } from 'react-router';
import classNames from 'classnames';
import { DateUtils } from 'utils/Utils';
import Loader from 'shared/ui/loader/Loader';
import { saveCurrentSegment, copySegment, nameEditViewShow, deleteSegment } from '../actions';
import { KEYCODE_KEY_ENTER } from 'shared/constants';
import UserUtils from 'utils/UserUtils';
import GenericDeleteButton from '../../../shared/ui/buttons/GenericDeleteButton';
import { ACTION } from 'utils/track-constants';
import {
    CREATED_ON,
    UPDATED_ON,
    SEGMENT_NAME_PLACEHOLDER
} from '../constants';

export default class SegmentName extends React.Component {

    componentDidMount() {
        const { params: { segmentId, encodedSegment }, dispatch } = this.props;

        if (!segmentId && !encodedSegment) {
            const segmentNameInputDOM = ReactDom.findDOMNode(this.refs.inputName);
            if (segmentNameInputDOM !== null) {
                segmentNameInputDOM.focus();
            }
            dispatch(nameEditViewShow(true));
        }
    }

    componentDidUpdate(prevProps) {
        const { segmentView: { isNameEdit: currentNameEdit } } = this.props;
        const { segmentView: { isNameEdit: prevNameEdit } } = prevProps;

        if (currentNameEdit && !prevNameEdit) {
            this.refs.inputName.focus();
            this.refs.inputName.setSelectionRange(0, this.refs.inputName.value.length);
        }
    }

    onInputLeave = () => {
        const { dispatch } = this.props;
        this.saveSegmentName();
        dispatch(nameEditViewShow(false));
    }

    onCopy = () => {
        const { dispatch } = this.props;
        dispatch(copySegment());
        browserHistory.push('/dashboard/segment/new');
    }

    getStatus() {
        const { updatedOn, createdOn } = this.props.segment.currentSegment;
        let content = null;
        const text = updatedOn ? UPDATED_ON : CREATED_ON;

        if (updatedOn || createdOn) {
            const formattedDate = DateUtils.toStandardFormatDate(updatedOn || createdOn);

            content = (
                <div className="segment-date">
                    <span className="high-light">
                        { text } { formattedDate }
                    </span>
                </div>
            );
        }

        return content;
    }

    getSavingLoader() {
        const { segmentSave } = this.props.segment;

        return segmentSave.inProgress ? (
            <div className="saving-segment-notifier">
                <Loader className="small-loader white" />
                Saving...
            </div>
        ) : null;
    }

    getSegmentDelete() {
        const { currentSegment } = this.props.segment;
        const deleteButtonIconClass = classNames({
            disabled: !this.isPaidUser() || !currentSegment.id
        });

        return (
            <GenericDeleteButton
                className={ deleteButtonIconClass }
                dispatch={ this.props.dispatch }
                item={ this.props.segment.currentSegment }
                tracker={ ACTION.DELETE_SEGMENT }
                deleteAction={ deleteSegment }
                afterDelete={ this.afterSegmentDelete }
            />
        );
    }

    getSegmentActions() {
        const { currentSegment } = this.props.segment;
        const duplicateIconClass = classNames('icon icon-duplicate', {
            disabled: !this.isPaidUser() || !currentSegment.id
        });

        return (
            <div className="segment-actions">
                <span
                    className="icon icon-edit"
                    title="Edit"
                    onClick={ this.clickEditName }
                    data-qa="segment-edit"
                />
                <span
                    className={ duplicateIconClass }
                    title="Duplicate"
                    onClick={ this.onCopy }
                    data-qa="segment-duplicate"
                />
                { this.getSegmentDelete() }
            </div>
        );
    }

    getInputData() {
        const { currentSegment } = this.props.segment;
        const { segmentView } = this.props;
        const displayText = currentSegment.name || SEGMENT_NAME_PLACEHOLDER;
        const inputStyle = {
            width: (displayText.length + 1) * 17
        };

        return (
            <div className="segment-input-wrapper">
                {
                    segmentView.isNameEdit ? (
                        <input
                            ref="inputName"
                            className="segment-input"
                            style={ inputStyle }
                            value={ currentSegment.name }
                            onChange={ this.handleInputChange }
                            placeholder={ SEGMENT_NAME_PLACEHOLDER }
                            onBlur={ this.onInputLeave }
                            onKeyPress={ this.handleKeyPress }
                        />
                    ) : (
                        <span
                            className="segment-input"
                            onClick={ this.clickEditName }
                            data-qa="segment-name"
                        >
                            { currentSegment.name || SEGMENT_NAME_PLACEHOLDER }
                        </span>
                    )
                }
                { this.getSegmentActions() }
            </div>
        );
    }

    handleKeyPress = (event) => {
        if (event.which === KEYCODE_KEY_ENTER) {
            this.saveSegmentName();
        }
    }

    afterSegmentDelete = () => {
        browserHistory.push('/dashboard');
    }

    saveSegmentName() {
        const { dispatch, segment: { currentSegment } } = this.props;

        if (currentSegment.id) {
            dispatch(saveCurrentSegment());
        }
    }

    clickEditName = () => {
        const { dispatch } = this.props;
        dispatch(nameEditViewShow(true));
    }

    handleInputChange = (event) => {
        const { onSegmentNameChange } = this.props;
        if (onSegmentNameChange) {
            onSegmentNameChange(event.target.value);
        }
    }

    isPaidUser() {
        const { accountDetails } = this.props;
        return UserUtils.isPaid(accountDetails);
    }

    render() {
        return (
            <div id="segment-name-container">
                { this.getInputData() }
                <div>
                    { this.getStatus() }
                    { this.getSavingLoader() }
                </div>
            </div>
        );
    }
}

SegmentName.propTypes = {
    segment: PropTypes.object.isRequired
};
