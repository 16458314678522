import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import DraggableGrid from './components/DraggableGrid';
import FixedGrid from './components/FixedGrid';


function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapDispatchToProps)

export default class GridContainer extends React.Component {
    getCompanyName() {
        const tableData = this.props.tableData;
        return tableData.map((item) => {
            return item.companyName;
        });
    }

    render() {
        const cssClass = classNames('table-container', this.props.cssClass);
        return (
            <div className={ cssClass }>
                <FixedGrid
                    companyName={ this.getCompanyName() }
                    { ...this.props }
                />
                <DraggableGrid { ...this.props } />
            </div>
        );
    }
}
