import React from 'react';
import { connect } from 'react-redux';
import PopularTechnologies from './components/PopularTechnologies';
import { selectNewProduct } from 'discovery/product/actions';
import { loadPopularTechnologies } from './actions';

function mapStateToProps(state) {
    return {
        popularTechnologies: state.home.popularTechnologies,
        technologiesCount: state.taxonomy.products.data.length
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class PopularTechnologiesContainer extends React.Component {

    // @todo: Uncomment this once we havea api support for getting specific technologies
    componentDidMount() {
        if (!this.props.popularTechnologies.data.length) {
            this.props.dispatch(loadPopularTechnologies());
        }
    }

    render() {
        return (
            <PopularTechnologies
                selectNewProduct={ selectNewProduct }
                {...this.props}
            />
        );
    }
}
