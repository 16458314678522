import React from 'react';
import { connect } from 'react-redux';
import {
    addSignalScoreId,
    removeSignalScoreId
} from './actions';
import {
    SIGNAL_SCORE_OPTIONS
} from 'shared/constants';
import { SIGNAL_SCORE_TITLE } from './constants';
import SignalScoreSelection from './SignalScoreSelection';

function mapStateToProps(state) {
    const { segment } = state.myhg;

    return {
        signalScoreIds: segment.segmentFilters.signalScores
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

@connect(mapStateToProps, mapDispatchToProps)

export default class SignalScoresContainer extends React.Component {

    onChange = (item, isChecked) => {
        const { dispatch, signalScoreIds } = this.props;
        if (isChecked) {
            dispatch(addSignalScoreId(item.id));
        } else if (signalScoreIds.length > 1) {
            // remove signal score if there are at least two selected
            dispatch(removeSignalScoreId(item.id));
        }
    }

    getTiles() {
        const { signalScoreIds } = this.props;
        return SIGNAL_SCORE_OPTIONS.map((item) => {
            const isChecked = signalScoreIds.indexOf(item.id) >= 0;
            return (
                <SignalScoreSelection
                    key={ item.id }
                    isChecked={ isChecked }
                    item={ item }
                    onChangeSelection={ this.onChange }
                />
            );
        });
    }

    render() {
        return (
            <div id="signal-score-filter-section">
                <div className="filter-section-title">
                    <h3>{ SIGNAL_SCORE_TITLE }</h3>
                </div>
                <div className="signal-scores-selection">
                    { this.getTiles() }
                </div>
            </div>
        );
    }
}
