import {
    FILTERS_SET_REVENUE_INTERVAL
} from '../constants';

export const revenueFilterInitialState = null;

export default function revenue(state = revenueFilterInitialState, action) {
    switch (action.type) {
        case FILTERS_SET_REVENUE_INTERVAL:
            return action.revenue;

        default:
            return state;
    }
}
