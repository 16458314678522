import {
    FILTERS_ADD_LOCATION,
    FILTERS_REMOVE_LOCATION,
    FILTERS_SET_LOCATIONS,
    FILTERS_CLEAR_LOCATIONS,
    FILTERS_LOCATION_TYPE_COUNTRY,
    FILTERS_LOCATION_TYPE_REGION
} from '../constants';
import {
    APAC_REGION_NAME,
    APAC_FULL_REGION_NAME,
    EMEA_REGION_NAME,
    EMEA_FULL_REGION_NAME,
    NORTH_AMERICA_REGION_NAME,
    NORTH_AMERICA_FULL_REGION_NAME,
    LATAM_REGION_NAME,
    LATAM_FULL_REGION_NAME
} from './constants';

export const setupLocationsFilterInitialState = {
    [FILTERS_LOCATION_TYPE_COUNTRY]: [],
    [FILTERS_LOCATION_TYPE_REGION]: []
};

function getRegionName(locationList, regionName) {
    switch (regionName) {
        case APAC_REGION_NAME:
            return APAC_FULL_REGION_NAME;
        case EMEA_REGION_NAME:
            return EMEA_FULL_REGION_NAME;
        case NORTH_AMERICA_REGION_NAME:
            return NORTH_AMERICA_FULL_REGION_NAME;
        case LATAM_REGION_NAME:
            return LATAM_FULL_REGION_NAME;
        default:
            return '';
    }
}

export const locationsForDropdown = (locationList) => {
    const uniqueRegions = [...new Set(locationList.map((item) => {
        return item.region;
    }))];
    const regions = uniqueRegions.map((region) => {
        return {
            id: region,
            text: getRegionName(locationList, region),
            type: FILTERS_LOCATION_TYPE_REGION
        };
    });

    // extract countries
    const countries = locationList.map((location) => {
        return {
            id: location.iso,
            text: location.country,
            region: location.region,
            type: FILTERS_LOCATION_TYPE_COUNTRY
        };
    });

    return {
        [FILTERS_LOCATION_TYPE_REGION]: regions,
        [FILTERS_LOCATION_TYPE_COUNTRY]: countries
    };
};

export function getSearchableLocations(locationItems, excludeLocations) {
    let regions = locationItems[FILTERS_LOCATION_TYPE_REGION];
    let countries = locationItems[FILTERS_LOCATION_TYPE_COUNTRY];

    const excludeRegions = excludeLocations[FILTERS_LOCATION_TYPE_REGION];
    const excludeCountries = excludeLocations[FILTERS_LOCATION_TYPE_COUNTRY];

    countries = countries.filter((countryLocation) => {
        return excludeRegions.findIndex((region) => {
            return region === countryLocation.region;
        }) === -1;
    });

    // exclude regions from excluded regions
    regions = regions.filter((regionLocation) => {
        return excludeRegions.findIndex((region) => {
            return region === regionLocation.id;
        }) === -1;
    });

    // exclude countries from excluded countries
    countries = countries.filter((location) => {
        return excludeCountries.findIndex((excludeCountry) => {
            return excludeCountry === location.id;
        }) === -1;
    });

    return [{
        type: FILTERS_LOCATION_TYPE_REGION,
        items: regions
    }, {
        type: FILTERS_LOCATION_TYPE_COUNTRY,
        items: countries
    }];
}

export default function locations(state = setupLocationsFilterInitialState, action) {
    const { location } = action;

    switch (action.type) {
        case FILTERS_ADD_LOCATION:
            return Object.assign({}, state, {
                [location.type]: [...state[location.type], location.id]
            });

        case FILTERS_REMOVE_LOCATION: {
            const index = state[location.type].indexOf(location.id);
            return Object.assign({}, state, {
                [location.type]: [
                    ...state[location.type].slice(0, index),
                    ...state[location.type].slice(index + 1)
                ]
            });
        }

        case FILTERS_SET_LOCATIONS: {
            return action.locations;
        }

        case FILTERS_CLEAR_LOCATIONS: {
            return setupLocationsFilterInitialState;
        }

        default:
            return state;
    }
}
