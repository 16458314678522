export const FIRMOGRAPHIC_LOCATIONS_ROUTE = 'locations';
export const FIRMOGRAPHIC_EMPLOYEES_ROUTE = 'employees';
export const FIRMOGRAPHIC_REVENUE_ROUTE = 'revenue';
export const FIRMOGRAPHIC_INDUSTRY_ROUTE = 'industry';

// FIRMOGRAPHIC ACTION TYPES
export const FIRMOGRAPHIC_TAB_SELECT = 'FIRMOGRAPHIC_TAB_SELECT';

// FIRMOGRAPHIC DATA LOAD
export const FIRMOGRAPHIC_DATA_REQUEST = 'FIRMOGRAPHIC_DATA_REQUEST';
export const FIRMOGRAPHIC_DATA_SUCCESS = 'FIRMOGRAPHIC_DATA_SUCCESS';
export const FIRMOGRAPHIC_DATA_FAILURE = 'FIRMOGRAPHIC_DATA_FAILURE';
