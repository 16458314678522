import UserService from 'common/services/UserService';
import {
    USER_LOAD_PREFERENCES_SUCCESS,
    USER_LOAD_PREFERENCES_FAILURE,
    USER_SAVE_PREFERENCES_SUCCESS,
    USER_SAVE_PREFERENCES_FAILURE
} from './constants';

export function loadUserPreferences() {
    return (dispatch) => {
        return UserService.instance.getUserPreferences().then((response) => {
            dispatch({
                type: USER_LOAD_PREFERENCES_SUCCESS,
                data: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_LOAD_PREFERENCES_FAILURE,
                error
            });
        });
    };
}

export function saveUserPreferences(preferences) {
    return (dispatch) => {
        return UserService.instance.putUserPreferences(preferences).then(() => {
            dispatch({
                type: USER_SAVE_PREFERENCES_SUCCESS
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_SAVE_PREFERENCES_FAILURE,
                error
            });
        });
    };
}
