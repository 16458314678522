import {
    SEGMENT_LOCATIONS_DATA_REQUEST,
    SEGMENT_LOCATIONS_DATA_SUCCESS,
    SEGMENT_LOCATIONS_DATA_FAILURE,
    SEGMENT_LOCATIONS_CLEAN_DATA
} from './constants';

export const locationsFirmographicInitialState = {
    isFetching: false,
    error: null,
    data: null,
    cancelToken: null
};

export default function locationsFirmographic(state = locationsFirmographicInitialState, action) {
    switch (action.type) {
        case SEGMENT_LOCATIONS_DATA_REQUEST :
            return Object.assign({}, state, {
                isFetching: true,
                cancelToken: action.cancelToken,
                error: null
            });

        case SEGMENT_LOCATIONS_DATA_SUCCESS :
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data,
                error: null,
                cancelToken: null
            });

        case SEGMENT_LOCATIONS_DATA_FAILURE :
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                cancelToken: null
            });

        case SEGMENT_LOCATIONS_CLEAN_DATA: {
            if (state.cancelToken) {
                state.cancelToken.cancel();
            }
            return locationsFirmographicInitialState;
        }

        default:
            return state;
    }
}
