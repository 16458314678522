import axios from 'axios';
import SegmentService from 'common/services/SegmentService';
import UserUtils from 'utils/UserUtils';
import {
    SEGMENT_MATCHES_DATA_REQUEST,
    SEGMENT_MATCHES_DATA_SUCCESS,
    SEGMENT_MATCHES_DATA_FAILURE,
    SEGMENT_NEW_FILTERS,
    SEGMENT_TABLE_START_PAGE,
    SEGMENT_TABLE_RECORDS_PER_PAGE,
    NON_PAID_NUMBER_OF_RECORDS,
    SEGMENT_EXAMPLE_DATA_RECORD
} from './constants';
import {
    MATCH_SOURCE_TYPE
} from 'myhg/segment/constants';

function getMatchesURL(statusObj) {
    let url;
    const { source } = statusObj;

    if (!source) {
        url = 'filtering/matches/data';
    } else if (source === MATCH_SOURCE_TYPE.FILE) {
        url = 'filtering/csv/matches/results';
    } else {
        // platform matches url
        url = 'salesforce/matches/results';
    }

    return url;
}

function customParamsForMatchURL(statusObj) {
    const { sourceValue, source } = statusObj;
    let customParam;

    switch (source) {
        case MATCH_SOURCE_TYPE.FILE:
            customParam = { fileId: sourceValue };
            break;

        default:
            customParam = {};
    }

    return customParam;
}

export function hasNewFilters(hasFilters) {
    return (dispatch) => {
        return dispatch({
            type: SEGMENT_NEW_FILTERS,
            hasFilters
        });
    };
}

export const defaultPaginationParams = {
    page: SEGMENT_TABLE_START_PAGE,
    perPage: SEGMENT_TABLE_RECORDS_PER_PAGE * 2
};

export function loadMatchData(paginationParams = defaultPaginationParams, clearData = true) {
    return (dispatch, getState) => {
        const cancelToken = axios.CancelToken.source();
        const currentState = getState();
        const { data: { status, filters }, id } = currentState.myhg.segment.currentSegment;
        const isPaidUser = UserUtils.isPaid(currentState.user.accountDetails.data);
        const exampleData = Array(NON_PAID_NUMBER_OF_RECORDS).fill(SEGMENT_EXAMPLE_DATA_RECORD);

        const reqParams = Object.assign({},
            paginationParams,
            filters,
            customParamsForMatchURL(status)
        );

        dispatch({
            type: SEGMENT_MATCHES_DATA_REQUEST,
            cancelToken
        });
        let result = null;

        if (isPaidUser) {
            result = SegmentService.instance
                .getMatchesData(getMatchesURL(status), reqParams, id, cancelToken)
                .then((response) => {
                    dispatch({
                        type: SEGMENT_MATCHES_DATA_SUCCESS,
                        data: response.data,
                        clearData,
                        products: currentState.taxonomy.products.data
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: SEGMENT_MATCHES_DATA_FAILURE,
                        error
                    });
                });
        } else {
            dispatch({
                type: SEGMENT_MATCHES_DATA_SUCCESS,
                data: exampleData,
                clearData,
                products: currentState.taxonomy.products.data
            });
            result = true;
        }
        return result;
    };
}
