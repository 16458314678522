import PropTypes from 'prop-types';
import React from 'react';
import PanelWrapper from 'shared/ui/panel/PanelWrapper';
import PopularTechnologiesList from './PopularTechnologiesList';
import TotalCompanies from './TotalCompanies';
import Loader from 'shared/ui/loader/Loader';

/**
 * Component that will render details of similar products.
 */
export default class PopularTechnologies extends React.Component {

    getPanelContent(popularTechnologies, technologiesCount) {
        return (
            <div className="row">
                <div className="col-12 col-lg-6">
                    <PopularTechnologiesList
                        technologies={ popularTechnologies.data.slice(0, 3) }
                        tooltipId="tooltip1"
                        {...this.props}
                    />
                </div>
                <div className="col-12 col-lg-6 custom-table">
                    <PopularTechnologiesList
                        technologies={ popularTechnologies.data.slice(3, 6) }
                        tooltipId="tooltip2"
                        {...this.props}
                    />
                </div>

                <div className="col-12 col-sm-6 custom-tfoot">
                    <TotalCompanies
                        products={ popularTechnologies.data }
                        count={ technologiesCount }
                    />
                </div>
            </div>
        );
    }

    render() {
        const { popularTechnologies, technologiesCount } = this.props;
        const isFetching = popularTechnologies && popularTechnologies.isFetching;
        const error = popularTechnologies && popularTechnologies.error;

        let panelContent = null;
        if (error) {
            panelContent = error.message;
        } else if (!isFetching && !!popularTechnologies.data.length) {
            panelContent = this.getPanelContent(popularTechnologies, technologiesCount);
        } else {
            panelContent = <Loader />;
        }
        return (
            <PanelWrapper
                className="box"
                title="Trending Technologies"
            >
                { panelContent }
            </PanelWrapper>
        );
    }
}

PopularTechnologies.propTypes = {
    popularTechnologies: PropTypes.object.isRequired
};
