import {
    SIGNAL_SCORE_ADD,
    SIGNAL_SCORE_REMOVE,
    SIGNAL_SCORE_RESET,
    SIGNAL_SCORE_SET
} from './constants';

export function addSignalScoreId(scoreId) {
    return (dispatch) => {
        return dispatch({
            type: SIGNAL_SCORE_ADD,
            scoreId
        });
    };
}

export function removeSignalScoreId(scoreId) {
    return (dispatch) => {
        return dispatch({
            type: SIGNAL_SCORE_REMOVE,
            scoreId
        });
    };
}

export function resetSignalScores() {
    return (dispatch) => {
        return dispatch({
            type: SIGNAL_SCORE_RESET
        });
    };
}

export function setSignalScoreIds(scoreIds) {
    return (dispatch) => {
        return dispatch({
            type: SIGNAL_SCORE_SET,
            scoreIds
        });
    };
}
