import {
    FILTERS_ADD_LOCATION,
    FILTERS_REMOVE_LOCATION,
    FILTERS_SET_LOCATIONS,
    FILTERS_CLEAR_LOCATIONS
} from '../constants';

export function addLocation(location) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_ADD_LOCATION,
            location
        });
    };
}

export function removeLocation(location) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_REMOVE_LOCATION,
            location
        });
    };
}

export function setLocations(locations) {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_SET_LOCATIONS,
            locations
        });
    };
}

export function clearLocations() {
    return (dispatch) => {
        return dispatch({
            type: FILTERS_CLEAR_LOCATIONS
        });
    };
}
