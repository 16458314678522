import GenericBenefits from './accountBenefits/GenericBenefits';
import SalesforceBenefits from './accountBenefits/SalesforceBenefits';
import PardotBenefits from './accountBenefits/PardotBenefits';
import FileBenefits from './accountBenefits/FileBenefits';
import MarketoBenefits from './accountBenefits/MarketoBenefits';
import EloquaBenefits from './accountBenefits/EloquaBenefits';
import { LANDING_PAGE_TYPES } from 'discovery/authenticationModal/constants';

export const ACCOUNT_BENEFITS_COMPONENTS = {
    [LANDING_PAGE_TYPES.NONE]: GenericBenefits,
    [LANDING_PAGE_TYPES.LP_SALESFORCE]: SalesforceBenefits,
    [LANDING_PAGE_TYPES.LP_PARDOT]: PardotBenefits,
    [LANDING_PAGE_TYPES.LP_MARKETO]: MarketoBenefits,
    [LANDING_PAGE_TYPES.LP_FILE]: FileBenefits,
    [LANDING_PAGE_TYPES.LP_ELOQUA]: EloquaBenefits
};
