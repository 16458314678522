import RegistrationService from 'common/services/RegistrationService';
import { setupAuthenticationData } from '../actions';
import {
    VALID_VERIFICATION_LINK,
    CONFIRM_SIGN_UP_REQUEST,
    CONFIRM_SIGN_UP_SUCCESS,
    CONFIRM_SIGN_UP_FAILURE
} from './constants';

export function validLink(isValid) {
    return {
        type: VALID_VERIFICATION_LINK,
        isValid
    };
}

export function confirmRegistration(confirmToken) {
    return (dispatch) => {
        dispatch({
            type: CONFIRM_SIGN_UP_REQUEST
        });

        // Dispatch vanilla actions asynchronously
        return RegistrationService.instance.confirmRegistration(confirmToken)
            .then((response) => {
                RegistrationService.saveRegistrationProgress(null);
                // return statement required to determine when below async call finished
                return dispatch(setupAuthenticationData(
                    response.data,
                    CONFIRM_SIGN_UP_SUCCESS
                ));
            })
            .catch((error) => {
                dispatch({
                    type: CONFIRM_SIGN_UP_FAILURE,
                    error
                });

                return Promise.reject(error);
            });
    };
}
