import React from 'react';
import ReactDOM from 'react-dom';
import HorizontalBarChart from 'shared/ui/charts/HorizontalBarChart';
import { setIndustries } from 'myhg/segment/segmentFilters/industriesFilter/actions';

/**
 * Component that renders the Industry Chart
 */
export default class IndustryChart extends React.Component {

    componentDidMount() {
        this.barChart = new HorizontalBarChart(this.getDOMNode(), this.getChartState());
    }

    componentDidUpdate() {
        if (this.barChart) {
            this.barChart.update(this.getChartState());
        }
    }

    componentWillUnmount() {
        if (this.barChart) {
            this.barChart.destroy(this.getDOMNode());
        }
    }

    onIndustrySelect = (industry) => {
        const { dispatch } = this.props;

        this.props.createNewProductSegment();
        dispatch(setIndustries([industry.name]));
    }

    getDOMNode() {
        return ReactDOM.findDOMNode(this);
    }

    getChartState() {
        let state = {};

        if (this.props.data && this.props.data.length) {
            let data = this.props.data;

            data = data.filter((item) => {
                return item.value > 0;
            }) || [];

            data = data.sort((left, right) => {
                return left.value < right.value ? 1 : -1;
            });

            state = {
                yGrid: false,
                shadowBar: false,
                data,
                chartHeight: data.length * 40,
                tickPaddingSpacesFraction: 1,
                onClick: this.onIndustrySelect
            };
        }

        return state;
    }

    render() {
        return (
            <div id="industry-bar-chart" className="bar-chart-container">
            </div>
        );
    }
}

