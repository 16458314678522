import React from 'react';

export default class SearchSelectionHeader extends React.Component {

    render() {
        return (
            <div className="search-selection-header">
                Hold CTRL or CMD to select multiple products
            </div>
        );
    }
}
